import React from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
import { clx } from "../utils/clx";
import { getFormattedPrice } from "../utils/getFormattedPrice";
import Text from "./text";

export default function WalletTransactionCard({
  referenceId,
  date,
  amount,
  status,
  classname,
  children,
}) {
  const classes = clx(
    "w-[740px] flex items-center space-x-[20px] justify-between py-4 border-b-2 border-[#6C6F7F]/10",
    classname
  );
  return (
    <div className={classes}>
      <div className='flex items-center justify-center space-x-3'>
        <div className='w-[32px] h-[32px] lg:w-[48px] lg:h-[48px] rounded-full bg-[#F5F5FE] flex items-center justify-center'>
          <IoCheckmarkSharp className='text-[18px] text-[#00DA16] font-bold' />
        </div>
        <div className='flex flex-col'>
          <Text
            as='p'
            classname='text-[14px] lg:text-[20px] text-[#2C3048] font-semibold'
          >
            {referenceId ?? "626668777"}
          </Text>
          <Text as='span' classname='text-xs text-[#00DA16]'>
            {status ?? "Success"}
          </Text>
        </div>
      </div>
      <div className='flex flex-col items-end'>
        <Text as='p' classname='text-[14px] lg:text-[20px] text-[#6C6F7F]'>
          ₦ {getFormattedPrice(amount) ?? "₦500,0000"}
        </Text>
        <Text as='span' classname='text-xs text-[#9799A5] whitespace-nowrap'>
          {date ?? "Oct. 5, 2022"}
        </Text>
      </div>
    </div>
  );
}
