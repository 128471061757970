import { FaFacebookF, FaInstagramSquare, FaLinkedin, FaTwitter } from "react-icons/fa";
import * as IMG from "./images";
// export const affiliateClinet = [
//   "ALLSAINTS-ASGC1",
//   "BALOGUN-KETU1",
//   "CHARTER-COURT",
//   "CHARTERCOURT1",
//   "COLINDALE-SURULERE1",
//   "CORALREEF-EAAS1",
//   "CRESTING-STAR1",
//   "FBILLS-ALCOVE1",
//   "FBILLS-LAGOS1",
//   "FBILLS-MAPLE_ESTATE2",
//   "FBILLS-WESTMONT1",
//   "FBILLS-LAGOS1",
//   "FBILLS-MAPLE_ESTATE2",
//   "FBILLS-WESTMONT1",
//   "FIELDCO-FRANKFORTEHEIGHT2",
//   "FILMORE-RCTERRACES1",
//   "GATEPASS-FIRST1",
//   "GINJAR-IBADAN1",
//   "GINJAR-IKOTA1",
//   "GINJAR-SUJIMOTOR1",
//   "GINJAR-TEST2",
//   "GINJAR-TEST4",
//   "IKOTA1",
//   "JOHN-MADUEKE1",
//   "KACHI-LEKKI1",
//   "KLIMMAT-ABUJA1",
//   "KNOTANDGEAR",
//   "KNOTANDGEAR-IKATE1",
//   "LANDWEY-EASTAMBER1",
//   "LANDWEY-URBANPRIME3",
//   "LANDWEY-EASTAMBER1",
//   "LANDWEY-URBANPRIME3",
//   "LANDWEY-WALTONGATE1",
//   "LEKKIGARDENS-PHASE3",
//   "LOL-JJJJ1",
//   "MAXMIGOLD-SUJIMOTOR1",
//   "MAXMIGOLD-TESTTTT1",
//   "MUSTAPHA-UPUTU1",
//   "NERC-NG1",
//   "NEWJERSEYWARRI-NEWJERSEYWARRI1",
//   "OWELLINKSO-GAZELLE1",
//   "PRIMEWALTERS-IKATE1",
//   "PROVIDENCECOURT-AGUNGI1",
//   "QFA-ADMIRA",
//   "QFA-ADMIRAS",
//   "QFA-ADMIRASS",
//   "QFA-OVERHEADTANK2",
//   "SAIMETERS-YABATECHDIESEL1",
//   "SAIMETERS-YABATECHEKEDC1",
//   "SERVICEFLAT-LEKKI1",
//   "STANBIC-AJOSE",
//   "TERRA-OLIVIACOURT11",
//   "TPDC-LEKKI1",
//   "URBANPRIME-OGOMBO1",
//   "URBAN_PRIME-ESTATE2",
//   "VESSELNET-AJANAVILLAGE1",
//   "WATTWORTH-ENERGY1",
//   "WATTWORTH-LAGOS1",
//   "WHITEHOUSECOURT-LAGOS1",
//   "X-TEND-LEKKI1",
// ];
export const affiliateClinet = [
  {
    id: 0,
    img: IMG.charter,
  },
  {
    id: 1,
    img: IMG.knot,
  },
  {
    id: 2,
    img: IMG.landwey,
  },
  {
    id: 3,
    img: IMG.sujimoto,
  },
  {
    id: 4,
    img: IMG.terre,
  },
  {
    id: 5,
    img: IMG.ifitness,
  },
  {
    id: 6,
    img: IMG.stanbic,
  },
  {
    id: 7,
    img: IMG.owel,
  },
  {
    id: 8,
    img: IMG.watt,
  },
  // {
  //   id: 9,
  //   img: IMG.lekki,
  // },
  // {
  //   id: 10,
  //   img: IMG.charter,
  // },
  // {
  //   id: 11,
  //   img: IMG.landwey,
  // },
 
];
export const blogContent = [
  {
    id: 0,
    title:
      "The Costly Oversight: Why Real Estate Developers Must Prioritize Energy Auditors in Generator Sizing",
    img: IMG.collab,
    subtitle: "hello",
    paragraph:
      "hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter",
  },
  {
    id: 1,
    title: "hello world",
    img: IMG.collab,
    subtitle: "hello",
    paragraph:
      "hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter",
  },
  {
    id: 2,
    title: "hello world",
    img: IMG.collab,
    subtitle: "hello",
    paragraph:
      "hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter",
  },
  {
    id: 3,
    title: "hello world",
    img: IMG.collab,
    subtitle: "hello",
    paragraph:
      "hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter",
  },
  {
    id: 4,
    title: "hello world",
    img: IMG.collab,
    subtitle: "hello",
    paragraph:
      "hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter",
  },
  {
    id: 5,
    title: "hello world",
    img: IMG.collab,
    subtitle: "hello",
    paragraph:
      "hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter",
  },
  {
    id: 6,
    title: "hello world",
    img: IMG.collab,
    subtitle: "hello",
    paragraph:
      "hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter hello prepaid meter",
  },
];

export const footerData = [
  {
    id: 1,
    page: "Service",
    path: "/",
  },
  {
    id: 2,
    page: "About us",
    path: "/about",
  },
  {
    id: 1,
    page: "FAQs",
    path: "/contact",
  },
];

export const sheduleProperty = [
  "1 to 50",
  "51 to 100",
  "101 to 150",
  "151 to 200",
];
export const Country = ["Nigeria", "Ghana"];

export const AboutData = [
  {
    title: "Prepaidmeter.ng - Powering Transparency in Energy Consumption",
    paragraph:"Prepaidmeter.ng is a trailblazing startup dedicated to transforming the energy landscape in Nigeria through cutting-edge solutions across energy metering, smart grids, minigrids, and comprehensive energy management.",
    image: IMG.saveEnergy,
  },
  {
    // title: "Vision & Mission",
    paragraph:'Prepaidmeter.ng is a trailblazing startup dedicated to transforming the energy landscape in Nigeria through cutting-edge solutions across energy metering, smart grids, minigrids, and comprehensive energy management.',
    content: [
      {
        "Unmatched accuracy and reliability":"Our meters ensure precise and dependable measurement of energy consumption, empowering informed decision-making for individuals and businesses.",
        "Real-time data transparency:":"We go beyond basic readings, offering real-time data that empowers customers to understand their energy usage patterns and identify areas for cost savings and optimization.",
        "Advanced features at an accessible price point:":" Our meters boast a range of advanced features, including remote monitoring, tamper detection, and prepayment functionalities, all designed to be accessible and affordable for the Nigerian market.",
      },
    ],
    image: IMG.chart,
  },
  {
    // title: "Our Solution",
    paragraph:"By leveraging Prepaidmeter.ng's state-of-the-art solutions, individuals, businesses, and communities can:",
    content: [
      {
        "Gain greater control over energy expenses:":"Our meters provide the data and insights needed to make informed choices about energy usage, leading to significant cost savings and improved budgeting.",
        "Embrace responsible energy consumption:":"By fostering a culture of energy awareness and efficiency, our solutions contribute to a greener future by reducing overall energy consumption and environmental impact.",
        "Empower informed decision-making:": "Our prepayment functionalities empower users to manage their energy expenses proactively, eliminating surprises and promoting financial well-being.",
        "Visitor Access:": "Ensure secure and convenient access management.",
      },
    ],
    image: IMG.chart,
  },
  {
    paragraph:'Prepaidmeter.ng is committed to staying at the forefront of the Nigerian energy sector. We continuously innovate and develop solutions that address the evolving needs of our customers, making energy consumption transparent, affordable, and sustainable for all.'
  }
];


export const Social =[
  {
    id:1,
    icon: <FaTwitter className="text-[24px] text-white hover:text-secondary-dark" />,
    path:'https://twitter.com/PrepaidmeterNg'
  },
  {
    id:2,
    icon:  <FaInstagramSquare className="text-[24px] text-white hover:text-secondary-dark" />,
    path:'https://www.instagram.com/prepaidmeter.ng/'
  },
  {
    id:3,
    icon:  <FaFacebookF className="text-[24px] text-white hover:text-secondary-dark" />,
    path:'https://twitter.com/PrepaidmeterNg'
  },
  {
    id:3,
    icon:   <FaLinkedin className="text-[24px] text-white hover:text-secondary-dark" />,
    path:'https://www.linkedin.com/company/iobotech/'
  },
]
