import Checkbox from "../../../components/check-box";
import Text from "../../../components/text";

export const reset_user_data = Array.from({ length: 20 }).map((data, i) => ({
  _id: i + 1,
  name: "Oloko Opeyemi ",
  apartment: "Landwey Estate Lekki 1",
  meterid: `5232464252${i}`,
  meter_type: `ENERGY`
}));

export const reset_user_column = [

  {
    id: 'userid',
    // The header can use the table's getToggleAllRowsSelectedProps method
    // to render a checkbox
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <div className="flex flex-row gap-3">
        <Checkbox {...getToggleAllRowsSelectedProps()} />
        <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
          Select all
        </Text>
      </div>
    ),
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    Cell: ({ row }) => (
      <div>
        <Checkbox
          {...row.getToggleRowSelectedProps()} />
      </div>
    ),
  },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Name
      </Text>
    ),
    Cell: ({ value, row }) => <Text as="p" classname='text-left text-[16px]'>
      {value}
    </Text>,
    accessor: "fullname",
  },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Apartment
      </Text>
    ),
    // Cell: ({ value, row }) => <Text as="p">
    //     {value}
    // </Text>,
    accessor: "apartment",
  },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        MeterID
      </Text>
    ),
    //     Cell: ({ value, row }) => <Text as="p">
    //     {value}
    // </Text>,
    accessor: "meterid",
  },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Meter Type
      </Text>
    ),
    Cell: ({ value, row }) => <Text as="p" classname=' uppercase text-left text-[16px]'>
      {value}
    </Text>,

    accessor: "type",
  },

];
