import React from "react";
import { clx } from "../utils/clx";
import Text from "./text";

export default function ActivityCard({
  bg,
  iconBg,
  description,
  time,
  classname,
  children,
}) {
  const classes = clx("flex items-center space-x-2", bg, classname);
  return (
    <div className={classes}>
      <div className={`w-[40px] h-[40px] rounded-[2px] ${iconBg}`}></div>
      <div>
        <Text as='p' weight='semibold' classname='text-[12px]'>
          5232464252 Prepaid Energy was recharged
        </Text>
        <Text as='p' classname='text-[10px] text-[#434743]'>
          Oct 31, 2022, 18:30
        </Text>
      </div>
    </div>
  );
}
