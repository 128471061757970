import React, { useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const BillingCard = ({ billing, onDelete, onEdit }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDelete = () => {
    onDelete(billing.id);
  };

  return (
    <div className="p-4 border rounded-md shadow-sm mb-4">
      <div className="flex justify-between">
        <h3 className="text-xl font-bold">{billing.type === 'fixed' ? 'Fixed Billing' : 'Installment Billing'}</h3>
        <div className="space-x-2">
          <button onClick={() => onEdit(billing)} className="text-blue-500"><FaEdit /></button>
          <button onClick={() => setConfirmDelete(true)} className="text-red-500"> <FaTrashAlt /></button>
        </div>
      </div>
      <p><strong>Name:</strong> {billing.name}</p>
      <p><strong>Amount:</strong> {billing.amount}</p>
      <p><strong>Start Date:</strong> {billing.startDate}</p>
      {billing.type === 'fixed' ? (
        <>
          <p><strong>Frequency:</strong> {billing.frequency}</p>
          <p><strong>Unit Type:</strong> {billing.unitType}</p>
        </>
      ) : (
        <p><strong>End Date:</strong> {billing.endDate}</p>
      )}
      {confirmDelete && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
            <p>Are you sure you want to delete this billing?</p>
            <div className="flex justify-end space-x-4 mt-4">
              <button onClick={() => setConfirmDelete(false)} className="py-2 px-4 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded-md">Cancel</button>
              <button onClick={handleDelete} className="py-2 px-4 bg-red-500 hover:bg-red-600 text-white rounded-md">Delete</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const BillingForm = ({ billing, type, onSubmit, onClose }) => {
  const [name, setName] = useState(billing?.name || '');
  const [amount, setAmount] = useState(billing?.amount || '');
  const [frequency, setFrequency] = useState(billing?.frequency || 'daily');
  const [startDate, setStartDate] = useState(billing?.startDate || '');
  const [unitType, setUnitType] = useState(billing?.unitType || 'bungalow');
  const [installments, setInstallments] = useState(billing?.installments || [{ amount: '', startDate: '' }]);

  const handleInstallmentChange = (index, field, value) => {
    const newInstallments = [...installments];
    newInstallments[index][field] = value;
    setInstallments(newInstallments);
  };

  const handleAddInstallment = () => {
    setInstallments([...installments, { amount: '', startDate: '' }]);
  };

  const handleRemoveInstallment = (index) => {
    const newInstallments = installments.filter((_, i) => i !== index);
    setInstallments(newInstallments);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const billingData = {
      id: billing?.id || Math.random().toString(36).substr(2, 9),
      type,
      name,
      amount,
      startDate,
      ...(type === 'fixed' ? { frequency, unitType } : { installments }),
    };
    onSubmit(billingData);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">{type === 'fixed' ? 'Fixed Billing' : 'Installment Billing'}</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Total Amount</label>
            <input
              type="number"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Start Date</label>
            <input
              type="date"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </div>
          {type === 'fixed' ? (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Frequency</label>
                <select
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                  value={frequency}
                  onChange={(e) => setFrequency(e.target.value)}
                  required
                >
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Unit Type</label>
                <select
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                  value={unitType}
                  onChange={(e) => setUnitType(e.target.value)}
                  required
                >
                  <option value="bungalow">Bungalow</option>
                  <option value="1 bedroom">1 Bedroom</option>
                  <option value="2 bedrooms">2 Bedrooms</option>
                  <option value="3 bedrooms">3 Bedrooms</option>
                  <option value="duplex">Duplex</option>
                  <option value="all">All</option>
                </select>
              </div>
            </>
          ) : (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Installments</label>
                {installments.map((installment, index) => (
                  <div key={index} className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Installment {index + 1}</label>
                    <div className="flex space-x-4">
                      <input
                        type="number"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Amount"
                        value={installment.amount}
                        onChange={(e) => handleInstallmentChange(index, 'amount', e.target.value)}
                        required
                      />
                      <input
                        type="date"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500"
                        value={installment.startDate}
                        onChange={(e) => handleInstallmentChange(index, 'startDate', e.target.value)}
                        required
                      />
                      {index > 0 && (
                        <button
                          type="button"
                          onClick={() => handleRemoveInstallment(index)}
                          className="py-2 px-4 bg-red-500 hover:bg-red-600 text-white rounded-md shadow-sm transition duration-150"
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={handleAddInstallment}
                  className="py-2 px-4 bg-green-500 hover:bg-green-600 text-white rounded-md shadow-sm transition duration-150"
                >
                  Add Installment
                </button>
              </div>
            </>
          )}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="py-2 px-4 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded-md shadow-sm transition duration-150"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md shadow-sm transition duration-150"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};



const BillingApp = () => {
  const [billingType, setBillingType] = useState(null);
  const [billings, setBillings] = useState([]);
  const [currentBilling, setCurrentBilling] = useState(null);

  const handleAddBilling = (billing) => {
    if (currentBilling) {
      setBillings(billings.map(b => b.id === currentBilling.id ? billing : b));
    } else {
      setBillings([...billings, billing]);
    }
    setBillingType(null); // Close the form after adding or editing
    setCurrentBilling(null); // Reset the current billing
  };

  const handleDeleteBilling = (id) => {
    setBillings(billings.filter(billing => billing.id !== id));
  };

  const handleEditBilling = (billing) => {
    setBillingType(billing.type);
    setCurrentBilling(billing);
  };

  return (
    <div className="p-4">
      {billingType ? (
        <BillingForm
          type={billingType}
          billing={currentBilling}
          onSubmit={handleAddBilling}
          onClose={() => {
            setBillingType(null);
            setCurrentBilling(null);
          }}
        />
      ) : (
        <div className="space-x-4 mb-4">
          <button onClick={() => setBillingType('fixed')} className="py-2 px-4 bg-green-500 hover:bg-green-600 text-white rounded-md">Add Fixed</button>
          <button onClick={() => setBillingType('installment')} className="py-2 px-4 bg-yellow-500 hover:bg-yellow-600 text-white rounded-md">Add Installment</button>
        </div>
      )}
      {billings.map((billing) => (
        <BillingCard
          key={billing.id}
          billing={billing}
          onDelete={handleDeleteBilling}
          onEdit={handleEditBilling}
        />
      ))}
    </div>
  );
};

export default BillingApp;
