import React from "react";

import PaymentTab from "./tabs";
import { clx } from "../../../../utils/clx";
import VendHistory from "./vend";
import ChargesHistory from "./charges";

export default function PaymentHistory() {
    const [activeTab, setActiveTab] = React.useState(1);

    const classes = clx(
        "w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex flex-col space-y-[40px]"
    );
    return (
        <div className={classes}>

            <PaymentTab activeTab={activeTab} setTab={setActiveTab} />
            <div>
                {activeTab === 1 &&
                    <VendHistory />
                }
                {activeTab === 2 && <>
                    <ChargesHistory />
                </>}
            </div>
        </div>
    );
}