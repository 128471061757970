import React from "react";
import { clx } from "../utils/clx";

export default function ProfilePicture({ url, classname, children }) {
  const [selectedPicture, setSelectedPicture] = React.useState(null);
  const imageRef = React.useRef();

  const handleChange = () => {
        setSelectedPicture(imageRef.current.files[0]?.name);
  };

  const classes = clx(
    "w-[128px] h-[128px] rounded-full ring-2 ring-offset-4 ring-primary-dark overflow-hidden relative group",
    classname
  );

  return (
    <div className={classes}>
      <img
        src={url?? '/assets/images/profile-img.png'}
        alt='profile-img'
        className='w-full h-full object-fill'
      />
      <div
        className='absolute inset-0 bg-black/20 hover:bg-black/40 min-w-full min-h-full rounded-full cursor-pointer flex items-center justify-center'
        onClick={() => imageRef.current.click()}
      >
        <img
          src='/assets/icons/camera-icon.svg'
          alt='camera-icon'
          className='w-[54px] h-[48px]'
        />
        <input
          ref={imageRef}
          type='file'
          name='profile-img'
          id='profile-img'
          onChange={handleChange}
          hidden
        />
      </div>
    </div>
  );
}
