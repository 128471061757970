import React, { useCallback } from "react";
import Table from "../../../../../components/table";
import { invoices_column, invoices_data } from "../../../../../utils/table-utils/admin/invoices-table";
import Radio from "../../../../../components/radio";
import useAdminUserList from "../../../../../hooks/apis/admin/useAdminUserList";
import { clx } from "../../../../../utils/clx";

export default function Invoices() {
    const [selectedType, setSelectedType] = React.useState("all");
    const [filteredList, setFilteredList] = React.useState([]);


    const { data: payoutData, isLoading: isPayoutLoading, isFetching: isPayoutFetching } = useAdminUserList("payout");
    const loadMeters = useCallback(() => {
        setFilteredList([])

        payoutData?.data?.length && payoutData?.data?.map((item) => {
            const { payout } = item
            const isPaid = selectedType?.toLowerCase() === "paid"
            if ((isPaid && payout === true) || (!isPaid && payout === false)) {
                setFilteredList((prev) => [...prev, {
                    fullname: item?.user?.fullname,
                    apartment: item?.user?.apartment,
                    amount: item?.amount,
                    paid: isPaid ? "Paid" : "Unpaid",

                    date: item?.dateCreated,
                    purpose: item?.payout_slug,
                    payoutDate: item?.payoutDate 

                }])
            }

        });
    }, [selectedType, payoutData]);

    React.useEffect(() => {
        loadMeters()
    }, [loadMeters])

    const handleSelectType = useCallback((e) => {
        setSelectedType(e.target.value)
    }, [])

    React.useEffect(() => {
        setSelectedType("all")
    }, [])

    // const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");

    return (
        <div>

            <div className="flex justify-end gap-4 p-3">
                <Radio
                    name='filter-type'
                    label='All'
                    classname='text-[16px]'
                    checked={selectedType === "all"}
                    value="all"
                    onChange={handleSelectType}
                    onclick={handleSelectType}
                />
                <Radio
                    name='filter-type'
                    label='Paid'
                    classname='text-[16px]'
                    checked={selectedType === "paid"}
                    value="paid"
                    onChange={handleSelectType}
                    onclick={handleSelectType}

                />
                <Radio
                    name='filter-type'
                    label='Unpaid'
                    classname='text-[16px]'
                    checked={selectedType === "unpaid"}
                    value="unpaid"
                    onChange={handleSelectType}
                    onclick={handleSelectType}
                />
            </div>
            <div className="max-w-full h-full rounded-[8px] bg-white p-6 flex flex-col space-y-6 relative">

                <Table
                    data={filteredList ?? []}
                    columns={invoices_column}
                    isLoading={isPayoutLoading || isPayoutFetching}
                />


            </div>
        </div>
    );
}