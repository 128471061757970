import React from 'react'
import Text from '../../text'
import DownloadCSV from './download-csv';
import Button from '../../button';
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from '../../input';
import { AiOutlineBarcode } from 'react-icons/ai';
import Radio from '../../radio';
import { TbCurrencyNaira } from 'react-icons/tb';
import axios from 'axios';
import { useAuthUser } from 'react-auth-kit';
import { toast } from 'react-toastify';
import { CgCommunity } from 'react-icons/cg';

export default function CreditMeterModal({ selectedRows, selectedType, modalType, setModalType }) {
    const [validationAttempt, setValidationAttempt] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [radioState, setRadioState] = React.useState("no");
    const [vendWith, setVendWith] = React.useState("naira");
    const [data, setData] = React.useState([]);
    const authUser = useAuthUser();
    const onSubmit = () => {
        setIsLoading(true);
        setValidationAttempt(false);
        const meterid = selectedRows.map((item) => item.original.meterid);
        axios.post("https://pm-server.herokuapp.com/api/v2.0/credit-meter", {
            amounts: formik.values.amounts,
            meters: meterid,
            remotely: radioState,
            vendWith: vendWith,
            prepaid: selectedType
        }, {
            headers: {
                Authorization: authUser().authToken,
                _rootKey: formik.values._rootKey,
            },
        }).then(res => {
            // console.log(res)
            setIsLoading(false)
            setData(res.data.data)
            setModalType("csv")
            toast.success(res.data.message)

        }).catch(err => {
            // console.log(err)
            setIsLoading(false)
            toast.error(err.response.data.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const formValues = {
        amounts: "",
        _rootKey: "",

    };
    const validationSchema = Yup.object({
        amounts: Yup.number().required("amount is required"),
        _rootKey: Yup.string().required("Root Key is required"),
    });
    const formik = useFormik({
        initialValues: formValues,
        onSubmit,
        validationSchema,
        validateOnBlur: false,
        validateOnChange: validationAttempt,
    });
    return (
        <React.Fragment>
            {modalType === "credit" &&

                <form onSubmit={formik.handleSubmit}>
                    <Text as="h3" weight="bold" classname="text-[24px] lg:text-[30px] text-center">
                        Credit Meter
                    </Text>
                    <div className="my-2 flex flex-col space-y-2">
                        <Input
                            iconLeft={vendWith === "naira" ? <TbCurrencyNaira /> : <CgCommunity />}
                            label="Amount:"
                            placeholder="Enter Amount"
                            type="number"
                            name="amounts"
                            value={formik.values.amounts}
                            onchange={(e) => formik.handleChange(e)}
                            onblur={formik.handleBlur}
                            errorMessage={formik.errors.amounts}
                        />
                        <div>
                            <Text as="h3" weight="semi-bold" classname="text-[18px] text-left">
                                Confirm Remotely
                            </Text>
                            <div className="flex flex-row space-x-6">
                                <Radio
                                    name="confirm-remotely"
                                    label='YES'
                                    classname='text-[16px]'
                                    value="yes"
                                    checked={radioState === "yes"}
                                    onchange={({ target }) => setRadioState(target.value)}
                                    onclick={({ target }) => setRadioState(target.value)}
                                />
                                <Radio
                                    name='confirm-remotely'
                                    label='NO'
                                    classname='text-[16px]'
                                    value="no"
                                    checked={radioState === "no"}
                                    onchange={({ target }) => setRadioState(target.value)}
                                    onclick={({ target }) => setRadioState(target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <Text as="h3" weight="semi-bold" classname="text-[18px] text-left">
                                Vend with
                            </Text>
                            <div className="flex flex-row space-x-6">
                                <Radio
                                    name="vend-with"
                                    label='Naira'
                                    classname='text-[16px]'
                                    value="naira"
                                    checked={vendWith === "naira"}
                                    onchange={({ target }) => setVendWith(target.value)}
                                    onclick={({ target }) => setVendWith(target.value)}
                                />
                                <Radio
                                    name='vend-with'
                                    label='Unit'
                                    classname='text-[16px]'
                                    value="unit"
                                    checked={vendWith === "unit"}
                                    onchange={({ target }) => setVendWith(target.value)}
                                    onclick={({ target }) => setVendWith(target.value)}
                                />
                            </div>
                        </div>
                        <Input
                            iconLeft={<AiOutlineBarcode />}
                            label="Root Key:"
                            placeholder="Enter Root Key"
                            type="text"
                            name="_rootKey"
                            value={formik.values._rootKey}
                            onchange={(e) => formik.handleChange(e)}
                            onblur={formik.handleBlur}
                            errorMessage={formik.errors._rootKey}
                        />

                    </div>
                    <Button buttonType="submit" classname="w-full" isLoading={isLoading}>
                        Credit Meter
                    </Button>

                </form>


            }
            {modalType === "csv" && <DownloadCSV data={data} screen='credit' title='Credit-Meter Response' />}
        </React.Fragment>
    )
}