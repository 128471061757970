import React from "react";
import Text from "../../../../components/text";
import useAdminRank from "../../../../store/admin/adminRank";


export default function AffiliateSettlementsTab({ activeTab, setTab }) {

  const { adminRank } = useAdminRank()
  console.log(adminRank); 

  return (
    <div className='flex space-x-[50px]'>
      {["DETAILS", "SET DETAILS", "SETTLEMENTS"].map((tab, index) => {
        if (index !== 0 && adminRank < 5) {
          return
        }
        return (
          <Text
            key={index}
            as='p'
            weight='semibold'
            classname={`text-[#6C6F7F] lg:text-[20px] py-2 border-[#6C6F7F] tracking-wider ${activeTab === index + 1 && "border-b-2"
              }`}
            onclick={() => setTab(index + 1)}
          >
            {tab}
          </Text>
        )
      })}
    </div>
  );
}