export const getFormattedTime = (time) => {
      const hour = Number(time.split(':')[0])
      const formattedHour = hour===12? 12: hour % 12
      if (hour >=0 && hour<=11) {
            if (formattedHour === 0) {
                  return '12am'
            }
            return `${formattedHour}am`
      }
      return `${formattedHour}pm`
}