import Text from "../../components/text";
import { clx } from "../../utils/clx";
import FAQSection from "./components/faq-section";
import ContactForm from "./components/contact-form";
import AddressSection from "./components/address-section";
import React from "react";

export default function ContactPageWrapper() {
  const classes = clx(
    "w-[93%] lg:w-[90%] lg:py-[100px] py-6 flex flex-col mx-auto ",
  );
  React.useEffect(() => {
    window.scrollTo(0, 0);
    
  }, []);
  return (
    <div>
      <div className={classes}>
        {/* hero */}
        <div className="flex flex-col gap-8 items-center lg:flex-row justify-between  h-full  lg:max-h-[100vh]">
          {/* left side */}
          <div className="flex flex-col lg:max-w-[700px] gap-4 lg:space-y-8">
            <Text as="h1" weight="medium" classname="
          text-2xl text-center lg:text-left md:text-4xl lg:text-5xl xl:text-6xl leading-8">
              Get in touch
              with us
            </Text>
            <Text as="p" weight="normal" classname="text-base md:text-[20px] text-[#6C6F7F]">
              We have made assest monitory easy for you!
            </Text>

          </div>

          {/* right side */}

          <div className="w-full lg:mr-10 lg:w-auto">
            {/* form */}

            <ContactForm />

          </div>
        </div>
      </div>
      {/* address */}
      <div className={classes}>
        <AddressSection />
      </div>
      {/* Faq section */}
      <FAQSection />

    </div>
  );
}