import React from 'react';
import Button from '../../../../../components/button';
import ModalWrapper from '../../../../../components/modals/modal-wrapper';
import EditPeriodic from '../../../../../components/modals/admin/user-charges/edit-periodic';
import { useMemo } from 'react';
import ExceptionalModal from '../../../../../components/modals/admin/user-charges/exceptional-modal';




export default function PeriodicActions({ value, row }) {
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [modalType, setModalType] = React.useState("");
    const isExceptional = useMemo(() => {
        return row.original.exceptional
    }, [row.original.exceptional])
    const handleButtonClick = (type) => {
        setModalType(type);
        setModalOpen(true);
    };
    return (
        <React.Fragment>
            <div className="flex items-center space-x-6">
                {/* <FiLock color="gray" /> */}
                <Button
                    variant="text"
                    classname=" w-fit  text-secondary-dark"
                    onclick={() => handleButtonClick("edit")}
                >
                    Edit
                </Button>
                {
                    isExceptional === true ? (
                        <Button
                            variant="text"
                            classname=" w-fit  text-secondary-dark"
                            onclick={() => handleButtonClick("activate")}
                        >
                            Activate
                        </Button>
                    ) : (
                        <Button
                            variant="text"
                            classname=" w-fit  text-secondary-dark"
                            onclick={() => handleButtonClick("deactivate")}
                        >
                            Deactivate
                        </Button>
                    )
                }

            </div>

            <ModalWrapper
                isOpen={isModalOpen}
                handleModalClose={() => setModalOpen(false)}
            >
                {modalType === "edit" && <>
                    <EditPeriodic data={row.original} />
                </>}
                {modalType === "activate" && <>
                <ExceptionalModal data={row.original} modalType={modalType} query="periodic-opt-in" />
                </>}
                {modalType === "deactivate" && <>
                <ExceptionalModal data={row.original} modalType={modalType} query="periodic-opt-out" />
                </>}
            </ModalWrapper>
        </React.Fragment>
    );
}