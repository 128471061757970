import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { AiOutlineApartment } from "react-icons/ai";
import Text from "../../../../components/text";
import Input from "../../../../components/input";
import Button from "../../../../components/button";
import useAuthV2 from "../../../../store/admin/authKey";
import { useQuery, useQueryClient } from "react-query";



export default function MeterStatusEdit({ data }) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { authv2, setAuthV2, setAuthAndCookie } = useAuthV2();
  const queryClient = useQueryClient();
  
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const formValues = {
    status: data.status,  
  };
  const validationSchema = Yup.object({
    status: Yup.string().required("Apartment is Required"),
  });

  const onSubmit = (values) => {
    setIsLoading(true);

    axios
      .put(
        `https://api-pm-e1ea777feac4.herokuapp.com/metering/modify-request-status?_id=${data._id}`,
        values,
        { headers: { Authorization: authv2 } }
      )
      .then((res) => {
        console.log(res)
        // queryClient.invalidateQueries('request-meter');
        queryClient.refetchQueries('request-meter', { force: true });
        toast.success(res.data.message);
        navigate('/manager/configure');
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'An error occurred');
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  const statusOfMeter = ["PROCESSING", "DELIVERED", "FAILED", "PENDING"];

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <Text
          as="h3"
          weight="bold"
          classname="text-[24px] lg:text-[30px] text-center"
        >
          Edit Status
        </Text>
        <div className="my-6 flex flex-col space-y-6">
          <select
            className="border-none bg-gray-200 outline-none rounded-[6px] py-[15px] px-[15px]"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.status}
            required
            name="status"
          >
            <option value="" disabled>
              Change Status
            </option>
            {statusOfMeter.map((stats, index) => (
              <option value={stats} key={index}>
                {stats}
              </option>
            ))}
          </select>
        </div>
        <Button
          isLoading={isLoading}
          //   onclick={() => setValidationAttempt(true)}
          buttonType="submit"
          classname="w-full"
        >
          Edit
        </Button>
      </div>
    </form>
  );
}
