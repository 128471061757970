import React from "react";
import Input from "../../../components/input";
import Text from "../../../components/text";
import { BsSearch } from "react-icons/bs";
import { useAuthUser } from "react-auth-kit";
import useAdminAffiliates from "../../../store/admin/affiliates";
import { io } from "socket.io-client";
import { getTruncatedText } from "../../../utils/getTruncatedText";

export default function ChatSidebar({ setSelectedChat }) {
  return (
    <div className="flex flex-col w-[300px] h-[100%] lg:h-[calc(100vh-120px)] lg:mt-[20px] overflow-hidden">
      <div>
        <Text as="h3" weight="semibold" classname="lg:text-[28px]">
          Messages
        </Text>
        <div className="mt-6">
          {/* <Input
            iconLeft={<BiSearch className="text-[20px]"/>}
            variant='outlined'
            placeholder='search here'
            inputClass='py-[8px]'
          /> */}
          <Input
            iconLeft={<BsSearch />}
            placeholder="Search User here"
            variant="white"
            inputClass="py-[7px] px-[15px] rounded-r-0"
            // onchange={({ target }) => setSearch(target.value)}
          />
        </div>
      </div>
      <div className="flex-1 py-[40px] overflow-y-auto scrollbar-thin scrollbar-thumb-primary-dark scrollbar-thumb-rounded-lg pr-4 flex flex-col space-y-6"></div>
    </div>
  );
}

const ChatCard = ({ img, name, onclick, lastMessage }) => {
  return (
    <div
      className="flex items-center justify-between cursor-pointer"
      onClick={onclick}
    >
      <div className="flex items-center space-x-4">
        <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
          <img src={img} alt="" className="object-fill" />
        </div>
        <div>
          <Text as="h5" weight="semibold">
            {name}
          </Text>
          <Text as="p" classname="text-gray-500 w-[120px] truncate">
            {getTruncatedText(lastMessage, 25)}
          </Text>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <Text as="h5" classname="text-gray-500">
          Dec 08
        </Text>
        <span className="w-[10px] h-[10px] rounded-full bg-black text-white flex items-center justify-center text-[8px] p-2">
          10
        </span>
      </div>
    </div>
  );
};
