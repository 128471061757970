import React from "react";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { TbCurrencyNaira } from "react-icons/tb";
import Button from "../../components/button";
import Input from "../../components/input";
import Radio from "../../components/radio";
import Text from "../../components/text";
import AuthLayout from "../../layouts/auth-layout";
import { clx } from "../../utils/clx";

export default function WalletRechargePageWrapper() {
  const [isRemoteComplied, setRemoteComplied] = React.useState(false);

  return (
    <AuthLayout>
      <div className='w-full'>
        <div className='w-full h-full flex space-x-[30px]'>
          <div className='flex-1 h-full flex flex-col justify-center'>
            <Text as='p' classname='font-[600] text-[64px] text-primary-dark'>
              Wallet Recharge
            </Text>
            <Text
              as='p'
              weight='medium'
              classname='text-[24px] text-primary-dark'
            >
              Sign in into your account
            </Text>
            <WalletRechargePageWrapper.SampleInfoContainer />
          </div>
          <div className='flex-1 flex justify-end items-center'>
            <div className='bg-[#F8F8FF] border-[1px] rounded-[16px] w-full py-[50px] px-[70px]'>
              <div className='flex flex-col space-y-6'>
                <Input
                  iconLeft={<FaEnvelope className='text-[#9799A5]' />}
                  placeholder='Email Address'
                />
                <Input
                  iconLeft={
                    <TbCurrencyNaira className='text-[#9799A5] text-[20px]' />
                  }
                  placeholder='Amount'
                />
                <div className='pb-[30px]'>
                  <div className='flex items-center justify-between'>
                    <Text as='span' weight='semibold' classname='text-[18px]'>
                      Remotely?
                    </Text>
                    <div className='flex space-x-3'>
                      <Radio
                        name='confirm-remotely'
                        label='Yes'
                        onclick={() => setRemoteComplied(true)}
                      />
                      <Radio
                        name='confirm-remotely'
                        label='No'
                        onclick={() => setRemoteComplied(false)}
                      />
                    </div>
                  </div>
                  <div className='my-1'>
                    <Text as='p' classname='text-[12px] text-[#9799A5]'>
                      Yes - your meter will be recharged remotely
                    </Text>
                    <Text as='p' classname='text-[12px] text-[#9799A5]'>
                      No - A token will be sent to you
                    </Text>
                  </div>
                </div>
                <Button
                  classname='w-full'
                  disabled={isRemoteComplied === false ? true : null}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export function SampleInfoContainer({ classname, children }) {
  const classes = clx(
    "bg-secondary-light p-6 px-8 rounded-[8px] flex items-center space-x-[40px] my-[40px] w-fit",
    classname
  );
  return (
    <div className={classes}>
      <div className='flex flex-col space-y-1'>
        <Text as='p' classname='text-primary-dark'>
          Meter Id
        </Text>
        <Text
          as='span'
          classname='border-2 border-primary-dark/50 text-primary-dark rounded-[4px] w-fit p-1 px-4'
        >
          5232464252
        </Text>
      </div>

      <div className='flex flex-col space-y-1'>
        <Text as='p' classname='text-primary-dark'>
          Name
        </Text>
        <Text
          as='span'
          classname='bg-secondary-dark text-primary-dark rounded-[4px] w-fit p-1 px-4'
        >
          Vincent Kagbodavid
        </Text>
      </div>
    </div>
  );
}
WalletRechargePageWrapper.SampleInfoContainer = SampleInfoContainer;
