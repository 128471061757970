import React from "react";
import Text from "../../text";
import Input from "../../input";
import Button from "../../button";
import { useFormik } from "formik";
import * as Yup from "yup";
// import banks from "../../../utils/table-utils/banks";
import { toast } from "react-toastify";
import { useAuthUser } from "react-auth-kit";
import useAdminAffiliates from "../../../store/admin/affiliates";
import axios from "axios";
import useAccountResolution from "../../../hooks/apis/resolveAccount";
import Radio from "../../radio";

export default function PayoutFormDetails({ setModalType, data, query }) {
    const [validationAttempt, setValidationAttempt] = React.useState(false);
    const [selectedReceiverBank, setSelectedReceiverBank] = React.useState("");
    const [selectedSenderBank, setSelectedSenderBank] = React.useState("");
    const [senderBankName, setSenderBankName] = React.useState("");
    const [receiverBankName, setReceiverBankName] = React.useState("");
    const [receiver, setReceiver] = React.useState("default");
    const [banks, setBanks] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const { selectedAffiliate } = useAdminAffiliates();
    const authUser = useAuthUser();

    // resolve accounts
    const { resolvedAccountName: resolvedSenderAccountName, resolveAccountName: resolveSenderAccountName,
        isFetching: isFetchingSenderAccountName, isError: errorSenderAccountName,
        setResolvedAccountName: setResolvedSenderAccountName
    } = useAccountResolution();
    const { resolvedAccountName: resolvedReceiverAccountName, resolveAccountName: resolveReceiverAccountName,
        isFetching: isFetchingReceiverAccountName, isError: errorReceiverAccountName,
        setResolvedAccountName: setResolvedReceiverAccountName
    } = useAccountResolution();
    const formValues = {
        txn_id: "",
        // sender_bank_name: "", 
        sender_account_number: "",
        receiver_account_number: "",
        // receiver_bank_name: "",
    };
    const validationSchema = Yup.object({
        txn_id: Yup.string().required("Transaction ID is required"),
        // sender_bank_name: Yup.string().required(" Bank Name is required"),
        sender_account_number: Yup.number().required(" Account Number is required"),
        receiver_account_number: receiver === "default" && Yup.number().required(" Account Number is required"),
        // receiver_bank_name: Yup.string().required("Bank Name is required"),
    });


    // /////////////////////////////////////////////////////////
    const onSubmit = (values) => {
        setValidationAttempt(false);
        setIsLoading(true)
        const baseUrl = "https://pm-server.herokuapp.com/api/v2.0/manager/settle-payout";
        const headers = {
            Authorization: authUser().authToken,
        };

        let payload = {};

        if (query === "default") {
            payload = {
                ...values,
                sender_bank_name: senderBankName,
                receiver_bank_name: receiverBankName,
                sender_account_name: resolvedSenderAccountName,
                receiver_account_name: resolvedReceiverAccountName,
                ids: data,
                tag: selectedAffiliate,
                receiver: receiver,
            };
        } else if (query === "date-range") {
            payload = {
                ...values,
                ...data,
                sender_bank_name: senderBankName,
                receiver_account_name: resolvedReceiverAccountName,
                receiver_bank_name: receiverBankName,
                sender_account_name: resolvedSenderAccountName,
                tag: selectedAffiliate,
                receiver: receiver,
            };
        }
        axios
            .post(`${baseUrl}?query=${query}`, payload, { headers }).then(res => {
                toast.success(res.data.message)
                setModalType("success")

            }).catch(err => {
                toast.error(err.response.data.message)
            }).finally(() => {
                setIsLoading(false)
            }
            )
    }

    const formik = useFormik({
        initialValues: formValues,
        onSubmit,
        validationSchema,
        validateOnBlur: false,
        validateOnChange: validationAttempt,
    });
    React.useEffect(() => {
        const fetchbanks = async () => {
            try {
                const response = await axios.get(
                    "https://pm-server.herokuapp.com/api/v2.0/bank-codes"
                );
                // console.log(response.data.data);
                setBanks(response.data.data);
            } catch (error) {
                // console.log(error);
            }
        }
        fetchbanks()
    }, [])
    React.useEffect(() => {
        if (formik.values.receiver_account_number && selectedReceiverBank) {
            resolveReceiverAccountName(
                formik.values.receiver_account_number,
                selectedReceiverBank
            );
        }
    }, [formik.values.receiver_account_number, selectedReceiverBank]);

    React.useEffect(() => {
        if (formik.values.sender_account_number && selectedSenderBank) {
            resolveSenderAccountName(
                formik.values.sender_account_number,
                selectedSenderBank
            )
        }

    }, [formik.values.sender_account_number, selectedSenderBank]);

    React.useEffect(() => {
        if (errorSenderAccountName) {
            toast.error(errorSenderAccountName);
        }
        if (errorReceiverAccountName) {
            toast.error(errorReceiverAccountName);
        }
    }, [errorSenderAccountName, errorReceiverAccountName]);
    return (
        <form onSubmit={formik.handleSubmit}>
            <div>
                <Text as="h3" weight="bold" classname="text-[24px] lg:text-[30px] text-center">
                    Payout Details
                </Text>
                <div className="flex flex-col space-y-6">
                    <Input
                        label="Transaction ID:"
                        placeholder="1234567890"
                        type="text"
                        name="txn_id"
                        value={formik.values.txn_id}
                        onchange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorMessage={formik.errors.txn_id}
                        disabled={isLoading}
                    />
                    <div className="flex flex-row w-full items-center  gap-2">
                        <Input
                            label="Sender Account Name:"
                            placeholder="John Doe"
                            type="text"
                            name="sender_account_name"
                            value={isFetchingSenderAccountName ? "Please wait..." : resolvedSenderAccountName}
                            disabled={true}
                        />
                        <Input
                            label="Sender Account Number:"
                            placeholder="1234567890"
                            type="number"
                            disabled={isLoading}
                            name="sender_account_number"
                            value={formik.values.sender_account_number}
                            onchange={(e) => {
                                setSelectedSenderBank("")
                                formik.setFieldValue("sender_bank_name", "")
                                setResolvedSenderAccountName("")
                                formik.handleChange(e)
                            }}
                            onBlur={formik.handleBlur}
                            errorMessage={formik.errors.sender_account_number}
                        />
                    </div>

                    <select
                        value={formik.values.sender_bank_name}
                        name="sender_bank_name"
                        onChange={(e) => {
                            formik.handleChange(e)
                            setSelectedSenderBank(e.target.value)
                            const bankName = banks.findIndex((bank) => bank.code === e.target.value)
                            setSenderBankName(banks[bankName].name)
                        }}
                        onBlur={formik.handleBlur}
                        disabled={isLoading}
                        required
                        className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
                    >
                        {formik.values.sender_account_number ? (
                            <>
                                <option value="">Select Sender Bank</option>
                                {banks.map((bank) => (
                                    <option key={bank.id} value={bank.code} name={bank.name}>
                                        {bank.name}
                                    </option>
                                ))}
                            </>

                        ) : (
                            <option value="">
                                Please enter a valid account number
                            </option>
                        )}

                    </select>
                    <div className="flex gap-x-6 items-center">
                        {/* Radio button for using existing account details */}
                        <Radio
                            label="Use existing account details"
                            name="checkbox"
                            value="details"
                            onchange={() => setReceiver("details")}
                            onclick={() => setReceiver("details")}
                            checked={receiver === "details"}
                            classname="text-[12px] text-gray-400 mt-6"
                        />

                        {/* Radio button for entering new receiver details */}
                        <Radio
                            label="Enter new receiver details"
                            name="checkbox"
                            value="default"
                            onchange={() => setReceiver("default")}
                            onclick={() => setReceiver("default")}
                            checked={receiver === "default"}
                            classname="text-[12px] text-gray-400 mt-6"
                        />
                    </div>
                    {receiver === "default" && (
                        <>
                            <div className="flex flex-row w-full items-center  gap-2">
                                <Input
                                    label="Receiver Account Name:"
                                    placeholder="John Doe"
                                    type="text"
                                    name="receiver_account_name"
                                    value={isFetchingReceiverAccountName ? "Please wait..." : resolvedReceiverAccountName}
                                    disabled={true && receiver === "details"}

                                />
                                <Input
                                    label="Receiver Account Number:"
                                    placeholder="1234567890"
                                    type="number"
                                    name="receiver_account_number"
                                    value={formik.values.receiver_account_number}
                                    onchange={
                                        (e) => {
                                            setSelectedReceiverBank("")
                                            formik.setFieldValue("receiver_bank_name", "")
                                            setResolvedReceiverAccountName("")
                                            formik.handleChange(e)
                                        }
                                    }
                                    onBlur={formik.handleBlur}
                                    errorMessage={formik.errors.receiver_account_number}
                                    disabled={receiver === "details" && isLoading}
                                />
                            </div>
                            <select
                                value={formik.values.receiver_bank_name}
                                name="receiver_bank_name"
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    setSelectedReceiverBank(e.target.value)
                                    const bankName = banks.findIndex((bank) => bank.code === e.target.value)
                                    setReceiverBankName(banks[bankName].name)
                                }}
                                onBlur={formik.handleBlur}
                                disabled={receiver === "details" && isLoading}
                                required={receiver === "default"}
                                className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
                            >
                                {formik.values.receiver_account_number ? (
                                    <>
                                        <option value="">Select Receiver Bank</option>
                                        {banks.map((bank) => (
                                            <option key={bank.id} value={bank.code} name={bank.name}>
                                                {bank.name}
                                            </option>
                                        ))}
                                    </>

                                ) : (
                                    <option value="">
                                        Please enter a valid account number
                                    </option>
                                )}
                            </select>
                        </>
                    )}
                    <Button
                        isLoading={isLoading}
                        buttonType="submit"
                        onclick={() => setValidationAttempt(true)}
                        classname="w-full"

                    >
                        Payout
                    </Button>
                </div>
            </div>
        </form>
    );
}
