import React, { useMemo } from "react";
import Text from "../../text";
import Input from "../../input";
import Button from "../../button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TbCurrencyNaira, TbPercentage } from "react-icons/tb";
import axios from "axios";
import useAdminAffiliates from "../../../store/admin/affiliates";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

export default function EditRegularCharges({ onButtonClick, data }) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { selectedAffiliate } = useAdminAffiliates();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const isPercentage = useMemo(() => {
    return data.charges_slug === "percentage" ? true : false;
  }, [data.charges_slug,])
  const formValues = {
    name: data?.name,
    amount: data?.amount,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Charges Name is Required"),
    amount: isPercentage
      ? Yup.string().required("Amount is required").matches(
        /^([1-9]\d{0,1}(\.\d{1,2})?|100(\.0{1,2})?)$/,
        "Invalid percentage format"
      )
      : Yup.number().required("Amount is required"),

  });
  const onSubmit = (values) => {
    setIsLoading(true);
    // console.log(values);
    setValidationAttempt(false);
    axios.put("https://pm-server.herokuapp.com/api/v2.0/charges-proxy?query=edit", {
      ...values,
      tag: selectedAffiliate,
      charges_slug: data.charges_slug,
      insight_slug: data.insight_slug,
      type: data.type,
    }, {
      headers: {
        Authorization: authUser().authToken,
      },
    }).then(res => {
      // console.log(res)
      setIsLoading(false)
      navigate('/manager/configure/charges')
      toast.success(res.data.message)

    }).catch(err => {
      // console.log(err)
      setIsLoading(false)
      toast.error(err.response.data.message)

    }).finally(() => {
      setIsLoading(false)
    })
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <Text
          as="h3"
          weight="bold"
          classname="text-[24px] lg:text-[30px] text-center"
        >
          Edit Charges
        </Text>
        <div className="my-6 flex flex-col space-y-6">
          <Input
            label="Charge Name:"
            type="text"
            name="name"
            placeholder={data?.name}
            defaultValue={data?.name}
            value={formik.values.name}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.name}
          />
          {isPercentage ? (
            <Input
              iconLeft={<TbPercentage />}
              label="Charge Amount:"
              type="number"
              name="amount"
              defaultValue={data?.amount}
              placeholder={data?.amount}
              value={formik.values.amount}
              onchange={(e) => formik.handleChange(e)}
              onblur={formik.handleBlur}
              errorMessage={formik.errors.amount}
            />
          ) : (
            <Input
              iconLeft={<TbCurrencyNaira />}
              label="Charge Amount:"
              type="number"
              name="amount"
              defaultValue={data?.amount}
              placeholder={data?.amount}
              value={formik.values.amount}
              onchange={(e) => formik.handleChange(e)}
              onblur={formik.handleBlur}
              errorMessage={formik.errors.amount}
            />
          )}
        </div>
        <Button buttonType="submit" isLoading={isLoading} classname="w-full">
          Edit
        </Button>
      </div>
    </form>
  );
}
