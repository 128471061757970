import React from "react";
import { BiSearch } from "react-icons/bi";
import Input from "../../components/input";
import Text from "../../components/text";
import { clx } from "../../utils/clx";
import { walletTransactionHistoryColumns } from "../../utils/table-utils/user/columns/wallet-transaction-history";
import useWalletInfo from "../../hooks/apis/user/useWalletInfo";
import Table from "../../components/table";

export default function WalletTransactionsPageWrapper() {
  const transaction_columns = React.useMemo(
    () => walletTransactionHistoryColumns,
    []
  );
  const { data: walletInfo } = useWalletInfo({
    key: "walletTransaction",
    query: "transactions",
  });

  const [search, setSearch] = React.useState("");

  React.useEffect(() => window.scrollTo(0, 0));

  const classes = clx(
    "w-[93%] max-w-[93%] lg:w-[90%] lg:max-w-[90%] overflow-hidden mx-auto"
  );
  return (
    <div className={classes}>
      <Text
        as='h5'
        weight='semibold'
        classname='text-[18px] lg:text-[28px] py-[25px]'
      >
        Transaction History
      </Text>
      <Input
        value={search}
        onchange={({ target }) => setSearch(target.value)}
        iconLeft={<BiSearch className='text-[#7D8195] text-[20px]' />}
        placeholder='Please type your question here.'
        inputClass='bg-[#F5F5FE] w-[70%] mx-auto my-[20px]'
      />
      <div className='py-[30px] overflow-auto'>
        <Table
          search={search}
          columns={transaction_columns}
          data={walletInfo?.data}
        />
        {/* <div className='w-[700px] max-w-[700px] lg:w-[100%] lg:max-w-[100%]'>
          <WalletTransactionHistoryTable tableInstance={tableInstance} />
        </div> */}
      </div>
    </div>
  );
}
