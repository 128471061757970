import React from "react";
import BalanceCard from "../../components/balance-card";
import MeterIDContainer from "../../components/meterid-container";
import Filter from "../../components/modals/filter";
import ModalWrapper from "../../components/modals/modal-wrapper";
import Text from "../../components/text";
import { clx } from "../../utils/clx";
import FuelConsumptionChart from "./components/charts/fuel-consumption-chart";

export default function FuelDashboardWrapper({ classname }) {

  const [isModalOpen, setModalOpen] = React.useState(false);
  const [filteredChart, setFilteredChart] = React.useState(null);

  React.useEffect(() => window.scrollTo(0, 0));
  const classes = clx(
    "w-[93%] max-w-[93%] lg:w-[90%] lg:max-w-[90%] mx-auto py-[50px]",
    classname
  );
  return (
    <React.Fragment>
      <div className={classes}>
        <div>
          <Text
            as='p'
            classname='text-center text-[#6C6F7F] p-3 px-6 rounded-[4px] bg-[#E6E6E9] w-fit mx-auto'
          >
            Expire Date: 14th Dec.2021
          </Text>
        </div>
        <div className='py-[30px]'>
          <div className=' flex flex-col-reverse lg:flex-col'>
            <div>
              <Text
                as='p'
                weight='semibold'
                classname='text-[#6C6F7F] lg:text-[25px]'
              >
                Machine Status
              </Text>
              <div className='max-w-[100%] overflow-auto'>
                <div className='max-w-full rounded-[12px] bg-[#E6E6FB] h-fit my-[30px] p-4 lg:p-8 flex flex-col lg:flex-row lg:items-center lg:justify-between lg:space-x-[20px] space-y-6 lg:space-y-0'>
                  <div className='grid grid-cols-2 lg:flex items-center lg:space-x-[100px]'>
                    <div className='flex items-center space-x-4'>
                      <div className='w-[40px] h-[40px] rounded-[4px] bg-[#4F7694]/30 flex items-center justify-center'>
                        <img
                          src='/assets/icons/grid-icon.png'
                          alt='price-icon'
                        />
                      </div>
                      <Text
                        as='span'
                        classname='text-[#6C6F7F] text-[12px] lg:text-[20px]'
                      >
                        Grid
                      </Text>
                    </div>
                    <div className='flex items-center space-x-4'>
                      <div className='w-[70px] h-[40px] rounded-[8px] bg-primary-dark flex items-center justify-center'>
                        <Text
                          as='span'
                          weight='semibold'
                          classname='text-white'
                        >
                          ON
                        </Text>
                      </div>
                      <Text
                        as='span'
                        classname='text-[#6C6F7F] text-[12px] lg:text-[20px]'
                      >
                        2hrs, 5mins
                      </Text>
                    </div>
                  </div>
                  <div className='lg:block border-b-2 lg:border-b-0 lg:border-l-2 w-[100%] lg:w-fit lg:h-[60px] border-[#6C6F7F]/10' />
                  <div className='grid grid-cols-2 lg:flex items-center lg:space-x-[100px]'>
                    <div className='flex items-center space-x-4'>
                      <div className='w-[40px] h-[40px] rounded-[4px] bg-[#4F7694]/30 flex items-center justify-center'>
                        <img
                          src='/assets/icons/generator-icon.png'
                          alt='price-icon'
                        />
                      </div>
                      <Text
                        as='span'
                        classname='text-[#6C6F7F] text-[12px] lg:text-[20px]'
                      >
                        Generator
                      </Text>
                    </div>
                    <div className='flex items-center space-x-4'>
                      <div className='w-[70px] h-[40px] rounded-[8px] bg-white flex items-center justify-center'>
                        <Text
                          as='span'
                          weight='semibold'
                          classname='text-primary-dark'
                        >
                          OFF
                        </Text>
                      </div>
                      <Text
                        as='span'
                        classname='text-[#6C6F7F] text-[12px] lg:text-[20px]'
                      >
                        1hrs, 1mins
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex items-center space-x-6 my-[30px] lg:my-0 mb-[50px] lg:mb-0 lg:mt-[50px] '>
              <Text as='span'>Service id</Text>
              <MeterIDContainer useFor='generator' />
            </div>
          </div>
          <div className='py-[50px] flex lg:flex-row items-center lg:space-y-0 space-x-3 lg:space-x-6'>
            <BalanceCard
              platform='IoT'
              title='Fuel Level'
              variant='tertiary'
              classname='flex-1 md:flex-none w-full lg:w-fit'
              noPrice
            />
            <BalanceCard
              platform='IoT'
              title='Fuel Consumption'
              variant='tertiary'
              classname='flex-1 md:flex-none w-full lg:w-fit'
              noPrice
            />
            <BalanceCard
              platform='IoT'
              title='Consumed Today'
              variant='tertiary'
              classname='flex-1 md:flex-none w-full lg:w-fit'
              noPrice
            />
          </div>

          <FuelConsumptionChart
            handleFilteredChart={setFilteredChart}
            onFilterClick={() => setModalOpen(true)}
            classname='my-[30px] lg:my-[50px]'
          />

          <div className=''>
            <div className='flex items-center justify-between py-[20px]'>
              <Text
                as='span'
                weight='medium'
                classname='lg:text-[23px] text-[#6C6F7F]'
              >
                Grid/Generator History
              </Text>
              {/* <Text
              as='span'
              weight='medium'
              classname='text-[23px] text-secondary-dark'
            >
              see all
            </Text> */}
            </div>
            <div className='py-[30px] flex flex-col space-y-6'>
              {/* <EnergyTransactionCard classname='w-[100%]' />
            <EnergyTransactionCard classname='w-[100%]' />
            <EnergyTransactionCard classname='w-[100%]' /> */}
            </div>
          </div>
        </div>
      </div>

      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => setModalOpen(false)}
      >
        <Filter
          handleModalClose={() => setModalOpen(false)}
          route={
            filteredChart === "bar"
              ? "/user/fuel-statistics"
              : "/user/fuel-statistics-area"
          }
        />
      </ModalWrapper>
    </React.Fragment>
  );
}
