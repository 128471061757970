import React, { useMemo, useState } from "react";
import Text from "../../text";
import Multiselect from "multiselect-react-dropdown";
import { configureItems } from "../../../constants/admin-configureItems";
import Button from "../../button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { toast } from "react-toastify";

export default function HideFeatures({ data }) {
    const [isLoading, setIsLoading] = useState(false)
    const [features, setFeatures] = useState([])
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const hideFeatures = useMemo(() => {
        const value = data?.features.hide
        setFeatures(value)
        return value
    }, [data])
    // console.log(data)
    const options = useMemo(() => {
        const rank = {
            tenant: 0,
            administrator: 1,
            super: 2,
        }[data.role];
        return configureItems.filter(item =>
            item.rank <= rank
        ).map(item => {
            return { title: item.title, path: item.path }
        })

    }, [configureItems])


    const handleHide = () => {
        setIsLoading(true)
        axios.put("https://pm-server.herokuapp.com/api/v2.0/manager/actions?query=hide-features", {
            features: features,
            adminid: data.adminid
        }, {
            headers: {
                Authorization: authUser().authToken,
            },
        }).then(res => {
            // console.log(res)
            setIsLoading(false)
            navigate('/manager/configure/admin-management')
            toast.success(res.data.message)
        }).catch(err => {
            // console.log(err)
            setIsLoading(false)
            toast.error(err.response.data.message)
        }).finally(() => {
            setIsLoading(false)
        })

    }
    return (
        <div>
            <Text as="h3"
                weight="bold"
                classname="text-[24px] lg:text-[30px] text-center">
                Update Features
            </Text>

            <div className="flex flex-col  space-y-6">
                <Text as="p" weight="normal" classname="text-left text-secondary-dark italic">
                    Any selected feature will be hidden from the manager
                </Text>
                <Multiselect
                    style={{
                        chips: {
                            background: "#b4b5b6",
                        },
                        searchBox: {
                            border: "none",

                        },
                        optionContainer: {
                            border: "none",
                            background: "#bbbbbb",
                        },
                        option: {
                            padding: "10px",
                            // border: "none",
                            color: "#010623",
                            background: "#fff",
                        },
                    }}
                    options={options}
                    selectedValues={hideFeatures}
                    showCheckbox
                    onSelect={setFeatures}
                    onRemove={setFeatures}
                    displayValue="title"
                    placeholder="Select Feature"
                    className="max-w-[400px] border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
                />
                <Button classname="w-full" isLoading={isLoading}
                    onclick={handleHide}
                >
                    Update Features
                </Button>
            </div>


        </div >
    );
}