import React from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import { EnergyTransactionHistoryColumns } from "../../../../utils/table-utils/user/columns/energy-transaction-history";
import useSelectedEnergyUser from "../../../../store/admin/selected-energy-user";
import useVendInfo from "../../../../hooks/apis/user/useVendInfo";
import { getSortedLastToStart } from "../../../../utils/getSortedLastToStart";
import { clx } from "../../../../utils/clx";
import Input from "../../../../components/input";
import Text from "../../../../components/text";
import Table from "../../../../components/table";
import useGetRecentTransaction, { getRecentTransaction } from "../../../../hooks/apis/admin/useRecentTransaction";
import useAdminAffiliateInfo from "../../../../hooks/apis/admin/useAdminAffiliateInfo";
import { useAuthUser } from "react-auth-kit";
import { useQuery } from "react-query";

export default function AffiliateChargesTransactions() {
  const transaction_columns = React.useMemo(
    () => EnergyTransactionHistoryColumns,
    []
  );


  //!
  // const { transact } = useGetRecentTransaction();
  // console.log(transact)
  const { selectedEnergyUser: user } = useSelectedEnergyUser();
  const affiliate = useAdminAffiliateInfo();
  const authUser = useAuthUser();

  const yourAdminId = user?.userid
  const authTransact = authUser().authToken

  const { data: tra } = useQuery('trac', () => getRecentTransaction(yourAdminId, authTransact));
  let transact = tra?.data?.data
  // console.log(transact)
  //!
  // const { data:  } = useWalletInfo({ action: "transactions" });
  // const { selectedEnergyUser: user } = useSelectedEnergyUser();
  const { data: transactionsInfo } = useVendInfo({
    prepaid: "energy",
   
  });
  const transactions = getSortedLastToStart(transactionsInfo?.data);

  const [search, setSearch] = React.useState("");

  React.useEffect(() => window.scrollTo(0, 0));

  const classes = clx(
    "w-[93%] max-w-[93%] lg:w-[90%] lg:max-w-[90%] overflow-hidden mx-auto"
  );
  return (
    <div className={classes}>
      {/* <div className='flex items-center space-x-6'>
        <MdArrowBackIosNew className='text-[#9799A5] text-[20px] cursor-pointer' />
        <Text as='span' classname='text-[#9799A5]'>
          Back to Prepaid Energy Dashboard
        </Text>
      </div> */}
      <Text
        as="h5"
        weight="semibold"
        classname="text-[18px] lg:text-[28px] py-[25px]"
      >
        Transaction History
      </Text>
      <Input
        value={search}
        onchange={({ target }) => setSearch(target.value)}
        iconLeft={<BiSearch className="text-[#7D8195] text-[20px]" />}
        placeholder="Please type your question here."
        inputClass="bg-[#F5F5FE] w-[70%] mx-auto my-[20px]"
      />
      <div className="py-[30px]">
        <Table
          search={search}
          columns={transaction_columns}
          data={transact}
          // data={transactions}
        />
        {/* <div className='w-[700px] max-w-[700px] lg:w-[100%] lg:max-w-[100%]'>
          <EnergyTransactionHistoryTable tableInstance={tableInstance} />
        </div> */}
      </div>
    </div>
  );
}
