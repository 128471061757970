import React from 'react'
import { useQuery } from 'react-query';
import { supabase } from '../../../views/static/supabse';

export const useAdminBlog = () => {
    const { data, isLoading, isError, refetch, isFetching } = useQuery('blog', async () => {
        try {
          const { data, error } = await supabase.from('blog_posts').select();
      
          if (error) {
            throw new Error(error.message);
          }
      
          return data;
        } catch (error) {
          console.error('Error fetching blog posts:', error);
          throw error; 
        }
      });
  return { data, isLoading, isError, refetch, isFetching }
}

export const useSingleAdminBlog = (id) => {
    return useQuery(['singleBlog', id], async () => {
      try {
        const { data, error } = await supabase
          .from('blog_posts')
          .select()
          .eq('id', id)
          .single();
  
        if (error) {
          throw new Error(error.message);
        }
  
        return data;
      } catch (error) {
        console.error('Error fetching blog post:', error);
        throw error;
      }
    });
  };