import React, { useState } from "react";
import GatePasssAccessTabs from "./tabs";
import VisitorsDetails from "./steps/visitors-details";
import Time from "./steps/time";
import AccessCode from "./steps/access-code";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { format } from "date-fns";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMutation } from "react-query";

export default function GatePasssAccess() {
  const [currentStep, setCurrentStep] = useState(1);
  const [ code ,setCode] =useState('')
  const steps = ["Visitor Details", "Time", "Access code"];
  const authUser = useAuthUser();

  const mutation = useMutation(
    (dataToSend) => {
      return axios.post(
        'https://api-pm-e1ea777feac4.herokuapp.com/gatepass',
        { ...dataToSend },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authUser().authToken,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        const { access_code } = data.data.data;
        console.log(data.data);
        console.log(access_code);
        setCurrentStep(3);
        setCode(access_code);
      },
      onError: (error) => {
        console.error(error);
        console.error(error.response.data);
        console.error(error.response.data.message);
        toast(error.response.data.message);
      },
    }
  );

  const onSubmit = () => {
    const time = format(new Date(), 'yyyy-MM-dd');
    const combinedDateTime = `${time} ${formik.values.time}`;
    const dataToSend = {
      fullname: formik.values.fullname,
      email: formik.values.email,
      maximum: formik.values.maximum,
      expiresin: combinedDateTime,
      category: formik.values.category,
      gender: formik.values.gender,
      phonenumber: formik.values.phonenumber,
    };

    console.log(dataToSend);

    mutation.mutate(dataToSend);
  };

  
  const today = new Date();
  today.setHours(23, 59, 59, 999);
  const VisitorsDetailSchema = Yup.object({
    fullname: Yup.string()
    .required('Please enter your fullname')
    .matches(/^[a-zA-Z]+ [a-zA-Z]+$/, 'Fullname must contain only alphabets and have at least one space')
    .min(5, 'Fullname length must be at least 5 characters long'), 
    gender: Yup.string().required("Gender is required"),
    email: Yup.string().email("Invalid email").optional(),
    phonenumber: Yup.number().optional(),
    maximum: Yup.number().integer().positive().required("Number of People required"),
    category: Yup.string().required("Category is required"),
    time: Yup
    .date()
    .transform((value, originalValue) => {
      if (originalValue && typeof originalValue === 'string') {
        const [hours, minutes] = originalValue.split(':');
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        return date;
      }
      return value;
    })
    .required('Time is required'),
  });

  const formik = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      phonenumber: "",
      gender: "",
      // expiresin:''
    },
    validationSchema: VisitorsDetailSchema,
    onSubmit: onSubmit,
  });

  console.log(formik.values);

  const displaySteps = (step) => {
    switch (step) {
      case 1:
        return (
          <VisitorsDetails
            onButtonClick={() => {
              setCurrentStep(2);
            }}
            formik={formik}
          />
        );
      case 2:
        return <Time onButtonClick={mutation.isLoading} formik={formik} onSubmit={onSubmit} />;
      case 3:
        return (
          <AccessCode onButtonClick={() => setCurrentStep(4)} formik={formik} code={code}/>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="flex py-[88px] flex-col gap-6 rounded-lg mx-auto p-[50px] md:px-[100px] w-full max-w-[787px] bg-[#f8f8f8] shadow-sm">
        <GatePasssAccessTabs steps={steps} currentStep={currentStep} />
        <div className="flex flex-col gap-6">
          {displaySteps(currentStep)}{" "}
        </div>
      </div>
    </div>
  );
}
 // { 
        //   fullname: string;
        //   email?: string;
        //   maximum: number|null;
        //   expiresin: Date|null;
        //   category: string;
        //   gender: string;
        //   phonenumber?: string;}
         // time: Yup.date().transform((value, originalValue) => {
    //   if (originalValue && typeof originalValue === 'string') {
    //     const [hours, minutes] = originalValue.split(':');
    //     const date = new Date();
    //     date.setHours(hours);
    //     date.setMinutes(minutes);
    //     return date;
    //   }
    //   return value;
    // }).required('Time is required'),
    // time: Yup.date().nullable()
    // time: Yup.date().min(today, "Entry time must be within today or later").required('Time is required')
    // time: Yup.string().required("Time is required")    
    // expiresin: Yup.date().default(() => new Date()),
        // entryDate: Yup.date().min(new Date(), "Entry date must be today or later").required('Date is required'),
    // time: Yup.date().min(new Date(), "Entry date must be today or later").required('Date is required'),
    // time: Yup.date()
    // .transform((value, originalValue) => {
    //   if (originalValue === '') return null;
    //   return value;
    // })
    // .nullable()
