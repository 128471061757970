import React from "react";
import axios from "axios";
// import { useLocation, useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
// import useUserInfo from "./useUserInfo";
import useUserStore from "../../../store/user/store";
import { useQuery } from "react-query";

export default function useWalletInfo({ key, query }) {
  const user = useUserStore((state) => state.user);
  const authUser = useAuthUser();

  //   const navigate = useNavigate();
  const { isLoading, data, isError, error, refetch } = useQuery(
    key ?? "WalletInfo",
    () =>
      axios.get(
        `https://api-pm-e1ea777feac4.herokuapp.com/wallet?query=${query}`,
      
        {
          headers: {
            Authorization: authUser().authToken,
            
          },
        }
      )
  );
  return { data: data?.data, isLoading, isError, refetch };
}
