import React from "react";
import { clx } from "../../../../utils/clx";
import AffiliateSettlementsTab from "./tabs";
import Details from "./details";
import SetDetails from "./set-details";
import InstantSettlments from "./instant-settlements";



export default function AffiliateSettlements() {
    const [activeTab, setActiveTab] = React.useState(1);

    const classes = clx(
        "w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex flex-col space-y-[40px]"
    );


    return (
        <div className={classes}>

            <AffiliateSettlementsTab activeTab={activeTab} setTab={setActiveTab} />
            <div>
                {activeTab === 1 && <Details />}
                {activeTab === 2 && <SetDetails />}
                {activeTab === 3 && <InstantSettlments />}
               
            </div>
        </div>
    );
}