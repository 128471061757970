import Button from "../../../../components/button";
import Text from "../../../../components/text";
import ReactStars from "react-rating-stars-component";

export default function Reviews() {
  const ratingChanged = (newRating) => {
    console.log(newRating);
    // setRateChange(newRating);
  };
  return (
    <div className="flex flex-col gap-4 lg:space-y-10 mt-10">
      <div className="hidden lg:flex  lg:items-center  lg:gap-32">
        <div>
          <Text
            as="h1"
            weight="bold"
            classname="lg:text-5xl md:text-3xl text-2xl text-primary-light"
          >
            4.2 out of 5
          </Text>
          <div className="flex space-x-2 items-center">
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={22}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
            />
            <Text as="p" classname=" text-[#6C6F7F]">
              10 Reviews
            </Text>
          </div>
          <div className="flex gap-8 mt-7">
            <Button
              type="tertiary"
              size="small"
              classname="border  border-primary-light"
            >
              see all reviews
            </Button>
            <Button type="primary" size="small" classname="">
              write a review
            </Button>
          </div>
        </div>
        <div>
          <div className="flex items-center space-x-3">
            <Text as="p" classname=" text-[#6C6F7F]">
              5 stars
            </Text>
            <div class="w-[214px] h-1 bg-gray-300 rounded-full">
              <div class="w-1/2 h-full bg-primary-dark rounded-full"></div>
            </div>
            <Text as="p" classname=" text-[#6C6F7F]">
              4
            </Text>
          </div>
          <div className="flex items-center space-x-3">
            <Text as="p" classname=" text-[#6C6F7F]">
              5 stars
            </Text>
            <div class="w-[214px] h-1 bg-gray-300 rounded-full">
              <div class="w-1/3 h-full bg-primary-dark rounded-full"></div>
            </div>
            <Text as="p" classname=" text-[#6C6F7F]">
              3
            </Text>
          </div>
          <div className="flex items-center space-x-3">
            <Text as="p" classname=" text-[#6C6F7F]">
              4 stars
            </Text>
            <div class="w-[214px] h-1 bg-gray-300 rounded-full">
              <div class="w-1/3 h-full bg-primary-dark rounded-full"></div>
            </div>
            <Text as="p" classname=" text-[#6C6F7F]">
              2
            </Text>
          </div>
          <div className="flex items-center space-x-3">
            <Text as="p" classname=" text-[#6C6F7F]">
              2 stars
            </Text>
            <div class="w-[214px] h-1 bg-gray-300 rounded-full">
              <div class="w-0 h-full bg-primary-dark rounded-full"></div>
            </div>
            <Text as="p" classname=" text-[#6C6F7F]">
              1
            </Text>
          </div>
        </div>
      </div>
      <hr className="hidden lg:block" />
      <div className="flex flex-col lg:flex-row items-start lg:items-center w-full gap-6 lg:gap-32">
        <Text as="h3" weight="bold" classname="block lg:hidden text-left">
          Verified Customer feedback
        </Text>
        <ReviewCard />
        <ReviewCard />
      </div>
    </div>
  );
}

export const ReviewCard = () => {
  const ratingChanged = (newRating) => {
    console.log(newRating);
    // setRateChange(newRating);
  };
  return (
    <div className="px-[21px] py-[19px] flex-grow space-y-7 bg-[#e6e6e9] rounded-lg">
      <div className="flex items-center justify-between">
        <div className="flex space-x-2 items-center">
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={22}
            isHalf={true}
            emptyIcon={<i className="far fa-star"></i>}
            halfIcon={<i className="fa fa-star-half-alt"></i>}
            fullIcon={<i className="fa fa-star"></i>}
            activeColor="#ffd700"
          />
          <Text as="p" classname=" text-[#6C6F7F]">
            10 Reviews
          </Text>
        </div>
        <Text as="p" classname=" text-[#6C6F7F]">
          8/8/2023
        </Text>
      </div>
      <Text as="p" classname=" text-[#6C6F7F]">
        Good monitor. Setup was intuitive and easy. Looks nice on my desk. I
        just have to figure out how to adjust the color of the screen. Excellent
        monitor interfaces easily with my NVIDIA Graphics cards
      </Text>
      <Text as="p" classname=" text-[#6C6F7F]">
        Name of product here
      </Text>
    </div>
  );
};
