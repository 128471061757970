import React from "react";
import { clx } from "../../utils/clx";
import Text from "../../components/text";
import { useFormik } from "formik";
import Input from "../../components/input";
import Picker from "../../components/Picker";
import Button from "../../components/button";
import { validationSchemaReferrer } from "./buy-meter/buy-meter-validation";
import { useMutation } from "react-query";
import axios from "axios";
import { toast } from "react-toastify";
import ModalWrapper from "../../components/modals/modal-wrapper";
import * as IMG from "./../../images";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import CopyText from "../../components/toast/copy-text";
const ReferrrerSignup = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const classes = clx("w-[93%] lg:w-[90%] flex flex-col mx-auto pt-8");
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    // code: "",
  };

  const mutation = useMutation(
    async (values) => {
      const res = await axios.post(
        "https://api-pm-e1ea777feac4.herokuapp.com/guest/add-referral",
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data.message);
        setIsModalOpen(true);
        handleReset();
        // Handle success state here
      },
      onError: (error) => {
        toast.error(error.response?.data?.message || "An error occurred");
      },
    }
  );
  React.useEffect(() => {
    if (mutation.isSuccess) {
      setIsModalOpen(true);
    }
  }, [mutation.isSuccess]);
  const {
    values,
    handleBlur,
    handleSubmit,
    handleReset,
    handleChange,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: validationSchemaReferrer,
    onSubmit: (values) => {
      mutation.mutate(values);
    },
  });

  console.log(mutation?.data?.data?.referralCode);
  return (
    <section className={classes}>
      <div className="flex flex-col md:flex-row items-center gap-10 mb-24">
        <div className="border-2 rounded-lg bg-secondary-dark w-full md:w-2/5 px-5 py-5 md:py-40">
          <Text
            as="h3"
            weight="bold"
            classname="text-[14px] md:text-lg text-center font-semubold"
          >
            Unlock incredible rewards by recommending our community to others.
          </Text>
          <div className="flex gap-2.5 items-center justify-center relative z-[1]">
            <Text as="span" weight="light" classname="text-sm text-white">
              Recommend a community or estate to us.
            </Text>
          </div>
        </div>
        <div className=" w-full md:w-3/5">
          <Text
            as="h2"
            weight="bold"
            classname="text-secondary-dark pt-3 mb-3 text-lg md:text-4xl"
          >
            Sign Up as A Referral
          </Text>
          <Text as="p">Fill out this short form to get started</Text>

          <form className="mt-5 space-y-5 w-full" onSubmit={handleSubmit}>
            <div className="flex flex-col lg:flex-row space-y-4 md:space-y-0 gap-0 md:gap-3 lg:gap-4 items-center w-full">
              <Input
                type="text"
                label="First Name *"
                name="firstname"
                // defaultValue={JSON.parse(getSchedule).FirstName}
                value={values.firstname}
                onchange={handleChange}
                onblur={handleBlur}
                errorMessage={touched.firstname && errors.firstname}
                placeholder="First Name"
              />
              <Input
                type="text"
                label="Last Name *"
                name="lastname"
                // defaultValue={JSON.parse(getSchedule).lastName}
                value={values.lastname}
                onchange={handleChange}
                onblur={handleBlur}
                errorMessage={touched.lastname && errors.lastname}
                placeholder="last Name"
              />
            </div>
            <Input
              type="text"
              // label="Community name *"
              name="email"
              // defaultValue={JSON.parse(getSchedule).email}
              value={values.email}
              onchange={handleChange}
              onblur={handleBlur}
              errorMessage={touched.email && errors.email}
              placeholder="Email"
            />
            <Input
              // classname="w-5/6"
              type="phonenumber"
              // label="Community name *"
              name="phonenumber"
              // defaultValue={JSON.parse(getSchedule).email}
              value={values.phonenumber}
              onchange={handleChange}
              onblur={handleBlur}
              errorMessage={touched.phonenumber && errors.phonenumber}
              placeholder="Phone Number"
            />

            <div className="flex gap-4">
              <input
                id="agree"
                type="checkbox"
                checked
                className="accent-light-green-500"
                value={true}
                onChange={() => {}}
              />
              <label htmlFor="agree">
                I agree with Iobotech Terms of Service and Privacy Policy.
              </label>
            </div>

            <Button
              buttonType="submit"
              isLoading={mutation.isLoading}
              classname="w-full text-center"
            >
              Sign Up
            </Button>
          </form>
        </div>
      </div>
      {isModalOpen && (
        <ModalWrapper
          isOpen={isModalOpen}
          handleModalClose={() => setIsModalOpen(false)}
        >
          <div className="flex  flex-col sm:flex-row items-center space-x-3">
            <div className="text-center">
              <img
                src="/assets/icons/verified-icon.png"
                alt="loading"
                className="object-cover"
              />
              <Text as="h3" classname="text-lg mt-2">{mutation?.data?.message}</Text>
            </div>
            <div className="flex gap-4 items-center">
              <Text as="span" classname="text-xl">
                {mutation?.data?.data?.referralCode}
              </Text>
              <CopyToClipboard
                text={mutation?.data?.data?.referralCode}
                onCopy={() =>
                  toast.success(
                    <CopyText>Referral code copied successfully!</CopyText>
                  )
                }
              >
                <BiCopy className="block text-black cursor-pointer text-xl" />
              </CopyToClipboard>
            </div>
          </div>
        </ModalWrapper>
      )}
    </section>
  );
};

export default ReferrrerSignup;

export const countryNumber = ["+234", "+235", "+236", "+237"];
