import React from "react";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useQuery } from "react-query";

export default function useUserServiceConfig({ key, prepaid }) {
  const authUser = useAuthUser();

  const { isLoading, data, isError, refetch } = useQuery(key, () =>
    axios.post(
      `https://api-pm-e1ea777feac4.herokuapp.com/services/user`,
      {
        type: "metering",
        product_slug: prepaid,
      },
      {
        headers: {
          Authorization: authUser().authToken,
        },
      }
    )
  );
  return { data: data?.data, isLoading, isError, refetch };
}
