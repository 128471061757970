import React from "react";

import ComingSoon from "../../views/static/components/coming-soon";

export default function CorporatePage() {
  return (
    <React.Fragment>
      <ComingSoon />
    </React.Fragment>
  );
}
