import React from "react";
import Button from "../../../../components/button";
import Input from "../../../../components/input";
import axios from "axios";
import { useQuery } from "react-query";
import * as Yup from "yup";
import Text from "../../../../components/text";

export default function Time({ onButtonClick, formik, onSubmit ,code}) {
  const { isLoading, error, data } = useQuery("data", () =>
    axios.get("https://api-pm-e1ea777feac4.herokuapp.com/gatepass/constant")
  );

  const handleClick = async () => {
    // Perform form validation here if needed
    const today = new Date();
today.setHours(23, 59, 59, 999);
    const VisitorsDetailSchema = Yup.object({
      maximum: Yup.number()
        .integer()
        .positive()
        .required("Amount of user is require"),
      category: Yup.string().required("Category is required"),
      // entryDate: Yup.date()
      //   .min(new Date(), "Entry date must be today or later")
      //   .required("Date is required"),
    //   time: Yup.date()
    // .transform((value, originalValue) => {
    //   if (originalValue === '') return null;
    //   return value;
    // })
    // .nullable()
    
    time: Yup.date().transform((value, originalValue) => {
      if (originalValue && typeof originalValue === 'string') {
        const [hours, minutes] = originalValue.split(':');
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        return date;
      }
      return value;
    }).required('Time is required'),
        // time: Yup.date().min(today, "Entry time must be within today or later").required('Time is required')
        // time: Yup.date().nullable()
        // time: Yup.date().min(new Date(), "Entry date must be today or later").required('Date is required'),
      // time: Yup.date().min(new Date(), "Entry date must be today or later").required('Date is required'),
      // time: Yup.string().required("Time is required"),
    });

    // Call the onButtonClick callback to move to the next step
    const match = await VisitorsDetailSchema.isValid(formik.values, {
      abortEarly: false,
    });

    console.log(match);

    if (!match) {
      return;
    } else{
      console.log("submit");
      console.log(formik.values);
      // Call the onButtonClick callback to move to the next step
      await onSubmit();
      onButtonClick();
    }
  };

  if (isLoading) {
    // Show a loading spinner or disable the form elements
    return (
      <div className="flex items-center justify-center h-full">
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    // Display an error message
    return (
      <div className="flex items-center justify-center h-full">
        <p>An error has occurred: {error.message}</p>
      </div>
    );
  }

  console.log(formik.errors);

  const { category } = data?.data?.data || [];

  return (
    <div className="flex flex-col justify-between mx-auto w-full space-y-6 max-w-[500px]">
      <Input
        label="Number of People"
        placeholder="Number of People"
        type="number"
        name="maximum"
        onchange={formik.handleChange}
        onblur={formik.handleBlur}
        errorMessage={formik.touched.maximum && formik.errors.maximum}
        value={formik.values.maximum}
      />
      <Input
        label="Time"
        placeholder="Enter Time"
        type="time"
        name="time"
        value={formik.values.time}
        onchange={formik.handleChange}
        onblur={formik.handleBlur}
        errorMessage={formik.errors.time}
      />
     
      <select
        name="category"
        id=""
        className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px] my-6 w-full"
        required
        type="number"
        onChange={formik.handleChange}
        onblur={formik.handleBlur}
        errorMessage={formik.touched.category && formik.errors.category}
        value={formik.values.category}
      >
        <option value="">Select Category</option>
        {category.map((categories, index) => (
          <option value={categories} key={index}>
            {categories}
          </option>
        ))}
      </select>
      {formik.errors.category && (
        <Text
          as="span"
          weight="medium"
          classname="text-[15px] lg:text-base text-red-600"
        >
          {formik.errors.category}
        </Text>
      )}

      <Button
        isLoading={onButtonClick}
        onclick={handleClick}
        disabled={
          formik.errors.maximum ||
          formik.errors.category ||
          formik.errors.time ||
          formik.errors.entryDate
            ? true
            : false
        }
      >
        Generate Access code
      </Button>
    </div>
  );
}


