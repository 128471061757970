import React, { useCallback } from "react"
import { useNavigate} from "react-router-dom"
import Table from "../../../../../components/table"
import { clx } from "../../../../../utils/clx"
import { affiliiate_services_insights_columns } from "../../../../../utils/table-utils/admin/afflilate-services-insights-table"
import Input from "../../../../../components/input"
import { BsSearch } from "react-icons/bs"
import Button from "../../../../../components/button"
import { IoAddOutline } from "react-icons/io5"
import { useAffiliateProducts } from "../../../../../store/admin/configure"
import ModalWrapper from "../../../../../components/modals/modal-wrapper"
import AffiliateInsightsActionModal from "../../../../../components/modals/admin/affiilate-insight-action-modal"
import { toast } from "react-toastify"
import { useAuthUser } from "react-auth-kit"
import useAdminAffiliates from "../../../../../store/admin/affiliates"
import axios from "axios"

export default function SercviceInsights() {
    const navigate = useNavigate()
    const { affiliateProducts } = useAffiliateProducts()
    const { selectedAffiliate } = useAdminAffiliates()
    const authUser = useAuthUser()
    const [search, setSearch] = React.useState()
    const [isModalOpen, setModalOpen] = React.useState(false)
    const [validationAttempt, setValidationAttempt] = React.useState(false)
    const [tableData, setTabledata] = React.useState([])
    const [radioState, setRadioState] = React.useState("yes")
    const [productData, setProductData] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)

    if (!affiliateProducts || affiliateProducts === null) {
        navigate('/manager/configure/affiliates/service')
    }
    const redirect = useCallback(() => {
        if (affiliateProducts?.tag !== selectedAffiliate) {
            navigate('/manager/configure/affiliates/service')
        }
    }, [selectedAffiliate])
    React.useEffect(() => {
        redirect()
    }, [redirect])

    const loadTableData = useCallback(() => {
        const data = affiliateProducts?.data.filter((item) => item.slug === affiliateProducts?.product_slug)
        setTabledata(data[0])
    }, [affiliateProducts?.data, affiliateProducts?.product_slug])

    React.useEffect(() => {
        loadTableData()
    }, [loadTableData])

    const handleAction = (query) => {
        setIsLoading(true);
        axios.post(`https://pm-server.herokuapp.com/api/v2.0/affiliate/service?query=${query}`, {
            tag: selectedAffiliate,
            data: {
                active: radioState === "yes" ? true : false,
                name: productData.name,
                slug: productData.slug.toLowerCase(),
                value: productData.data
            },
            service_slug: affiliateProducts.service_slug,
            product_slug: affiliateProducts.product_slug
        }, {

            headers: {
                Authorization: authUser().authToken,
            }
        }
        ).then((res) => {
            setIsLoading(false);
            setModalOpen(false);
            toast.success(res.data.message)
            navigate("/manager/configure/affiliates/service")
        }).catch((err) => {
            setIsLoading(false);
            toast.error(err.response.data.message)
        }).finally(() => {
            setIsLoading(false);
        })

    }

    const classes = clx(
        "w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex flex-col"
    );
    return (
        <React.Fragment>
            <div className={classes}>
                <div className="flex justify-end p-3">

                    <Button
                        variant="text"
                        iconLeft={<IoAddOutline />}
                        classname=" w-fit text-secondary-dark"
                        onclick={() => setModalOpen(true)}
                    >
                        Add
                    </Button>


                </div>
                <div className='w-full h-full rounded-[20px] bg-white p-6 flex flex-col space-y-6'>
                    <div className="flex ml-auto justify-end">
                        <Input
                            iconLeft={<BsSearch />}
                            placeholder="Search...."
                            inputClass="py-[6px] px-[15px] rounded-r-0"
                            onchange={({ target }) => setSearch(target.value)}
                        />
                    </div>
                    <Table
                        search={search}
                        data={tableData?.data ?? []}
                        columns={affiliiate_services_insights_columns}
                    />
                </div>
            </div>
            <ModalWrapper
                isOpen={isModalOpen}
                handleModalClose={() => setModalOpen(false)}
            >
                <AffiliateInsightsActionModal
                    type="add"
                    label="Enter Value"
                    data={productData}
                    productData={productData}
                    setProductData={setProductData}
                    btnText="Add"
                    isLoading={isLoading}
                    title="Add Value"
                    active={radioState}
                    setActive={setRadioState}
                    validationAttempt={validationAttempt}
                    setValidationAttempt={setValidationAttempt}
                    onButtonClick={() => handleAction("add-product-data")}

                />
            </ModalWrapper>
        </React.Fragment>
    )
}