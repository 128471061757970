import Button from "../../button";
import Radio from "../../radio";
import Text from "../../text";
export default function SwitchMeterModal({ onButtonClick, radioState, setRadioState, loading }) {
    const onSubmit = () => {
        onButtonClick();
    };
    return (
        <div>
            <Text
                as="h3"
                weight="bold"
                classname="text-[24px] lg:text-[30px] text-center"
            >
                Switch Meter
            </Text>
            <div className="my-6 flex flex-col bg-[#6C6F7F] items-center p-6 space-y-6 rounded-lg">
                <Text as='h3' classname='text-white'>
                    Switch Action
                </Text>
                <div className="flex flex-row space-x-6">
                    <Radio
                        name="confirm-remotely"
                        label='ON'
                        classname='text-[16px] text-white'
                        value="on"
                        checked={radioState === "on"}
                        onchange={({ target }) => setRadioState(target.value)}
                        onclick={({ target }) => setRadioState(target.value)}
                    />
                    <Radio
                        name='filter-type'
                        label='OFF'
                        classname='text-[16px] text-white'
                        value="off"
                        checked={radioState === "off"}
                        onchange={({ target }) => setRadioState(target.value)}
                        onclick={({ target }) => setRadioState(target.value)}
                    />
                </div>
                <div>
                    <Text as='p' classname='text-white'>
                        ON  - Meter will be switched on remotely <br />
                        OFF - Meter will be switched off remotely
                    </Text>
                </div>
            </div>
            <Button onclick={onSubmit} isLoading={loading} classname="w-full">
                Continue
            </Button>
        </div>

    );
}
