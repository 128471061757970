import Button from "../../../components/button";
import Text from "../../../components/text";


export const Counter = ({ quantity, onIncrement, onDecrement }) => {
    return (
      <div className="flex items-center border space-x-4">
        <Button
          type="tertiary"
          size="small"
          classname="border rounded-none text-xl font-bold"
          onclick={onDecrement}
        >
          -
        </Button>
        <Text as="p" classname="">
          {quantity}
        </Text>
        <Button
          type="tertiary"
          size="small"
          classname="border rounded-none font-bold text-xl"
          onclick={onIncrement}
        >
          +
        </Button>
      </div>
    );
  };