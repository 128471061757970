import React from "react";
import StaticLayout from "../../layouts/static-layout";
import { DownloadApp } from "../../views/static/components/DownloadApp";
export default function DownloadPage() {
      return (
            <React.Fragment>
                  <StaticLayout>
                        <DownloadApp phone={true}/>
                  </StaticLayout>
            </React.Fragment>
      )
}