import React from "react";
import { FiLock } from "react-icons/fi";
import Button from "../../../../components/button";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import ModalWrapper from "../../../../components/modals/modal-wrapper";
import EditPeriodicCharges from "../../../../components/modals/admin/edit-periodic-charges";
import RemovePeriodicCharges from "../../../../components/modals/admin/remove-periodic-charges";

export default function PeriodicChargesActions({ value, row }) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");

  const handleButtonClick = (type) => {
    setModalType(type);
    setModalOpen(true);
  };
  return (
    <React.Fragment>
      <div className="flex items-center space-x-6">
        {/* <FiLock color="gray" /> */}
        <Button
          variant="text"
          iconLeft={<AiOutlineEdit />}
          classname=" w-fit  text-secondary-dark"
          onclick={() => handleButtonClick("edit")}
        >
          Edit
        </Button>
        <Button
          variant="text"
          iconLeft={<RiDeleteBinLine />}
          classname=" w-fit  text-secondary-dark"
          onclick={() => handleButtonClick("remove")}
        >
          Remove
        </Button>
      </div>

      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => setModalOpen(false)}
      >
        {modalType === "edit" && <EditPeriodicCharges data={row.original} />}
        {modalType === "remove" && <RemovePeriodicCharges data={row.original} />}
      </ModalWrapper>
    </React.Fragment>
  );
}
