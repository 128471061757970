import { useFormik } from "formik";
import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import Button from "../../components/button";
import Input from "../../components/input";
import Text from "../../components/text";
import AuthLayout from "../../layouts/auth-layout";
import { clx } from "../../utils/clx";

export default function MeterIDPageWrapper() {
  return (
    <AuthLayout pageTitle='' usePageNavigation>
      <div>
        <div className='py-6'>
          <Text
            as='h1'
            classname='font-[600] text-[54px] text-primary-dark text-center'
          >
            Meter Id
          </Text>
          <Text as='p' classname='text-[20px] text-center'>
            Kindly input your meter id
          </Text>
        </div>

        <div className='lg:bg-[#F8F8FF] lg:border-[1px] rounded-[16px] w-full lg:w-[550px] py-[0px] lg:py-[50px] px-[20px] lg:px-[70px]'>
          <div className='w-full mx-auto'>
            <MeterIDPageWrapper.Form />
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export function Form({}) {
  const formValues = {
    meterID: "",
  };

  const onSubmit = (values) => {
    return {values}
  };

  const formik = useFormik({ initialValues: formValues, onSubmit });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        iconLeft={<img src='/assets/icons/meter-id-icon.png' alt='icon' />}
        placeholder='Meter ID'
        name='meterID'
        onchange={formik.handleChange}
        value={formik.values.meterID}
      />
      <Button buttonType='submit' classname='w-full mt-[50px]'>
        Verify
      </Button>
    </form>
  );
}

MeterIDPageWrapper.Form = Form;
