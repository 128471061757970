import React from "react";
import { useLocation } from "react-router-dom";

export default function usePageTitle(menuList) {
  const router = useLocation();
  const [activePage, setActivePage] = React.useState();
  React.useEffect(() => {
    menuList?.map((link) =>
      link.route === router.pathname || link.route === `${router.pathname}/`?setActivePage(link.pageTitle) : null
    );
  }, [router, activePage, menuList]);
  return activePage;
}
