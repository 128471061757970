import React from "react";
import Chart from "react-apexcharts";
import { BiSave } from "react-icons/bi";
import BalanceCard from "../components/balance-card";
import Button from "../components/button";
import EnergyTransactionCard from "../components/energy-transaction-card";
import FAQ from "../components/faq";
import Input from "../components/input";
import LinkButton from "../components/link-button";
import OTP from "../components/otp";
import ProfilePicture from "../components/profile-picture";
import RechargeCard from "../components/recharge-card";
import Select from "../components/select";
import Switch from "../components/switch";
import Text from "../components/text";
import WalletTransactionCard from "../components/wallet-transaction-card";
import useChart from "../hooks/useChart";

export default function Test() {
  const config = useChart("bar");
  return (
    <React.Fragment>
      {/* <Chart {...config} width='100%' /> */}
      <Button
        iconRight={<BiSave />}
        size="medium"
        type="secondary"
        variant="outlined"
      >
        Button Component
      </Button>
      <Select
        placeholder="Select a payment platform"
        render={({ setDropdownOpen, setSelectedOption }) =>
          ["Hello", "Hi"].map((option, index) => (
            <Text
              as="p"
              weight="semibold"
              classname="py-4 px-3 bg-gray-50 hover:bg-[#E6E6E9]"
              onclick={() => {
                setSelectedOption(option);
                setDropdownOpen(false);
              }}
            >
              {option}
            </Text>
          ))
        }
      />
      <Text as="p" classname="text-[30px]" weight="bold">
        Hello there
      </Text>
    </React.Fragment>
  );
}
