import React from "react";
import Text from "../../../components/text";
import Input from "../../../components/input";
import { BsTelephone } from "react-icons/bs";

export const CheckoutForm = ({ items }) => {
  return (
    <form className="w-full max-w-[716px] flex flex-col space-y-6 py-6 px-8  bg-[#F8F8FF]">
      <Text as="h3" weight="bold">
        Personal Info
      </Text>

      <Input placeholder="Full Name" />
      <div className="flex flex-col lg:flex-row gap-6">
        <Input
          placeholder="Phone Number"
          iconLeft={<BsTelephone className="text-gray-400 font-bold" />}
        />
         <Input placeholder="Email Address" />
      </div>
      <Text as="h3" weight="bold">
        Address Info
      </Text>

      <Input placeholder="House Delivery Address" />
      <div className="flex flex-col lg:flex-row gap-6">
        <Input placeholder="Region" />
        <Input placeholder="LGA" />
      </div>
    </form>
  );
};
