import React from 'react'
import { clx } from '../utils/clx';
import Text from './text';

const Picker = ({
   
    errorMessage,
    value,
    onChange,
    onfocus,
    onblur,
    label,
    placeholder,
    inputClass,
    name,
    variant = "primary",
    background,
    borderColor,
    iconLeft,
    iconRight,
    classname,
    children,
    content,
    defaultValue
}) => {
    const classes = clx("flex flex-col space-y-2 w-full", classname);
    const inputClasses = clx(
      `flex items-center select-none space-x-3 border-${borderColor} rounded-[8px] py-[15px] px-[20px]`,
      variant === "primary" && "bg-[#E6E6E9] dark:bg-[#1B1D26]",
      variant === "secondary" && "bg-secondary-dark",
      variant === "outlined" && "bg-transparent border-[2px]  border-gray-400",
      variant === "white" && "bg-white",
      inputClass
    );
  return (
   <section className={classes}>
   {label && (
    <label htmlFor={name}>
      <Text as="span" weight="medium" classname="text-[15px] lg:text-base mt-10">
        {label}
      </Text>
    </label>
  )}
   <div className={inputClasses}>
   <select
     id={name}
     defaultValue={defaultValue}
     name={name}
     value={value}
     onChange={onChange}
     className={` flex-1 font-gantari font-[500] outline-none placeholder:text-[14px] lg:placeholder:text-base bg-transparent ${variant === "secondary" && "placeholder-white"
            } dark:text-[#e6e6e9]`}
     onFocus={onfocus}
     onBlur={onblur}
   >
   {content
    .map((shed, index) => (
     <option value={shed} key={index}>{shed}</option>
   ))}
   </select>
   </div>
   
   {errorMessage && (
    <Text
      as="span"
      weight="medium"
      classname="text-[15px] lg:text-base text-red-600"
    >
      {errorMessage}
    </Text>
  )}
   </section>
  )
}

export default Picker