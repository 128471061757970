import React from "react";
import useAdminConsumptionInfo from "../../hooks/apis/admin/useAdminConsumptionInfo";

export function GetAdminConsumptionData() {
  const [totalCredit, setTotalCredit] = React.useState({});
  const [totalConsumption, setTotalConsumption] = React.useState({});
  const [availableBalance, setAvailableBalance] = React.useState({});
  const [totalUsers, setTotalUsers] = React.useState(0);
  const [currentMonthVending, setCurrentMonthVending] = React.useState({});
  const [todayVending, setTodayVending] = React.useState({});
  // const cost = React.useRef();
  // const setCost = useUserStore((state) => state.setCost);
  const {
    data: consumptionInfo,
    isLoading,
    isFetching,
  } = useAdminConsumptionInfo({});
  const cost = consumptionInfo?.data.updateOverview.tariff;
  // console.log("cost", cost);


  React.useEffect(() => {
    // cost.current = consumptionInfo?.data.updateOverview.tariff;
    setTotalUsers(consumptionInfo?.data.totalUsers);
    setCurrentMonthVending(consumptionInfo?.data.vendingOverview.currentMonthVending);
    setTodayVending(consumptionInfo?.data.vendingOverview.todayVending);
    setTotalCredit({
      unit: consumptionInfo?.data.updateOverview.totalcredit,
      price: (
        consumptionInfo?.data.updateOverview.totalcredit * cost
      ).toFixed(),
    });
    setTotalConsumption({
      unit: consumptionInfo?.data.updateOverview.totalconsumption,
      price: (
        consumptionInfo?.data.updateOverview.totalconsumption * cost
      ).toFixed(),
    });
    setAvailableBalance({
      unit: consumptionInfo?.data.updateOverview.residual,
      price: (consumptionInfo?.data.updateOverview.residual * cost).toFixed(),
    });
  }, [isLoading, isFetching, consumptionInfo]);

  // console.log("Data", consumptionInfo?.data);
  return {
    totalUsers,
    currentMonthVending,
    todayVending,
    totalCredit,
    totalConsumption,
    availableBalance,
    isLoading,
    cost,
    isFetching,
  };
}
