import React from "react";
import Text from "../../../../components/text";


export default function PaymentHistoryTab({ activeTab, setTab }) {
    return (
        <div className='flex space-x-[50px]'>
            {["Vend History", "Charges History"].map((tab, index) => (
                <Text
                    key={index}
                    as='p'
                    weight='semibold'
                    classname={`text-[#6C6F7F] lg:text-[20px] py-2 border-[#6C6F7F] tracking-wider ${activeTab === index + 1 && "border-b-2"
                        }`}
                    onclick={() => setTab(index + 1)}
                >
                    {tab}
                </Text>
            ))}
        </div>
    );
}