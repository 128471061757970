import React, { useCallback, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { vend_history_column } from "../../../../../utils/table-utils/admin/vend-history";
import Text from "../../../../../components/text";
import Input from "../../../../../components/input";
import Table from "../../../../../components/table";
import { clx } from "../../../../../utils/clx";
import useAdminVendInfo from "../../../../../hooks/apis/admin/useAdminVendInfo";


const VendHistory = () => {
    const [search, setSearch] = React.useState("");
    const [selectedType, setSelectedType] = React.useState("daily-history");



    const {
        data: VendInfo,
        isLoading: isVendInfoLoading,
        isFetching: isVendInfoFetching,
        refetch
    } = useAdminVendInfo({ key: "AllVendInfo", screen: selectedType });

    // console.log(VendInfo)

    useEffect(() => {
        refetch()
    }, [selectedType])

    const handleSelectType = useCallback((e) => {
        setSelectedType(e.target.value)
    }, [])

    // useEffect(() => {
    //     setSelectedType("energy")
    // }, []);

    const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
    return (
        <React.Fragment>

            <div>
                <div className="max-w-full h-full rounded-[8px] bg-white p-6 flex flex-col space-y-6 relative">
                    <div className="flex flex-row justify-between items-center">

                        <div className="flex flex-row gap-3 items-center">
                            <Text as="p" weight="semibold">
                                Filter By:
                            </Text>
                            <select name="" id=""
                                value={selectedType}
                                onChange={handleSelectType}
                                className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
                            >
                                <option value="daily-history">Daily</option>
                                <option value="monthly-history">Monthly</option>
                                <option value="yearly-history">Yearly</option>
                                {/* <option value="user-history">User</option> */}

                            </select>

                        </div>
                        <Text as="p" weight="semibold" classname="text-secondary-dark">
                            Transactions were sorted based on the Total Transactions ranking...
                        </Text>
                        <div className="flex items-center">
                            <Input
                                iconLeft={<BsSearch />}
                                placeholder="Search..."
                                inputClass="py-[6px] px-[15px] rounded-r-0"
                                onchange={({ target }) => setSearch(target.value)}
                            />
                        </div>

                    </div>
                    <Table
                        search={search}
                        data={VendInfo?.data ?? []}
                        columns={vend_history_column}
                        isLoading={isVendInfoLoading || isVendInfoFetching}
                    // isLoading={isUserListLoading || isUserListFetching}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default VendHistory;
