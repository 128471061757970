import React from 'react';
import { useNavigate } from 'react-router-dom';
import Iobotech from '../../src/images/iobotech-login.png';

const IobotechLogin = () => {
    const navigate = useNavigate();
    const handleLogin = () => {
        // Perform login validation here if needed
        // Navigate to the dashboard after login
        navigate('/iobotech/dashboard')
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen">
            <div className="lg:w-1/2 flex justify-center items-center">
                <img src={Iobotech} alt="iobotech"  loading="lazy" className="object-cover w-full h-full" />
            </div>
            <div className="lg:w-1/2 flex justify-center items-center mt-8 px-4 lg:px-0">
                <div className="text-left lg:text-center">
                    <h1 className="mb-4 text-3xl lg:text-4xl font-lato font-extrabold leading-7 tracking-tight">
                        Welcome!
                    </h1>
                    <form className="flex flex-col items-center lg:items-start">
                        <input
                            type="text"
                            className="w-full lg:w-[427px] h-[48px] px-4 py-3 mb-4 border border-solid border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                            placeholder="Username"
                        />
                        <div className="w-full lg:w-[427px] mb-4">
                            <input
                                type="password"
                                className="w-full h-[48px] px-4 py-3 border border-solid border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                                placeholder="Password"
                            />
                            <p className="font-lato text-xs font-semibold leading-4 text-left text-blue-700 px-2 py-1 rounded">Forgot password?</p>
                        </div>
                        <div className='w-full lg:w-[427px]'>
                            <button
                             onClick={handleLogin}
                            className="w-full h-[48px] px-4 py-3 mb-4 bg-blue-700 text-white font-lato text-xs font-semibold leading-4 rounded-lg focus:outline-none focus:border-blue-500">Login</button>
                            <p className="mt-2 text-center">Not a member? <span className="text-blue-600">Register now</span></p>
                        </div>

                        
                    </form>
                </div>
            </div>
        </div>
    );
}

export default IobotechLogin;
