import React from "react";
import Text from "../../../components/text";
import { Counter } from "./counter";
import Button from "../../../components/button";
import { clx } from "../../../utils/clx";

export const CartCard = ({ items, classname }) => {
  const classes = clx(
    "w-full lg:max-w-[70%]",
    classname
  );
  const [quantity, setQuantity] = React.useState(1);
  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  return (
    <div className={classes}>
      <div className="flex justify-between rounded-t-xl items-center py-6 px-8 bg-[#E6E6E9]">
        <Text as="h3" weight="bold">
          Cart(2)
        </Text>

        <Text as="h3" weight="bold" classname="hidden lg:block text-left">
          Item Price
        </Text>

        <Text as="h3" weight="bold" classname="hidden lg:block">
          Action
        </Text>
      </div>
      <div className="bg-[#F8F8F8] gap-6 flex flex-col lg:flex-row items-start  lg:items-center  rounded-b-xl px-5 lg:px-11  py-12">
        {/* item */}
        <div className="flex gap-3 items-center w-full lg:max-w-[40%]">
          <img
            src="/assets/images/product1.png"
            alt=""
            className="lg:w-[137px] w-[108px] lg:h-[137px] h-[108px]"
          />

          <div className="flex flex-col space-y-4">
            <Text as="h3" classname="text-[#6C6F7F]">
              APC UPS 1500VA 900W UPS Battery Backup & Surge ............
            </Text>
            <Text as="p" classname="text-[#6C6F7F]">
              sold by iobotech
            </Text>
            <Text as="h3" classname="block lg:hidden text-3xl">
              N14,392
            </Text>

            <div className="hidden lg:flex items-center justify-between lg:gap-6">
              <Text as="h3" weight="bold" classname=" text-lg text-[#6C6F7F]">
                Quantity:
              </Text>
              <Counter
                quantity={quantity}
                onIncrement={handleIncrement}
                onDecrement={handleDecrement}
              />
            </div>
          </div>
        </div>

        {/* price */}
        <div className="hidden  lg:flex w-full flex-row justify-between items-center lg:flex-col lg:ml-10">
          <Text as="h3" classname="text-center lg:text-left text-3xl">
            N14,392
          </Text>
          <Text as="p" classname=" text-[#6C6F7F]">
            N14,392 x 2 Items
          </Text>
        </div>

        {/* action */}
        <div className="flex items-center justify-between">
          <Button
            type="tertiary"
            size="small"
            classname="text-secondary-dark  lg:ml-auto"
          >
            Remove item
          </Button>
          <div className="block lg:hidden">
            <Counter
              quantity={quantity}
              onIncrement={handleIncrement}
              onDecrement={handleDecrement}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
