import * as Yup from 'yup'
export const ValidationSchema = Yup.object({
    firstName: Yup.string()
      .required("Please enter your first name")
      // .matches(/^[a-zA-Z]+ [a-zA-Z]+$/, "Fullname must contain only alphabets and have at least one space")
      .min(5, "Fullname length must be at least 5 characters long"),
    lastName: Yup.string()
      .required("Please enter your last name")
      // .matches(/^[a-zA-Z]+ [a-zA-Z]+$/, "Fullname must contain only alphabets and have at least one space")
      .min(5, "Fullname length must be at least 5 characters long"),
    phoneNumber: Yup.string().required("phoneNumber is required").max(11),
    type: Yup.string().required("Meter types is required"),
    phase: Yup.string().required("Phase is required"),
    provider: Yup.string().required("Provider is required"),
    quantity: Yup.number().integer().positive('most be positive').required("Quantity is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });


  export const validationSchemaReferrer = Yup.object({
    firstname:Yup.string().required('First Name is Required'),
    lastname:Yup.string().required('Last Name is Required'),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    // code:Yup.string().required('Country Code is Required'),
    phonenumber:Yup.string().required('Phone Number is Required')
  })


  export const validationSchemaDemo = Yup.object({
    firstname:Yup.string().required('First Name is Required'),
    lastname:Yup.string().required('Last Name is Required'),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    community:Yup.string().required('Community Name is Required'),
    totalProperty:Yup.number().integer().positive().required('Number of properties in your community is Required'),
    address:Yup.string().required('Address is Required'),
    country:Yup.string().required('Country is Required')
  })