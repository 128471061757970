import React from "react";
import Text from "../../../../components/text";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import CopyText from "../../../../components/toast/copy-text";
import { BiCopy } from "react-icons/bi";

export default function UnregisteredUserAuth({ value, row }) {

    return (
        <div className="flex flex-row space-x-2 items-center">
            <Text as='p' classname='text-left text-secondary-dark text-[16px]'>
                {value}
            </Text>
            <CopyToClipboard
                text={value}
                onCopy={() =>
                    toast.success(
                        <CopyText>Token copied successfully!</CopyText>
                    )
                }
            >
                <BiCopy className="block text-black cursor-pointer hover:opacity-50" />
            </CopyToClipboard>
        </div>
    )
}