import Text from "../../../../components/text";

export default function ReturnPolicy() {
  return (
    <div>
      <Text as="p">
        Free return within 15 days for Official Store items and 7 days for other
        eligible items.
      </Text>
    </div>
  );
}
