import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import useVendInfo from "../../../../hooks/apis/user/useVendInfo";
import { clx } from "../../../../utils/clx";
import { getSortedLastToStart } from "../../../../utils/getSortedLastToStart";
import useSelectedEnergyUser from "../../../../store/admin/selected-energy-user";
import useAdminAffiliateInfo from "../../../../hooks/apis/admin/useAdminAffiliateInfo";
import { useQuery } from "react-query";

import { useCookies } from "react-cookie";
import { getSingleUserAdminCharges } from "../../../../hooks/apis/admin/useSingleUserAdminCharges";
import UserChargesCard from "../../../../components/modals/admin/user-charges/user-charges-card";
import { BsSearch } from "react-icons/bs";
import Input from "../../../../components/input";
import Table from "../../../../components/table";
import { charges_overv_column, charges_transactions_column } from "../../../../utils/table-utils/admin/energy-transactions-table";
import useAuthV2 from "../../../../store/admin/authKey";

// import EnergyConsumptionChart from "./components/charts/energy-consumption-chart";
// import { EnergyFunding } from "./dashboard";

export default function SingleUserChargesDashboard({ classname, children }) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [filteredChart, setFilteredChart] = React.useState(null);
  const [modalFor, setModalFor] = React.useState(null);
  // const [transact, settransact] = useState([])
  // const [isFundWalletOpen, setFundWalletOpen] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [paymentMethod, setPaymentMethod] = React.useState(false);
  const navigate = useNavigate();
  // const user = useUserStore((state) => state.user);
  // ! 
  const { selectedEnergyUser: user } = useSelectedEnergyUser();
  const affiliate = useAdminAffiliateInfo();
  const authUser = useAuthUser();
  const [cookies, setCookie, removeCookie] = useCookies(["Auth2"]);
  const [search, setSearch] = React.useState("");

  
  const { data: transactionsInfo } = useVendInfo({
    prepaid: "energy",
  });
   //! folly
  // const { transact } = useGetRecentTransaction();
  const yourAdminId = user?.userid;
  const authTransact = authUser().authToken;


  ///! get Cookies    single user transaction history
  // const AuthV2 = cookies.Auth2?.token;
  //! replace with t
  const { authv2, setAuthV2, setAuthAndCookie } = useAuthV2();
  const {
    data: charges,
    isLoading: isLoadCharges,
    isFetching: isfetchCharges,
  } = useQuery("charges", () => getSingleUserAdminCharges(authv2, yourAdminId));
  // console.log(charges, "from charges");

  const transactions = getSortedLastToStart(transactionsInfo?.data);

  const handleModalControl = (useCase) => {
    setModalFor(useCase);
    setModalOpen(true);
  };

  const handlePaymentMethodSelect = (method) => {
    setPaymentMethod(method);
    setStep(2);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setStep(1);
  };

  React.useEffect(() => window.scrollTo(0, 0), [isfetchCharges, isLoadCharges]);
  React.useEffect(() => {
    if (!user?.userid) {
      navigate("/manager/charges/customers");
    }
  });

 
 

  const classes = clx("w-[93%] lg:w-[90%] mx-auto py-[40px]", classname);
  return (
    <React.Fragment>
      <div className={classes}>
        <div className="max-w-[100%] scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-thumb-[#E5E5E5] overflow-auto pt-[40px] pb-[10px] grid grid-cols-2 gap-4 lg:gap-x-3 lg:grid-cols-3 justify-between ">
          <UserChargesCard
            title="Total Payment"
            price={charges?.data?.data?.payment}

          />
          <UserChargesCard
            title="Total Outstanding"
            price={charges?.data?.data?.outstanding}

          />
        </div>
        <div className="flex justify-end items-center space-x-6 py-2">
        <div className="flex items-center">
          <Input
            iconLeft={<BsSearch />}
            placeholder="Search"
            variant="white"
            inputClass="py-[6px] px-[15px] rounded-r-0"
            onchange={({ target }) => setSearch(target.value)}
          />
        </div>
      </div>

        <div className="w-full my-[40px] mt-[10px] bg-white rounded-[16px] p-6">
          <Table
            search={search}

            data={charges?.data?.data?.history ?? [{}]}
            
            columns={charges_overv_column}
            isLoading={isLoadCharges || isfetchCharges}
          />
        </div>
      </div>
     
    </React.Fragment>
  );
}


