import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaAppStore, FaCircleUser, FaGooglePlay, FaLock, FaUser } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import Button from "../../components/button";
import Input from "../../components/input";
import Text from "../../components/text";
import AuthLayout from "../../layouts/auth-layout";
import { clx } from "../../utils/clx";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { useSignIn, useIsAuthenticated, useAuthUser } from "react-auth-kit";
import { MdError } from "react-icons/md";
import { GrInfo } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import useUserInfo from "../../hooks/apis/user/useUserInfo";
import useUserStore from "../../store/user/store";
import { DownLoadLink } from "../static/hompage";

export default function LoginPageWrapper() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const [isVerified, setVerified] = React.useState(false);

  // console.log("auth", isAuthenticated())

  React.useEffect(() => {
    if (isAuthenticated() && authUser()?.user === "User") {
      return navigate("/user");
    } else {
      setVerified(true);
    }
  });
  //! 
  if (isVerified) {
    return (
      <AuthLayout>
        <div className="w-full pb-[50px] lg:pb-0 lg:flex items-center lg:space-x-[30px]">
          <div className="w-full h-fit lg:flex items-center">
            <div className="lg:hidden">
              <div className="my-[40px]">
                <img
                  src="assets/images/logo2.png"
                  alt="logo"
                  className="w-[250px] mx-auto"
                />
              </div>
              <div className="border-[1px] border-secondary-dark w-full rounded-[12px] bg-[#D9D9D9] overflow-hidden">
                <img
                  src="assets/images/login-banner.png"
                  alt="logo"
                  className="w-[100%] object-fill"
                />
              </div>
            </div>
            <div className="flex-1 h-full flex flex-col justify-center w-full mt-[40px] my-[30px] lg:mb-0">
              <Text
                as="p"
                classname="font-[600] text-[28px] lg:text-[64px] text-primary-dark dark:text-[#E6E6E9]"
              >
                Welcome Back!
              </Text>
              <Text
                as="p"
                weight="medium"
                classname="text-[14px] lg:text-[24px] text-primary-dark dark:text-[#E6E6E9]"
              >
                Download our app
              </Text>
            </div>
            <div className="flex-1 flex justify-end items-center">
              <div className="bg-[#F8F8FF] dark:bg-[#12131A] border-[1px] rounded-[16px] w-full py-[30px] lg:py-[50px] px-[20px] lg:px-[70px]">
                <Form />
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    );
  }
}
// flex flex-col mt-[72px] lg:mt-0 h-full gap-[20px] justify-between lg:mr-10 


export function Form({ }) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [loginMessage, setLoginMessage] = React.useState({
    type: "",
    message: "",
  });
  const [isLoading, setLoading] = React.useState(false);
  const signIn = useSignIn();
  const navigate = useNavigate();

  function getUserToken(loginData) {
    return axios
      .post("https://api-pm-e1ea777feac4.herokuapp.com/user/login", loginData)
      .then((res) => {
        setLoginMessage({
          type: "success",
          message:
            "You are successfully Logged in!. You will be redirected shortly",
        });
        signIn({
          token: res.data.data.token,
          expiresIn: 360,
          tokenType: "Bearer",
          authState: {
            company: res.data.data.company,
            authToken: res.data.data.token,
            user: "User",
          },
        });
        setLoading(false);
        navigate("/user");
      })
      .catch((err) => {
        setLoading(false);
        if (err.message === "Network Error") {
          return setLoginMessage({
            type: "error",
            message:
              "Oops! Connection Not Extablished. Please check your internet connection",
          });
        }
        return setLoginMessage({
          type: "error",
          message: err.response.data.message,
        });

        // switch (err.response.data.message) {
        //   case "You have been restricted from Login, Please Contact the Administrator!!!":
        //     return setLoginMessage({
        //       type: "warning",
        //       message: err.response.data.message,
        //     });
        //   case "Incorrect credential.!!!":
        //     return setLoginMessage({
        //       type: "error",
        //       message:
        //         "Invalid credential or Password. Please confirm the details you provided",
        //     });
        //   case "Incorrect Password.!!!":
        //     return setLoginMessage({
        //       type: "error",
        //       message:
        //         "Invalid credential or Password. Please confirm the details you provided",
        //     });

        //   default:
        //     return setLoginMessage({
        //       type: "error",
        //       message: "Oops! Something went wrong",
        //     });
        // }
      });
  }

  // const { isLoading, isError, error, data, mutate } = useMutation(
  //   // "Login",
  //   getUserToken
  // );

  const formValues = {
    credential: "",
    password: "",
  };

  const validationSchema = Yup.object({
    credential: Yup.string()
      .required("Please enter your credential"),
    password: Yup.string().required("Please enter your password"),
  });

  const onSubmit = (values) => {
    setValidationAttempt(false);
    setLoading(true);
    getUserToken(values);
    // mutate(values);
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col space-y-6">
        {loginMessage.message && (
          <LoginPageWrapper.MessageContainer
            messageType={loginMessage.type}
            message={loginMessage.message}
          />
        )}
        <Input
          type="text"
          iconLeft={<FaUser className="text-[#9799A5]" />}
          placeholder="Enter your Email or Username"
          name="credential"
          onchange={formik.handleChange}
          onblur={formik.handleBlur}
          errorMessage={formik.errors.credential}
          value={formik.values.credential}
        />
        <Input
          type="password"
          iconLeft={<FaLock className="text-[#9799A5]" />}
          placeholder="Password"
          name="password"
          onchange={formik.handleChange}
          onblur={formik.handleBlur}
          errorMessage={formik.errors.password}
          value={formik.values.password}
        />
        <Text
          as="p"
          classname="text-secondary-dark text-[14px] lg:text-base text-right"
          href="/forget-password"
        >
          Forgot Password
        </Text>
        <Button
          buttonType="submit"
          classname="w-full text-[14px] lg:text-base"
          onclick={() => setValidationAttempt(true)}
          isLoading={isLoading}
        >
          Sign in
        </Button>
        <Text
          as="p"
          classname="text-center text-[14px] lg:text-base"
          href="/auth-token"
        >
          <span className="text-[#6C6F7F]">New User?</span> Create an Account
        </Text>
        <Text
          as="p"
          classname="text-secondary-dark text-[14px] lg:text-base text-center lg:hidden"
        >
          Request Demo
        </Text>
      </div>
    </form>
  );
}

export function MessageContainer({ messageType, message }) {
  const classes = clx(
    "border-[1px] py-2 px-4 rounded-[8px] flex space-x-3",
    messageType === "error"
      ? "bg-red-500/30 "
      : messageType === "success"
        ? " bg-green-500/20"
        : "bg-yellow-500/20"
  );
  return (
    <div className={classes}>
      <div>
        {messageType === "error" || messageType === "warning" ? (
          <MdError
            className={`${messageType === "error" ? "text-red-600" : "text-secondary-dark"
              } text-[18px] lg:text-[22px]`}
          />
        ) : (
          <GrInfo className="text-green-600 text-[18px] lg:text-[22px]" />
        )}
      </div>
      <Text
        as="p"
        classname={`${messageType === "error"
            ? "text-red-600"
            : messageType === "success"
              ? "text-green-600"
              : "text-secondary-dark"
          } text-[13px] lg:text-[14px]`}
      >
        {message}
      </Text>
    </div>
  );
}

LoginPageWrapper.MessageContainer = MessageContainer;
LoginPageWrapper.Form = Form;
