import React from "react";
import Text from "../../../components/text";
import ManagerPassword from "./components/admin-manager-password-action";
import AdminManagerActions from "./components/admin-manager-actions";
import AdminManagerOtherActions from "./components/admin-manager-other-actions";

export const managers_profile_data = Array.from({ length: 10 }).map(
  (data, i) => ({
    _id: i + 1,
    name: `Samuel Lasisi-${i + 1}`,
    pic: "/assets/images/thumbnail.png",
    email: "userexample@gmail.com",
    role: "Tenant Administrator",
    company: "Company Name",
  })
);

export const managers_profile_column = [

  // {
  //   // //     id: "_",
  //   // Header: (
  //   //   <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
  //   //     S/N
  //   //   </Text>
  //   // ),
  //   // //     Cell: TableIcon,
  //   // accessor: "_id",
  // },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Name
      </Text>
    ),
    accessor: "fullname",
    Cell: ({ value, row }) => (
      <div className='flex items-center space-x-4'>
        {/* <div className='w-[40px] h-[40px] rounded-full bg-red-500'>
          <img
            src='/assets/images/thumbnail.png'
            alt=''
            className='object-fill'
          />
        </div> */}
        <Text as='p' classname='text-left text-[16px]'>
          {value}
        </Text>
      </div>
    ),
  },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Email
      </Text>
    ),
    accessor: "email",
  },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Role
      </Text>
    ),
    Cell: ({ value, row }) => (
      <Text as='p' classname='text-left text-[16px] capitalize'>
        {value}
      </Text>
    ),
    accessor: "role",
  },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Company
      </Text>
    ),
    Cell: ({ value, row }) => (
      <Text as='p' classname='text-left text-[16px] uppercase text-secondary-dark'>
        {value}
      </Text>
    ),
    accessor: "company",
  },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Password
      </Text>
    ),
    Cell: ManagerPassword,
    accessor: "password",
  },
  {

    id: "2",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Actions
      </Text>
    ),
    accessor: "_id",
    Cell: AdminManagerActions

  },
  {

    id: "3",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Other Actions
      </Text>
    ),
    accessor: "_id",
    Cell: AdminManagerOtherActions

  },
];
