import React from 'react';
import Text from '../../../components/text';
import { clx } from '../../../utils/clx';
import OTP from '../../../components/otp';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { VscReferences } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useAuthUser } from 'react-auth-kit';

export default function CheckReference() {
    const [isLoding, setIsLoading] = React.useState(false);
    const [success, setSuccess] = React.useState("");
    const [error, setError] = React.useState("");
    const [reference, setReference] = React.useState('');
    const [platform, setPlatform] = React.useState('');
    const authUser = useAuthUser();
    const handleCheck = (e) => {
        e.preventDefault();
        setError("");
        setSuccess("");
        setIsLoading(true);
        if (reference === '' || !platform) {
            toast.error('Please enter reference number');
            return;
        }
        axios.post('https://pm-server.herokuapp.com/api/v2.0/manager/check-refr', {
            refr: reference,
            platform
        },
            {
                headers: {
                    Authorization: authUser().authToken,
                },
            }).then((res) => {
                setIsLoading(false);
                setSuccess(res.data.message);
                toast.success(res.data.message);
            }
            ).catch((err) => {
                setIsLoading(false);
                setError(err.response.data.message);
                toast.error(err.response.data.message);

            }
            )
            .finally(() => {
                setIsLoading(false);

            }
            );

    }

    const classes = clx("w-[93%] lg:w-[90%] flex items-center flex-col mx-auto lg:py-[40px]");


    return (
        <div className={classes}>
            <div>
                <Text as='h2' weight='bold' classname='text-[24px] text-center text-primary-light'>
                    Check Reference
                </Text>
            </div>

            <form onSubmit={handleCheck} className="flex justify-center py-10 mt-7 px-6 lg:px-12  flex-col w-[90%] lg:min-w-[400px] lg:max-w-fit rounded-[20px] bg-white">
                <>
                    <Input
                        classname=""
                        label="Reference Number:"
                        placeholder="Enter Reference Number"
                        type="text"
                        name="reference"
                        required
                        // defaultValue={reference}
                        iconLeft={<VscReferences />}
                        value={reference}
                        onchange={(e) => {
                            setReference(e.target.value)
                        }}
                    />
                    <select name="" id=""
                        value={platform}
                        className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px] my-6"
                        required
                        onChange={(e) => {
                            setPlatform(e.target.value)
                        }}
                    >
                        <option value="">Select Platform</option>
                        <option value="paystack">Paystack</option>
                        <option value="flutterwave">Flutterwave</option>
                    </select>
                    <Button classname=""
                        buttonType="submit"
                        isLoading={isLoding}
                    >
                        Check
                    </Button>

                    {success && <Text as='p' weight='bold' classname=' text-center text-primary-light'>
                        {success}
                    </Text>}
                    {error && <Text as='p' weight='bold' classname=' text-center text-red-500'>
                        {error}
                    </Text>}
                </>
            </form>
        </div>
    )
}