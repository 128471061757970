import React from "react";
import { clx } from "../../../utils/clx";
import Text from "../../../components/text";
import Input from "../../../components/input";
import { BiPlus, BiSearch } from "react-icons/bi";
import Table from "../../../components/table";
import { DiscoElecticityMetersListColumns } from "../../../utils/table-utils/user/columns/disco-electricity-meters-list";

import Button from "../../../components/button";
import MeterDetails from "./steps/meter-details";
import MeterId from "./steps/meter-id";
import UserInfo from "./steps/user-info";
import Tabs from "./tabs";
import ModalWrapper from "../../../components/modals/modal-wrapper";
import useGetDiscoMeters from "../../../hooks/apis/user/useGetDiscoMeters";
import useBuyElectricityData from "../../../store/static/buy-electricity";

export default function DiscoElectricityMeterWrapper({ classname }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  // const [currentStep, setCurrentStep] = React.useState(1);
  const {
    setDiscoInfo,
    currentStep,
    setUserInfo,
    setCurrentStep,
    setElectricityInfo,
  } = useBuyElectricityData();

  const { data, isLoading } = useGetDiscoMeters({
    key: "get-meters",
    query: "get-disco-meters",
  });
  // console.log(data);

  const steps = ["Meter Info", "Confirm Meter details", "User info"];
  const meter_columns = React.useMemo(
    () => DiscoElecticityMetersListColumns,
    []
  );

  const displaySteps = (step) => {
    switch (step) {
      case 1:
        return <MeterId />;
      case 2:
        return <MeterDetails />;
      case 3:
        return <UserInfo />;
      default:
        return null;
    }
  };

  const handleModal = () => {
    setElectricityInfo({});
    setDiscoInfo({});
    setCurrentStep(1);
    setUserInfo({});
    setIsModalOpen(true);
  };

  const classes = clx(
    "w-[93%] lg:w-[90%] mx-auto  space-x-[20px] justify-between pt-[30px] lg:py-[15px] lg:pb-[50px]",
    classname
  );
  return (
    <React.Fragment>
      <div className={classes}>
        <Text as="h1" weight="bold" classname="text-xl md:text-2xl">
          Meter List
        </Text>

        <Input
          value={search}
          onchange={({ target }) => setSearch(target.value)}
          iconLeft={<BiSearch className="text-[#7D8195] text-[20px]" />}
          placeholder="Please type your question here."
          inputClass="bg-[#F5F5FE] w-[70%] mx-auto my-[20px]"
        />

        <div className="py-[30px]">
          <Table
            search={search}
            isLoading={isLoading}
            columns={meter_columns}
            data={data?.data ?? []}
          />
        </div>

        <div className="flex justify-center mt-6">
          <Button
            variant="contained"
            type="secondary"
            size="medium"
            onclick={handleModal}
            iconRight={<BiPlus />}
          >
            Add New Meter
          </Button>
        </div>
      </div>

      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => {
          setIsModalOpen(false);

          setCurrentStep(1);
        }}
      >
        <div>
          <div className="flex flex-col gap-3 lg:gap-6  md:p-6 lg:p-12  w-full">
            <Text as="h3" weight="bold" classname="text-center text-sm lg:text-xl">
              {steps[currentStep - 1]}
            </Text>
            <Tabs steps={steps} currentStep={currentStep} />
            {displaySteps(currentStep, setCurrentStep)}{" "}
            {/* Pass setCurrentStep if needed */}
          </div>
        </div>
      </ModalWrapper>
    </React.Fragment>
  );
}
