import React from "react";
import ContactPageWrapper from "../../views/static/contact-page";
import StaticLayout from "../../layouts/static-layout";

export default function ContactPage() {
  return <React.Fragment>
    <StaticLayout>
      <ContactPageWrapper />
    </StaticLayout>
  </React.Fragment>
}
