import React from "react";
import Text from "../../text";
import Button from "../../button";
import Input from "../../input";
import TextArea from "../../textarea";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BsPeopleFill } from "react-icons/bs"
import { AiOutlineComment } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
export default function GatePassData({
    type,
    data
}) {
    const [validationAttempt, setValidationAttempt] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false)
    const authUser = useAuthUser();
    const navigate = useNavigate();


    // console.log(data);
    const entry = data?.entry ? formatDate(data.entry) : "N/A";
    const exit = data?.exit ? formatDate(data.exit) : "N/A";
    function formatDate(date) {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate)) {
            return "N/A";
        }
        return parsedDate.toLocaleDateString("en-GB").split("/").join("-");
    }


    const formValues = {
        population: "",
        comment: ""

    };
    const validationSchema = Yup.object({
        population: Yup.number().required("Population is required").max(data?.maximum, `Population cannot be more than ${data?.maximum}`),
        comment: Yup.string().required("Comment is required"),
    });
    const onSubmit = async (values) => {
        setValidationAttempt(false);
        setIsLoading(true)
        await axios.put(`https://pm-server.herokuapp.com/api/v2.0/manager/sign-gatepass`, {
            access_code: data?.access_code,
            action: type === "Sign In" ? "in" : "out",
            ...values
        }, {
            headers: {
                Authorization: authUser().authToken
            }
        }).then((res) => {
            toast.success(res.data.message)
            navigate("/manager/configure")
        }).catch((err) => {
            setIsLoading(false);
            toast.error(err.response.data.message)
        }).finally(() => {
            setIsLoading(false);
            formik.resetForm()
        });

    }
    const formik = useFormik({
        initialValues: formValues,
        onSubmit,
        validationSchema,
        validateOnBlur: false,
        validateOnChange: validationAttempt,
    });

    return (
        <div className="flex flex-col">
            <Text
                as="h3"
                weight="bold"
                classname="text-[24px] lg:text-[30px] text-center"
            >
                Access Details
            </Text>
            <div className="flex flex-col py-4 px-6">
                <div className="flex flex-row justify-between items-center">
                    <Text
                        as="h3"
                        weight="bold"
                        classname="text-[#6C6F7F] text-[18px] text-center"
                    >
                        Receipient
                    </Text>
                    <Text as="p" classname="text-[#6C6F7F] text-center">
                        {data?.receipient.fullname}
                    </Text>
                </div>
                <div className="flex flex-row justify-between items-center">
                    <Text
                        as="h3"
                        weight="bold"
                        classname="text-[#6C6F7F] text-[18px] text-center"
                    >
                        Apartment
                    </Text>
                    <Text as="p" classname="text-[#6C6F7F] text-center">
                        {data?.receipient.apartment}
                    </Text>
                </div>

                <Text as="h3" weight="semibold" classname="text-[#6C6F7F] text-[18px] text-center">
                    Visitor's Details
                </Text>
                <table>
                    <tbody >
                        <tr>
                            <td className=" text-[#6C6F7F]">Name:</td>
                            <td className="text-right text-[#6C6F7F]">{data.fullname}</td>
                        </tr>
                        <tr>
                            <td className=" text-[#6C6F7F]">Email:</td>
                            <td className="text-right text-[#6C6F7F]">{data.email}</td>
                        </tr>
                        <tr>
                            <td className=" text-[#6C6F7F]">Phone Number:</td>
                            <td className="text-right text-[#6C6F7F]">{data.phonenumber}</td>
                        </tr>
                        <tr>
                            <td className="text-[#6C6F7F]">Gender:</td>
                            <td className="text-right text-[#6C6F7F]">{data.gender}</td>
                        </tr>
                        <tr>
                            <td className=" text-[#6C6F7F]">Time of Visit:</td>
                            <td className="text-right text-[#6C6F7F]">{data.time}</td>
                        </tr>
                        <tr>
                            <td className=" text-[#6C6F7F]">Entry date:</td>
                            <td className="text-right text-[#6C6F7F]">{entry}</td>
                        </tr>
                        <tr>
                            <td className=" text-[#6C6F7F]">Exit date:</td>
                            <td className="text-right text-[#6C6F7F]">{exit}</td>
                        </tr>
                        <tr>
                            <td className="text-[#6C6F7F]">Checked In</td>
                            <td className={`text-right font-bold ${data?.status === "CHECKIN" ? "text-green-500" : "text-red-500"}`}>
                                {data?.status === "PENDING" && "Pending"}
                                {data?.status === "CHECKOUT" && "Checked Out"}
                                {data?.status === "CHECKIN" && "Checked In"}
                            </td>
                        </tr>


                    </tbody>
                </table>
                <div className="flex justify-end">
                    <Text as='p' classname='text-primary-light rounded-lg py-1 px-3 mt-2 bg-[#F0F0FF]'>
                        {data?.access_code}
                    </Text>
                </div>
            </div>
            {
                data?.status === "PENDING" && type === "Sign In" && (
                    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">

                        <Input
                            iconLeft={<BsPeopleFill />}
                            type="number"
                            placeholder="Enter Population"
                            name="population"
                            value={formik.values.population}
                            errorMessage={formik.errors.population}
                            onblur={formik.handleBlur}
                            onchange={(e) => formik.handleChange(e)}
                            classname="w-full"
                        />
                        <TextArea
                            iconLeft={<AiOutlineComment />}
                            placeholder="Enter Comments..."
                            classname="w-full"
                            name="comment"
                            value={formik.values.comment}
                            onchange={(e) => formik.handleChange(e)}
                            errorMessage={formik.errors.comment}
                            handleBlur={formik.handleBlur}
                        />
                        <Button
                            classname="w-full"
                            buttonType="submit"
                            isLoading={isLoading}
                        >
                            {type}
                        </Button>
                    </form>
                )
            }
            {
                data?.status === "CHECKIN" && type === "Sign Out" && (
                    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
                        <Input
                            iconLeft={<BsPeopleFill />}
                            type="number"
                            placeholder="Enter Population"
                            name="population"
                            value={formik.values.population}
                            errorMessage={formik.errors.population}
                            onblur={formik.handleBlur}
                            onchange={(e) => formik.handleChange(e)}
                            classname="w-full"
                        />
                        <TextArea
                            iconLeft={<AiOutlineComment />}
                            placeholder="Enter Comments..."
                            classname="w-full"
                            name="comment"
                            value={formik.values.comment}
                            onchange={(e) => formik.handleChange(e)}
                            errorMessage={formik.errors.comment}
                            handleBlur={formik.handleBlur}
                        />


                        <Button
                            classname="w-full"
                            isLoading={isLoading}
                            buttonType="submit"
                        >
                            {type}
                        </Button>
                    </form>
                )
            }
        </div>

    )
}