import React from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Text from "../../components/text";
import { clx } from "../../utils/clx";
import FuelStatisticAreaChart from "./components/charts/fuel-statistics-chart-area";

export default function FuelStatisticsAreaWrapper() {
  const navigate = useNavigate()
  const classes = clx("w-[93%] lg:w-[90%] mx-auto pb-[50px] lg:pb-[70px]");
  return (
    <div className={classes}>
      <div
        className='flex items-center space-x-6 cursor-pointer'
        onClick={() => navigate(-1)}
      >
        <MdArrowBackIosNew className='text-[#9799A5] text-[20px] ' />
        <Text as='span' classname='text-[#9799A5]'>
          Back to Fuel Monitoring Dashboard
        </Text>
      </div>
      <FuelStatisticAreaChart classname='mt-[50px] rounded-[20px] shadow-xl w-full max-h-fit' />
    </div>
  );
}

export function Tabs({ classname, children }) {
  const [activeTab, setActiveTab] = React.useState(1);
  const classes = clx(
    "w-fit mx-auto flex items-center space-x-6 my-[50px] bg-[#F1F1FF] py-[28px] px-[20px] lg:px-[40px] rounded-[12px]",
    classname
  );
  return (
    <div className={classes}>
      {["Daily", "Weekly", "Monthly"].map((tab, index) => (
        <div
          key={index}
          className={`py-[6px] px-[16px] rounded-[4px] font-semibold cursor-pointer ${
            activeTab === index + 1
              ? "bg-secondary-dark text-primary-dark"
              : "bg-[#E6E6E9] text-primary-dark"
          }`}
          onClick={() => setActiveTab(index + 1)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
}

FuelStatisticsAreaWrapper.Tabs = Tabs;
