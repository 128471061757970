import React from "react";
import { IoAddOutline } from "react-icons/io5";
import Button from "../../../components/button";
import Table from "../../../components/table";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";
import {
  customer_column,
  customer_data,
} from "../../../utils/table-utils/admin/customer-table";
import {
  request_column,
  request_data,
} from "../../../utils/table-utils/admin/requests-table";

export default function RequestPageWrapper({ classname, children }) {
  const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
  // console.log("customer", customer_data);
  return (
    <div className={classes}>
      <div className="flex items-center justify-between">
        <Text as="p" weight="bold" classname="text-[28px]">
          Requests
        </Text>
        <Button
          iconLeft={<IoAddOutline />}
          type="white"
          classname="bg-[#E6E6E9] text-primary-dark hover:bg-gray-300"
        >
          Add Request
        </Button>
      </div>
      <div className="my-[40px] bg-white rounded-[16px] p-6">
        <Table data={request_data} columns={request_column} />
      </div>
    </div>
  );
}
