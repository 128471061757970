import { IoLocationOutline } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import Text from "../../../components/text";

export default function AddressSection() {
  return (
    <div className="py-6 lg:py-[60px] px-6 lg:px-[104px] w-full gap-6 flex flex-col lg:flex-row lg:justify-between items-center rounded-lg bg-[#F4F6F8]">
      <div className="flex flex-col space-y-1">
        <Text as="h3" classname="text-left text-[24px]" weight="bold">
          Prepaidmeter
        </Text>
        <Text as="p" classname="text-left text-secondary-dark" weight="normal">
          prepaidnigeria@gmail.com
        </Text>
      </div>

      {/* <div className="flex space-x-2">
        <IoLocationOutline className="h-5 w-5 " />
        <Text
          as="p"
          classname="text-left w-full lg:max-w-[200px]"
          weight="normal"
        >
          29, Chief Israel Udo street Therra Annex Sangotedo, Ajah
        </Text>
      </div> */}

      <div className="flex space-x-2">
        <FiPhoneCall className="h-5 w-5 " />
        <Text as="p" classname="text-left " weight="normal">
          +234-906-882-5492
        </Text>
      </div>
    </div>
  );
}
