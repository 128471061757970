import React from "react";
import ActivityCard from "../../../components/activity-card";
import AdminCountCard from "../../../components/admin-count-card";
import AdminWalletBalanceCard from "../../../components/admin-wallet-balance";
import Table from "../../../components/table";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";
import {
  recent_transactions_column,
  recent_transactions_data,
} from "../../../utils/table-utils/admin/dashboard-recent-transactions";
import useAdminAffiliateInfo from "../../../hooks/apis/admin/useAdminAffiliateInfo";
import DashboardAnalytics from "./dashboard-analytics";
import { useNavigate } from "react-router-dom";
import useAdminVendInfo from "../../../hooks/apis/admin/useAdminVendInfo";
import Input from "../../../components/input";
import { BsSearch } from "react-icons/bs";
import DashboardActivities from "./activities";

export default function DashboardWrapper() {
  const affiliate = useAdminAffiliateInfo();
  const {
    data: VendInfo,
    isLoading: isVendInfoLoading,
    isFetching: isVendInfoFetching,
  } = useAdminVendInfo({ key: "AllVendInfo" });
  const [search, setSearch] = React.useState("");
  console.log(VendInfo?.data)

  const navigate = useNavigate();

  

  const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");

  return (
    <div className={classes}>
      {/* <AdminWalletBalanceCard /> */}
      <div className="flex space-x-[30px] pt-[40px]">
        <div className="flex-1 w-[60%] flex flex-col space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <AdminCountCard
              description="Total Overall User"
              classname="w-[100%]"
              bg="bg-primary-dark"
              iconBg="bg-white/30"
              textColor="text-white"
              count={
                affiliate?.energymeters?.length > 0
                  ? affiliate?.energymeters?.length
                  : 0 + affiliate?.watermeters?.length > 0
                  ? affiliate?.watermeters?.length
                  : 0 + affiliate?.iotservices?.length > 0
                  ? affiliate?.iotservices?.length
                  : 0
              }
            />
            <AdminCountCard
              description="Total Prepaid Energy User"
              classname="w-[100%]"
              bg="bg-[#9799A5]"
              iconBg="bg-white/30"
              textColor="text-white"
              count={
                affiliate?.energymeters?.length > 0
                  ? affiliate?.energymeters?.length
                  : 0
              }
              // onclick={() => navigate("/manager/energy")}
            />
            <AdminCountCard
              description="Total Prepaid Water User"
              classname="w-[100%]"
              bg="bg-[#F0D596]"
              iconBg="bg-[#DB9800]/30"
              textColor="text-primary-light"
              count={
                affiliate?.watermeters?.length > 0
                  ? affiliate?.watermeters?.length
                  : "0"
              }
              // onclick={() => navigate("/manager/water")}
            />
            <AdminCountCard
              description="Total Fuel Monitoring User"
              classname="w-[100%]"
              bg="bg-[#FBF5E6]"
              iconBg="bg-[#DB9800]/30"
              textColor="text-primary-light"
              count={
                affiliate?.iotservices?.length > 0
                  ? affiliate?.iotservices?.length
                  : "0"
              }
              // onclick={() => navigate("/manager/fuel")}
            />
          </div>
          <div className="max-w-full h-full rounded-[8px] bg-white p-6 flex flex-col space-y-6">
            <div className="flex items-center justify-between">
              <div>
                <Text as="h5" weight="bold" classname="text-[20px]">
                  Recent Transactions
                </Text>
              </div>
              <Text
                as="h5"
                weight="semibold"
                classname="text-[16px] text-secondary-dark"
                href="transactions"
              >
                See All
              </Text>
            </div>

            <div className="flex justify-end items-center space-x-6">
              <div className="flex items-center">
                <Input
                  iconLeft={<BsSearch />}
                  placeholder="Search By Meter ID"
                  inputClass="py-[6px] px-[15px] rounded-r-0"
                  onchange={({ target }) => setSearch(target.value)}
                />
                {/* <div className="h-full w-[50px] border-2 py-[4px] rounded-[6px]">
              ee
            </div> */}
              </div>
              {/* <Text as="p" weight="semibold">
                Download
              </Text> */}
            </div>

            <Table
              search={search}
              data={VendInfo?.data ?? []}
              columns={recent_transactions_column}
              isLoading={isVendInfoLoading || isVendInfoFetching}
              hidePagination
            />
          </div>
        </div>
        <div className="w-[300px] flex flex-col space-y-6">
          <DashboardAnalytics />
          <DashboardActivities />
        </div>
      </div>
    </div>
  );
}
