import React, { useMemo } from "react";
import Text from "../../text";
import Input from "../../input";
import Button from "../../button";
import { useFormik } from "formik";
import { TbCurrencyNaira, TbPercentage } from "react-icons/tb";
import * as Yup from "yup";
import useAdminAffiliates from "../../../store/admin/affiliates";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

export default function AddRegularCharges({ charges_slug, type }) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { selectedAffiliate } = useAdminAffiliates();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const isPercentage = useMemo(() => {
    return charges_slug === "percentage" ? true : false;
  }, [charges_slug])

  const formValues = {
    name: "",
    amount: "",
    insight_slug: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is Required"),
    amount: isPercentage
      ? Yup.string().required("Amount is required").matches(
        /^(100|[1-9][0-9]?)$/,
        "Percentage must be between 1 and 100"
      )
      : Yup.number().required("Amount is required"),
    insight_slug: Yup.string().required("Slug is required").matches(/^[a-z0-9-]+$/g, "Charges Slug can only contain lowercase letters, numbers, and hyphens with no spaces"),
  });

  const onSubmit = (values) => {
    setIsLoading(true)
    setValidationAttempt(false);
    // console.log(values)
    axios.put("https://pm-server.herokuapp.com/api/v2.0/charges-proxy?query=add", {
      ...values,
      tag: selectedAffiliate,
      charges_slug,
      type
    }, {
      headers: {
        Authorization: authUser().authToken,
      },
    }).then(res => {
      // console.log(res)
      setIsLoading(false)
      toast.success(res.data.message)
      // window.location.href = '/manager/configure/charges'
      navigate('/manager/configure/charges')
    }).catch(err => {
      // console.log(err)
      toast.error(err.response.data.message)
      setIsLoading(false)
    }).finally(() => {
      setIsLoading(false)
    }
    )
  };


  // useEffect(() => {

  //   console.log(data)
  // }, [data])
  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <Text
          as="h3"
          weight="bold"
          classname="text-[24px] lg:text-[30px] text-center"
        >
          Add Charges
        </Text>
        <div className="my-6 flex flex-col space-y-6">
          <Input
            label="Charge Name:"
            placeholder='Electricity Charges'
            type="text"
            name="name"
            value={formik.values.name}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.name}
          />
          {isPercentage ? (
            <Input
              iconLeft={<TbPercentage />}
              label="Charge Amount:"
              placeholder='100'
              type="number"
              name="amount"
              value={formik.values.amount}
              onchange={(e) => formik.handleChange(e)}
              onblur={formik.handleBlur}
              errorMessage={formik.errors.amount}
            />
          ) : (
            <Input
              iconLeft={<TbCurrencyNaira />}
              label="Charge Amount:"
              placeholder='4000'
              type="number"
              name="amount"
              value={formik.values.amount}
              onchange={(e) => formik.handleChange(e)}
              onblur={formik.handleBlur}
              errorMessage={formik.errors.amount}
            />
          )}
          <Input
            label="Charge Slug:"
            placeholder='chargeservices'
            type="text"
            name="insight_slug"
            value={formik.values.insight_slug}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.insight_slug}
          />
        </div>
        <Button isLoading={isLoading}
          onclick={() => setValidationAttempt(true)}
          buttonType="submit" classname="w-full">
          Add
        </Button>
      </div>
    </form>
  );
}
