import React from "react";
import MayAlsoLike from "./components/may-also-like";
import { useNavigate, useParams } from "react-router-dom";
import Text from "../../components/text";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import Input from "../../components/input";
import { RiSearch2Line, RiSearchLine } from "react-icons/ri";
import { BiFilterAlt } from "react-icons/bi";
import { TbArrowsSort } from "react-icons/tb";
import ReactStars from "react-rating-stars-component";
import { CategoryItems } from "../../utils/items";
import ProductCard from "./components/product-card";
import { sortList } from "../../constants/static-menu";
import Button from "../../components/button";
import { AiOutlineClose } from "react-icons/ai";

export default function CategoryWrapper() {
  const params = useParams();
  const navigate = useNavigate();
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [sortOpen, setSortOpen] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false);

  const { category } = params;
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  return (
    <React.Fragment>
      <div className="px-5 lg:py-[30px] py-5 lg:px-20 flex flex-col gap-10  bg-[#F8F8FF]">
        {/* desktop */}
        <div className="hidden lg:flex space-x-2">
          <Text href="/" as="p" classname="text-sm text-gray-400">
            Home
          </Text>
          <MdOutlineArrowForwardIos className="text-gray-400 w-4 h-4" />
          <Text as="p" classname="text-sm capitalize text-gray-400">
            {category}
          </Text>
        </div>
        <div className="hidden lg:flex items-center justify-between">
          <Text as="h1" weight="bold" classname="text-4xl capitalize">
            Search Results - <br />
            {category}
          </Text>
          {/* search input */}
          <Input
            iconLeft={<RiSearch2Line className="w-6 h-6 text-gray-400 " />}
            placeholder="Please type your question here."
            classname="w-[612px] bg-[#EEF1F499]"
          />
          {/* sort */}
          <div className="flex gap-2 items-center w-[300px] ">
            <Text as="p" weight="bold" classname="text-xl w-full text-right">
              Sort by
            </Text>

            <select
              name="sort"
              id="sort"
              className="rounded-md border-none px-3 w-full outline-none py-3 bg-white"
            >
              <option value="popularity">Relevance</option>
              <option value="price">Price</option>
              <option value="rating">Rating</option>
            </select>
          </div>
        </div>

        {/* mobile */}
        <div className="lg:hidden flex justify-between items-center gap-10">
          <MdOutlineArrowBackIosNew
            className="h-6 w-6  text-gray-400"
            onClick={() => navigate(-1)}
          />
          <Text
            as="h1"
            weight="bold"
            classname="text-2xl text-center  capitalize"
          >
            {category}
          </Text>

          <div className="lg:hidden flex items-center justify-between">
            <RiSearchLine
              className="h-6 w-6  text-gray-400"
              onClick={() => setSearchOpen((prev) => !prev)}
            />
            {/* <BiDotsVerticalRounded className="h-6 w-6  text-gray-400" /> */}
          </div>
        </div>
      </div>
      {/* search mobile */}
      {searchOpen && (
        <div>
          <Input
            iconLeft={<RiSearch2Line className="w-6 h-6 text-gray-400 " />}
            placeholder="Please type your question here."
            classname="bg-[#EEF1F499]"
          />
        </div>
      )}
      {/* filter mobile */}
      <div className="flex justify-between items-center px-16 py-1 lg:hidden border-t-2 mt-5 border-b-2">
        <div
          className="flex gap-1 items-center relative"
          onClick={() => setSortOpen((prev) => !prev)}
        >
          <TbArrowsSort className="w-6 h-6 font-medium text-gray-400" />
          <Text as="p" classname="text-sm w-full  text-gray-400">
            Sort by
          </Text>
          {sortOpen && (
            <div className="absolute w-[200px] left-0 top-8 rounded-lg z-10 bg-white shadow-md">
              {sortList.map((item) => (
                <Text
                  as="p"
                  key={item.id}
                  classname="px-4 py-2 rounded-lg hover:bg-gray-100 cursor-pointer"
                >
                  {item.label}
                </Text>
              ))}
            </div>
          )}
        </div>
        <Text as="p" classname="text-center text-gray-400">
          |
        </Text>
        <div
          className="flex gap-1 relative items-center"
          onClick={() => {
            setFilterOpen((prev) => !prev);
          }}
        >
          <BiFilterAlt className="font-medium w-6 h-6 text-gray-400" />
          <Text as="p" classname="text-sm w-full  text-gray-400">
            Filters
          </Text>
          {filterOpen && <MobileFilter setFilterOpen={setFilterOpen} />}
        </div>
      </div>

      <div className="px-5 lg:py-[30px] py-5 lg:px-20 mt-11 lg:mt-20 flex">
        {/* side bar */}
        <div className="hidden lg:flex flex-2 flex-col space-y-6">
          <Text as="h3" weight="medium" classname="text-xl text-gray-500">
            Browse Categories
          </Text>

          <div className="flex flex-col mb-10">
            <Text as="p" weight="medium" classname="text-sm text-gray-500">
              Generator & Power
            </Text>
            <Text as="p" weight="medium" classname="text-sm text-gray-500">
              Solar & Wind Power
            </Text>
          </div>

          <div className="flex flex-col gap-6">
            <Text as="h3" weight="medium" classname="text-xl text-gray-500">
              Brand
            </Text>
            <Input
              iconLeft={<RiSearch2Line className="w-4 h-4 text-gray-400 " />}
              placeholder="search here."
              classname="w-[191px] h-7 bg-white p-0 text-xs"
            />
            <div className="flex flex-col space-y-4 mt-4">
              <div className="flex items-center gap-6">
                <input type="checkbox" className="w-4 h-4" />
                <Text as="p" weight="medium" classname="text-sm text-gray-500">
                  915 Generator
                </Text>
              </div>
              <div className="flex items-center gap-6">
                <input type="checkbox" className="w-4 h-4" />
                <Text as="p" weight="medium" classname="text-sm text-gray-500">
                  Airwick
                </Text>
              </div>
              <div className="flex items-center gap-6">
                <input type="checkbox" className="w-4 h-4" />
                <Text as="p" weight="medium" classname="text-sm text-gray-500">
                  Led
                </Text>
              </div>
              <div className="flex items-center gap-6">
                <input type="checkbox" className="w-4 h-4" />
                <Text as="p" weight="medium" classname="text-sm text-gray-500">
                  Lontor
                </Text>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <Text as="h3" weight="medium" classname="text-xl text-gray-500">
              Price
            </Text>
            <div>
              <input
                id="medium-range"
                min="0"
                max="100"
                type="range"
                step="1"
                class="w-full h-2 mb-6 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
              <div className="flex justify-between">
                <Text as="p" classname="text-sm text-gray-500">
                  N 0
                </Text>
                <Text as="p" classname="text-sm text-gray-500 float-right">
                  N 900,000+
                </Text>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <Text as="h3" weight="medium" classname="text-xl text-gray-500">
              Availability
            </Text>
            <div className="flex items-center gap-6">
              <input type="checkbox" className="w-4 h-4" />
              <Text as="p" weight="medium" classname="text-sm text-gray-500">
                Show available items only
              </Text>
            </div>

            {/* customer ratings */}
            <div className="flex flex-col gap-6">
              <Text as="h3" weight="medium" classname="text-xl text-gray-500">
                Customer Rating
              </Text>

              <div className="flex flex-col space-y-4 mt-4">
                <div className="flex items-center gap-6">
                  <input type="radio" className="w-4 h-4 rounded" />
                  <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    size={20}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                </div>
                <div className="flex items-center gap-6">
                  <input type="radio" className="w-4 h-4" />
                  <ReactStars
                    count={4}
                    onChange={ratingChanged}
                    size={20}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                </div>
                <div className="flex items-center gap-6">
                  <input type="radio" className="w-4 h-4" />
                  <ReactStars
                    count={3}
                    onChange={ratingChanged}
                    size={20}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                </div>
                <div className="flex items-center gap-6">
                  <input type="radio" className="w-4 h-4" />
                  <ReactStars
                    count={2}
                    onChange={ratingChanged}
                    size={20}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                </div>
                <div className="flex items-center gap-6">
                  <input type="radio" className="w-4 h-4" />
                  <ReactStars
                    count={2}
                    onChange={ratingChanged}
                    size={20}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* lists of products */}
        <div className=" lg:flex-1 bg-[#F8F8FF] grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-6 p-0 md:p-12 w-full h-full lg:ml-auto  lg:max-w-[80%]">
          {CategoryItems.map((item) => (
            <ProductCard
              key={item.id}
              item={item}
              isbtn={true}
              category={category}
            />
          ))}
        </div>
      </div>

      <div className="py-[50px] lg:py-[140px] px-5 lg:px-20 flex flex-col gap-16 lg:gap-56 items-center">
        <MayAlsoLike title="Products you may also like" />
      </div>
    </React.Fragment>
  );
}

export function MobileFilter({ classname, isOpen, setFilterOpen }) {
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  return (
    <>
      <div
        className="lg:hidden w-[350px] absolute top-8 -right-8 z-50   bg-white  pb-[80px]"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className="flex items-center justify-between bg-[#F8F8FF] px-5 py-5">
          <AiOutlineClose
            className="w-6 h-6 text-gray-500"
            onClick={() => setFilterOpen(false)}
          />
          <Text as="h3" weight="bold" classname="text-lg">
            Filter
          </Text>
          <Text
            as="p"
            weight="medium"
            classname="cursor-pointer text-sm text-secondary-dark"
          >
            clear all
          </Text>
        </div>
        <div className="px-6 py-6 flex flex-col gap-3">
          <Text as="h3" weight="medium" classname="text-lg text-gray-500">
            Browse Categories
          </Text>

          <div className="flex flex-col mb-5">
            <Text as="p" weight="medium" classname="text-sm text-gray-500">
              Generator & Power
            </Text>
            <Text as="p" weight="medium" classname="text-sm text-gray-500">
              Solar & Wind Power
            </Text>
          </div>

          <div className="flex flex-col gap-3">
            <Text as="h3" weight="medium" classname="text-lg text-gray-500">
              Brand
            </Text>
            <Input
              iconLeft={<RiSearch2Line className="w-4 h-4 text-gray-400 " />}
              placeholder="search here."
              classname="w-[191px] bg-white p-0 text-xs"
            />
            <div className="flex flex-col gap-2 mt-2">
              <div className="flex items-center gap-3">
                <input type="checkbox" className="w-4 h-4" />
                <Text as="p" weight="medium" classname="text-sm text-gray-500">
                  915 Generator
                </Text>
              </div>
              <div className="flex items-center gap-4">
                <input type="checkbox" className="w-4 h-4" />
                <Text as="p" weight="medium" classname="text-sm text-gray-500">
                  Airwick
                </Text>
              </div>
              <div className="flex items-center gap-4">
                <input type="checkbox" className="w-4 h-4" />
                <Text as="p" weight="medium" classname="text-sm text-gray-500">
                  Led
                </Text>
              </div>
              <div className="flex items-center gap-4">
                <input type="checkbox" className="w-4 h-4" />
                <Text as="p" weight="medium" classname="text-sm text-gray-500">
                  Lontor
                </Text>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <Text as="h3" weight="medium" classname="text-lg text-gray-500">
              Price
            </Text>
            <div>
              <input
                id="medium-range"
                min="0"
                max="100"
                type="range"
                step="1"
                class="w-full h-2 mb-6 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
              <div className="flex justify-between">
                <Text as="p" classname="text-sm text-gray-500">
                  N 0
                </Text>
                <Text as="p" classname="text-sm text-gray-500 float-right">
                  N 900,000+
                </Text>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <Text as="h3" weight="medium" classname="text-lg text-gray-500">
              Availability
            </Text>
            <div className="flex items-center gap-4">
              <input type="checkbox" className="w-4 h-4" />
              <Text as="p" weight="medium" classname="text-sm text-gray-500">
                Show available items only
              </Text>
            </div>

            {/* customer ratings */}
            <div className="flex flex-col gap-4">
              <Text as="h3" weight="medium" classname="text-lg text-gray-500">
                Customer Rating
              </Text>

              <div className="flex flex-col gap-2 mt-2">
                <div className="flex items-center gap-6">
                  <input type="radio" className="w-4 h-4 rounded" />
                  <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    size={20}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                </div>
                <div className="flex items-center gap-4">
                  <input type="radio" className="w-4 h-4" />
                  <ReactStars
                    count={4}
                    onChange={ratingChanged}
                    size={16}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                </div>
                <div className="flex items-center gap-4">
                  <input type="radio" className="w-4 h-4" />
                  <ReactStars
                    count={3}
                    onChange={ratingChanged}
                    size={16}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                </div>
                <div className="flex items-center gap-4">
                  <input type="radio" className="w-4 h-4" />
                  <ReactStars
                    count={2}
                    onChange={ratingChanged}
                    size={16}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                </div>
                <div className="flex items-center gap-4">
                  <input type="radio" className="w-4 h-4" />
                  <ReactStars
                    count={2}
                    onChange={ratingChanged}
                    size={16}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-6">
            <Button classname="w-full">Apply Filter</Button>
          </div>
        </div>
      </div>
    </>
  );
}
