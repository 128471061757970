import Text from "../../../components/text";

export const recent_transactions_data = Array.from({ length: 10 }).map(
  (data, i) => ({
    _id: i + 1,
    amount: 56000,
    token: "626668777",
    unit: "1.1",
    date_created: "Oct 05, 2022, 18:30",
    reference_id: `5232464252${i}`,
    name: `Oloko Opeyemi ${i + 1}`,
    apartment: `Apartment ${i + 1}`,
    meter_number: `07348847738${i}`,
  })
);

export const recent_transactions_column = [
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Date Created
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className="flex items-center space-x-4">
        <Text as="p" classname="text-left text-[16px]">
          {value}
        </Text>
      </div>
    ),
    accessor: "Gen_Date",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Name
      </Text>
    ),
    //     Cell: TableIcon,
    accessor: "fullname",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Apartment
      </Text>
    ),
    //     Cell: TableIcon,
    accessor: "apartment",
  },
  {
    //     id: "_",
    Header: (
      <Text
        as="p"
        classname="text-left text-[16px] text-[#2C3048] whitespace-nowrap"
      >
        Meter Number
      </Text>
    ),
    //     Cell: TableIcon,
    accessor: "Meter_id",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Reference ID
      </Text>
    ),
    //     Cell: TableIcon,
    accessor: "refr",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Amount
      </Text>
    ),
    accessor: "Total_paid",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#404669]">
        Token
      </Text>
    ),
    accessor: "Token",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Unit
      </Text>
    ),
    accessor: "Total_unit",
  },

  // {
  //   //     id: "_",
  //   Header: (
  //     <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
  //       Action
  //     </Text>
  //   ),
  //   Cell: ({ value, row }) => (
  //     <div className='flex items-center space-x-4'>
  //       <Text as='p' classname='text-left text-[16px] text-secondary-dark'>
  //         View
  //       </Text>
  //     </div>
  //   ),
  //   accessor: "_id",
  // },
];
