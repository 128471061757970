import React from "react";
import Text from "../../text";
import Input from "../../input";
import Button from "../../button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { AiOutlineApartment } from "react-icons/ai";

export default function EditCategory({ data}) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const formValues = {
    apartment: data.apartment,
   
  };
console.log(formValues)
  const validationSchema = Yup.object({
    category: Yup.string().required("Category is Required"),
  });

  const onSubmit = (values) => {
    const userid = data.userid
    setIsLoading(true)
    setValidationAttempt(false);
    axios.put("https://pm-server.herokuapp.com/api/v2.0/manager/user-modification?query=edit-apartment", {
      ...values,
      userid
    }, {
      headers: {
        Authorization: authUser().authToken,
      },
    }).then(res => {
      navigate('/manager/configure/user-management')
      toast.success(res.data.message)
    }).catch(err => {
      toast.error(err.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <Text
          as="h3"
          weight="bold"
          classname="text-[24px] lg:text-[30px] text-center"
        >
          Edit Category
        </Text>
        <div className="my-6 flex flex-col space-y-6">
          <Input
            iconLeft={<AiOutlineApartment />}
            label="Category Name:"
            placeholder={data.category ?? 'no data'}
            defaultValue={data.category ?? 'no data'}
            type="text"
            name="Category"
            value={formik.values.category}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.category}
          />
        </div>
        <Button isLoading={isLoading}
          onclick={() => setValidationAttempt(true)}
          buttonType="submit" classname="w-full">
          Edit
        </Button>
      </div>
    </form>
  );
}
