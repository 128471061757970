import { create } from 'zustand'


const useUserStore = create((set) => ({
      user: {name:'Oloko'},
      refetchUser: ()=>{},
      authToken: '',
      cost: '',
      setUser: (userInfo) => set(()=>({user:userInfo})),
      setRefetchUser: (refetchFunction) => set(()=>({refetchUser:refetchFunction})),
      setToken: (token) => set(()=>({authToken:token})),
      setCost: (cost) => set(()=>({cost:cost})),
}))


export default useUserStore