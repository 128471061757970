import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { BiCopy } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import ModalWrapper from "../../components/modals/modal-wrapper";
import Text from "../../components/text";
import WalletTransactionCard from "../../components/wallet-transaction-card";
import useWalletInfo from "../../hooks/apis/user/useWalletInfo";
import useUserStore from "../../store/user/store";
import { clx } from "../../utils/clx";
import { WalletFunding } from "./dashboard";
import { toast } from "react-toastify";
import CopyText from "../../components/toast/copy-text";
import { getFormattedPrice } from "../../utils/getFormattedPrice";

export default function WalletPageWrapper({ classname, children }) {
  const [isBalanceHidden, setBalanceHidden] = React.useState(true);
  const [isFundWalletOpen, setFundWalletOpen] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [paymentMethod, setPaymentMethod] = React.useState(false);

  const navigate = useNavigate();
  const { data: wallet } = useWalletInfo({ query: "balance" });
  const user = useUserStore((state) => state.user);
  // console.log(user)
  
  // console.log("User", user);
  
  const { data: walletInfo } = useWalletInfo({
    key: "walletTransaction",
    query: "transactions",
  });

  // console.log(walletInfo)
  
  // console.log("walletinfo", walletInfo)
  // const handleGeneralFundButtonClick = (platform = null) => {
  //   // setFundingPlatform(platform);
  //   setFundWalletOpen(true);
  // };

  const handlePaymentMethodSelect = (method) => {
    setPaymentMethod(method);
    setStep(2);
  };

  const handleModalClose = () => {
    setFundWalletOpen(false);
    setStep(1);
  };

  const classes = clx(
    "h-[calc(100%-70px)] flex flex-col lg:block overflow-hidden lg:h-fit lg:max-h-fit lg:w-[90%] mx-auto",
    classname
  );
  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }
  return (
    <React.Fragment>
      <div className={classes}>
        <div className="lg:flex justify-between space-x-[30px]">
          <div className="w-[100%] lg:w-[600px] lg:h-[347px] p-[40px] bg-primary-dark lg:rounded-[20px] flex flex-col items-center lg:justify-between space-y-[30px]">
            <div className="lg:hidden">
              <Text
                as="p"
                weight="semibold"
                classname="text-[14px] text-white text-center"
              >
                Wallet
              </Text>
              <div className="flex items-center space-x-2 pt-3">
                <Text as="p" classname="text-[14px] text-white text-center">
                  Wallet ID:{" "}
                  <span className="text-[16px]">
                    {user?.walletid ?? "9030332503"}
                  </span>
                </Text>
                <CopyToClipboard
                  text={user?.walletid}
                  onCopy={() =>
                    toast.success(
                      <CopyText>Wallet ID copied successfully!</CopyText>
                    )
                  }
                >
                  <BiCopy className="block text-white cursor-pointer" />
                </CopyToClipboard>
              </div>
            </div>
            <Text
              as="p"
              classname="hidden lg:block text-white text-[21px] text-center"
            >
              Wallet Balance
            </Text>
            <div className="flex items-center space-x-4">
              <Text
                as="h5"
                classname="text-center text-white text-[28px] lg:text-[56px] font-bold"
              >
                {isBalanceHidden
                  ? "*** *** ***"
                  : `₦ ${getFormattedPrice(wallet?.data.balance) ?? "0"}` ??
                    "N500,000"}
              </Text>
              {isBalanceHidden ? (
                <AiFillEyeInvisible
                  className="text-[#9799A5] text-[24px] cursor-pointer"
                  onClick={() => setBalanceHidden((state) => !state)}
                />
              ) : (
                <AiFillEye
                  className="text-[#9799A5] text-[24px] cursor-pointer"
                  onClick={() => setBalanceHidden((state) => !state)}
                />
              )}
            </div>
            <Button
              variant="contained"
              type="secondary"
              size="medium"
              classname="px-[50px] lg:px-[70px]"
              onclick={() => setFundWalletOpen(true)}
            >
              Fund Wallet
            </Button>
          </div>
          <div className="my-[30px] w-[322px] min-h-[150px] max-h-fit px-[30px] rounded-[20px] border-2 border-black hidden lg:flex flex-col justify-center space-y-3">
            <Text as="p" weight="semibold" classname="text-primary-dark">
              Wallet
            </Text>
            <div className="flex items-center space-x-2">
              <Text as="p" weight="medium" classname="text-primary-dark">
                Wallet ID: <span className="font-normal">{user?.walletid}</span>
              </Text>
              <CopyToClipboard
                text={user?.walletid}
                onCopy={() =>
                  toast.success(
                    <CopyText>Wallet ID copied successfully!</CopyText>
                  )
                }
              >
                <BiCopy className="block text-black cursor-pointer" />
              </CopyToClipboard>
            </div>
            <div>
              <Text as="p" weight="semibold" classname="text-primary-dark">
                Virtual Account
              </Text>
              <div className="flex items-center space-x-2">
                <Text as="p" weight="medium" classname="text-primary-dark">
                  Account No:{" "}
                  <span className="font-normal">
                    {user?.virtual_account?.account_number}
                  </span>
                </Text>
                <CopyToClipboard
                  text={user?.virtual_account?.account_number}
                  onCopy={() =>
                    toast.success(
                      <CopyText>Account Number copied successfully!</CopyText>
                    )
                  }
                >
                  <BiCopy className="block text-black cursor-pointer" />
                </CopyToClipboard>
              </div>
              <div className="flex items-center space-x-2">
                <Text as="p" weight="medium" classname="text-primary-dark">
                  Bank Name:{" "}
                  <span className="font-normal">
                    {user?.virtual_account?.bank ?? ""}
                  </span>
                </Text>
              </div>
              <div className="flex items-center space-x-2">
                <Text as="p" weight="medium" classname="text-primary-dark">
                  Account Name:{" "}
                  <span className="font-normal">
                    {user?.virtual_account?.account_name ?? ""}
                  </span>
                </Text>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col py-[10px] lg:py-[50px] w-[93%] lg:w-[100%] mx-auto overflow-hidden">
          <div className="flex items-center justify-between">
            <Text
              as="span"
              classname="text-[16px] lg:text-[23px] py-[20px] font-semibold text-[#6C6F7F]"
            >
              Recent Transaction
            </Text>
            <Text
              as="span"
              classname="text-[16px] lg:text-[23px] text-secondary-dark"
              onclick={() => navigate("/user/wallet-transactions")}
            >
              see all
            </Text>
          </div>
          <div className="flex-1 lg:pb-[20px] flex flex-col overflow-hidden">
            {/* <Text as='p' classname='hidden lg:block py-8 text-[#6C6F7F]'>
            Sunday, October 7 2022
          </Text> */}
            <div className="flex-1 overflow-hidden bg-gray-30">
              <div className="h-full lg:h-fit overflow-auto flex flex-col space-y-6">
                {walletInfo?.data.map((transaction) => (
                  <WalletTransactionCard
                    key={transaction?._id}
                    referenceId={transaction?.refr}
                    status={transaction?.status}
                    amount={transaction?.amount}
                    date={transaction?.day}
                    classname="w-[100%]"
                  />
                ))}
                {/* <WalletTransactionCard classname='w-[100%]' />
              <WalletTransactionCard classname='w-[100%]' />
              <WalletTransactionCard classname='w-[100%]' />
              <WalletTransactionCard classname='w-[100%]' /> */}
              </div>
            </div>
          </div>
          {/* <div className='hidden lg:block pb-[20px]'>
          <Text as='p' classname='py-8 text-[#6C6F7F]'>
            Sunday, October 7 2022
          </Text>
          <div className='flex flex-col space-y-6'>
            <WalletTransactionCard classname='w-[100%]' />
            <WalletTransactionCard classname='w-[100%]' />
          </div>
        </div> */}
        </div>
      </div>

      <ModalWrapper
        isOpen={isFundWalletOpen}
        handleModalClose={handleModalClose}
      >
        <WalletFunding
          step={step}
          paymentMethod={paymentMethod}
          onselect={handlePaymentMethodSelect}
          setStep={setStep}
          handleModalClose={handleModalClose}
        />
      </ModalWrapper>
    </React.Fragment>
  );
}
