import React from "react";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { clx } from "../utils/clx";
import Text from "./text";

export default function FAQ({ body, isOpen, onclick,  title, classname, active, children }) {

  const classes = clx("w-[740px]", classname);
  return (
    <div className={classes}>
      <div className='py-3 px-4 lg:px-8 bg-[#E6E6E9] rounded-[12px] flex items-center space-x-4 justify-between'>
        <Text
          as='p'
          classname='text-[15px] lg:text-[18px] text-primary-dark font-semibold'
        >
          {title}
        </Text>
        <div
          className={`min-w-[25px] lg:min-w-[32px] min-h-[25px] lg:min-h-[32px] rounded-full cursor-pointer ${
            isOpen || active ? "bg-primary-dark" : "bg-white"
          } flex items-center justify-center`}
          onClick={onclick}
        >
          {isOpen || active ? (
            <RxCross2 className='text-white text-[14px] lg:text-base' />
          ) : (
            <FaPlus className='text-primary-dark text-[14px] lg:text-base' />
          )}
        </div>
      </div>
      {(isOpen || active) && (
        <div className='py-6 px-4 lg:px-8 flex flex-col space-y-3'>
          {body.map((content, index) => (
            <Text
              key={index}
              as='article'
              classname='text-[13px] lg:text-[15px]'
            >
              {content}
            </Text>
          ))}
        </div>
      )}
    </div>
  );
}
