import React, { useCallback } from "react";
import Table from "../../../../../components/table"
import { clx } from "../../../../../utils/clx";
import Button from "../../../../../components/button";
import { IoAddOutline } from "react-icons/io5";
import ModalWrapper from "../../../../../components/modals/modal-wrapper";
import { useLocation, useNavigate, useParams } from "react-router";
import Text from "../../../../../components/text";
import { regular_charge_columns } from "../../../../../utils/table-utils/admin/regular-charges-table";
import AddRegularCharges from "../../../../../components/modals/admin/add-regular-charges";


export default function RegularInsights() {
    const navigate = useNavigate()
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [tableData, setTableData] = React.useState([]);
    const { state } = useLocation()
    const { path } = useParams()
    if (!state || state === null) {
        navigate('/manager/configure/charges')
    }
    const loadTableData = useCallback(() => {
        setTableData([])
        state?.data.insights.map((item, index) => {
            setTableData(prev => [...prev,
            {
                id: index + 1,
                name: item.name,
                amount: item.amount,
                insight_slug: item.slug,
                type: state.type,
                charges_slug: path,
                unit: path === "percentage" ? "%" : "₦",
                naira_addup: item.naira_addup,
                cap: item.cap,
                cap_at: item.cap?.cap_at,
                cap_to: item.cap?.cap_to,

            }]
            );
        })
    }, [path])

    React.useEffect(() => {
        loadTableData()
    }, [loadTableData])


    const classes = clx(
        "w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex flex-col"
    );
    return (
        <React.Fragment>
            <div className={classes}>
                <div className="flex flex-row items-center justify-between p-3">
                    <Text as='h2' weight="semi-bold" classname='text-primary-dark text-[18px]'>
                        {state.data.desc}...
                    </Text>
                    <Button
                        variant='text'
                        iconLeft={<IoAddOutline />}
                        onclick={() => setModalOpen(true)}

                        classname=' w-fit text-secondary-dark'
                    >
                        Add
                    </Button>
                </div>
                <div className='w-full h-full rounded-[20px] bg-white p-6 flex flex-col space-y-6'>
                    <Table
                        columns={regular_charge_columns}
                        data={tableData}
                    />
                </div>
            </div>
            <ModalWrapper
                isOpen={isModalOpen}
                handleModalClose={() => setModalOpen(false)}
            >
                {<AddRegularCharges charges_slug={path} type={state.type} />}
            </ModalWrapper>
        </React.Fragment>
    );
}