import React, { useState } from "react";
import GatePasssAccessTabs from "./tabs";
import VisitorsDetails from "./steps/visitors-details";
import Time from "./steps/time";
import AccessCode from "./steps/access-code";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { toast } from "react-toastify";
import useAdminAffiliates from "../../../../../store/admin/affiliates";


export default function CreateAccess() {
    const [currentStep, setCurrentStep] = useState(1);
    const [visitorDetails, setVisitorDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [accessCode, setAccessCode] = useState("");
    const authUser = useAuthUser();
    const { selectedAffiliate } = useAdminAffiliates();

    const steps = ["Visitor Details", "Time", "Access code"];

    const handleFormSubmit = (values) => {
        setVisitorDetails(values);
        if (!values) {
            return;
        }
        setCurrentStep(2);
    }

    const handleTimeSubmit = (values) => {
        if (!values) {
            return;
        }
        const data = {
            ...visitorDetails,
            ...values
        }
        setIsLoading(true);
        axios.post("https://pm-server.herokuapp.com/api/v2.0/user/new-gatepass", {
            ...data,
            tag: selectedAffiliate,
        }, {
            headers: {
                Authorization: authUser().authToken,
            },
        }).then(res => {
            setIsLoading(false);
            toast.success(res.data.message);
            setAccessCode(res.data.data.access_code);
            setCurrentStep(3);
        }).catch(err => {
            toast.error(err.response.data.message);
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        })
    }
    const displaySteps = (step) => {
        switch (step) {
            case 1:
                return <VisitorsDetails
                    onFormSumit={handleFormSubmit}

                />;

            case 2:
                return <Time
                    onButtonClick={handleTimeSubmit}
                    isLoading={isLoading}
                />;
            case 3:
                return <AccessCode
                    accessCode={accessCode}
                    visitorDetails={visitorDetails}

                />;
            default:
                return null;
        }
    };

    // Inside GatePasssAccess component
    return (
        <div>
            <div className="flex py-[88px] flex-col gap-6 rounded-lg mx-auto p-[50px] md:px-[100px] w-full max-w-[787px] bg-white shadow-sm">
                <GatePasssAccessTabs steps={steps} currentStep={currentStep} />
                <div className="flex flex-col gap-6">
                    {displaySteps(currentStep, setCurrentStep)} {/* Pass setCurrentStep if needed */}
                </div>
            </div>
        </div>
    );

}
