import React, { useCallback, useEffect } from "react";
import Table from "../../../components/table";
import { clx } from "../../../utils/clx";
import Input from "../../../components/input";
import Text from "../../../components/text";
import ModalWrapper from "../../../components/modals/modal-wrapper";
import { BsSearch } from "react-icons/bs";
import useAdminUserList from "../../../hooks/apis/admin/useAdminUserList";
import { useSelectedRows } from "../../../store/admin/configure";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { toast } from "react-toastify";
import { restrict_user_column } from "../../../utils/table-utils/admin/restrict-user-table";
import RestrictUserModal from "../../../components/modals/admin/restrict-user";
import UnrestrictUserModal from "../../../components/modals/admin/unrestrict-user";


const RestrictUser = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [selectedType, setSelectedType] = React.useState("");
  const [filteredList, setFilteredList] = React.useState([]);
  const { selectedRows } = useSelectedRows();
  const {
    data: userList,
    isLoading: isUserListLoading,
    isFetching: isUserListFetching,
  } = useAdminUserList()
  const loadMeters = useCallback(() => {
    setFilteredList([])
    userList?.data?.userList?.length && userList?.data?.userList?.map((item) => {
      const { fullname, apartment, energyid, waterid, restriction, userid } = item
      const isEnergy = selectedType.toLowerCase() === "energy"
      if ((isEnergy && energyid?.length) || (!isEnergy && waterid?.length)) {
        let mappedRestriction = restriction.split("").map((char, index) => {
          if (index < restriction?.length - 1) {
            const twoDigit = restriction?.slice(index, index + 2);
            switch (twoDigit) {
              case "32":
                return "Switch, Vend";
              case "23":
                return "Vend, Switch";
              case "31":
                return "Switch, Login";
              case "13":
                return "Login, Switch";
              case "21":
                return "Vend, Login";
              case "12":
                return "Login, Vend";
                
            }
          } else {
            switch (char) {
              case "1":
                return "Login";
              case "2":
                return "Vend";
              case "3":
                return "Switch";
              case "4":
                return "Gatepass Access";
              default:
                return char;
            }
          }
        }).join("");

        if (
          mappedRestriction.includes("Login") && mappedRestriction.includes("Vend")
        ) {
          mappedRestriction = "Login, Vend";
        } else if (
          mappedRestriction.includes("Login") && mappedRestriction.includes("Switch")
        ) {
          mappedRestriction = "Login, Switch";
        } else if (
          mappedRestriction.includes("Vend") && mappedRestriction.includes("Switch")
        ) {
          mappedRestriction = "Vend, Switch";
        } else if (
          mappedRestriction.includes("Login") &&
          mappedRestriction.includes("Vend") &&
          mappedRestriction.includes("Switch")
        ) {
          mappedRestriction = "Login, Vend, Switch";
        }



        setFilteredList((prev) => [...prev, {
          fullname,
          apartment,
          meterid: isEnergy ? energyid : waterid,
          type: selectedType,
          restriction: mappedRestriction,
          userid
        }])
      }
    }
    )
  }, [selectedType, userList]);


  useEffect(() => {
    loadMeters()

  }, [loadMeters])

  const handleSelectType = useCallback((e) => {
    setSelectedType(e.target.value)
  }, [])

  useEffect(() => {
    setSelectedType("energy")
  }, []);
  const handleRestictClick = useCallback(() => {
    if (selectedRows.length === 0) return toast.error("Please select a row")
    else if (selectedRows.length > 1) return toast.error("Please select only one row at a time")
    else {
      setModalOpen(true);
      setModalType("restrict");
    }
  }, [selectedRows]);

  const handleUnrestictClick = useCallback(() => {
    if (selectedRows.length === 0) return toast.error("Please select a row")
    else if (selectedRows.length > 1) return toast.error("Please select only one row at a time")
    else {
      setModalOpen(true);
      setModalType("unrestrict");
    }
  }, [selectedRows]);

  const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
  return (
    <React.Fragment>

      <div className={classes}>
        <div className="max-w-full h-full rounded-[8px] bg-white p-6 flex flex-col space-y-6 relative">
          <div className="flex flex-row justify-between items-center">
            <div
              className="flex flex-row items-center space-x-2"
            >
              <Text
                as="p"
                weight="semibold"
                classname="lg:text-[16px] text-secondary-dark cursor-pointer hover:underline"
                onclick={handleRestictClick}
              >
                Restrict User
              </Text>
              <span>
                |
              </span>
              <Text
                as="p"
                weight="semibold"
                classname="lg:text-[16px] text-secondary-dark cursor-pointer hover:underline"
                onclick={handleUnrestictClick}
              >
                Unrestrict User  
              </Text>


            </div>
            <div className="flex justify-end items-center space-x-6">

              <div className="flex flex-row gap-3 items-center">
                <Text as="p" weight="semibold">
                  Meter Type:
                </Text>
                <select name="" id=""
                  value={selectedType}
                  onChange={handleSelectType}
                  className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
                >
                  <option value="energy">Energy</option>
                  <option value="water">Water</option>
                </select>
              </div>
              <div className="flex items-center">
                <Input
                  iconLeft={<BsSearch />}
                  placeholder="Search By Meter ID"
                  inputClass="py-[6px] px-[15px] rounded-r-0"
                  onchange={({ target }) => setSearch(target.value)}
                />
              </div>
            </div>
          </div>
          <Table
            search={search}
            data={filteredList ?? []}
            columns={restrict_user_column}
            isLoading={isUserListLoading || isUserListFetching}
          />
        </div>
      </div>

      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => setModalOpen(false)}>
        {modalType === "restrict" && <RestrictUserModal selectedRows={selectedRows} />}
        {modalType === "unrestrict" && <UnrestrictUserModal selectedRows={selectedRows} />}
      </ModalWrapper>
    </React.Fragment>
  );
};

export default RestrictUser;
