import { create } from "zustand";
import { useCookies } from "react-cookie";



const useAuthV2 = create((set) => ({
  authv2: '',
  setAuthV2: (auth) => set(() => ({ authv2: auth })),
  setAuthAndCookie: (auth) => {
    set(() => ({ authv2: auth }));
    useCookies.setCookie('Auth2', { token: auth }, { /* cookie options */ });
  },
}));

export default useAuthV2;
