import { AiFillSetting } from "react-icons/ai";
import { BsFillChatDotsFill } from "react-icons/bs";
import { FaQuestionCircle, FaUserAlt } from "react-icons/fa";
import { IoWallet } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";
import { MdPayment } from "react-icons/md";

export const userMenu = [
  {
    id: 1,
    label: "Dashboard",
    icon: <RxDashboard className="text-[20px] lg:text-[24px]" />,
    pageTitle: "Overview",
    route: "/user",
  },
  {
    id: 2,
    label: "Wallet",
    icon: <IoWallet className="text-[20px] lg:text-[24px]" />,
    pageTitle: "Wallet",
    route: "/user/wallet",
  },
  {
    id: 3,
    label: "Chat",
    icon: <BsFillChatDotsFill className="text-[20px] lg:text-[24px]" />,
    pageTitle: "Chat",
    route: "/user/chat",
  },
  {
    id: 4,
    label: "FAQs",
    icon: <FaQuestionCircle className="text-[20px] lg:text-[24px]" />,
    pageTitle: "FAQs",
    route: "/user/faqs",
  },
  {
    id: 5,
    label: "Profile",
    icon: <FaUserAlt className="text-[20px] lg:text-[24px]" />,
    pageTitle: "My Profile",
    route: "/user/profile",
  },
  {
    id: 6,
    label: "Payments",
    icon: <MdPayment className="text-[20px] lg:text-[24px]" />,
    pageTitle: "Payments",
    route: "/user/payments",
  },
  {
    id: 7,
    label: "Services",
    icon: <AiFillSetting className="text-[20px] lg:text-[24px]" />,
    pageTitle: "Services",
    route: "/user/other-services",
  },
];
