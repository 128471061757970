import React from "react";
import {BsArrowUp} from 'react-icons/bs'
import { clx } from "../utils/clx";
import Text from "./text";

export default function NotificationContainer({ title, date, time, classname, children }) {
  const classes = clx("flex items-center space-x-3 w-full", classname);
  return (
    <div className={classes}>
      <div className='min-w-[50px] min-h-[50px] rounded-full bg-[#F5F5FE] flex items-center justify-center'>
        <BsArrowUp className="text-green-500 text-base lg:text-[20px]" />
      </div>
      <div className='flex-1'>
        <Text
          as='p'
          weight='semibold'
          classname='text-[#6C6F7F] text-[13px] lg:text-base'
        >
          {title}
        </Text>
        <div className='flex items-center space-x-4'>
          <Text
            as='p'
            weight='semibold'
            classname='text-[#9799A5] text-[11px] lg:text-[12px]'
          >
            {date}
          </Text>
          <Text
            as='p'
            weight='semibold'
            classname='text-[#9799A5] text-[11px] lg:text-[12px]'
          >
            {time}
          </Text>
        </div>
      </div>
    </div>
  );
}
