import React from "react";
import Text from "../../text";
import Button from "../../button";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router";
import useAdminAffiliates from "../../../store/admin/affiliates";
import { toast } from "react-toastify";
export default function DeleteUser({ onButtonClick, data }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const { selectedAffiliate } = useAdminAffiliates();
  const navigate = useNavigate();
  const authUser = useAuthUser();

  const onSubmit = (values) => {
    const userid = data.userid
    setIsLoading(true)
    axios.put("https://pm-server.herokuapp.com/api/v2.0/manager/user-modification?query=delete-user", {
      userid
    }, {
      headers: {
        Authorization: authUser().authToken,
      },
    }).then(res => {
      navigate('/manager/configure/user-management')
      toast.success(res.data.message)
    }).catch(err => {
      toast.error(err.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  };

  return (
    <div>
      <Text
        as="h3"
        weight="bold"
        classname="text-[24px] lg:text-[30px] text-center"
      >
        Delete User
      </Text>
      <div className="my-6 flex flex-col space-y-6">
        <Text as="p" weight="medium" classname=" text-secondary-dark">
          Are you sure you want to delete this user {data?.fullname}?
        </Text>
      </div>
      <Button onclick={onSubmit} isLoading={isLoading} classname="w-full">
        Delete
      </Button>
    </div>

  );
}
