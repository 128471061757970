import React from "react";
import { Route } from "react-router-dom";
import Homepage from "../pages/api-docs/homepage";

export default function GetApiDocsRoutes() {
  return (
    <React.Fragment>
      <Route path="/api-documentation">
        <Route index element={<Homepage />} />
      </Route>
    </React.Fragment>
  );
}
