import React, { useMemo, useState } from "react";
import Text from "../../text";
import Multiselect from 'multiselect-react-dropdown';
import useAdminAffiliates from "../../../store/admin/affiliates";
import Button from "../../button";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

export default function GrantBranch({ data }) {
    const [tags, setTags] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const { affiliates } = useAdminAffiliates();
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const options = useMemo(() => {
        const options = []
        Object.keys(affiliates).map(

            (affilate) => {
                if (!data.tag.includes(affilate) && data.company === affilate?.split('-')[0]?.toLowerCase()) {
                    options.push({ tag: affilate })
                }
            }
        )
        return options
    }, [data])


    const handleGrantBranch = () => {
        if (tags.length === 0) {
            return toast.error("Select a Branch")
        }
        setIsLoading(true)
        axios.put("https://pm-server.herokuapp.com/api/v2.0/manager/actions?query=grant-branch-access", {
            tags: tags.map(tag => tag.tag),
            adminid: data.adminid
        }, {
            headers: {
                Authorization: authUser().authToken,
            },
        }).then(res => {
            // console.log(res)
            setIsLoading(false)
            navigate('/manager/configure/admin-management')
            toast.success(res.data.message)
        }).catch(err => {
            // console.log(err)
            setIsLoading(false)
            toast.error(err.response.data.message)
        }).finally(() => {
            setIsLoading(false)
        })

    }


    return (
        <div>
            <Text as="h3"
                weight="bold"
                classname="text-[24px] lg:text-[30px] text-center">
                Grant Branch
            </Text>

            <div className="flex flex-col  space-y-6">
                <Multiselect
                    style={{
                        chips: {
                            background: "#b4b5b6",
                        },
                        searchBox: {
                            border: "none",

                        },
                        optionContainer: {
                            border: "none",
                            background: "#bbbbbb",
                        },
                        option: {
                            padding: "10px",
                            // border: "none",
                            color: "#010623",
                            background: "#fff",
                        },
                    }}
                    options={options}
                    selectedValues={tags}
                    showCheckbox
                    onSelect={(e) => setTags(e)}
                    onRemove={(e) => setTags(e)}
                    displayValue="tag"
                    placeholder="Select Branch"
                    className="max-w-[300px] border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
                />
                <Button classname="w-full" isLoading={isLoading} onclick={
                    handleGrantBranch
                } >
                    Grant Branch
                </Button>
            </div>


        </div >
    )
}