import React from "react";
import { clx } from "../../utils/clx";
import Text from "../text";


export default function ErrorToast({ classname, closeToast, toastProps, children }) {
      const classes=clx('flex items-center space-x-4')
      return (
            <div className={classes}>
                  {/* <div className="w-[40px] h-[40px] rounded-full bg-red-500"></div> */}
                  <Text as='p' weight="semibold" classname='text-[14px] lg:text-base text-red-500'>{children}</Text>
            </div>
      )
}