import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';

import axios from 'axios';
import useSelectedEnergyUser from '../../../store/admin/selected-energy-user';

const useGetRecentTransaction = () => {
  const authUser = useAuthUser();
  const { selectedEnergyUser: user } = useSelectedEnergyUser();
  const [transact, setTransact] = useState([]);

  useEffect(() => {
    const getRecentTransaction = async () => {
      try {
        const response = await axios.post(
          'https://pm-server.herokuapp.com/api/v2.0/manager/vend-txn?query=default',
          {
            slug: 'user',
            userid: user?.userid,
            prepaid: 'energy',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: authUser().authToken,
            },
          }
        );

        setTransact(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    getRecentTransaction();
  }, []);

  return { transact };
};

export default useGetRecentTransaction;

export const getRecentTransaction = async (userId,authTransact) => {
  const response = await axios.post(
    'https://pm-server.herokuapp.com/api/v2.0/manager/vend-txn?query=default',
    {
      slug: 'user',
      userid: userId,
      prepaid: 'energy',
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authTransact,
        // 'x-api-key':'GINJAR469JhRtV4ZDioSfa/kWxOe8MnvqEhpTL',
        // Authorization: authUser().authToken,
      },
    }
  );

  return response;
}




