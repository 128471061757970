import axios from "axios";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { toast } from "react-toastify";
import useUserStore from "../store/user/store";
import { clx } from "../utils/clx";
import Button from "./button";
import ModalWrapper from "./modals/modal-wrapper";
import SwitchDialogue from "./modals/switch-dialogue";
import { MeterSwitch } from "./switch";
import Text from "./text";
import CopyText from "./toast/copy-text";
import { useAuthUser } from "react-auth-kit";

export default function MeterIDContainer({
  number,
  useFor,
  classname,
  relay_state,
  remote
}) {
  const { user, refetchUser } = useUserStore((state) => state);
  const [isMeterOn, setMeterOn] = React.useState();
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isSwitching, setSwitching] = React.useState(false);
  const authUser = useAuthUser();

  React.useEffect(() => {
    setMeterOn(relay_state);
  }, [user, isMeterOn]);

  const handleMeterToggle = () => {
    // setMeterOn(status => !status);
    setSwitching(true);
    axios
      .put(
        "https://api-pm-e1ea777feac4.herokuapp.com/metering/switch-meter",
        {
          prepaid: "energy",
          action: isMeterOn ? "off" : "on",
          userid: user?.userid,
        },
        {
          headers: {
            Authorization: authUser().authToken,
          },
        }
      )
      .then((res) => {
        refetchUser();
        setModalOpen(false);
        setSwitching(false);
      })
      .catch((err) => {
        setModalOpen(false);
        setSwitching(false);
      });
  };

  const classes = clx(
    "rounded-[12px] py-3 bg-primary-light px-3 flex justify-between",
    useFor === "generator" ? "w-[320px]" : "w-[340px]",
    classname
  );
  return (
    <React.Fragment>
      <div className={classes}>
        <div className="flex items-center space-x-3">
          <img src="/assets/icons/meter-id-icon.png" alt="" />
          <Text as="span" classname="text-[#F8F8FF]">
            {number}
          </Text>
          <div>
            <CopyToClipboard
              text={number}
              onCopy={() =>
                toast.success(
                  <CopyText>Meter ID copied successfully!</CopyText>
                )
              }
            >
              <BiCopy className="block text-[#F8F8FF] text-[15px] lg:text-[18px] cursor-pointer" />
            </CopyToClipboard>
          </div>
        </div>
        {useFor === "generator" ? (
          <div className="bg-[#DB9800] hover:bg-secondary-dark/80 cursor-pointer px-[10px] py-[2px] rounded-[8px]">
            <Text as="span" classname="text-white">
              Subscribe
            </Text>
          </div>
        ) : (
          <MeterSwitch
            isMeterOn={isMeterOn}
            isActive={isMeterOn}
            toggleMeterOn={() => setModalOpen(true)}
            // onAccept={handleMeterToggle}
            // onReject={() => setModalOpen(false)}
          />
        )}
      </div>
      <ModalWrapper
        isOpen={remote && isModalOpen}
        handleModalClose={() => {
          setModalOpen(false);
          setSwitching(false);
        }}
      >
        <SwitchDialogue
          isSwitching={isSwitching}
          isMeterOn={isMeterOn}
          onAccept={remote? handleMeterToggle : null}
          onReject={() => setModalOpen(false)}
        />
      </ModalWrapper>
    </React.Fragment>
  );
}
