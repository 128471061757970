import React from 'react';
import Button from '../../../../components/button';
import { CgTrash } from "react-icons/cg";
import ModalWrapper from '../../../../components/modals/modal-wrapper';
import EditAdminManager from '../../../../components/modals/admin/edit-admin-manager';
import RemoveAdminManager from '../../../../components/modals/admin/remove-admin-manager';


export default function AdminManagerActions({ value, row }) {
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [modalType, setModalType] = React.useState("");

    const handleButtonClick = (type) => {
        setModalType(type);
        setModalOpen(true);
    };

    // console.log(row.original);
    return (
        <React.Fragment>
            <div className="flex items-center space-x-6">
                {/* <FiLock color="gray" /> */}
                <Button
                    variant="text"
                    classname=" w-fit  text-secondary-dark"
                    onclick={() => handleButtonClick("edit")}
                >
                    Edit
                </Button>
                <Button
                    variant="text"
                    iconLeft={<CgTrash />}
                    classname=" w-fit  text-secondary-dark"
                    onclick={() => handleButtonClick("remove")}
                />
            </div>

            <ModalWrapper
                isOpen={isModalOpen}
                handleModalClose={() => setModalOpen(false)}
            >
                {modalType === "edit" && <EditAdminManager data={row.original} />}
                {modalType === "remove" && <RemoveAdminManager data={row.original} />}
            </ModalWrapper>
        </React.Fragment>
    );
}