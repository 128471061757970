import React from "react";
import { Link } from "react-router-dom";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";
import useAdminRank from "../../../store/admin/adminRank";

export default function UserMangementWrapper() {

    const { adminRank } = useAdminRank()

    const routes = [
        {
            id: 1,
            title: "Restrict User",
            image: "/assets/icons/restrict.png",
            path: "restrict-user",
            rank: 0
        },
        {
            id: 2,
            title: "Reset User",
            image: "/assets/icons/reset.png",
            path: "reset-user",
            rank: 1
        },
        {
            id: 3,
            title: "New Meter",
            image: "/assets/icons/new-meter.png",
            path: "new-meter",
            rank: 1
        },
        {
            id: 4,
            title: "Credit Meter",
            image: "/assets/icons/credit.png",
            path: "credit-meter",
            rank: 1
        },
        // {
        //     id: 5,
        //     title: "Transfer Meter",
        //     image: "/assets/icons/transfer.png",
        //     path: "",
        //     rank: 0
        // },
        {
            id: 6,
            title: "Switch Meter",
            image: "/assets/icons/switch.png",
            path: "switch-meter",
            rank: 0
        },
        {
            id: 7,
            title: "Check Reference",
            image: "/assets/icons/check-reference.png",
            path: "check-reference",
            rank: 0
        },
        {
            id: 8,
            title: "Send Token",
            image: "/assets/icons/send-token.png",
            path: "send-token",
            rank: 0
        },
        {
            id: 9,
            title: "New Service",
            image: "/assets/icons/new-service.png",
            path: "",
            rank: 1
        },
        {
            id: 10,
            title: "User Charges",
            image: "/assets/icons/user-charges.png",
            path: "user-charges",
            rank: 1
        },
        {
            id: 11,
            title: "Other Settings",
            image: "/assets/icons/other-settings.png",
            path: "",
            rank: 5
        },
        {
            id: 12,
            title: "Virtual Account",
            image: "/assets/icons/virtual-account.png",
            path: "",
            rank: 5
        },
        {
            id: 13,
            title: "Concentrator Mode",
            image: "/assets/icons/concentrator.png",
            path: "",
            rank: 1
        },
        {
            id: 14,
            title: "Unregistered Users",
            image: "/assets/icons/restrict.png",
            path: "unregistered-users",
            rank: 0
        },
        {
            id: 15,
            title: "Users",
            image: "/assets/icons/restrict.png",
            path: "users",
            rank: 1
        }
    ];

    const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
    return (
        <div className={classes}>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 gap-[40px] mt-[20px] items-center">

                {
                    routes.map((item) => {
                        if (item.rank <= adminRank) {
                            return (
                                <Link
                                    to={`/manager/configure/user-management/${item.path}`}
                                    key={item.id}
                                    className="hover:shadow-md"
                                >
                                    <div
                                        key={item.id}
                                        className="flex flex-col items-center  justify-center w-full bg-white  rounded-md p-[20px] h-[200px] gap-3"
                                    >
                                        <img src={item.image} className="w-[78px]" alt="icon" />
                                        <Text as="p" classname={`font-bold`}>
                                            {item.title}
                                        </Text>
                                    </div>
                                </Link>
                            )
                        }
                    })}
            </div>
        </div>
    );
}
