import React from "react";
import { clx } from "../../../../utils/clx";
import Text from "../../../text";
import { getFormattedPrice } from "../../../../utils/getFormattedPrice";
import { FaMoneyBillWave } from "react-icons/fa";

const UserChargesCard = ({
  variant = "secondary",
  platform,
  unit,
  price,
  title,
  noPrice,
  classname,
  children,
}) => {
  const classes = clx(
    "group rounded-[8px] w-full h-fit p-4 lg:p-6 cursor-pointer",
    variant === "primary"
      ? "bg-[#E6E6FB] hover:bg-[#1A1882]"
      : variant === "secondary"
      ? "bg-[#FBF5E6] hover:bg-secondary-dark"
      : "bg-[#EBEDF4] hover:bg-[#6C6F7F]",
    classname
  );
  return (
    <div className={classes}>
      <div className="flex space-x-4 items-start">
        <div
          className={`w-[32px] h-[32px] md:w-[45px] md:h-[45px] rounded-[4px] flex items-center justify-center ${
            variant === "primary"
              ? "bg-[#A4A7FF]/30 group-hover:bg-[#071252]"
              : variant === "secondary"
              ? "bg-[#EAC36B]/30 group-hover:bg-[#865D00]"
              : "bg-[#A4A7FF]/30 group-hover:bg-[#010415]/30"
          }`}
        >
        <FaMoneyBillWave />
        </div>
        <div className="py-0">
          <Text
            as="p"
            classname={`text-[11px] md:text-[12px] ${
              variant === "primary" || variant === "tertiary"
                ? "text-[#6C6F7F] group-hover:text-white"
                : "text-[#6C6F7F] group-hover:text-black"
            }`}
          >
            {title}
          </Text>
          <div className="pt-4 md:flex items-center space-x-3">
    
            {!noPrice && (
              <div className="text-[#6C6F7F] group-hover:text-white px-3  group-hover:border-white">
                <Text as="span">
                  ₦{price ? getFormattedPrice(price) : "0"} 
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default UserChargesCard;
