import React from "react";
import { clx } from "../utils/clx";

export default function OTP({ getOTP, classname, children,len=4 }) {
  const [otp, setOTP] = React.useState(new Array(len).fill(""));
  const [activeBox, setActiveBox] = React.useState(0);
  const inputRef = React.useRef();

  const handleOTPChange = (target, index) => {
    const virtualArray = [...otp];
    if (!target?.value) {
      virtualArray[index] = "";
      setActiveBox(index - 1);
    } else {
      virtualArray[index] = target?.value[target?.value.length - 1];
      setActiveBox(index + 1);
    }
    setOTP(virtualArray);
  };

  const handleKeyDown = (target, key, index) => {
    switch (key) {
      case "Backspace":
        if (index === otp.length - 1) {
          otp[index] = "";
        }
        otp[index] = "";
        otp[index - 1] = "";
        activeBox > 0 && setActiveBox(index - 1);
        break;
      // case 'Tab':
      //   if (!target?.value) {
      //     setActiveBox(index-2)
      //   }
      //   break;
      default:
        return null;
    }
  };

  React.useEffect(() => {
    inputRef.current?.focus();
    getOTP && getOTP(otp.join(""));
  }, [activeBox]);

  return (
    <div className='flex space-x-6'>
      {otp.map((otpNumber, index) => (
        <input
          onFocus={() => setActiveBox(index)}
          key={index}
          ref={activeBox === index ? inputRef : null}
          type='number'
          value={otp[index]}
          maxLength={2}
          className={`w-[50px] lg:w-[40px] text-center text-[28px] lg:text-[40px] font-semibold outline-none px-2 py-2 border-2 rounded-[10px] lg:rounded-none lg:border-b-[3px] lg:border-r-0 lg:border-t-0 lg:border-l-0 bg-[#E6E6E9] lg:bg-transparent ${
            index < activeBox || index === activeBox
              ? "border-primary-dark/30 lg:border-primary-dark"
              : "border-primary-[#D9D9D9]"
          }`}
          onChange={({ target }) => handleOTPChange(target, index)}
          onKeyDown={({ key, target }) => handleKeyDown(target, key, index)}
        />
      ))}
    </div>
  );
}
