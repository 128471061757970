import React from "react";
import { Route } from "react-router-dom";
import usePageTitle from "../hooks/usePageTitle";
import ProtectedUserDashboard from "../layouts/dashboard/user/protected-user-dashboard";
import ChatPage from "../pages/user/chat";
import DashboardPage from "../pages/user/dashboard";
import EnergyDashboard from "../pages/user/energy-dashboard";
import EnergyStatisticsPage from "../pages/user/energy-statistics";
import EnergyStatisticsAreaPage from "../pages/user/energy-statistics-area";
import EnergyTransactionPage from "../pages/user/energy-transaction";
import FAQPage from "../pages/user/faq";
import FuelDashboard from "../pages/user/fuel-dashboard";
import FuelStatisticsPage from "../pages/user/fuel-statistics";
import FuelStatisticsAreaPage from "../pages/user/fuel-statistics-area";
import ProfilePage from "../pages/user/profile";
import WalletPage from "../pages/user/wallet";
import WalletTransactionsPage from "../pages/user/wallet-transactions";
import WaterConsumption from "../pages/user/water-dashboard";
import WaterStatisticsPage from "../pages/user/water-statistics";
import WaterStatisticsAreaPage from "../pages/user/water-statistics-area";
import WaterTransactionPage from "../pages/user/water-transaction";
import EditProfile from "../views/user/edit-profile";
import { userMenu } from "../constants/user-menu";
import OtherServicesPage from "../pages/user/other-services";
import PeriodicCharges from "../views/user/periodic-charges";
import GatePasssAccess from "../views/user/gate-pass/gate-pass";
import PaymentsPage from "../pages/user/payments";
import DiscoElectricityMeterPage from "../pages/user/disco-electricity-meter";
import DiscoMeterPage from "../pages/user/disco-meter";
import DiscoMeterTransaction from "../pages/user/disco-meter-transaction";

export default function GetUserRoutes() {
  const titledRoutes = [
    ...userMenu,
    {
      id: 1,
      pageTitle: "Prepaid Energy Dashboard",
      route: "/user/energy",
    },
    {
      id: 2,
      pageTitle: "Prepaid Water Dashboard",
      route: "/user/water",
    },
    {
      id: 3,
      pageTitle: "Fuel Monitoring Dashboard",
      route: "/user/fuel",
    },
    {
      id: 4,
      pageTitle: "Statistics",
      route: "/user/energy-statistics",
    },
    {
      id: 5,
      pageTitle: "Edit Profile",
      route: "/user/edit-profile",
    },
    {
      id: 6,
      pageTitle: "Periodic Charges",
      route: "/user/periodic-charges",
    },
    {
      id: 7,
      pageTitle: "Gate Pass/ Access",
      route: "/user/gate-pass-access",
    },
    {
      id: 8,
      pageTitle: "Disco Electricity Meter",
      route: "/user/disco-meters",
    },
  ];
  const pageTitle = usePageTitle(titledRoutes);

  return (
    <Route
      path="/user"
      element={<ProtectedUserDashboard pageTitle={pageTitle} />}
    >
      <Route index element={<DashboardPage />} />
      <Route path="wallet" element={<WalletPage />} />
      <Route path="wallet-transactions" element={<WalletTransactionsPage />} />
      <Route path="faqs" element={<FAQPage />} />
      <Route path="periodic-charges" element={<PeriodicCharges />} />
      <Route path="gate-pass-access" element={<GatePasssAccess />} />
      <Route path="profile" element={<ProfilePage />} />
      <Route path="other-services" element={<OtherServicesPage />} />
      <Route path="payments" element={<PaymentsPage />} />
      <Route path="edit-profile" element={<EditProfile />} />
      <Route path="chat" element={<ChatPage />} />
      <Route path="energy" element={<EnergyDashboard />} />
      <Route path="water" element={<WaterConsumption />} />
      <Route path="fuel" element={<FuelDashboard />} />
      <Route path="fuel-statistics" element={<FuelStatisticsPage />} />
      <Route path="fuel-statistics-area" element={<FuelStatisticsAreaPage />} />
      <Route path="water-transaction" element={<WaterTransactionPage />} />
      <Route path="water-statistics" element={<WaterStatisticsPage />} />
      <Route
        path="water-statistics-area"
        element={<WaterStatisticsAreaPage />}
      />
      <Route path="disco-meters">
        <Route index element={<DiscoElectricityMeterPage />} />
        <Route path=":slug" element={<DiscoMeterPage />} />
        <Route path=":slug/disco-meter-transaction" element={<DiscoMeterTransaction />} />
      </Route>
      <Route path="energy-transaction" element={<EnergyTransactionPage />} />
      <Route path="energy-statistics" element={<EnergyStatisticsPage />} />
      <Route
        path="energy-statistics-area"
        element={<EnergyStatisticsAreaPage />}
      />
    </Route>
  );
}
