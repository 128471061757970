import React, { useMemo } from "react";
import Text from "../../text";
import Button from "../../button";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import useAdminAffiliateInfo from "../../../hooks/apis/admin/useAdminAffiliateInfo";

const allRestrictions = [
    {
        id: 1,
        title: "Login",
    },
];

export default function RestrictManagerModal({ selectedRows }) {
    const [restriction, setRestriction] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const affiliateUser = useAdminAffiliateInfo();
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const adminid = useMemo(() => {
        const adminid = selectedRows.map((item) => item.original.adminid)
        return adminid[0]
    }, [selectedRows]);
    const isRestricted = useMemo(() => {
        return selectedRows.map((item) =>
            Array.isArray(item.original.restriction)
                ? item.original.restriction.map((r) => r.toLowerCase())
                : item.original.restriction
                    .split(",")
                    .map((r) => r.trim().toLowerCase())
        );
    }, [selectedRows]);
    const availableRestrictions = allRestrictions.filter((restriction) =>
        isRestricted.every((r) => !r.includes(restriction.title.toLowerCase()))
    );

    const handleSubmit = () => {
        if (!restriction) return toast.error("Please select a restriction to apply");
        setIsLoading(true);
        axios.put("https://pm-server.herokuapp.com/api/v2.0/manager/manager-restriction?query=add", {
            adminid: adminid,
            restriction: restriction,
        }, {
            headers: {
                Authorization: authUser().authToken,
                "x-api-key": affiliateUser?.apikey,
            },
        }).then(res => {
            // console.log(res)
            setIsLoading(false)
            navigate('/manager/configure/admin-management')
            toast.success(res.data.message)

        }).catch(err => {
            // console.log(err)
            setIsLoading(false)
            toast.error(err.response.data.message)

        }).finally(() => {
            setIsLoading(false)
        })
    };

    return (
        <div className="flex flex-col space-y-4">
            <Text as="h3" weight="bold" classname="text-[24px] lg:text-[30px] text-center]"
            >
                Restrict Manager
            </Text>
            {availableRestrictions.length > 0 ? (
                <div className="flex flex-col items-center space-y-4">
                    <select
                        value={restriction}
                        onChange={(e) => {
                            setRestriction(e.target.value);
                        }}
                        className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
                        required
                    >
                        <option value="">Select a restriction to apply</option>
                        {availableRestrictions.map((restriction) => (
                            <option key={restriction.id} value={restriction.id}>
                                {restriction.title}
                            </option>
                        ))}
                    </select>
                    <Button isLoading={isLoading} classname="w-full" onclick={handleSubmit}>
                        Apply
                    </Button>
                </div>
            ) : (
                <Text
                    as="p"
                    weight="semibold"
                    classname="text-center text-secondary-dark"
                >
                    All restrictions are already applied to the selected Manager
                </Text>
            )}
        </div>
    );
}
