import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/button";
import Text from "../../../components/text";
import { GiMegaphone } from "react-icons/gi";

const ComingSoon = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex  items-center justify-center">
      <div className="bg-transparent md:bg-white p-3 md:p-8 rounded-lg flex flex-col space-y-3 md:shadow-md text-center">
        <GiMegaphone className="w-20 md:w-40 h-20 md:h-40 mx-auto text-secondary-dark" />
        <Text as="h1" classname="text-3xl font-semibold">
          Coming Soon!
        </Text>
        <Text as="p">
          Our awesome feature is under construction. Stay tuned for updates!
        </Text>

        {/* Add a button to link back to the home page */}
        <Button href="/" classname="w-full">
          Go to home
        </Button>
      </div>
    </div>
  );
};

export default ComingSoon;
