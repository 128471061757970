import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import Text from "../../components/text";
import AuthLayout from "../../layouts/auth-layout";
import { clx } from "../../utils/clx";

export default function RechargePageWrapper() {
  return (
    <AuthLayout>
      <div>
        <Text
          as='h1'
          classname='mb-[30px] font-[600] text-[54px] text-primary-dark text-center'
        >
          Recharge
        </Text>
        <div className='w-[550px] py-6 rounded-[16px] bg-[#F8F8FF] flex flex-col justify center items-center space-y-6'>
          <RechargePageWrapper.Dropdown label='Wallet' />
          <RechargePageWrapper.Dropdown label='Meter Id' />
          <RechargePageWrapper.Dropdown label='Fuel Monitoring Subscription' />
        </div>
      </div>
    </AuthLayout>
  );
}

export function Dropdown({ label, classname, children }) {
  const classes = clx(
    "rounded-[15px] border-2 border-black/10 px-6 py-4 w-[70%] mx-auto flex items-center justify-between",
    classname
  );
  return (
    <div className={classes}>
      <div className='flex items-center space-x-6'>
        <div>
          <img src='/assets/icons/wallet.png' alt='wallet-icon' />
        </div>
        <Text as='span'>{label}</Text>
      </div>
      <IoIosArrowForward className='text-black/80 cursor-pointer' />
    </div>
  );
}

RechargePageWrapper.Dropdown = Dropdown;
