import React from "react";
import * as Yup from "yup";
import { MdEmail, MdPhone } from "react-icons/md";
import { BsMoonFill, BsPencilFill } from "react-icons/bs";
import Button from "../../components/button";
import Input from "../../components/input";
import ChangePassword from "../../components/modals/change-password";
import ModalWrapper from "../../components/modals/modal-wrapper";
import ProfilePicture from "../../components/profile-picture";
import Text from "../../components/text";
import { clx } from "../../utils/clx";
import { IoMdArrowDropright } from "react-icons/io";
import { FaLock } from "react-icons/fa";
import Switch from "../../components/switch";
import { useFormik } from "formik";
import useUserStore from "../../store/user/store";

export default function ProfilePageWrapper({ classname, children }) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const user = useUserStore((state) => state.user);

  const classes = clx("w-[93%] lg:w-[90%] mx-auto hidden lg:block", classname);
  return (
    <React.Fragment>
      <div className={classes}>
        <div className='w-[650px]'>
          <Text as='p' weight='semibold' classname='text-[28px]'>
            Profile Details
          </Text>
          <div className='flex items-center space-x-[40px] py-[50px]'>
            <ProfilePicture url={user?.pics} />
            <Text as='span' weight='semibold' classname='text-[38px]'>
              {user ? `${user.firstname} ${user.lastname}` : "Samuel Lasisi"}
            </Text>
          </div>

          <ProfilePageWrapper.Form user={user} />

          <div className='py-[50px] border-t-2 border-[#E6E6E9]'>
            <Text as='p' classname='font-semibold text-[25px]'>
              Change Password
            </Text>
            <div className='py-[15px] flex justify-between items-center'>
              <Text as='span' classname='text-[21px]'>
                Change your old password to a new one
              </Text>
              <Text
                as='span'
                classname='text-secondary-dark text-[19px] cursor-pointer'
                onclick={() => setModalOpen(true)}
              >
                Change password
              </Text>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Profile Page */}
      <MobileProfile user={user} onPassChangeClick={() => setModalOpen(true)} />

      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => setModalOpen(false)}
      >
        <ChangePassword />
      </ModalWrapper>
    </React.Fragment>
  );
}

export function Form({ user }) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  

  const formValues = {
    email: '',
    phonenumber: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Please enter your email"),
    phonenumber: Yup.number().required("Please enter your phone number"),
  });

  const onSubmit = (values) => {
    setValidationAttempt(false);
    // setLoading(true);
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex flex-col space-y-[40px]'>
        <div className='flex items-center justify-between'>
          <Text as='span' classname='flex-1 text-[23px]'>
            Username
          </Text>
          <Input
            type='email'
            iconLeft={<MdEmail className='text-[#6C6F7F] text-[20px]' />}
            // defaultValue={user ? user.email : null}
            placeholder={user?.username}
            classname='w-[60%] max-w-[60%]'
            name='email'
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.email}
            value={formik.values.email}
          />
        </div>
        <div className='flex items-center justify-between'>
          <Text as='span' classname='flex-1 text-[23px]'>
            Full Name
          </Text>
          <Input
            type='text'
            iconLeft={<MdEmail className='text-[#6C6F7F] text-[20px]' />}
            placeholder={user?.fullname}
            classname='w-[60%] max-w-[60%]'
            name='email'
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.email}
            value={formik.values.email}
          />
        </div>
        <div className='flex items-center justify-between'>
          <Text as='span' classname='flex-1 text-[23px]'>
            Email Address
          </Text>
          <Input
            type='email'
            iconLeft={<MdEmail className='text-[#6C6F7F] text-[20px]' />}
            placeholder={user?.email}
            classname='w-[60%] max-w-[60%]'
            name='email'
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.email}
            value={formik.values.email}
          />
        </div>
        <div className='flex items-center justify-between'>
          <Text as='span' classname='flex-1 text-[23px]'>
            Phone Number
          </Text>
          <Input
            type='phone'
            iconLeft={<MdPhone className='text-[#6C6F7F] text-[20px]' />}
            placeholder={user?.phonenumber}
            classname='w-[60%] max-w-[60%]'
            name='phonenumber'
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.phonenumber}
            value={formik.values.phonenumber}
          />
        </div>
      </div>

      <Button buttonType='submit' size='medium' classname='ml-auto my-[50px]'>
        Save changes
      </Button>
    </form>
  );
}

export function MobileProfile({ user, onPassChangeClick }) {
  const classes = clx("w-[93%] mx-auto py-[30px] lg:hidden");
  return (
    <div className={classes}>
      <div>
        <ProfilePicture url={user?.pics} classname='mx-auto' />
        <Text as='p' weight='semibold' classname='text-[22px] text-center my-3'>
          {user ? `${user.firstname} ${user.lastname}` : "Samuel Lasisi"}
        </Text>
        <Button
          type='tertiary'
          size='medium'
          iconRight={<BsPencilFill />}
          classname='mx-auto border-2'
          href='/user/edit-profile'
        >
          Edit Profile
        </Button>
      </div>
      <div className='mt-[70px] flex flex-col space-y-4'>
        <div className='flex items-center justify-between'>
          <div className='flex space-x-4 items-center'>
            <div className='w-[40px] h-[40px] rounded-[8px] bg-[#E6E6E9] flex items-center justify-center'>
              <FaLock className='text-[20px]' />
            </div>
            <Text as='span' weight='semibold' classname='text-[#2C3048]'>
              Change Password
            </Text>
          </div>
          <div
            className='w-fit rounded-full p-1 bg-[#F5F5FE]'
            onClick={onPassChangeClick}
          >
            <IoMdArrowDropright className=' text-[#181818]/50' />
          </div>
        </div>
        <div className='flex items-center justify-between'>
          <div className='flex space-x-4 items-center'>
            <div className='w-[40px] h-[40px] rounded-[8px] bg-[#E6E6E9] flex items-center justify-center'>
              <BsMoonFill className='text-[20px]' />
            </div>
            <Text as='span' weight='semibold' classname='text-[#2C3048]'>
              Dark Mode
            </Text>
          </div>
          <div className='flex items-center space-x-2'>
            <Text as='span'>OFF</Text>
            <Switch />
          </div>
        </div>
      </div>
    </div>
  );
}

ProfilePageWrapper.Form = Form;
