import useAdminAffiliates from "../../../store/admin/affiliates";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useQuery } from "react-query";

export default function useAffiliatesSettlementsDetails() {
  const authUser = useAuthUser();
  const { selectedAffiliate } = useAdminAffiliates();

  const fetchSettlementsDetails = () => {
    return axios.post(
      `https://pm-server.herokuapp.com/api/v2.0/settlement?query=get-details`,
      {
        tag: selectedAffiliate,
      },
      {
        headers: {
          Authorization: authUser().authToken,
        }, 
      }
    );
  };
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [`affiliates-settlements-details`, selectedAffiliate],
    queryFn: () => fetchSettlementsDetails(selectedAffiliate),
  });

  return { data: data?.data, isLoading, isFetching };
}
