import React from "react";
import AboutPageWrapper from "../../views/static/about-page";
import StaticLayout from "../../layouts/static-layout";
import ReferrrerSignup from "../../views/static/referrrer_signup";

export default function RefererSignUpPage() {
  return <React.Fragment>
    <StaticLayout>
      <ReferrrerSignup/>
    </StaticLayout>
  </React.Fragment>
}
