import React from "react";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import Button from "../../components/button";
import Input from "../../components/input";
import OTP from "../../components/otp";
import Text from "../../components/text";
import AuthLayout from "../../layouts/auth-layout";
import { clx } from "../../utils/clx";

export default function MeterVerificationPageWrapper() {
  return (
    <AuthLayout pageTitle='Meter ID Verification' usePageNavigation>
      <div className='w-full flex flex-col space-y-8 items-center py-[20px] lg:py-0'>
        <div>
          <Text
            as='p'
            classname='hidden lg:block font-[600] text-[54px] text-primary-dark text-center'
          >
            Meter Id verification
          </Text>
          <Text
            as='p'
            weight='medium'
            classname='text-[14px] lg:text-[20px] text-primary-dark lg:text-center'
          >
            Type in the 4 digit OTP code that we sent to your email.
          </Text>
        </div>
        <div className='lg:bg-[#F8F8FF] lg:border-[1px] rounded-[16px] w-full lg:w-[550px] py-[0px] lg:py-[50px] px-[0px] lg:px-[70px]'>
          <div className='flex flex-col items-center space-y-6'>
            <div className='mb-[30px]'>
              <OTP />
            </div>
            <Button classname='w-full'>Verify</Button>
            <Text as='p' classname='text-[14px] lg:text-base lg:text-center'>
              Didn’t receive a code?{" "}
              <span className='text-secondary-dark'>Resend OTP</span>
            </Text>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}
