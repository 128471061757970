import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaEnvelope, FaUserAlt } from "react-icons/fa";
import Button from "../../components/button";
import Input from "../../components/input";
import ProfilePicture from "../../components/profile-picture";
import { clx } from "../../utils/clx";
import useUserStore from "../../store/user/store";

export default function EditProfile() {
  
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const user= useUserStore(state=>state.user)
  
  const classes = clx("w-[93%] mx-auto py-[30px] lg:hidden");

  const formValues = {
    fullName: "",
    email: "",
    phone: ""
  };

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Please enter your fullname"),
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Please enter your email"),
    phone: Yup.number("Please enter a valid phone number").required("Please enter your phone number"),
  });

  const onSubmit = (values) => {
    setValidationAttempt(false);
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes}>
        <div>
          <ProfilePicture url={user?.pics} classname='mx-auto' />
        </div>
        <div className='my-[70px] flex flex-col space-y-6'>
          <Input
            iconLeft={<FaUserAlt className='text-[#9799A5]' />}
            placeholder='Samuel Lasisi'
            name='fullName'
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.fullName}
            value={formik.values.fullName}
          />
          <Input
            type="email"
            iconLeft={<FaEnvelope className='text-[#9799A5]' />}
            placeholder='Example@gmail.com'
            name='email'
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.email}
            value={formik.values.email}
          />
          <Input
            type="phone"
            iconLeft={<BsFillTelephoneFill className='text-[#9799A5]' />}
            placeholder='09035870397'
            name='phone'
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.phone}
            value={formik.values.phone}
          />
        </div>
        <Button
          buttonType='submit'
          onclick={() => setValidationAttempt(true)}
          classname='w-full'
          // isLoading
        >
          Save Changes
        </Button>
      </div>
    </form>
  );
}
