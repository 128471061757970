import React from "react";
import { Routes, Route } from "react-router-dom";
import Test from "./pages/test";
import GetAdminRoutes from "./routes/admin-route";
import GetAuthRoutes from "./routes/auth-routes";
import GetIobotechRoutes from "./routes/iobotech-routes";
import GetStaticRoutes from "./routes/static-routes";
import GetUserRoutes from "./routes/user-routes";
import GetApiDocsRoutes from "./routes/api-docs-routes";
import TestApi from "./api-calls/Test/test";

function App() {
  return (
    <Routes>
      {GetStaticRoutes()}
      {GetIobotechRoutes()}
      {GetAuthRoutes()}
      {GetUserRoutes()}
      {GetAdminRoutes()}
      {GetApiDocsRoutes()}
      <Route path='/test' element={<Test />} />
      <Route path='/testapi' element={<TestApi />} />
    </Routes>
  );
}

export default App;
