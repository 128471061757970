import React from "react";
import Chart from "react-apexcharts";
import Text from "../../../../components/text";
import useChart from "../../../../hooks/useChart";
import { clx } from "../../../../utils/clx";

export default function WaterStatisticAreaChart({ classname }) {
  const barChartConfig = useChart({
    type: "area",
    colors: ["#996A00"],
    categories: ["8am", "10am", "12am", "2pm", "4pm"],
    data: [90, 40, 39, 57, 13],
    height: "400px",
    strokeColors: ["#996A00"],
  });
  const classes = clx(classname);
  return (
    <div className={classes}>
      <div className='h-full w-full flex flex-col p-4 lg:p-6'>
        <WaterStatisticAreaChart.ChartTopBar />
        <div className='flex-1'>
          <div className='w-full h-[100%]'>
            <Chart {...barChartConfig} />
          </div>
        </div>
      </div>
    </div>
  );
}

export function ChartTopBar({ classname, noTabs, children }) {
  const classes = clx("", classname);
  return (
    <div className={classes}>
      <div className='pb-2 mb-4 flex items-center justify-between border-b-2 border-[#6C6F7F]/10'>
        <div className=''>
          {/* <Text as='h5' classname='text-[28px] font-semibold'>
            524.52 <span className='text-[16px]'>kwh</span>
          </Text> */}
          <Text as='p' classname='text-[12px] text-[#DB9800]'>
            Estimated cost is around ₦1000
          </Text>
        </div>
        <div className='cursor-pointer'>
          <Text as='span' classname=''>
            Filter Data
          </Text>
        </div>
      </div>
    </div>
  );
}

WaterStatisticAreaChart.ChartTopBar = ChartTopBar;
