import { create } from "zustand";

const useSelectedRows = create((set) => ({
 selectedRows: [],
  setSelectedRows: (selectedRows) => set({ selectedRows }),
}));

const useAffiliateProducts = create((set) => ({
  affiliateProducts: [],
  setAffiliateProducts: (affiliateProducts) => set({ affiliateProducts }),
}));

export  { useSelectedRows, useAffiliateProducts };
