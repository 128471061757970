import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { useAuthUser } from "react-auth-kit";
import { GrInfo } from "react-icons/gr";
import { MdError } from "react-icons/md";
import * as Yup from "yup";
import { clx } from "../../utils/clx";
import Button from "../button";
import Input from "../input";
import Radio from "../radio";
import Text from "../text";
import { toast } from "react-toastify";

export default function ChangePassword({ classname, children }) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [message, setMessage] = React.useState({
    type: "",
    message: "",
  });
  const [isChangeSuccessful, setChangeSuccessful] = React.useState(false);

  const [isLoading, setLoading] = React.useState(false);
  const userAuth = useAuthUser();

  async function resetPassword(data) {
    axios
      .put(
        "https://api-pm-e1ea777feac4.herokuapp.com/user/reset-password",
        {
          password: data.password,
          newpassword: data.newpassword,
        },
        {
          headers: {
            Authorization: userAuth().authToken,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        toast.success(res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        switch (err.response.data.message) {
          case "This is not your password. Please enter the correct current password.!!!":
            setMessage({ type: "error", message: "Incorrect password" });
            setChangeSuccessful(false);
            return;
          case "Password was successfully Changed!!!":
            setMessage({
              type: "success",
              message: "Password changed successfully",
            });
            setChangeSuccessful(true);
            return;
          default:
            return null;
        }
      });
  }

  const classes = clx("relative flex flex-col", classname);

  const formValues = {
    password: "",
    newpassword: "",
    cnewpassword: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string().required("Please enter your old password"),
    newpassword: Yup.string()
      .min(8, "password must be at least 8 characters")
      .required("Please enter your new password"),
    cnewpassword: Yup.string()
      .oneOf([Yup.ref("newpassword"), null], "Password Doesn't Match")
      .required("Please confirm your password"),
  });

  const onSubmit = (values, { resetForm }) => {
    setValidationAttempt(false);
    setLoading(true);
    resetPassword(values);
    isChangeSuccessful && resetForm();
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes}>
        <Text
          as="h3"
          weight="bold"
          classname="text-[24px] lg:text-[30px] text-center"
        >
          Change Password
        </Text>
        {message.message && (
          <ChangePassword.MessageContainer
            messageType={message.type}
            message={message.message}
          />
        )}
        <div className="my-6 flex flex-col space-y-6">
          <Input
            type="password"
            placeholder="Enter old password"
            name="password"
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.password}
            value={formik.values.password}
          />
          <Input
            type="password"
            placeholder="Enter new password"
            name="newpassword"
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.newpassword}
            value={formik.values.newpassword}
          />
          <Input
            type="password"
            placeholder="Confirm new password"
            name="cnewpassword"
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.cnewpassword}
            value={formik.values.cnewpassword}
          />
        </div>

        <div className="mt-6 w-fit mx-auto">
          <Button
            buttonType="submit"
            size="medium"
            onclick={() => setValidationAttempt(true)}
            isLoading={isLoading}
            //     onclick={onButtonClick}
          >
            Change Password
          </Button>
        </div>
      </div>
    </form>
  );
}

export function MessageContainer({ messageType, message }) {
  const classes = clx(
    "border-[1px] py-2 px-4 rounded-[8px] flex space-x-3",
    messageType === "error"
      ? "bg-red-500/30 "
      : messageType === "success"
      ? " bg-green-500/20"
      : "bg-yellow-500/20"
  );
  return (
    <div className={classes}>
      <div>
        {messageType === "error" || messageType === "warning" ? (
          <MdError
            className={`${
              messageType === "error" ? "text-red-600" : "text-secondary-dark"
            } text-[18px] lg:text-[22px]`}
          />
        ) : (
          <GrInfo className="text-green-600 text-[18px] lg:text-[22px]" />
        )}
      </div>
      <Text
        as="p"
        classname={`${
          messageType === "error"
            ? "text-red-600"
            : messageType === "success"
            ? "text-green-600"
            : "text-secondary-dark"
        } text-[13px] lg:text-[14px]`}
      >
        {message}
      </Text>
    </div>
  );
}

ChangePassword.MessageContainer = MessageContainer;
