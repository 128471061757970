import React, { useCallback, useMemo } from "react";
import ModalWrapper from "../../../../components/modals/modal-wrapper";
import EditRegularCapCharges from "../../../../components/modals/admin/edit-regular-cap-cahrges";
import RemoveRegularCapCharges from "../../../../components/modals/admin/remove-regular-cap-charges";
import AddRegularCapCharges from "../../../../components/modals/admin/add-regular-cap-charges";

import EditRegularNairaAddUp from "../../../../components/modals/admin/edit-regular-naira-add-up";


export default function RegularChargesOtherActions({ value, row }) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const isPercentage = useMemo(() => {
    return row.original.unit === "%";
  }, [row.original.unit]);
  const isCap = useMemo(() => {
    return row.original.cap;
  }, [row.original.cap]);

  const handleButtonClick = useCallback(
    (type) => {
      if (type === "" || type === undefined) return setModalType("");
      setModalType(type);
      setModalOpen(true);

    }, [
    modalType
  ])

  return (
    <React.Fragment>
      {isPercentage ? (

        <select
          value={modalType}
          onChange={
            (e) => {
              handleButtonClick(e.target.value)
            }
          }
          className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
        >
          <option value="">
            Select Action
          </option>

          {!isCap ? (<option value="add">Add Cap</option>) :
            <>
              <option value="edit">Edit Cap</option>
              <option value="remove">Remove Cap</option>
            </>
          }
          && <option value="edit-naira">Edit Naira Addup</option>
        </select>
      ) : null}

      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => {
          setModalOpen(false)
          setModalType("")
        }}
      >
        {modalType === "edit" && <EditRegularCapCharges data={row.original} title="Edit Cap" />}
        {modalType === "remove" && <RemoveRegularCapCharges data={row.original} title="Remove Cap" />}
        {modalType === "add" && <AddRegularCapCharges data={row.original} title="Add Cap" />}
        {modalType === "edit-naira" && <EditRegularNairaAddUp data={row.original} title="Edit Naira Addup" />}
      </ModalWrapper>
    </React.Fragment>
  );
}
