import React from "react";
import Text from "../../../components/text";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ModalWrapper from "../../../components/modals/modal-wrapper";
import useGetDiscoMeters from "../../../hooks/apis/user/useGetDiscoMeters";

import Button from "../../../components/button";
import { toast } from "react-toastify";
import axios from "axios";
import ErrorToast from "../../../components/toast/error-toast";
import { useAuthUser } from "react-auth-kit";

export function TableIcon({ value, row }) {
  // console.log(row.original);
  const navigate = useNavigate();
  const slug = useMemo(() => row.original.meterid, []);

  return (
    <>
      <Text
        onclick={() => navigate(`/user/disco-meters/${slug}`)}
        as="p"
        weight="semibold"
        classname="text-left text-[13px] lg:text-base text-secondary-dark hover:opacity-50"
      >
        View Details
      </Text>
    </>
  );
}

export function MeterNumber({ value }) {
  return (
    <Text
      as="p"
      weight="semibold"
      classname="text-left text-[13px] lg:text-base text-[#2C3048]"
    >
      {value}
    </Text>
  );
}

export function Name({ value }) {
  return (
    <Text
      as="p"
      weight="normal"
      classname="text-left text-[13px] lg:text-base text-[#2C3048]"
    >
      {value}
    </Text>
  );
}

export function Provider({ value }) {
  return (
    <Text
      as="p"
      weight="normal"
      classname={`text-left text-[13px] lg:text-base capitalize`}
    >
      {value}
    </Text>
  );
}

export function Action({ value, row }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigation = useNavigate();
  const authUser = useAuthUser();
  const onDelete = () => {
    setIsOpen(true);
  };

  const onSubmit = async () => {
    setIsLoading(true)
    axios
      .post(
        `https://api-pm-e1ea777feac4.herokuapp.com/user/disco?query=remove-disco-profile&meterid=${row?.original?.meterid}`,
        {},
        {
          headers: {
            Authorization: authUser().authToken,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setIsOpen(false);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(<ErrorToast>{err.response.data.message}</ErrorToast>);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Text
        onclick={onDelete}
        as="p"
        weight="normal"
        classname="text-left text-[13px] lg:text-base cursor-pointer text-red-600"
      >
        Delete
      </Text>

      <ModalWrapper
        isOpen={isOpen}
        handleModalClose={() => {
          setIsOpen(false);
        }}
      >
        <div>
          <Text
            as="h3"
            weight="bold"
            classname="text-[24px] lg:text-[30px] text-center"
          >
            Delete Meter
          </Text>
          <div className="my-6 flex flex-col space-y-6">
            <Text as="p" weight="medium" classname=" text-secondary-dark">
              Are you sure you want to delete this meter{" "}
              {row?.original?.meterid}
            </Text>
          </div>
          <Button
            buttonType="button"
            onclick={onSubmit}
            isLoading={isLoading}
            classname="w-full"
          >
            Delete
          </Button>
        </div>
      </ModalWrapper>
    </React.Fragment>
  );
}
