import React from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import AdminCountCard from "../../../components/admin-count-card";
import Button from "../../../components/button";
import Table from "../../../components/table";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";
import {
  energy_transactions_column,
  energy_transactions_data,charges_transactions_column
} from "../../../utils/table-utils/admin/energy-transactions-table";
import { GetAdminConsumptionData } from "../../../api-calls/admin/getAdminEnergyConsumptionData";
import useAdminVendInfo from "../../../hooks/apis/admin/useAdminVendInfo";
import Input from "../../../components/input";
import { BsSearch } from "react-icons/bs";
import { CSVDownload, CSVLink } from "react-csv";
// import { EnergyCSVHeader } from "./download-config/energy-header";
import { FiDownload } from "react-icons/fi";
import {useAdminCharges} from "../../../hooks/apis/admin/useAdminCharges";
import { ChargesCSVHeader, EnergyCSVHeader } from "../prepaid-energy/download-config/energy-header";
import useAdminAffiliates from "../../../store/admin/affiliates";
import Select from "../../../components/select";

export default function ChargesWrapper() {
  const [select,setSelect]= React.useState('monthly')
  const period =[ 'monthly', 'weekly', 'annually', 'semi-annually', 'quarterly']
  const {
    totalUsers,
  } = GetAdminConsumptionData({ query: "realtime", prepaid: "energy" });
  const navigate = useNavigate();
 
  const [search, setSearch] = React.useState("");
  const { selectedAffiliate } = useAdminAffiliates();

  const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
  const { data: chargesData, isLoading: chargesLoading, isError: chargesError, refetch: chargesRefetch, isFetching: chargesIsFetching } = useAdminCharges({slug:select});
 
  React.useEffect(() => {
    chargesRefetch()
    return () => {
      console.log('I run after re-render, but before the next useEffect');
    };
  }, [selectedAffiliate,select]);

  return (
    <div className={classes}>
     <ChargesWrapperDropDown period={period} setSelect={setSelect}/>     
      <div className="grid grid-cols-3 gap-4 my-[40px]">
        <AdminCountCard
          description="Total Customers"
          count={totalUsers ?? 0}
          bg="bg-white"
          iconBg="bg-[#E6E6E9]/30"
          classname="w-full"
          onclick={() => navigate("customers")}
          isLoading={chargesLoading || chargesIsFetching}
        />
        <AdminCountCard
          description={`Active Payment for ${new Date().toLocaleString("en-us", {
            month: "long",
          })}`}
          bg="bg-[#E6E6E9]"
          iconBg="bg-[#9799A54D]/30"
          classname="w-full"
          platform="Charges"
          // decimalScale={0}
          isDecimal={false}
          unit={chargesData?.data?.data?.overview?.activePayment?.total }
          // unit={currentMonthVending?.kwh}
          // price={currentMonthVending?.naira}
          price={chargesData?.data?.data?.overview?.activePayment?.amount}
          isLoading={chargesLoading || chargesIsFetching}
        />
        <AdminCountCard
          description="Today Payment"
          bg="bg-[#E6E6E9]"
          iconBg="bg-[#9799A54D]/30"
          classname="w-full"
          platform="Charges"
          // decimalScale={0}
          isDecimal={false}
          unit={chargesData?.data?.data?.overview?.todayPayment?.total ?? 0}
          // unit={chargesData?.data?.data?.overview?.todayPayment?.total ?? 0}
          price={chargesData?.data?.data?.overview?.todayPayment?.amount}
          isLoading={chargesLoading || chargesIsFetching}
        />
        <AdminCountCard
          description="Total Payment"
          bg="bg-[#E6E6E9]"
          iconBg="bg-[#9799A54D]/30"
          classname="w-full"
          platform="Charges"
          // decimalScale={0}
          // unit={totalCredit?.unit}
          isDecimal={false}
          unit={chargesData?.data?.data?.overview?.payments?.total}
          price={chargesData?.data?.data?.overview?.payments?.amount}
          // price={totalCredit?.price}
          isLoading={chargesLoading || chargesIsFetching}
        />
        <AdminCountCard
          description="Total Payout"
          bg="bg-[#E6E6E9]"
          iconBg="bg-[#9799A54D]/30"
          classname="w-full"
          platform="Charges"
          // decimalScale={0}
          isDecimal={false}
          // unit={availableBalance?.unit}
          // price={availableBalance?.price}
          unit={chargesData?.data?.data?.overview?.payout?.total}
          price={chargesData?.data?.data?.overview?.payout?.amount}
          isLoading={chargesLoading || chargesIsFetching}
        />{" "}
        <AdminCountCard
          description="Total Unpayout"
          bg="bg-[#E6E6E9]"
          iconBg="bg-[#9799A54D]/30"
          classname="w-full"
          platform="Charges"
          // decimalScale={0}
          isDecimal={false}
          // unit={Number(totalConsumption?.unit).toFixed(2)}
          // price={totalConsumption?.price}
          unit={chargesData?.data?.data?.overview?.unpayout?.total}
          price={chargesData?.data?.data?.overview?.unpayout?.amount}
          isLoading={chargesLoading || chargesIsFetching}
        />
      </div>

      <div className="my-[40px]">
        <Text
          as="span"
          classname="text-[16px] lg:text-[23px] py-[20px] font-semibold text-[#6C6F7F]"
        >
          Transactions History
        </Text>

        <div className="flex justify-end items-center space-x-6 py-2">
          <div className="flex items-center">
            <Input
              iconLeft={<BsSearch />}
              placeholder="Search By Meter ID"
              variant="white"
              inputClass="py-[6px] px-[15px] rounded-r-0"
              onchange={({ target }) => setSearch(target.value)}
            />
            {/* <div className="h-full w-[50px] border-2 py-[4px] rounded-[6px]">
              ee
            </div> */}
          </div>
          <CSVLink
            data={chargesData?.data?.data?.history ?? []}
            // data={EnergyVendInfo?.data ?? []}
            headers={ChargesCSVHeader}
            // headers={EnergyCSVHeader}
            filename="Charges.CSV"
          >
            <Button iconLeft={<FiDownload />} variant="none">
              <Text as="p" weight="semibold">
                Download
              </Text>
            </Button>
          </CSVLink>
        </div>

        <div className="w-full my-[40px] mt-[10px] bg-white rounded-[16px] p-6">
          <Table
            search={search}
            // data={EnergyVendInfo?.data ?? [{}]}
            data={chargesData?.data?.data?.history ?? [{}]}
            // columns={energy_transactions_column}
            columns={charges_transactions_column}
            isLoading={chargesIsFetching || chargesIsFetching}
          />
        </div>
      </div>
    </div>
  );
}

// const period =[ 'monthly', 'weekly', 'annually', 'semi-annually', 'quarterly']
export const ChargesWrapperDropDown =({period,setSelect})=>{
  return(
    <Select
    placeholder="Select Period"
    classname="py-2 w-[250px] z-20"
    // value='monthly'
    render={({setDropdownOpen, setSelectedOption })=>(
       <div>
          {
            period.map((item,indx)=>(
              <Text
              key={indx}
              as='p'
              classname="p-2 hover:bg-primary-dark hover:text-white cursor-pointer"
              onclick={async ()=>{
                  console.log(item)
                  setSelectedOption(item)
                  setSelect(item)
                  setDropdownOpen(true);
              }}  
              >
              {item}  
              </Text>
            ))
          }
       </div>

    )}

    
    />
  )
}
