import Text from "../../../components/text";
import { clx } from "../../../utils/clx";
import { Card } from "./features-section";
import YouTube from 'react-youtube';

export default function Clients() {
  const classes = clx(
    "px-6 lg:px-[140px] flex flex-col items-center py-6 lg:py-[60px] bg-[#ffff] overflow-hidden"
  );

  return (
    <div className={classes} data-aos="fade-up">
      <Text
        as="h1"
        weight="semibold"
        classname="text-2xl lg:text-[64px] text-left"
      >
        What our clients say
      </Text>

      <div className="bg-[#DB9800] py-[30px] lg:py-[77px] px-5 lg:px-[39px] rounded-xl mt-[30px] lg:mt-[80px] lg:max-w-[1250px]">
        <div className="flex flex-col gap-8 lg:flex-row justify-between items-center">
          <ClientCard
            title="Vincent Nelson"
            description="I've been using this prepaid meter app for a few months now, and it's been a real game-changer. It's so convenient to track my electricity usage and top up my balance right from my phone. The interface is user-friendly, and I've never had any issues. Highly recommended! "
            imgSrc={"/assets/images/daniel.png"}
          />
          <ClientCard
            title="Daniel Praise"
            description="This prepaid meter app has saved me from the hassle of visiting the utility office for top-ups. It's simple to navigate, and the notifications for low balance are handy. I wish there were more features, but it's a solid app for managing my prepaid meter."
            imgSrc={"/assets/images/vincent.png"}
          />
          {/*<CustomerReview  videoId={'QFopRe36vFE'}/>*/}
        </div>
      </div>
    </div>
  );
}

export function ClientCard({ title, description, imgSrc }) {
  return (
    <div className="h-full flex flex-col items-center gap-8 lg:flex-row ">
      <div>
        <img
          src={imgSrc}
          alt=""
          className="object-contain w-[76px] lg:w-full"
        />
      </div>
      <div className="lg:max-w-[350px] flex flex-col-reverse lg:flex-col lg:space-y-6">
        <Text
          as="h1"
          weight="semibold"
          classname="text-2xl text-center lg:text-left  text-white"
        >
          {title}
        </Text>
        <Text
          as="p"
          weight="normal"
          classname="text-sm text-center lg:text-left  text-white"
        >
          {description}
        </Text>
      </div>
    </div>
  );
}

export function CustomerReview({ videoId }) {
  const onPlayerReady= (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const opts= {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      'playsinline': 1
    },
  };

  return <YouTube videoId={ videoId } opts={opts} onReady={onPlayerReady} />;
}