import React from "react";
import { useNavigate } from "react-router-dom";
import BalanceCard from "../../components/balance-card";
import Button from "../../components/button";
import EnergyTransactionCard from "../../components/energy-transaction-card";
import MeterIDContainer from "../../components/meterid-container";
import Filter from "../../components/modals/filter";
import ModalWrapper from "../../components/modals/modal-wrapper";
import Text from "../../components/text";
import { clx } from "../../utils/clx";
import WaterConsumptionChart from "./components/charts/water-consumption-chart";

export default function WaterConsumptionWrapper({ classname, children }) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [filteredChart, setFilteredChart] = React.useState(null);

  const navigate = useNavigate();


  React.useEffect(() => window.scrollTo(0, 0));

  const classes = clx("w-[93%] lg:w-[90%] mx-auto py-[40px]", classname);
  return (
    <React.Fragment>
      <div className={classes}>
        <div className='flex items-center space-x-6'>
          <Text as='span' classname='text-[12px] lg:text-[16px]'>
            Meter Number
          </Text>
          <MeterIDContainer />
        </div>
        <div className='max-w-[100%] scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-thumb-[#E5E5E5] overflow-auto pt-[40px] pb-[10px] grid grid-cols-2 gap-4 lg:gap-0 lg:flex items-center justify-between ali lg:space-x-6'>
          <BalanceCard variant='primary' title='Total Credit' />
          <BalanceCard variant='primary' title='Available Balance' />
          <BalanceCard variant='primary' title='Total Consumption' />
          <div className='lg:hidden rounded-[8px] md:w-fit p-4 flex flex-col md:flex-row md:items-center md:space-x-[50px] space-y-6 md:space-y-0'>
            <div className='flex items-center space-x-[20px]'>
              <div className='min-w-[32px] min-h-[32px] lg:w-[42px] lg:h-[42px] rounded-[4px] bg-[#9799A5] flex items-center justify-center'>
                <img
                  src='/assets/icons/price-icon-black.png'
                  alt='price-icon'
                />
              </div>
              <div className='flex flex-col'>
                <Text as='span' classname='text-[#6C6F7F] text-[11px]'>
                  Price per unit
                </Text>
                <Text as='span' weight='bold' classname='text-[20px]'>
                  96{" "}
                  <span className='font-normal text-[#6C6F7F] text-[12px]'>
                    {" "}
                    ( + k )
                  </span>
                </Text>
              </div>
            </div>
            <Button size='medium' classname='px-[40px]'>
              Recharge
            </Button>
          </div>
        </div>
        <div className='hidden border-2 rounded-[8px] md:w-fit p-4 my-[40px] lg:flex flex-col md:flex-row md:items-center md:space-x-[50px] space-y-6 md:space-y-0'>
          <div className='flex items-center space-x-[20px]'>
            <div className='w-[42px] h-[42px] rounded-[4px] bg-[#9799A5] flex items-center justify-center'>
              <img src='/assets/icons/price-icon-black.png' alt='price-icon' />
            </div>
            <Text as='span' classname='text-[#6C6F7F]'>
              Price per unit
            </Text>
            <Text as='span' weight='bold' classname='text-[24px]'>
              96 <span className='font-normal text-[#6C6F7F]'> ( + k )</span>
            </Text>
          </div>
          <Button size='medium' classname='px-[40px]'>
            Recharge
          </Button>
        </div>
        <WaterConsumptionChart
          handleFilteredChart={setFilteredChart}
          onFilterClick={() => setModalOpen(true)}
          classname='my-[30px] lg:my-[50px]'
        />
        <div className=''>
          <div className='flex items-center justify-between py-[20px]'>
            <Text
              as='span'
              weight='medium'
              classname='lg:text-[23px] text-[#6C6F7F]'
            >
              Recent Transactions
            </Text>
            <Text
              as='span'
              weight='medium'
              classname='lg:text-[23px] text-secondary-dark'
              onclick={() => navigate("/user/water-transaction")}
            >
              see all
            </Text>
          </div>
          <div className='lg:py-[30px] flex flex-col space-y-6 max-h-[400px] scrollbar-thin overflow-auto'>
            <EnergyTransactionCard classname='w-[100%]' />
            <EnergyTransactionCard classname='w-[100%]' />
            <EnergyTransactionCard classname='w-[100%]' />
          </div>
        </div>
      </div>

      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => setModalOpen(false)}
      >
        <Filter
          handleModalClose={() => setModalOpen(false)}
          route={
            filteredChart === "bar"
              ? "/user/water-statistics"
              : "/user/water-statistics-area"
          }
        />
      </ModalWrapper>
    </React.Fragment>
  );
}
