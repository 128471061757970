import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { useAuthUser } from "react-auth-kit";
import * as Yup from "yup";
import useUserStore from "../../store/user/store";
import { clx } from "../../utils/clx";
import Button from "../button";
import Input from "../input";
import Radio from "../radio";
import Text from "../text";

export default function Reference({
  classname,
  loading,
  setPlatform,
  setReference,
  message,
  noDescription,
  onButtonClick,
  setData,
  children,
}) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);

  const classes = clx("relative flex flex-col", classname);

  const formValues = {
    reference: "",
  };

  const validationSchema = Yup.object({
    reference: Yup.string().required("Please enter a refernece id"),
    platform: Yup.string().required("Please select a gateway"),
  });

  const onSubmit = (values) => {
    setValidationAttempt(false);
    setData && setData(values);
    onButtonClick();
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes}>
        <Text
          as="h3"
          weight="bold"
          classname="text-[24px] lg:text-[30px] text-center"
        >
          Reference ID?
        </Text>
        <div className="my-6">
          <Text
            as="h3"
            weight="bold"
            classname="text-[13px] lg:text-base text-center pb-2 text-red-500"
          >
            {message}
          </Text>
          <Input
            type="text"
            placeholder="Reference id"
            name="reference"
            onchange={(e) => {
              // setData &&
              //   setData({
              //     reference: e.target.value,
              //   });
              setReference && setReference(e.target.value);
              formik.handleChange(e);
            }}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.reference}
            value={formik.values.reference}
          />
          <Text
            as="h3"
            weight="bold"
            classname="text-[24px] lg:text-[30px] text-center"
          >
            Platform?
          </Text>
          <div className="my-6">
            <select
              className="flex items-center select-none space-x-3 border-undefined rounded-[8px] py-[15px] px-[20px] bg-[#E6E6E9] dark:bg-[#1B1D26] w-full text-[#1B1D26] dark:text-[white]"
              placeholder="Platform"
              name="platform"
              onChange={(e) => {
                // setData &&
                //   setData({
                //     platform: e.target.value,
                //   });
                setPlatform(e.target.value);
                formik.handleChange(e);
              }}
            >
              <option value="">Select Platform</option>
              <option value="paystack">paystack</option>
              <option value="flutterwave">flutterwave</option>
            </select>
          </div>
        </div>

        <div className="mt-6 w-fit mx-auto">
          <Button
            buttonType="submit"
            size="medium"
            onclick={() => setValidationAttempt(true)}
            isLoading={loading}
            // disabled={
            //   !noDescription && isRemoteComplied === false ? true : null
            // }
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
}
