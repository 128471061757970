import React from "react";
import { clx } from "../../../utils/clx";
import Text from "../../../components/text";

export default function NoSelectedAffiliate() {
  const classes = clx(
    "w-full min-h-[calc(100vh-100px)] bg-white flex flex-col space-y-4 items-center justify-center bg-gray-50"
  );
  return (
    <div className={classes}>
      <div className="flex w-[200px] h-[200px]">
        <img
          src="/assets/icons/pointing-up.gif"
          alt="Select an affiliate"
          className="w-full h-full object-fill"
        />
      </div>
      <div className="text-center">
        <Text as="h5" weight="semibold" classname="text-[20px] text-primary-dark">
          No Site Selected
        </Text>
        <Text as="h5" weight="medium" classname="text-[14px] text-secondary-dark">
          Please select a site at the top left corner
        </Text>
      </div>
    </div>
  );
}
