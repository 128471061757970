import React from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { clx } from "../utils/clx";
import Button from "./button";
import Text from "./text";
import useAdminAffiliateWalletInfo from "../hooks/apis/admin/useAdminWalletInfo";

export default function AdminWalletBalanceCard({ classname, children }) {
  const [isBalanceHidden, setBalanceHidden] = React.useState(false);
  const { data: affiliateWallet } = useAdminAffiliateWalletInfo({
    action: "balance",
  });
  const { data: affiliateTrans } = useAdminAffiliateWalletInfo({
    action: "transactions",
  });

  // console.log("wallet", affiliateWallet);
  // console.log("walletTrans", affiliateTrans);
  const classes = clx("w-full rounded-[20px] h-fit bg-white py-8", classname);
  return (
    <div className={classes}>
      <div className="w-[80%] mx-auto">
        <Text as="p" classname="text-[20px]">
          Wallet Balance
        </Text>
        <div className="pt-[15px] flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <Text
              as="h5"
              classname="text-center  text-[28px] lg:text-[56px] font-bold"
            >
              {isBalanceHidden
                ? "*** *** ***"
                : affiliateWallet?.data?.balance ?? "Null"}
            </Text>
            {isBalanceHidden ? (
              <AiFillEyeInvisible
                className="text-[#9799A5] text-[24px] cursor-pointer"
                onClick={() => setBalanceHidden((state) => !state)}
              />
            ) : (
              <AiFillEye
                className="text-[#9799A5] text-[24px] cursor-pointer"
                onClick={() => setBalanceHidden((state) => !state)}
              />
            )}
          </div>
          <Button classname="px-[80px]">Fund Wallet</Button>
        </div>
      </div>
    </div>
  );
}
