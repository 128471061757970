import {
  eachDayOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  getMonth,
  sub,
} from "date-fns";
import React from "react";
import useUserStore from "../../../store/user/store";
import getDate from "../../../utils/getDate";
import { getFormattedTime } from "../../../utils/getFormattedTime";
import getWeekDay from "../../../utils/getWeekDay";
import useConsumptionInfo from "./useConsumptionInfo";
import useSelectedEnergyUser from "../../../store/admin/selected-energy-user";

export default function useEnergyConsumptionsInfo() {
  const [isConverted, setIsConverted] = React.useState(false);
  const user = useUserStore((state) => state.user);

  React.useEffect(() => {}, [isConverted]);

  // Daily
  const startDate = sub(new Date(), {
    days: 6,
  });
  const endDate = new Date();

  // Monthly
  const startMonth = sub(new Date(), {
    months: 6,
  });
  const endMonth = new Date();

  // Yearly
  const startYear = sub(new Date(), {
    years: 6,
  });
  const endYear = new Date();

  // Daily
  const dayInterval = eachDayOfInterval({
    start: startDate,
    end: endDate,
  }).map((date) => getDate(date, { format: "dd/mm/yy" }));

  // Monthly
  const monthInterval = eachMonthOfInterval({
    start: startMonth,
    end: endMonth,
  }).map((date) => getDate(date, { format: "mm/yy" }));

  // Yearly
  const yearInterval = eachYearOfInterval({
    start: startYear,
    end: endYear,
  }).map((date) => getDate(date, { format: "yyyy" }));

  // Daily
  const { data: DailyConsumptionInfo } = useConsumptionInfo({
    key: "barChartConsumption",
    query: "date-range",
    prepaid: "energy",
    start: getDate(startDate, { format: "yyyy-mm-dd" }),
    end: getDate(endDate, { format: "yyyy-mm-dd" }),
  });

  // Monthly
  const { data: MonthlyConsumptionInfo } = useConsumptionInfo({
    key: "MonthlybarChartConsumption",
    query: "month-range",
    prepaid: "energy",
    start: getDate(startMonth, { format: "yyyy-mm" }),
    end: getDate(endMonth, { format: "yyyy-mm" }),
  });

  // Yearly
  const { data: YearlyConsumptionInfo } = useConsumptionInfo({
    key: "YearlybarChartConsumption",
    query: "year-range",
    prepaid: "energy",
    start: getDate(startYear, { format: "yyyy" }),
    end: getDate(endYear, { format: "yyyy" }),
  });

  // Hourly
  const { data: HourlyConsumptionInfo } = useConsumptionInfo({
    key: "HourlybarChartConsumption",
    query: "hourly",
    prepaid: "energy",
    day: getDate(new Date(), { format: "dd-mm-yyyy" }),

    // end: getDate(endYear, { format: "yyyy" }),
  });

  // console.log("Hourly-consumptions-from-api", HourlyConsumptionInfo?.data);

  // Hourly Interval
  const hourlyInterval = HourlyConsumptionInfo?.data.map((consumption) => {
    // console.log("Hours-From-API", consumption.hour);
    return getFormattedTime(consumption.hour);
  });

  // Hourly Consumptions
  const hourlyConsumptions = HourlyConsumptionInfo?.data.map((data) => {
    if (isConverted) {
      return Number(data.consumption * user?.cost.energy).toFixed(2);
    }
    return Number(data.consumption).toFixed(2);
  });
  const hourlyUnits = HourlyConsumptionInfo?.data.map((data) =>
    Number(data.consumption).toFixed(2)
  );

  // Daily
  const dailyConsumptions = DailyConsumptionInfo?.data.map((data) => {
    if (isConverted) {
      return Number(data.consumption * user?.cost.energy).toFixed(2);
    }
    return Number(data.consumption).toFixed(2);
  });
  const dailyUnits = DailyConsumptionInfo?.data.map((data) => {
    return Number(data.consumption).toFixed(2);
  });
  // Monthly
  const monthlyConsumptions = MonthlyConsumptionInfo?.data.map((data) => {
    if (isConverted) {
      return Number(data.consumption * user?.cost.energy).toFixed(2);
    }
    return Number(data.consumption).toFixed(2);
  });
  const monthlyUnits = MonthlyConsumptionInfo?.data.map((data) => {
    return Number(data.consumption).toFixed(2);
  });
  // Yearly
  const yearlyConsumptions = YearlyConsumptionInfo?.data.map((data) => {
    if (isConverted) {
      return Number(data.consumption * user?.cost.energy).toFixed(2);
    }
    return Number(data.consumption).toFixed(2);
  });
  const yearlyUnits = YearlyConsumptionInfo?.data.map((data) => {
    return Number(data.consumption).toFixed(2);
  });

  return {
    isConverted,
    setIsConverted,
    dailyConsumptions,
    dayInterval,
    monthlyConsumptions,
    monthInterval,
    yearlyConsumptions,
    yearInterval,
    dailyUnits,
    monthlyUnits,
    yearlyUnits,
    hourlyInterval,
    hourlyConsumptions,
    hourlyUnits,
  };
}
