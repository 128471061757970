import React from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Text from "../../../../components/text";

export default function ManagerPassword({ value, row }) {
    const [isPasswordVisible, setPasswordVisible] = React.useState(false);

    return (
        <div className="flex flex-row space-x-2 items-center">
            <Text as='p' classname='text-left text-[12px]'>
                {
                    isPasswordVisible ? value : "********"
                }
            </Text>
            {

                isPasswordVisible ? (
                    <AiOutlineEye
                        className="text-[#9799A5] hover:text-[#8280A5] text-[12px] cursor-pointer"
                        onClick={() => setPasswordVisible((state) => !state)}
                    />
                ) : (
                    <AiOutlineEyeInvisible
                        className="text-[#9799A5] hover:text-[#8280A5] text-[12px] cursor-pointer"
                        onClick={() => setPasswordVisible((state) => !state)}
                    />
                )
            }
        </div>
    )
}