import React from "react";
import AboutPageWrapper from "../../views/static/about-page";
import StaticLayout from "../../layouts/static-layout";

export default function AboutPage() {
  return <React.Fragment>
    <StaticLayout>
      <AboutPageWrapper />
    </StaticLayout>
  </React.Fragment>
}
