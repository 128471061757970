import { AiFillSetting } from "react-icons/ai";
import { BsFillChatDotsFill } from "react-icons/bs";
import { FaQuestionCircle, FaUserAlt, FaBloggerB } from "react-icons/fa";
import { GiFuelTank } from "react-icons/gi";
import { IoWallet, IoWaterSharp } from "react-icons/io5";
import { RiServiceFill } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { SlEnergy } from "react-icons/sl";
import { MdShowChart } from "react-icons/md";
import { CgFeed } from "react-icons/cg";

export const ioboMenu = [
  {
    id: 1,
    label: "Transaction",
    icon: <RxDashboard className="text-[20px] lg:text-[24px]" />,
    pageTitle: "Overview",
    route: "/iobotech",
  },
  {
    id: 2,
    label: "Resident",
    icon: <RiServiceFill className="text-[20px] lg:text-[24px]" />,
    pageTitle: "Services",
    route: "resident",
    subroutes: [
      {
        id: 1,
        label: "Prepaid Energy",
        icon: <SlEnergy className="text-[20px] lg:text-[24px]" />,
        pageTitle: "Prepaid Energy",
        route: "/iobotech/energy",
      },
      {
        id: 2,
        label: "Prepaid Water",
        icon: <IoWaterSharp className="text-[20px] lg:text-[24px]" />,
        pageTitle: "Prepaid Water",
        route: "/iobotech/water",
      },
      {
        id: 3,
        label: "Fuel Monitoring",
        icon: <GiFuelTank className="text-[20px] lg:text-[24px]" />,
        pageTitle: "Fuel Monitoring",
        route: "/iobotech/fuel",
      },
    ],
  },
  {
    id: 3,
    label: "Billing",
    icon: <MdShowChart className="text-[20px] lg:text-[24px]" />,
    pageTitle: "",
    route: "billing",
    subroutes: [
      {
        id: 1,
        label: "Periodic",
        icon: <CgFeed className="text-[20px] lg:text-[24px]" />,
        pageTitle: "Periodic Charges",
        route: "/iobotech/manager/charges",
      }
    ],
  },
  {
    id: 4,
    label: "Maintenance",
    icon: <AiFillSetting className="text-[20px] lg:text-[24px]" />,
    pageTitle: "maintenance",
    route: "/iobotech/configure",
  },
  {
    id: 5,
    label: "Fuel",
    icon: <BsFillChatDotsFill className="text-[20px] lg:text-[24px]" />,
    pageTitle: "fuel",
    route: "/iobotech/chat",
  },
  {
    id: 6,
    label: "Security",
    icon: <BsFillChatDotsFill className="text-[20px] lg:text-[24px]" />,
    pageTitle: "Security",
    route: "/iobotech/chat",
  },
  {
    id: 7,
    label: "Facilities",
    icon: <BsFillChatDotsFill className="text-[20px] lg:text-[24px]" />,
    pageTitle: "Facilities",
    route: "/iobotech/chat",
  },
];
