import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Text from "../../text";
import Input from "../../input";
import Button from "../../button";
import { BsCalendarDate } from "react-icons/bs";


const isValidDateFormat = (dateString) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateRegex.test(dateString);
  };
  
  const isEndDateValid = (startDate, endDate) => {
    if (!isValidDateFormat(startDate) || !isValidDateFormat(endDate)) {
      return false;
    }
  
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
  
    return startDateObj <= endDateObj;
  };

export default function DateRangePayout({ onButtonClick }) {
    const [validationAttempt, setValidationAttempt] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const formValues = {
        startDate: "",
        endDate: "",
    };
  
    const validationSchema = Yup.object({
      startDate: Yup.string()
          .required("Start Date is Required")
          .test("is-valid-date", "Invalid Date Format", (value) =>
              isValidDateFormat(value)
          )
          .test("is-not-past-date", "Start Date must not be in the past", function (value) {
              const currentDate = new Date();
              const selectedDate = new Date(value);
              return selectedDate >= currentDate;
          }),
      endDate: Yup.string()
          .required("End Date is Required")
          .test("is-valid-date", "Invalid Date Format", (value) =>
              isValidDateFormat(value)
          )
          .test("is-valid-range", "End Date must not be before Start Date", function (value) {
              return isEndDateValid(formik.values.startDate, value);
          }),
  });
  
    
      const onSubmit = (values) => {
       onButtonClick(values)
      };
    

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formValues,
        onSubmit,
        validationSchema,
        validateOnBlur: false,
        validateOnChange: validationAttempt,
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            <div>
                <Text
                    as="h3"
                    weight="bold"
                    classname="text-[24px] lg:text-[30px] text-center"
                >
                   Payout By Date
                </Text>
                <div className="my-6 flex flex-col space-y-6">
                <Text
                    as="h3"
                    weight="normal"
                    classname="text-[16px] text-center text-secondary-dark"
                >
                    Please select the date range for which you want to generate the payout.
                </Text>
                    <Input
                        iconLeft={<BsCalendarDate />}
                        label="Start Date"
                        type="date"
                        name="startDate"
                        value={formik.values.startDate}
                        onchange={formik.handleChange}
                        onblur={formik.handleBlur}
                        errorMessage={formik.errors.startDate}
                    />
                    <Input
                        iconLeft={<BsCalendarDate />}
                        label="End Date"
                        type="date"
                        name="endDate"
                        value={formik.values.endDate}
                        onchange={formik.handleChange}
                        onblur={formik.handleBlur}
                        errorMessage={formik.errors.endDate}
                    />
                </div>
                <Button isLoading={isLoading}
                    onclick={() => setValidationAttempt(true)}
                    buttonType="submit" classname="w-full">
                    Continue 
                </Button>
            </div>
        </form>
    )
}