import React from "react";
import useAdminAffiliates from "../../../store/admin/affiliates";

const useAdminAffiliateInfo = () => {
  const { affiliates, selectedAffiliate } = useAdminAffiliates();
  // console.log(affiliates, selectedAffiliate);
  if (
    affiliates &&
    selectedAffiliate !== "" &&
    selectedAffiliate !== "All"
  ) {
    return affiliates[selectedAffiliate][0];
  }
  return {};
};

export default useAdminAffiliateInfo;
