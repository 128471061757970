import React from "react";
import useAdminAffiliates from "../../../store/admin/affiliates";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useQuery } from "react-query";

export default function useAdminChargesInfo({
  prepaid,
  page = 1,
  limit = 10,
  key,
  screen = "default",
}) {
  const authUser = useAuthUser();
  const { affiliates, selectedAffiliate } = useAdminAffiliates();
  const affiliatesArray = affiliates && Object.keys(affiliates);

  const fetchChargesInfo = (affiliate) => {
    return axios.post(
      `https://pm-server.herokuapp.com/api/v2.0/manager/charges-txn?screen=${screen}`,
      {
        slug: "tags",
        tags:
          selectedAffiliate === "All"
            ? [...affiliatesArray]
            : [selectedAffiliate],
        prepaid,
      },
      {
        headers: {
          Authorization: authUser().authToken,
        },
      }
    );
  };

  //   const queryResult = useQueries(
  //     affiliatesArray.map((affiliate) => {
  //       return {
  //         queryKey: ["admin-vend-info", affiliate],
  //         queryFn: () => fetchChargesInfo(affiliate),
  //       };
  //     })
  //   );

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [key ?? "admin-charges-info", selectedAffiliate],
    queryFn: () => fetchChargesInfo(selectedAffiliate),
  });

  return { data: data?.data, isLoading, isFetching, refetch };
}
