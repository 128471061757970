import { format, parseISO } from "date-fns";
import Text from "../../../../components/text";



export const Demo_request = [
  {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Date Created
        </Text>
      ),
      Cell: ({ value, row }) => <Text as="p">
      {format(parseISO(value), 'dd-MM-yyyy H:mm:ss')}
      </Text>,
      accessor: "createdAt",
    },
    {
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Full Name
        </Text>
      ),
      Cell: ({ row }) => (
        <Text as="p" classname='text-left text-[16px]'>
          {`${row.original.firstname} ${row.original.lastname}`}
        </Text>
      ),
      accessor: "firstname",
    },

    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Email
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "email",
    },
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Address
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "address",
    },
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Country
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "country",
    },
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Community
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "community",
    },
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Total Propperty
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "totalProperty",
    },
    
   
  
  ];
export const referer_request = [
  {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Date Created
        </Text>
      ),
      Cell: ({ value, row }) => <Text as="p">
      {format(parseISO(value), 'dd-MM-yyyy H:mm:ss')}
      </Text>,
      accessor: "createdAt",
    },
    {
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Full Name
        </Text>
      ),
      Cell: ({ row }) => (
        <Text as="p" classname='text-left text-[16px]'>
          {`${row.original.firstname} ${row.original.lastname}`}
        </Text>
      ),
      accessor: "firstname",
    },
    
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Telephone
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "phoneNumber",
    },
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Email
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "email",
    },
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Address
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "address",
    },
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Country
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "country",
    },
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Community
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "community",
    },
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Total Propperty
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "totalProperty",
    },
    
   
  
  ];