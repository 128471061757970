import React from 'react';
import { FaBeer, FaCoffee } from 'react-icons/fa';
import { BsFillPeopleFill } from "react-icons/bs";
import { RiBillLine } from "react-icons/ri";
import { GrHostMaintenance, GrShieldSecurity } from "react-icons/gr";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { TfiAnnouncement } from "react-icons/tfi";
import { IoSettingsSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';

const services = [
  { icon: <FaBeer />, description: 'Transaction',  route: '/iobotech/dashboard/monitoring' },
  { icon: <RiBillLine />, description: 'Billing', route: '/iobotech/dashboard/billing' },
  { icon:  <BsFillPeopleFill />, description: 'User Management',  route: '/iobotech/dashboard/resident' },
  { icon: <GrHostMaintenance />, description: 'Maintenance',  route: '/iobotech/dashboard/maintenance' },
  { icon: <GrShieldSecurity />, description: 'Security',  route: '/iobotech/dashboard/security' },
  { icon: <HiBuildingOffice2 />, description: 'Public facilities', route: '/iobotech/dashboard/public-facilities' },
  { icon: <TfiAnnouncement />, description: 'Announcement',route: '/iobotech/dashboard/announcement' },
  { icon: <IoSettingsSharp />, description: 'Settings', route: '/iobotech/dashboard/settings' },
  { icon: <IoSettingsSharp />, description: 'SOS', route: '/iobotech/dashboard/sos' },
];

function Index() {
  return (
    <div className="w-[93%] lg:w-[90%] mx-auto lg:py-[40px]">
      <div className="grid lg:grid-cols-4 md:grid-cols-3 gap-[40px] mt-[20px] items-center">
        {services.map((service, index) => (
          <Link to={service.route} key={index} className="hover:shadow-md">
            <div className="flex flex-col items-center justify-center w-full bg-[#ECEEFA] rounded-md p-[20px] h-[200px] gap-3">
              <div className="text-4xl p-2 mb-2 bg-white rounded-full text-black">
                {service.icon}
              </div>
              <div className="text-center font-bold text-black">
                {service.description}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Index;
