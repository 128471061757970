import React from "react";
import { clx } from "../utils/clx";
import Text from "./text";
import { providers } from "./modals/guest/providers";

export default function DiscoCard({ classname, onclick }) {
  const classes = clx(
    "w-[740px] h-fit rounded-[20px] bg-[#F5F5FE] py-[10px] shadow-lg border-2 border-transparent hover:border-2 hover:border-black/20 hover:cursor-pointer dark:bg-[#070917]",
    classname
  );
  return (
    <div className={classes} onClick={onclick}>
      <div
        onClick={onclick}
        className="py-4 lg:py-6 px-[30px] flex justify-between items-center border-b-2 border-[#E6E6E9] dark:border-[#1B1D26]"
      >
        <Text
          as="h3"
          weight="semibold"
          classname="text-[18px] lg:text-[23px] text-primary-dark dark:text-[#E6E6E9]"
        >
          Disco Electricity Meter
        </Text>
      </div>

      <div className="flex p-6 px-[30px] gap-6 ">
        {providers?.map(
          (provider, index) =>
            index !== 0 && (
              <div className="rounded  w-12 h-12">
                <img
                  src={provider.icon}
                  alt=""
                  className="w-full  mr-2 object-cover"
                />
              </div>
            )
        )}
      </div>
    </div>
  );
}
