import React from "react";
import Text from "../../components/text";
import { clx } from "../../utils/clx";
import Banner from "./components/banner";
import MayAlsoLike from "./components/may-also-like";
import ProductCard from "./components/product-card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { ProductItems } from "../../utils/items";

export default function MarketPlaceWrapper() {
  const categoryItems = [
    {
      id: 1,
      category: "solar",
      imgSrc: "",
    },
    {
      id: 2,
      category: "electronics",
      imgSrc: "",
    },
    {
      id: 3,
      category: "power",
      imgSrc: "",
    },
    {
      id: 4,
      category: "solar",
      imgSrc: "",
    },
    {
      id: 5,
      category: "electronics",
      imgSrc: "",
    },
    {
      id: 6,
      category: "power",
      imgSrc: "",
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    
    speed: 500,
    slidesToShow: 2, // Number of items to show at once
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1.8,
        },
      },
    ],
  };
  const classes = clx("w-[93%] lg:w-[90%] flex flex-col mx-auto");
  return (
    <React.Fragment>
      <div className={classes}>
        {/* hero section */}
        <div className="flex flex-col gap-12 lg:flex-row lg:justify-between items-center h-[85vh] mb-0 lg:mb-8">
          {/* left side */}
          <div className="flex flex-col mt-12 lg:mt-0 space-y-6 lg:max-w-[700px] w-full">
            <Text
              as="h1"
              weight="bold"
              classname="text-4xl lg:text-6xl font-bold leading-10"
            >
              Get Access To Quality Products
            </Text>
            <Text
              as="p"
              weight="normal"
              classname="text-base md:text-[20px] text-[#6C6F7F]
"
            >
              With Prepaidmeter, monitoring your assest remotely is made easy
              and also supported by variety of interesting features.
            </Text>
          </div>
          {/* right side */}
          <div className="relative flex flex-col gap-6 lg:max-w-[700px] w-full h-[40%] lg:h-[80%] self-start">
            <div
              className="absolute top-0 right-0 bg-black w-full h-full"
              style={{
                clipPath: "polygon(0 0, 100% 0, 100% 96%, 31% 100%)",
              }}
            ></div>
            <figure className="absolute top-[42%] translate-x-[-50%] translate-y-[-50%] left-[60%] lg:right-[-41%] h-[70%] lg:h-[55%] w-[70%] lg:w-[80%]">
              <img
                src="/assets/images/solar-panel.png"
                className="w-full h-full object-cover"
                alt="solar-panel"
              />
            </figure>
            <figure className="absolute top-[50%] translate-x-[-50%] translate-y-[-50%] left-[25%] h-[70%] lg:left-[18%] lg:h-[55%] w-[70%] lg:w-[80%]">
              <img
                src="/assets/images/car-engine.png"
                className="w-full h-full object-cover"
                alt="car-engine"
              />
            </figure>
            <figure className="absolute top-[76%] translate-x-[-50%] translate-y-[-50%] w-[70%] left-[60%] lg:left-[48%] lg:h-[55%] h-[70%] lg:w-[80%] ">
              <img
                src="/assets/images/home-appliance.png"
                className="w-full h-full object-cover"
                alt="home-appliance"
              />
            </figure>
          </div>
        </div>
      </div>

      <Banner />
      <div className="py-[50px] lg:py-[140px] px-5 lg:px-20 flex justify-center mb-5 lg:mb-10">
        <div className="grid grid-cols-3 gap-5 lg:gap-10">
          {categoryItems.map((item) => (
            <Link to={`/marketplace/${item.category}`} key={item.id}>
              <Card key={item.id} title={item.category} imgSrc={item.imgSrc} />
            </Link>
          ))}
        </div>
      </div>

      {/* May also like */}

      <div className="py-[50px] lg:py-[140px] px-5 lg:px-20 flex flex-col gap-16 lg:gap-56 items-center  bg-[#E6E9FB]">
        <MayAlsoLike />

        <div className="flex flex-col space-y-6 lg:flex-row w-full lg:justify-between h-full">
          <div className="flex gap-6 flex-col justify-between">
            <Text
              as="h1"
              weight="bold"
              classname=" text-3xl text-center lg:text-left lg:text-7xl"
            >
              Ginjar Top Selling
            </Text>

            <div className="flex flex-col gap-6">
              <Slider {...settings} className="lg:w-[612px]">
                {ProductItems.map((item, index) => (
                  <div key={index} className="px-2 mr-3">
                    <ProductCard item={item} />
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          <div className=" flex justify-center">
            <img
              src="/assets/images/lady-on-red.png"
              alt=""
              className=" w-[342px] lg:w-[600px] object-cover"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const Card = ({ title, imgSrc }) => {
  return (
    <div className="flex flex-col space-y-2 lg:space-y-6">
      <div className="bg-gray-300 w-[106px] lg:w-[300px] h-[122px] lg:h-[400px]"></div>
      <Text
        as="h1"
        weight="medium"
        classname="text-sm lg:text-xl capitalize text-center"
      >
        {title}
      </Text>
    </div>
  );
};
