import React from "react";
import Text from "../../text";
import Input from "../../input";
import Button from "../../button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Radio from "../../radio";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { toast } from "react-toastify";
import useAdminAffiliates from "../../../store/admin/affiliates";
import { TbCurrencyNaira } from "react-icons/tb";

export default function EditRegularCapCharges({ onButtonClick, data, title }) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [radioState, setRadioState] = React.useState(data?.cap.cap_status);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const { selectedAffiliate } = useAdminAffiliates();
  const formValues = {
    cap_at: data?.cap.cap_at,
    cap_name: data?.cap.cap_name,
    cap_to: data?.cap.cap_to,
    cap_slug: data?.cap.cap_slug,
  };

  const validationSchema = Yup.object({
    cap_at: Yup.number()
      .integer("Cap Amount must be a whole number")
      .required("Cap Amount is required"),
    cap_name: Yup.string().required("Cap Name is required"),
    cap_to: Yup.number()
      .integer("Cap Max must be a whole number")
      .required("Cap Max is required")
      .test("cap_to", "Cap To must be less than or equal to Cap At", function (value) {
        const capAt = this.resolve(Yup.ref("cap_at"));
        return value <= capAt;
      }),
    cap_slug: Yup.string().required("Cap Slug is required"),
  });


  const onSubmit = (values) => {
    setIsLoading(true);
    // console.log(values);
    setValidationAttempt(false);
    axios.put("https://pm-server.herokuapp.com/api/v2.0/charges-proxy?query=cap", {
      cap: { ...values, cap_status: radioState },
      tag: selectedAffiliate,
      charges_slug: data.charges_slug,
      insight_slug: data.insight_slug,
      type: data.type,

    }, {
      headers: {
        Authorization: authUser().authToken,
      },
    }).then(res => {
      // console.log(res)
      setIsLoading(false)
      navigate('/manager/configure/charges')
      toast.success(res.data.message)

    }).catch(err => {
      // console.log(err)
      setIsLoading(false)
      toast.error(err.response.data.message)

    }).finally(() => {
      setIsLoading(false)
    })
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <Text
          as="h3"
          weight="bold"
          classname="text-[24px] lg:text-[30px] text-center"
        >
          {title}
        </Text>
        <div className="my-2 flex flex-col space-y-1">
          <Input
            label="Cap Name:"
            type="text"
            name="cap_name"
            placeholder={data?.cap.cap_name}
            defaultValue={data?.cap.cap_name}
            value={formik.values.cap_name}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.cap_name}
          />
          <Input
            label="Cap At:"
            type="number"
            name="cap_at"
            iconLeft={<TbCurrencyNaira />}
            defaultValue={data?.cap.cap_at}
            placeholder={data?.cap.cap_at}
            value={formik.values.cap_at}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.cap_at}
          />
          <Input
            label="Cap To:"
            type="number"
            iconLeft={<TbCurrencyNaira />}
            name="cap_to"
            defaultValue={data?.cap.cap_to}
            placeholder={data?.cap.cap_to}
            value={formik.values.cap_to}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.cap_to}
          />
          <Input
            label="Cap Slug:"
            type="text"
            name="cap_slug"
            defaultValue={data?.cap.cap_slug}
            placeholder={data?.cap.cap_slug}
            value={formik.values.cap_slug}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.cap_slug}
          />
        </div>
        <div className="flex flex-col my-1">
          <Text
            as="p"
            weight="bold"
            classname="text-[16px] text-left"
          >
            Activate Cap:
          </Text>
          <div className="flex flex-row space-x-2">
            <Radio
              name='filter_type'
              label='Yes'
              classname='text-[16px]'
              value="true"
              checked={radioState}
              onchange={() => setRadioState(true)}
              onclick={() => setRadioState(true)}

            />
            <Radio
              name='cap_status'
              label='No'
              classname='text-[16px]'
              value="false"
              checked={radioState === false}
              onchange={() => setRadioState(false)}
              onclick={() => setRadioState(false)}

            />

          </div>
        </div>
        <Button buttonType="submit" classname="w-full" isLoading={isLoading}>
          Edit
        </Button>
      </div>
    </form>
  );
}
