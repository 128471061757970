import axios from "axios";
import React from "react";
import { useAuthUser } from "react-auth-kit";
import { useQuery } from "react-query";


const useMeterRequst = () => {
    const authUser = useAuthUser();
  
    const { isLoading, data, isError, isFetching, refetch,refetchOnMount } = useQuery(
      "request-meter",
      () =>
        axios.get(
          "https://api-pm-e1ea777feac4.herokuapp.com/metering/buy-meter-request",
          { headers: { Authorization: authUser().authToken } }
        ),
        {
          enabled: true,
          refetchOnWindowFocus: 'always',
          refetchOnMount: true,
          refetchOnReconnect: true,
          staleTime:0
        }
    );
  
    return { isLoading, data, isError, isFetching, refetch,refetchOnMount };
  };
  
  export default useMeterRequst;
  
