import React from "react";
import StaticLayout from "../../layouts/static-layout";
import BuyElectricityWrapper from "../../views/static/buy-electricity/buy-electricity-page"

export default function BuyElectricityPage() {
  return <React.Fragment>
    <StaticLayout>
      <BuyElectricityWrapper />
    </StaticLayout>
  </React.Fragment>
}
