import Button from "../../button";
import Radio from "../../radio";
import Text from "../../text";
export default function ClearTamperModal({ onButtonClick, radioState, setRadioState, loading }) {
    const onSubmit = () => {
        onButtonClick();
    };
    return (
        <div>
            <Text
                as="h3"
                weight="bold"
                classname="text-[24px] lg:text-[30px] text-center"
            >
                Clear Tamper
            </Text>
            <div className="my-6 flex flex-col bg-[#6C6F7F] items-center p-6 space-y-6 rounded-lg">
                <Text as='h3' classname='text-white'>
                    Remotely ?
                </Text>
                <div className="flex flex-row space-x-6">
                    <Radio
                        name="confirm-remotely"
                        label='YES'
                        classname='text-[16px] text-white'
                        value="yes"
                        checked={radioState === "yes"}
                        onchange={({ target }) => setRadioState(target.value)}
                        onclick={({ target }) => setRadioState(target.value)}
                    />
                    <Radio
                        name='filter-type'
                        label='NO'
                        classname='text-[16px] text-white'
                        value="no"
                        checked={radioState === "no"}
                        onchange={({ target }) => setRadioState(target.value)}
                        onclick={({ target }) => setRadioState(target.value)}
                    />
                </div>
                <div>
                    <Text as='p' classname='text-white'>
                        Yes - Your tamper will be cleared <br />
                        No - A token will be sent to you
                    </Text>
                </div>
            </div>
            <Button onclick={onSubmit} isLoading={loading} classname="w-full">
                Continue
            </Button>
        </div>

    );
}
