import React from "react";
import { BsSearch } from "react-icons/bs";

import Table from "../../../components/table";
import { clx } from "../../../utils/clx";
import Input from "../../../components/input";
import useAdminUserList from "../../../hooks/apis/admin/useAdminUserList";
import { unregistered_users_column } from "../../../utils/table-utils/admin/unregistered-users-table";



export default function UnregisteredUsers() {
    const [search, setSearch] = React.useState("");
    const {
        data: userList,
        isLoading: isUserListLoading,
        isFetching: isUserListFetching,
    } = useAdminUserList("unregistered")

    const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
    return (
        <React.Fragment>
            <div className={classes}>
                <div className="max-w-full h-full rounded-[8px] bg-white p-6 flex flex-col space-y-6 relative">
                    <div className="flex flex-row justify-end">
                        <div className="flex items-center">
                            <Input
                                iconLeft={<BsSearch />}
                                placeholder="Search By Meter ID"
                                inputClass="py-[6px] px-[15px] rounded-r-0"
                                onchange={({ target }) => setSearch(target.value)}
                            />
                        </div>
                    </div>
                    <Table
                        search={search}
                        data={userList?.data ?? []}
                        columns={unregistered_users_column}
                        isLoading={isUserListLoading || isUserListFetching}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}