import React from "react";
import Chart from "react-apexcharts";
import { FiFilter } from "react-icons/fi";
import Text from "../../../../components/text";
import useChart from "../../../../hooks/useChart";
import { clx } from "../../../../utils/clx";

export default function FuelConsumptionChart({
  handleFilteredChart,
  onFilterClick,
  classname,
  children,
}) {

  const handleFilterClick = (chartType) => {
    handleFilteredChart(chartType);
    onFilterClick();
  };

  const barChartConfig = useChart({
    type: "bar",
    colors: ["#DFA22D"],
    categories: ["S", "M", "T", "W", "T", "F", "S"],
    data: [90, 40, 39, 57, 13, 45, 84],
  });
  const areaChartConfig = useChart({
    type: "area",
    colors: ["#EAC36B", "#fff", "#fff", "#fff"],
    categories: ["8am", "10am", "12am", "2am", "4am"],
    data: [1.7, 0.2, 2.6, 1.8, 2.9],
    strokeColors: ["#EAC36B"],
  });
  const classes = clx("", classname);
  return (
    <div className={classes}>
      <Text as='p' weight='medium' classname='lg:text-[23px] text-[#6C6F7F]'>
        Fuel Monitoring
      </Text>
      <div className='py-[10px] md:py-[20px] h-fit flex flex-col lg:flex-row lg:items-center lg:space-x-6 space-y-10 lg:space-y-0'>
        <div className='py-4 lg:flex-1 rounded-[20px]  shadow-xl'>
          <div className='max-w-full p-4'>
            <FuelConsumptionChart.ChartTopBar
              onFilterClick={() => handleFilterClick("bar")}
            />
            <Chart {...barChartConfig} />
          </div>
        </div>
        <div className='py-[10px] md:py-[28px] lg:flex-1 rounded-[20px]  shadow-xl'>
          <div className='max-w-full p-4'>
            <FuelConsumptionChart.ChartTopBar
              onFilterClick={() => handleFilterClick("area")}
              noTabs
            />
            <Chart {...areaChartConfig} />
          </div>
        </div>
      </div>
    </div>
  );
}

export function ChartTopBar({ onFilterClick, classname, noTabs, children }) {
  const classes = clx("", classname);
  return (
    <div className={classes}>
      {!noTabs && <ChartTopBar.Tabs classname='my-4' />}
      <div className='w-[90%] mx-auto'>
        {noTabs && (
          <Text as='span' classname='inline-block text-[#6C6F7F] py-2'>
            12 - 10 - 2022
          </Text>
        )}
        <div className='pb-2 mb-4 flex items-center justify-between border-b-2 border-[#6C6F7F]/10'>
          <div className=''>
            {/* <Text as='h5' classname='text-[28px] font-semibold'>
              524.52 <span className='text-[16px]'>kwh</span>
            </Text> */}
            <Text as='p' classname='text-[12px] text-[#DB9800]'>
              Estimated cost is around ₦1000
            </Text>
          </div>
          <div
            className='cursor-pointer flex items-center space-x-1'
            onClick={onFilterClick}
          >
            <FiFilter />
            <Text as='span' classname='text-[14px] lg:text-base'>
              Filter Data
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Tabs({ classname, children }) {
  const [activeTab, setActiveTab] = React.useState(1);
  const classes = clx("w-fit mx-auto flex items-center space-x-6", classname);
  return (
    <div className={classes}>
      {["Daily", "Weekly", "Monthly"].map((tab, index) => (
        <div
          key={index}
          className={`py-[6px] px-[16px] rounded-[4px] font-semibold cursor-pointer ${
            activeTab === index + 1
              ? "bg-primary-dark text-white"
              : "bg-[#E6E6E9] text-primary-dark"
          }`}
          onClick={() => setActiveTab(index + 1)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
}

ChartTopBar.Tabs = Tabs;
FuelConsumptionChart.ChartTopBar = ChartTopBar;
