import { useNavigate } from "react-router-dom";
import Text from "../../../components/text";
import useSelectedEnergyUser from "../../../store/admin/selected-energy-user";

export const customer_data = Array.from({ length: 10 }).map((data, i) => ({
  _id: i + 1,
  name: "Oloko Opeyemi ",
  email: "olokoopeyemi06@gmail.com",
  meter_id: `5232464252${i}`,
}));

export const customer_column = [

  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Name
      </Text>
    ),
    accessor: "fullname",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Apartment
      </Text>
    ),
    accessor: "apartment",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        MeterID
      </Text>
    ),
    accessor: "meterid",
  },

  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Email
      </Text>
    ),
    accessor: "email",
  },
  {
    id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Actions
      </Text>
    ),
    Cell: ({ value, row }) => {
      const { setSelectedEnergyUser } = useSelectedEnergyUser();
      const navigate = useNavigate();
      return (
        <div className="flex items-center space-x-4">
          <Text
            as="p"
            classname="text-left text-[16px] text-secondary-dark"
            onclick={() => {
              setSelectedEnergyUser(row.original);
              navigate("single");
            }}
          >
            View
          </Text>
          {/* <Text as='p' classname='text-left text-[16px]'>
          Restrict
        </Text> */}
        </div>
      );
    },
    // accessor: "_id",
  },
];
export const customer_charges_column = [

  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Name
      </Text>
    ),
    accessor: "fullname",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Apartment
      </Text>
    ),
    accessor: "apartment",
  },

  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Email
      </Text>
    ),
    accessor: "email",
  },
  {
    id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Actions
      </Text>
    ),
    Cell: ({ value, row }) => {
      const { setSelectedEnergyUser } = useSelectedEnergyUser();
      const navigate = useNavigate();
      return (
        <div className="flex items-center space-x-4">
          <Text
            as="p"
            classname="text-left text-[16px] text-secondary-dark"
            onclick={() => {
              setSelectedEnergyUser(row.original);
              navigate("single");
            }}
          >
            View
          </Text>
          {/* <Text as='p' classname='text-left text-[16px]'>
          Restrict
        </Text> */}
        </div>
      );
    },
    // accessor: "_id",
  },
];
