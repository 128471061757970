import React from "react";
import useAffiliatesSettlementsDetails from "../../../../../hooks/apis/admin/useAffiliatesSettlementsDetails";
import { clx } from "../../../../../utils/clx";
import Text from "../../../../../components/text";
import Button from "../../../../../components/button";
import ModalWrapper from "../../../../../components/modals/modal-wrapper";
import SettlementActionsModal from "../../../../../components/modals/admin/sttlements-modal/settlement-actions";
import SetInstantCharges from "../../../../../components/modals/admin/sttlements-modal/set-instant-charges";
import StartSettlement from "../../../../../components/modals/admin/sttlements-modal/start-settlement";
import ChangeSettlement from "../../../../../components/modals/admin/sttlements-modal/change-settlement";


export default function InstantSettlements() {
    const {
        data: details,
        isLoading,
    } = useAffiliatesSettlementsDetails()
    const [modalType, setModalType] = React.useState("")
    const [modalOpen, setModalOpen] = React.useState(false)
    const handleButtonClick = (type) => {
        setModalType(type);
        setModalOpen(true);
    };

    const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex justify-center");
    return (
        <React.Fragment>

            <div className={classes}>
                <div className="bg-white max-w-[600px] p-8 w-full h-full flex flex-col items-center rounded-lg">

                    {
                        isLoading ?
                            <div>
                                <Text as="h3" weight="semibold" classname="text-center">Loading...</Text>
                            </div>
                            :
                            <>
                                {
                                    details?.data?.settlement ?

                                        <div className="flex flex-col space-y-6 w-full">
                                            <Text as="h3" weight="semibold" classname="text-center text-xl">
                                                Settlements
                                            </Text>
                                            <div className="grid grid-cols-2 gap-6 w-full">
                                                {
                                                    (details?.data?.settlement?.slug === "default" && !details?.data?.settlement?.data.charges)
                                                    && (<Button variant="outlined" weight="medium" size="small"
                                                        onclick={() => handleButtonClick("set-charges")}
                                                    >
                                                        Set Charges
                                                    </Button>)
                                                }
                                                {
                                                    details?.data?.settlement?.data.charges
                                                    && (<Button variant="outlined" weight="medium" size="small"
                                                        onclick={() => handleButtonClick("edit-charges")}
                                                    >
                                                        Edit Charges
                                                    </Button>)
                                                }

                                                {(details?.data?.settlement?.slug === "default" && details?.data?.settlement?.data.charges)
                                                    &&
                                                    (<Button variant="outlined" weight="medium" size="small"
                                                        onclick={() => handleButtonClick("start")}
                                                    >
                                                        Start settlement
                                                    </Button>)
                                                }


                                                {details?.data?.settlement?.slug !== "default" && (
                                                    <>
                                                        <Button variant="outlined" weight="medium" size="small"
                                                            onclick={() => handleButtonClick("change")}
                                                        >
                                                            Change Settlement
                                                        </Button>

                                                        <Button variant="outlined" weight="medium" size="small"
                                                            onclick={() => handleButtonClick("remove")}
                                                        >
                                                            Remove Settlement
                                                        </Button>

                                                    </>
                                                )}
                                            </div>

                                        </div>
                                        : <div>
                                            <Text as="h3" weight="semibold" classname="text-center">No details</Text>
                                        </div>
                                }
                            </>
                    }
                </div>
            </div>

            <ModalWrapper
                isOpen={modalOpen}
                handleModalClose={() => setModalOpen(false)}
            >
                {modalType === "change" && <ChangeSettlement slug={details?.data?.settlement?.slug} />}

                {modalType === "start" && <StartSettlement />}
                {modalType === "remove" && <SettlementActionsModal btnType="Remove" query="remove-settlement" type="Remove Settlement" />}
                {modalType === "set-charges" && <SetInstantCharges data={details?.data?.settlement?.data.charges} btnType="Set-charges" type="Set Instant Charges" />}
                {modalType === "edit-charges" && <SetInstantCharges data={details?.data?.settlement?.data.charges} btnType="Edit-charges" type="Edit Instant Charges" />}
            </ModalWrapper>

        </React.Fragment>
    )
}


