import React from "react";
import { Link } from "react-router-dom";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";

export default function AdminMangementWrapper() {
    const routes = [
        {
            id: 1,
            title: "Managers Profile",
            image: "/assets/icons/manager-profile.png",
            path: "managers-profile",
        },
        {
            id: 2,
            title: "Restrict Manager",
            image: "/assets/icons/restrict-manager.png",
            path: "restrict-manager",
        },
        // {
        //     id: 3,
        //     title: "Grant Branch Access",
        //     image: "/assets/icons/admin.png",
        //     path: "",
        // },
    ];

    const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
    return (
        <div className={classes}>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 gap-[40px] mt-[20px] items-center">
                {routes.map((item) => (
                    <Link
                        to={`/manager/configure/admin-management/${item.path}`}
                        key={item.id}
                        className="hover:shadow-md"
                    >
                        <div
                            key={item.id}
                            className="flex flex-col items-center  justify-center w-full bg-white  rounded-md p-[20px] h-[200px] gap-3"
                        >
                            <img src={item.image} className="w-[78px]" alt="icon" />
                            <Text as="p" classname={`font-bold`}>
                                {item.title}
                            </Text>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}
