import React from "react";
import StaticFooter from "../components/static-footer";
import StaticHeader from "../components/static-header";
import { clx } from "../utils/clx";
import { CgMenuRight } from "react-icons/cg";
import { MdCancel } from "react-icons/md";
import { signUpOptions, staticMenu } from "../constants/static-menu";
import Text from "../components/text";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import Button from "../components/button";

export default function StaticLayout({ classname, headerClass, children }) {
  const [isNavOpen, setNavOpen] = React.useState(false);

  const classes = clx("min-h-[100%]", classname);
  return (
    <div className={classes}>
      <div className="hidden lg:block">
        <StaticHeader containerClass={headerClass} />
      </div>
      <StaticLayout.MobileHeader
        isOpen={isNavOpen}
        handleClose={() => setNavOpen(false)}
      />
      <div className="flex justify-between lg:hidden p-6">
        <Link to="/">
          <img src="/assets/images/logo2.png" alt="" width="150px" />
        </Link>
        <div onClick={() => setNavOpen(true)}>
          <CgMenuRight className="text-[30px] text-primary-dark dark:text-white" />
        </div>
      </div>
      <div className="w-full">{children}</div>
      <StaticFooter />
    </div>
  );
}

export function MobileHeader({ classname, isOpen, handleClose }) {
  const router = useLocation();
  const [openSubmenu, setOpenSubmenu] = React.useState(null);
  const [signUpOptionsOpen, setSignUpOptionsOpen] = React.useState(false);

  const toggleSubmenu = (index) => {
    if (openSubmenu === index) {
      setOpenSubmenu(null);
    } else {
      setOpenSubmenu(index);
    }
  };

  const handleMouseEnter = (index) => {
    setOpenSubmenu(index);
  };

  const handleMouseLeave = () => {
    setOpenSubmenu(null);
  };
  const classes = clx(
    "lg:hidden fixed inset-0 z-50 h-[100%] max-h-[100%] w-full bg-black/5 overflow-hidden duration-300",
    isOpen ? "ml-0" : "ml-[-100%]",
    classname
  );
  return (
    <div
      className={classes}
      onClick={({ target, currentTarget }) =>
        target === currentTarget && handleClose()
      }
    >
      <div className="w-[300px] h-[100%] max-h-[100%] bg-white px-[20px] py-[40px] pb-[80px] overflow-auto">
        <div
          className="w-fit"
          onClick={({ target, currentTarget }) => handleClose()}
        >
          <MdCancel className="text-[33px] text-primary-dark" />
        </div>
        <div className="flex flex-col  space-y-6 pt-[30px]">
          {staticMenu.map((link, index) => (
            <div key={index}>
              <div className="flex items-center justify-between">
                <Text
                  href={link.route}
                  as="span"
                  weight={
                    router.pathname === link.route ? "semibold" : "normal"
                  }
                  onclick={() => toggleSubmenu(index)}
                  classname={`${
                    router.pathname === link.route &&
                    "lg:text-[18px] text-sm text-transparent bg-clip-text bg-gradient-to-l from-primary-dark via-primary-light to-secondary-dark "
                  } cursor-pointer`}
                >
                  {link.label}
                </Text>
                {link.subroutes && (
                  <div
                    onClick={() => toggleSubmenu(index)}
                    className="cursor-pointer"
                    onMouseEnter={() => handleMouseEnter(index)}
                  >
                    {openSubmenu === index ? (
                      <IoMdArrowDropup className="h-5 w-5" />
                    ) : (
                      <IoMdArrowDropdown className="h-5 w-5" />
                    )}
                  </div>
                )}
              </div>
              {link.subroutes && openSubmenu === index && (
                <div
                  className="w-[200px] left-0 mt-2 rounded-lg bg-white shadow-md"
                  onMouseLeave={() => handleMouseLeave()}
                >
                  {link.subroutes.map((sublink) => (
                    <Text
                      as="p"
                      key={sublink.id}
                      onclick={() => {
                        window.location.href = sublink.route;
                      }}
                      classname="px-4 py-2 rounded-lg hover:bg-gray-100 cursor-pointer"
                    >
                      {sublink.label}
                    </Text>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex flex-col mt-6 space-y-4">
          <Button
            variant="outlined"
            size="small"
            href="/login"
            classname="md:text-sm lg:text-xl"
          >
            Sign in
          </Button>
          <div>
            <Button
              variant="contained"
              size="small"
              onclick={() => setSignUpOptionsOpen((prev) => !prev)}
            >
              Sign up
            </Button>
            {signUpOptions && signUpOptionsOpen && (
              <div
                className="w-[200px] mt-2 bg-primary-dark rounded-lg shadow-md"
                onMouseLeave={() => setSignUpOptionsOpen((prev) => !prev)}
              >
                {signUpOptions.map((sublink) => (
                  <Text
                    as="p"
                    key={sublink.id}
                    onclick={() => {
                      window.location.href = sublink.route;
                    }}
                    classname="px-4 py-2 text-white hover:bg-primary-light rounded-lg cursor-pointer"
                  >
                    {sublink.label}
                  </Text>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

StaticLayout.MobileHeader = MobileHeader;