import axios from "axios"

// charges/user-overview?userid=UiD-16906636257170
export const getSingleUserAdminCharges =async(auth,userid)=>{
  return axios.get('https://api-pm-e1ea777feac4.herokuapp.com/charges/user-overview',{
    params:{
        userid:userid
        // userid: 'UiD-16906636257170',
    },
    headers :{
        Authorization:auth
        // Authorization: '%7B%22token%22%3A%22eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTlmOTZiM2U0NzM5M2U2NWYzNmVlYzYiLCJyb2xlIjoibWFuYWdlciIsImlhdCI6MTcwNTU4NTM5MSwiZXhwIjoxNzA1NTg4OTkxfQ.Iko6tSsQx_SZqMie462k5-nSbvHk5A-i0Tbj64Q5YYc%22%7D',
    }
  })
}

// await axios.get(
//     'https://api-pm-e1ea777feac4.herokuapp.com/charges/user-overview',
//     {
//       params: {
//         userid: 'your_user_id_here', // Provide the actual user id
//       },
//       headers: {
//         Authorization: 'your_access_token_here', // Provide the actual access token
//       },
//     }
//   );