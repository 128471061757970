import React, { useEffect } from "react";
import Text from "../../components/text";
import { clx } from "../../utils/clx";
import Button from "../../components/button";
import ModalWrapper from "../../components/modals/modal-wrapper";
import ConfirmPeriodicCharges from "../../components/modals/confirm-periodic-charges";
import RechargeSuccess from "../../components/modals/recharge-success";
import { getFormattedPrice } from "../../utils/getFormattedPrice";
import useUserCharges from "../../hooks/apis/user/useUserCharges";
import useChargesPayment from "../../hooks/apis/user/useChargesPayment";
import useChargesTransactionHistory from "../../hooks/apis/user/useChargesTransactionHistory";
import Table from "../../components/table";
import { userChargesTransactionHistoryColumns } from "../../utils/table-utils/user/columns/user-transaction-history ";

export const periodicList = [
  {
    id: 1,
    title: "Weekly Charge",
    bill: "2,000",
    type: "weekly",
  },
  {
    id: 2,
    title: "Monthly Charge",
    bill: "2,000",
    type: "monthly",
  },
  {
    id: 3,
    title: "Quarterly Charge",
    bill: "2,000",
    type: "quarterly",
  },
  {
    id: 4,
    title: "Semi-Annual Charge",
    bill: "2,000",
    type: "semi-annual",
  },
  {
    id: 5,
    title: "Annual Charge",
    bill: "2,000",
    type: "annual",
  },
  {
    id: 6,
    title: "Total Charge",
    bill: "2,000",
    type: "total",
  },
];

export default function PeriodicCharges() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [paymentType, setPaymentType] = React.useState();
  const onPayNow = (item) => {
    setModalType(item.slug);
    setIsModalOpen(true);
  };
  const transaction_columns = React.useMemo(
    () => userChargesTransactionHistoryColumns,
    []
  );
  const {
    data: responseData,
    refetch: fetch,
    isLoading: loading,
    isError: error,
    isSuccess,
  } = useChargesPayment({
    key: "user-periodic-payment",
    payloads: { type: "periodic", charges_slug: modalType },
  });

  // console.log(error, isSuccess);

  const handlePayment = (options) => {
    fetch();
  };

  const { data, refetch } = useUserCharges({
    key: "user-periodic-charges",
    type: "periodic",
  });

  useEffect(() => {
    if (isSuccess) {
      setModalType("success");
      refetch();
    }
  }, [responseData]);

  const { data: transactionHistory, isLoading: historyLaoding } =
    useChargesTransactionHistory({
      key: "user-periodic-charges-history",
      type: "periodic",
    });
  // console.log(transactionHistory);
  useEffect(() => {
    refetch();
  }, []);

  const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:block");
  return (
    <React.Fragment>
      <div className={classes}>
        <div className="grid sm:grid gap-3 md:grid-cols-3  md:mt-[20px] items-center  mt-[10px] grid-cols-2 ">
          {data?.data?.map((item) => {
            return (
              <div
                key={item?.slug}
                className="flex flex-col items-center justify-center w-[200px] bg-[#E6E6E9] rounded-md h-[200px] gap-3"
              >
                <Text
                  as="h3"
                  weight="bold"
                  classname="capitalize text-primary-dark"
                >
                  {item?.name}
                </Text>
                <Text as="p" weight="bold" classname="text-secondary-dark mt-3">
                  Bill: ₦ {getFormattedPrice(item?.amount_to_pay)}
                </Text>

                <Button
                  classname="py-2 px-4 rounded-md text-center bg-primary-dark text-[12px] hover:bg-primary-light transition-all duration-200 ease-in-out mt-2"
                  onclick={() => {
                    setPaymentType(item);
                    onPayNow(item);
                  }}
                  
                >
                  Pay Now
                </Button>
              </div>
            );
          })}
        </div>

        <div>
          <Text
            as="h5"
            weight="semibold"
            classname="text-[18px] lg:text-[28px] py-[25px]"
          >
            Transaction History
          </Text>
          <div className="py-[30px]">
            <Table
              search={search}
              columns={transaction_columns}
              isLoading={historyLaoding}
              data={transactionHistory?.data ?? []}
            />
          </div>
        </div>
      </div>

      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => setIsModalOpen(false)}
      >
        {modalType === paymentType?.slug && (
          <ConfirmPeriodicCharges
            data={paymentType}
            onButtonClick={() => handlePayment(paymentType)}
            isLoading={loading}
          />
        )}

        {modalType === "success" && (
          <>
            <RechargeSuccess
              text="Your services charge payment was successful"
              title="Successful"
              onButtonClick={() => setIsModalOpen(false)}
            />
          </>
        )}
      </ModalWrapper>
    </React.Fragment>
  );
}
