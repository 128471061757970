import Text from "../../../../components/text";
import { getFormattedPrice } from "../../../getFormattedPrice";

export const userChargesTransactionHistoryColumns = [
  {
    id: "day",
    Header: (
      <Text
        as="p"
        classname="text-left text-[16px] lg:text-[18px] text-[#2C3048]"
      >
        Date
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className="flex items-center space-x-4">
        <Text as="p" classname="text-left text-[16px]">
          {value}
        </Text>
      </div>
    ),
    accessor: "day",
  },
  {
    id: "type",
    Header: (
      <Text
        as="p"
        classname="text-left text-[16px] lg:text-[18px] text-[#2C3048]"
      >
        Periodic Type
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className="flex items-center space-x-4">
        <Text as="p" classname=" capitalize text-left text-[16px]">
          {value.split("/")[1]}
        </Text>
      </div>
    ),
    accessor: "payout_slug",
  },
  {
    id: "amount",
    Header: (
      <Text
        as="p"
        classname="text-left text-[16px] lg:text-[18px] text-[#2C3048]"
      >
        Amount
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className="flex items-center space-x-4">
        <Text as="p" classname="text-left text-[16px]">
        ₦ {getFormattedPrice(value)}
        </Text>
      </div>
    ),
    accessor: "amount",
  },
];
