import React from "react";
import Button from "../../../../../../components/button";
import Input from "../../../../../../components/input";
import { useFormik } from "formik";
import * as Yup from "yup";


export default function Time(
    { onButtonClick, isLoading }
) {
    const [validationAttempt, setValidationAttempt] = React.useState(false);
    const formValues = {
        time: "",
        entry: "",
        exit: "",
    }

    const validationSchema = Yup.object({
        time: Yup.string().required("Time is Required"),
        entry: Yup.string().required("Entry Date is required"),
        exit: Yup.string().required("Exit Date is required"),
      
    })
    const formik = useFormik({
        initialValues: formValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setValidationAttempt(false);
            // console.log(values);
            if (!values) {
                return;
            }
            onButtonClick(values);
        },
        validateOnChange: validationAttempt,
        validateOnBlur: false,
    });

   
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col justify-between mx-auto w-full space-y-6 max-w-[500px]">
                <Input
                    label="Time"
                    placeholder="Enter Time"
                    type="time"
                    name="time"
                    value={formik.values.time}
                    onchange={formik.handleChange}
                    onblur={formik.handleBlur}
                    errorMessage={formik.errors.time}

                 />
             
                <div className="flex flex-row justify-between items-center gap-1 md:gap-5">
                    <Input
                        label="Entry Date"
                        placeholder="Enter Entry Date"
                        type="date"
                        name="entry"
                        value={formik.values.entry}
                        onchange={formik.handleChange}
                        onblur={formik.handleBlur}
                        errorMessage={formik.errors.entry}
                    />
                    <Input
                        label="Exit Date"
                        placeholder="Enter Exit Date"
                        type="date"
                        name="exit"
                        value={formik.values.exit}
                        onchange={formik.handleChange}
                        onblur={formik.handleBlur}
                        errorMessage={formik.errors.exit}
                    />
                </div>

                <Button buttonType="submit"
                    isLoading={isLoading}
                >
                    Generate Access code
                </Button>
            </div>
        </form>
    )
}