import React from 'react'
import Text from '../../text'
import Button from '../../button';
import Radio from '../../radio';
import { toast } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';

export default function ConfirmPayout({ onButtonClick, selectedRows }) {
    const [agree, setAgree] = React.useState(false)
    const tableData = selectedRows.map((item) => ({
        _id: item.original._id,
        name: item.original.user.fullname,
        apartment: item.original.user.apartment,
        amount: item.original.amount,
    }))

    const total = tableData.reduce((acc, curr) => acc + parseInt(curr.amount), 0);
    const onSubmit = (values) => {
        if (!agree) return toast.error("Kindly agree to the terms and conditions")
        onButtonClick();
    };

    return (
        <div className='flex flex-col items-center space-y-3 p-2'>
            <Text as="h3"
                weight="bold"
                classname="text-[24px] lg:text-[30px] text-center">
                Confirm
            </Text>
            <hr className='w-full' />
            <Text as='p' className=' text-[12px] text-center mt-3'>
                Kindly confirm you are about to pay the total sum of
            </Text>
            <div className='flex flex-col gap-1 items-center mt-3 '
            >
                <Text as='p' className='text-center'>
                    Amount:
                </Text>
                <CurrencyFormat
                    value={total}
                    displayType="text"
                    decimalScale={2}
                    renderText={(value) => <Text as="span" classname="text-[24px] lg:text-[30px] text-center">₦{value}</Text>}
                    thousandSeparator
                    fixedDecimalScale
                />
            </div>
            <div className='flex flex-col items-center rounded-lg px-10  max-h-[300px] overflow-hidden  overflow-y-scroll py-4 bg-[#F0F0FF] mt-6'>
                <Text as='h3' className='text-center text-[18px] mb-3'>
                    To
                </Text>

                <table className='w-full space-x-6 border-collapse  '>
                    <tr className='border-b-2'>
                        <th className='text-left text-[14px] text-gray-400 px-3 py-2'>Name</th>
                        <th className='text-left text-[14px] text-gray-400 px-3 py-2'>Apartment</th>
                        <th className='text-left text-[14px] text-gray-400 px-3 py-2'>Amount</th>
                    </tr>
                    {
                        tableData.map((item) => (
                            <tr key={item._id} className=''>
                                <td className='text-left text-[14px] text-gray-400 px-3 capitalize py-2'>{item.name}</td>
                                <td className='text-left text-[14px] text-gray-400 px-3 capitalize py-2'>{item.apartment}</td>
                                <td className='text-left text-[14px] text-gray-400 px-3 py-2'><CurrencyFormat
                                    value={item.amount}
                                    displayType="text"
                                    decimalScale={2}
                                    renderText={(value) => <Text as="span">₦{value}</Text>}
                                    thousandSeparator
                                    fixedDecimalScale
                                /></td>
                            </tr>
                        ))
                    }

                </table>
            </div>

            <Radio
                label="I agree with the terms and conditions"
                name="checkbox"
                value={agree}
                onchange={(e) => setAgree(e.target.checked)}
                checked={agree}
                onclick={(e) => setAgree(e.target.checked)}
                classname='text-[12px]  text-gray-400  mt-6'
            />
            <Button
                classname='text-[12px]  mt-6'
                onclick={onSubmit}
            >
                Pay now
            </Button>
        </div>
    )
}