import Text from "../../../components/text";
import ReactStars from "react-rating-stars-component";
import React from "react";
import Button from "../../../components/button";
import { Link } from "react-router-dom";

const ratingChanged = (newRating) => {
  console.log(newRating);
};

export default function ProductCard({ item, isbtn = false, category }) {
  return (
    <div className="bg-white flex flex-col w-[200px] lg:max-w-[400px] lg:w-full rounded-md">
      <Link to={`/marketplace/${category}/${item.id}`}>
        <div className="">
          <img
            src={item?.imgSrc}
            alt=""
            className="w-[200px] aspect-square lg:w-[300px]"
          />
        </div>
      </Link>
      <div className="flex flex-col lg:space-y-2 p-2 lg:p-4 mb-2 lg:mb-6">
        <Text
          as="p"
          weight="medium"
          classname=" text-sm lg:text-xl  whitespace-nowrap overflow-hidden truncate text-ellipsis text-gray-500"
        >
          {item?.description}
        </Text>
        <Text as="p" weight="bold" classname="text-lg lg:text-xl">
          N{item?.price}
        </Text>

        <div className="flex flex-row items-center gap-2 ">
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={16}
            isHalf={true}
            emptyIcon={<i className="far fa-star"></i>}
            halfIcon={<i className="fa fa-star-half-alt"></i>}
            fullIcon={<i className="fa fa-star"></i>}
            activeColor="#ffd700"
          />
          <Text as="p" classname=" text-sm text-gray-500">
            {item?.rating} Reviews
          </Text>
        </div>
        {isbtn && <Button classname="text-sm mt-2">Add to Cart</Button>}
      </div>
    </div>
  );
}
