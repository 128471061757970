import React from "react";
import Input from "../../../../../../components/input";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../../../../components/button";


export default function VisitorsDetails({ onFormSumit }) {
    const [validationAttempt, setValidationAttempt] = React.useState(false);
    const formValues = {
        fullname: "",
        email: "",
        phonenumber: "",
        gender: "",
        maximum: "",
        category: "",
    }

    const validationSchema = Yup.object({
        fullname: Yup.string().required("Fullname is Required"),
        email: Yup.string().required("Email is required").email("Invalid Email"),
        phonenumber: Yup.string().required("Phone is required"),
        gender: Yup.string().required("gender is required"),
        maximum: Yup.number().required("Maximum Number of Visitors is required"),
        category: Yup.string().required("Category is required"),
    });

    const formik = useFormik({
        initialValues: formValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setValidationAttempt(false);
            // console.log(values);
            if (!values) {
                return;
            }
            onFormSumit(values);
        },
        validationSchema,
        validateOnBlur: false,
        validationAttempt: validationAttempt,
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col mx-auto w-full space-y-6 max-w-[500px]">

                <Input
                    label="Fullname"
                    placeholder="Enter fullname"
                    type="text"
                    name="fullname"
                    value={formik.values.fullname}
                    onchange={formik.handleChange}
                    onblur={formik.handleBlur}
                    errorMessage={formik.errors.fullname}

                />
                <Input
                    label="Email"
                    placeholder="Enter email"
                    type="email"
                    name="email"
                    value={formik.values.email}
                    onchange={formik.handleChange}
                    onblur={formik.handleBlur}
                    errorMessage={formik.errors.email}

                />
                <Input
                    label="Phone"
                    placeholder="08012345678"
                    type="text"
                    name="phonenumber"
                    value={formik.values.phonenumber}
                    onchange={formik.handleChange}
                    onblur={formik.handleBlur}
                    errorMessage={formik.errors.phonenumber}

                />
                <Input
                    label="Maximum Number of Visitors"
                    placeholder="Enter Maximum Number of Visitors"
                    type="number"
                    name="maximum"
                    value={formik.values.maximum}
                    onchange={formik.handleChange}
                    onblur={formik.handleBlur}
                    errorMessage={formik.errors.maximum}

                />
                <Input
                    label="Category e.g. Family, Friends, etc."
                    placeholder="Enter Category e.g. Family, Friends, etc."
                    type="text"
                    name="category"
                    value={formik.values.category}
                    onchange={formik.handleChange}
                    onblur={formik.handleBlur}
                    errorMessage={formik.errors.category}

                />


                <select
                    name="gender"
                    id=""
                    className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px] my-6 w-full"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </select>
                {formik.touched.gender && formik.errors.gender && (
                    <div>{formik.errors.gender}</div>
                )}

                <Button buttonType="submit">Continue</Button>
            </div>
        </form>
    );
}
