import React from "react";
import useAffiliatesSettlementsDetails from "../../../../../hooks/apis/admin/useAffiliatesSettlementsDetails";
import { clx } from "../../../../../utils/clx";
import Text from "../../../../../components/text";

import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from "react-icons/md"


export default function Details() {
    const {
        data: details,
        isLoading,
    } = useAffiliatesSettlementsDetails()



    const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex justify-center");
    return (
        <div className={classes}>
            <div className="bg-white max-w-[600px] p-8 w-full h-full flex flex-col items-center rounded-lg">

                {
                    isLoading ?
                        <div>
                            <Text as="h3" weight="semibold" classname="text-center">Loading...</Text>
                        </div>
                        :
                        <>
                            {
                                details?.data?.settlement ?

                                    <div className="flex flex-col space-y-6 w-full">
                                        <Text as="h3" weight="semibold" classname="text-center text-xl">Settlement Details</Text>

                                        <div className="flex justify-between items-center">
                                            <Text as="p" weight="medium" classname="">
                                                Name:
                                            </Text>
                                            <Text as="p" weight="medium" classname="text-secondary-dark">
                                                {details?.data?.settlement?.data?.name}
                                            </Text>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <Text as="p" weight="medium" classname="">
                                                Email:
                                            </Text>
                                            <Text as="p" weight="medium" classname="text-secondary-dark">
                                                {details?.data?.settlement?.data?.email}
                                            </Text>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <Text as="p" weight="medium" classname="">
                                                Bank Name:
                                            </Text>
                                            <Text as="p" weight="medium" classname="text-secondary-dark">
                                                {details?.data?.settlement?.data?.bank}
                                            </Text>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <Text as="p" weight="medium" classname="">
                                                Account Number:
                                            </Text>
                                            <Text as="p" weight="medium" classname="text-secondary-dark">
                                                {details?.data?.settlement?.data?.account_number}
                                            </Text>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <Text as="p" weight="medium" classname="">
                                                Active:
                                            </Text>
                                            <Text as="p" weight="medium" classname={`${details?.data?.settlement?.active
                                                === true ? "text-green-500" : "text-red-500"
                                                }`}>
                                                {details?.data?.settlement?.active ? "Yes" : "No"}
                                            </Text>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <Text as="p" weight="medium" classname="">
                                                Settlement Type:
                                            </Text>
                                            <Text as="p" weight="medium" classname="text-secondary-dark">
                                                {details?.data?.settlement?.slug}
                                            </Text>
                                        </div>

                                        <hr />
                                        <div>
                                            {details?.data?.settlement?.data.charges && (
                                                <ChargesCard
                                                    cap_at={details?.data?.settlement?.data.charges.cap_at}
                                                    cap_to={details?.data?.settlement?.data.charges.cap_to}
                                                    name={details?.data?.settlement?.data.charges.name}
                                                    format={details?.data?.settlement?.data.charges.format}
                                                    value={details?.data?.settlement?.data.charges.value}

                                                />
                                            )}
                                        </div>

                                    </div>


                                    : <div>
                                        <Text as="h3" weight="semibold" classname="text-center">No details</Text>
                                    </div>
                            }
                        </>
                }
            </div>

        </div>
    )
}


export const ChargesCard = ({ name, value, format, cap_at, cap_to }) => {
    const [view, setView] = React.useState(false)
    return (
        <div className="flex flex-col space-y-6">
            <div className="flex justify-between items-center">
                <Text as="h3" weight="semibold" classname="text-xl">Charges</Text>
                {
                    view ? (
                        <MdOutlineKeyboardArrowUp onClick={() => setView(prev => !prev)} className="w-6 h-6 cursor-pointer" />
                    ) : (
                        <MdOutlineKeyboardArrowDown onClick={() => setView(prev => !prev)} className="w-6 h-6 cursor-pointer" />
                    )
                }
            </div>

            {
                view && (
                    <div className="flex flex-col space-y-2">
                        <div className="flex justify-between items-center">
                            <Text as="p" weight="medium" classname="">
                                Name:
                            </Text>
                            <Text as="p" weight="medium" classname="text-secondary-dark">
                                {name}
                            </Text>
                        </div>
                        <div className="flex justify-between items-center">
                            <Text as="p" weight="medium" classname="">
                                Value:
                            </Text>
                            <Text as="p" weight="medium" classname="text-secondary-dark">
                                {value}
                            </Text>
                        </div>
                        <div className="flex justify-between items-center">
                            <Text as="p" weight="medium" classname="">
                                Format:
                            </Text>
                            <Text as="p" weight="medium" classname="text-secondary-dark">
                                {format}
                            </Text>
                        </div>
                        {
                            cap_at && (
                                <div className="flex justify-between items-center">
                                    <Text as="p" weight="medium" classname="">
                                        Charges above ₦ {cap_at}:
                                    </Text>
                                    <Text as="p" weight="medium" classname="text-secondary-dark">
                                        ₦ {cap_to}
                                    </Text>
                                </div>

                            )
                        }

                    </div>
                )
            }

        </div>
    )
}