import axios from "axios";
import { useAuthUser } from "react-auth-kit";

import { useQuery } from "react-query";

export default function useGetDiscoMeters({ key, query }) {
  const authUser = useAuthUser();

  //   const navigate = useNavigate();
  const { isLoading, data, isSuccess,  isError, refetch } = useQuery(
    key ?? "get-meters",
    () =>
      axios.post(
        `https://api-pm-e1ea777feac4.herokuapp.com/user/disco?query=${query}`,
        {},
        {
          headers: {
            Authorization: authUser().authToken,
          },
        }
      )
  );
  return { data: data?.data, isLoading, isSuccess, isError, refetch };
}
