import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { TbCurrencyNaira } from "react-icons/tb";
import Text from "../../../text";
import Input from "../../../input";
import TextArea from "../../../textarea";
import Button from "../../../button";

export default function UpdateOverdraft({ data }) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const formValues = {
    overdraft: data.wallet.overdraft,
    reason: ""
  };

  const validationSchema = Yup.object({
    overdraft: Yup.number()
      .required("Overdraft is required").max(data.wallet.overdraft, `Overdraft must be less than or equal to ${data.wallet.overdraft}`),
    reason: Yup.string().required("Reason is required"),
  });

  const onSubmit = (values) => {

    console.log(values)
    const userid = data.userid
    setIsLoading(true)
    setValidationAttempt(false);
    axios.post("https://pm-server.herokuapp.com/api/v2.0/manager/set-user-charges?query=remove-overdraft", {
      ...values,
      userid: userid
    }, {
      headers: {
        Authorization: authUser().authToken,
      },
    }).then(res => {
      console.log(res)
      setIsLoading(false)
      navigate('/manager/configure/user-management')
      toast.success(res.data.message)
    }).catch(err => {
      console.log(err)
      setIsLoading(false)
      toast.error(err.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <Text
          as="h3"
          weight="bold"
          classname="text-[24px] lg:text-[30px] text-center"
        >
          Update Overdraft
        </Text>
        <div className="my-6 flex flex-col space-y-6">
          <Input
            iconLeft={<TbCurrencyNaira />}
            label="Overdraft:"
            placeholder={data.wallet.overdraft}
            type="number"
            name="overdraft"
            defaultValue={data.wallet.overdraft}
            value={formik.values.overdraft}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.overdraft}
          />
          <TextArea
            label='Reason:'
            placeholder='Enter reason'
            inputClass='rounded-[16px] bg-[#F5F5FE]'
            name='reason'
            value={formik.values.reason}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.reason}
          />
        </div>
        <Button isLoading={isLoading}
          onclick={() => setValidationAttempt(true)}
          buttonType="submit" classname="w-full">
          Update
        </Button>
      </div>
    </form>
  );
}
