import React from "react";
import Text from "../../text";
import Button from "../../button";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router";
import useAdminAffiliates from "../../../store/admin/affiliates";
import { toast } from "react-toastify";
export default function RemoveRegularCharges({ onButtonClick, data }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const { selectedAffiliate } = useAdminAffiliates();
  const naviate = useNavigate();
  const authUser = useAuthUser();
  const onSubmit = () => {
    setIsLoading(true);
    // console.log(data)
    axios.put("https://pm-server.herokuapp.com/api/v2.0/charges-proxy?query=delete", {
      insight_slug: data.insight_slug,
      charges_slug: data.charges_slug,
      type: data.type,
      tag: selectedAffiliate,
    }, {
      headers: {
        Authorization: authUser().authToken,
      },
    }).then(res => {
      // console.log(res)
      setIsLoading(false)
      naviate('/manager/configure/charges')
      toast.success(res.data.message)
    }).catch(err => {
      // console.log(err)
      setIsLoading(false)
      toast.error(err.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  };

  return (
    <div>
      <Text
        as="h3"
        weight="bold"
        classname="text-[24px] lg:text-[30px] text-center"
      >
        Remove Charges
      </Text>
      <div className="my-6 flex flex-col space-y-6">
        <Text as="p" weight="medium" classname=" text-secondary-dark">
          Are you sure you want to remove this charges {data?.name}?
        </Text>
      </div>
      <Button onclick={onSubmit} isLoading={isLoading} classname="w-full">
        Remove
      </Button>
    </div>

  );
}
