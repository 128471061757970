import Text from "../../../components/text";

export const request_data = Array.from({ length: 10 }).map((data, i) => ({
  _id: i + 1,
  title: "Prepaid Energy",
  type: "Single Phase",
  quantity: i + 1,
  date_created: "Oct 05, 2022, 18:30",
  status: "Success",
  proposed_date: "Oct 05, 2022, 18:30",
}));

export const request_column = [
  {
    id: "1",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Title
      </Text>
    ),
    //     Cell: TableIcon,
    accessor: "title",
  },
  {
    id: "2",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Type
      </Text>
    ),
    accessor: "type",
  },
  {
    id: "3",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Quantity
      </Text>
    ),
    accessor: "quantity",
  },
  {
    id: "4",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Date Created
      </Text>
    ),
    accessor: "date_created",
  },
  {
    id: "5",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Status
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className="flex items-center space-x-4">
        <Text as="p" classname="text-left text-[12px] border-[1px] border-[#00DA16] text-[#00DA16] bg-[#DAF8DD] rounded-[4px] px-3">
          {value}
        </Text>
      </div>
    ),
    accessor: "status",
  },
  {
    id: "6",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Proposed Date
      </Text>
    ),
    accessor: "proposed_date",
  },
  {
    id: "7",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Actions
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className="flex items-center space-x-4">
        <Text as="p" classname="text-left text-[16px] text-secondary-dark">
          View
        </Text>
      </div>
    ),
    accessor: "_id",
  },
];
