import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { signUpOptions, staticMenu } from "../constants/static-menu";
import { clx } from "../utils/clx";
import Button from "./button";
import Text from "./text";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

export default function StaticHeader({
  classname,
  containerClass = "w-full shadow-sm",
  children,
}) {
  const router = useLocation();
  const classes = clx(
    "w-[93%] lg:w-[90%] mx-auto flex items-center justify-between py-8",
    classname
  );

  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [signUpOptionsOpen, setSignUpOptionsOpen] = useState(false);

  const toggleSubmenu = (index) => {
    if (openSubmenu === index) {
      setOpenSubmenu(null);
    } else {
      setOpenSubmenu(index);
    }
  };

  const handleMouseEnter = (index) => {
    setOpenSubmenu(index);
  };

  const handleMouseLeave = () => {
    setOpenSubmenu(null);
  };

  return (
    <div className={containerClass}>
      <div className={classes}>
        <Link to="/">
          <img src="/assets/images/logo2.png" alt="" width="200px" />
        </Link>
        <div className="flex items-center space-x-3 lg:space-x-8">
          {staticMenu.map((link, index) => (
            <div key={index} className="relative">
              <div className="flex items-center space-x-2">
                <Text
                  href={link.route}
                  as="span"
                  weight={
                    router.pathname === link.route ? "semibold" : "normal"
                  }
                  onclick={() => toggleSubmenu(index)}
                  classname={`${
                    router.pathname === link.route &&
                    "lg:text-[18px] text-sm text-transparent bg-clip-text bg-gradient-to-l from-primary-dark via-primary-light to-secondary-dark "
                  } cursor-pointer `}
                >
                  {link.label}
                </Text>
                {link.subroutes && (
                  <div
                    onClick={() => toggleSubmenu(index)}
                    className="cursor-pointer"
                    onMouseEnter={() => handleMouseEnter(index)}
                  >
                    {openSubmenu === index ? (
                      <IoMdArrowDropup className="h-5 w-5" />
                    ) : (
                      <IoMdArrowDropdown className="h-5 w-5" />
                    )}
                  </div>
                )}
              </div>
              {link.subroutes && openSubmenu === index && (
                <div
                  className="absolute w-[200px] left-0 mt-2 rounded-lg z-10 bg-white shadow-md"
                  onMouseLeave={() => handleMouseLeave()}
                >
                  {link.subroutes.map((sublink) => (
                    <Text
                      as="p"
                      key={sublink.id}
                      onclick={() => {
                        window.location.href = sublink.route;
                      }}
                      classname="px-4 py-2 rounded-lg hover:bg-gray-100 cursor-pointer"
                    >
                      {sublink.label}
                    </Text>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex items-center space-x-4">
          <Button
            variant="outlined"
            size="small"
            href="/login"
            classname="md:text-sm lg:text-xl"
          >
            Sign in
          </Button>
          <div className="relative">
            <Button
              variant="contained"
              size="small"
              onclick={() => setSignUpOptionsOpen((prev) => !prev)}
            >
              Sign up
            </Button>
            {signUpOptions && signUpOptionsOpen && (
              <div
                className="absolute w-[200px] right-0 mt-2 bg-primary-dark rounded-lg shadow-md"
                onMouseLeave={() => setSignUpOptionsOpen((prev) => !prev)}
              >
                {signUpOptions.map((sublink) => (
                  <Text
                    as="p"
                    key={sublink.id}
                    onclick={() => {
                      window.location.href = sublink.route;
                    }}
                    classname="px-4 py-2 text-white hover:bg-primary-light rounded-lg cursor-pointer"
                  >
                    {sublink.label}
                  </Text>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
