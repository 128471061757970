import CurrencyFormat from "react-currency-format";
import Text from "../../../components/text";

export const wallet_data = Array.from({ length: 40 }).map((data, i) => ({
  _id: i + 1,
  amount: 56000,
  token: "626668777",
  unit: "1.1",
  status: "success",
  date_created: "Oct 05, 2022, 18:30",
  reference_id: `5232464252${i}`,
}));

export const wallet_column = [
  {
    id: "1",
    Header: "",
    Cell: ({ row, value }) => (
      <div className='w-[40px] h-[40px] rounded-full bg-primary-dark'></div>
    ),
    accessor: "_id",
  },
  {
    id: "2",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Reference ID
      </Text>
    ),
    //     Cell: TableIcon,
    accessor: "reference_id",
  },
  {
    id: "3",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Amount
      </Text>
    ),
    Cell: ({ row, value }) => (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        <CurrencyFormat
          value={value}
          displayType="text"
          decimalScale={2}
          renderText={(value) => <Text as="span">₦{value}</Text>}
          thousandSeparator
          fixedDecimalScale
        />
      </Text>
    ),
    accessor: "amount",
  },
  {
    id: "4",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Status
      </Text>
    ),
    Cell: ({ row, value }) => (
      <Text as='p' classname='text-left text-[16px] text-green-600'>
        {value}
      </Text>
    ),
    accessor: "status",
  },
  {
    id: "5",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Date Created
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className='flex items-center space-x-4'>
        <Text as='p' classname='text-left text-[16px]'>
          {value}
        </Text>
      </div>
    ),
    accessor: "date_created",
  },
];
