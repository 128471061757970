import React from "react";
import Text from "../../../components/text";
import ActivityCard from "../../../components/activity-card";
import { io } from "socket.io-client";
import useAdminAffiliateInfo from "../../../hooks/apis/admin/useAdminAffiliateInfo";
import useAdminUser from "../../../store/admin/admin-user";
import useAdminUserInfo from "../../../hooks/apis/admin/useAdminUserInfo";
import useAdminAffiliates from "../../../store/admin/affiliates";
import useAdminActivities from "../../../store/admin/activities";

export default function DashboardActivities() {
  //   const [activities, setActivities] = React.useState([]);
  const { activities, setActivities } = useAdminActivities();

  // React.useEffect(() => {
  //   const socket = new io("https://pm-server.herokuapp.com/api/v2.0/socket");

  //   socket.on("connect", () => {
  //     //   console.log(socket.connected);
  //   });
  //   socket.on("connection", () => {});
  //   socket.on("connect_error", () => {
  //     console.log("Not Connected");
  //   });
  //   socket.on("recentActivities", (activity) => {
  //     if (activity) {
  //       let incomingActivity = activity
  //       console.log("actEvent", incomingActivity);
  //       if (activities.length === 0) {
  //         setActivities([activity.data]);
  //       } else if (
  //         activities.some((data) => data.activity === activity.data.activity)
  //       ) {
  //         setActivities([...activities]);
  //       } else {
  //         setActivities([...activities, activity.data]);
  //       }
  //     }
  //     console.log(activities);
  //   });
  //   return () => {
  //     socket.off("connect");
  //     socket.off("recentActivities");
  //   };
  // });
  return (
    <div className="w-full min-h-[300px] h-fit rounded-[8px] bg-white p-4">
      <Text as="h5" weight="bold" classname="text-[20px] text-primary-dark">
        Activities
      </Text>
      <div className="flex flex-col space-y-4">
        {activities?.map((activity, index) => (
          <ActivityCard key={index} iconBg="bg-[#9799A5]" />
        ))}
      </div>
    </div>
  );
}
