import React from "react";
import { Route } from "react-router-dom";
import LoginPage from "../pages/auth/login";
import Homepage from "../pages/static-pages/homepage";
import AboutPage from "../pages/static-pages/aboutpage";
import ContactPage from "../pages/static-pages/contact-page";
import BuyElectricityPage from "../pages/static-pages/buy-electricity";
import MarketPlacePage from "../pages/static-pages/market-place-page";
import CategoryPage from "../pages/static-pages/category-page";
import ItemDetailsPage from "../pages/static-pages/item-details-page";
import CartPage from "../pages/static-pages/cart-page";
import CheckoutPage from "../pages/static-pages/checkout-page";
import CorporatePage from "../pages/static-pages/corporate";
import DownloadPage from "../pages/static-pages/DownloadPage";
import Blogpage from "../pages/static-pages/blogpage";
import SingleBlogpage from "../pages/static-pages/SingleBlogPage";

import CreateBlogpage from "../pages/static-pages/CreateBlogPage";
import BuyMeterPage from "../pages/static-pages/buy-meter-page";
import ReferrerPage from "../pages/static-pages/referrerPage";
import ReferrerSignupPage from "../pages/static-pages/refererSignupPage";
import DemoPage from "../pages/static-pages/demo-page";


export default function GetStaticRoutes() {
  return (
    <React.Fragment>
      <Route path="/">
        <Route index element={<Homepage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="download" element={<DownloadPage />} />
        <Route path="demo" element={<DemoPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="corporate" element={<CorporatePage />} />
        <Route path="createblog" element={<CreateBlogpage/>} />
        <Route path="referral" element={<ReferrerPage/>} />
        <Route path="referer_signup" element={<ReferrerSignupPage/>} />
        <Route path="blog" >
           <Route index element={<Blogpage />}/>
           <Route path=':id' element={<SingleBlogpage/>}/>
  </Route>
        {/* <Route path="services" element={<ServicesPage />} /> */}
        <Route
          path="buy-electricity/:meterId"
          element={<BuyElectricityPage />}
        />
        <Route
          path="buy-meter"
          element={<BuyMeterPage />}
        />
        <Route path="login" element={<LoginPage />} />
        <Route path="marketplace">
          <Route index element={<MarketPlacePage />} />
          <Route path=":category" element={<CategoryPage />} />
          <Route path=":category/:id" element={<ItemDetailsPage />} />
        </Route>
        <Route path="cart" element={<CartPage />} />
        <Route path="checkout" element={<CheckoutPage />} />
      </Route>
    </React.Fragment>
  );
}
