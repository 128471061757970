import React, { useEffect } from "react";
import { clx } from "../../../../utils/clx";
import SetChargesTab from "./tabs";
import PeriodicCharges from "./periodic";
import RegularCharges from "./regular";
import UseFetchEndpoint from "../../../../hooks/apis/useFetch";
import useAdminAffiliates from "../../../../store/admin/affiliates";
import useAdminUserInfo from "../../../../hooks/apis/admin/useAdminUserInfo";
import useAdminRank from "../../../../store/admin/adminRank";

export default function SetChargesWrapper() {
    const { selectedAffiliate } = useAdminAffiliates();
    const [periodicCharges, setPeriodicCharges] = React.useState([])
    const [regularCharges, setRegularCharges] = React.useState([])
    const [customCharges, setCustomCharges] = React.useState([])
    const { adminRank } = useAdminRank()
    // console.log(adminRank)
    const {
        data,
        isLoading,
        isFetching,
        isError,
        refetch
    } = UseFetchEndpoint({ method: "get", endpoint: `/${selectedAffiliate}/property?prop=charges`, key: 'charges-props' })
    useEffect(() => {
        refetch()

    }, [])

    useEffect(() => {
        if (data) {
            setPeriodicCharges(data.charges_insights.periodic)
            setRegularCharges(data.charges_insights.regular)
            setCustomCharges(data.charges_insights.custom)
        }
    }, [data])
    const [activeTab, setActiveTab] = React.useState(1);
    const classes = clx(
        "w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex flex-col"
    );
    return (
        <div className={classes}>
            <SetChargesTab activeTab={activeTab} setTab={setActiveTab} />
            <div>
                {activeTab === 1 && <PeriodicCharges data={periodicCharges} loading={isLoading}
                    isFetching={isFetching}
                    isError={isError}

                />}
                {adminRank > 1 && activeTab === 2 && <RegularCharges data={regularCharges} loading={isLoading} />}
                {/* {activeTab === 3 && <div>Custom Charges</div>} */}
            </div>
        </div>
    );
}