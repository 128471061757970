import React from "react";
import Table from "../../../components/table";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";
import {
  customer_charges_column,
  customer_column,
  customer_data,
} from "../../../utils/table-utils/admin/customer-table";
import useAdminUserList from "../../../hooks/apis/admin/useAdminUserList";

export default function CustomersCharges({ classname, children }) {
  const { data: UserList, isFetching, isLoading } = useAdminUserList();

  // console.log(UserList)
  const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
  return (
    <div className={classes}>
      <Text as="p" weight="bold" classname="text-[28px]">
        Customers
      </Text>

      <div className="my-[40px] bg-white rounded-[16px] p-6">
        <Table
          data={UserList?.data?.userList ?? []}
          columns={customer_charges_column}
          isLoading={isLoading || isFetching}
        />
      </div>
    </div>
  );
}
