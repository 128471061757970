import axios from "axios";
import React from "react";
import { useAuthUser } from "react-auth-kit";
import { useQuery } from "react-query";

const useDemoRequst = (type) => {
  const authUser = useAuthUser();

  const { isLoading, data, isError, isFetching, refetch } = useQuery(
    "demo-request"+type ,
    () =>
      axios.get(
        "https://api-pm-e1ea777feac4.herokuapp.com/guest",
        {
          params:{type:type},
          headers: { Authorization: authUser().authToken }
        }
      ),
    {
      enabled: true,
      refetchOnWindowFocus: 'always',
      refetchOnMount: true,
      refetchOnReconnect: true,
      staleTime:0
    }
  );

  return { isLoading, data, isError, isFetching, refetch };
};

export default useDemoRequst;
