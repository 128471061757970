import axios from "axios";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/button";
import OTP from "../../components/otp";
import Text from "../../components/text";
import AuthLayout from "../../layouts/auth-layout";
import useTimer from "../../hooks/useTimer";
import useUserRegistration from "../../store/user/registration";

export default function VerificationPageWrapper() {
  const [otp, getOTP] = React.useState();
  const [searchParams] = useSearchParams();
  const authDataString = decodeURIComponent(searchParams.get("verify-data"));
  const authData = JSON.parse(authDataString);
  const auth_email = searchParams.get("auth-email");
  const navigate = useNavigate();
  const { timer, resetTime, isComplete } = useTimer(60);

  // console.log(authData)

  const sendOTP = (otp, action) => {
    if (otp.length === 4) {
      axios
        .get(
          `https://api-pm-e1ea777feac4.herokuapp.com/otp/user-registration/${auth_email}/${otp}`
        )
        .then((res) => {
          toast.success("Account Created Successfully!");
          navigate("/login");
        })
        .catch((err) => {
          toast.error(err?.response.data.message);
        });
    }
  };

  const ResendOTP = () => {
    // console.log(authInfo)
    const keysToFilter = ["apartment", "confirmPassword"];
    const filteredObject = { ...authData };
    for (const key of keysToFilter) {
      delete filteredObject[key];
    }
    axios
      .post("https://api-pm-e1ea777feac4.herokuapp.com/user/register", {
        ...filteredObject,
      })
      .catch((err) => {
        // console.log(err)
        toast.error(err.response.data.message);
      });
  };

  React.useEffect(() => {
    if (!auth_email) {
      navigate("/auth-token");
    }
  });
  return (
    <AuthLayout pageTitle="Code Verification" usePageNavigation>
      <div className="w-full flex flex-col items-center justify-center py-[30px] lg:py-0">
        <div className="lg:hidden">
          <div>
            <img src="/assets/icons/verified-icon.png" alt="verified-icon" />
          </div>
        </div>
        <div className="py-[30px] lg:pt-0">
          <Text
            as="p"
            classname="font-[600] text-[28px] lg:text-[54px] text-primary-dark text-center"
          >
            Enter Verification Code
          </Text>
          <Text
            as="p"
            weight="medium"
            classname="text-[14px] lg:text-[20px] text-primary-dark text-center"
          >
            A code has been sent to {auth_email}
          </Text>
        </div>
        <div className="lg:bg-[#F8F8FF] lg:border-[1px] rounded-[16px] w-full lg:w-[550px] py-[0px] lg:py-[50px] px-[20px] lg:px-[70px]">
          <div className="flex flex-col items-center space-y-6">
            <div className="mb-[30px]">
              <OTP getOTP={getOTP} />
            </div>
            <Button classname="w-full" onclick={() => sendOTP(otp, "register")}>
              Verify
            </Button>
            <Text as="p" classname="text-[14px] lg:text-center">
              Didn’t receive a code?{" "}
              <span className="text-secondary-dark">
                {isComplete ? (
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      resetTime();
                      ResendOTP();
                    }}
                  >
                    Resend
                  </span>
                ) : (
                  `${String(timer.minutes).padStart(2, "0")}:${String(
                    timer.seconds
                  ).padStart(2, "0")}`
                )}
              </span>
            </Text>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}