import { AiFillSetting } from "react-icons/ai";
import { BsFillChatDotsFill } from "react-icons/bs";
import { FaQuestionCircle, FaUserAlt,FaBloggerB } from "react-icons/fa";
import { GiFuelTank } from "react-icons/gi";
import { IoWallet, IoWaterSharp } from "react-icons/io5";
import { RiServiceFill } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { SlEnergy } from "react-icons/sl";
import { MdShowChart } from "react-icons/md";
import { CgFeed } from "react-icons/cg";



export const adminMenu = [
  {
    id: 1,
    label: "Dashboard",
    icon: <RxDashboard className="text-[20px] lg:text-[24px]" />,
    pageTitle: "Overview",
    route: "/manager",
  },
  // {
  //   id: 2,
  //   label: "Wallet",
  //   icon: <IoWallet className="text-[20px] lg:text-[24px]" />,
  //   pageTitle: "Wallet",
  //   route: "/manager/wallet",
  // },
  {
    id: 3,
    label: "Services",
    icon: <RiServiceFill className="text-[20px] lg:text-[24px]" />,
    pageTitle: "Services",
    route: "",
    subroutes: [
      {
        id: 1,
        label: "Prepaid Energy",
        icon: <SlEnergy className="text-[20px] lg:text-[24px]" />,
        pageTitle: "Prepaid Energy",
        route: "/manager/energy",
      },
      {
        id: 2,
        label: "Prepaid Water",
        icon: <IoWaterSharp className="text-[20px] lg:text-[24px]" />,
        pageTitle: "Prepaid Water",
        route: "/manager/water",
      },
      {
        id: 1,
        label: "Fuel Monitory",
        icon: <GiFuelTank className="text-[20px] lg:text-[24px]" />,
        pageTitle: "Fuel Monitory",
        route: "/manager/fuel",
      },
    ],
  },
  {
    id: 4,
    label: "Charges",
    icon: <MdShowChart className="text-[20px] lg:text-[24px]" />,
    pageTitle: "Services",
    route: "",
    subroutes: [
      {
        id: 1,
        label: "Periodic",
        icon: <CgFeed className="text-[20px] lg:text-[24px]" />,
        pageTitle: "Prepaid Energy",
        route: "/manager/charges",
      }
    ],
  },
  {
    id: 6,
    label: "Configure",
    icon: <AiFillSetting className="text-[20px] lg:text-[24px]" />,
    pageTitle: "Configuration",
    route: "/manager/configure",
  },
  {
    id: 7,
    label: "Chat",
    icon: <BsFillChatDotsFill className="text-[20px] lg:text-[24px]" />,
    pageTitle: "Chat",
    route: "/manager/chat",
  },
  // {
  //   id: 8,
  //   label: "Create Blog",
  //   icon: <FaBloggerB className="text-[20px] lg:text-[24px]" />,
  //   pageTitle: "Blog",
  //   route: "/manager/blog",
  // },
  // {
  //   id: 8,
  //   label: "Request",
  //   icon: <FaQuestionCircle className="text-[20px] lg:text-[24px]" />,
  //   pageTitle: "FAQs",
  //   route: "/manager/request",
  // },
  // {
  //   id: 9,
  //   label: "Settings",
  //   icon: <AiFillSetting className="text-[20px] lg:text-[24px]" />,
  //   pageTitle: "Chat",
  //   route: "/admin/settings",
  // },
];
