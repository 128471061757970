import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { AiOutlineBarcode } from "react-icons/ai";
import { FaEnvelope } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import Button from "../../components/button";
import Input from "../../components/input";
import Text from "../../components/text";
import AuthLayout from "../../layouts/auth-layout";
import { clx } from "../../utils/clx";

export default function EmailAuthPageWrapper() {
  return (
    <AuthLayout pageTitle='Email Address' usePageNavigation>
      <div className='py-[15px] lg:py-0'>
        <div className='lg:py-6'>
          <Text
            as='h1'
            classname='hidden lg:block font-[600] text-[54px] text-primary-dark text-center'
          >
            Email Address
          </Text>
          <Text as='p' classname='text-[14px] lg:text-[20px] lg:text-center'>
            Enter your email address to gain access
          </Text>
        </div>

        <div className='lg:bg-[#F8F8FF] lg:border-[1px] rounded-[16px] w-full lg:w-[550px] py-[20px] lg:py-[50px] px-[0px] lg:px-[70px]'>
          <EmailAuthPageWrapper.Form />
        </div>
      </div>
    </AuthLayout>
  );
}

export function Form({}) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);

  const formValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Please enter your email"),
  });

  const onSubmit = (values) => {
    setValidationAttempt(false);
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='w-full mx-auto'>
        <Input
          type='email'
          iconLeft={<FaEnvelope className='text-[#9799A5] text-[20px]' />}
          placeholder='example@gmail.com'
          name='email'
          onchange={formik.handleChange}
          onblur={formik.handleBlur}
          errorMessage={formik.errors.email}
          value={formik.values.email}
        />
        <Button
          buttonType='submit'
          classname='w-full mt-[50px]'
          onclick={() => setValidationAttempt(true)}
        >
          Submit
        </Button>
      </div>
    </form>
  );
}

EmailAuthPageWrapper.Form = Form;
