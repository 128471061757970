import Button from "../../../components/button";
import Text from "../../../components/text";
import { MdArrowForwardIos } from "react-icons/md";
import ProductCard from "./product-card";
import { ProductItems } from "../../../utils/items";
import Slider from "react-slick";

export default function MayAlsoLike({ title = "Weekly Discount" }) {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    speed: 500,
    slidesToShow: 4, // Number of items to show at once
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1.8,
        },
      },
    ],
  };
  return (
    <div className="rounded-md bg-none  md:bg-[#F8F8F8] p-0 sm:p-3 lg:p-9 w-full ">
      <div className="flex flex-row justify-between items-center">
        <Text as="h1" weight="bold" classname="lg:text-3xl text-center">
          {title}
        </Text>
        <Button
          variant="small"
          type="tertiary"
          classname="mt-6"
          iconRight={<MdArrowForwardIos className="text-gray-400" />}
        >
          View All
        </Button>
      </div>
      <div className="flex flex-col gap-6 mt-8">
        <Slider {...settings}>
          {ProductItems.map((item, index) => (
            <div key={index} className="px-2">
              <ProductCard item={item} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
