import React from "react";
import Text from "../../components/text";
import Input from "../../components/input";
import Select from "../../components/select";
import { Country, sheduleProperty } from "../../data";
import Button from "../../components/button";
import { IoMdArrowRoundForward } from "react-icons/io";
import TextArea from "../../components/textarea";
import { useFormik } from "formik";
import Picker from "../../components/Picker";
import * as Yup from "yup";
import { validationSchemaDemo } from "./buy-meter/buy-meter-validation";
import { toast } from "react-toastify";
import axios from "axios";
import { IoHappyOutline } from "react-icons/io5";

const ScheduleDemo = ({ setIsModalOpen }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    community: "",
    totalProperty: "",
    address: "",
    country: "",
  };

  const onSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const res = await axios.post(
        "https://api-pm-e1ea777feac4.herokuapp.com/guest/book-demo",
        values
      );
      console.log(res);
      setIsLoading(false);
      resetForm();
      handleReset();
      setIsModalOpen(false);
      // navigate("/");
      toast.success(res.data.message);
      setIsSuccess(true);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      // Handle error and show appropriate message
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };
  const {
    values,
    handleBlur,
    handleSubmit,
    handleReset,
    handleChange,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: validationSchemaDemo,
    onSubmit,
  });

  return (
    <section
      className={
        setIsModalOpen
          ? "max-h-96 overflow-y-auto"
          : "flex py-[88px] flex-col gap-6 rounded-lg mx-auto p-[50px] md:px-[100px] w-full max-w-[787px] bg-[#f8f8f8] shadow-sm"
      }
      onClick={({ target, currentTarget }) =>
      target === currentTarget && setIsModalOpen(false)
    }
    >
      <div>
        <Text as="h2" weight="bold" classname="pt-1 mb-3 text-md md:text-4xl">
          Schedule a Demo
        </Text>
        <Text as="p">
          Please fill out the form below to provide basic information about your
          community
        </Text>
        <form onSubmit={handleSubmit} className="mt-3 space-y-1">
          <Input
            type="text"
            label="First Name *"
            name="firstname"
            // defaultValue={JSON.parse(getSchedule).FirstName}
            value={values.firstname}
            onchange={handleChange}
            onblur={handleBlur}
            errorMessage={touched.firstname && errors.firstname}
            placeholder="First Name"
          />
          <Input
            type="text"
            label="Last Name *"
            name="lastname"
            // defaultValue={JSON.parse(getSchedule).lastName}
            value={values.lastname}
            onchange={handleChange}
            onblur={handleBlur}
            errorMessage={touched.lastname && errors.lastname}
            placeholder="last Name"
          />
          <Input
            type="email"
            label="Email *"
            name="email"
            // defaultValue={JSON.parse(getSchedule).lastName}
            value={values.email}
            onchange={handleChange}
            onblur={handleBlur}
            errorMessage={touched.email && errors.email}
            placeholder="Email"
          />
          <Input
            type="text"
            label="Community name *"
            name="community"
            // defaultValue={JSON.parse(getSchedule).communityName}
            value={values.community}
            onchange={handleChange}
            onblur={handleBlur}
            errorMessage={touched.community && errors.community}
            placeholder="Community Name"
          />
          <Input
            placeholder={"Number of Community"}
            type="number"
            // content={sheduleProperty}
            name={"totalProperty"}
            value={values.totalProperty}
            onchange={handleChange}
            onblur={handleBlur}
            errorMessage={touched.totalProperty && errors.totalProperty}
            label="Number of properties in your community *"
          />
          <TextArea
            type="text"
            label="Address *"
            placeholder="Street Address"
            name="address"
            value={values.address}
            onchange={handleChange}
            onblur={handleBlur}
            errorMessage={touched.address && errors.address}
          />
          <Input
            placeholder={"Name of Country"}
            // content={Country}
            name={"country"}
            value={values.country}
            onchange={handleChange}
            onblur={handleBlur}
            errorMessage={touched.country && errors.country}
            label="Country"
            inputClasses="mb-2"
          />

          <Button
            buttonType="submit"
            disabled={""}
            isLoading={isLoading}
            classname="w-full mt-6"
            iconLeft={<IoMdArrowRoundForward />}
          >
            Submit
          </Button>
        </form>
      </div>
    </section>
  );
};

export default ScheduleDemo;
