import React from "react";
import Text from "../../../components/text";
import ManagerPassword from "./components/admin-manager-password-action";
import AdminManagerActions from "./components/admin-manager-actions";
import Checkbox from "../../../components/check-box";

export const restrict_managers_data = Array.from({ length: 10 }).map(
  (data, i) => ({
    _id: i + 1,
    name: `Samuel Lasisi-${i + 1}`,
    pic: "/assets/images/thumbnail.png",
    email: "userexample@gmail.com",
    role: "Tenant Administrator",
    company: "Company Name",
  })
);

export const restrict_managers_column = [
  {
    id: 'managerid',
    // The header can use the table's getToggleAllRowsSelectedProps method
    // to render a checkbox
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <div className="flex flex-row gap-3">
        {/* <Checkbox {...getToggleAllRowsSelectedProps()} /> */}
        <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
          Select
        </Text>
      </div>
    ),
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    Cell: ({ row }) => (
      <div>
        <Checkbox
          {...row.getToggleRowSelectedProps()} />
      </div>
    ),
  },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Name
      </Text>
    ),
    accessor: "fullname",
    Cell: ({ value, row }) => (
      <div className='flex items-center space-x-4'>
        {/* <div className='w-[40px] h-[40px] rounded-full bg-red-500'>
          <img
            src='/assets/images/thumbnail.png'
            alt=''
            className='object-fill'
          />
        </div> */}
        <Text as='p' classname='text-left text-[16px]'>
          {value}
        </Text>
      </div>
    ),
  },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Email
      </Text>
    ),
    accessor: "email",
  },
  // {
  //   //     id: "_",
  //   Header: (
  //     <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
  //       Role
  //     </Text>
  //   ),
  //   Cell: ({ value, row }) => (
  //     <Text as='p' classname='text-left text-[16px] capitalize'>
  //       {value}
  //     </Text>
  //   ),
  //   accessor: "role",
  // },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Restriction
      </Text>
    ),
    Cell: ({ value, row }) => (
      <Text as='p' classname='text-left text-[16px] uppercase text-secondary-dark'>
        {value}
      </Text>
    ),
    accessor: "restriction",
  },
  // {
  //   //     id: "_",
  //   Header: (
  //     <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
  //       Password
  //     </Text>
  //   ),
  //   Cell: ManagerPassword,
  //   accessor: "password",
  // },
  // {

  //   id: "2",
  //   Header: (
  //     <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
  //       Actions
  //     </Text>
  //   ),
  //   accessor: "_id",
  //   Cell: AdminManagerActions

  // },
];
