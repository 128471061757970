import React from "react";
import StaticLayout from "../../layouts/static-layout";
import Referrer from "../../views/static/referrer";
export default function  ReferrerPage() {
      return (
            <React.Fragment>
                  <StaticLayout>
                        <Referrer/>
                  </StaticLayout>
            </React.Fragment>
      )
}