import React from "react";
import Text from "../../../../../components/text";
import { clx } from "../../../../../utils/clx";
import Button from "../../../../../components/button";
import { IoAddOutline } from "react-icons/io5";
import { GrStatusDisabled } from "react-icons/gr";
import { RxSwitch } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import ModalWrapper from "../../../../../components/modals/modal-wrapper";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import useAdminAffiliates from "../../../../../store/admin/affiliates";
import { toast } from "react-toastify";

export default function RegularCharges({ data }) {
  const [modalType, setModalType] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false)
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [payload, setPayload] = React.useState({});
  const [chargeSlug, setChargeSlug] = React.useState()
  const classes = clx("w-[93%] lg:w-[90%] lg:py-[40px]");
  const authUser = useAuthUser();
  const { selectedAffiliate } = useAdminAffiliates();
  const handleButtonClick = (type) => {
    const paylaods = {
      activate: {
        tag: selectedAffiliate,
        type: data.slug,
      },
      deactivate: {
        tag: selectedAffiliate,
        type: data.slug,
      },
      "charges-opt-out": {
        tag: selectedAffiliate,
        type: data.slug,
      },
      "charges-opt-in": {
        tag: selectedAffiliate,
        type: data.slug,
      }
    }[type]
    const types = {
      activate: false,
      deactivate: true,
      "charges-opt-out": true,
      "charges-opt-in": false,
    }[type]
    if (type === 'charges-opt-in' || type === 'charges-opt-out') {
      const result = data.status === types
      if (!result) {
        return toast.error(`No ${type === 'charges-opt-in' ? 'Inactive' : 'Active'} Charges`)
      }
    }
    else if (type === 'activate' || type === 'deactivate') {
      const result = data.charges.some((item) => item.active === types)
      if (!result) {
        return toast.error(`No ${type === 'activate' ? 'Inactive' : 'Active'} Charges`)
      }
    }
    setModalType(type)
    setModalOpen(true)
    setPayload(paylaods)
  };
  const handleSubmitClick = (e) => {
    e.preventDefault()
    setIsLoading(true)
    axios.put(`https://pm-server.herokuapp.com/api/v2.0/charges-proxy?query=${modalType}`, {
      ...payload,
      charges_slug: chargeSlug
    }, {
      headers: {
        Authorization: authUser().token
      }
    }).then(res => {
      toast.success(res.data.message)
      setIsLoading(false)
      navigate('/manager/configure')
    }).catch(err => {
      toast.error(err.response.data.message)
      setIsLoading(false)
    }).finally(() => {
      setModalOpen(false)
      setIsLoading(false)
      setChargeSlug('')
    })
  }

  return (
    <React.Fragment>
      {data.status === true ? (
        <div className={classes}>
          <div className="flex justify-between items-center">
            <Button
              variant='text'
              iconLeft={<RxSwitch />}
              onclick={() => {
                handleButtonClick("charges-opt-out");
              }}

              classname=' w-fit text-secondary-dark'
            >
              Switch off {data.slug} charges
            </Button>

            <div className="flex  gap-4 p-3">
              <Button
                variant='text'
                iconLeft={<IoAddOutline />}
                onclick={() => {
                  handleButtonClick("activate");
                }}

                classname=' w-fit text-secondary-dark'
              >
                Activate a charge
              </Button>
              <Button
                variant='text'
                iconLeft={<GrStatusDisabled />}
                onclick={() => {
                  handleButtonClick("deactivate");
                }}
                classname=' w-fit text-secondary-dark'
              >
                Deactivate a charge
              </Button>
            </div>

          </div>

          <Text as='h2' weight="semi-bold" classname='text-primary-dark my-6 text-[18px]'>
            {data.desc}...
          </Text>
          <div>
            {data.charges.some((item) => item.active === true) ? (
              <div className="grid lg:grid-cols-4 md:grid-cols-3 gap-[40px] items-center">
                {data.charges.map((item, index) => {
                  if (item.active === true) {
                    return (
                      <div key={index} className="flex flex-col items-center justify-center w-[200px] bg-[#E6E6E9] rounded-md h-[200px] gap-3">
                        <Text as="h3" classname="font-bold capitalize">
                          {item.name}
                        </Text>
                        <Text as="p" classname="text-secondary-dark mt-3 font-bold">
                          {item.slug === "naira" ? "₦" : "%"}
                          {item?.total}
                        </Text>
                        <Link
                          to={`/manager/configure/regular-charges/${data.slug}/${item.slug}`}
                          className="text-white font-bold px-4 py-2 rounded-md bg-primary-dark hover:bg-primary-light transition-all duration-200 ease-in-out"
                          state={{
                            data: item,
                            type: data.slug
                          }}
                        >
                          View Insight
                        </Link>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            ) : (
              <div className="flex my-auto items-center justify-center">
                <Text as="h3" weight="bold" classname="text-center text-primary-dark">
                  No active charges, activate a charge to view insights
                </Text>
              </div>
            )}
          </div>

        </div>

      ) : (
        <div className={classes}>
          <Button
            variant='text'
            iconLeft={<RxSwitch />}
            onclick={() => {
              handleButtonClick("charges-opt-in");
            }}

            classname=' w-fit text-secondary-dark'
          >
            Switch on periodic charges
          </Button>
        </div>
      )
      }

      {/* activate charge modal */}
      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => setModalOpen(false)}
      >
        {modalType === "activate" && <form onSubmit={handleSubmitClick}>
          <Text as="h3" classname="text-center font-bold text-xl">
            Activate a charge
          </Text>
          <div className="flex flex-col items-center justify-center gap-4">
            <Text as="h5" weight="bold" classname="text-center text-secondary-dark">
              Select a charge to activate
            </Text>
            {/* drop down */}

            <select name="" id=""
              value={chargeSlug}
              required
              onChange={(e) =>
                setChargeSlug(e.target.value)

              }
              className="w-[200px] h-[40px] border border-primary-dark rounded-md capitalize"
            >
              <option value="">
                Select a charge
              </option>
              {data.charges.map((item, index) => {

                if (item.active === false) {
                  return (
                    <>

                      <option
                        className="text-primary-dark capitalize"
                        key={index} value={item.slug}>{item.name}</option>
                    </>
                  )
                }
              })
              }

            </select>
            <Button buttonType="submit" isLoading={isLoading}>
              Activate
            </Button>
          </div>

        </form>
        }
        {modalType === "deactivate" && <form onSubmit={handleSubmitClick}>
          <Text as="h3" classname="text-center font-bold text-xl">
            Deactivate a charge
          </Text>
          <div className="flex flex-col items-center justify-center gap-4">
            <Text as="p" weight="bold" classname="text-center text-secondary-dark">
              Select a charge to deactivate
            </Text>
            {/* drop down */}
            <select name="" id=""
              value={chargeSlug}
              required
              onChange={(e) => setChargeSlug(e.target.value)}
              className="w-[200px] h-[40px] border border-primary-dark rounded-md capitalize">

              <option value="">
                Select a charge
              </option>
              {data.charges.map((item, index) => {

                if (item.active === true) {
                  return (
                    <>
                      <option
                        className="text-primary-dark capitalize"
                        key={index} value={item.slug}>{item.name}</option>
                    </>
                  )
                }
              })
              }

            </select>
            <Button buttonType="submit" isLoading={isLoading}>
              Deactivate
            </Button>

          </div>

        </form>
        }
        {modalType === "charges-opt-in" &&
          <div>
            <Text
              as="h3"
              weight="bold"
              classname="text-[24px] lg:text-[30px] text-center"
            >
              Switch on periodic charges
            </Text>
            <div className="my-6 flex flex-col space-y-6">
              <Text as="p" weight="medium" classname=" text-secondary-dark">
                Switching on {data.slug} charges will allow you to charge your <br />
                customers {data.slug}. You can switch off {data.slug} charges
              </Text>
            </div>
            <Button onclick={handleSubmitClick} isLoading={isLoading} classname="w-full">
              Continue
            </Button>
          </div>
        }
        {modalType === "charges-opt-out" &&
          <div>
            <Text
              as="h3"
              weight="bold"
              classname="text-[24px] lg:text-[30px] text-center"
            >
              Switch Off {data.slug} charges
            </Text>
            <div className="my-6 flex flex-col space-y-6">
              <Text as="p" weight="medium" classname=" text-secondary-dark">
                Switching off {data.slug} charges will stop you from charging your <br />
                customers {data.slug}. You can switch on {data.slug} charges
              </Text>
            </div>
            <Button onclick={handleSubmitClick} isLoading={isLoading} classname="w-full">
              Continue
            </Button>
          </div>
        }

      </ModalWrapper>

    </React.Fragment>
  );
}
