import CurrencyFormat from "react-currency-format";
import Text from "../../../../components/text";
import OverDraftActions from "../components/user-charges/overdraft-actions"

export const overdraft_data = Array.from({ length: 20 }).map((data, i) => ({
  _id: i + 1,
  name: "Oloko Opeyemi ",
  apartment: "Landwey Estate Lekki 1",
  meterid: `5232464252${i}`,
  meter_type: `ENERGY`
}));

export const overdraft_column = [
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Name
      </Text>
    ),
    Cell: ({ value, row }) => <Text as="p" classname='text-left text-[16px]'>
      {value}
    </Text>,
    accessor: "fullname",
  },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Apartment
      </Text>
    ),
    // Cell: ({ value, row }) => <Text as="p">
    //     {value}
    // </Text>,
    accessor: "apartment",
  },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        User ID
      </Text>
    ),
    //     Cell: ({ value, row }) => <Text as="p">
    //     {value}
    // </Text>,
    accessor: "userid",
  },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Overdraft
      </Text>
    ),
    Cell: ({ value, row }) => (<CurrencyFormat
      value={value}
      displayType="text"
      decimalScale={2}
      renderText={(value) => <Text as="span">₦{value}</Text>}
      thousandSeparator
      fixedDecimalScale
    />),

    accessor: "wallet.overdraft",
  },
  {
    id: "2",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Charge Actions
      </Text>
    ),
    accessor: "_id",
    Cell: OverDraftActions,

  }

];
