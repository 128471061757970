import React from "react";
import { FiFilter } from "react-icons/fi";
import Chart from "react-apexcharts";
import Text from "../../../../components/text";
import useChart from "../../../../hooks/useChart";
import { clx } from "../../../../utils/clx";
import useEnergyConsumptionsInfo from "../../../../hooks/apis/user/useEnergyConsumptionsInfo";
import { MeterSwitch } from "../../../../components/switch";
import {
  getCummulatedEnergyValues,
  getCummulatedValue,
} from "../../../../utils/getCommulatedValues";
import useUserStore from "../../../../store/user/store";
import { getFormattedPrice } from "../../../../utils/getFormattedPrice";
import getDate from '../../../../utils/getDate'

export default function EnergyConsumptionChart({
  handleFilteredChart,
  onFilterClick,
  classname,
  children,
}) {
  const [activeTab, setActiveTab] = React.useState(1);
  const [barCummulativeUnit, setBarCummulativeUnit] = React.useState();
  const [areaCummulativeUnit, setAreaCummulativeUnit] = React.useState();

  const {
    dailyConsumptions,
    dayInterval,
    monthlyConsumptions,
    monthInterval,
    yearlyConsumptions,
    yearInterval,
    isConverted,
    setIsConverted,
    dailyUnits,
    monthlyUnits,
    yearlyUnits,
    hourlyInterval,
    hourlyConsumptions,
    hourlyUnits,
  } = useEnergyConsumptionsInfo();

  const handleFilterClick = (chartType) => {
    handleFilteredChart(chartType);
    onFilterClick();
  };

  React.useEffect(() => {
    setAreaCummulativeUnit(getCummulatedValue(hourlyUnits));
  }, [hourlyUnits]);

  React.useEffect(() => {
    switch (activeTab) {
      case 1:
        const dailyUnit = getCummulatedValue(dailyUnits);
        setBarCummulativeUnit(dailyUnit);
        break;
      case 2:
        const monthlyUnit = getCummulatedValue(monthlyUnits);
        setBarCummulativeUnit(monthlyUnit);
        break;
      case 3:
        const yearlyUnit = getCummulatedValue(yearlyUnits);
        setBarCummulativeUnit(yearlyUnit);
        break;
      default:
        break;
    }
  }, [activeTab, dayInterval]);

  const {
    options: barOptions,
    series: barSeries,
    otherConfig: barConfig,
  } = useChart({
    chartId: "energy-bar-chart",
    type: "bar",
    colors: ["#8692D6"],
    yLabel: "Naira(₦)",
    categories:
      activeTab === 1
        ? dayInterval
        : activeTab === 2
        ? monthInterval
        : yearInterval,
    data:
      activeTab === 1
        ? dailyConsumptions
        : activeTab === 2
        ? monthlyConsumptions
        : yearlyConsumptions,
    height: "300px",
  });

  const {
    options: areaOptions,
    series: areaSeries,
    otherConfig: areaConfig,
  } = useChart({
    chartId: `Consumption values for ${getDate(new Date(), {format: 'dd-mm-yyyy'})}`,
    type: "area",
    yLabel: "Naira(₦)",
    colors: ["#1230C9", "#fff", "#fff", "#fff"],
    categories: hourlyInterval,
    data: hourlyConsumptions,
    strokeColors: ["#9799A5"],
    height: "300px",
  });

  const getWidth = (array) => {
    switch (true) {
      case array?.length <= 7:
        return "w-full";
      case array?.length > 7 && array?.length <= 15:
        return "w-[550px]";
      case array?.length > 15 && array?.length <= 20:
        return "w-[600px]";
      case array?.length > 20 && array?.length <= 24:
        return "w-[650px]";
      default:
        return "w-full";
    }
  }

  const classes = clx("", classname);
  return (
    <div className={classes}>
      <Text as='p' weight='medium' classname='lg:text-[23px] text-[#6C6F7F]'>
        Energy consumption
      </Text>
      <div className='py-[10px] md:py-[20px] h-fit grid grid-cols-1 lg:grid-cols-2 lg:gap-x-6 gap-y-10 lg:gap-y-0'>
        <div className='py-[10px] md:py-[28px] lg:flex-1 rounded-[20px]  shadow-xl'>
          <div className='max-w-full p-4'>
            <EnergyConsumptionChart.ChartTopBar
              onFilterClick={() => handleFilterClick("area")}
              cummulativeUnits={areaCummulativeUnit}
              date={getDate(new Date(), { format: "dd-mm-yyyy" })}
              noTabs
              noFilter
            />
            <div className='w-[90%] mx-auto'>
              <MeterSwitch
                isActive={isConverted}
                toggleActive={() => setIsConverted((state) => !state)}
                label={`Consumption values are in Naira`}
                labelClasses='text-gray-600'
                toolTip='switch to toggle conversion (Naira)'
              />
            </div>
            <div className='max-w-[100%] overflow-x-auto overflow-y-hidden scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-thumb-primary-dark'>
              <div className={`${getWidth(hourlyInterval)}`}>
                <Chart
                  options={areaOptions}
                  series={areaSeries}
                  {...areaConfig}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='py-[10px] md:py-[20px] lg:flex-1 rounded-[20px]  shadow-xl'>
          <div className='max-w-[100%] p-4'>
            <EnergyConsumptionChart.ChartTopBar
              activeTab={activeTab}
              tabSetter={setActiveTab}
              onFilterClick={() => handleFilterClick("bar")}
              cummulativeUnits={barCummulativeUnit}
            />
            <div className='w-[90%] mx-auto'>
              <MeterSwitch
                isActive={isConverted}
                toggleActive={() => setIsConverted((state) => !state)}
                label={`Consumption values are in Naira`}
                labelClasses='text-gray-600'
                toolTip='switch to toggle conversion (Naira)'
              />
            </div>
            <Chart options={barOptions} series={barSeries} {...barConfig} />
          </div>
        </div>
      </div>
    </div>
  );
}

export function ChartTopBar({
  activeTab,
  onFilterClick,
  classname,
  cummulativeUnits,
  cummulativePrice,
  date,
  noTabs,
  tabSetter,
  noFilter,
  children,
}) {
  const user = useUserStore((state) => state.user);
  const classes = clx("", classname);
  return (
    <div className={classes}>
      {!noTabs && (
        <ChartTopBar.Tabs
          activeTab={activeTab}
          tabSetter={tabSetter}
          classname='my-4'
        />
      )}
      <div className='w-[90%] mx-auto'>
        {noTabs && (
          <Text as='span' classname='inline-block text-[#6C6F7F] py-2'>
            {date} <span className="font-bold">(Today)</span>
          </Text>
        )}
        <div className='pb-2 mb-4 flex items-center justify-between space-x-2 border-b-2 border-[#6C6F7F]/10'>
          <div className=''>
            <Text as='h5' classname='text-[28px] font-semibold'>
              {cummulativeUnits} <span className='text-[16px]'>Units</span>
            </Text>
            <Text as='p' classname='text-[12px] text-[#DB9800]'>
              Estimated cost is around ₦
              {getFormattedPrice(cummulativeUnits * user?.cost?.energy)}
            </Text>
          </div>
          {!noFilter && (
            <div
              className='cursor-pointer flex items-center space-x-1'
              onClick={onFilterClick}
            >
              <FiFilter />
              <Text
                as='span'
                classname='text-[14px] lg:text-base whitespace-nowrap'
              >
                Filter Data
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function Tabs({ activeTab, tabSetter, classname, children }) {
  // const [activeTab, setActiveTab] = React.useState(1);
  const classes = clx("w-fit mx-auto flex items-center space-x-6", classname);
  return (
    <div className={classes}>
      {["Daily", "Monthly", "Yearly"].map((tab, index) => (
        <div
          key={index}
          className={`py-[6px] px-[16px] rounded-[4px] font-semibold cursor-pointer ${
            activeTab === index + 1
              ? "bg-primary-dark text-white"
              : "bg-[#E6E6E9] text-primary-dark"
          }`}
          onClick={() => tabSetter(index + 1)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
}

ChartTopBar.Tabs = Tabs;
EnergyConsumptionChart.ChartTopBar = ChartTopBar;
