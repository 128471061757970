import React, { useState } from 'react';
import { FaFilter, FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'tailwindcss/tailwind.css';

const meteringData = [
  { date: '2024-05-01T10:30:00', name: 'Alice', usage: 120, unit: 'kWh', status: 'Completed' },
  { date: '2024-05-02T14:45:00', name: 'Bob', usage: 75, unit: 'kWh', status: 'Pending' },
  { date: '2024-05-03T09:15:00', name: 'Charlie', usage: 200, unit: 'kWh', status: 'Rejected' },
];

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;

  return (
    <span>
      {`${month}-${day}-${year} `}
      <span className="text-xs text-gray-500">{` ${hours}:${minutes} ${ampm}`}</span>
    </span>
  );
};

const MeteringTable = () => {
  const [filteredMeteringData, setFilteredMeteringData] = useState(meteringData);
  const [filters, setFilters] = useState({ startDate: null, endDate: null, name: '', usage: '', unit: '', status: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleDateChange = (date, dateType) => {
    setFilters({
      ...filters,
      [dateType]: date,
    });
  };

  const filterMeteringData = () => {
    setFilteredMeteringData(
      meteringData.filter(meter =>
        ((filters.startDate && filters.endDate) ? (new Date(meter.date) >= filters.startDate && new Date(meter.date) <= filters.endDate) : true) &&
        (filters.name === '' || meter.name.toLowerCase().includes(filters.name.toLowerCase())) &&
        (filters.usage === '' || meter.usage === parseFloat(filters.usage)) &&
        (filters.unit === '' || meter.unit.toLowerCase().includes(filters.unit.toLowerCase())) &&
        (filters.status === '' || meter.status.toLowerCase().includes(filters.status.toLowerCase()))
      )
    );
    setIsModalOpen(false);
  };

  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'text-green-800';
      case 'pending':
        return 'text-yellow-500';
      case 'rejected':
        return 'text-red-600';
      default:
        return '';
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Metering Table</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-[#6071D9] text-white px-4 py-2 rounded-md flex items-center transition-colors duration-200"
        >
          <FaFilter />
          <span className="ml-2">Filter</span>
        </button>
      </div>
      <div className="overflow-x-auto bg-white shadow-md rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Full Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Usage</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unit</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredMeteringData.map((meter, index) => (
              <tr key={index} className="hover:bg-gray-100 transition-colors duration-200">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{formatDate(meter.date)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{meter.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{meter.usage}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{meter.unit}</td>
                <td className={`px-6 py-4 whitespace-nowrap text-sm ${getStatusClass(meter.status)}`}>{meter.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow-lg w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Filter Metering Data</h2>
            <div className="mb-4 flex space-x-2">
              <div className="w-1/2 relative">
                <label className="block text-gray-700 mb-2">Start Date</label>
                <DatePicker
                  selected={filters.startDate}
                  onChange={date => handleDateChange(date, 'startDate')}
                  selectsStart
                  startDate={filters.startDate}
                  endDate={filters.endDate}
                  showTimeSelect
                  timeIntervals={15}
                  dateFormat="dd/MM/yy h:mm aa"
                  placeholderText="dd/mm/yy"
                  className="w-full border border-gray-300 px-2 py-1 rounded text-sm"
                />
                <FaCalendarAlt className="absolute right-3 top-10 text-gray-400 pointer-events-none" />
              </div>
              <div className="w-1/2 relative">
                <label className="block text-gray-700 mb-2">End Date</label>
                <DatePicker
                  selected={filters.endDate}
                  onChange={date => handleDateChange(date, 'endDate')}
                  selectsEnd
                  startDate={filters.startDate}
                  endDate={filters.endDate}
                  minDate={filters.startDate}
                  showTimeSelect
                  timeIntervals={15}
                  dateFormat="dd/MM/yy h:mm aa"
                  placeholderText="dd/mm/yy"
                  className="w-full border border-gray-300 px-2 py-1 rounded text-sm"
                />
                <FaCalendarAlt className="absolute right-3 top-10 text-gray-400 pointer-events-none" />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Name</label>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={filters.name}
                onChange={handleFilterChange}
                className="w-full border border-gray-300 px-2 py-1 rounded text-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Usage</label>
              <input
                type="number"
                name="usage"
                placeholder="Usage"
                value={filters.usage}
                onChange={handleFilterChange}
                className="w-full border border-gray-300 px-2 py-1 rounded text-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Unit</label>
              <input
                type="text"
                name="unit"
                placeholder="Unit"
                value={filters.unit}
                onChange={handleFilterChange}
                className="w-full border border-gray-300 px-2 py-1 rounded text-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Status</label>
              <input
                type="text"
                name="status"
                placeholder="Status"
                value={filters.status}
                onChange={handleFilterChange}
                className="w-full border border-gray-300 px-2 py-1 rounded text-sm"
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={filterMeteringData}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MeteringTable;
