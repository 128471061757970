import axios from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import { useAuthUser } from "react-auth-kit";
import { TbCurrencyNaira } from "react-icons/tb";
import * as Yup from "yup";
import useUserStore from "../../store/user/store";
import { clx } from "../../utils/clx";
import { getFormattedPrice } from "../../utils/getFormattedPrice";
import Button from "../button";
import Input from "../input";
import Radio from "../radio";
import Text from "../text";
import useUserCharges from "../../hooks/apis/user/useUserCharges";


export default function Amount({
  classname,
  paymentMethod,
  noDescription,
  setActualAmount,
  minimum,
  showBreakdown,
  onButtonClick,
  setData,
  setPlatform,
  setTotalAmount,
  children,
}) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const user = useUserStore((state) => state.user);
  const [amount, setAmount] = React.useState(0);

  const { data, refetch, isLoading, isError } = useUserCharges({
    key: "user-regular-charges",
    type: "regular",
  });

  // console.log(data)
  useEffect(() => {
    refetch();
  }, []);

  const { chargesArray } = useMemo(() => {
    const isNairaCharges = data?.data?.find(({ slug }) => slug === "naira");
    const isPercentageCharges = data?.data?.find(
      ({ slug }) => slug === "percentage"
    );
    const chargesArray = [];

    isNairaCharges?.insights?.map(({ name, amount: value }) => {
      chargesArray.push({ name, value: Number(value) });
    });

    isPercentageCharges?.insights?.map(
      ({ name, amount: value, naira_addup, cap }) => {
        naira_addup = naira_addup ?? 0;
        value = (Number(amount) * value) / 100;
        let valueWithAddup = value + naira_addup;

        if (cap && cap?.["cap_status"]) {
          const { cap_at, cap_to } = cap;
          if (cap_at && cap_to) {
            if (value > cap_at) {
              valueWithAddup = cap_to;
            }
          }
        }
        chargesArray.push({ name, value: Number(valueWithAddup) });
      }
    );

    return { chargesArray };
  }, [amount]);

  const totalCharges = useMemo(() => {
    return chargesArray?.reduce((acc, item) => acc + item?.value, 0);
  }, [chargesArray]);

  const totalAmount = () => {
    const acc = (Number(amount) + Number(totalCharges)).toFixed(2);
    setTotalAmount(acc);
    return acc;
  };

  const classes = clx("relative flex flex-col", classname);

  const formValues = noDescription
    ? {
        rechargeAmount: "",
        platform: "",
      }
    : {
        rechargeAmount: "",
        remoteCompliance: user?.mode.energy > 0 ? "" : "no",
      };

  const validationSchema = noDescription
    ? Yup.object({
        rechargeAmount: Yup.number()
          .min(minimum, `Minimum recharge can't be less than ₦${getFormattedPrice(minimum)}`)
          .required("Please enter an amount to fund"),
        platform: Yup.string().required("Please select a gateway"),
      })
    : Yup.object({
        rechargeAmount: Yup.number()
          .min(
            minimum, `Minimum recharge can't be less than ₦${getFormattedPrice(minimum)}`
          )
          .required("Please enter an amount to fund"),
        remoteCompliance: Yup.string().required("Select an option (Yes || No)"),
      });

  const onSubmit = (values) => {
    setValidationAttempt(false);
    setData(values);
    onButtonClick();
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });

  const isMetering = user?.user_services?.find(
    ({ slug, status }) => slug === "metering" && status
  );

  const meteringProduct = isMetering?.product;
  const energyProduct = meteringProduct.find(
    ({ slug }) => slug === "energy"
  );

  const remote = energyProduct?.remote === 'none'? false : true;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes}>
        <Text
          as="h3"
          weight="bold"
          classname="text-[24px] lg:text-[30px] text-center"
        >
          Amount?
        </Text>
        <div className="my-6">
          <Input
            type="number"
            placeholder="Amount"
            name="rechargeAmount"
            iconLeft={<TbCurrencyNaira className="text-[18px]" />}
            onchange={(e) => {
              setActualAmount && setActualAmount(e.target.value);
              setAmount(e.target.value);
              formik.handleChange(e);
            }}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.rechargeAmount}
            value={formik.values.rechargeAmount}
          />
        </div>
        {paymentMethod === "bank" ? (
          <>
            <Text
              as="h3"
              weight="bold"
              classname="text-[24px] lg:text-[30px] text-center"
            >
              Platform?
            </Text>
            <div className="my-6">
              <select
                className="flex items-center select-none space-x-3 border-undefined rounded-[8px] py-[15px] px-[20px] bg-[#E6E6E9] dark:bg-[#1B1D26] w-full text-[#1B1D26] dark:text-[white]"
                placeholder="Platform"
                name="platform"
                value={formik.values.platform}
                onChange={(e) => {
                  // setData &&
                  //   setData({
                  //     platform: e.target.value,
                  //   });
                  setPlatform(e.target.value);
                  formik.handleChange(e);
                }}
              >
                <option value="">Select Platform</option>
                <option value="paystack">paystack</option>
                <option value="flutterwave">flutterwave</option>
              </select>
              <Text as="p" classname="text-[14px] lg:text-base text-red-600">
                {formik.errors.platform}
              </Text>
            </div>
            <div className="w-full flex flex-col space-y-2 mb-2">
              <div className="flex flex-col space-y-1">
                {chargesArray?.map((item, index) => (
                  <div
                    className="flex items-center justify-between border-b-2"
                    key={index}
                  >
                    <Text
                      as="p"
                      weight="semibold"
                      classname="text-[12px] lg:text-base "
                    >
                      {item.name}
                    </Text>
                    <Text
                      as="p"
                      weight="semibold"
                      classname="text-[12px] text-gray-700"
                    >
                      ₦ {getFormattedPrice(item.value)}
                    </Text>
                  </div>
                ))}

                <div className="flex items-center justify-between border-b-2">
                  <Text
                    as="p"
                    weight="bold"
                    classname="text-[12px] lg:text-base"
                  >
                    Total Charges
                  </Text>
                  <Text
                    as="p"
                    weight="semibold"
                    classname="text-[12px] text-gray-700"
                  >
                    ₦ {getFormattedPrice(totalCharges)}
                  </Text>
                </div>
              </div>
              <Text
                as="p"
                weight="semibold"
                classname="text-[12px] text-gray-700"
              >
                Total Amount <br />
                <span className="font-extrabold text-primary font-gantari">
                  ₦ {getFormattedPrice(totalAmount())}
                </span>
              </Text>
            </div>
          </>
        ) : (
          <div>
{/*             <div className="w-full flex flex-col space-y-2 mb-2">
              <div className="flex flex-col space-y-1">
                {user?.debt &&
                  Object.entries(user.debt).map(([key, value]) => (
                    <div
                      className="flex items-center justify-between border-b-2"
                      key={key}
                    >
                      <Text
                        as="p"
                        weight="semibold"
                        classname="text-[12px] lg:text-base "
                      >
                        {key.slice(0, 1).toLocaleUpperCase() + key.slice(1)}
                      </Text>
                      <Text
                        as="p"
                        weight="semibold"
                        classname="text-[12px] text-red-500"
                      >
                        ₦ {getFormattedPrice(value) || 0}
                      </Text>
                    </div>
                  ))}
              </div>
            </div> */}
            <div
              className={`${
                remote ? "flex" : "hidden"
              } items-center justify-between`}
            >
              <Text as="span" weight="semibold" classname="text-[18px]">
                Remotely?
              </Text>
              <div className="flex space-x-3">
                <Radio
                  name="confirm-remotely"
                  label="Yes"
                  onblur={formik.handleBlur}
                  onchange={formik.handleChange}
                  value="yes"
                  onclick={({ target }) =>
                    (formik.values.remoteCompliance = target.value)
                  }
                />
                <Radio
                  name="confirm-remotely"
                  label="No"
                  onchange={formik.handleChange}
                  onblur={formik.handleBlur}
                  value="no"
                  onclick={({ target }) =>
                    (formik.values.remoteCompliance = target.value)
                  }
                />
              </div>
            </div>
            <Text as="p" classname="text-[14px] lg:text-base text-red-600">
              {formik.errors.remoteCompliance}
            </Text>

            <div className="my-1">
              <Text as="p" classname="text-[12px] text-[#9799A5]">
                Yes - your meter will be recharged remotely
              </Text>
              <Text as="p" classname="text-[12px] text-[#9799A5]">
                No - A token will be sent to you
              </Text>
            </div>
          </div>
        )}

        <div className="mt-6 w-fit mx-auto">
          <Button
            buttonType="submit"
            size="medium"
            onclick={() => setValidationAttempt(true)}
            // disabled={
            //   !noDescription && isRemoteComplied === false ? true : null
            // }
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
}
