import Text from "../../../../components/text";
import {
  Action,
  MeterNumber,
  Name,
  Provider,
  TableIcon,
} from "../disco-electricity-meters-list-utils";

export const DiscoElecticityMetersListColumns = [
  {
    id: "_",
    Header: () => null,
    Cell: TableIcon,
    accessor: "logo",
  },
  {
    id: "meter_number",
    Header: (
      <Text
        as="p"
        classname="text-left text-[16px] lg:text-[18px] text-[#2C3048]"
      >
        Meter Number
      </Text>
    ),
    Cell: MeterNumber,
    accessor: "meterid",
  },
  {
    id: "name",
    Header: (
      <Text
        as="p"
        classname="text-left text-[16px] lg:text-[18px] text-[#2C3048]"
      >
        Name
      </Text>
    ),
    Cell: Name,
    accessor: "name",
  },
  {
    id: "provider",
    Header: (
      <Text as="p" classname="text-[16px] text-left lg:text-[18px] text-[#2C3048]">
        Provider
      </Text>
    ),
    Cell: Provider,
    accessor: "provider",
  },
  {
    id: "type",
    Header: (
      <Text as="p" classname="text-[16px] text-left lg:text-[18px] text-[#2C3048]">
        Type
      </Text>
    ),
    Cell: Provider,
    accessor: "type",
  },
  {
    id: "action",
    Header: () => null,
    Cell: Action,
    accessor: "",
  },
];
