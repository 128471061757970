import React from "react";
import AdminCountCard from "../../../../components/admin-count-card";
import Table from "../../../../components/table";
import Text from "../../../../components/text";
import { clx } from "../../../../utils/clx";
import { wallet_column, wallet_data } from "../../../../utils/table-utils/admin/wallet-table";
import AdminWalletBalanceCard from "../../../../components/admin-wallet-balance";

export default function WalletWrapper() {
  const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
  return (
    <div className={classes}>
      <AdminWalletBalanceCard />
      <div className='flex pt-[40px]'>
        <div className='w-full h-full rounded-[20px] bg-white p-6 flex flex-col space-y-6'>
          <div className='flex items-center justify-between'>
            <div>
              <Text as='h5' weight='bold' classname='text-[20px]'>
                Recent Transactions
              </Text>
            </div>
            <Text
              as='h5'
              weight='semibold'
              classname='text-[12px] text-secondary-dark'
            >
              See All
            </Text>
          </div>

          <Table
            data={wallet_data}
            columns={wallet_column}
          />
        </div>
      </div>
    </div>
  );
}
