import React from "react";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import useUserStore from "../../../store/user/store";
import { useQuery } from "react-query";

export default function useConsumptionInfo({
  key,
  userId,
  query,
  apiKey,
  prepaid,
  start,
  end,
  startmonth,
  day,
  endmonth,
  startyear,
  endyear
}) {
  const authUser = useAuthUser();
  const user = useUserStore((state) => state.user);

  const { isLoading, data, isError, isFetching, refetch } = useQuery(key??"ConsumptionInfo", () =>
    axios.post(
      `https://api-pm-e1ea777feac4.herokuapp.com/metering/consumption?query=${query}`,
      // 'https://pm-server.herokuapp.com/api/v2.0/consumption/?query=' + query,
      {
        // userid: user?.userid??userId,
        prepaid,
        start,
        end,
        day,
        startmonth,
        endmonth,
        startyear,
        endyear,
      },
      {
        headers: {
          'x-api-key': user.apikey??apiKey,
          Authorization: authUser().authToken,
        },
      }
    ),
    {
      enabled: true
    }
  );
  return { data: data?.data, isLoading, isError, isFetching, refetch };
}
