export const GatePassHeader = [
    { label: "Fullname", key: "fullname" },
    { label: "Email", key: "email" },
    { label: "P.Number", key: "phonenumber" },
    { label: "Entry Date/Time", key: "checkin.createdAt" },
    { label: "Exit Date/Time", key: "checkout.createdAt" },
    { label: "A.Type", key: "sign_in" },
    { label: "Access Code", key: "access_code" },
    { label: "Actions", key: "_id" },
    { label: "Day", key: "day" },
  ];