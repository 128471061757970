import React from "react";
import { BiCopy } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DashboardSidebar from "../../../components/dashboard-sidebar";
import LinkButton from "../../../components/link-button";
import Text from "../../../components/text";
import { adminMenu } from "../../../constants/admin-menu";
import { clx } from "../../../utils/clx";
import { IoChevronBack } from "react-icons/io5";
import Select from "../../../components/select";
import useAdminAffiliates from "../../../store/admin/affiliates";
import useUserInfo from "../../../hooks/apis/user/useUserInfo";
import BranchSelect from "./branch-select";
import NoSelectedAffiliate from "./no-selected-affiliate";
import useAdminUserInfo from "../../../hooks/apis/admin/useAdminUserInfo";
import useAdminRank from "../../../store/admin/adminRank";
import useAdminData from "../../../store/admin/adminData";

export default function AdminDashboardLayout({
  classname,
  pageTitle,
  children,
 
}) {
  const router = useLocation();
  const navigate = useNavigate();
  const [isNavOpen, setNavOpen] = React.useState(false);
  const [isMainMenu, setIsMainMenu] = React.useState(null);
  const scrollRef = React.useRef();
  const { setAdminRank } = useAdminRank()
  const { setAdminData } = useAdminData()
  const { selectedAffiliate, setAffiliates } = useAdminAffiliates(
    (state) => state
  );

  const {
    UserInfo: admin,
    isLoading,
    refetch,
    isFetching,
  } = useAdminUserInfo();

  React.useEffect(() => {
    setIsMainMenu(false);
    scrollRef.current?.scrollTo(0, 0);
    for (let menu of adminMenu) {
      if (
        menu.route === router.pathname ||
        menu.route === `${router.pathname}/`
      ) {
        setIsMainMenu(true);
      }
    }
  }, [router, isMainMenu]);

  React.useEffect(() => {
    refetch();
    const features = admin?.data
    if (features) {
      const { affiliates, __rank__, ...adminData } = features
      setAdminData(adminData)
      setAffiliates(affiliates);
      setAdminRank(__rank__);
    }
  }, [admin]);
  // console.log(admin)

  const classes = clx("lg:max-h-screen  lg:h-screen flex", classname);

  if (isFetching) {
    return (
      <div className="h-[100%] w-full flex items-center justify-center">
        <div className="fixed inset-0 h-[100%]">
          <img
            src="/assets/images/spiral-background.jpg"
            className="object-cover lg:object-fill w-full h-full opacity-[1.5%] lg:opacity-[1.5%]"
            alt=""
          />
        </div>
        <div className="fixed inset-0 left-[50%] translate-x-[-50%] flex justify-center items-center w-fit flex-col">
          <div>
            <img src="/assets/icons/loader.gif" alt="" className="w-[100px]" />
          </div>
          <Text as="p" weight="bold" classname="text-[26px] lg:text-[40px]">
            Welcome <span className="text-secondary-dark">Back!</span>
          </Text>
        </div>
      </div>
    );
  }
  return (
    <div className={classes}>
      <div className="hidden lg:block">
        <DashboardSidebar useFor="admin" />
      </div>
      <div className="flex-1 min-h-screen lg:min-h-fit w-full flex flex-col lg:max-h-screen lg:overflow-hidden relative">
        <div className="fixed lg:static inset-0 w-full max-h-[70px] lg:max-h-[100px] bg-white z-40">
          <div className=" lg:static w-[93%] lg:w-[90%]  mx-auto py-[20px] lg:py-[30px] flex items-center">
            {isMainMenu ? (
              <div>
                <div className="hidden lg:block">
                  <BranchSelect />
                </div>
                <div
                  className="lg:hidden w-fit h-fit p-2 rounded-full bg-primary-dark flex justify-center items-center"
                  onClick={() => setNavOpen(true)}
                >
                  <GiHamburgerMenu className="text-[20px] text-white" onClick={()=>console.log('here')}/>
                </div>
              </div>
            ) : (
              <div className="flex items-center space-x-3">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <IoChevronBack className="text-[24px]" />
                  {/* <Text as='span'>Back</Text> */}
                </div>
                <div className="hidden lg:block">
                  <BranchSelect />
                </div>
              </div>
            )}
            <div className="flex-1 text-center">
              <Text as="span">{pageTitle}</Text>
            </div>
            <div className="flex items-center space-x-8">
              {isMainMenu && (
                <div>
                  <img src="/assets/icons/bell.png" alt="" />
                </div>
              )}
              <div className="hidden lg:flex items-center space-x-2">
                <div className="w-[48px] h-[48px] rounded-full">
                  <img src="/assets/images/thumbnail.png" alt="" />
                </div>
                <img src="/assets/icons/dropdown.png" alt="" />
              </div>
            </div>
          </div>
          {/* Mobile Nav */}
          {/* <AdminDashboardLayout.MobileNav
            isOpen={isNavOpen}
            handleClose={() => setNavOpen(false)}
          /> */}
        </div>

        <div
          ref={scrollRef}
          className="lg:flex-1 w-full lg:overflow-auto mt-[70px] lg:mt-0 scrollbar scrollbar-thumb-primary-dark scrollbar-track-secondary-dar scrollbar-track-rounded-lg scrollbar-thumb-rounded-lg"
        >
          <div className="bg-gray-50 min-h-[calc(100vh-100px)] relative">
            {selectedAffiliate === "" || selectedAffiliate === "All" ? (
              <NoSelectedAffiliate />
            ) : (
              <Outlet />
  
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function MobileNav({ classname, isOpen, handleClose, children }) {
  const [isLinkClicked, setLinkClicked] = React.useState(false);
  const router = useLocation();

  const handleLinkClick = () => {
    setLinkClicked(true);
    handleClose();
  };
  React.useEffect(() => {
    setLinkClicked(false);
    window.scrollTo(0, 0);
  }, [isLinkClicked]);
  const classes = clx(
    "lg:hidden fixed inset-0 z-50 h-screen max-h-screen w-full bg-black/5 overflow-hidden duration-300",
    isOpen ? "ml-0" : "ml-[-100%]",
    classname
  );
  return (
    <div
      className={classes}
      onClick={({ target, currentTarget }) =>
        target === currentTarget && handleClose()
      }
    >
      <div className="w-[300px] h-screen max-h-screen bg-white px-[20px] py-[40px] overflow-auto">
        <div
          className="w-fit"
          onClick={({ target, currentTarget }) => handleClose()}
        >
          <MdCancel className="text-[33px] text-primary-dark" />
        </div>
        <div className="flex items-center space-x-6 pt-[30px]">
          <div className="w-[48px] h-[48px] rounded-full">
            <img src="/assets/images/thumbnail.png" alt="" />
          </div>
          <div>
            <Text as="p">Hello</Text>
            <Text as="p" classname="font-semibold">
              Samuel Lasisi
            </Text>
          </div>
        </div>
        <div className="flex items-center space-x-1 pb-[30px] py-3">
          <Text as="p" weight="semibold" classname="text-[14px]">
            UserID:
          </Text>
          <div className="flex items-center space-x-3">
            <Text as="span" classname="text-[14px]">
              123456ABCD
            </Text>
            <BiCopy className="block text-black" />
          </div>
        </div>
        <div className="flex flex-col space-y-6 py-[20px] pb-[100px] border-b-[3px]">
          
          <LinkButton classname="hover:bg-transparent hover:text-[#6C6F7F]">
            Logout
          </LinkButton>
        </div>
      </div>
    </div>
  );
}

AdminDashboardLayout.MobileNav = MobileNav;

