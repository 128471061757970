import React from "react";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import ErrorToast from "../../../components/toast/error-toast";

export default function useChargesPayment({ key, payloads }) {
  const authUser = useAuthUser();
  console.log({ payloads });

  const { isLoading, data, isError, refetch, isSuccess } = useQuery(
    key,
    () =>
      axios
        .post(
          "https://api-pm-e1ea777feac4.herokuapp.com/charges/pay",
          { ...payloads },
          {
            headers: {
              Authorization: authUser().authToken,
            },
          }
        )
        .catch((err) => {
          toast.error(<ErrorToast>{err.response.data.message}</ErrorToast>);
        }),
    {
      enabled: false,
    }
  );
  return { data: data?.data, isLoading, isError, refetch, isSuccess };
}
