import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import Button from "../../components/button";
import Input from "../../components/input";
import Text from "../../components/text";
import AuthLayout from "../../layouts/auth-layout";

export default function AdminForgetPassPageWrapper() {
  return (
    <AuthLayout pageTitle='Forgot Password' usePageNavigation>
      <div className='w-full flex flex-col space-y-8 items-center py-[20px] lg:py-0'>
        <div>
          <Text
            as='p'
            classname='hidden lg:block font-[600] text-[54px] text-primary-dark text-center'
          >
            Forgot password ?
          </Text>
          <Text
            as='p'
            weight='medium'
            classname='text-[14px] lg:text-[20px] text-primary-dark lg:text-center'
          >
            Enter your meter number, we will send an to OTP to your email
          </Text>
        </div>
        <div className='lg:bg-[#F8F8FF] lg:border-[1px] rounded-[16px] w-full lg:w-[550px] py-[0px] lg:py-[50px] px-[0px] lg:px-[70px]'>
          <AdminForgetPassPageWrapper.Form />
        </div>
      </div>
    </AuthLayout>
  );
}

export function Form({}) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);

  const formValues = {
    meterNumber: "",
  };

  const validationSchema = Yup.object({
    meterNumber: Yup.number("Meter Number must be a range of numbers").required(
      "Please enter your meter number"
    ),
  });

  const onSubmit = (values) => {
    setValidationAttempt(false);
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex flex-col items-center space-y-6'>
        <div className='w-full mb-[30px]'>
          <Input
            label='Enter Meter ID'
            type='number'
            iconLeft={<img src='/assets/icons/meter-id-icon.png' alt='icon' />}
            placeholder='Meter Number'
            classname='w-full'
            name='meterNumber'
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.meterNumber}
            value={formik.values.meterNumber}
          />
        </div>
        <Button buttonType='submit' classname='w-full'>
          Submit
        </Button>
      </div>
    </form>
  );
}

AdminForgetPassPageWrapper.Form = Form;
