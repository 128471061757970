import React from "react";
import { FcCheckmark } from "react-icons/fc";
import Text from "../text";
import CurrencyFormat from "react-currency-format";

export default function TransactionCard({ classname, data, children }) {
  return (
    <div className="w-[550px] pt-[30px]">
      <div className="flex items-center justify-between border-b-2 pb-4">
        <div>
          <Text as="p" classname="text-[14px] lg:text-base text-[#6C6F7F]">
            Reference Id
          </Text>
          <Text
            as="h2"
            weight="semibold"
            classname="text-black text-[20px] lg:text-[24px]"
          >
            {data?.refr}
          </Text>
        </div>
        <div className="w-[50px] h-[50px] rounded-full bg-[#F5F5FE] flex items-center justify-center">
          <FcCheckmark className="text-[25px]" />
        </div>
      </div>
      <div className="py-4">
        <Text as="p" weight="bold">
          <span className="text-secondary-dark">TOKEN:</span> {data?.Token}
        </Text>
      </div>
      <div className="pb-4 flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-[40px] items-start">
        <div className="flex flex-col space-y-2">
          <Text as="p" classname="text-[14px] lg:text-base text-[#2C3048]">
            Amount
          </Text>
          <CurrencyFormat
            value={data?.Total_paid}
            isNumericString
            displayType="text"
            renderText={(value) => (
              <Text
                as="h2"
                weight="normal"
                classname="text-[#6C6F7F] text-[20px] lg:text-[24px]"
              >
                ₦{value}
              </Text>
            )}
            thousandSeparator
            fixedDecimalScale
          />
        </div>
        <div className="flex-1 flex items-center space-x-3 lg:justify-between">
          {/* <div className="flex flex-col space-y-2">
            <Text as="p" classname="text-[#2C3048]">
              Fee
            </Text>
            <Text as="h2" weight="normal" classname="text-[#6C6F7F]">
              ₦25
            </Text>
          </div> */}

          <div className="flex flex-col space-y-2">
            <Text as="p" classname="text-[#2C3048]">
              Status
            </Text>
            <Text
              as="h2"
              weight="normal"
              classname="text-[#00DA16] bg-[#DAF8DD] rounded-[4px] px-3"
            >
              Success
            </Text>
          </div>

          <div className="flex flex-col space-y-2">
            <Text as="p" classname="text-[#2C3048]">
              Date & Time
            </Text>
            <Text as="h2" weight="normal" classname="text-[#6C6F7F]">
              {data?.Gen_Date}
            </Text>
          </div>

          {/* <div className="flex flex-col space-y-2">
            <Text as="p" classname="text-[#2C3048]">
              Time
            </Text>
            <Text as="h2" weight="normal" classname="text-[#6C6F7F]">
              9:53am
            </Text>
          </div> */}
        </div>
      </div>
    </div>
  );
}
