import React from "react";
import useConsumptionInfo from "../../hooks/apis/user/useConsumptionInfo";
import useUserStore from "../../store/user/store";


export function GetConsumptionData({ key, query, prepaid, userId, apiKey }) {
  const [totalCredit, setTotalCredit] = React.useState({});
  const [totalConsumption, setTotalConsumption] = React.useState({});
  const [availableBalance, setAvailableBalance] = React.useState({});
  const setCost = useUserStore((state) => state.setCost);
  const {
    data: consumptionInfo,
    isLoading,
    isFetching,
  } = useConsumptionInfo({
    query,
    prepaid,
    key,
    userId,
    apiKey,
  });
  const cost = consumptionInfo?.data.cost;




  React.useEffect(() => {
    setCost(consumptionInfo?.data.cost);
    setTotalCredit({
      unit: consumptionInfo?.data.Cumulative_Purchased_Amount,
      price: (
        consumptionInfo?.data.Cumulative_Purchased_Amount * cost
      ).toFixed(),
    });
    // setTotalConsumption({
    //   unit: consumptionInfo?.data.Cumulative_Total_Kwh_Consumption,
    //   price: (
    //     consumptionInfo?.data.Cumulative_Total_Kwh_Consumption * cost
    //   ).toFixed(),
    // });
    setAvailableBalance({
      unit: consumptionInfo?.data.Residual_Amount,
      price: (consumptionInfo?.data.Residual_Amount * cost).toFixed(),
    });
  }, [isLoading, isFetching, consumptionInfo]);

  return {
    totalCredit,
    totalConsumption,
    availableBalance,
    isLoading,
    cost,
    isFetching,
  };
}
