import React, { useEffect } from "react";
import Text from "../../../components/text";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import useAdminAffiliateInfo from "../../../hooks/apis/admin/useAdminAffiliateInfo";
import useAdminChargesInfo from "../../../hooks/apis/admin/useAdminChargesInfo";
import UseFetchEndpoint from "../../../hooks/apis/useFetch";
import useAdminAffiliates from "../../../store/admin/affiliates";

export default function DashboardAnalytics() {
  const affiliate = useAdminAffiliateInfo();
  
  // FIXME: Resolve later on completion of UI
  // const { selectedAffiliate } = useAdminAffiliates();

  // const { data, isFetching, refetch } = UseFetchEndpoint({
  //   method: "get",
  //   endpoint: `/${selectedAffiliate}/property?prop=charges`,
  //   key: "charges-props",
  // });
  // useEffect(() => {
  //   refetch();
  // }, [selectedAffiliate]);

  // const services = data?.services?.services;

  // const isMetering = services?.find(
  //   ({ slug, status }) => slug === "metering" && status
  // );

  // const meteringProduct = isMetering?.product;
  // const energyProduct = meteringProduct.map(
  //   ([key, value]) => {
      
  //   }
  // );

  const values = React.useMemo(
    () => (
      {
      energy:
        affiliate?.energymeters?.length > 0
          ? affiliate?.energymeters?.length
          : 0,
      water:
        affiliate?.watermeters?.length > 0 ? affiliate?.watermeters?.length : 0,
      fuel:
        affiliate?.iotservices?.length > 0 ? affiliate?.iotservices?.length : 0,
    }),
    [affiliate]
  );
  const series = [values.energy, values.water, values.fuel];

  const options = {
    labels: ["Energy", "Water", "Fuel"],
    theme: {
      monochrome: {
        enabled: false,
      },
    },
    legend: {
      position: "bottom",
    },
    noData: {
      text: "No Data",
    },
  };
  return (
    <div className="w-full rounded-[8px] bg-white p-4">
      <Text as="h5" weight="bold" classname="text-[20px] text-primary-dark">
        Analytics
      </Text>
      <div className="mt-4">
        {affiliate?.energymeters?.length === 0 &&
        affiliate?.watermeters?.length === 0 &&
        affiliate?.iotservices?.length === 0 ? (
          <Text
            as="h5"
            weight="semibold"
            classname="text-secondary-dark text-center"
          >
            No Data Found
          </Text>
        ) : (
          <ReactApexChart type="donut" options={options} series={series} />
        )}
      </div>
    </div>
  );
}
