import React from "react";
import { FaRegBuilding } from "react-icons/fa";
import { IoPeople } from "react-icons/io5";
import { MdLocationCity } from "react-icons/md";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";
import * as Img from "../../../images/";

const Collab = () => {
  const classes = clx(
    "px-6 lg:px-[140px] flex flex-col sm:flex-row justify-between items-center py-4 lg:py-[100px] bg-[#ffff] overflow-hidden"
  );

  const colors = ["#f9f4d4", "#e0f2e0", "#c5d0f3", "#e6d3cb"]; // Different background colors for each row

  return (
    <section className={classes}>
      <div className="text-center">
        <Text
          as="h1"
          weight="bold"
          classname="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-center"
        >
          Our Features
        </Text>
        {Data.map((data, index) => (
          <div
            key={index}
            className={`rounded-lg overflow-hidden mx-4 md:mx-0 my-8`} // added margin to create space between rows
            style={{ backgroundColor: colors[index % colors.length] }}
          >
            <div className={`flex flex-col md:flex-row ${
              index % 2 === 1 ? "md:flex-row-reverse" : ""
            }`}>
              <div className="flex-1" data-aos="fade-right">
                <img
                  src={data.img}
                  alt=""
                  className="object-contain w-full h-90 rounded-lg"
                />
              </div>
              <div className="flex-1 flex flex-col justify-center items-center p-4 md:p-8"  data-aos="fade-left">
                <Text
                  as="h5"
                  weight="semibold"
                  classname="text-md md:text-3xl text-center mb-4 md:mb-6"
                >
                  {data.title}
                </Text>
                <Text
                  as="p"
                  weight="normal"
                  className="text-sm md:text-[18px] text-left text-[#6C6F7F]"
                >
                  {data.content}
                </Text>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Collab;

const Data = [
  {
    img: Img.bill,
    title: "Bill Payment and Collections",
    content: `No more bill-collecting nightmares! Collect bills and invoices effortlessly with Prepaidmeter.ng. Your residents can pay with ease, and you'll have all payment records in one place, with no paperwork needed. Stress-free and seamless!`,
  },
  {
    img: Img.office,
    title: "Simplified Property Management",
    content: `Power up your property management game! Keep records of your properties and occupants, communicate directly with facility managers and residents, and find tenants for your vacant properties with our platform. It's property management made easy!`,
  },
  {
    img: Img.community,
    title: "Secure Your Communities",
    content: `Keep your residents safe and secure with our visitor management and emergency button features. With Prepaidmeter.ng, you can ensure the safety of your residents with ease.`,
  },
  {
    img: Img.business,
    title: "Our platform goes beyond data delivery",
    content: `At Prepaidmeter.ng, we're committed to making your business easy. Let's discuss your pain points, and we'll create the perfect customized solution for you. Whatever you need, we've got you covered!`,
  },
];
