import React from "react";
import { BiCopy } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DashboardSidebar from "../../../components/dashboard-sidebar";
import LinkButton from "../../../components/link-button";
import Text from "../../../components/text";
import { ioboMenu } from "../../../constants/iobotech-menu";
import { clx } from "../../../utils/clx";
import { IoChevronBack } from "react-icons/io5";
import LocationSelect from "./location-select";
import NoSelectedEstate from "./no-selected-estate";
import useAdminAffiliates from "../../../store/admin/affiliates";
import useAdminUserInfo from "../../../hooks/apis/admin/useAdminUserInfo";
import useAdminRank from "../../../store/admin/adminRank";
import useAdminData from "../../../store/admin/adminData";

export default function ProtectedIoboDashboard({
  classname,
  pageTitle,
  children
}) {
  const router = useLocation();
  const navigate = useNavigate();
  const [isNavOpen, setNavOpen] = React.useState(false);
  const [isMainMenu, setIsMainMenu] = React.useState(null);
  const scrollRef = React.useRef();
  const { setAdminRank } = useAdminRank();
  const { setAdminData } = useAdminData();
  const { selectedAffiliate, setAffiliates } = useAdminAffiliates((state) => state);

  const { UserInfo: iobo, isLoading, refetch, isFetching } = useAdminUserInfo();

  React.useEffect(() => {
    setIsMainMenu(false);
    scrollRef.current?.scrollTo(0, 0);
    for (let menu of ioboMenu) {
      if (menu.route === router.pathname || menu.route === `${router.pathname}/`) {
        setIsMainMenu(true);
      }
    }
  }, [router, isMainMenu]);

  const classes = clx("lg:max-h-screen lg:h-screen flex", classname);

  return (
    <div className={classes}>
      <div className="hidden lg:block ">
        <DashboardSidebar background="bg-[#ECEEFA]" textColor="text-black-900" useFor="iobo" />

      </div>
      <div className="flex-1 min-h-screen lg:min-h-fit w-full flex flex-col lg:max-h-screen lg:overflow-hidden relative">
        <div className="fixed lg:static inset-0 w-full max-h-[70px] lg:max-h-[100px] bg-white z-40">
          <div className=" lg:static w-[93%] lg:w-[90%]  mx-auto py-[20px] lg:py-[30px] flex items-center">
            {isMainMenu ? (
              <div>
                <div className="hidden lg:block">
                  <LocationSelect />
                </div>
                <div
                  className="lg:hidden w-fit h-fit p-2 rounded-full bg-primary-dark flex justify-center items-center"
                  onClick={() => setNavOpen(true)}
                >
                  <GiHamburgerMenu className="text-[20px] text-white" />
                </div>
              </div>
            ) : (
              <div className="flex items-center space-x-3">
                <div className="flex items-center cursor-pointer" onClick={() => navigate(-1)}>
                  <IoChevronBack className="text-[24px]" />
                </div>
                <div className="hidden lg:block">
                  <LocationSelect />
                </div>
              </div>
            )}
            <div className="flex-1 text-center">
              <Text as="span">{pageTitle}</Text>
            </div>
            <div className="flex items-center space-x-8">
              {isMainMenu && (
                <div>
                  <img src="/assets/icons/bell.png" alt="" />
                </div>
              )}
              <div className="hidden lg:flex items-center space-x-2">
                <div className="w-[48px] h-[48px] rounded-full">
                  <img src="/assets/images/thumbnail.png" alt="" />
                </div>
                <img src="/assets/icons/dropdown.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div
          ref={scrollRef}
          className="lg:flex-1 w-full lg:overflow-auto mt-[70px] lg:mt-0 scrollbar scrollbar-thumb-primary-dark scrollbar-track-secondary-dar scrollbar-track-rounded-lg scrollbar-thumb-rounded-lg"
        >
          <div className="bg-gray-50 min-h-[calc(100vh-100px)] relative">
            {/* {selectedAffiliate === "" || selectedAffiliate === "All" ? (
              // <NoSelectedEstate />
              <>hdhdfhdff</>
            ) : ( */}
              <Outlet />
              
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
}


export function MobileNav({ classname, isOpen, handleClose, children }) {
  const [isLinkClicked, setLinkClicked] = React.useState(false);
  const router = useLocation();

  const handleLinkClick = () => {
    setLinkClicked(true);
    handleClose();
  };
  React.useEffect(() => {
    setLinkClicked(false);
    window.scrollTo(0, 0);
  }, [isLinkClicked]);
  const classes = clx(
    "lg:hidden fixed inset-0 z-50 h-screen max-h-screen w-full bg-black/5 overflow-hidden duration-300",
    isOpen ? "ml-0" : "ml-[-100%]",
    classname
  );
  return (
    <div
      className={classes}
      onClick={({ target, currentTarget }) =>
        target === currentTarget && handleClose()
      }
    >
      <div className="w-[300px] h-screen max-h-screen bg-white px-[20px] py-[40px] overflow-auto">
        <div
          className="w-fit"
          onClick={({ target, currentTarget }) => handleClose()}
        >
          <MdCancel className="text-[33px] text-primary-dark" />
        </div>
        <div className="flex items-center space-x-6 pt-[30px]">
          <div className="w-[48px] h-[48px] rounded-full">
            <img src="/assets/images/thumbnail.png" alt="" />
          </div>
          <div>
            <Text as="p">Hello</Text>
            <Text as="p" classname="font-semibold">
              Samuel Lasisi
            </Text>
          </div>
        </div>
        <div className="flex items-center space-x-1 pb-[30px] py-3">
          <Text as="p" weight="semibold" classname="text-[14px]">
            UserID:
          </Text>
          <div className="flex items-center space-x-3">
            <Text as="span" classname="text-[14px]">
              123456ABCD
            </Text>
            <BiCopy className="block text-black" />
          </div>
        </div>
        <div className="flex flex-col space-y-6 py-[20px] pb-[100px] border-b-[3px]">
          
          <LinkButton classname="hover:bg-transparent hover:text-[#6C6F7F]">
            Logout
          </LinkButton>
        </div>
      </div>
    </div>
  );
}

ProtectedIoboDashboard.MobileNav = MobileNav;

