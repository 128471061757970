import { create } from "zustand";

const useSelectedEnergyUser = create((Set) => ({
  selectedEnergyUser: {},
  apikey: "",
  setSelectedEnergyUser: (energyUser) =>
    Set(() => ({ selectedEnergyUser: energyUser })),
  setEnergyUserApiKey: (apiKey) => Set(() => ({ apikey: apiKey })),
}));

export default useSelectedEnergyUser;
