import React from "react";
import StaticLayout from "../../layouts/static-layout";
import MarketPlaceWrapper from "../../views/static/market-place";
import ComingSoon from "../../views/static/components/coming-soon";
import BuyMeterWrapper from "../../views/static/buy-meter/buy-meter";

export default function MarketPlacePage() {
  return (
    <React.Fragment>
       <StaticLayout>
      <BuyMeterWrapper/>
      {/*<MarketPlaceWrapper />*/}
      {/*<ComingSoon />*/}
    </StaticLayout> 
    </React.Fragment>
  );
}
