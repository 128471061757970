import React, { useCallback } from "react"
import { clx } from "../../../../../utils/clx";
import { Link, useNavigate } from "react-router-dom";
import Text from "../../../../../components/text";
import { GrServices, GrStatusDisabled } from "react-icons/gr";
import Button from "../../../../../components/button";
import { IoAddOutline } from "react-icons/io5";
import { CgTrash } from "react-icons/cg";
import ModalWrapper from "../../../../../components/modals/modal-wrapper";
import AffiliateServiceModal from "../../../../../components/modals/admin/affiliate-service-modal";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { toast } from "react-toastify";
import useAdminAffiliates from "../../../../../store/admin/affiliates";
import { useAffiliateProducts } from "../../../../../store/admin/configure";
import useAdminRank from "../../../../../store/admin/adminRank";

export default function ServiceSlug() {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [modalType, setModalType] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [newService, setNewService] = React.useState("");
    const authUser = useAuthUser();
    const navigate = useNavigate()
    const { selectedAffiliate } = useAdminAffiliates();
    const { affiliateProducts, setAffiliateProducts } = useAffiliateProducts()
    const { adminRank } = useAdminRank()


    if (!affiliateProducts || affiliateProducts === null) {
        navigate('/manager/configure/affiliates/service')
    }
    const redirect = useCallback(() => {
        if (affiliateProducts?.tag !== selectedAffiliate) {
            navigate('/manager/configure/affiliates/service')
        }
    }, [selectedAffiliate])

    React.useEffect(() => {
        redirect()
    }, [redirect])
    const handleButtonClick = (path) => {
        setIsModalOpen(true);
        setModalType(path);
    }
    const handleAction = (query) => {
        setIsLoading(true);
        axios.post(`https://pm-server.herokuapp.com/api/v2.0/affiliate/service?query=${query}`, {
            tag: selectedAffiliate,
            product_slug: newService.toLowerCase(),
            service_slug: affiliateProducts.service_slug
        }, {

            headers: {
                Authorization: authUser().authToken,
            }
        }
        ).then((res) => {
            setIsLoading(false);
            setIsModalOpen(false);
            toast.success(res.data.message)
            navigate(`/manager/configure/affiliates/service`)
            setNewService("")
        }).catch((err) => {
            setIsLoading(false);
            toast.error(err.response.data.message)
        }).finally(() => {
            setIsLoading(false);
        })
    }
    const classes = clx(
        "w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex flex-col space-y-[40px]"
    );
    return (
        <React.Fragment>

            <div className={classes}>
                <div className="flex flex-row justify-between items-center">
                    <Text as="p" weight="bold" classname="capitalize text-[22px]">
                        {affiliateProducts?.service_slug}
                    </Text>
                    {adminRank >= 2 && (
                        <div className="flex  gap-4 p-3">
                            <Button
                                variant='text'
                                iconLeft={<IoAddOutline />}
                                onclick={() => {
                                    handleButtonClick("activate");
                                }}

                                classname=' w-fit text-secondary-dark'
                            >
                                Activate a product
                            </Button>
                            <Button
                                variant='text'
                                iconLeft={<GrStatusDisabled />}
                                onclick={() => {
                                    handleButtonClick("deactivate");
                                }}
                                classname=' w-fit text-secondary-dark'
                            >
                                Deactivate a product
                            </Button>
                            <Button
                                variant='text'
                                iconLeft={<CgTrash />}
                                onclick={() => {
                                    handleButtonClick("remove");
                                }}
                                classname=' w-fit text-secondary-dark'
                            >
                                Remove a product
                            </Button>
                        </div>
                    )
                    }
                </div>
                {affiliateProducts?.data?.length ?
                    (<div className="grid lg:grid-cols-4 md:grid-cols-3 gap-[40px] items-center">
                        {affiliateProducts?.data.map((item, index) => (
                            <div key={index} className="flex flex-col items-center justify-center w-[200px] bg-[#E6E6E9] rounded-md h-[200px] gap-3">
                                <GrServices size={50} />
                                <Text as="h3" weight="bold" classname="capitalize">
                                    {item.slug}
                                </Text>
                                <Text as="p" weight="bold" classname={`${item.active ? "text-green-600" : "text-red-400"} `}>
                                    {item.active ? "Active" : "Inactive"}
                                </Text>
                                <Link
                                    key={index}
                                    className="text-white font-bold px-4 py-2 rounded-md bg-primary-dark hover:bg-primary-light transition-all duration-200 ease-in-out"
                                    to={`/manager/configure/affiliates/service/${affiliateProducts?.service_slug}/${item.slug}`}
                                    onClick={() => {
                                        setAffiliateProducts({
                                            ...affiliateProducts,
                                            product_slug: item.slug
                                        })
                                    }}
                                >
                                    View insights
                                </Link>
                            </div>
                        ))}
                    </div>) : (
                        <div>
                            <Text as="p" classname="text-center mt-60 m-auto">
                                No products available
                            </Text>
                        </div>
                    )}
            </div>

            <>
                <ModalWrapper
                    isOpen={isModalOpen}
                    handleModalClose={() => setIsModalOpen(false)}
                >
                    {modalType === "activate" && <AffiliateServiceModal
                        title={"Activate a Product"}
                        body="Activate a product for this service"
                        onButtonClick={() => {
                            handleAction('activate-product')

                        }}
                        data={affiliateProducts?.data.filter((item) => item.active === false)}
                        btnText="Activate"
                        isLoading={isLoading}
                        type="activate"
                        newService={newService}
                        setNewService={setNewService}
                        label="Product"
                        query="get-product-slugs"
                    />}
                    {modalType === "deactivate" &&
                        <AffiliateServiceModal
                            title={"Deactivate a product"}
                            body="Deactivate a product for this service"
                            onButtonClick={() => handleAction('deactivate-product')}
                            data={affiliateProducts?.data.filter((item) => item.active === true)}
                            btnText="Deactivate"
                            isLoading={isLoading}
                            type="deactivate"
                            newService={newService}
                            setNewService={setNewService}
                            label="Product"
                        />
                    }
                    {modalType === "remove" && <AffiliateServiceModal
                        title={"Remove a product"}
                        body="Remove a product for this service"
                        onButtonClick={() => handleAction('remove-product')}
                        data={affiliateProducts?.data}
                        btnText="Remove"
                        isLoading={isLoading}
                        type="remove"
                        newService={newService}
                        setNewService={setNewService}
                        label="Product"
                    />}
                </ModalWrapper>
            </>
        </React.Fragment>
    )
}