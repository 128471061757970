import React from "react";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";

import { useQuery } from "react-query";
import useUserStore from "../../../store/user/store";

export default function UseViewAdmin({
  key,
  userId,
  query,
  apiKey,
  prepaid,
  start,
  end,
  startmonth,
  day,
  endmonth,
  startyear,
  endyear,
  role
}) {
  const authUser = useAuthUser();
  const user = useUserStore((state) => state.user);

  const { isLoading, data, isError, isFetching, refetch } = useQuery(key??"ConsumptionInfo", () =>
    axios.post(
    //   `https://api-pm-e1ea777feac4.herokuapp.com/metering/consumption?query=${query}`,
      'https://pm-server.herokuapp.com/api/v2.0/consumption/?query=' + query,
      {
        userid: user?.userid??userId,
        prepaid:'energy',
                start:'2022-12',
                end:'2023-2',
        // prepaid,
        // start,
        // end,
        day,
        startmonth,
        endmonth,
        startyear,
        endyear,
      },
      {
        headers: {
          'x-api-key': user.apikey??apiKey,
          Authorization: authUser().authToken,
          'Content-Type':'application/json'
        },
      }
    ),
    {
      enabled: true
    }
  );
  return { data: data?.data, isLoading, isError, isFetching, refetch };
}


// import React from "react";
// import useConsumptionInfo from "../../hooks/apis/user/useConsumptionInfo";
// import useUserStore from "../../store/user/store";

export function GetViewAdminConsumptionData({ key, query, prepaid, userId, apiKey }) {
  const [totalCredit, setTotalCredit] = React.useState({});
  const [totalConsumption, setTotalConsumption] = React.useState({});
  const [availableBalance, setAvailableBalance] = React.useState({});
  const setCost = useUserStore((state) => state.setCost);
  const {
    data: consumptionInfo,
    isLoading,
    isFetching,
  } = UseViewAdmin({
    query,
    prepaid,
    key,
    userId,
    apiKey,
  });
  // console.log(consumptionInfo)
  const cost = consumptionInfo?.data.cost;
  React.useEffect(() => {
    setCost(consumptionInfo?.data.cost);
    setTotalCredit({
      unit: consumptionInfo?.data.Cumulative_Purchased_Amount,
      price: (
        consumptionInfo?.data.Cumulative_Purchased_Amount * cost
      ).toFixed(),
    });
    setTotalConsumption({
      unit: consumptionInfo?.data.Cumulative_Total_Kwh_Consumption,
      price: (
        consumptionInfo?.data.Cumulative_Total_Kwh_Consumption * cost
      ).toFixed(),
    });
    setAvailableBalance({
      unit: consumptionInfo?.data.Residual_Amount,
      price: (consumptionInfo?.data.Residual_Amount * cost).toFixed(),
    });
  }, [isLoading, isFetching, consumptionInfo]);

  return {
    totalCredit,
    totalConsumption,
    availableBalance,
    isLoading,
    cost,
    isFetching,
  };
}
