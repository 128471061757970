import React from "react";
import { Link } from "react-router-dom";
import useAdminRank from "../../../../store/admin/adminRank";
import { clx } from "../../../../utils/clx";
import Text from "../../../../components/text";


export default function GuestWrapper() {
    const { adminRank } = useAdminRank()
    const routes = [
        {
            id: 1,
            title: "Referer",
            image: "/assets/images/referer.svg",
            path: "referer-request",
            rank: 5
        },
        {
            id: 2,
            title: "Schedule Demo",
            image: "/assets/images/demo.svg",
            path: "demo-request",
            rank: 5
        }
    

    ];

    const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
    return (
        <div className={classes}>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 gap-[40px] mt-[20px] items-center">
                {routes.map((item) => {
                    if (item.rank <= adminRank) {
                        return (
                            <Link
                                to={`/manager/configure/guest/${item.path}`}
                                key={item.id}
                                className="hover:shadow-md"
                            >
                                <div
                                    key={item.id}
                                    className="flex flex-col items-center  justify-center w-full bg-white  rounded-md p-[20px] h-[200px] gap-3"
                                >
                                    <img src={item.image} className="w-[78px]" alt="icon" />
                                    <Text as="p" classname={`font-bold`}>
                                        {item.title}
                                    </Text>
                                </div>
                            </Link>)
                    }
                })}
            </div>
        </div>
    );
}
