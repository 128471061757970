import CurrencyFormat from "react-currency-format";
import Checkbox from "../../../components/check-box";
import Text from "../../../components/text";

export const payout_data = Array.from({ length: 5 }).map((data, i) => ({
  _id: i + 1,
  name: "Oloko Opeyemi ",
  apartment: `Chrestovest Bodijah Freedom ${i}`,
  amount: `N10,000`,
  purpose: `WEEKLY`,
}));

export const payout_column = [
  {
    id: "selection",
    // The header can use the table's getToggleAllRowsSelectedProps method
    // to render a checkbox
    // Header: ({ getToggleAllRowsSelectedProps }) => (
    //   <div className="flex flex-row gap-3">
    //     <Checkbox {...getToggleAllRowsSelectedProps()} />
    //     <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
    //       Select all
    //     </Text>
    //   </div>
    // ),
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    Cell: ({ row }) => (
      <div>
        <Checkbox {...row.getToggleRowSelectedProps()} />
      </div>
    ),
  },
  {
    //     id: "_",
    Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
            Date Created
        </Text>
    ),
    accessor: "dateCreated",
    Cell: ({ value, row }) => <Text as='p' classname="text-left text-[16px] text-primary-light">
        {value && new Date(value).toLocaleString("en-US")}
    </Text>
},

  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Name
      </Text>
    ),
    Cell: ({ value, row }) => (
      <Text as="p" classname="text-left text-[16px] capitalize">
        {value}
      </Text>
    ),
    accessor: "user.fullname",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Apartment
      </Text>
    ),
    // Cell: ({ value, row }) => <Text as="p">
    //     {value}
    // </Text>,
    accessor: "user.apartment",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Amount
      </Text>
    ),
    Cell: ({ value, row }) => <Text as="p">
      <CurrencyFormat
        value={value}
        displayType="text"
        decimalScale={2}
        renderText={(value) => <Text as="span">₦{value}</Text>}
        thousandSeparator
        fixedDecimalScale
      />
    </Text>,
    accessor: "amount",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Purpose
      </Text>
    ),
    Cell: ({ value, row }) => <Text as='p' classname="text-left text-[16px] uppercase text-secondary-dark">
      {value}
    </Text>,
    accessor: "payout_slug",
  },
];
