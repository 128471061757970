import React from "react";
import StaticLayout from "../../layouts/static-layout";
import CreateBlog from "../../views/static/CreateBlog";


export default function CreateBlogpage() {
      return (
            <React.Fragment>
                        <CreateBlog/>
            </React.Fragment>
      )
}