import React from "react";
import { MdCancel, MdSend } from "react-icons/md";
import { Link } from "react-router-dom";
import Text from "../../../components/text";
import TextArea from "../../../components/textarea";
import ChatSidebar from "./sidebar";
import { io } from "socket.io-client";
import useAdminAffiliates from "../../../store/admin/affiliates";
import useAdminUser from "../../../store/admin/admin-user";
import useAdminUserInfo from "../../../hooks/apis/admin/useAdminUserInfo";
import { useAuthUser } from "react-auth-kit";
import ChatContainer from "../../../components/chat-container";

export default function AdminChatWrapper() {
  return (
    <div className="fixed h-[100%] max-h-[100%] inset-0 z-50 lg:static flex space-x-4 w-[100%] lg:w-[90%] mx-auto overflow-hidden">
      {/* Sidebar */}
      <ChatSidebar />

      <div className="bg-white flex flex-col h-[100%] lg:h-[calc(100vh-120px)] lg:mt-[20px] flex-1 mx-auto lg:border-2 lg:border-b-0 lg:border-primary-dark lg:rounded-t-[40px] overflow-hidden">
        <div className="h-[100px] lg:h-[150px] w-full bg-primary-dark flex items-center justify-center">
          <div className="w-[93%] lg:w-[85%] mx-auto flex items-center justify-between">
            <div className="flex items-center space-x-3 lg:space-x-6">
              <div className="w-[50px] h-[50px] lg:w-[70px] lg:h-[70px] p-4 rounded-full bg-white">
                <img
                  src="/assets/images/chat-logo2.png"
                  width="100%"
                  height="100%"
                  alt=""
                />
              </div>
              <div className="">
                <Text as="p" classname="lg:text-[28px] text-white">
                  Admin
                </Text>
                <Text
                  as="span"
                  classname="text-[12px] lg:text-[16px] text-white"
                >
                  Online
                </Text>
              </div>
            </div>
            <div className="lg:hidden text-white">
              <Link
                to="/user"
                className="w-fit"
                // onClick={({ target, currentTarget }) => handleClose()}
              >
                <MdCancel className="text-[33px] text-primary-dark bg-white rounded-full" />
              </Link>
            </div>
          </div>
        </div>
        <div className="flex h-[calc(100%-100px)] lg:h-[calc(100%-150px)] flex-col w-[93%] lg:w-[85%] mx-auto pb-[20px]">
          <div
            // ref={scrollRef}
            className="flex-1 overflow-auto my-[12px] scrollbar-none lg:scrollbar-thin scrollbar-thumb-primary-dark scrollbar-thumb-rounded-md"
          ></div>
          <div className="flex items-end space-x-4 lg:space-x-[30px]">
            <TextArea
              placeholder="Enter your message here"
              inputClass="rounded-[16px] bg-[#F5F5FE]"
            />
            <div className="min-w-[48px] min-h-[48px] rounded-full bg-[#F5F5FE] hover:bg-[#6C6F7F]/40 cursor-pointer flex items-center justify-center">
              <MdSend className="text-[25px] text-primary-dark" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
