import React from "react";
import { clx } from "../utils/clx";
import Text from "./text";

export default function Radio({ label, checked, labelClasses, value, onblur, onchange, name, onclick, classname, children }) {
  const radioRef = React.useRef();
  const [isChecked, setChecked] = React.useState();

  const handleClick = (e) => {
    radioRef.current?.click();
    onclick(e)
  };
  
  const classes = clx("flex items-center space-x-2", classname);
  return (
    <div className={classes} onClick={handleClick}>
      {/* <span
        className={`w-[15px] h-[15px] rounded-full ${
          isChecked ? "bg-blue-500" : "bg-red-500"
        } inline-block`}
        onClick={handleClick}
      ></span> */}
      <input
        ref={radioRef}
        onChange={onchange}
        value={value}
        onBlur={onblur}
        type='radio'
        name={name}
        checked={checked}
        id={name}
      />
      <Text as='span' classname={labelClasses}>{label}</Text>
    </div>
  );
}
