import React, { useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import 'tailwindcss/tailwind.css';

const MeteringBilling = ({ filters, handleFilterChange }) => {
  const [isMeteringCollapsed, setIsMeteringCollapsed] = useState(true);
  const [isBillingCollapsed, setIsBillingCollapsed] = useState(true);

  const toggleMeteringCollapse = () => {
    setIsMeteringCollapsed(!isMeteringCollapsed);
  };

  const toggleBillingCollapse = () => {
    setIsBillingCollapsed(!isBillingCollapsed);
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    const currentFilters = filters[name] ? filters[name].split(',') : [];
    const updatedFilters = checked 
      ? [...currentFilters, value] 
      : currentFilters.filter(item => item !== value);
    
    handleFilterChange({
      target: {
        name,
        value: updatedFilters.join(','),
      },
    });
  };

  return (
    <div>
      <div className="mb-4">
        <div className="flex justify-between items-center">
          <label className="block text-gray-700 mb-2">Metering</label>
          <button onClick={toggleMeteringCollapse} className="focus:outline-none">
            {isMeteringCollapsed ? <IoIosArrowDown /> : <IoIosArrowUp />}
          </button>
        </div>
        {!isMeteringCollapsed && (
          <div className="border border-gray-300 p-2 rounded mb-2 w-full">
            <label className="block mb-2">
              <input
                type="checkbox"
                name="category"
                value="Energy"
                checked={filters.category?.split(',').includes('Energy') || false}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Energy
            </label>
            <label className="block mb-2">
              <input
                type="checkbox"
                name="category"
                value="Water"
                checked={filters.category?.split(',').includes('Water') || false}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Water
            </label>
            <label className="block mb-2">
              <input
                type="checkbox"
                name="category"
                value="Gas"
                checked={filters.category?.split(',').includes('Gas') || false}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Gas
            </label>
          </div>
        )}
      </div>

      <div className="mb-4">
        <div className="flex justify-between items-center">
          <label className="block text-gray-700 mb-2">Billing</label>
          <button onClick={toggleBillingCollapse} className="focus:outline-none">
            {isBillingCollapsed ? <IoIosArrowDown /> : <IoIosArrowUp />}
          </button>
        </div>
        {!isBillingCollapsed && (
          <div className="border border-gray-300 p-2 rounded mb-2 w-full">
            <label className="block mb-2">
              <input
                type="checkbox"
                name="description"
                value="Service Charge"
                checked={filters.description?.split(',').includes('Service Charge') || false}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Service Charge
            </label>
            <label className="block mb-2">
              <input
                type="checkbox"
                name="description"
                value="Diesel"
                checked={filters.description?.split(',').includes('Diesel') || false}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Diesel
            </label>
            <label className="block mb-2">
              <input
                type="checkbox"
                name="description"
                value="Levy"
                checked={filters.description?.split(',').includes('Levy') || false}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              Levy
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default MeteringBilling;
