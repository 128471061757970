import React from "react";
import { Route } from "react-router-dom";
import usePageTitle from "../hooks/usePageTitle";
import { ioboMenu } from "../constants/iobotech-menu";
import ProtectedIoboDashboard from "../layouts/dashboard/iobotech/iobotech-dashboard";
import ConfigurePage from "../pages/admin/configure";
import IoboPage from "../views/admin/configure/iobo-index"
import BillingPage from "../views/admin/configure/billing-index"
// import ioboPage from "../pages/admin/configure/iobo-index"
import AffiliateSettlements from "../views/admin/configure/overview-services";
import IobotechLogin from "../components/iobotech-login";
import IobotechTransactionTable from "../components/iobotech-transaction-table";
import BillingCard from "../components/billing-card";
import Services from "../views/admin/configure/overview-services/services";
import MeterRechargeTable from "../components/meter-recharge-table";
import ServiceCharge from "../components/service-charge";
export default function GetIobotechRoutes() {
  const titledRoutes = [
    ...ioboMenu.map(item => ({
      id: item.id,
      pageTitle: item.pageTitle,
      route: item.route,
    })),
    // Additional route definitions if needed
  ];
  
  const pageTitle = usePageTitle(titledRoutes);

  return (
    <React.Fragment>
      <Route path="/iobotech" element={<IobotechLogin />} />
      <Route path="/iobotech/dashboard" element={<ProtectedIoboDashboard pageTitle={pageTitle} />} >
        <Route index element={<Services/>} /> 
        <Route path="monitoring" element={<IobotechTransactionTable/>} /> 
        <Route path="resident" element={<ServiceCharge />} />
        <Route path="billing" element={ <BillingPage />}/>
        <Route path="maintenance" element={<div>Prepaid Water</div>} /> 
        <Route path="security" element={<div>Fuel Monitoring</div>} /> 
        <Route path="charges" element={<div>Periodic Charges</div>} /> 
        <Route path="manager/configure" element={<div>Configuration</div>} /> 
        <Route path="chat" element={<div>Chat</div>} /> 
        <Route path="meter" element={<BillingPage />} />
        {/* <Route path="service" element={<ServiceCharge />} /> */}
      </Route>
    </React.Fragment>
  );
}
