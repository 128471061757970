import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { GetConsumptionData } from "../../../../api-calls/user/getConsumptionData";
import BalanceCard from "../../../../components/balance-card";
import Button from "../../../../components/button";
import EnergyTransactionCard from "../../../../components/energy-transaction-card";
import MeterIDContainer from "../../../../components/meterid-container";
import Filter from "../../../../components/modals/filter";
import ModalWrapper from "../../../../components/modals/modal-wrapper";
import TransactionCard from "../../../../components/modals/transaction-card";
import Text from "../../../../components/text";
import useUserInfo from "../../../../hooks/apis/user/useUserInfo";
import useVendInfo from "../../../../hooks/apis/user/useVendInfo";
import useWalletInfo from "../../../../hooks/apis/user/useWalletInfo";
import useUserStore from "../../../../store/user/store";
import { clx } from "../../../../utils/clx";
import { getSortedLastToStart } from "../../../../utils/getSortedLastToStart";
import useSelectedEnergyUser from "../../../../store/admin/selected-energy-user";
import useAdminAffiliateInfo from "../../../../hooks/apis/admin/useAdminAffiliateInfo";
import EnergyConsumptionChart from "../../../user/components/charts/energy-consumption-chart";
import AffiliateEnergyConsumptionChart from "../../components/charts/energy-consumption-chart";
import { GetViewAdminConsumptionData } from "../../../../hooks/apis/admin/useViewAdmin";
import useAdminVendInfo from "../../../../hooks/apis/admin/useAdminVendInfo";
import { useQuery } from "react-query";
import useRecentTransaction, { RecentTreansaction, getRecentTransaction } from "./../../../../hooks/apis/admin/useRecentTransaction";
import axios from "axios";
import useGetRecentTransaction from "./../../../../hooks/apis/admin/useRecentTransaction";




// import EnergyConsumptionChart from "./components/charts/energy-consumption-chart";
// import { EnergyFunding } from "./dashboard";

export default function SingleUserEnergyDashboard({ classname, children }) {
  
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [filteredChart, setFilteredChart] = React.useState(null);
  const [modalFor, setModalFor] = React.useState(null);
  // const [transact, settransact] = useState([])
  // const [isFundWalletOpen, setFundWalletOpen] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [paymentMethod, setPaymentMethod] = React.useState(false);
  const navigate = useNavigate();
  // const user = useUserStore((state) => state.user);
  const { selectedEnergyUser: user } = useSelectedEnergyUser();
  const affiliate = useAdminAffiliateInfo();
  const authUser = useAuthUser();

  const {
    availableBalance,
    totalConsumption,
    totalCredit,
    cost,
    isFetching,
    isLoading,
  } = GetViewAdminConsumptionData({
    query: "realtime",
    prepaid: "energy",
    userId: user?.userid,
    apiKey: affiliate?.apikey,
  });
  const { data: transactionsInfo } = useVendInfo({
    prepaid: "energy",
  
  });
  


const transactions = getSortedLastToStart(transactionsInfo?.data);

  const handleModalControl = (useCase) => {
    setModalFor(useCase);
    setModalOpen(true);
  };

  const handlePaymentMethodSelect = (method) => {
    setPaymentMethod(method);
    setStep(2);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setStep(1);
  };

  React.useEffect(() => window.scrollTo(0, 0), [isFetching, isLoading]);
  React.useEffect(() => {
    if (!user?.userid) {
      navigate("/manager/energy/customers");
    }
  });


  //! folly
  // const { transact } = useGetRecentTransaction();
  const yourAdminId = user?.userid
  const authTransact = authUser().authToken

  const { data: tra,isLoading:la,isError:lb, isFetching:lc, refetch:ld ,error:ll} = useQuery('trac', () => getRecentTransaction(yourAdminId, authTransact));
  let transact = tra?.data?.data
  
//!

  const classes = clx("w-[93%] lg:w-[90%] mx-auto py-[40px]", classname);
  return (
    <React.Fragment>
      <div className={classes}>
       
        <div className="max-w-[100%] scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-thumb-[#E5E5E5] overflow-auto pt-[40px] pb-[10px] grid grid-cols-2 gap-4 lg:gap-x-3 lg:grid-cols-3 justify-between ">
        <BalanceCard
        platform="Prepaid Energy"
        title="Total Credit"
        unit={totalCredit.unit}
        price={totalCredit.price}
      />
      {user?.mode?.energy > 0 ? (
        <BalanceCard
          platform="Prepaid Energy"
          title="Available Balance"
          unit={availableBalance.unit}
          price={availableBalance.price}
        />
      ) : null}
      {user?.mode?.energy > 0 ? (
        <BalanceCard
          platform="Prepaid Energy"
          title="Total Consumption"
          unit={totalConsumption.unit}
          price={totalConsumption.price}
        />
      ) : null}
          

         
        </div>
       
        
        <div className="">
          <div className="flex items-center justify-between py-[20px]">
            <Text
              as="span"
              weight="medium"
              classname="lg:text-[23px] text-[#6C6F7F]"
            >
              Recent Transactions
            </Text>
            <Text
              as="span"
              weight="medium"
              classname="lg:text-[23px] text-secondary-dark"
              onclick={() => navigate("transactions")}
            >
              see all
            </Text>
          </div>
          <div className="lg:py-[30px] flex flex-col space-y-6">
           {
            transact?.slice(0,10).map((transaction,index)=>(
              <EnergyTransactionCard
              key={index}
              data={transaction}
              classname="w-[100%]"
            />
            ))
           }
          </div>
        </div>
      </div>
      <ModalWrapper isOpen={isModalOpen} handleModalClose={handleModalClose}>
        {/* {modalFor === "payment" && (
          <EnergyFunding
            step={step}
            setStep={setStep}
            handleModalClose={handleModalClose}
            onselect={handlePaymentMethodSelect}
            paymentMethod={paymentMethod}
          />
        )} */}
        {modalFor === "filter" && (
          <Filter
            handleModalClose={() => setModalOpen(false)}
            route={
              filteredChart === "bar"
                ? "/manager/energy/customers/single/energy-statistics"
                : null
            }
          />
        )}

      </ModalWrapper>
    </React.Fragment>
  );
}


// {transact?.data.slice(0, 5).map((transaction, index) => (
//   <EnergyTransactionCard
//     key={index}
//     data={transaction}
//     classname="w-[100%]"
//   />
// ))}

// {user?.mode?.energy > 0 ? (
//   <AffiliateEnergyConsumptionChart
//     handleFilteredChart={setFilteredChart}
//     onFilterClick={() => handleModalControl("filter")}
//     classname="my-[30px] lg:my-[50px]"
//   />
// ) : null}