import React from "react";
import { Link } from "react-router-dom";
import useUserStore from "../store/user/store";
import { clx } from "../utils/clx";
import { getFormattedPrice } from "../utils/getFormattedPrice";
import Button from "./button";
import Text from "./text";

export default function RechargeCard({
  href,
  onclick,
  platform,
  onButtonClick,
  totalCredit,
  totalConsumption,
  availableBalance,
  handleFuel,
  classname,
  isLoading,
  children,
  remote,
  type
}) {
  const user = useUserStore((state) => state.user);

  const classes = clx(
    "w-[740px] h-fit rounded-[20px] bg-[#F5F5FE] py-[10px] shadow-lg border-2 border-transparent hover:border-2 hover:border-black/20 hover:cursor-pointer dark:bg-[#070917]",
    classname
  );
  return (
    <div className={classes} onClick={onclick}>
      <div
        onClick={onclick}
        className="py-4 lg:py-6 px-[30px] flex justify-between items-center border-b-2 border-[#E6E6E9] dark:border-[#1B1D26]"
      >
        <Text
          as="h3"
          weight="semibold"
          classname="text-[18px] lg:text-[23px] text-primary-dark dark:text-[#E6E6E9]"
        >
          {platform ?? "Prepaid Energy"}
        </Text>
        <div className="flex space-x-6">
          <Button href={href} size="medium">
            View
          </Button>
          {platform !== "IoT" && (
            <Button
              variant="contained"
              size="medium"
              classname="hidden lg:block px-[40px]"
              onclick={onButtonClick}
            >
              Recharge
            </Button>
          )}
        </div>
      </div>
      <div onClick={onclick} className={`p-6 px-[30px] flex justify-between`}>
        <RechargeCard.Label
          onclick={onclick}
          platform={platform}
          label={platform === "IoT" ? "Fuel Level" : "Total Credit"}
          labelObject={totalCredit}
          isLoading={isLoading}
          type={type}
        />
        {remote ? (
          <RechargeCard.Label
            onclick={onclick}
            platform={platform}
            label={
              platform === "IoT" ? "Fuel Consumption" : "Total Consumption"
            }
            labelObject={totalConsumption}
            isLoading={isLoading}
            type={type}
          />
        ) : null}
        {remote ? (
          <RechargeCard.Label
            onclick={onclick}
            platform={platform}
            label={platform === "IoT" ? "Machine Status" : "Available Balance"}
            labelObject={availableBalance}
            handleFuel={handleFuel}
            isLoading={isLoading}
            type={type}
          />
        ) : null}
      </div>
      {platform !== "IoT" && (
        <Button
          size="medium"
          classname="lg:hidden mx-6 my-[10px] mb-[15px] ml-auto"
          onclick={onButtonClick}
        >
          Recharge
        </Button>
      )}
    </div>
  );
}

export function Label({
  label,
  onclick,
  platform,
  labelObject,
  handleFuel,
  classname,
  children,
  isLoading ,
  type
}) {
  const classes = clx("w-fit", classname);
  const types = {energy: 'kwh', water: 'm3', iot: 'L'}[type]
  if (isLoading) {
    return (
      <div className={`${classes} flex items-center justify-center`}>
        <div className="w-[40px] lg:w-[60px] h-[40px] lg:h-[60px]">
          <img
            src="/assets/icons/loader2.svg"
            alt="loading..."
            className="w-full h-full object-fill"
          />
        </div>
      </div>
    );
  }
  return (
    <div className={classes} onClick={onclick}>
      <Text
        onclick={onclick}
        as="span"
        classname="text-[10px] lg:text-[14px] text-[#6C6F7F] dark:text-[#9799A5]"
      >
        {label}
      </Text>
      <div
        onClick={onclick}
        className="flex flex-col lg:flex-row lg:items-center lg:space-x-4 lg:mt-[15px]"
      >
        <Text
          onclick={onclick}
          as="p"
          weight="semibold"
          classname="text-[13px] py-2 lg:py-0 lg:text-base font-semibold text-primary-dark flex items-center dark:text-[#E6E6E9]"
        >
          {labelObject?.unit}
          {handleFuel && "Grid"}
          {platform === "IoT" && handleFuel && (
            <span className="inline-block mx-1 px-3 text-[8px] bg-primary-dark text-white rounded-[4px]">
              {labelObject?.machineStatus}
            </span>
          )}
          {!handleFuel && (
            <span className="font-normal">
              {types}
            </span>
          )}
        </Text>
        {platform !== "IoT" && (
          <Text
            onclick={onclick}
            as="span"
            classname="text-white lg:text-secondary-dark text-[12px] rounded-[3px] lg:rounded-none bg-secondary-dark lg:bg-transparent px-3 py-1 lg:py-0 lg:px-4 lg:border-l-[1px] border-primary-dark"
          >
            ₦{getFormattedPrice(labelObject?.price)}
          </Text>
        )}
      </div>
    </div>
  );
}

RechargeCard.Label = Label;
