import React from 'react'
import Slider from "react-slick";

const CustomSlider = ({children,slidesToShow=3,speed=2000}) => {
    var settings = {
        dots: false,
      infinite: true,
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      autoplay: true,
      speed: speed,
      autoplaySpeed: 2000,
      cssEase: "linear",
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow:slidesToShow ?? 2,
              slidesToScroll: 3,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <Slider {...settings}>
     {children}
    </Slider>
  )
}

export default CustomSlider