import React from "react";
import MayAlsoLike from "./components/may-also-like";
import Text from "../../components/text";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { CartCard } from "./components/cart-card";
import { OrderSummary } from "./components/order-summary";
import { CheckoutForm } from "./components/checkout-form";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";

export default function CheckoutPageWrapper() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="px-5 lg:py-[30px] py-5 lg:px-20 flex flex-col gap-10  bg-[#F8F8FF]">
        <div className="hidden lg:flex space-x-2">
          <Text href="/" as="p" classname="text-sm text-gray-400">
            Home
          </Text>
          <MdOutlineArrowForwardIos className="text-gray-400 w-4 h-4" />
          <Text as="p" classname="text-sm capitalize text-gray-400">
            Cart
          </Text>
        </div>
        <div className="lg:hidden flex items-center gap-10">
          <MdOutlineArrowBackIosNew
            className="h-6 w-6  text-gray-400"
            onClick={() => navigate(-1)}
          />
          <Text
            as="h1"
            weight="bold"
            classname="text-2xl text-center  capitalize"
          >
            Place your order
          </Text>
        </div>
        <div className="hidden lg:flex items-center justify-between">
          <Text as="h1" weight="bold" classname="text-4xl capitalize">
            Place your order
          </Text>
          {/* search input */}
        </div>
      </div>
      <div className="w-[93%] mt-20 lg:w-[90%] flex justify-between mx-auto">
        <CartCard classname="hidden lg:block" />
        <OrderSummary />
      </div>
      <div className="w-[93%] mt-20 lg:w-[90%] mx-auto">
        <CheckoutForm />
      </div>
      <div className="px-5 w-full flex items-center lg:hidden mt-10">
        <Button
          onclick={() => {
            navigate("/checkout");
          }}
          classname="w-full"
        >
          Checkout
        </Button>
      </div>

      <div className="py-[50px] lg:py-[140px] px-5 lg:px-20 flex flex-col gap-16 lg:gap-56 items-center">
        <MayAlsoLike title="Products you may also like" />
      </div>
    </React.Fragment>
  );
}
