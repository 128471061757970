import React from "react";

import { IoAddOutline } from "react-icons/io5";
import Button from "../../../components/button";
import Table from "../../../components/table";
import { managers_profile_column, managers_profile_data } from "../../../utils/table-utils/admin/managers-profile-table";
import { clx } from "../../../utils/clx";
import useAdminManagerList from "../../../hooks/apis/admin/useAdminManagerList";
import { BsSearch } from "react-icons/bs";
import Input from "../../../components/input";
import ModalWrapper from "../../../components/modals/modal-wrapper";
import AddAdminManager from "../../../components/modals/admin/add-admin-manager";


export default function ManagersProfile() {
    const [search, setSearch] = React.useState("");
    const [isModalOpen, setModalOpen] = React.useState(false);

    const {
        data: managerList,
        isLoading: isManagerLoading,
        isFetching: isManagerFetching,
    } = useAdminManagerList()

    // console.log(managerList?.data)



    const classes = clx(
        "w-[93%] lg:w-[90%] mx-auto lg:py-[10px] flex flex-col space-y-[10px]"
    );
    return (
        <React.Fragment>

            <div className={classes}>
                <Button
                    variant='text'
                    iconLeft={<IoAddOutline />}
                    classname=' w-fit ml-auto px-6 font-medium text-secondary-dark'
                    onclick={() => setModalOpen(true)}
                >
                    Add Manager
                </Button>
                <div className='w-full h-full rounded-[20px] bg-white p-6 flex flex-col space-y-6'>
                    <div className="flex justify-end ml-auto">
                        <Input
                            iconLeft={<BsSearch />}
                            placeholder="Search..."
                            inputClass="py-[6px] px-[15px] rounded-r-0"
                            onchange={({ target }) => setSearch(target.value)}
                        />
                    </div>
                    <Table
                        data={managerList?.data ?? []}
                        columns={managers_profile_column}
                        isLoading={isManagerLoading || isManagerFetching}
                        search={search}

                    />
                </div>
            </div>
            <ModalWrapper
                isOpen={isModalOpen}
                handleModalClose={() => setModalOpen(false)}
            >
                <AddAdminManager />
            </ModalWrapper>
        </React.Fragment>
    );
}