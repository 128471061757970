import React from "react";
import Table from "../../../components/table";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";
import {
  fuel_monitory_column,
  fuel_monitory_data,
} from "../../../utils/table-utils/admin/fuel-monitory-table";

export default function FuelMonitoryPageWrapper() {
  const classes = clx(
    "w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex flex-col space-y-[40px]"
  );
  return (
    <div className={classes}>
      <Text as='h2' weight='semibold' classname='text-[24px]'>
        Fuel Monitoring
      </Text>

      <div className='w-full h-full rounded-[20px] bg-white p-6 flex flex-col space-y-6'>
        <Table data={fuel_monitory_data} columns={fuel_monitory_column} />
      </div>
    </div>
  );
}
