import React, { useCallback, useEffect } from "react";
import Table from "../../../components/table";
import { clx } from "../../../utils/clx";
import Input from "../../../components/input";
import Text from "../../../components/text";
import ModalWrapper from "../../../components/modals/modal-wrapper";
import { BsSearch } from "react-icons/bs";
import useAdminUserList from "../../../hooks/apis/admin/useAdminUserList";
import { useSelectedRows } from "../../../store/admin/configure";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { switch_meter_column, switch_tamper_column } from "../../../utils/table-utils/admin/switch-meter-table";
import SwitchMeterModal from "../../../components/modals/admin/switch-meter";
import useAdminAffiliateInfo from "../../../hooks/apis/admin/useAdminAffiliateInfo";
import DownloadCSV from "../../../components/modals/admin/download-csv";


const SwitchMeter = () => {
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [csvData, setCsvData] = React.useState()
    const [modalType, setModalType] = React.useState("switch");
    const [search, setSearch] = React.useState("");
    const [selectedType, setSelectedType] = React.useState("");
    const [filteredList, setFilteredList] = React.useState([]);
    const [radioState, setRadioState] = React.useState("on");
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate();


    const { selectedRows } = useSelectedRows();
    const authUser = useAuthUser();
    const affiliateUser = useAdminAffiliateInfo();

    const {
        data: userList,
        isLoading: isUserListLoading,
        isFetching: isUserListFetching,
    } = useAdminUserList()
    const loadMeters = useCallback(() => {
        setFilteredList([]);
        userList?.data?.userList?.length && userList?.data?.userList?.map((item) => {
            const { fullname, apartment, energyid, waterid } = item;
            const isEnergy = selectedType.toLowerCase() === "energy";
            if ((isEnergy && energyid.length) || (!isEnergy && waterid.length)) {
                setFilteredList((prev) => [
                    ...prev,
                    {
                        fullname,
                        apartment,
                        meterid: isEnergy ? energyid : waterid,
                        type: selectedType,
                    },
                ]);
            }
        });
    }, [selectedType, userList]);

    useEffect(() => {
        loadMeters()

    }, [loadMeters])

    const handleSelectType = useCallback((e) => {
        setSelectedType(e.target.value)
    }, [])

    useEffect(() => {
        setSelectedType("energy")
    }, []);
    const handleSwitchMeterClick = useCallback(() => {
        if (selectedRows.length === 0) return toast.error("Please select a row")
        else {
            setModalType("switch");
            setModalOpen(true);
        }
    }, [selectedRows]);

    const handleSubmit = () => {
        setLoading(true)
        const selectedRowData = selectedRows.map((item) => item.original.meterid);
        axios.put("https://pm-server.herokuapp.com/api/v2.0/manager/meter-switch", {
            meters: selectedRowData,
            action: radioState,
            prepaid: selectedType.toLowerCase()
        }, {
            headers: {
                Authorization: authUser().authToken,
                "x-api-key": affiliateUser?.apikey,

            },
        }).then((res) => {
            // console.log(res.data.data)
            toast.success(res.data.message)
            setLoading(false)
            setModalType("csv")
            setCsvData(res.data.data)
        }).catch((err) => {
            // console.log(err)
            toast.error(err.response.data.message)
            setLoading(false)
        })
    };

    const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
    return (
        <React.Fragment>

            <div className={classes}>
                <div className="max-w-full h-full rounded-[8px] bg-white p-6 flex flex-col space-y-6 relative">
                    <div className="flex flex-row justify-between items-center">
                        <Text
                            as="p"
                            weight="semibold"
                            classname="lg:text-[16px] text-secondary-dark cursor-pointer hover:underline"
                            onclick={handleSwitchMeterClick}
                        >
                            Switch Meter
                        </Text>
                        <div className="flex justify-end items-center space-x-6">

                            <div className="flex flex-row gap-3 items-center">
                                <Text as="p" weight="semibold">
                                    Meter Type:
                                </Text>
                                <select name="" id=""
                                    value={selectedType}
                                    onChange={handleSelectType}
                                    className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
                                >
                                    <option value="energy">Energy</option>
                                    <option value="water">Water</option>
                                </select>
                            </div>
                            <div className="flex items-center">
                                <Input
                                    iconLeft={<BsSearch />}
                                    placeholder="Search By Meter ID"
                                    inputClass="py-[6px] px-[15px] rounded-r-0"
                                    onchange={({ target }) => setSearch(target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <Table
                        search={search}
                        data={filteredList ?? []}
                        columns={switch_meter_column}
                        isLoading={isUserListLoading || isUserListFetching}
                    />
                </div>
            </div>

            <ModalWrapper
                isOpen={isModalOpen}
                handleModalClose={() => {
                    if (modalType === "csv") {
                        setModalOpen(false)
                        navigate("/manager/configure/user-management")
                    }
                    else setModalOpen(false)
                }}>
                {modalType === "switch" &&
                    <SwitchMeterModal onButtonClick={handleSubmit} radioState={radioState} setRadioState={setRadioState} loading={loading} />}
                {
                    modalType === "csv" && <DownloadCSV screen="switch" data={csvData} title="Switch Response" />
                }
            </ModalWrapper>
        </React.Fragment>
    );
};

export default SwitchMeter;
