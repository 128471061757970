import React from "react";
import {
  RequireAuth,
  useAuthHeader,
  useAuthUser,
  useIsAuthenticated,
} from "react-auth-kit";
import Text from "../../../components/text";
import useUserInfo from "../../../hooks/apis/user/useUserInfo";
import UserDashboardLayout from "./user-dashboard-layout";
import { useNavigate } from "react-router-dom";

export default function ProtectedUserDashboard(props) {
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const [isUser, setIsUser] = React.useState(false);
  // console.log("token", authUser().user  );
  // eslint-disable-next-line no-unused-expressions
  // if (authUser().user !== "User") {
  //   return navigate("/login");
  // }

  React.useEffect(() => {
    if (authUser() && authUser().user !== "User") {
      return navigate("/login");
    } else {
      setIsUser(true);
    }
  });
  if (isUser) {
    return (
      <RequireAuth loginPath="/login">
        <UserDashboardLayout {...props} />
      </RequireAuth>
    );
  }
}
