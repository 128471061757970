import Text from "../../components/text";
import { clx } from "../../utils/clx";
import React from "react";

import FAQSection from "./components/faq-section";
import { AboutData } from "../../data";

export default function AboutPageWrapper() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = clx(
    "px-6 lg:px-[100px] flex flex-col gap-[40px] lg:flex-row lg:justify-between items-center py-6 lg:py-[100px] bg-[#FBF5E6] "
  );
  return (
    <div>
      <div className={classes}>
        {/* left side */}
        <div className="flex flex-col max-w-[700px] space-y-4 lg:space-y-8">
          <Text
            as="h1"
            weight="medium"
            classname="
          text-2xl md:text-4xl text-center lg:text-left lg:text-5xl xl:text-6xl leading-8"
          >
            We exist to provide solutions around iot services
          </Text>
          <Text
            as="p"
            weight="normal"
            classname="text-base md:text-[20px] text-[#6C6F7F]
"
          >
            We have made assest monitory Easy!
          </Text>
        </div>
        <div className=" rounded-lg overflow-hidden mr-10">
          <img
            src="/assets/images/woman-on-glasses.png"
            alt="hero"
            className="w-[301px] lg:w-full object-contain"
          />
        </div>
      </div>

      <div className="px-6 lg:px-[100px] lg:py-[100px] py-6 flex flex-col space-y-4 lg:space-y-12 bg-white">
      {AboutData.map((abt, index) => (
        <AboutStory
        Paragraph={abt.paragraph}
          title={abt.title}
          image={abt.image}
          key={index}
          content={abt.content}
        />
      ))}
      </div>
      {/* Faq section */}
      <FAQSection />
    </div>
  );
}
// <Text
// as="h1"
// weight="medium"
// classname="
// text-2xl text-center lg:text-left md:text-4xl lg:text-5xl xl:text-6xl leading-8"
// >
// Get to know us
// </Text>

// <Text
// as="p"
// weight="normal"
// classname="text-sm text-center lg:text-left lg:text-2xl text-[#6C6F7F]"
// >
// Prepaidmeter.ng is here to help individuals, businesses and organization
// that face challenges in getting their actual consumption rate of their
// assets, so Prepaidmeter.ng is hereby solving this problem in easy and
// efficient ways. With Prepaidmeter.ng, asset monitory is at your finger-
// tips
// </Text>
function AboutStory({ Paragraph, title, image ,content}) {
  // console.log(Array.isArray(content))
  return (
    <section
      className="flex gap-5 xl:gap-[128px] justify-center aos-init aos-animate space-y-5"
      data-aos="fade-up"
    >
      <div className="p-23 gap-3 flex flex-col items-center aos-init aos-animate">
        <div className="bg-secondary-light h-8 w-8 xl:h-[60px] xl:w-[60px] customPing duration-700 rounded-full flex justify-center items-center">
          <div className="bg-secondary-dark h-4 w-4 rounded-full xl:h-[30px] xl:w-[30px] animate-ping"></div>
        </div>
        <div className="w-[1px] flex-1 bg-primary-light"></div>
      </div>
      <div
        className="w-full md:max-w-[500px] lg:max-w-[800px] pr-4 aos-init aos-animate"
        data-aos="fade-up"
      >
        <Text
          as="h3"
          classname="font-semibold text-md md:text-lxl lg:text-2xl mb-5"
        >
          {title}
        </Text>
        <Text
          as="p"
          classname="text-sm  md:text-lg lg:text-xl leading-8 py-3 "
        >
          {Paragraph}
        </Text>
         {
          content && Array.isArray(content) && content.length > 0 && content.map((item,index)=>(
            <ul className="leading-10">
              {
                Object.entries(item).map(([key,value])=>(
                  <li className="text-sm  md:text-lg lg:text-xl leading-8 py-3 "><span className="font-bold">{key}</span> {value}</li>
                ))
              }
            </ul>
          ))
         }
           
     {image !== undefined && (
      <div
      className="mt-[30px] xl:mt-[60px] rounded-lg overflow-hidden aos-init aos-animate"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <img src={image} alt="" className="w-full h-72" />
    </div>

     )}
      </div>
    </section>
  );
}
 // {content && Array.isArray(content) && content.length > 0 && (
        //   <ul className="list-disc">
        //     {content.map((item, index) => (
        //       <li key={index}>
        //         {Object.entries(item).map(([key, value]) => (
        //           <span key={key}>
        //             <span>{key}</span>: {value}
        //           </span>
        //         ))}
        //       </li>
        //     ))}
        //   </ul>
        // )}
// {
//   content && Array.isArray(content[0]).map(([key,value])=>())
// }
