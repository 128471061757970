import { useState } from "react";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";
import Button from "../../../components/button";
import { BsDash, BsHandIndex, BsPlus } from "react-icons/bs";
import useGetFaq from "../../../hooks/apis/useGetFaq";

export default function FAQSection() {
  const { data, isLoading, isFetching } = useGetFaq("user");
  const classes = clx(
    "px-6 lg:px-[140px] flex flex-col lg:flex-row justify-between py-6 lg:py-[10px] bg-[#ffff] overflow-hidden"
  );

  return (
    <div className={classes} data-aos="fade-up">
      <div className="lg:max-w-[50%] flex space-x-5 lg:space-y-10">
        <Text
          as="h1"
          weight="semibold"
          classname="text-2xl lg:text-[64px] text-left leading-[64px]"
        >
          Frequenty Asked Questions
        </Text>
      </div>

      <div className="lg:max-w-[50%] w-full flex flex-col space-y-12">
        {data?.data?.prepaid.map((item,index) => (
          <FAQCard key={index} title={item.title} description={item.body} />
        ))}
      </div>
    </div>
  );
}

export function FAQCard({ title, description }) {
  const [show, setShow] = useState(false);

  return (
    <div className="flex flex-col space-y-6">
      <div className="flex  justify-between items-center">
        <Text
          as="h1"
          weight="semibold"
          classname="text-[16px] lg:text-xl text-left "
        >
          {title}
        </Text>
        {show ? (
          <BsDash
            onClick={() => setShow((prev) => !prev)}
            className="w-6 h-6 cursor-pointer"
          />
        ) : (
          <BsPlus
            onClick={() => setShow((prev) => !prev)}
            className="w-6 h-6 cursor-pointer"
          />
        )}
      </div>
      {/* body */}

      {show && (
        <Text
          as="p"
          weight="normal"
          classname="text-sm text-left text-[#6C6F7F]"
        >
          {description}
        </Text>
      )}
    </div>
  );
}
