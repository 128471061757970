import React from "react";
import Text from "../../text";
import Input from "../../input";
import Button from "../../button";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAdminAffiliates from "../../../store/admin/affiliates";
import useAdminRank from "../../../store/admin/adminRank";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { FaEnvelope, FaUserAlt } from "react-icons/fa";

export default function AddAdminManager() {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [tag, setTag] = React.useState("");
  const [role, setRole] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { affiliates } = useAdminAffiliates();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const { adminRank } = useAdminRank()

  const formValues = {
    fullname: "",
    email: "",
  };

  const validationSchema = Yup.object({
    fullname: Yup.string().required("Fullname is Required"),
    email: Yup.string().required("Email is required").email("Invalid Email"),

  });

  const onSubmit = (values) => {
    if (tag === "") {
      toast.error("Select a Branch");
      return;
    } else if (role === "") {
      toast.error("Select a Role");
      return;
    }
    setIsLoading(true)
    setValidationAttempt(false);
    axios.put("https://pm-server.herokuapp.com/api/v2.0/manager/actions?query=add", {
      ...values,
      tag: tag,
      role: role,
    }, {
      headers: {
        Authorization: authUser().authToken,
      },
    }).then(res => {
      // console.log(res)
      setIsLoading(false)
      navigate('/manager/configure/admin-management')
      toast.success(res.data.message)
    }).catch(err => {
      // console.log(err)
      setIsLoading(false)
      toast.error(err.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <Text
          as="h3"
          weight="bold"
          classname="text-[24px] lg:text-[30px] text-center"
        >
          Add Manager
        </Text>
        <div className="my-6 flex flex-col space-y-6">
          <Input
            iconLeft={<FaUserAlt />}
            label="Fullname:"
            placeholder='John Doe'
            type="text"
            name="fullname"
            value={formik.values.fullname}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.fullname}
          />

          <Input
            iconLeft={<FaEnvelope />}
            label="Email:"
            placeholder="test@gmail.com"
            type="email"
            name="email"
            value={formik.values.email}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.email}
          />
          <select name="tag"
            value={tag}
            required
            onChange={(e) => setTag(e.target.value)}
            id=""
            className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
          >
            <option value="">Select Branch</option>
            {Object.keys(affiliates)?.map((affiliate, index) => (
              <option key={index} value={affiliate}>{affiliate}</option>
            ))}
          </select>

          <select
            required
            name="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
          >
            <option value="">Select Role</option>
        {adminRank ===5 && <option value="agent">
              Agent
            </option>}
            <option value="administrator">
              Administrator
            </option>
            <option value="tenant">Tenant</option>
          </select>

        </div>
        <Button isLoading={isLoading}
          onclick={() => setValidationAttempt(true)}
          buttonType="submit" classname="w-full">
          Add
        </Button>
      </div>
    </form>
  );
}
