import { useFormik } from "formik";
import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import Button from "../../components/button";
import Input from "../../components/input";
import Text from "../../components/text";
import AuthLayout from "../../layouts/auth-layout";
import { clx } from "../../utils/clx";

export default function FuelIDPageWrapper() {
  return (
    <AuthLayout>
      <div>
        <div className='py-6'>
          <Text
            as='h1'
            classname='font-[600] text-[54px] text-primary-dark text-center'
          >
            Fuel Monitoring Id
          </Text>
          <Text as='p' classname='text-[20px] text-center'>
            Kindly input your fuel monitoring service id
          </Text>
        </div>

        <div className='bg-[#F8F8FF] border-[1px] rounded-[16px] w-[550px] py-[50px] px-[70px]'>
          <FuelIDPageWrapper.Form />
        </div>
      </div>
    </AuthLayout>
  );
}

export function Form({}) {
  const formValues = {
    serviceId: "",
  };

  const onSubmit = (values) => {
    return {values}
  };

  const formik = useFormik({ initialValues: formValues, onSubmit });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='w-full mx-auto'>
        <Input
          iconLeft={<img src='/assets/icons/meter-id-icon.png' alt='icon' />}
          placeholder='service id'
          name='serviceId'
          onchange={formik.handleChange}
          value={formik.values.serviceId}
        />
        <Button buttonType='submit' classname='w-full mt-[50px]'>
          Verify
        </Button>
      </div>
    </form>
  );
}

FuelIDPageWrapper.Form = Form;
