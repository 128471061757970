import React from "react";
import { clx } from "../../utils/clx";
import Text from "../../components/text";
import Button from "../../components/button";
import * as IMG from "./../../images";
import { FaGift } from "react-icons/fa";
import { IoMegaphone } from "react-icons/io5";
import { RiArrowRightUpFill } from "react-icons/ri";

const Referrer = () => {
  const classes = clx("w-[93%] lg:w-[90%] flex flex-col mx-auto pt-8");

  return (
    <section className={classes}>
      <div className="mx-auto ">
        <Text
          as="h3"
          weight="bold"
          classname="font-semibold text-[26px] md:text-3xl lg:text-5xl text-center leading-[39px] md:leading-10 lg:leading-[72px] xl:max-w-[927px] xl:mx-auto"
        >
          Unveil the Next Era in Estate Management for Your Community!
        </Text>
        <Button
          classname="text-center mx-auto w-full md:px-14 md:w-fit my-8"
          href="/referer_signup"
        >
          Sign up as Referral
        </Button>
        <img src={IMG.referral} className="w-full h-[300px]  my-8" alt="" />
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex-1">
            <Text
              as="h3"
              weight="bold"
              classname="font-sora font-semibold text-[26px] md:text-xl lg:text-2xl xl:text-3xl"
            >
              Exploring the Mechanics of the Iobotech Referral Program
            </Text>
            <RefereraDetails
              icon={
                <IoMegaphone className="text-secondary-dark text-2xl xl:text-3xl" />
              }
              title="Promote Iobotech App"
              paragraph='Join as a referral and obtain an exclusive referral link. Share this link with your community or estate, encouraging them to experience our services. Alternatively, utilize the "Get Started" button to refer an entire community.'
            />
            <RefereraDetails
              icon={
                <FaGift className="text-secondary-dark text-2xl xl:text-3xl" />
              }
              title="Get your rewards!"
              paragraph="Upon your referred individuals signing up and successfully onboarding to a paid plan"
            />
          </div>
          <img
            src={IMG.Customer}
            className="w-full flex-1 md:w-1/2 mt-3 md:mt-0"
            alt=""
          />
        </div>
        <div className="flex flex-col sm:flex-row  justify-center gap-4 mt-5 sm:mt-3">
          <Button
            classname="text-center  w-full md:px-14 sm:w-fit my-1 sm:my-8"
            href="/marketplace"
          >
            Get A Meter
          </Button>
          <Button
            variant="outlined"
            classname="text-center  w-full md:px-14 sm:w-fit my-1 sm:my-8 mb-4 sm:mb-0"
            href="/referer_signup"
            iconRight={<RiArrowRightUpFill />}
          >
            Sign up as Referral
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Referrer;

function RefereraDetails({ icon, title, paragraph }) {
  return (
    <section className="">
      <div className="flex gap-6 items-center py-3">
        <h3>{icon}</h3>
        <Text as="h6" classname="text-sm font-bold lg:text-xl xl:text38xl">
          {title}
        </Text>
      </div>
      <Text as="p" classname="">
        {paragraph}
      </Text>
    </section>
  );
}
