import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { AiOutlineBarcode } from "react-icons/ai";
import Button from "../../components/button";
import Input from "../../components/input";
import OTP from "../../components/otp";
import Text from "../../components/text";
import AuthLayout from "../../layouts/auth-layout";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import useBuyElectricityData from "../../store/static/buy-electricity";

export default function AuthmeteridPageWrapper() {
  return (
    <AuthLayout>
      <div className="w-full lg:flex flex-col lg:space-y-8 items-center py-[15px] lg:py-0">
        <div>
          <Text
            as="p"
            classname=" font-[600] text-[24px] lg:text-[54px] text-primary-dark text-center"
          >
            Meter Number
          </Text>
          <Text
            as="p"
            weight="medium"
            classname="text-[14px] lg:text-[20px] text-primary-dark lg:text-center"
          >
            Enter your meter number to sign up
          </Text>
        </div>
        <div className="lg:bg-[#F8F8FF] lg:border-[1px] rounded-[16px] w-full lg:w-[550px] py-[20px] lg:py-[50px] px-[0px] lg:px-[70px]">
          <AuthmeteridPageWrapper.Form />
        </div>
      </div>
    </AuthLayout>
  );
}

export function Form({}) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const { setElectricityInfo } = useBuyElectricityData();
  const navigate = useNavigate();

  const formValues = {
    meterid: "",
  };

  const validationSchema = Yup.object({
    meterid: Yup.string().required("Please enter your meterid"),
  });

  const onSubmit = (values) => {
    setValidationAttempt(false);
    setLoading(true);
    axios
      .get(
        `https://api-pm-e1ea777feac4.herokuapp.com/search-meter?meterid=${values.meterid}`
      )
      .then((res) => {
        // console.log(res.data.data);
        const isDisco = res?.data?.data.host === "disco";
        // console.log(res.data);
        // console.log(res.data)
        if (isDisco) {
          setElectricityInfo(res.data.data);
          toast.success(res.data.message);
          navigate(`/buy-electricity/${values.meterid}`);
        } else {
          navigate(
            `/signup?auth=${values.meterid}&&auth-data=${JSON.stringify({
              ...res?.data?.data,
            })}`
          );
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        if (err.response.data.message === "Meter already Exist.!!!") {
          navigate("/login");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col items-center space-y-6">
        <div className="w-full mb-[30px]">
          <Input
            type="text"
            iconLeft={
              <AiOutlineBarcode className="text-[#9799A5] text-[20px]" />
            }
            placeholder="Meter Number"
            classname="w-full"
            name="meterid"
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.meterid}
            value={formik.values.meterid}
          />
        </div>
        <Button
          buttonType="submit"
          classname="w-full"
          onclick={() => setValidationAttempt(true)}
          isLoading={isLoading}
        >
          Sign up
        </Button>
        <Text as="p" classname="text-center">
          Already have an account?
          <Link to="/login">
            <span className="text-secondary-dark">Login</span>
          </Link>
        </Text>
      </div>
    </form>
  );
}

AuthmeteridPageWrapper.Form = Form;
