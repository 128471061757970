import React from "react";
import { clx } from "../utils/clx";
import { getFormattedPrice } from "../utils/getFormattedPrice";
import Text from "./text";

export default function TOBalanceCard({
  variant = "secondary",
  platform,
  unit,
  price,
  title,
  noPrice,
  classname,
  children,
}) {
  const classes = clx(
    "group rounded-[8px] w-full h-fit p-4 lg:p-6 cursor-pointer",
    variant === "primary"
      ? "bg-[#E6E6FB] hover:bg-[#1A1882]"
      : variant === "secondary"
      ? "bg-[#FBF5E6] hover:bg-secondary-dark"
      : "bg-[#EBEDF4] hover:bg-[#6C6F7F]",
    classname
  );

  return (
    <div className={classes}>
      <div className="flex space-x-4 items-start">
        <div
          className={`w-[32px] h-[32px] md:w-[45px] md:h-[45px] rounded-[4px] flex items-center justify-center ${
            variant === "primary"
              ? "bg-[#A4A7FF]/30 group-hover:bg-[#071252]"
              : variant === "secondary"
              ? "bg-[#EAC36B]/30 group-hover:bg-[#865D00]"
              : "bg-[#A4A7FF]/30 group-hover:bg-[#010415]/30"
          }`}
        >
          <img src="/assets/icons/total-credit.png" alt="" /> 
        </div>
        <div className="py-0">
          <Text
            as="p"
            classname={`text-[11px] md:text-[12px] ${
              variant === "primary" || variant === "tertiary"
                ? "text-[#6C6F7F] group-hover:text-white"
                : "text-[#6C6F7F] group-hover:text-black"
            }`}
          >
            {title ? title : "Available balance"}
          </Text>
          <div className="hidden pt-4 md:flex items-center space-x-3">
            {/* <Text
              as="h5"
              weight="bold"
              classname={`lg:text-[16px] xl:text-[18px] flex items-center ${
                variant === "primary" || variant === "tertiary"
                  ? "text-black group-hover:text-white"
                  : "text-black"
              }`}
            >
              {unit}{" "}
              <span className="font-semibold lg:text-[16px] text-[18px] px-1">
                {platform === "Prepaid Energy" ? "kwh" : "L"}
              </span>
            </Text> */}
            {/* {!noPrice && ( */}
              <div className="text-[#6C6F7F] group-hover:text-white px-3">
                <Text as="span">
                  ₦{price ? getFormattedPrice(price) : "0"} 
                </Text>
              </div>
            {/* )} */}
          </div>
        </div>
      </div>
      <div className="lg:hidden pt-4 flex flex-col items-center space-x-3">
        {/* <Text
          as="h5"
          weight="bold"
          classname={`lg:text-[16px] xl:text-[18px] flex items-center ${
            variant === "primary" || variant === "tertiary"
              ? "text-black group-hover:text-white"
              : "text-black"
          }`}
        >
          {unit}{" "}
          <span className="font-normal lg:text-[16px] text-[18px]">
            {platform === "IoT" ? "L" : "kwh"}
          </span>
        </Text> */}
        {!noPrice && (
          <div className="text-primary-dark bg-white py-[4px] px-[8px] rounded-[4px]">
            <Text as="span">₦{getFormattedPrice(price)}</Text>
          </div>
        )}
      </div>
    </div>
  );
}
