import React from "react";
import Table from "../../../components/table";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";
import {
  customer_column,
  customer_data,
} from "../../../utils/table-utils/admin/customer-table";
import useAdminVendInfo from "../../../hooks/apis/admin/useAdminVendInfo";
import { recent_transactions_column } from "../../../utils/table-utils/admin/dashboard-recent-transactions";

export default function AllDashboardTransactionsPage({ classname, children }) {
  const {
    data: VendInfo,
    isLoading: isVendInfoLoading,
    isFetching: isVendInfoFetching,
  } = useAdminVendInfo({ key: "TotalVendInfo" });
  const [search, setSearch] = React.useState("");

  const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
  return (
    <div className={classes}>
      <Text as="p" weight="bold" classname="text-[28px]">
        All Transactions
      </Text>

      <div className="my-[40px] bg-white rounded-[16px] p-6">
        <Table
          search={search}
          data={VendInfo?.data ?? []}
          columns={recent_transactions_column}
          isLoading={isVendInfoLoading || isVendInfoFetching}
        />
      </div>
    </div>
  );
}
