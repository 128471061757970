import Text from "../../../components/text";
import React from "react";
import AffiliiateServicesInsightsActions from "./components/affiliate-services-insights-actions";

export const affiliiate_services_insights_data = Array.from({ length: 5 }).map((data, i) => ({
  _id: i + 1,
  name: `Oloko Opeyemi ${i + 1}`,
  amount: `N10,000`,
  unit: `%`
}));

export const affiliiate_services_insights_columns = [
  {
    // id: "1",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Name
      </Text>
    ),
    Cell: ({ value, row }) => (
      <Text as="p" classname="text-left text-[16px] capitalize">
        {value}
      </Text>
    ),
    accessor: "name",
  },
  {
    // id: "2",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Value
      </Text>
    ),
    //     Cell: ({ value, row }) => <Text as="p">
    //     {value}
    // </Text>,
    accessor: "value",
  },
  {
    id: "3",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Actions
      </Text>
    ),
    accessor: "path",
    Cell: AffiliiateServicesInsightsActions,
  },
];
