import Text from "../../../../components/text";
import {
  Amount,
  Token,
  ReferenceID,
  Unit,
  TableIcon,
} from "../energy-transaction-history-utils";

export const EnergyTransactionHistoryColumns = [
  {
    id: "_",
    Header: () => null,
    Cell: TableIcon,
    accessor: "",
  },
  {
    id: "reference",
    Header: (
      <Text
        as='p'
        classname='text-left text-[16px] lg:text-[18px] text-[#2C3048]'
      >
        Reference Id
      </Text>
    ),
    Cell: ReferenceID,
    accessor: "refr",
  },
  {
    id: "amount",
    Header: (
      <Text
        as='p'
        classname='text-left text-[16px] lg:text-[18px] text-[#2C3048]'
      >
        Amount
      </Text>
    ),
    Cell: Amount,
    accessor: "Total_paid",
  },
  {
    id: "unit",
    Header: (
      <Text
        as='p'
        classname='text-left text-[16px] lg:text-[18px] text-[#2C3048]'
      >
        Unit
      </Text>
    ),
    Cell: Unit,
    accessor: "Total_unit",
  },
  {
    id: "token",
    Header: (
      <Text
        as='p'
        classname='text-[16px] lg:text-[18px] text-[#2C3048]'
      >
        Token
      </Text>
    ),
    Cell: Token,
    accessor: "Token",
  },
];
