import React, { useCallback } from "react"
import { clx } from "../../../utils/clx";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Text from "../../../components/text";
import Radio from "../../../components/radio";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CgGitBranch } from "react-icons/cg";
import { BiRename } from "react-icons/bi";
import { RiAttachment2 } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import useAffiliateList from "../../../hooks/apis/admin/useAllAffiliateList";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineBarcode } from "react-icons/ai";

export default function AddAffiliates() {
    const [validationAttempt, setValidationAttempt] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [radioState, setRadioState] = React.useState("existing");
    const [affiliateName, setAffiliateName] = React.useState("")
    const [totalBranch, setTotalBranch] = React.useState(null)
    const { data: affiliateList, isLoading: fetching } = useAffiliateList()
    const navigate = useNavigate();
    const authUser = useAuthUser();
    const handlefilter = useCallback((e) => {
        setRadioState(e.target.value)
    }, [])

    const formValues = {
        name: "",
        email: "",
        branch: "",
        suffix: "",
        _rootKey: "",

    }
    const validationSchema = Yup.object({
        name: Yup.string()
            .required(radioState === "new" ? "Name is required" : null)
            .matches(/^\S*$/, "Spaces are not allowed in Name"),
        email: Yup.string()
            .required(radioState === "new" ? "Email is required" : null)
            .email("Invalid Email Address")
            .matches(/^\S*$/, "Spaces are not allowed in Email"),
        branch: Yup.string()
            .required("Branch is required")
            .matches(/^\S*$/, "Spaces are not allowed in Branch"),
        suffix: Yup.number()
            .integer()
            .required("Suffix is required"),

        _rootKey: Yup.string().required("Root Key is required"),

    });


    const onSubmit = (values) => {
        setIsLoading(true)
        setValidationAttempt(false);
        axios.post("https://pm-server.herokuapp.com/api/v2.0/affiliate/register", radioState === "new" ? {
            ...values,
        } : {
            name: affiliateName,
            branch: values.branch,
            suffix: values.suffix,
        }, {
            headers: {
                Authorization: authUser().authToken,
                _rootKey: formik.values._rootKey,

            }
        }).then((res) => {
            setIsLoading(false)
            toast.success(res.data.message)
            navigate("/manager/configure/affiliates")
        }).catch((err) => {
            setIsLoading(false)
            toast.error(err.response.data.message)
            // console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const formik = useFormik({
        initialValues: formValues,
        validationSchema,
        onSubmit,
        validateOnBlur: true,
        validateOnChange: validationAttempt,
    });
    const classes = clx("w-[93%] lg:w-[90%] flex items-center flex-col mx-auto lg:py-[40px]");
    return (
        <div className={classes}>
            <div>
                <Text as='h2' weight='bold' classname='text-[24px] text-center text-primary-light'>
                    Add Affiliates
                </Text>
            </div>
            {/* radio state */}
            <form onSubmit={formik.handleSubmit} className="flex justify-center py-6 mt-7 px-6 lg:px-12  flex-col w-[90%] lg:min-w-[400px] lg:max-w-fit rounded-[20px] bg-white">
                <>
                    <div className="flex justify-end gap-4">
                        <Radio
                            name='filter-type'
                            label='Existing'
                            checked={radioState === "existing"}
                            onchange={handlefilter}
                            onclick={handlefilter}
                            classname='text-[16px]'
                            value="existing"
                            
                        />
                        <Radio
                            name='filter-type'
                            label='New'
                            classname='text-[16px]'
                            value="new"
                            checked={radioState === "new"}
                            onchange={handlefilter}
                            onclick={handlefilter}
                        />
                    </div>
                    {radioState === "existing" && (
                        <>
                            <div className="flex flex-col justify-start space-y-3 mb-6">
                                <Text as='p' weight='bold' classname='text-[16px] text-left'>
                                    Name of Affiliate:
                                </Text>
                                <select
                                    value={affiliateName}
                                    onChange={(e) => {
                                        setAffiliateName(e.target.value)
                                        const branchIndex = affiliateList?.data.findIndex(({ name }) => name === e.target.value)
                                        setTotalBranch(affiliateList?.data[branchIndex]?.['total_branch'])
                                    }
                                    }
                                    className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px] my-6"
                                    required
                                >
                                    {
                                        fetching ? (
                                            <option value="">Loading affiliates...</option>
                                        ) : (
                                            <>
                                                <option value="">Select Affiliate</option>
                                                {affiliateList?.data?.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.name}>{item.name}</option>
                                                    )
                                                })}
                                            </>
                                        )
                                    }
                                </select>
                                {totalBranch && <Text as="p">
                                    {`This Affiliate has ${totalBranch} branch(es)`}
                                </Text>}
                            </div>
                        </>
                    )}
                    {radioState === "new" && (
                        <Input

                            iconLeft={<BiRename />}
                            label="Name of Affiliate:"
                            placeholder="Enter name of affiliate"
                            type="text"
                            name="name"
                            onchange={(e) => formik.handleChange(e)}
                            onblur={formik.handleBlur}
                            value={formik.values.name}
                            errorMessage={formik.errors.name}
                        />
                    )}
                    <Input
                        iconLeft={<CgGitBranch />}
                        label="Branch Name:"
                        placeholder="Enter branch name"
                        type="text"
                        name="branch"
                        onchange={(e) => formik.handleChange(e)}
                        onblur={formik.handleBlur}
                        value={formik.values.branch}
                        errorMessage={formik.errors.branch}
                    />
                    <Input
                        iconLeft={<RiAttachment2 />}
                        label="suffix:"
                        placeholder="Enter suffix"
                        type="number"
                        name="suffix"
                        onchange={(e) => formik.handleChange(e)}
                        value={formik.values.suffix}
                        onblur={formik.handleBlur}
                        errorMessage={formik.errors.suffix}
                    />
                    {radioState === "new" && (
                        <Input
                            iconLeft={<HiOutlineMail />}
                            label="Email:"
                            placeholder="Enter email"
                            type="email"
                            name="email"
                            onchange={(e) => formik.handleChange(e)}
                            onblur={formik.handleBlur}
                            value={formik.values.email}
                            errorMessage={formik.errors.email}
                        />
                    )}
                    <Input
                        iconLeft={<AiOutlineBarcode />}
                        label="Root Key:"
                        placeholder="Enter Root Key"
                        type="text"
                        name="_rootKey"
                        value={formik.values._rootKey}
                        onchange={(e) => formik.handleChange(e)}
                        onblur={formik.handleBlur}
                        errorMessage={formik.errors._rootKey}
                    />
                    <Button classname="mt-6"
                        buttonType="submit"
                        isLoading={isLoading}
                    >
                        Add
                    </Button>
                </>
            </form>
        </div>
    )
}