import React, { useMemo } from "react";
import { useFormik } from "formik";
import { TbCurrencyNaira, TbPercentage } from "react-icons/tb";
import * as Yup from "yup";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";


import Text from "../../../text";
import Input from "../../../input";
import Button from "../../../button";
import useAdminAffiliates from "../../../../store/admin/affiliates";

export default function SetInstantCharges({ data, btnType, type }) {
    const [validationAttempt, setValidationAttempt] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isPercentage, setIsPercentage] = React.useState(data?.format === "percentage" ? true : false);
    const { selectedAffiliate } = useAdminAffiliates();
    const navigate = useNavigate();
    const authUser = useAuthUser();

    const formValues = {
        charges_name: data?.name || "",
        charges_amount: data?.value || "",
        cap_at: data?.cap_at || "",
        cap_to: data?.cap_to || "",
    };

    const onSubmit = (values) => {
        setIsLoading(true)
        setValidationAttempt(false);
        // console.log(values)
        axios.post("https://pm-server.herokuapp.com/api/v2.0/settlement?query=set-instant-charges", {
            ...values,
            tag: selectedAffiliate,
            charges_format: isPercentage ? "percentage" : "naira",
        }, {
            headers: {
                Authorization: authUser().authToken,
            }
        }).then(res => {
            toast.success(res.data.message)
            navigate('/manager/configure/affiliates')
        }).catch(err => {
            toast.error(err.response.data.message)
        }).finally(() => {
            setIsLoading(false)
            formik.resetForm()
        })
    };


    const validationSchema = Yup.object({
        charges_name: Yup.string().required("Name is Required"),
        charges_amount: isPercentage
            ? Yup.string().required("Amount is required").matches(
                /^(100(\.0{1,2})?|[1-9]?[0-9](\.[0-9]{1,2})?)$/,
                "Percentage must be between 1 and 100 with up to 2 decimal places"
            )
            : Yup.number().required("Amount is required"),
        cap_at: isPercentage
            && Yup.number()
                .integer("Cap Amount must be a whole number")
                .required("Cap Amount is required"),
        cap_to: isPercentage
            && Yup.number()
                .integer("Cap Max must be a whole number")
                .required("Cap Max is required")
                .test("cap_to", "Cap To must be less than or equal to Cap At", function (value) {
                    const capAt = this.resolve(Yup.ref("cap_at"));
                    return value <= capAt;
                }),
    });


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formValues,
        onSubmit,
        validationSchema,
        validateOnBlur: false,
        validateOnChange: validationAttempt,
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            <div>
                <Text
                    as="h3"
                    weight="bold"
                    classname="text-[24px] lg:text-[30px] text-center"
                >
                    {type}
                </Text>
                <div className="my-2 flex flex-col space-y-2">
                    <Input
                        label="Charge Name:"
                        placeholder='Electricity Charges'
                        type="text"
                        name="charges_name"
                        defaultValue={data?.name || ""}
                        value={formik.values.charges_name}
                        onchange={(e) => formik.handleChange(e)}
                        onblur={formik.handleBlur}
                        errorMessage={formik.errors.charges_name}
                    />
                    <div className="flex flex-col space-y-2">
                        <Text as="p" weight="medium">
                            Charge Format:
                        </Text>

                        <select name="" id=""
                            value={isPercentage ? "percentage" : "naira"}
                            onChange={(e) => {
                                if (e.target.value === "percentage") {
                                    setIsPercentage(true);
                                } else {
                                    setIsPercentage(false);
                                }
                            }}
                            required
                            className="w-full h-[40px] bg-[#E6E6E9] border-none rounded-md capitalize outline-none"

                        >
                            <option value="percentage">Percentage</option>
                            <option value="naira">Naira</option>
                        </select>
                    </div>

                    {isPercentage ? (
                        <>
                            <Input
                                iconLeft={<TbPercentage />}
                                label="Charge Amount:"
                                placeholder='100'
                                type="number"
                                defaultValue={data?.value || ""}
                                name="charges_amount"
                                value={formik.values.charges_amount}
                                onchange={(e) => formik.handleChange(e)}
                                onblur={formik.handleBlur}
                                errorMessage={formik.errors.charges_amount}
                            />
                            <Input
                                label="Cap At:"
                                type="number"
                                name="cap_at"
                                iconLeft={<TbCurrencyNaira />}
                                defaultValue={data?.cap_at || ""}

                                placeholder="4000"
                                value={formik.values.cap_at}
                                onchange={(e) => formik.handleChange(e)}
                                onblur={formik.handleBlur}
                                errorMessage={formik.errors.cap_at}
                            />
                            <Input
                                label="Cap To:"
                                type="number"
                                iconLeft={<TbCurrencyNaira />}
                                name="cap_to"
                                placeholder="4000"
                                defaultValue={data?.cap_to || ""}
                                value={formik.values.cap_to}
                                onchange={(e) => formik.handleChange(e)}
                                onblur={formik.handleBlur}
                                errorMessage={formik.errors.cap_to}
                            />
                        </>
                    ) : (
                        <Input
                            iconLeft={<TbCurrencyNaira />}
                            label="Charge Amount:"
                            placeholder='4000'
                            type="number"
                            defaultValue={data?.value || ""}
                            name="charges_amount"
                            value={formik.values.charges_amount}
                            onchange={(e) => formik.handleChange(e)}
                            onblur={formik.handleBlur}
                            errorMessage={formik.errors.charges_amount}
                        />
                    )}
                </div>
                <Button isLoading={isLoading}
                    onclick={() => setValidationAttempt(true)}
                    buttonType="submit" classname="w-full">
                    {btnType}
                </Button>
            </div>
        </form>
    );
}
