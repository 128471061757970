import React from "react";
import { clx } from "../../../utils/clx";
import Button from "../../../components/button";
import Text from "../../../components/text";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TextArea from "../../../components/textarea";
import useAdminAffiliates from "../../../store/admin/affiliates";
import UseFetchEndpoint from "../../../hooks/apis/useFetch";
import { BiHelpCircle } from "react-icons/bi";
import ModalWrapper from "../../../components/modals/modal-wrapper";
import DownloadCSV from "../../../components/modals/admin/download-csv";

export default function AddNewMeter() {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [prepaid, setPrepaid] = React.useState("");
  const [role, setRole] = React.useState("");
  const [remote, setRemote] = React.useState("");
  const [model, setModel] = React.useState("");
  const [phase, setPhase] = React.useState("");
  const [csvData, setCsvData] = React.useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = React.useState("");
  const { selectedAffiliate } = useAdminAffiliates();
  const navigate = useNavigate();
  const authUser = useAuthUser();

  const {
    data: manufacturerSlug,
    isFetching,
    refetch,
  } = UseFetchEndpoint({
    method: "get",
    endpoint: `/manager/meter-manufacturers`,
    key: "manufacturer-props",
  });

  React.useEffect(() => {
    refetch();
  }, []);

  const formValues = {
    meters: "",
    apartments: "",
  };
  const onSubmit = (values) => {
    const formatedMeter = values.meters.split(",").map((item) => item.trim());
    const formatedApartment = values.apartments
      .split(",")
      .map((item) => item.trim());
    if (formatedMeter.length !== formatedApartment.length) {
      toast.error("meters and apartments must be equal");
      return;
    }
    const data = {
      meters: formatedMeter,
      apartments: formatedApartment,
      prepaid,
      role,
      remote,
      model,
      phase,
      manufacturer: selectedManufacturer,
      tag: selectedAffiliate,
    };
    setIsLoading(true);
    setValidationAttempt(false);
    axios
      .post(
        "https://pm-server.herokuapp.com/api/v2.0/manager/new-meter",
        {
          ...data,
        },
        {
          headers: {
            Authorization: authUser().authToken,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setModalOpen(true);
        // console.log(res.data.data)
        setCsvData(res.data.data);
      })
      .catch((err) => {
      
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
        setRole("");
        setModel("");
        setRemote("");
        setPhase("");
        setPrepaid("");
        setSelectedManufacturer("");
      });
  };
  const validationSchema = Yup.object({
    meters: Yup.string()
      .required("Meters is required")
      .matches(/^(\d+(,\s*\d+)*)?$/, "Invalid meters format"),
    apartments: Yup.string()
      .required("Apartments is required")
      .matches(/^(\w+(,\s*\w+)*)?$/, "Invalid apartments format"),
  });
  
  
  
  

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    validateOnChange: validationAttempt,
  });
  const classes = clx(
    "w-[93%] lg:w-[90%] flex items-center flex-col mx-auto lg:py-[40px]"
  );
  return (
    <React.Fragment>
      <div className={classes}>
        <div>
          <Text
            as="h2"
            weight="bold"
            classname="text-[24px] text-center text-primary-light"
          >
            Add New Meter(s)
          </Text>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="flex justify-center py-6 mt-5 px-6 lg:px-12  flex-col w-[90%] lg:min-w-[400px] lg:max-w-fit rounded-[20px] bg-white"
        >
          <>
            <TextArea
              label="Meter(s)"
              placeholder="112345, 3245523, "
              inputClass="rounded-[16px] bg-[#F5F5FE]"
              name="meters"
              value={formik.values.meters}
              onchange={(e) => formik.handleChange(e)}
              onblur={formik.handleBlur}
              errorMessage={formik.errors.meters}
            />
            <>
              <TextArea
                label="Apartment(s)"
                placeholder="test, test2"
                inputClass="rounded-[16px] bg-[#F5F5FE]"
                name="apartments"
                value={formik.values.apartments}
                onchange={(e) => formik.handleChange(e)}
                onblur={formik.handleBlur}
                errorMessage={formik.errors.apartments}
              />
              <div className="flex flex-row items-center gap-4 my-2">
                <BiHelpCircle size={20} />
                <Text
                  as="p"
                  weight="bold"
                  classname="text-left text-secondary-dark"
                >
                  Please note that the meters and apartments <br /> must be in
                  the same order
                </Text>
              </div>
            </>
            <>
              <div>
                <select
                  name=""
                  id=""
                  value={prepaid}
                  onChange={(e) => setPrepaid(e.target.value)}
                  required
                  className="w-full h-[40px] bg-[#E6E6E9] border-none rounded-md capitalize outline-none my-2"
                >
                  <option value="">Select Prepaid</option>
                  <option value="energy">Energy</option>
                  <option value="water">Water</option>
                  <option value="gas">Gas</option>
                </select>
              </div>
            </>
            <>
              <div>
                <select
                  name=""
                  id=""
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                  className="w-full h-[40px] bg-[#E6E6E9] border-none rounded-md capitalize outline-none my-2"
                >
                  <option value="">Select Role</option>
                  <option value="user">User</option>
                </select>
              </div>
            </>
            <>
              <div>
                <select
                  name=""
                  id=""
                  value={selectedManufacturer}
                  onChange={(e) => setSelectedManufacturer(e.target.value)}
                  required
                  className="w-full h-[40px] bg-[#E6E6E9] border-none rounded-md capitalize outline-none my-2"
                >
                  {isFetching ? (
                    <option value="">Loading manufacturers...</option>
                  ) : (
                    <>
                      <option value="">Select Manufacturer</option>
                      {Object.keys(manufacturerSlug ?? {}).map((key, index) => {
                        return (
                          <option
                            key={index}
                            value={manufacturerSlug[key].slug}
                          >
                            {manufacturerSlug[key].slug}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
              </div>
            </>
            <>
              {selectedManufacturer && (
                <>
                  {/* Display remote Select */}
                  <select
                    name="remote"
                    id="remote"
                    value={remote}
                    onChange={(e) => setRemote(e.target.value)}
                    required
                    className="w-full h-[40px] bg-[#E6E6E9] border-none rounded-md capitalize outline-none my-2"
                  >
                    <option value="">Select Remote</option>
                    {manufacturerSlug.map((manufacturer) => {
                      if (manufacturer?.slug === selectedManufacturer) {
                        return manufacturer?.remotes?.map((remote, index) => (
                          <option key={index} value={remote}>
                            {remote}
                          </option>
                        ));
                      }
                      return null;
                    })}
                  </select>

                  {/* Display phase Select */}
                  <select
                    name="phase"
                    id="phase"
                    value={phase}
                    onChange={(e) => setPhase(e.target.value)}
                    required
                    className="w-full h-[40px] bg-[#E6E6E9] border-none rounded-md capitalize outline-none my-2"
                  >
                    <option value="">Select phase</option>
                    {manufacturerSlug.map((manufacturer) => {
                      if (manufacturer?.slug === selectedManufacturer) {
                        return manufacturer?.phases?.map((phase, index) => (
                          <option key={index} value={phase}>
                            {phase}
                          </option>
                        ));
                      }
                      return null;
                    })}
                  </select>

                  {/* Display Model Select */}
                  <select
                    name="model"
                    id="model"
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    required
                    className="w-full h-[40px] bg-[#E6E6E9] border-none rounded-md capitalize outline-none my-2"
                  >
                    <option value="">Select Model</option>
                    {manufacturerSlug.map((manufacturer) => {
                      if (manufacturer?.slug === selectedManufacturer) {
                        return manufacturer?.apis?.map((model, index) => (
                          <option key={index} value={model.slug}>
                            {model.name}
                          </option>
                        ));
                      }
                      return null;
                    })}
                  </select>
                </>
              )}
            </>
            <Button classname="mt-6" buttonType="submit" isLoading={isLoading}>
              Add
            </Button>
          </>
        </form>
      </div>

      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => {
          setModalOpen(false);
          navigate("/manager/configure/user-management");
        }}
      >
        <DownloadCSV
          data={csvData}
          screen="newMeter"
          title="New Meter Response"
        />
      </ModalWrapper>
    </React.Fragment>
  );
}
