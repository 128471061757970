import React from "react";
import Text from "../../text";
import Input from "../../input";
import Button from "../../button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { FaEnvelope, FaLock, FaUserAlt } from "react-icons/fa";

export default function EditAdminManager({ data }) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const formValues = {
    fullname: data.fullname,
    email: data.email,
    password: data.password,
  };

  const validationSchema = Yup.object({
    fullname: Yup.string().required("Fullname is Required"),
    email: Yup.string().required("Email is required").email("Invalid Email"),
    password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters"),

  });

  const onSubmit = (values) => {
    const adminid = data.adminid
    setIsLoading(true)
    setValidationAttempt(false);
    axios.put("https://pm-server.herokuapp.com/api/v2.0/manager/actions?query=edit", {
      ...values,
      adminid: adminid
    }, {
      headers: {
        Authorization: authUser().authToken,
      },
    }).then(res => {
      navigate('/manager/configure/admin-management')
      toast.success(res.data.message)
    }).catch(err => {
      toast.error(err.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <Text
          as="h3"
          weight="bold"
          classname="text-[24px] lg:text-[30px] text-center"
        >
          Edit Manager
        </Text>
        <div className="my-6 flex flex-col space-y-6">
          <Input
            iconLeft={<FaUserAlt />}
            label="Fullname:"
            placeholder={data.fullname}
            defaultValue={data.fullname}
            type="text"
            name="fullname"
            value={formik.values.fullname}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.fullname}
          />

          <Input
            iconLeft={<FaEnvelope />}
            label="Email:"
            placeholder={data.email}
            defaultValue={data.email}
            type="email"
            name="email"
            value={formik.values.email}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.email}
          />
          <Input
            iconLeft={<FaLock />}
            label="Password:"
            placeholder={data.password}
            defaultValue={data.password}
            type="password"
            name="password"
            value={formik.values.password}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.password}
          />
        </div>
        <Button isLoading={isLoading}
          onclick={() => setValidationAttempt(true)}
          buttonType="submit" classname="w-full">
          Edit
        </Button>
      </div>
    </form>
  );
}
