import React from "react";
import Text from "../../text";
import Input from "../../input";
import Button from "../../button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { AiOutlineBarcode } from "react-icons/ai";

export default function SendTokenModal({ data, title }) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const authUser = useAuthUser();

  // const meterid = data.map((item) => item.original.meterid)

  const formValues = {
    token: "",
  };

  const validationSchema = Yup.object({
    token: Yup.number().required("Token is required"),
  });
  const onSubmit = (values) => {
    setIsLoading(true);
    const meterid = data.map((item) => item.original.meterid);

    setValidationAttempt(false);
    axios.post("https://pm-server.herokuapp.com/api/v2.0/send-token", {
      ...values,
      meterid: meterid[0],

    }, {
      headers: {
        Authorization: authUser().authToken,
      },
    }).then(res => {
      console.log(res)
      setIsLoading(false)
      navigate('/manager/configure/user-management')
      toast.success(res.data.message)

    }).catch(err => {
      // console.log(err)
      setIsLoading(false)
      toast.error(err.response.data.message)

    }).finally(() => {
      setIsLoading(false)
    })
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <Text
          as="h3"
          weight="bold"
          classname="text-[24px] lg:text-[30px] text-center"
        >
          {title}
        </Text>
        <div className="my-6 flex flex-col space-y-6">
          <Input
            iconLeft={<AiOutlineBarcode />}
            label="Token:"
            placeholder="3957 9908 3891 7336 4307"
            type="number"
            name="token"
            value={formik.values.token}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.token}
          />
        </div>
        <Button buttonType="submit" classname="w-full" isLoading={isLoading}>
          Send Token
        </Button>
      </div>
    </form>
  );
}
