import React from "react";
import { IoMdArrowDropright } from "react-icons/io";
import { clx } from "../../utils/clx";
import Button from "../button";
import Input from "../input";
import Text from "../text";

export default function SelectPaymentOption({
  classname,
  onselect,
  onlyBank,
  onlyWallet,
  useReference,
  useTransfer,
  children,
}) {
  const classes = clx("relative flex flex-col", classname);
  return (
    <div className={classes}>
      <Text
        as="h3"
        weight="bold"
        classname="text-[24px] lg:text-[30px] text-center"
      >
        Payment Method
      </Text>
      <div className="flex flex-col space-y-6 my-[30px]">
        {useTransfer && (
          <div
            className="cursor-pointer w-full rounded-[20px] border-2 p-4 lg:p-4 px-6 flex items-center justify-between"
            onClick={() => onselect("transfer")}
          >
            <div className="flex items-center space-x-2">
              <div className="p-2 flex items-center justify-center rounded-full bg-[#F5F5FE]">
                <img src="/assets/icons/bank.png" alt="bank-icon" />
              </div>
              <Text as="p" classname="text-[18px] lg:text-[20px]">
                Virtual Account
              </Text>
            </div>
            <IoMdArrowDropright className="text-[30px] text-[#181818]/50" />
          </div>
        )}
        {!onlyBank && (
          <div
            className="cursor-pointer w-full rounded-[20px] border-2 p-4 lg:p-4 px-6 flex items-center justify-between"
            onClick={() => onselect("wallet")}
          >
            <div className="flex items-center space-x-2">
              <div className="p-2 flex items-center justify-center rounded-full bg-[#F5F5FE]">
                <img src="/assets/icons/wallet.png" alt="wallet-icon" />
              </div>
              <Text as="p" classname="text-[18px] lg:text-[20px]">
                Wallet
              </Text>
            </div>
            <IoMdArrowDropright className="text-[30px] text-[#181818]/50" />
          </div>
        )}
        {!onlyWallet && (
          <div
            className="cursor-pointer w-full rounded-[20px] border-2 p-4 lg:p-4 px-6 flex items-center justify-between"
            onClick={() => onselect("bank")}
          >
            <div className="flex items-center space-x-2">
              <div className="p-2 flex items-center justify-center rounded-full bg-[#F5F5FE]">
                <img src="/assets/icons/bank.png" alt="bank-icon" />
              </div>
              <Text as="p" classname="text-[18px] lg:text-[20px]">
                Bank
              </Text>
            </div>
            <IoMdArrowDropright className="text-[30px] text-[#181818]/50" />
          </div>
        )}

        {useReference && (
          <div
            className="cursor-pointer w-full rounded-[20px] border-2 p-4 lg:p-4 px-6 flex items-center justify-between"
            onClick={() => onselect("reference")}
          >
            <div className="flex items-center space-x-2">
              <div className="p-2 flex items-center justify-center rounded-full bg-[#F5F5FE]">
                <img src="/assets/icons/bank.png" alt="bank-icon" />
              </div>
              <Text as="p" classname="text-[18px] lg:text-[20px]">
                Reference
              </Text>
            </div>
            <IoMdArrowDropright className="text-[30px] text-[#181818]/50" />
          </div>
        )}
      </div>
    </div>
  );
}
