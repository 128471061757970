import React from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
// import { clx } from "../../../../utils/clx";
// import AffiliateEnergyStatisticChart from "../../components/charts/energy-statistics-chart";
import { clx } from "../../../../utils/clx";
import AffiliateEnergyStatisticChart from "../../components/charts/energy-statistics-chart";

export default function AffiliateBarChargesChartStatistics() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();

  React.useEffect(() => {
    setStartDate(searchParams.get("start-date"));
    setEndDate(searchParams.get("end-date"));
  }, [startDate, endDate]);

  const classes = clx("w-[93%] lg:w-[90%] mx-auto pb-[50px] lg:pb-[70px]");
  return (
    <div className={classes}>
      {/* <div className='flex items-center space-x-6'>
        <MdArrowBackIosNew className='text-[#9799A5] text-[20px] cursor-pointer' />
        <Text as='span' classname='text-[#9799A5]'>
          Back to Prepaid Energy Dashboard
        </Text>
      </div> */}
      {/* <EnergyStatisticsWrapper.Tabs /> */}
      <AffiliateEnergyStatisticChart classname="rounded-[20px] shadow-xl w-full max-h-fit" />
    </div>
  );
}

export function Tabs({ classname, children }) {
  const [activeTab, setActiveTab] = React.useState(1);
  const classes = clx(
    "w-fit mx-auto flex items-center space-x-6 my-[50px] bg-[#F1F1FF] py-[28px] px-[20px] lg:px-[40px] rounded-[12px]",
    classname
  );
  return (
    <div className={classes}>
      {["Daily", "Weekly", "Monthly"].map((tab, index) => (
        <div
          key={index}
          className={`py-[6px] px-[16px] rounded-[4px] font-semibold cursor-pointer ${
            activeTab === index + 1
              ? "bg-secondary-dark text-primary-dark"
              : "bg-[#E6E6E9] text-primary-dark"
          }`}
          onClick={() => setActiveTab(index + 1)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
}

AffiliateBarChargesChartStatistics.Tabs = Tabs;
