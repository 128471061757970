import React from "react"
import Text from "../../../components/text"

export default function GatePasssAccessTabs({ steps, currentStep }) {
    const [newStep, setNewStep] = React.useState([])
    const stepRef = React.useRef()
    const updataStep = (stepNumber, steps) => {
        const newSteps = [...steps]
        let count = 0

        while (count < newSteps.length) {
            if (count === stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: true,
                    selected: true,
                    completed: true
                }
                count++
            }
            else if (count < stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: true,
                    completed: true
                }
                count++
            }
            else {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: false,
                    completed: false
                }
                count++
            }

        }
        return newSteps

    }
    React.useEffect(() => {
        const stepsState = steps.map((step, index) => ({
            description: step,
            completed: false,
            highlighted: index === 0 ? true : false,
            selected: index === 0 ? true : false,
        }));

        stepRef.current = stepsState;
        const current = updataStep(currentStep - 1, stepRef.current);
        setNewStep(current);
    }, [steps, currentStep]);


    const displayTabs = newStep.map((step, index) => {
        return (
            <div key={index} className={
                index !== newStep.length - 1 ? "flex items-center" : "flex items-center "
            }>
                <div className="flex gap-1 flex-row items-center text-primary-dark">
                    <div className={`rounded-full transition duration-500 ease-in-out border-2 border-gray-300 h-[20px] w-[20px] flex items-center justify-center ${step.selected ? "bg-primary-dark border-none" : ""
                        }`}>

                        {step.completed ? (
                            <Text as="p" classname="text-white p-1 text-xs font-bold">✓</Text>
                        ) :
                            (
                                <Text as="p" classname="text-xs p-2 font-bold">{index + 1}</Text>
                            )}

                    </div>

                    <Text as="p" weight="medium" classname={`text-[12px] upercase ${step.highlighted ? "text-primary-dark" : ""
                        }`}>
                        {step.description}
                    </Text>
                    {
                        index !== newStep.length - 1 && (
                            <div className={`border-t-2 bg-gray-300 w-[50px] transition duration-500 ease-in-out
                    ${step.completed ? "border-primary-dark bg-primary-dark" : ""}
                    `}>
                                {/* line */}
                            </div>

                        )
                    }
                </div>
            </div>
        )
    })

    return (
        <div className="flex flex-row justify-center gap-4 items-center" >
            {displayTabs}
        </div>
    )

}