export const EnergyCSVHeader = [
  { label: "Date Created", key: "Gen_Date" },
  { label: "Name", key: "fullname" },
  { label: "Apartment", key: "apartment" },
  { label: "Token", key: "Token" },
  { label: "Amount", key: "Total_paid" },
  { label: "Unit", key: "Total_unit" },
  { label: "ReferenceID", key: "refr" },
];
export const ChargesCSVHeader = [
  { label: "Apartment", key: "user.apartment" },
  { label: "Target Period", key: "targetPeriod" },
  { label: "Amount", key: "amount" },
  { label: "Day", key: "day" }
];
