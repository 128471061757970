import react from "react";
import { clx } from "../../utils/clx";
import Button from "../button";
import Text from "../text";

export default function RechargeSuccess({
  classname,
  extra,
  onButtonClick,
  children,
  title = "Recharge Successful",
  text = " We’ve sent details to your mail",
}) {
  const classes = clx("relative flex flex-col items-center", classname);
  return (
    <div>
      <div className={classes}>
        <div>
          <img
            src="/assets/icons/recharge-success.png"
            alt=""
            className="w-[120px] mx-auto"
          />
        </div>
        <div className="my-6">
          <Text as="h6" weight="semibold" classname="text-[25px] text-center">
            {title}
          </Text>
          <Text as="p" classname="text-[#9799A5] text-[14px] text-center">
            {text}
          </Text>
          <div>{extra && extra()}</div>
        </div>

        <Button
          size="medium"
          classname="mx-auto px-[40px]"
          onclick={onButtonClick}
        >
          Done
        </Button>
      </div>
    </div>
  );
}
