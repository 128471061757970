import React from "react";
import { IconContext } from "react-icons";
import { clx } from "../../utils/clx";
import Text from "../text";

export default function ChatSuccess({
  classname,
  icon,
  closeToast,
  toastProps,
  children,
}) {
  const classes = clx("flex items-center space-x-4");
  return (
    <IconContext.Provider value={{ size: "18" }}>
      <div className={classes}>
        {/* <div className="w-[40px] h-[40px] rounded-full bg-red-500"></div> */}
        <div>{icon}</div>
        <Text
          as='p'
          weight='semibold'
          classname='text-[14px] text-green-500 lg:text-base'
        >
          {children}
        </Text>
      </div>
    </IconContext.Provider>
  );
}
