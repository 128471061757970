import Text from "../../../../components/text";
import Button from "../../../../components/button";
import useBuyElectricityData from "../../../../store/static/buy-electricity";

export default function UserInfo({}) {
  const { discoInfo, userInfo } = useBuyElectricityData();
  return (
    <div className="flex flex-col space-y-4 ">
      <Text as="p" weight="bold" classname="text-[16px] text-[#9799A5]">
        Meter: {discoInfo?.data.biller?.biller_name}
      </Text>
      <Text
        as="p"
        classname="text-[16px] text-black p-2 rounded-md bg-[#e9e9f8]"
      >
        {discoInfo?.meterid} - {discoInfo?.data?.name}
      </Text>

      <div className="flex flex-col p-4 space-y-2 rounded-md bg-[#e9e9f8]">
        <Text as="p" classname="text-[16px] ">
          Account Detail: {userInfo?.account_number}
        </Text>
        <Text as="p" classname="text-[16px] ">
          Account Name: {userInfo?.account_name}
        </Text>
        <Text as="p" classname="text-[16px]">
          Bank: {userInfo?.bank_name}
        </Text>
      </div>

      <Button
        classname="mt-14"
        type="secondary"
        variant="contained"
        onclick={() => {
          window.location.reload();
        }}
      >
        Finish
      </Button>
    </div>
  );
}
