import Text from "../../../../components/text";

export default function Overview() {
  return (
    <div>
      <ul className="list-disc text-gray-600 tracking-wide">
        <li>
          <Text as="p">
            Save yourself extra charges when you can do it yourself
          </Text>
        </li>
        <li>
          <Text as="p">
            Learn how to detect car issues early before they become problems
          </Text>
        </li>
        <li>
          <Text as="p">
            View your car engine data on your ANDROID PHONE live all by yourself
          </Text>
        </li>
        <li>
          <Text as="p">
            RETRIEVE Fault Codes (DTCs) and CLEAR Check Engine lights
          </Text>
        </li>
        <li>
          <Text as="p">
            View Detailed Fault Code Explanation with Technical Advice from your
            car Manufacture on how best to work
          </Text>
        </li>
      </ul>
    </div>
  );
}
