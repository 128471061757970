import axios from "axios";
import React from "react";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import {  useNavigate } from "react-router-dom";
import { GetConsumptionData } from "../../api-calls/user/getConsumptionData";
import Button from "../../components/button";
import Amount from "../../components/modals/amount";
import AmountConfirmation from "../../components/modals/amount-confirmation";
import ModalWrapper from "../../components/modals/modal-wrapper";
import RechargeSuccess from "../../components/modals/recharge-success";
import Reference from "../../components/modals/reference";
import SelectPaymentOption from "../../components/modals/select-payment-option";
import RechargeCard from "../../components/recharge-card";
import Text from "../../components/text";
import useWalletInfo from "../../hooks/apis/user/useWalletInfo";
import useUserStore from "../../store/user/store";
import { clx } from "../../utils/clx";
import { usePaystackPayment } from "react-paystack";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { toast } from "react-toastify";
import ErrorToast from "../../components/toast/error-toast";
import { getFormattedPrice } from "../../utils/getFormattedPrice";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import CopyText from "../../components/toast/copy-text";
import VirtualAccountInfo from "../../components/modals/virtual-account-info";
import useUserServiceConfig from "../../hooks/apis/user/useUserServiceConfig";
import DiscoCard from "../../components/disco-card";

export default function DashboardWrapper({ classname, children }) {
  const [isBalanceHidden, setBalanceHidden] = React.useState(true);
  const [fundingPlatform, setFundingPlatform] = React.useState(null);
  const [isFundWalletOpen, setFundWalletOpen] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const { data: walletInfo } = useWalletInfo({ query: "balance" });

  const {
    availableBalance,
    totalConsumption,
    totalCredit,
    isLoading: isEnergyConsumptionLoading,
    isFetching: isEnergyConsumptionFetching,
  } = GetConsumptionData({ query: "realtime", prepaid: "energy" });
  
  const signOut = useSignOut();
  const isDisco = user?.disco;
  
  
  React.useEffect(()=>{
  if(!isDisco){
    navigate('/download')
    signOut()
 
  }
  },[isDisco,navigate,signOut])


  React.useEffect(() => {}, [
    isEnergyConsumptionLoading,
    isEnergyConsumptionFetching,
  ]);

  const handleModalClose = () => {
    setFundWalletOpen(false);
    setStep(1);
  };

  const handleGeneralFundButtonClick = (platform = null) => {
    setFundingPlatform(platform);
    setFundWalletOpen(true);
  };

  const handleFundButtonClick = (platform = null) => {
    setFundingPlatform(platform);
    setFundWalletOpen(true);
  };

  const handlePaymentMethodSelect = (method) => {
    setPaymentMethod(method);
    setStep(2);
  };

  const handleCardClick = (e, url) => {
    const { target, currentTarget } = e;
    if (target === currentTarget) {
      navigate(url);
    }
  };

  const handleDiscoRoute = () => {
    navigate("/user/disco-meters");
  };

  const isMetering = user?.user_services?.find(
    ({ slug, status }) => slug === "metering" && status
  );

  const meteringProduct = isMetering?.product;
  const energyProduct = meteringProduct?.find(({ slug }) => slug === "energy");

  const classes = clx(
    "w-[93%] lg:w-[90%] mx-auto flex space-x-[20px] justify-between pt-[30px] lg:py-[15px] lg:pb-[50px]",
    classname
  );


  return (
    <React.Fragment>
      <div className={classes}>
        <div className="w-[100%] lg:w-[72%]">
          <Text
            as="p"
            classname="hidden lg:block text-[#2C3048] dark:text-[#9799A5] relative after:absolute after:bottom-0 after:border-2 after:border-[#9799A5] after:w-[50px] after:left-0 after:top-[40px]"
          >
            Wallet Balance
          </Text>
          <div className="hidden py-[40px] lg:flex items-center space-x-[40px]">
            <div className="flex items-center space-x-8">
              <Text as="h5" classname="text-[56px] dark:text-[#E6E6E9]">
                {isBalanceHidden
                  ? "*** *** ***"
                  : user
                  ? `₦${getFormattedPrice(walletInfo?.data?.balance) ?? "0"}`
                  : "₦500,000"}
              </Text>
              <img
                src="/assets/icons/invisible.png"
                alt=""
                className="cursor-pointer"
                onClick={() => setBalanceHidden((state) => !state)}
              />
            </div>
            <Button
              size="medium"
              onclick={() => handleGeneralFundButtonClick(null)}
            >
              Fund Wallet
            </Button>
          </div>
          {/* Mobile Wallet Balance */}
          <div className="lg:hidden w-[100%] h-fit lg:h-[347px] p-[40px] bg-primary-dark rounded-[20px] flex flex-col items-center justify-between">
            <Text
              as="p"
              classname="text-white text-[12px] lg:text-[21px] text-center"
            >
              Wallet Balance
            </Text>
            <div className="flex items-center space-x-4">
              <Text
                as="h5"
                classname="text-center text-white text-[28px] lg:text-[56px] py-[15px]"
              >
                {isBalanceHidden
                  ? "*** *** ***"
                  : user
                  ? `₦${getFormattedPrice(walletInfo?.data?.balance) ?? ""}`
                  : "₦500,000"}
              </Text>
              {isBalanceHidden ? (
                <AiFillEyeInvisible
                  className="text-[#9799A5] text-[24px] cursor-pointer"
                  onClick={() => setBalanceHidden((state) => !state)}
                />
              ) : (
                <AiFillEye
                  className="text-[#9799A5] text-[24px] cursor-pointer"
                  onClick={() => setBalanceHidden((state) => !state)}
                />
              )}
            </div>
            <Button
              variant="contained"
              type="white"
              size="medium"
              classname=""
              onclick={() => handleGeneralFundButtonClick(null)}
            >
              Fund Wallet
            </Button>
          </div>
          <div className="py-[50px] lg:pb-[40px] flex flex-col space-y-[30px]">
            {/* disco metering */}

            {isDisco ?? (
              <DiscoCard
                onclick={() => handleDiscoRoute()}
                classname="max-w-full"
              />
            )}

            {meteringProduct?.length
              ? meteringProduct?.map((item, index) => (
                  <RechargeCard
                    key={index}
                    remote={item?.remote === "none" ? false : true}
                    user={user}
                    type={item.slug}
                    platform={`Prepaid ${item?.slug}`}
                    totalCredit={totalCredit}
                    totalConsumption={totalConsumption}
                    availableBalance={availableBalance}
                    href={`/user/${item?.slug}`}
                    onclick={(e) => handleCardClick(e, `/user/${item?.slug}`)}
                    onButtonClick={() => handleFundButtonClick(item?.slug)}
                    classname="max-w-full"
                    isLoading={
                      isEnergyConsumptionLoading || isEnergyConsumptionFetching
                    }
                  />
                ))
              : null}
            {/* {user && user?.water && (
              <RechargeCard
                user={user}
                type="water"
                platform="Prepaid Water"
                totalCredit={{ unit: "524.96", price: "15,425" }}
                totalConsumption={{ unit: "524.96", price: "15,425" }}
                availableBalance={{ unit: "524.96", price: "15,425" }}
                href="/user/water"
                onclick={(e) => handleCardClick(e, "/user/water")}
                onButtonClick={() => handleFundButtonClick("water")}
                classname="max-w-full"
              />
            )} */}
            {user && user?.fuel && (
              <RechargeCard
                type="fuel"
                platform="IoT"
                totalCredit={{ unit: "524.96", price: "15,425" }}
                totalConsumption={{ unit: "524.96", price: "15,425" }}
                availableBalance={{ machineStatus: "ON" }}
                href="/user/fuel"
                onclick={(e) => handleCardClick(e, "/user/fuel")}
                onButtonClick={() => handleFundButtonClick("fuel")}
                classname="max-w-full"
                handleFuel
              />
            )}
          </div>
        </div>
        <div className="flex-1 hidden overflow-hidden lg:block h-[calc(100vh-150px)] rounded-[20px] bg-[#D9D9D9]">
          <img
            src="/assets/images/banner-dark.png"
            alt="prepaid-meter-banner"
            width="100%"
            height="100%"
            className="object-fill"
          />
        </div>
      </div>

      <ModalWrapper
        isOpen={isFundWalletOpen}
        handleModalClose={handleModalClose}
      >
        {/* General Fund Wallet */}
        {!fundingPlatform && (
          <DashboardWrapper.WalletFunding
            step={step}
            setStep={setStep}
            handleModalClose={handleModalClose}
            onselect={handlePaymentMethodSelect}
            paymentMethod={paymentMethod}
          />
        )}

        {/* Energy Funding */}
        {fundingPlatform === "energy" && (
          <DashboardWrapper.EnergyFunding
            step={step}
            setStep={setStep}
            handleModalClose={handleModalClose}
            onselect={handlePaymentMethodSelect}
            paymentMethod={paymentMethod}
          />
        )}

        {/* Fuel Funding */}
        {fundingPlatform === "fuel" && step === 1 && (
          <SelectPaymentOption onselect={handlePaymentMethodSelect} />
        )}
        {fundingPlatform === "fuel" && step === 2 && (
          <Amount
            onButtonClick={() => setStep(3)}
            noDescription={paymentMethod === "bank"}
          />
        )}
        {fundingPlatform === "fuel" && step === 3 && (
          <AmountConfirmation
            onButtonClick={() => setStep(4)}
            noDescription={paymentMethod === "bank"}
          />
        )}
        {fundingPlatform === "fuel" && step === 4 && (
          <RechargeSuccess onButtonClick={handleModalClose} />
        )}
      </ModalWrapper>
    </React.Fragment>
  );
}

export function WalletFunding({
  step,
  onselect,
  paymentMethod,
  setStep,
  handleModalClose,
}) {
  const [message, setMessage] = React.useState("");
  const [actualAmount, setActualAmount] = React.useState();
  const [totalAmount, setTotalAmount] = React.useState();
  const [platform, setPlatform] = React.useState("");
  const [formReference, setFormReference] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { refetch } = useWalletInfo({ query: "balance" });
  const [formData, setFormData] = React.useState();

  const user = useUserStore((state) => state.user);
  // console.log(user);
  const authUser = useAuthUser();
  // add this ${user?.paymentprefix}-
  const reference = `${user?.paymentprefix}-${Date.now()}`;
  // console.log(user)
  const onSuccess = () => {
    fundWallet();
  };

  const onClose = () => {
    return;
  };
  const paystackConfig = {
    reference,
    email: user?.email,
    amount: totalAmount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: user?.paystack_pk,
  };

  const flutterwaveConf = {
    public_key: user?.flutterwave_pk,
    tx_ref: reference,
    amount: totalAmount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: user?.email,
      name: `${user?.fullname}`,
    },
  };

  // console.log("form", actualAmount);

  const platforms = {
    paystack: usePaystackPayment(paystackConfig),
    flutterwave: useFlutterwave(flutterwaveConf),
  };

  const makePayment = platforms[platform];

  const initializePayment = () => {
    axios
      .post(
        `https://api-pm-e1ea777feac4.herokuapp.com/wallet/fund-init`,
        {
          refr: reference,
          gateway: platform,
          amount: actualAmount,
        },
        {
          headers: {
            Authorization: authUser().authToken,
          },
        }
      )
      .then((res) => {
        // toast.success(`Payment of ${actualAmount} Initialized Successfully `);
      });
  };

  const handlePayment = () => {
    if (platform === "paystack") {
      initializePayment();
      makePayment(onSuccess, onClose);
    }
    if (platform === "flutterwave") {
      initializePayment();
      makePayment({
        callback: (response) => {
          // console.log(response);
          onSuccess();
          closePaymentModal();
        },
        onClose: onClose(),
      });
    }
  };
  const fundWallet = () => {
    setMessage("");
    setLoading(true);
    axios
      .post(
        "https://api-pm-e1ea777feac4.herokuapp.com/wallet/fund",
        {
          refr: paymentMethod === "bank" ? reference : formReference ?? "",
          gateway: platform,
        },
        {
          headers: {
            Authorization: authUser().authToken,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setStep(3);
        refetch();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(<ErrorToast>{err.response.data.message}</ErrorToast>);
      });
  };



  switch (step) {
    case 1:
      return (
        <SelectPaymentOption
          onselect={onselect}
          onlyBank
          useReference
          useTransfer
        />
      );
    case 2:
      if (paymentMethod === "bank") {
        return (
          <Amount
            onButtonClick={() => handlePayment()}
            noDescription={paymentMethod === "bank"}
            message={message}
            setData={setFormData}
            setPlatform={setPlatform}
            setActualAmount={setActualAmount}
            setTotalAmount={setTotalAmount}
            paymentMethod="bank"
            minimum={user?.minimum?.wallet}
          />
        );
      } else if (paymentMethod === "reference") {
        return (
          <Reference
            onButtonClick={fundWallet}
            setData={setFormData}
            setPlatform={setPlatform}
            setReference={setFormReference}
            message={message}
            loading={loading}
          />
        );
      } else if (paymentMethod === "transfer") {
        return <VirtualAccountInfo user={user} />;
      }
      break;
    case 3:
      return <RechargeSuccess onButtonClick={handleModalClose} />;
    // case 4:
    //   return <RechargeSuccess onButtonClick={handleModalClose} />;
    default:
      return null;
  }
}

export function EnergyFunding({
  step,
  onselect,
  paymentMethod,
  setStep,
  handleModalClose,
}) {
  const [formData, setFormData] = React.useState({});
  const [amount, setAmount] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [token, setToken] = React.useState("");
  const navigate = useNavigate();

  const user = useUserStore((state) => state.user);
  const authUser = useAuthUser();

  const vendMeter = () => {
    setLoading(true);
    // console.log({ formData });
    axios
      .post(
        `https://api-pm-e1ea777feac4.herokuapp.com/metering/vend-private-meter`,
        {
          amount: formData.rechargeAmount,
          remotely: formData.remoteCompliance,
          prepaid: "energy",
        },
        {
          headers: {
            Authorization: authUser().authToken,
          },
        }
      )
      .then((res) => {
        setToken(res.data.data.Token);
        setStep(4);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err.response.data.message);
        if (err.response.data.message.includes("charges")) {
          navigate("/user/payments");
        }
        toast.error(<ErrorToast>{err.response.data.message}</ErrorToast>);
      });
  };

  const { data, isLoading, isError } = useUserServiceConfig({
    key: "user-service-config",
    prepaid: "energy",
  });
  // console.log(data);
  switch (step) {
    case 1:
      return <SelectPaymentOption onselect={onselect} onlyWallet />;
    case 2:
      return (
        <Amount
          onButtonClick={() => setStep(3)}
          // noDescription={paymentMethod === "bank"}
          platform="energy"
          minimum={data?.data?.minimum_meter_recharge}
          setData={setFormData}
          setAmount={setAmount}
          showBreakdown
        />
      );
    case 3:
      return (
        <AmountConfirmation
          amount={formData?.rechargeAmount}
          onButtonClick={(e) => vendMeter()}
          noDescription={paymentMethod === "bank"}
          loading={loading}
        />
      );
    case 4:
      return (
        <RechargeSuccess
          onButtonClick={handleModalClose}
          extra={() => (
            <div className="mt-4 flex flex-col items-center">
              <Text as="h5" weight="semibold" classname="text-secondary-dark">
                Token
              </Text>
              <div className="w-full flex items-center space-x-2">
                <Text as="h5" weight="semibold">
                  {token}
                </Text>
                <CopyToClipboard
                  text={token}
                  onCopy={() =>
                    toast.success(
                      <CopyText>Token copied successfully!</CopyText>
                    )
                  }
                >
                  <BiCopy className="block text-black cursor-pointer" />
                </CopyToClipboard>
              </div>
            </div>
          )}
        />
      );
    default:
      return null;
  }
}

DashboardWrapper.WalletFunding = WalletFunding;
DashboardWrapper.EnergyFunding = EnergyFunding;
