import React from "react";
import Text from "../../../../components/text";
import { clx } from "../../../../utils/clx";
import GridConsumptionChart from "../charts/grid-monitory-chart";
import GridBalanceCard from "./balance-card";

export default function SingleGridCompanyPage() {
  const [filteredChart, setFilteredChart] = React.useState(null);

  const classes = clx(
    "w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex flex-col space-y-[40px]"
  );
  return (
    <div className={classes}>
      <Text as='p' weight='semibold' classname='text-[#6C6F7F] lg:text-[20px]'>
        Guarantee Trust Bank
      </Text>

      <div className='my-[30px] flex space-x-6'>
        <GridBalanceCard bg='bg-[#EAC36B]' classname='flex-1' />
        <GridBalanceCard bg='bg-[#DB9800]' classname='flex-1' />
        <GridBalanceCard bg='bg-[#DB9800]' classname='flex-1' />
      </div>

      <div className='my-[30px] w-full'>
        <GridConsumptionChart
          handleFilteredChart={setFilteredChart}
          // onFilterClick={() => setModalOpen(true)}
          classname='my-[30px] lg:my-[50px]'
        />
      </div>
    </div>
  );
}
