import Text from "../../../components/text";
import UnregisteredUserAuth from "./components/unregistered-user-auth";

export const unregistered_users_data = Array.from({ length: 20 }).map((data, i) => ({
    _id: i + 1,
    name: "Oloko Opeyemi ",
    apartment: "Landwey Estate Lekki 1",
    meterid: `5232464252${i}`,
    meter_type: `ENERGY`
}));

export const unregistered_users_column = [
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Apartment
            </Text>
        ),
        Cell: ({ value, row }) => <Text as="p" classname='text-left text-[16px]'>
            {value}
        </Text>,
        accessor: "apartment",
    },

    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                User ID
            </Text>
        ),
        //     Cell: ({ value, row }) => <Text as="p">
        //     {value}
        // </Text>,
        accessor: "userid",
    },

    {
        id: "2",
        Header: (
            <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
                Auth Token
            </Text>
        ),
        accessor: "auth",
        Cell: UnregisteredUserAuth,

    }

];
