import Text from "../../../components/text";
import React from "react";
import RegularChargesActions from "./components/regular-charges-actions";
import RegularChargesOtherActions from "./components/regular-charges-other-actions";

export const regular_charge_data = Array.from({ length: 5 }).map((data, i) => ({
  _id: i + 1,
  name: `Oloko Opeyemi ${i + 1}`,
  amount: `N10,000`,
  unit: `%`
}));

export const regular_charge_columns = [
  // {
  //   id: "1",
  //   Header: (
  //     <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
  //       S/N
  //     </Text>
  //   ),
  //   Cell: ({ value, row }) => <Text as="p">{value}</Text>,
  //   accessor: "_id",
  // },
  {
    // id: "1",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Name
      </Text>
    ),
    Cell: ({ value, row }) => (
      <Text as="p" classname="text-left text-[16px] capitalize">
        {value}
      </Text>
    ),
    accessor: "name",
  },
  {
    // id: "2",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Charge Amount 
      </Text>
    ),
    //     Cell: ({ value, row }) => <Text as="p">
    //     {value}
    // </Text>,
    accessor: "amount",
  },
  {
    // id: "2",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Unit
      </Text>
    ),
    //     Cell: ({ value, row }) => <Text as="p">
    //     {value}
    // </Text>,
    accessor: "unit",
  },
  {
    // id: "2",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Naira Addup (N)
      </Text>
    ),
    //     Cell: ({ value, row }) => <Text as="p">
    //     {value}
    // </Text>,
    accessor: "naira_addup",
  },
  {
    // id: "2",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Cap At (₦)
      </Text>
    ),
    Cell: ({ value, row }) => <Text as="p">
      {value}
    </Text>,
    accessor: "cap_at",
  },
  {
    // id: "2",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Cap To (₦)
      </Text>
    ),
    Cell: ({ value, row }) => <Text as="p">
      {value}
    </Text>,
    accessor: "cap_to",
  },
  {
    id: "2",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Charge Actions
      </Text>
    ),
    accessor: "_id",
    Cell: RegularChargesActions,
  },
  {
    id: "3",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Other Actions
      </Text>
    ),
    accessor: "cap",
    Cell: RegularChargesOtherActions,
  },
];
