import { create } from "zustand";

const useBuyElectricityData = create((set) => ({
  electricityInfo: {},
  discoInfo: {},
  currentStep: 1, 
  userInfo: {},
  setCurrentStep: (step) => set({ currentStep: step }),
  setElectricityInfo: (data) => set({ electricityInfo: data }),
  setDiscoInfo: (data) => set({ discoInfo: data }),
  setUserInfo: (data) => set({ userInfo: data }),
}));

export default useBuyElectricityData;
