import React from "react";
import { clx } from "../../../../utils/clx";
import GatePassTab from "./tabs";
import VerifyGatePass from "./verify-gate-pass";
import AccessRecords from "./access-records";
import CreateAccess from "./create-access/gate-pass";
;

export default function GatePassWrapper() {
    const [activeTab, setActiveTab] = React.useState(1);
    const classes = clx(
        "w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex flex-col space-y-[40px]"
    );
    return (
        <div className={classes}>

            <GatePassTab activeTab={activeTab} setTab={setActiveTab} />
            <div>
                {activeTab === 1 && <VerifyGatePass />}
                {activeTab === 2 && <AccessRecords />}
                {activeTab === 3 && <CreateAccess />}
            </div>
        </div>
    );
}