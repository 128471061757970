import React from "react";
import StaticLayout from "../../layouts/static-layout";
import CartPageWrapper from "../../views/static/cart-page";

export default function CartPage() {
  return <React.Fragment>
    <StaticLayout>
      <CartPageWrapper />
    </StaticLayout>
  </React.Fragment>
}
