import { FaAppStore, FaGooglePlay } from "react-icons/fa"
import { DownLoadLink } from "../hompage"
import Text from "../../../components/text"

export const DownloadApp = ({phone}) => {
    return (
      <div className="w-[93%] lg:w-[90%] flex flex-col mx-auto pt-8">
        <section className="">
          <Text
            as="p"
            weight="bold"
            classname="text-[20px] mb-6 text lg:text-[24px] text-primary-dark dark:text-[#E6E6E9]"
          >
            Download our app
          </Text>
          <div className="flex space-x-6">
            <DownLoadLink
              iconLeft={
                <FaAppStore className="text-white w-6 lg:w-8 h-6 lg:h-8" />
              }
              title="App Store"
              link="https://apps.apple.com/us/app/prepaid-meter/id6448630946"
            />
            <DownLoadLink
              iconLeft={
                <FaGooglePlay className="text-white w-6 lg:w-8 h-6 lg:h-8" />
              }
              title="Google Play"
              link="https://play.google.com/store/apps/details?id=com.millzbdm.prepaidmeter"
            />
          </div>
        </section>
  
        {/* image */}
        {phone &&(<div className="ml-auto">
          <img
            src="/assets/images/hero-img.png"
            alt="hero"
            className="w-[329px] md:w-full"
          />
          </div>)
        }
      </div>
    )
  }