import React from "react";
import { clx } from "../../utils/clx";
import Button from "../button";
import Text from "../text";

export default function SwitchDialogue({
  onAccept,
  isSwitching,
  onReject,
  isMeterOn,
  classname,
  children,
}) {
  const classes = clx("flex flex-col items-center", classname);
  if (isSwitching) {
    return <div>Switching...</div>;
  }
  return (
    <div className={classes}>
      <div className='w-fit mx-auto'>
        <img src='/assets/icons/question-mark.png' alt='' />
      </div>
      <Text as='p' classname='text-primary-dark text-center text-[20px] my-6'>
        Do you want to turn {isMeterOn ? "OFF" : "ON"} your meter?
      </Text>
      <div className='w-fit mx-auto flex space-x-[20px]'>
        <Button
          variant='outlined'
          size='medium'
          classname='block px-[40px]'
          onclick={onReject}
        >
          No
        </Button>
        <Button size='medium' classname='block px-[40px]' onclick={onAccept}>
          Yes
        </Button>
      </div>
    </div>
  );
}
