import { createClient } from "@supabase/supabase-js";


//dbpass  Alabi95329075
// projeccturl https://oharkdyflutzkwegeffp.supabase.co
// publickKey   eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9oYXJrZHlmbHV0emt3ZWdlZmZwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcxNDg2NjYsImV4cCI6MjAyMjcyNDY2Nn0.k9O59uMQB1wsgy2kmWLi-Ad_K4S_SkhgWAV3XCFXISI

const supabaseUrl = "https://oharkdyflutzkwegeffp.supabase.co";
const supabaseKey ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9oYXJrZHlmbHV0emt3ZWdlZmZwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcxNDg2NjYsImV4cCI6MjAyMjcyNDY2Nn0.k9O59uMQB1wsgy2kmWLi-Ad_K4S_SkhgWAV3XCFXISI";

export const supabase = createClient(supabaseUrl, supabaseKey);