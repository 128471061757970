import React from "react";
import { useNavigate } from "react-router-dom";
import { GetConsumptionData } from "../../api-calls/user/getConsumptionData";
import BalanceCard from "../../components/balance-card";
import Button from "../../components/button";
import EnergyTransactionCard from "../../components/energy-transaction-card";
import MeterIDContainer from "../../components/meterid-container";
import Filter from "../../components/modals/filter";
import ModalWrapper from "../../components/modals/modal-wrapper";
import Text from "../../components/text";
import useVendInfo from "../../hooks/apis/user/useVendInfo";
import useUserStore from "../../store/user/store";
import { clx } from "../../utils/clx";
import { getSortedLastToStart } from "../../utils/getSortedLastToStart";
import EnergyConsumptionChart from "./components/charts/energy-consumption-chart";
import { EnergyFunding } from "./dashboard";

export default function EnergyDashboardWrapper({ classname, children }) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [filteredChart, setFilteredChart] = React.useState(null);
  const [modalFor, setModalFor] = React.useState(null);
  // const [isFundWalletOpen, setFundWalletOpen] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [paymentMethod, setPaymentMethod] = React.useState(false);
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const {
    availableBalance,
    totalConsumption,
    totalCredit,
    cost,
    isFetching,
    isLoading,
  } = GetConsumptionData({ query: "realtime", prepaid: "energy" });
  const { data: transactionsInfo } = useVendInfo({ prepaid: "energy" });
  const transactions = getSortedLastToStart(transactionsInfo?.data);

  const handleModalControl = (useCase) => {
    setModalFor(useCase);
    setModalOpen(true);
  };

  const handlePaymentMethodSelect = (method) => {
    setPaymentMethod(method);
    setStep(2);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setStep(1);
  };

  const isMetering = user?.user_services?.find(
    ({ slug, status }) => slug === "metering" && status
  );

  const energyProduct = isMetering?.product.find(
    ({ slug, active }) => slug === "energy" && active
  );

  const remote = energyProduct?.remote === "none" ? false : true;

  React.useEffect(() => window.scrollTo(0, 0), [isFetching, isLoading]);

  const classes = clx("w-[93%] lg:w-[90%] mx-auto py-[40px]", classname);
  return (
    <React.Fragment>
      <div className={classes}>
        <div className="flex items-center space-x-6">
          <Text as="span" classname="text-[12px] lg:text-[16px]">
            Meter Number
          </Text>
          <MeterIDContainer number={energyProduct?.meterid} user={user} relay_state={energyProduct.relay_state} remote={remote} />
        </div>
        <div className="max-w-[100%] scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-thumb-[#E5E5E5] overflow-auto pt-[40px] pb-[10px] grid grid-cols-2 gap-4 lg:gap-x-3 lg:grid-cols-3 justify-between ">
          <BalanceCard
            platform="Prepaid Energy"
            title="Total Credit"
            unit={totalCredit?.unit}
            price={totalCredit?.price}
          />
          {remote ? (
            <BalanceCard
              platform="Prepaid Energy"
              title="Available Balance"
              unit={availableBalance.unit}
              price={availableBalance.price}
            />
          ) : null}
          {remote ? (
            <BalanceCard
              platform="Prepaid Energy"
              title="Total Consumption"
              unit={totalConsumption.unit}
              price={totalConsumption.price}
            />
          ) : null}

          <div className="lg:hidden rounded-[8px] md:w-fit p-4 flex flex-col md:flex-row md:items-center md:space-x-[50px] space-y-6 md:space-y-0">
            <div className="flex items-center space-x-[10px]">
              <div className="min-w-[32px] min-h-[32px] lg:w-[42px] lg:h-[42px] rounded-[4px] bg-[#9799A5] flex items-center justify-center">
                <img
                  src="/assets/icons/price-icon-black.png"
                  alt="price-icon"
                />
              </div>
              <div className="flex flex-col">
                <Text as="span" classname="text-[#6C6F7F] text-[11px]">
                  Price per unit
                </Text>
                <Text as="span" weight="bold" classname="text-[18px]">
                  {cost}{" "}
                  <span className="font-normal text-[#6C6F7F] text-[8px]">
                    {" "}
                    ( + VAT )
                  </span>
                </Text>
              </div>
            </div>
            <Button
              size="medium"
              classname="px-[40px]"
              onclick={() => handleModalControl("payment")}
            >
              Recharge
            </Button>
          </div>
        </div>
        <div className="hidden border-2 rounded-[8px] md:w-fit p-4 my-[40px] lg:flex flex-col md:flex-row md:items-center md:space-x-[50px] space-y-6 md:space-y-0">
          <div className="flex items-center space-x-[20px]">
            <div className="w-[42px] h-[42px] rounded-[4px] bg-[#9799A5] flex items-center justify-center">
              <img src="/assets/icons/price-icon-black.png" alt="price-icon" />
            </div>
            <Text as="span" classname="text-[#6C6F7F]">
              Price per unit
            </Text>
            <Text as="span" weight="bold" classname="text-[24px]">
              {cost}{" "}
              <span className="font-normal text-[#6C6F7F] text-[16px]">
                {" "}
                ( + VAT )
              </span>
            </Text>
          </div>
          <Button
            size="medium"
            classname="px-[40px]"
            onclick={() => handleModalControl("payment")}
          >
            Recharge
          </Button>
        </div>
        {remote ? (
          <EnergyConsumptionChart
            handleFilteredChart={setFilteredChart}
            onFilterClick={() => handleModalControl("filter")}
            classname="my-[30px] lg:my-[50px]"
          />
        ) : null}
        <div className="">
          <div className="flex items-center justify-between py-[20px]">
            <Text
              as="span"
              weight="medium"
              classname="lg:text-[23px] text-[#6C6F7F]"
            >
              Recent Transactions
            </Text>
            <Text
              as="span"
              weight="medium"
              classname="lg:text-[23px] text-secondary-dark"
              onclick={() => navigate("/user/energy-transaction")}
            >
              see all
            </Text>
          </div>
          <div className="lg:py-[30px] flex flex-col space-y-6">
            {transactionsInfo?.data?.slice(0, 5).map((transaction, index) => (
              <EnergyTransactionCard
                key={index}
                data={transaction}
                // onclick={() => handleModalControl("transaction")}
                classname="w-[100%]"
              />
            ))}
          </div>
        </div>
      </div>
      <ModalWrapper isOpen={isModalOpen} handleModalClose={handleModalClose}>
        {modalFor === "payment" && (
          <EnergyFunding
            step={step}
            setStep={setStep}
            handleModalClose={handleModalClose}
            onselect={handlePaymentMethodSelect}
            paymentMethod={paymentMethod}
          />
        )}
        {modalFor === "filter" && (
          <Filter
            handleModalClose={() => setModalOpen(false)}
            route={
              filteredChart === "bar"
                ? "/user/energy-statistics"
                : "/user/energy-statistics-area"
            }
          />
        )}
      </ModalWrapper>
    </React.Fragment>
  );
}
