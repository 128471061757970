import React from "react";
import { Link } from "react-router-dom";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";
import useAdminRank from "../../../store/admin/adminRank";

export default function AffiliatesWrapper() {
    const { adminRank } = useAdminRank()
    const routes = [
        {
            id: 1,
            title: "Add Affiliate",
            image: "/assets/icons/affiliate-profile.png",
            path: "add-affiliate",
            rank: 2
        },
        {
            id: 2,
            title: "Services",
            image: "/assets/icons/new-service.png",
            path: "service",
            rank: 1
        },
        {
            id: 3,
            title: "Settlements",
            image: "/assets/icons/service.png",
            path: "settlements",
            rank: 1
        },
        // {
        //     id: 3,
        //     title: "Set Tariff",
        //     image: "/assets/icons/set-tariff.png",
        //     path: "",
        //     rank: 1
        // },
        // {
        //     id: 4,
        //     title: "Set Threshold",
        //     image: "/assets/icons/set-threshold.png",
        //     path: "",
        //     rank: 1
        // },
        // {
        //     id: 5,
        //     title: "Set Minimum",
        //     image: "/assets/icons/set-minimum.png",
        //     path: "",
        //     rank: 1
        // },
        // {
        //     id: 6,
        //     title: "Change Gateway Source",
        //     image: "/assets/icons/gateway-source.png",
        //     path: "",
        //     rank: 1
        // },
        {
            id: 7,
            title: "Remove Affiliate",
            image: "/assets/icons/restrict-affiliate.png",
            path: "remove-affiliate",
            rank: 5
        },
        // {
        //     id: 8,
        //     title: "Concentrator",
        //     image: "/assets/icons/concentrator.png",
        //     path: "",
        //     rank: 1
        // }

    ];

    const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
    return (
        <div className={classes}>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 gap-[40px] mt-[20px] items-center">
                {routes.map((item) => {
                    if (item.rank <= adminRank) {
                        return (
                            <Link
                                to={`/manager/configure/affiliates/${item.path}`}
                                key={item.id}
                                className="hover:shadow-md"
                            >
                                <div
                                    key={item.id}
                                    className="flex flex-col items-center  justify-center w-full bg-white  rounded-md p-[20px] h-[200px] gap-3"
                                >
                                    <img src={item.image} className="w-[78px]" alt="icon" />
                                    <Text as="p" classname={`font-bold`}>
                                        {item.title}
                                    </Text>
                                </div>
                            </Link>)
                    }
                })}
            </div>
        </div>
    );
}
