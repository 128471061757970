import React from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import AdminCountCard from "../../../components/admin-count-card";
import Button from "../../../components/button";
import Table from "../../../components/table";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";
import {
  energy_transactions_column,
  energy_transactions_data,
} from "../../../utils/table-utils/admin/energy-transactions-table";

export default function PrepaidWaterWrapper() {
  const [isBalanceHidden, setBalanceHidden] = React.useState(false);
  const navigate = useNavigate()

  const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
  return (
    <div className={classes}>
      {/* <div className='w-[70%]'>
        <Text as='p' classname='text-[20px]'>
          Wallet Balance
        </Text>
        <div className='pt-[15px] flex items-center justify-between'>
          <div className='flex items-center space-x-4'>
            <Text
              as='h5'
              classname='text-center  text-[28px] lg:text-[56px] font-bold'
            >
              {isBalanceHidden ? "*** *** ***" : "N500,000"}
            </Text>
            {isBalanceHidden ? (
              <AiFillEyeInvisible
                className='text-[#9799A5] text-[24px] cursor-pointer'
                onClick={() => setBalanceHidden((state) => !state)}
              />
            ) : (
              <AiFillEye
                className='text-[#9799A5] text-[24px] cursor-pointer'
                onClick={() => setBalanceHidden((state) => !state)}
              />
            )}
          </div>
          <Button classname='px-[80px]'>Fund Wallet</Button>
        </div>
      </div> */}
      <div className='grid grid-cols-3 gap-4 my-[40px]'>
        <AdminCountCard
          description='Total Customers'
          count={300}
          bg='bg-white'
          iconBg='bg-[#E6E6E9]/30'
          classname='w-full'
          onclick={()=>navigate("customers")}
        />
        <AdminCountCard
          description='Total Recharge October'
          bg='bg-[#F0D596]'
          iconBg='bg-[#9799A54D]/30'
          classname='w-full'
          platform='Prepaid Energy'
        />
        <AdminCountCard
          description='Total Recharge Today'
          bg='bg-[#F0D596]'
          iconBg='bg-[#9799A54D]/30'
          classname='w-full'
          platform='Prepaid Energy'
        />
        <AdminCountCard
          description='Total Credit'
          bg='bg-[#F0D596]'
          iconBg='bg-[#9799A54D]/30'
          classname='w-full'
          platform='Prepaid Energy'
        />
        <AdminCountCard
          description='Available Balance'
          bg='bg-[#F0D596]'
          iconBg='bg-[#9799A54D]/30'
          classname='w-full'
          platform='Prepaid Energy'
        />{" "}
        <AdminCountCard
          description='Total Consumption'
          bg='bg-[#F0D596]'
          iconBg='bg-[#9799A54D]/30'
          classname='w-full'
          platform='Prepaid Energy'
        />
      </div>

      <div className='my-[40px]'>
        <Text
          as='span'
          classname='text-[16px] lg:text-[23px] py-[20px] font-semibold text-[#6C6F7F]'
        >
          Transactions History
        </Text>

        <div className='w-full my-[40px] bg-white rounded-[16px] p-6'>
          <Table
            data={energy_transactions_data}
            columns={energy_transactions_column}
          />
        </div>
      </div>
    </div>
  );
}
