import React from "react";

export default function useTimer(time) {
  let day = Math.floor(time / 3600 / 24);
  let hour = Math.floor((time - day * 3600 * 24) / 3600);
  let minute = Math.floor(((time - hour * 3600) / 60) % 60);
  let second = time % 60;

  const [timer, setTimer] = React.useState({
    days: day,
    hours: hour,
    minutes: minute,
    seconds: second,
  });
  const [isComplete, setComplete] = React.useState(false);
  const resetTime = () => {
    setTimer({
      days: day,
      hours: hour,
      minutes: minute,
      seconds: second,
    });
    setComplete(false);
  };

  React.useEffect(() => {
    const timerHandler = setInterval(() => {
      if (timer.seconds === 0) {
        if (timer.minutes === 0) {
          if (timer.hours === 0) {
            if (timer.days === 0) {
              setComplete(true);
            } else {
              setTimer({
                seconds: 59,
                minutes: 59,
                hours: 23,
                days: timer.days - 1,
              });
            }
          } else {
            setTimer({
              seconds: 59,
              minutes: 59,
              hours: timer.hours - 1,
              days: timer.days,
            });
          }
        } else {
          setTimer({
            seconds: 59,
            minutes: timer.minutes - 1,
            hours: timer.hours,
            days: timer.days,
          });
        }
      } else {
        setTimer({
          seconds: timer.seconds - 1,
          minutes: timer.minutes,
          hours: timer.hours,
          days: timer.days,
        });
      }
    }, 1000);
    return () => clearInterval(timerHandler);
  });
  return { timer, resetTime, isComplete };
};
