import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";


import Text from "../../../text";
import Input from "../../../input";
import Button from "../../../button";
import Radio from "../../../radio";

export default function EditPeriodic({ data }) {
    const [validationAttempt, setValidationAttempt] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [debtCounter, setDebtCounter] = React.useState("");
    const [nextPayment, setNextPayment] = React.useState("");
    const [flag, setFlag] = React.useState(false);

    const [selectedCharge, setSelectedCharge] = React.useState("");
    const navigate = useNavigate();
    const authUser = useAuthUser();
    const formValues = {
        debtCounter: debtCounter,
    };

    const validationSchema = Yup.object({
        debtCounter: Yup.number().required("Debt Counter is Required"),
    });

    const onSubmit = (values) => {
        const userid = data.userid;
        setIsLoading(true)
        setValidationAttempt(false);
        axios.post("https://pm-server.herokuapp.com/api/v2.0/manager/set-user-charges?query=edit-periodic", {
            userid,
            data: {
                ...values,
                flag,
                slug: selectedCharge,
                nextPayment,
            }
        }, {
            headers: {
                Authorization: authUser().authToken,
            },
        }).then(res => {
            navigate('/manager/configure/user-management')
            toast.success(res.data.message)
        }).catch(err => {
            toast.error(err.response.data.message)
        }).finally(() => {
            setIsLoading(false)
        })
    };

    const handleChargeChange = (e) => {
        const selectedSlug = e.target.value;
        setSelectedCharge(selectedSlug);
        const selectedChargeDetails = data.charges.find(
            (charge) => charge.slug === selectedSlug
        );
        setDebtCounter(selectedChargeDetails.debtCounter);
        setNextPayment(selectedChargeDetails.nextPayment);
        setFlag(selectedChargeDetails.flag);
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formValues,
        onSubmit,
        validationSchema,
        validateOnBlur: false,
        validateOnChange: validationAttempt,
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            <div>
                <Text
                    as="h3"
                    weight="bold"
                    classname="text-[24px] lg:text-[30px] text-center"
                >
                    Edit Periodic Charge
                </Text>
                <div className="my-6 flex flex-col space-y-6">
                    <select
                        name=""
                        className="w-full h-[40px] bg-[#E6E6E9] border-none rounded-md capitalize outline-none my-2"
                        id=""
                        value={selectedCharge}
                        onChange={handleChargeChange}
                    >
                        <option value="">Select Charge</option>
                        {
                            data?.charges.map((item, index) => {
                                return (
                                    <option value={item.slug}>{item.slug}</option>
                                )
                            })
                        }
                    </select>

                    <div>
                        {
                            selectedCharge && (
                                <>
                                    <Input
                                        label="Debt Counter:"
                                        type="number"
                                        name="debtCounter"
                                        defaultValue={debtCounter}
                                        value={formik.values.debtCounter}
                                        onchange={formik.handleChange}
                                        onblur={formik.handleBlur}
                                        errorMessage={formik.errors.debtCounter}

                                    />
                                    <div className="my-2 flex flex-col space-y-2">
                                        <Text as="h3" weight="semi-bold" classname="text-[18px] text-left">
                                            Flag:
                                        </Text>
                                        <div className="flex flex-row space-x-6">
                                            <Radio
                                                name="filter-type"
                                                label="Yes"
                                                classname="text-[16px]"
                                                value={flag === true ? "Yes" : "No"}
                                                onchange={() => setFlag(true)}
                                                checked={flag === true}
                                                onclick={() => setFlag(true)}

                                            />
                                            <Radio
                                                name="filter-type"
                                                label="No"
                                                classname="text-[16px]"
                                                value={flag === false ? "No" : "Yes"}
                                                onchange={() => setFlag(false)}
                                                checked={flag === false}
                                                onclick={() => setFlag(false)}

                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
                <Button isLoading={isLoading}
                    onclick={() => setValidationAttempt(true)}
                    buttonType="submit" classname="w-full">
                    Edit
                </Button>
            </div>
        </form>
    );
}
