import React from 'react';
import Button from '../../../../components/button';
import { CgTrash } from "react-icons/cg";
import ModalWrapper from '../../../../components/modals/modal-wrapper';
import GatePassData from '../../../../components/modals/admin/verify-gate-pass';
import Text from '../../../../components/text';


export default function AccessRecordsAction({ value, row }) {
    // console.log(row.original);
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [modalType, setModalType] = React.useState("");

    const handleButtonClick = (type) => {
        setModalType(type);
        setModalOpen(true);
    };

    // console.log(row.original);
    return (
        <React.Fragment>
            <div className="flex items-center space-x-6">
                {/* <FiLock color="gray" /> */}

                {row.original.sign_in === "Pending" && (
                    <Button
                        variant="text"
                        classname=" w-fit  text-secondary-dark"
                        onclick={() => handleButtonClick("sign-in")}
                    >
                        Sign In
                    </Button>
                )}
                {row.original.sign_in === "Signed In" && (
                    <Button
                        variant="text"
                        classname=" w-fit  text-secondary-dark"
                        onclick={() => handleButtonClick("sign-out")}
                    >
                        Sign Out
                    </Button>
                )}

                {row.original.sign_in !== "Signed In" &&
                    <Button
                        variant="text"
                        iconLeft={<CgTrash />}
                        classname=" w-fit  text-secondary-dark"
                        onclick={() => handleButtonClick("remove")}
                    />}
                <Button
                    variant="text"
                    classname="w-fit text-sm"
                    onclick={() => handleButtonClick("view-more")}
                >
                    View More
                </Button>
            </div>

            <ModalWrapper
                isOpen={isModalOpen}
                handleModalClose={() => setModalOpen(false)}
            >
                {modalType === "sign-in" && <GatePassData data={row.original} type="Sign In" />}
                {modalType === "sign-out" && <GatePassData data={row.original} type="Sign Out" />}
                {modalType === "remove" && <></>}
                {modalType === "view-more" && <>
                    <div>
                        <Text
                            as="h3"
                            weight="bold"
                            classname="text-[24px] lg:text-[30px] text-center"
                        >
                            More Details
                        </Text>

                        <div>
                            <table class="min-w-full">
                                <thead>
                                    <tr>
                                        <th class="px-4 py-2">Check-in</th>
                                        <th class="px-4 py-2">Check-out</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="border px-4 py-2">
                                            <div>
                                                <Text
                                                    as="p"
                                                    weight="medium"
                                                    classname=" text-left"
                                                >
                                                    Pouplation: {row.original.checkin.population}
                                                </Text>
                                            </div>
                                            <div>
                                                <Text
                                                    as="p"
                                                    weight="medium"
                                                    classname="text-left"
                                                >
                                                    Comments: {row.original.checkin.comment}
                                                </Text>

                                            </div>
                                        </td>
                                        <td class="border px-4 py-2">

                                            <div>
                                                <Text
                                                    as="p"
                                                    weight="medium"
                                                    classname=" text-left"
                                                >
                                                    Pouplation: {row.original.checkout.population}
                                                </Text>
                                            </div>
                                            <div>
                                                <Text
                                                    as="p"
                                                    weight="medium"
                                                    classname="text-left"
                                                >
                                                    Comments: {row.original.checkout.comment}
                                                </Text>

                                            </div>
                                        </td>
                                    </tr>
                                    {/* <!-- Add more rows as needed --> */}
                                </tbody>
                            </table>

                        </div>
                    </div>

                </>}

            </ModalWrapper>
        </React.Fragment>
    );
}