import React from "react";
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";
import Text from "./text";
import { ImArrowLeft2, ImArrowRight2 } from "react-icons/im";
import { useSelectedRows } from "../store/admin/configure";
import ReactPaginate from "react-paginate";
import Input from "./input";

export default function Table({
  data,
  columns,
  search = "",
  hidePagination,
  pageSize = 10,
  isLoading,
}) {
  const [requiredPage, setRequiredPage] = React.useState();

  const memoizedColumns = React.useMemo(() => columns, [columns]);
  const memoizedData = React.useMemo(() => data, [data]);
  const tableInstance = useTable(
    {
      columns: memoizedColumns ?? [],
      data: memoizedData ?? [],
      initialState: { pageSize },
    },
    useGlobalFilter,
    usePagination,
    useRowSelect
  );
  const {
    state,
    page,
    canNextPage,
    nextPage,
    canPreviousPage,
    previousPage,
    gotoPage,
    pageCount,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    selectedFlatRows,
    // rows,
    prepareRow,
  } = tableInstance;

  const handleSearch = useAsyncDebounce(() => {
    tableInstance.setGlobalFilter(search || undefined);
  });

  const { setSelectedRows } = useSelectedRows();

  React.useEffect(() => {
    setSelectedRows(selectedFlatRows);
  }, [selectedFlatRows]);

  React.useEffect(() => {
    handleSearch();
  }, [search, handleSearch]);

  if (isLoading) {
    return (
      <div
        className={`w-full min-h-[300px] bg-gradient-to-br from-white to-gray-100 flex items-center justify-center`}
      >
        <div className="w-[80px] h-[80px]">
          <img
            src="/assets/icons/loader2.svg"
            alt="loading..."
            className="w-full h-full object-fill"
          />
        </div>
      </div>
    );
  }

  if (data?.length === 0) {
    return (
      <div
        className={`w-full min-h-[350px] bg-gradient-to-br from-white to-gray-100 flex items-center justify-center`}
      >
        <div className="text-center">
          <div className="w-[200px] lg:w-[250px] h-[200px] lg:h-[250px]">
            <img
              src="/assets/icons/not-found.png"
              alt="No Data found"
              className="w-full h-full object-contain"
            />
          </div>
          <Text
            weight="semibold"
            as="h2"
            classname="text-primary-dark leading-loose"
          >
            No Data Found
          </Text>
          {/* <img
          src="/assets/icons/loader2.svg"
          alt="loading..."
          className="w-full h-full object-fill"
        /> */}
        </div>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div className="max-w-[100%] overflow-auto scrollbar-thin">
        <table {...getTableProps()} className="min-w-full">
          <thead style={{ minWidth: "100%", borderRadius: 20 }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, id) => (
                  <th
                    key={id}
                    {...column.getHeaderProps()}
                    style={{ padding: "12px 30px 12px 30px" }}
                    className="bg-[#F5F5FE] first-of-type:rounded-l-[16px] last-of-type:rounded-r-[16px]"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, id) => {
              prepareRow(row);
              return (
                <tr
                  key={id}
                  {...row.getRowProps()}
                  className="even:bg-gray-50/50"
                >
                  {row.cells.map((cell, id) => (
                    <td
                      key={id}
                      {...cell.getCellProps()}
                      style={{
                        padding: "15px 30px 15px 30px",
                        borderBottom: "2px solid #EEF1F499",
                        minWidth: "100px",
                        width: "fit-content",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {!hidePagination && (
        <div className="w-fit max-w-full mx-auto">
          <div className="w-fit mx-auto flex space-x-1 mt-[30px] max-w-full overflow-x-auto scrollbar-none">
            <div
              className={`min-w-[35px] h-[35px] rounded-l-[10px] flex items-center justify-center ${
                canPreviousPage
                  ? "bg-primary-dark cursor-pointer text-white"
                  : "bg-primary-dark/40 cursor-not-allowed text-gray-300"
              }`}
              onClick={() => canPreviousPage && previousPage()}
            >
              <ImArrowLeft2 />
            </div>
            <div className="flex items-center space-x-1">
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={(page) => gotoPage(page.selected)}
                forcePage={state.pageIndex}
                pageRangeDisplayed={0}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                pageClassName="w-[35px] h-full flex items-center justify-center rounded-[4px] font-semibold"
                className="flex space-x-1 h-full"
                activeClassName="bg-secondary-dark/40"
              />
            </div>
            {/* {Array.from({ length: pageCount }).map((page, index) => (
            <Text
              key={index}
              as="p"
              weight="semibold"
              classname={`w-[35px] rounded-[4px] ${
                state.pageIndex === index
                  ? "bg-secondary-dark/40"
                  : "bg-secondary-dark/20"
              } flex items-center justify-center`}
              onclick={() => gotoPage(index)}
            >
              {index + 1}
            </Text>
          ))} */}
            <div
              className={`min-w-[35px] h-[35px] rounded-r-[10px] flex items-center justify-center ${
                canNextPage
                  ? "bg-primary-dark cursor-pointer text-white"
                  : "bg-primary-dark/40 cursor-not-allowed text-gray-300"
              }`}
              onClick={() => canNextPage && nextPage()}
            >
              <ImArrowRight2 />
            </div>
          </div>
          <div className="mt-2 flex items-center justify-between space-x-6">
            <Text as="p" weight="medium" classname="">
              Page <span className="font-bold">{state.pageIndex + 1}</span> of{" "}
              <span className="font-bold">{pageCount}</span>
            </Text>
            <div className="flex items-center space-x-2">
              <Text as="p" weight="semibold">
                GOTO
              </Text>
              <div className="flex">
                <div className="w-[50px] border-2 border-r-0 border-gray-300 rounded-l-[6px] overflow-hidden px-2 ">
                  <input
                    type="number"
                    className="outline-none border-none lg:text-[14px] font-semibold"
                    placeholder="Page"
                    onChange={({ target }) => setRequiredPage(target.value)}
                  />
                </div>
                <div
                  className="bg-primary-dark flex item-center justify-center text-white px-2 rounded-r-[6px] cursor-pointer"
                  onClick={() => gotoPage(requiredPage - 1)}
                >
                  <Text as="span">Go</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
