import React, { useCallback, useEffect } from "react";
import Table from "../../../components/table";
import { clx } from "../../../utils/clx";
import Input from "../../../components/input";
import Text from "../../../components/text";
import ModalWrapper from "../../../components/modals/modal-wrapper";
import { BsSearch } from "react-icons/bs";
import useAdminUserList from "../../../hooks/apis/admin/useAdminUserList";
import { useSelectedRows } from "../../../store/admin/configure";
import { toast } from "react-toastify";
import { credit_meter_column } from "../../../utils/table-utils/admin/credit-meter-table";
import CreditMeterModal from "../../../components/modals/admin/credit-meter";
import { useNavigate } from "react-router-dom";


const CreditMeter = () => {
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [selectedType, setSelectedType] = React.useState("energy");
    const [filteredList, setFilteredList] = React.useState([]);
    const { selectedRows } = useSelectedRows();
    const [modalType, setModalType] = React.useState("credit");
    const navigate = useNavigate();

    const {
        data: userList,
        isLoading: isUserListLoading,
        isFetching: isUserListFetching,
    } = useAdminUserList()
    const loadMeters = useCallback(() => {
        setFilteredList([]);
        userList?.data?.userList?.length && userList?.data?.userList?.map((item) => {
            const { fullname, apartment, energyid, waterid } = item;
            const isEnergy = selectedType.toLowerCase() === "energy";
            if ((isEnergy && energyid.length) || (!isEnergy && waterid.length)) {
                setFilteredList((prev) => [
                    ...prev,
                    {
                        fullname,
                        apartment,
                        meterid: isEnergy ? energyid : waterid,
                        type: selectedType,
                    },
                ]);
            }
        });
    }, [selectedType, userList]);

    useEffect(() => {
        loadMeters()

    }, [loadMeters])

    const handleSelectType = useCallback((e) => {
        setSelectedType(e.target.value)
    }, [])

    useEffect(() => {
        setSelectedType("energy")
    }, []);
    const handleCreditMeterClick = useCallback(() => {
        if (selectedRows.length === 0) {
            return toast.error("Please select a row")
        }
        else if (selectedRows.length > 10) {
            return toast.error("Please select a max of 10 rows")
        }
        else {
            setModalOpen(true);
        }
    }, [selectedRows]);
    const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
    return (
        <React.Fragment>

            <div className={classes}>
                <div className="max-w-full h-full rounded-[8px] bg-white p-6 flex flex-col space-y-6 relative">
                    <div className="flex flex-row justify-between items-center">
                        <Text
                            as="p"
                            weight="semibold"
                            classname="lg:text-[16px] text-secondary-dark cursor-pointer hover:underline"
                            onclick={handleCreditMeterClick}
                        >
                            Credit Meter
                        </Text>
                        <div className="flex justify-end items-center space-x-6">

                            <div className="flex flex-row gap-3 items-center">
                                <Text as="p" weight="semibold">
                                    Meter Type:
                                </Text>
                                <select name="" id=""
                                    value={selectedType}
                                    onChange={handleSelectType}
                                    className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
                                >
                                    <option value="energy">Energy</option>
                                    <option value="water">Water</option>
                                </select>
                            </div>
                            <div className="flex items-center">
                                <Input
                                    iconLeft={<BsSearch />}
                                    placeholder="Search By Meter ID"
                                    inputClass="py-[6px] px-[15px] rounded-r-0"
                                    onchange={({ target }) => setSearch(target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <Table
                        search={search}
                        data={filteredList ?? []}
                        columns={credit_meter_column}
                        isLoading={isUserListLoading || isUserListFetching}
                    />
                </div>
            </div>

            <ModalWrapper
                isOpen={isModalOpen}
                handleModalClose={() => {
                    if (modalType === "csv") { navigate("/manager/configure/user-management") }
                    else
                        setModalOpen(false)
                }
                }>
                <CreditMeterModal selectedRows={selectedRows} selectedType={selectedType} modalType={modalType} setModalType={setModalType} />

            </ModalWrapper>
        </React.Fragment>
    );
};

export default CreditMeter;
