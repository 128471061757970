import Text from "../../../components/text";

export const fuel_monitory_data = Array.from({ length: 10 }).map((data, i) => ({
  _id: i + 1,
  // name: `Guarantee Trust Bank-${i+1}`,
  name: " "
}));

export const fuel_monitory_column = [
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        S/N
      </Text>
    ),
    //     Cell: TableIcon,
    accessor: "_id",
  },
  {
    //     id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Name
      </Text>
    ),
    accessor: "name",
  },
  {
    id: "_",
    Header: (
      <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Actions
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className='flex items-center space-x-4'>
        <Text as='p' classname='text-left text-[16px] text-secondary-dark'>
          View Overall
        </Text>
        {/* <Text as='p' classname='text-left text-[16px]'>
          Restrict
        </Text> */}
      </div>
    ),
    accessor: "_id",
  },
];
