import React from "react";
import { FaAppStore, FaGooglePlay } from "react-icons/fa";
import Button from "../../components/button";
import Text from "../../components/text";
import { clx } from "../../utils/clx";
import IotSolutionsSection from "./components/iot-solutions-section";
import FeaturesSection from "./components/features-section";
import TrustedPartners from "./components/trusted-partners";
import FAQSection from "./components/faq-section";
import Clients from "./components/clients";
import Input from "../../components/input";
import { useNavigate } from "react-router-dom";
import useBuyElectricityData from "../../store/static/buy-electricity";
import axios from "axios";
import { toast } from "react-toastify";
import { AiOutlineBarcode } from "react-icons/ai";
import Ratings from "./components/Ratings";
import Collab from "./components/Collab";
import Services from "./components/Services";
import TextTransition, { presets } from 'react-text-transition';
import Community from "./components/Community";
import ModalWrapper from "../../components/modals/modal-wrapper";
import ScheduleDemo from "./Schedule-demo";

export default function HomepageWrapper() {
  const [meterId, setMeterId] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [erroMessage, setErrorMessage] = React.useState("");
  const { setElectricityInfo } = useBuyElectricityData();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const classes = clx("w-[93%] lg:w-[90%] flex flex-col mx-auto pt-8");

  React.useEffect(() => {
    const intervalId = setTimeout(() => {
      // toast.info( 'Request A Meter', {
      //   position: toast.POSITION.TOP_CENTER,
      //   autoClose: 1000,
      //   toastId:'custom-id-yes',
      //   pauseOnFocusLoss: false
      //   // autoClose: false,
      // });
      setIsModalOpen(true)
    }, 12000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleBuyElectricity = (e) => {
    e.preventDefault();
    if (!meterId) {
      setErrorMessage("Please enter your meter Id");
      return;
    }
    setIsLoading(true);
    axios
      .get(
        `https://api-pm-e1ea777feac4.herokuapp.com/search-meter?meterid=${meterId}`
      )
      .then((res) => {
        const isDisco = res?.data?.data.host === "disco";
        // console.log(res.data.data)
        if (isDisco) {
          setElectricityInfo(res.data.data);
          toast.success(res.data.message);
          navigate(`/buy-electricity/${meterId}`);
        } else {
          navigate(
            `/signup?auth=${meterId}&&auth-data=${JSON.stringify({
              ...res?.data?.data,
            })}`
          );
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.data.message === "Meter already Exist.!!!") {
          setErrorMessage( `${error.response.data.message} please proceed to sign in`);
          navigate("/login");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [index, setIndex] = React.useState(0);

  React.useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      10000, // every 10 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  const TEXTS = [
    "Simplified Electricity Bill Settlement!",
    "Hassle-free Power Payment Experience!",
    "Effortless Electricity Invoice Processing!",
    "Streamlined Energy Bill Payments!",
    "Convenient Power Expense Settlement!",
    "Easy Electricity Payment!"
  ];

  const ButMeter =[
    'Buy A Meter',
    'Request A Meter',
    'Purcahase A Meter'
  ]

 
  return (
    <React.Fragment>
      <div className={classes}>
        {/* hero */}
        <div className="flex flex-col gap-[72] lg:flex-row  lg:justify-between items-center h-full lg:h-[85vh]">
          {/* left side */}
          <div className="flex flex-col max-w-[342px] lg:max-w-[700px] w-full space-y-8">
          <Text
              as="h1"
              weight="bold"
              classname="text-2xl md:text-4xl lg:text-5xl xl:text-6xl leading-8 mb-8"
              style={{
                animation: 'fadeInOut 4s infinite',
              }}
            >
              <span>{TEXTS[index % TEXTS.length]}</span>
            </Text>
            <Text
              as="p"
              weight="normal"
              classname="text-[12px] lg:text-[20px] text-[#6C6F7F]
"
            >
            Central Vending for your communities, simplifying bills Collection, Improving Community Security With Our Smart Gate and Visitor Management
            </Text>
            <form
              onSubmit={handleBuyElectricity}
              className="flex flex-col bg-[#F8F8FF] space-y-6  lg:max-w-[503px] w-full py-[35px] lg:py-[60px] px-[31px] lg:px-[80px] border rounded-2xl"
            >
              <Input
                type="number"
                placeholder="Enter your meter Id"
                value={meterId}
                iconLeft={<AiOutlineBarcode />}
                disabled={isLoading}
                onchange={(e) => setMeterId(e.target.value)}
                errorMessage={erroMessage}
                onblur={() => setErrorMessage("")}
              />

              <Button
                buttonType="submit"
                classname="w-ful"
                isLoading={isLoading}
              >
              Buy Electricity

              </Button>
            </form>
            {/* buy meter*/}
                  {/* schedule and refere button*/}
      <Demo setIsModalOpen={setIsModalOpen} navigate={navigate}/>
         
           
          </div>

          {/* right side  media downlaod*/}
          

          <div className="flex flex-col mt-[72px] lg:mt-0 h-full gap-[20px] justify-between lg:mr-10">
            {/* download links */}
            <StoreDownlaod />
            {/* image */}
            <div className="ml-auto">
              <img
                src="/assets/images/hero-img.png"
                alt="hero"
                className="w-[329px] md:w-full"
              />
            </div>
          </div>
        </div>
      </div>
     {/* Rating folly*/}
     <Ratings/>
    {/* <Ratings/>*/}
     {/* Colab foll*/}
     
     <Collab/>
    {/* community*/}
    <Community/>
      {/* iot solutions */}
      
      {/*<IotSolutionsSection />*/}
      {/*sevice*/}
      
      <Services/>
      {/*<FeaturesSection /> */}
      

      {/* trusted Patners */}
      
      {/*<TrustedPartners /> */}

      {/* Faq section */}
      <FAQSection />
      

      {/* clients */}
      {/* schedule and refere button*/}
      <div className="px-6 lg:px-[140px] flex justify-center py-6 lg:py-[140px] ">
      <Demo setIsModalOpen={setIsModalOpen} navigate={navigate}/>
      </div>
     
      <Clients />
      <ModalWrapper
isOpen={isModalOpen}
handleModalClose={() => setIsModalOpen(false)}
onClick={({ target, currentTarget }) =>
target === currentTarget && setIsModalOpen(false)
}
>
<ScheduleDemo setIsModalOpen={setIsModalOpen}/>
</ModalWrapper>
     
    </React.Fragment>
  );
}



export const Demo =({setIsModalOpen,navigate})=>{
  return(
    <div className="lg:max-w-[503px] w-full flex flex-col md:flex-row gap-3">
             
    <Button
    buttonType="submit"
    onclick={()=>navigate('/marketplace')}
    classname='w-full md:w-1/2'
    >
    Get A Meter
    </Button>
    <Button
    buttonType="submit"
    // onclick={()=>setIsModalOpen(true)}
    onclick={()=>navigate('/demo')}
    classname='w-full md:w-1/2'
              >
  Schedule A Demo
  </Button>
    
    </div>
  )
}
export function DownLoadLink({ iconLeft, title, link ,bgColor}) {
  return (
    <div className={`px-4 py-[11px] rounded-[8px] lg:rounded-[16px] ${ bgColor ?'bg-white':'bg-primary-dark'}`}>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <button className={`${ bgColor ?'text-primary-dark':'text-white'} flex gap-2 items-center`}>
          {iconLeft}
          <div className="flex flex-col  justify-start">
            <p className="text-[8px] lg:text-[11px] text-left">Get it on</p>
            <h3 className="text-left text-[12px] lg:text-lg font-bold">
              {title}
            </h3>
          </div>
        </button>
      </a>
    </div>
  );
}


export  function StoreDownlaod ({bgColor}){
  return(
    <div className="flex space-x-6">
              <DownLoadLink
              bgColor={bgColor}
                iconLeft={
                  <FaAppStore className={`${ bgColor ?'text-primary-dark':'text-white'} w-6 lg:w-8 h-6 lg:h-8`} />
                }
                title="App Store"
                link="https://apps.apple.com/us/app/prepaid-meter/id6448630946"
              />
              <DownLoadLink
              bgColor={bgColor}
                iconLeft={
                  <FaGooglePlay className={`${ bgColor ?'text-primary-dark':'text-white'} w-6 lg:w-8 h-6 lg:h-8`} />
                }
                title="Google Play"
                link="https://play.google.com/store/apps/details?id=com.millzbdm.prepaidmeter"
              />
            </div>
  )
}



HomepageWrapper.DownLoadLink = DownLoadLink;


