import React from "react";
import { BsSearch } from "react-icons/bs";

import Table from "../../../components/table";
import { clx } from "../../../utils/clx";
import Input from "../../../components/input";
import useAdminUserList from "../../../hooks/apis/admin/useAdminUserList";
import { users_column } from "../../../utils/table-utils/admin/users-table";
import { CSVLink } from "react-csv";
import { FiDownload } from "react-icons/fi";
import Text from "../../../components/text";
import Button from "../../../components/button";
import { UserListHeader } from "./userlist";

export default function Users() {
    const [search, setSearch] = React.useState("");
    const {
        data: userList,
        isLoading: isUserListLoading,
        isFetching: isUserListFetching,
    } = useAdminUserList()
    console.log(userList)
    const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
    return (
        <React.Fragment>
            <div className={classes}>
                <div className="max-w-full h-full rounded-[8px] bg-white p-6 flex flex-col space-y-6 relative">
                    <div className="flex flex-row justify-end">
                        <div className="flex items-center gap-3">
                            <Input
                                iconLeft={<BsSearch />}
                                placeholder="Search By Meter ID"
                                inputClass="py-[6px] px-[15px] rounded-r-0"
                                onchange={({ target }) => setSearch(target.value)}
                            />
                            <CSVLink
                            data={userList?.data?.userList ?? []}
                            // data={EnergyVendInfo?.data ?? []}
                            headers={UserListHeader}
                            // headers={EnergyCSVHeader}
                            filename="User List.CSV"
                          >
                            <Button iconLeft={<FiDownload />} variant="none">
                              <Text as="p" weight="semibold">
                                Download
                              </Text>
                            </Button>
                          </CSVLink>
                        </div>
                    </div>
                    <Table
                        search={search}
                        data={userList?.data?.userList ?? []}
                        columns={users_column}
                        isLoading={isUserListLoading || isUserListFetching}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}