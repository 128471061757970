import React, { useEffect } from "react";
import { clx } from "../../utils/clx";
import CustomSlider from "./components/CustomSlider";
import { useAdminBlog } from "../../hooks/apis/admin/useAdminBlog";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import * as IMG from "./../../images";
import Text from "../../components/text";

const classes = clx("w-[93%] lg:w-[90%] flex flex-col mx-auto pt-8");

const Blog = () => {
  const { data: BLOG, isLoading, refetch } = useAdminBlog();
  
  useEffect(() => {
    refetch();
  }, [refetch]);

  console.log(BLOG)
  if (isLoading) {
    return (
      <section className={"flex justify-center items-center h-screen"}>
        <img
          src={IMG.blogLoader}
          alt="Loading..."
          className="object-cover w-auto h-auto"
        />
      </section>
    );
  }

  if (BLOG?.length === 0 || BLOG === undefined) {
    return (
      <div
        className={`w-full h-screen bg-gradient-to-br from-white to-gray-100 flex items-center justify-center`}
      >
        <div className="text-center">
          <div className="w-[200px] lg:w-[250px] h-[200px] lg:h-[250px]">
            <img
              src="/assets/icons/not-found.png"
              alt="No Data found"
              className="w-full h-full object-cover"
            />
          </div>
          <Text
            weight="semibold"
            as="h2"
            classname="text-primary-dark leading-loose"
          >
            No Data Found
          </Text>

        </div>
      </div>
    );
  }

  return (
    <section className={classes}>
       <div>

          <main className="grid grid-cols-1  md:grid-cols-3 justify-between gap-10 mt-10">
          <PopularBlog BLOG={BLOG}/>
          <FeatureBlog BLOG={BLOG}/>
          
  
          </main>
       </div>
      
    </section>
  );
};

export default Blog;

const Card = ({ height = '200px', width = '1/3', pad = true, title, content, image,id }) => {
  const maxParagraphLength = pad ? 40 : 30;
  const paddedParagraph = content?.slice(0, maxParagraphLength).padEnd(maxParagraphLength + 10, " *");

  return (
    <section className="border-t-4 border-t-[#E1AA2B] flex gap-5 w-full mb-4 py-3">
      {image && (
        <img
          src={image}
          alt={''}
          className={`w-${width} h-[${height}] object-cover`}
        />
      )}
      <div className={`py-3 flex flex-col justify-center ${image ? '' : 'w-full'}`}>
        <Text
          as="h2"
          weight="bold"
          classname=''
        >
          {title}
        </Text>
        {content && <div dangerouslySetInnerHTML={{ __html: paddedParagraph }} />}
        <Link
          className="flex items-center text-[#E1AA2B]"
          to={`/blog/${id}`}
        >
          Read more <FaLongArrowAltRight className="ml-1" />
        </Link>
      </div>
    </section>
  );
}



const PopularBlog = ({BLOG}) => (
  <section className="col-span-1 lg:col-span-2 w-full">
  <Text as='h2' weight="extrabold" classname='text-xl'>Discover Our Latest Post</Text>
     {
      BLOG.map((blog,index)=>(
        <Card key={index} title={blog.title} content={blog.content} id={blog.id} image={blog.image[0]}/>
      ))
     }
  </section>
);


const FeatureBlog = ({ BLOG }) => (
  <section className="col-span-1 w-full">
  <Text as='h2' weight="extrabold" classname='text-xl'>Our Features Post</Text>
  {
    BLOG.map((blog,index)=>(
      <Card key={index} title={blog.title} image={blog.image[0]} id={blog.id}  height='100px' width='100px' pad={false}/>
    ))
   }
  </section>
  );


