import React from "react";
import { Route } from "react-router-dom";
import { adminMenu } from "../constants/admin-menu";
import usePageTitle from "../hooks/usePageTitle";
import ProtectedAdminDashboard from "../layouts/dashboard/admin/protected-admin-dashboard";
import AdminChatPage from "../pages/admin/chat";
import Dashboard from "../pages/admin/dashboard";
import FuelMonitoryPage from "../pages/admin/fuel-monitory";
import PrepaidEnergyPage from "../pages/admin/prepaid-energy";
import PrepaidWaterPage from "../pages/admin/prepaid-water";
import RequestPage from "../pages/admin/request";
// import SettingsPage from "../pages/admin/settings";
import Wallet from "../pages/admin/wallet";
import SingleGridCompanyPage from "../views/admin/fuel-monitory/grid-company";
import PrepaidEnergyCustomers from "../views/admin/prepaid-energy/customers";
import SingleUserEnergyDashboard from "../views/admin/prepaid-energy/single-user-dashboard";
import ConfigurePage from "../pages/admin/configure";
import ClearTamper from "../views/admin/configure/clear-tamper";
import PaymentsInvocesPage from "../pages/admin/payments-invoices";
import SetChargesPage from "../pages/admin/set-charges";
import GatePassPage from "../pages/admin/gate-pass";
import AdminMangementPage from "../pages/admin/admin-management";
import UserMangementPage from "../pages/admin/user-management";
import AffiliatePage from "../pages/admin/affiliate";
import AllDashboardTransactionsPage from "../views/admin/dashboard/all-transactions";
import AffiliateEnergyTransactions from "../views/admin/prepaid-energy/single-user-dashboard/energy-transaction.jsx";
// import AffiliateBarEnergyStatistics from "../views/admin/prepaid-energy/single-user-dashboard/energy-statistics";
import RestrictUser from "../views/admin/configure/restrict-user";
import CheckReference from "../views/admin/configure/check-reference";
import PeriodicInsights from "../views/admin/configure/set-charges/periodic/insights";
import RegularInsights from "../views/admin/configure/set-charges/regular/insights";
import SwitchMeter from "../views/admin/configure/switch-meter";
import SendToken from "../views/admin/configure/send-token";
import PaymentHistory from "../views/admin/configure/payments-history.jsx";
import CreditMeter from "../views/admin/configure/credit-meter";
import ResetUser from "../views/admin/configure/reset-user";
import ManagersProfile from "../views/admin/configure/managers-profile";
import RestrictManager from "../views/admin/configure/restrict-manager";
import UserCharges from "../views/admin/configure/user-charges";
import AddAffiliates from "../views/admin/configure/add-affiliates";
import AffiliateServices from "../views/admin/configure/affiliate-services";
import ServiceSlug from "../views/admin/configure/affiliate-services/service-slug";
import SercviceInsights from "../views/admin/configure/affiliate-services/service-slug/service-insights";
import AddNewMeter from "../views/admin/configure/add-new-meter";
import UnregisteredUsers from "../views/admin/configure/unregistered-users";
import Users from "../views/admin/configure/users";
import AffiliateSettlements from "../views/admin/configure/affiliate-setlements";
import MeterForm from "../views/admin/configure/meter-form/index.jsx";
import RemoveAffiliate from "../views/admin/configure/remove-affiliates";
import AdminCharges from "../pages/admin/charges/index.js";
import CustomersCharges from "../views/admin/charges/customers-charges.jsx";
// import SingleUserCharges from "../views/admin/prepaid-energy/single-user-dashboard";
import AffiliateChargesTransactions from "../views/admin/prepaid-energy/single-user-dashboard/energy-transaction.jsx";
// import AffiliateBarChartStatistics from "../views/admin/charges/charges-admin/single-charges-dashboard/energy-statistics.jsx";
import AffiliateBarChargesChartStatistics from "../views/admin/charges/single-charges-dashboard/charges-statistics.jsx";
import SingleUserChargesDashboard from "../views/admin/charges/single-charges-dashboard/index.jsx";
import CreateBlogpage from "../pages/static-pages/CreateBlogPage.jsx";
import MeterRequest from "../views/admin/configure/meter-request.jsx";
import DemoRequest from "../views/admin/configure/book_demo/index.jsx";
import ReferrerlRequest from "../views/admin/configure/Referrer/index.jsx";
import GuestPage from "../pages/admin/guest.jsx";

export default function GetAdminRoutes() {
  const titledRoutes = [
    ...adminMenu,
    {
      id: 1,
      pageTitle: "Prepaid Energy",
      route: "/manager/energy",
    },
    {
      id: 2,
      pageTitle: "Prepaid Energy Customers",
      route: "/manager/energy/customers",
    },
    {
      id: 3,
      pageTitle: "Prepaid Water",
      route: "/manager/water",
    },
    {
      id: 4,
      pageTitle: "Prepaid Water Customers",
      route: "/manager/water/customers",
    },
    {
      id: 5,
      pageTitle: "Fuel Monitory",
      route: "/manager/fuel",
    },
    {
      id: 6,
      pageTitle: "Fuel Monitory Customers",
      route: "/manager/fuel/customers",
    },
    {
      id: 6,
      pageTitle: "Clear Tamper",
      route: "/manager/configure/cleartamper",
    },
    {
      id: 7,
      pageTitle: "Meter Form",
      route: "/manager/configure/form",
    },
    {
      id: 8,
      pageTitle: "Payment History",
      route: "/manager/configure/payment-history",
    },
    {
      id: 9,
      pageTitle: "Set Charges",
      route: "/manager/configure/charges",
    },
    {
      id: 10,
      pageTitle: "Verify Gate Pass",
      route: "/manager/configure/gatepass",
    },
    {
      id: 11,
      pageTitle: "Admin Management",
      route: "/manager/configure/admin-management",
    },
    {
      id: 12,
      pageTitle: "User Management",
      route: "/manager/configure/user-management",
    },
    {
      id: 13,
      pageTitle: "Affiliates",
      route: "/manager/configure/affiliates",
    },
  ];
  const pageTitle = usePageTitle(titledRoutes);
  return (
    <Route
      path="/manager"
      element={<ProtectedAdminDashboard pageTitle={pageTitle} />}
    >
      <Route path="blog" element={<CreateBlogpage />} />

      <Route index element={<Dashboard />} />
      <Route path="wallet" element={<Wallet />} />
      <Route path="transactions" element={<AllDashboardTransactionsPage />} />
      
      <Route path="energy">
        <Route index element={<PrepaidEnergyPage />} />
        <Route path="customers" element={<PrepaidEnergyCustomers />} />
        <Route
          path="customers/single"
          element={<SingleUserEnergyDashboard />}
        />
        <Route
          path="customers/single/transactions"
          element={<AffiliateEnergyTransactions />}
        />
        <Route
          path="customers/single/energy-statistics"
          element={<AffiliateBarChargesChartStatistics />}
        />
      </Route>

      <Route path="charges">
        <Route index element={<AdminCharges />} />
        <Route path="customers" element={<CustomersCharges />} />
        <Route
          path="customers/single"
          element={<SingleUserChargesDashboard />}
        />
        <Route
          path="customers/single/transactions"
          element={<AffiliateChargesTransactions />}
        />
        <Route
          path="customers/single/energy-statistics"
          element={<AffiliateBarChargesChartStatistics />}
        />
      </Route>

      <Route path="water">
        <Route index element={<PrepaidWaterPage />} />
        <Route path="customers" element={<PrepaidEnergyCustomers />} />
        <Route path="customers/:id" element={<SingleUserEnergyDashboard />} />
      </Route>

      <Route path="configure">
        <Route index element={<ConfigurePage />} />
        <Route path="cleartamper" element={<ClearTamper />} />

        {/* meter request */}
        <Route path="meter-request" element={<MeterRequest />} />
        {/**Guest page */}
        <Route path="guest">
          <Route index element={<GuestPage />} />
          <Route path="demo-request" element={<DemoRequest />} />
          <Route path="referral-request" element={<ReferrerlRequest />} />
        </Route>
        <Route path="form">
          <Route index element={<MeterForm />} />    
        </Route>
        {/* <Route path="guest" element={<MeterForm />} /> */}
        {/* <Route path="settings" element={<SettingsPage />} /> */}

        <Route path="payment-history" element={<PaymentHistory />} />
        <Route path="payments-invoices" element={<PaymentsInvocesPage />} />
        {/* charges */}
        <Route path="charges" element={<SetChargesPage />} />
        <Route
          path="periodic-charges/:slug/:path"
          element={<PeriodicInsights />}
        />
        <Route
          path="regular-charges/:slug/:path"
          element={<RegularInsights />}
        />

        {/* gatepass */}
        <Route path="gatepass" element={<GatePassPage />} />

        {/* admin-management */}
        <Route path="admin-management">
          <Route index element={<AdminMangementPage />} />
          <Route path="managers-profile" element={<ManagersProfile />} />
          <Route path="restrict-manager" element={<RestrictManager />} />
        </Route>
        {/* user-management */}
        <Route path="user-management">
          <Route index element={<UserMangementPage />} />
          <Route path="restrict-user" element={<RestrictUser />} />
          <Route path="check-reference" element={<CheckReference />} />
          <Route path="switch-meter" element={<SwitchMeter />} />
          <Route path="send-token" element={<SendToken />} />
          <Route path="credit-meter" element={<CreditMeter />} />
          <Route path="reset-user" element={<ResetUser />} />
          <Route path="user-charges" element={<UserCharges />} />
          <Route path="new-meter" element={<AddNewMeter />} />
          <Route path="unregistered-users" element={<UnregisteredUsers />} />
          <Route path="users" element={<Users />} />
        </Route>

        {/* affiliates */}

        <Route path="affiliates">
          <Route index element={<AffiliatePage />} />
          <Route path="add-affiliate" element={<AddAffiliates />} />
          <Route path="remove-affiliate" element={<RemoveAffiliate />} />
          <Route path="settlements" element={<AffiliateSettlements />} />
          <Route path="service">
            <Route index element={<AffiliateServices />} />
            <Route path=":slug">
              <Route index element={<ServiceSlug />} />
              <Route path=":slug" element={<SercviceInsights />} />
            </Route>
          </Route>
        </Route>

        
      </Route>

      <Route path="chat">
        <Route index element={<AdminChatPage />} />
        <Route path=":userid" element={<PrepaidEnergyCustomers />} />
      </Route>

      <Route path="fuel">
        <Route index element={<FuelMonitoryPage />} />
        <Route path="grid/:companyid" element={<SingleGridCompanyPage />} />
      </Route>
      {/* <Route path="settings"> */}
      {/* <Route index element={<SettingsPage />} /> */}
      {/* <Route path=':comapnyid' element={<PrepaidEnergyCustomers />} /> */}
      {/* </Route> */}
      <Route path="request">
        <Route index element={<RequestPage />} />
        {/* <Route path=':comapnyid' element={<PrepaidEnergyCustomers />} /> */}
      </Route>
    </Route>
  );
}
