import React, { useState } from "react";
import Input from "../../../components/input";
import Button from "../../../components/button";
import useGetBillers from "../../../hooks/apis/useGetBillers";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

import Text from "../../../components/text";
import { IoHappyOutline } from "react-icons/io5";
import { ValidationSchema } from "./buy-meter-validation";

const BuyMeterWrapper = () => {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const navigate = useNavigate();
  const provider = [
    "DISCO",
    "PRIVATE ENERGY SMART METERS",
    "PRIVATE WATER SMART METERS",
  ];

  const {
    data: billers,
    isFetching: isBillersFetching,
    isLoading: isBillersLoading,
  } = useGetBillers("electricity");

  const [meterSelected, setMeterSelected] = useState(false);

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    type: "",
    phase: "",
    provider: "",
    email: "",
    quantity: "",
  };

  
  const onSubmit = async (values, { resetForm }) => {
    // console.log(values);
    setIsLoading(true);
    setValidationAttempt(false);

    try {
      const res = await axios.post(
        "https://api-pm-e1ea777feac4.herokuapp.com/metering/buy-meter",
        values
      );
      console.log(res);

      setIsLoading(false);
      resetForm();
      // navigate("/");
      toast.success(res.data.message);
      setIsSuccess(true);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      // Handle error and show appropriate message
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const {
    touched,
    isSubmitting,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    // onSubmit: (values) => console.log(values),
    onSubmit,
  });

  const handleMeterChange = (e) => {
    handleChange(e);
    setMeterSelected(true);

    if (e.target.value !== "DISCO") {
      setFieldValue("provider", "IOBOTECH"); // Corrected to use setFieldValue
    }
  };

  return (
    <>
  
        {isSuccess ? (
          <div className="bg-gray-100 min-h-screen flex  items-center justify-center">
      <div className="bg-transparent md:bg-white p-3 md:p-8 rounded-lg flex flex-col space-y-3 md:shadow-md text-center">
        <IoHappyOutline className="w-20 md:w-40 h-20 md:h-40 mx-auto text-secondary-dark" />
        <Text as="h1" classname="text-3xl font-semibold">
        Thanks for patronizing us
        </Text>
        <Text as="p">
        Congratulations! Your request was successful.
        </Text>

        <Button href="/" classname="w-full" onnlick={() => { setIsSuccess(false); }}>
          Go to home
        </Button>
      </div>
    </div>
          
        ) : (
          <div className="flex py-[88px] flex-col gap-6 rounded-lg mx-auto p-[50px] md:px-[100px] w-full max-w-[787px] bg-[#f8f8f8] shadow-sm">
          <form onSubmit={handleSubmit} className="flex flex-col space-y-6">
           <Text as='h4' classname="text-3xl font-semibold text-center">Request A Meter</Text>
            <Input
              type="text"
              placeholder="First Name "
              name="firstName"
              onchange={handleChange}
              onblur={handleBlur}
              errorMessage={touched.firstName && errors.firstName}
              value={values.firstName}
            />
            <Input
              type="text"
              placeholder="Last Name"
              name="lastName"
              onchange={handleChange}
              onblur={handleBlur}
              errorMessage={touched.lastName && errors.lastName}
              value={values.lastName}
            />
            <Input
              type="text"
              placeholder="phoneNumber"
              name="phoneNumber"
              onchange={handleChange}
              onblur={handleBlur}
              errorMessage={touched.phoneNumber && errors.phoneNumber}
              value={values.phoneNumber}
            />
            <Input
              type="email"
              placeholder="Email"
              name="email"
              onchange={handleChange}
              onblur={handleBlur}
              errorMessage={touched.email && errors.email}
              value={values.email}
            />
            <>
            <select
              className="border-none bg-gray-200 outline-none rounded-[6px] py-[15px] px-[15px]"
              onChange={handleMeterChange}
              onBlur={handleBlur}
              errorMessage={touched.type && errors.type}
              value={values.type}
              required
              name="type"
            >
              <option value="" disabled>
                Choose a Meter Type
              </option>
              {provider.map((met, index) => (
                <option value={met} key={index}>
                  {met}
                </option>
              ))}
            </select>
            { touched.type && errors.type && (
              <Text
              as="span"
              weight="medium"
              classname="text-[15px] lg:text-base text-red-600"
            >
              {errors.type}
            </Text>
             )}
            </>
            <>
            {values.type === "DISCO" && (
              <select
                className="border-none bg-gray-200 outline-none rounded-[6px] py-[15px] px-[15px]"
                required
                name="provider"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={touched.provider && errors.provider}
                value={values.provider}
              >
                {isBillersFetching ? (
                  <option value="">Loading billers...</option>
                ) : (
                  <>
                    <option value="" disabled>
                      Choose a Provider
                    </option>
                    {billers?.data?.map((biller) => (
                      <option key={biller.id} value={biller.name}>
                        {biller.name}
                      </option>
                    ))}
                  </>
                )}
              </select>
            )}
            { touched.provider && errors.provider && (
              <Text
              as="span"
              weight="medium"
              classname="text-[15px] lg:text-base text-red-600"
            >
              {errors.provider}
            </Text>
             )}
            </>
            <>
            {[
              "PRIVATE ENERGY SMART METERS",
              "PRIVATE WATER SMART METERS",
            ].includes(values.type) && (
              <select
                className="border-none bg-gray-200 outline-none rounded-[6px] py-[15px] px-[15px]"
                required
                name="provider"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={touched.provider && errors.provider}
                value={values.provider}
              >
                <option value="" disabled>
                  Choose a Provider
                </option>
                <option value="">IOBOTECH</option>
              </select>
            )}
            { touched.provider && errors.provider && (
              <Text
              as="span"
              weight="medium"
              classname="text-[15px] lg:text-base text-red-600"
            >
              {errors.provider}
            </Text>
             )}
            </>

              <>
              <select
                className="border-none bg-gray-200 outline-none rounded-[6px] py-[15px] px-[15px]"
                required
                name="phase"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={touched.phase && errors.phase}
                value={values.phase}
              >
                <option value="" disabled>
                  Choose a Phase
                </option>
                <option value="prepaid">Single Phase</option>
                <option value="postpaid">Three Phase</option>
              </select>
              { touched.phase && errors.phase && (
                <Text
                as="span"
                weight="medium"
                classname="text-[15px] lg:text-base text-red-600"
              >
                {errors.phase}
              </Text>
               )}
              </>

            <Input
              type="number"
              placeholder="Quantity"
              name="quantity"
              onchange={handleChange}
              onblur={handleBlur}
              errorMessage={touched.quantity && errors.quantity}
              value={values.quantity}
            />
            <Button
              buttonType="submit"
              classname="w-full"
              isLoading={isLoading}
            >
              Buy Meter
            </Button>
          </form>
          </div>
        )}

    </>
  );
};

export default BuyMeterWrapper;
