import React from "react";
import { clx } from "../../utils/clx";
import { getFormattedPrice } from "../../utils/getFormattedPrice";
import Button from "../button";
import Input from "../input";
import Radio from "../radio";
import Text from "../text";

export default function AmountConfirmation({ classname, amount, onButtonClick,loading, children }) {

  const [isComplied, setComplied] = React.useState(false);

  const classes = clx("relative flex flex-col py-[50px]", classname);
  return (
    <div className={classes}>
      <Text
        as='h3'
        weight='bold'
        classname='text-[36px] lg:text-[45px] text-center'
      >
        ₦ {getFormattedPrice(amount)}
      </Text>
      <Radio
        label='I agree to be debited the amount above instantly from my wallet'
        classname='lg:max-w-[400px]'
        labelClasses='text-[#9799A5] text-[14px] lg:text-base'
        onclick={() => setComplied(true)}
      />
      <div className='mt-10 w-fit mx-auto'>
        <Button
          size='large'
          onclick={onButtonClick}
          disabled={isComplied === false}
          isLoading={loading}
        >
          Recharge ₦ {getFormattedPrice(amount)}
        </Button>
      </div>
    </div>
  );
}
