import React from "react";
import HomepageWrapper from "../../views/static/hompage";
import StaticLayout from "../../layouts/static-layout";
export default function Homepage() {
      return (
            <React.Fragment>
                  <StaticLayout>
                        <HomepageWrapper />
                  </StaticLayout>
            </React.Fragment>
      )
}