export const configureItems = [
  {
    id: 1,
    title: "Clear Tamper",
    image: "/assets/icons/tamper.png",
    path: "cleartamper",
    rank: 0,
  },
  {
    id: 2,
    title: "Set Charges",
    image: "/assets/icons/charges.png",
    path: "charges",
    rank: 1,
  },
  {
    id: 3,
    title: "Admin Managements",
    image: "/assets/icons/admin.png",
    path: "admin-management",
    rank: 1,
  },
  {
    id: 4,
    title: "User Managements",
    image: "/assets/icons/user.png",
    path: "user-management",
    rank: 0,
  },
  {
    id: 5,
    title: "Payment History",
    image: "/assets/icons/service.png",
    path: "payment-history",
    rank: 0,
  },
  {
    id: 6,
    title: "Affiliates",
    image: "/assets/icons/affiliates.png",
    path: "affiliates",
    rank: 1,
  },
  {
    id: 7,
    title: "Gate Pass",
    image: "/assets/icons/verify.png",
    path: "gatepass",
    rank: 0,
  },
  // {
  //   id: 8,
  //   title: "IOT Management",
  //   image: "/assets/icons/csv.png",
  //   path: "",
  //   rank: 0,
  // },
  {
    id: 9,
    title: "Payments Invoices",
    image: "/assets/icons/invoice.png",
    path: "payments-invoices",
    rank: 1,
  },
  {
    id: 10,
    title: "FAQs",
    image: "/assets/icons/adminfaqs.png",
    path: "",
    rank: 5,
  },
  {
    id: 11,
    title: "Meter Request",
    image: "/assets/icons/request.png",
    path: "meter-request",
    rank: 5,
  },
  {
    id: 12,
    title: "Guest",
    image: "/assets/images/guest.svg",
    path: "guest",
    rank: 5,
  },
  {
    id: 13,
    title: "Kct page",
    image: "/assets/icons/csv.png",
    path: "form",
    rank: 5,
  },
  // {
  //   id: 11,
  //   title: "Settings",
  //   image: "/assets/icons/admin.png",
  //   path: "settings",
  //   rank: 5,
  // },
];
