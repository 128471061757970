import React from "react";
import Text from "../../components/text";
import Button from "../../components/button";
import Input from "../../components/input";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { MdError } from "react-icons/md";
import { GrInfo } from "react-icons/gr";
import AuthLayout from "../../layouts/auth-layout";
import axios from "axios";
import * as Yup from "yup";
import { useAuthUser, useIsAuthenticated, useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { clx } from "../../utils/clx";
import { useCookies } from "react-cookie";
import useAuthV2 from "../../store/admin/authKey";

export default function AdminLoginPageWrapper() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const [isVerified, setVerified] = React.useState(false);

  // console.log("auth", isAuthenticated())

  React.useEffect(() => {
    if (isAuthenticated() && authUser()?.user === "manager") {
      return navigate("/manager");
    } else setVerified(true);
  });

  if (isVerified) {
    return (
      <AuthLayout>
        <div className="w-full pb-[50px] lg:pb-[30px] lg:flex items-center lg:space-x-[30px]">
          <div className="w-full lg:w-[700px] mx-auto h-fit flex flex-col justify-center space-y-[30px]">
            <div className="lg:hidden">
              <div className="my-[40px]">
                <img
                  src="/assets/images/logo2.png"
                  alt="logo"
                  className="w-[250px] mx-auto"
                />
              </div>
              <div className="border-[1px] border-secondary-dark w-full rounded-[12px] bg-[#D9D9D9] overflow-hidden">
                <img
                  src="/assets/images/login-banner.png"
                  alt="logo"
                  className="w-[100%] object-fill"
                />
              </div>
            </div>
            <div className="flex-1 h-full flex flex-col justify-center w-full mt-[40px] my-[30px] lg:mb-0 text-center">
              <Text
                as="p"
                classname="font-[600] text-[28px] lg:text-[64px] text-primary-dark dark:text-[#E6E6E9]"
              >
                PrepaidMeter Manager
              </Text>
              <Text
                as="p"
                weight="medium"
                classname="text-[14px] lg:text-[24px] text-primary-dark dark:text-[#E6E6E9]"
              >
                Enter your login details
              </Text>
            </div>
            <div className="flex-1 flex justify-end items-center">
              <div className="bg-[#F8F8FF] dark:bg-[#12131A] border-[1px] rounded-[16px] w-full py-[30px] lg:py-[50px] px-[20px] lg:px-[70px]">
                <AdminLoginPageWrapper.Form />
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    );
  }
}

export function Form({}) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [loginMessage, setLoginMessage] = React.useState({
    type: "",
    message: "",
  });
  const [isLoading, setLoading] = React.useState(false);
  const signIn = useSignIn();
  // const signWithTwoPointFive = useSignIn();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['Auth2'])
  const { setAuthAndCookie } = useAuthV2();
  // https://api-pm-e1ea777feac4.herokuapp.com/manager/login


   const getAdminTokenVserionTwo = async(loginData)=>{
    try {
        const res = await axios.post('https://api-pm-e1ea777feac4.herokuapp.com/manager/login',loginData)


        //!remove this
        // setCookie('Auth2',{
        //   token:res.data.data.token
        // },
        // {
        //   // secure:true,
        //   // httpOnly:true
        // })
        //! replace with this
        setAuthAndCookie(res.data.data.token);
        // console.log(res)
    } catch (error) {
      // console.log(error)
      // console.log(error.message)
      // console.log(error.response)

    }
  }
  function getAdminToken(loginData) {
    return axios
      .post("https://pm-server.herokuapp.com/api/v2.0/manager/login", loginData)
      .then((res) => {
        setLoginMessage({
          type: "success",
          message:
            "You are successfully Logged in!. You will be redirected shortly",
        });
        // console.log(res)
        signIn({
          token: res.data.data.token,
          expiresIn: 360,
          tokenType: "Bearer",
          authState: {
            company: res.data.data.company,
            authToken: res.data.data.token,
            user: "manager",
          },
        });
        setLoading(false);
        navigate("/manager");
      })
      .catch((err) => {
        setLoading(false);
        if (err.message === "Network Error") {
          return setLoginMessage({
            type: "error",
            message:
              "Oops! Connection Not Established. Please check your internet connection",
          });
        }

        switch (err.response.data.message) {
          case "You have been restricted from Login, Please Contact the Administrator!!!":
            return setLoginMessage({
              type: "warning",
              message: err.response.data.message,
            });
          case "Incorrect Email.!!!":
            return setLoginMessage({
              type: "error",
              message:
                "Invalid Email or Password. Please confirm the details you provided",
            });
          case "Incorrect Password.!!!":
            return setLoginMessage({
              type: "error",
              message:
                "Invalid Email or Password. Please confirm the details you provided",
            });

          default:
            return setLoginMessage({
              type: "error",
              message: "Oops! Something went wrong",
            });
        }
      });
  }

  // const { isLoading, isError, error, data, mutate } = useMutation(
  //   // "Login",
  //   getUserToken
  // );

  const formValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Please enter your email"),
    password: Yup.string().required("Please enter your password"),
  });

  const onSubmit = (values) => {
    setValidationAttempt(false);
    setLoading(true);
    getAdminToken(values);
    getAdminTokenVserionTwo(values)
    // mutate(values);
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col space-y-6">
        {loginMessage.message && (
          <AdminLoginPageWrapper.MessageContainer
            messageType={loginMessage.type}
            message={loginMessage.message}
          />
        )}
        <Input
          type="email"
          iconLeft={<FaEnvelope className="text-[#9799A5]" />}
          placeholder="Email Address"
          name="email"
          onchange={formik.handleChange}
          onblur={formik.handleBlur}
          errorMessage={formik.errors.email}
          value={formik.values.email}
        />
        <Input
          type="password"
          iconLeft={<FaLock className="text-[#9799A5]" />}
          placeholder="Password"
          name="password"
          onchange={formik.handleChange}
          onblur={formik.handleBlur}
          errorMessage={formik.errors.password}
          value={formik.values.password}
        />
        <Text
          as="p"
          classname="text-secondary-dark text-[14px] lg:text-base text-right"
          href="/admin/forget-password"
        >
          Forgot Password
        </Text>
        <Button
          buttonType="submit"
          classname="w-full text-[14px] lg:text-base"
          onclick={() => setValidationAttempt(true)}
          isLoading={isLoading}
        >
          Sign in
        </Button>
        {/* <Text
          as="p"
          classname="text-center text-[14px] lg:text-base"
          href="/auth-token"
        >
          <span className="text-[#6C6F7F]">New User?</span> Create an Account
        </Text>
        <Text
          as="p"
          classname="text-secondary-dark text-[14px] lg:text-base text-center lg:hidden"
        >
          Request Demo
        </Text> */}
      </div>
    </form>
  );
}

export function MessageContainer({ messageType, message }) {
  const classes = clx(
    "border-[1px] py-2 px-4 rounded-[8px] flex space-x-3",
    messageType === "error"
      ? "bg-red-500/30 "
      : messageType === "success"
      ? " bg-green-500/20"
      : "bg-yellow-500/20"
  );
  return (
    <div className={classes}>
      <div>
        {messageType === "error" || messageType === "warning" ? (
          <MdError
            className={`${
              messageType === "error" ? "text-red-600" : "text-secondary-dark"
            } text-[18px] lg:text-[22px]`}
          />
        ) : (
          <GrInfo className="text-green-600 text-[18px] lg:text-[22px]" />
        )}
      </div>
      <Text
        as="p"
        classname={`${
          messageType === "error"
            ? "text-red-600"
            : messageType === "success"
            ? "text-green-600"
            : "text-secondary-dark"
        } text-[13px] lg:text-[14px]`}
      >
        {message}
      </Text>
    </div>
  );
}

AdminLoginPageWrapper.MessageContainer = MessageContainer;
AdminLoginPageWrapper.Form = Form;
