import React from "react";
import { IoChevronBack } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthHeader from "../components/auth-header";
import Text from "../components/text";
import { clx } from "../utils/clx";
import StaticHeader from "../components/static-header";
import { CgMenuRight } from "react-icons/cg";
import { signUpOptions, staticMenu } from "../constants/static-menu";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import Button from "../components/button";

export default function AuthLayout({
  pageTitle,
  usePageNavigation,
  classname,
  children,
  headerClass,
}) {
  const navigate = useNavigate();
  const [isNavOpen, setNavOpen] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  });

  const classes = clx(
    "absolute bg-transparent dark:bg-[#121319] inset-0 min-h-screen flex flex-col z-[999] w-full",
    classname
  );
  return (
    <div className="relative">
      <div className="fixed inset-0 h-screen -z-50">
        <img
          src="/assets/images/spiral-background.jpg"
          className="object-cover lg:object-fill w-full z-0 h-full opacity-[1.5%] lg:opacity-[2%]"
          alt=""
        />
      </div>
      <div className={classes}>
        <div className="hidden lg:block">
          <StaticHeader containerClass={headerClass} />
        </div>
        <MobileHeader
          isOpen={isNavOpen}
          handleClose={() => setNavOpen(false)}
        />
        {/* {usePageNavigation && (
          <div className="lg:hidden py-[20px] w-[93%] mx-auto flex">
            <IoChevronBack
              className="text-[24px] text-left ml-[-5px]"
              onClick={() => navigate(-1)}
            />
            <div className="flex-1">
              <Text
                as="p"
                weight="semibold"
                classname="text-center text-[18px]"
              >
                {pageTitle}
              </Text>
            </div>
          </div>
        )} */}
        <div className="flex  justify-between lg:hidden p-6">
          <Link to="/">
            <img src="/assets/images/logo2.png" alt="" width="150px" />
          </Link>
          <div onClick={() => setNavOpen(true)}>
            <CgMenuRight className="text-[30px] text-primary-dark dark:text-white" />
          </div>
        </div>
        <div className="flex-1 w-[93%] lg:w-[90%] mx-auto lg:flex lg:py-[30px] justify-center">
          {children}
        </div>
      </div>
    </div>
  );
}

export function MobileHeader({ classname, isOpen, handleClose }) {
  const router = useLocation();
  const [openSubmenu, setOpenSubmenu] = React.useState(null);
  const [signUpOptionsOpen, setSignUpOptionsOpen] = React.useState(false);

  const toggleSubmenu = (index) => {
    if (openSubmenu === index) {
      setOpenSubmenu(null);
    } else {
      setOpenSubmenu(index);
    }
  };

  const handleMouseEnter = (index) => {
    setOpenSubmenu(index);
  };

  const handleMouseLeave = () => {
    setOpenSubmenu(null);
  };
  const classes = clx(
    "lg:hidden fixed inset-0 z-50 h-[100%] max-h-[100%] w-full bg-black/5 overflow-hidden duration-300",
    isOpen ? "ml-0" : "ml-[-100%]",
    classname
  );
  return (
    <div
      className={classes}
      onClick={({ target, currentTarget }) =>
        target === currentTarget && handleClose()
      }
    >
      <div className="w-[300px] h-[100%] max-h-[100%] bg-white px-[20px] py-[40px] pb-[80px] overflow-auto">
        <div
          className="w-fit"
          onClick={({ target, currentTarget }) => handleClose()}
        >
          <MdCancel className="text-[33px] text-primary-dark" />
        </div>
        <div className="flex flex-col  space-y-6 pt-[30px]">
          {staticMenu.map((link, index) => (
            <div key={index}>
              <div className="flex items-center justify-between">
                <Text
                  href={link.route}
                  as="span"
                  weight={
                    router.pathname === link.route ? "semibold" : "normal"
                  }
                  onclick={() => toggleSubmenu(index)}
                  classname={`${
                    router.pathname === link.route &&
                    "lg:text-[18px] text-sm text-transparent bg-clip-text bg-gradient-to-l from-primary-dark via-primary-light to-secondary-dark "
                  } cursor-pointer`}
                >
                  {link.label}
                </Text>
                {link.subroutes && (
                  <div
                    onClick={() => toggleSubmenu(index)}
                    className="cursor-pointer"
                    onMouseEnter={() => handleMouseEnter(index)}
                  >
                    {openSubmenu === index ? (
                      <IoMdArrowDropup className="h-5 w-5" />
                    ) : (
                      <IoMdArrowDropdown className="h-5 w-5" />
                    )}
                  </div>
                )}
              </div>
              {link.subroutes && openSubmenu === index && (
                <div
                  className="w-[200px] left-0 mt-2 rounded-lg bg-white shadow-md"
                  onMouseLeave={() => handleMouseLeave()}
                >
                  {link.subroutes.map((sublink) => (
                    <Text
                      as="p"
                      key={sublink.id}
                      onclick={() => {
                        window.location.href = sublink.route;
                      }}
                      classname="px-4 py-2 rounded-lg hover:bg-gray-100 cursor-pointer"
                    >
                      {sublink.label}
                    </Text>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex flex-col mt-6 space-y-4">
          <Button
            variant="outlined"
            size="small"
            href="/login"
            classname="md:text-sm lg:text-xl"
          >
            Sign in
          </Button>
          <div>
            <Button
              variant="contained"
              size="small"
              onclick={() => setSignUpOptionsOpen((prev) => !prev)}
            >
              Sign up
            </Button>
            {signUpOptions && signUpOptionsOpen && (
              <div
                className="w-[200px] mt-2 bg-primary-dark rounded-lg shadow-md"
                onMouseLeave={() => setSignUpOptionsOpen((prev) => !prev)}
              >
                {signUpOptions.map((sublink) => (
                  <Text
                    as="p"
                    key={sublink.id}
                    onclick={() => {
                      window.location.href = sublink.route;
                    }}
                    classname="px-4 py-2 text-white hover:bg-primary-light rounded-lg cursor-pointer"
                  >
                    {sublink.label}
                  </Text>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}