import React from 'react'
import { Route } from "react-router-dom";
import AuthTokenPage from "../pages/auth/auth-token";
import EmailAuthPage from "../pages/auth/email-auth";
import ForgetPassPage from "../pages/auth/forget-password";
import FuelIDPage from "../pages/auth/fuel-id";
import LoginPage from "../pages/auth/login";
import MeterIDPage from "../pages/auth/meter-id";
import MeterRechargePage from "../pages/auth/meter-recharge";
import MeterVerificationPage from "../pages/auth/meter-verification";
import NewPassPage from "../pages/auth/new-password";
import RechargePage from "../pages/auth/recharge";
import SignupPage from "../pages/auth/signup";
import VerificationPage from "../pages/auth/verification";
import WalletIDPage from "../pages/auth/wallet-id";
import WalletRechargePage from "../pages/auth/wallet-recharge";
import AdminLoginPage from '../pages/auth/admin-login';
import AdminForgetPassPage from '../pages/auth/admin-forget-password';

export default function GetAuthRoutes() {
      return (
        <React.Fragment>
          <Route path='/recharge' element={<RechargePage />} />
          <Route path='/meter-id' element={<MeterIDPage />} />
          <Route path='/Wallet-id' element={<WalletIDPage />} />
          <Route path='/fuel-id' element={<FuelIDPage />} />
          <Route path='/email-auth' element={<EmailAuthPage />} />
          <Route path='/meter-recharge' element={<MeterRechargePage />} />
          <Route path='/wallet-recharge' element={<WalletRechargePage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/manager/login' element={<AdminLoginPage />} />
          <Route path='/signup' element={<SignupPage />} />
          <Route path='/verification' element={<VerificationPage />} />
          <Route
            path='/meter-verification'
            element={<MeterVerificationPage />}
          />
          <Route path='/forget-password' element={<ForgetPassPage />} />
          <Route path='/manager/forget-password' element={<AdminForgetPassPage />} />
          <Route path='/new-password' element={<NewPassPage />} />
          <Route path='/auth-token' element={<AuthTokenPage />} />
        </React.Fragment>
      );
}