export const providers = [
  {
    value: "",
    label: "Choose a provider",
    icon: "",
  },
  {
    value: "AEDC NG",
    label: "AEDC NG",
    icon: "/assets/icons/provider-icons/aedc-ng.png",
  },
  {
    value: "BEDC NG",
    label: "BEDC NG",
    icon: "/assets/icons/provider-icons/bedc-ng.png",
  },
  {
    value: "PHED NG",
    label: "PHED NG",
    icon: "/assets/icons/provider-icons/phed-ng.png",
  },
  {
    value: "KEDCO NG",
    label: "KEDCO NG",
    icon: "/assets/icons/provider-icons/keadco-ng.png",
  },
  {
    value: "KAEDCO NG",
    label: "KAEDCO NG",
    icon: "/assets/icons/provider-icons/kaedco-ng.png",
  },
];
