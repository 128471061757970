import React from "react";
import MayAlsoLike from "./components/may-also-like";
import Text from "../../components/text";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { CartCard } from "./components/cart-card";
import { OrderSummary } from "./components/order-summary";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";

export default function CartPageWrapper() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="px-5 lg:py-[30px] py-5 lg:px-20 flex flex-col gap-10  bg-[#F8F8FF]">
        <div className="hidden lg:flex space-x-2">
          <Text href="/" as="p" classname="text-sm text-gray-400">
            Home
          </Text>
          <MdOutlineArrowForwardIos className="text-gray-400 w-4 h-4" />
          <Text as="p" classname="text-sm capitalize text-gray-400">
            Cart
          </Text>
        </div>
        <div className="lg:hidden flex items-center gap-10">
          <MdOutlineArrowBackIosNew
            className="h-6 w-6  text-gray-400"
            onClick={() => navigate(-1)}
          />
          <Text
            as="h1"
            weight="bold"
            classname="text-2xl text-center  capitalize"
          >
            Cart
          </Text>
        </div>
        <div className="hidden lg:flex items-center justify-between">
          <Text as="h1" weight="bold" classname="text-4xl capitalize">
            Cart
          </Text>
          {/* search input */}
        </div>
      </div>
      <div className="lg:hidden px-5 py-2 bg-[#E6E6E9]">
        <Text as="p" weight="bold" classname="text-sm  capitalize">
          Order Summary
        </Text>
      </div>
      <div className="lg:hidden flex justify-between px-5 py-2">
        <Text
          as="p"
          weight="bold"
          classname="text-sm text-[#E6E6E9] capitalize"
        >
          Total
        </Text>
        <Text
          as="p"
          weight="bold"
          classname="text-sm text-[#E6E6E9] capitalize"
        >
          N14,392
        </Text>
      </div>
      <hr className="block lg:hidden" />
      <div className="w-[93%] mt-10 lg:mt-20 lg:w-[90%] flex lg:flex-row flex-col lg:justify-between mx-auto">
        <CartCard />

        <OrderSummary classname="hidden lg:flex" />
      </div>
      <div className="px-5 w-full flex items-center lg:hidden mt-10">
        <Button  onclick={() => {
          navigate("/checkout");
        }} classname="w-full">Checkout</Button>
      </div>

      <div className="py-[50px] lg:py-[140px] px-5 lg:px-20 flex flex-col gap-16 lg:gap-56 items-center">
        <MayAlsoLike title="Products you may also like" />
      </div>
    </React.Fragment>
  );
}
