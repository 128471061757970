import React from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
import { clx } from "../utils/clx";
import { getFormattedPrice } from "../utils/getFormattedPrice";
import Text from "./text";
import TransactionCard from "./modals/transaction-card";
import ModalWrapper from "./modals/modal-wrapper";

export default function EnergyTransactionCard({
  unit,
  data,
  onclick,
  classname,
  children,
}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const classes = clx(
    "w-[740px] flex items-center justify-between py-4 border-b-2 border-[#6C6F7F]/10 cursor-pointer",
    classname
  );
  return (
    <React.Fragment>
      <div
        className={classes}
        onClick={() => {
          setIsModalOpen(true);
          onclick && onclick();
        }}
      >
        <div className="flex items-center justify-center space-x-3">
          <div className="w-[48px] h-[48px] rounded-full bg-[#FBF5E6] flex items-center justify-center">
            <img src="/assets/icons/energy-trans-icon.png" alt="icon" />
          </div>
          <div>
            <Text
              as="p"
              classname="text-[14px] lg:text-[20px] text-[#2C3048] font-semibold"
            >
              ₦ {getFormattedPrice(data?.Total_paid)}
            </Text>
            <Text
              as="span"
              classname="text-[11px] lg:text-[12px] text-[#9799A5]"
            >
              {data?.Gen_Date}
            </Text>
          </div>
        </div>
        <Text as="span" classname="text-[11px] lg:text-[12px] text-[#9799A5]">
          {data?.Total_unit}
        </Text>
      </div>

      {/* Modal */}

      <ModalWrapper isOpen={isModalOpen} handleModalClose={()=>setIsModalOpen(false)}>
        <TransactionCard data={data} />
      </ModalWrapper>
    </React.Fragment>
  );
}
