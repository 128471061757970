import React from "react";
import { clx } from "../utils/clx";
import Text from "./text";

export default function DiscoMeterIdContainer({ number, name, classname }) {
  const classes = clx(
    "rounded-[12px] py-3 border border-primary-dark px-3 flex justify-between",
    classname
  );
  return (
    <React.Fragment>
      <div className={classes}>
        <div className="flex items-center space-x-3 w-full">
          <img src="/assets/icons/meter-id-icon.png" alt="" />
          <Text as="span" classname=" px-2 border-r border-solid">
            {number}
          </Text>
          <Text as="span" classname="">
            {name}
          </Text>
        </div>
      </div>
    </React.Fragment>
  );
}
