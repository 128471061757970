import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useQuery } from "react-query";

export default function UseFetchEndpoint({ method, endpoint, body, key }) {
  const BaseUrl = "https://pm-server.herokuapp.com/api/v2.0";
  const authUser = useAuthUser();

  const fetchEndpoint = async () => {
    const response = await axios[method](`${BaseUrl}${endpoint}`, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: authUser().authToken,
      },
      body: JSON.stringify(body),
    });
    return response;
  };
  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    {
      queryKey: [key],
      queryFn: () => fetchEndpoint(method, endpoint, body),
    },
    {
      enabled: false,
    }
  );
  return { data: data?.data?.data, isLoading, isError, isFetching, refetch };
}
