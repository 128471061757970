import React from "react";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import useUserStore from "../../../store/user/store";
import { useQueries, useQuery } from "react-query";
import useAdminAffiliateInfo from "./useAdminAffiliateInfo";
import useAdminAffiliates from "../../../store/admin/affiliates";

export default function useAdminConsumptionInfo({
  key,
  query,
  prepaid,
  start,
  end,
  startmonth,
  day,
  endmonth,
  startyear,
  endyear,
}) {
  const authUser = useAuthUser();
  const { affiliates, selectedAffiliate } = useAdminAffiliates();
  const affiliatesArray = affiliates && Object.keys(affiliates);
  const token = authUser().authToken;

  // console.log("Token", authUser().authToken)
  // https://pm-server.herokuapp.com/api/v2.0/manager/users-overview'
  const fetchAffiliateConsumptionInfo = (affiliate) => {
    return axios.post(
      `https://pm-server.herokuapp.com/api/v2.0/manager/users-overview`,
      {
        slug: "tags",
        tags: affiliate === "All" ? [...affiliatesArray] : [affiliate],
        prepaid: "energy",
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  };

  const { isLoading, data, isError, isFetching, refetch } = useQuery({
    queryKey: [key ?? "AdminConsumptionInfo", selectedAffiliate],
    queryFn: () => fetchAffiliateConsumptionInfo(selectedAffiliate),
  });

  // const queryResult = useQueries(
  //   affiliatesArray.map((affiliate) => {
  //     return {
  //       queryKey: ["AdminConsumptionInfo", affiliate],
  //       queryFn: () => fetchAffiliateConsumptionInfo(affiliate),
  //     };
  //   })
  // );
  // console.log("consResult", queryResult);

  React.useEffect(() => {
    refetch();
  }, [selectedAffiliate]);

  return { data: data?.data, isLoading, isError, isFetching, refetch };
}
