import React from "react";
import Input from "../../../../components/input";
import Button from "../../../../components/button";
import Text from "../../../../components/text";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FaLock, FaPhone, FaUser } from "react-icons/fa";
import useBuyElectricityData from "../../../../store/static/buy-electricity";
import { MdEmail } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";

export default function MeterDetails() {
  const {
    discoInfo,
    electricityInfo,
    currentStep,
    setCurrentStep,
    setElectricityInfo,
  } = useBuyElectricityData();
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  // console.log("disco", discoInfo, "elecricity", electricityInfo);
  const formValues = {
    email: "",
    fullname: "",
    phonenumber: "",
    password: "",
    username: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required"),
    fullname: Yup.string().required("Full name is required"),
    username: Yup.string()
      .required("Username is required")
      .matches(/^\S*$/, "Username cannot contain spaces"), // Disallow spaces in the username
    phonenumber: Yup.string().required("Phone Number is required"),
    password: Yup.string().required("Password is required"),
  });
  

  const onSubmit = (values) => {
    console.log(values);
    setIsLoading(true);
    setValidationAttempt(false);
    axios
      .post("https://api-pm-e1ea777feac4.herokuapp.com/user/register", {
        ...values,
        biller: discoInfo.biller,
        ...electricityInfo,
      })
      .then((response) => {
        toast.success(response.data.message);
        setElectricityInfo({ ...electricityInfo, email: values.email });
        setCurrentStep(currentStep + 1);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
        formik.resetForm();
      });
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    validateOnChange: validationAttempt,
  });
  return (
    <div className="flex flex-col mx-auto mb-14 w-full space-y-6 ">
      <div className="flex px-5 md:px-autp flex-col space-y-2">
        <Text as="p" classname="text-[16px]">
          Confirm Meter Details
        </Text>
        <Text
          as="p"
          classname="text-sm md:text-[16px] p-2 rounded-md bg-[#e9e9f8]"
        >
          {electricityInfo?.meterid} - {discoInfo?.name}
        </Text>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col p-5 md:p-20 z-10 mb-14 space-y-6 justify-between mx-auto w-full rounded-lg bg-[#F8F8FF]"
      >
        <Input
          placeholder="Full Name"
          label="Name of Buyer"
          type="text"
          name="fullname"
          onchange={(e) => formik.handleChange(e)}
          onblur={formik.handleBlur}
          value={formik.values.fullname}
          errorMessage={formik.errors.fullname}
          iconLeft={<FaUser />}
        />
        <Input
          placeholder="Username"
          label="Username"
          type="text"
          iconLeft={<FaUser />}
          name="username"
          onchange={(e) => formik.handleChange(e)}
          onblur={formik.handleBlur}
          value={formik.values.username}
          errorMessage={formik.errors.username}
        />
        <Input
          placeholder="email"
          label="Email"
          type="email"
          name="email"
          onchange={(e) => formik.handleChange(e)}
          onblur={formik.handleBlur}
          value={formik.values.email}
          errorMessage={formik.errors.email}
          iconLeft={<MdEmail />}
        />

        <Input
          placeholder="+234"
          label="Phone Number"
          type="text"
          name="phonenumber"
          onchange={(e) => formik.handleChange(e)}
          onblur={formik.handleBlur}
          value={formik.values.phonenumber}
          errorMessage={formik.errors.phonenumber}
          iconLeft={<FaPhone />}
        />
        <Input
          placeholder="Create Password"
          label="Create Password"
          type="password"
          name="password"
          onchange={(e) => formik.handleChange(e)}
          onblur={formik.handleBlur}
          value={formik.values.password}
          errorMessage={formik.errors.password}
          iconLeft={<FaLock className="text-[#9799A5]" />}
        />

        <Button buttonType="submit" isLoading={isLoading}>
          Next
        </Button>
      </form>
    </div>
  );
}
