import React, { useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'tailwindcss/tailwind.css';
import MeteringTable from './metering-table';
import BillingTable from './billing-table';
import MeteringBilling from './metering-billing';

const transactions = [
  { date: '2024-05-01T10:30:00', name: 'Alice', amount: 120.00, description: 'Groceries', category: 'Food', status: 'Completed' },
  { date: '2024-05-02T14:45:00', name: 'Bob', amount: 75.50, description: 'Gas', category: 'Transport', status: 'Pending' },
  { date: '2024-05-03T09:15:00', name: 'Charlie', amount: 200.00, description: 'Clothes', category: 'Shopping', status: 'Rejected' },
  { date: '2024-05-01T16:00:00', name: 'Alice', amount: 120.00, description: 'Groceries', category: 'Food', status: 'Completed' },
  { date: '2024-05-02T18:30:00', name: 'Bob', amount: 75.50, description: 'Gas', category: 'Transport', status: 'Completed' },
  { date: '2024-05-03T11:45:00', name: 'Charlie', amount: 200.00, description: 'Clothes', category: 'Shopping', status: 'Rejected' },
];

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  
  return (
    <span>
      {`${month}-${day}-${year} `}
      <span className="text-xs text-gray-500">{` ${hours}:${minutes} ${ampm}`}</span>
    </span>
  );
};

const TransactionTable = () => {
  const [currentTab, setCurrentTab] = useState('all');
  const [filteredTransactions, setFilteredTransactions] = useState(transactions);
  const [filters, setFilters] = useState({ startDate: '', endDate: '', name: '', amount: '', description: '', category: '', status: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleDateChange = (dates) => {
    const [startDate, endDate] = dates;
    setFilters({
      ...filters,
      startDate,
      endDate,
    });
  };

  const filterTransactions = () => {
    setFilteredTransactions(
      transactions.filter(transaction =>
        ((filters.startDate && filters.endDate) ? (new Date(transaction.date) >= filters.startDate && new Date(transaction.date) <= filters.endDate) : true) &&
        (filters.name === '' || transaction.name.toLowerCase().includes(filters.name.toLowerCase())) &&
        (filters.amount === '' || transaction.amount === parseFloat(filters.amount)) &&
        (filters.description === '' || transaction.description.toLowerCase().includes(filters.description.toLowerCase())) &&
        (filters.category === '' || transaction.category.toLowerCase().includes(filters.category.toLowerCase())) &&
        (filters.status === '' || transaction.status.toLowerCase().includes(filters.status.toLowerCase()))
      )
    );
    setIsModalOpen(false);
  };

  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'text-green-800';
      case 'pending':
        return 'text-yellow-500';
      case 'rejected':
        return 'text-red-600';
      default:
        return '';
    }
  };

  const renderTransactionTable = () => (
    <div className="overflow-x-auto bg-white shadow-md rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Transactions Table</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-[#6071D9] text-white px-4 py-2 rounded-md flex items-center transition-colors duration-200"
        >
          <FaFilter />
          <span className="ml-2">Filter</span>
        </button>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Full Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredTransactions.map((transaction, index) => (
            <tr key={index} className="hover:bg-gray-100 transition-colors duration-200">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{formatDate(transaction.date)}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.name}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.amount}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.category}</td>
              <td className={`px-6 py-4 whitespace-nowrap text-sm ${getStatusClass(transaction.status)}`}>{transaction.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-center mb-4">
        <button
          onClick={() => setCurrentTab('all')}
          className={`px-4 py-2 rounded-l-md ${currentTab === 'all' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-600'} transition-colors duration-200`}
        >
          All
        </button>
        <button
          onClick={() => setCurrentTab('metering')}
          className={`px-4 py-2 ${currentTab === 'metering' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-600'} transition-colors duration-200`}
        >
          Metering
        </button>
        <button
          onClick={() => setCurrentTab('billing')}
          className={`px-4 py-2 rounded-r-md ${currentTab === 'billing' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-600'} transition-colors duration-200`}
        >
          Billing
        </button>
      </div>
      {currentTab === 'all' && renderTransactionTable()}
      {currentTab === 'metering' && <MeteringTable />}
      {currentTab === 'billing' && <BillingTable />}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow-lg w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Filter Transactions</h2>
            <div className="mb-4 flex space-x-2">
              <div className="w-1/2">
                <label className="block text-gray-700 mb-2">Start Date</label>
                <DatePicker
                  selected={filters.startDate}
                  onChange={date => handleDateChange([date, filters.endDate])}
                  selectsStart
                  startDate={filters.startDate}
                  endDate={filters.endDate}
                  showTimeSelect
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                   placeholderText="dd/mm/yy"
                  className="w-full border border-gray-300 px-2 py-1 rounded text-sm"
                />
              </div>
              <div className="w-1/2">
                <label className="block text-gray-700 mb-2">End Date</label>
                <DatePicker
                  selected={filters.endDate}
                  onChange={date => handleDateChange([filters.startDate, date])}
                  selectsEnd
                  startDate={filters.startDate}
                  endDate={filters.endDate}
                  minDate={filters.startDate}
                  showTimeSelect
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                   placeholderText="dd/mm/yy"
                  className="w-full border border-gray-300 px-2 py-1 rounded text-sm"
                />

              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Full Name</label>
              <input
                type="text"
                name="name"
                value={filters.name}
                onChange={handleFilterChange}
                className="w-full border border-gray-300 px-2 py-1 rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Amount</label>
              <input
                type="number"
                name="amount"
                value={filters.amount}
                onChange={handleFilterChange}
                className="w-full border border-gray-300 px-2 py-1 rounded"
              />
            </div>
           
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Category</label>
              <input
                type="text"
                name="category"
                value={filters.category}
                onChange={handleFilterChange}
                className="w-full border border-gray-300 px-3 py-2 rounded"
              />
            </div>
            <MeteringBilling filters={filters} handleFilterChange={handleFilterChange} />
            <div className="flex justify-end">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={filterTransactions}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionTable;
