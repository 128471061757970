export const staticMenu = [
  // {
  //   id: 1,
  //   label: "Home",
  //   //     icon: "/assets/icons/dashboard.png",
  //   //     pageTitle: "Overview",
  //   route: "/",
  // },
  {
    id: 2,
    label: "Client",
    //     icon: "/assets/icons/dashboard-wallet.png",
    //     pageTitle: "Wallet",
    route: "",
    subroutes: [
      {
        id: 1,
        label: "Personal",
        route: "/",
      },
      {
        id: 2,
        label: "Manager",
        route: "/manager",
      },
      {
        id: 3,
        label: "API Documentation",
        route: "/api-documentation",
      },
      // {
      //   id: 4,
      //   label: "Iobotech",
      //   route: "/iobotech",
      // },
    ],
  },
  {
    id: 3,
    label: "About Us",
    //     icon: "/assets/icons/chat.png",
    //     pageTitle: "Chat",
    route: "/about",
  },
  {
    id: 4,
    label: "Contact",
    //     icon: "/assets/icons/faq.png",
    //     pageTitle: "FAQs",
    route: "/contact",
  },
  {
    id: 5,
    label: "Request A Meter",
    //     icon: "/assets/icons/profile.png",
    //     pageTitle: "My Profile",
    route: "/marketplace",
  },
  {
    id: 5,
    label: "Referral Program",
    //     icon: "/assets/icons/profile.png",
    //     pageTitle: "My Profile",
    route: "/referral",
  },
  // {
  //   id: 6,
  //   label: "Blog",
  //   //     icon: "/assets/icons/profile.png",
  //   //     pageTitle: "My Profile",
  //   route: "/blog",
  // },
];

export const signUpOptions = [
  {
    id: 1,
    label: "As User",
    route: "/signup",
  },
  {
    id: 2,
    label: "As Corporate",
    route: "/corporate",
  },
  {
    id: 3,
    label: "Get Our API",
    route: "/api-documentation",
  },
];

export const sortList = [
  {
    id: 1,
    label: "Relevance",
  },
  {
    id: 2,
    label: "Price - High to low",
  },
  {
    id: 3,
    label: "Price - Low to high",
  },
];

export const apiMenuList = [
  {
    id: 1,
    label: "Home",
    route: "/api-documentation",
  },
  {
    id: 2,
    label: "Integration Guides",

    route: "/guide",
  },
  {
    id: 3,
    label: "Metering",
    route: "/metering",
  },
  {
    id: 4,
    label: "Gate pass",

    route: "/gate-pass",
  },
  {
    id: 4,
    label: "IOt remote monitoring",

    route: "/iot-monitoring",
  },
  {
    id: 4,
    label: "Market place",

    route: "/market-place",
  },
];
