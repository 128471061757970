import React from "react";
import Table from "../../../../../components/table";
import Radio from "../../../../../components/radio";
import {
  access_records_column,
  access_records_data,
} from "../../../../../utils/table-utils/admin/access-records-table";
import Input from "../../../../../components/input";
import { BsSearch } from "react-icons/bs";
import UseFetchEndpoint from "../../../../../hooks/apis/useFetch";
import useAdminAffiliates from "../../../../../store/admin/affiliates";
import { CSVLink } from "react-csv";
import Button from "../../../../../components/button";
import { FiDownload } from "react-icons/fi";
import Text from "../../../../../components/text";
import { GatePassHeader } from "../download/GatePassHeader";

export default function Invoices() {
  const [search, setSearch] = React.useState("");
  const [selectedType, setSelectedType] = React.useState("all");
  const [filteredData, setFilteredData] = React.useState([]);
  const { selectedAffiliate } = useAdminAffiliates();

  const {
    data: accessRecordsData,
    isFetching,
    isLoading,
    refetch,
  } = UseFetchEndpoint({
    method: "get",
    endpoint: `/get-gatepass?slug=tag&tag=${selectedAffiliate}`,
    key: "access-records",
  });
  console.log(accessRecordsData)

  const loadMeters = React.useCallback(() => {
    setFilteredData([]);
    accessRecordsData?.length &&
      accessRecordsData?.forEach((item) => {
        const { status } = item;
        // console.log(status);
        if (
          selectedType === "all" ||
          (selectedType === "pending" && status === "PENDING") ||
          (selectedType === "sign-in" && status === "CHECKIN") ||
          (selectedType === "sign-out" && status === "CHECKOUT")
        ) {
          const sign_in =
            status === "CHECKIN"
              ? "Signed In"
              : status === "CHECKOUT"
              ? "Signed Out"
              : "Pending";
          // console.log(sign_in)
          setFilteredData((prev) => [...prev, { ...item, sign_in }]);
        }
      });
    // console.log(filteredData);
  }, [selectedType, accessRecordsData]);

  console.log(filteredData)

  React.useEffect(() => {
    loadMeters();
  }, [loadMeters]);

  const handleSelectType = React.useCallback((e) => {
    setSelectedType(e.target.value);
  }, []);

  React.useEffect(() => {
    refetch();
  }, [selectedAffiliate]);
  console.log(access_records_column)

  return (
    <div>
      <div className="flex justify-end gap-4 p-3">
        <Radio
          name="filter-type"
          label="All"
          classname="text-[16px]"
          checked={selectedType === "all"}
          value="all"
          onChange={handleSelectType}
          onclick={handleSelectType}
        />
        <Radio
          name="filter-type"
          label="Pending"
          classname="text-[16px]"
          checked={selectedType === "pending"}
          value="pending"
          onChange={handleSelectType}
          onclick={handleSelectType}
        />
        <Radio
          name="filter-type"
          label="Signed In"
          classname="text-[16px]"
          checked={selectedType === "sign-in"}
          value="sign-in"
          onChange={handleSelectType}
          onclick={handleSelectType}
        />
        <Radio
          name="filter-type"
          label="Signed Out"
          classname="text-[16px]"
          checked={selectedType === "sign-out"}
          value="sign-out"
          onChange={handleSelectType}
          onclick={handleSelectType}
        />
      </div>
      <div className="w-full h-full rounded-[20px] bg-white p-6 flex flex-col space-y-6">
        <div className="flex justify-end items-center space-x-6">
          <div className="flex items-center justify-between gap-3">
            <Input
              iconLeft={<BsSearch />}
              placeholder="Search..."
              inputClass="py-[6px] px-[15px] rounded-r-0"
              onchange={({ target }) => setSearch(target.value)}
            />
            <CSVLink
              data={filteredData ?? []}
              // data={EnergyVendInfo?.data ?? []}
              headers={GatePassHeader}
              // headers={EnergyCSVHeader}
              filename="GatePass.CSV"
            >
              <Button iconLeft={<FiDownload />} variant="none">
                <Text as="p" weight="semibold">
                  Download
                </Text>
              </Button>
            </CSVLink>
          </div>
        </div>
        <Table
          data={filteredData ?? []}
          columns={access_records_column}
          isLoading={isFetching || isLoading}
          search={search}
        />
      </div>
    </div>
  );
}
