import React from "react"
import { clx } from "../../../utils/clx";
import Button from "../../../components/button";
import Text from "../../../components/text";
import useAdminAffiliates from "../../../store/admin/affiliates";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ModalWrapper from "../../../components/modals/modal-wrapper";
import axios from "axios";


export default function RemoveAffiliate() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [tag, setTag] = React.useState("")
    const [isOpen, setIsOpen] = React.useState(false)
    const { affiliates } = useAdminAffiliates();
    const navigate = useNavigate();
    const authUser = useAuthUser();


    const onSubmit = (e) => {
        e.preventDefault()
        setIsOpen(true)
    }

    const handleRemove = () => {
        setIsLoading(true)
        axios.get(`https://pm-server.herokuapp.com/api/v2.0/manager/remove-affiliate?tag=${tag}`, {
            headers: {
                Authorization: authUser().authToken,
            }
        })
            .then(res => {
                toast.success(res.data.message)
                navigate('/manager/configure/affiliates')
            }).catch(err => {
                toast.error(err.response.data.message)
            }).finally(() => {
                setIsLoading(false)
                setIsOpen(false)
            })
    }

    const classes = clx("w-[93%] lg:w-[90%] flex items-center flex-col mx-auto lg:py-[40px]");
    return (
        <React.Fragment>
            <div className={classes}>
                <div>
                    <Text as='h2' weight='bold' classname='text-[24px] text-center text-primary-light'>
                        Remove Affiliate
                    </Text>
                </div>
                {/* radio state */}
                <form onSubmit={onSubmit} className="flex justify-center py-6 mt-7 px-6 lg:px-12  flex-col w-[90%] lg:min-w-[400px] lg:max-w-fit rounded-[20px] bg-white">
                    <>
                        <>
                            <div className="flex flex-col justify-start space-y-3 mb-6">
                                <Text as='p' weight='bold' classname='text-[16px] text-left'>
                                    Select Affiliate:
                                </Text>
                                <select
                                    value={tag}
                                    onChange={(e) => {
                                        setTag(e.target.value)
                                    }
                                    }
                                    className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px] my-6"
                                    required
                                >
                                    <>
                                        <option value="">Select Affiliate</option>
                                        {typeof affiliates === "object" &&
                                            Object.keys(affiliates).map((item, i) => {
                                                return (
                                                    <option key={item} value={item}>
                                                        {item}
                                                    </option>
                                                );
                                            })}
                                    </>
                                </select>

                            </div>
                        </>
                        <Button
                            buttonType="submit"
                        >
                            Remove
                        </Button>
                    </>
                </form>
            </div>
            <ModalWrapper
                isOpen={isOpen}
                handleModalClose={() => setIsOpen(false)}
            >
                <ConfirmRemove isLoading={isLoading} onButtonClick={handleRemove} />
            </ModalWrapper>
        </React.Fragment>
    )
}


export const ConfirmRemove = ({ onButtonClick, isLoading }) => {
    const handleComfirm = () => {
        onButtonClick()
    }

    return (
        <div>
            <Text
                as="h3"
                weight="bold"
                classname="text-[24px] lg:text-[30px] text-center"
            >
                Remove Affiliate
            </Text>
            <div className="my-6 flex flex-col space-y-6">
                <Text as="p" weight="medium" classname=" text-secondary-dark">
                    Are you sure you want to remove this affiliate?
                </Text>
            </div>
            <Button onclick={handleComfirm} isLoading={isLoading} classname="w-full">
                Confirm
            </Button>
        </div>
    )
} 