import React, { useState } from 'react';
import BillingCard from '../../../components/billing-second-card';
import BillingForm from '../../../components/billing-form';

const BillingIndex = () => {
  const [showForm, setShowForm] = useState(false);
  const [billings, setBillings] = useState([]);

  const addBilling = (billing) => {
    setBillings([...billings, billing]);
    setShowForm(false);
  };

  const deleteBilling = (index) => {
    setBillings(billings.filter((_, i) => i !== index));
  };

  const editBilling = (index, updatedBilling) => {
    const newBillings = billings.slice();
    newBillings[index] = updatedBilling;
    setBillings(newBillings);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="flex flex-wrap gap-4">
        <div
          className="bg-white p-6 rounded-lg shadow-md cursor-pointer flex items-center justify-center w-64 h-64"
          onClick={() => setShowForm(true)}
        >
          <div className="text-center">
            <div className="text-2xl font-bold mb-2">ADD BILLING</div>
            <div className="text-4xl">+</div>
          </div>
        </div>
        {billings.map((billing, index) => (
          <BillingCard
            key={index}
            billing={billing}
            onDelete={() => deleteBilling(index)}
            onEdit={(updatedBilling) => editBilling(index, updatedBilling)}
          />
        ))}
      </div>
      {showForm && <BillingForm onSubmit={addBilling} onClose={() => setShowForm(false)} />}
    </div>
  );
}

export default BillingIndex;
