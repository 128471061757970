import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import Button from "../../components/button";
import Input from "../../components/input";
import OTP from "../../components/otp";
import Text from "../../components/text";
import AuthLayout from "../../layouts/auth-layout";
import { clx } from "../../utils/clx";
import axios from "axios";
import { toast } from "react-toastify";

export default function ForgetPassPageWrapper() {
  return (
    <AuthLayout pageTitle="Forgot Password" usePageNavigation>
      <div className="w-full flex flex-col space-y-8 items-center py-[20px] lg:py-0">
        <div>
          <Text
            as="p"
            classname="hidden lg:block font-[600] text-[54px] text-primary-dark text-center"
          >
            Forgot password ?
          </Text>
          <Text
            as="p"
            weight="medium"
            classname="text-[14px] lg:text-[20px] text-primary-dark lg:text-center"
          >
            Enter your username or email to retrieve your password
          </Text>
        </div>
        <div className="lg:bg-[#F8F8FF] lg:border-[1px] rounded-[16px] w-full lg:w-[550px] py-[0px] lg:py-[50px] px-[0px] lg:px-[70px]">
          <ForgetPassPageWrapper.Form />
        </div>
      </div>
    </AuthLayout>
  );
}

export function Form({}) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const requestOTP = async (credential) => {
    setLoading(true);
    return axios.get(
      `https://api-pm-e1ea777feac4.herokuapp.com/user/retrieve-password?credential=${credential}`
    );
  };

  const formValues = {
    credential: "",
  };

  const validationSchema = Yup.object({
    credential:
      Yup.string().required("Please enter your username or email") ||
      Yup.string().email().required("Please enter your username or email"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setValidationAttempt(false);
    await requestOTP(values.credential)
      .then((res) => {
        toast.success(
          "Your password have been received. Please check your mailbox."
        );
        setLoading(false);
        resetForm();
      })
      .catch((err) => {
        toast.error("Invalid Username/Email");
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col items-center space-y-6">
        <div className="w-full mb-[30px]">
          <Input
            label="Enter Username / Email"
            iconLeft={<img src="/assets/icons/meter-id-icon.png" alt="icon" />}
            placeholder="Username / Email"
            classname="w-full"
            name="credential"
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.credential}
            value={formik.values.credential}
          />
        </div>
        <Button isLoading={loading} buttonType="submit" classname="w-full">
          Submit
        </Button>
      </div>
    </form>
  );
}

ForgetPassPageWrapper.Form = Form;
