import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import CopyText from "../../../../components/toast/copy-text";
import { toast } from "react-toastify";
import { BiCopy } from "react-icons/bi";
import Text from "../../../../components/text";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "../../../../components/button";
import Input from "../../../../components/input";
import { AiOutlineBarcode } from "react-icons/ai";
import axios from "axios";
import useBuyElectricityData from "../../../../store/static/buy-electricity";
import { useNavigate } from "react-router-dom";

export default function UserInfo() {
  const navigate = useNavigate();

  const { userInfo, setUserInfo, electricityInfo } = useBuyElectricityData();
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const isData = Object.entries(userInfo).length;
  // console.log(electricityInfo);
  // console.log(isData)
  const formValues = {
    otp: "",
  };

  const validationSchema = Yup.object({
    otp: Yup.number().required("OTP is required"),
  });

  const onSubmit = (values) => {
    console.log(values);
    setIsLoading(true);
    setValidationAttempt(false);
    axios
      .get(
        `https://api-pm-e1ea777feac4.herokuapp.com/otp/user-registration/${electricityInfo.email}/${values.otp}`
      )
      .then((res) => {
        // console.log(res.data);
        setUserInfo(res.data.data);
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  console.log(userInfo);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    validateOnChange: validationAttempt,
  });
  return (
    <>
      {!isData && isData === 0 && (
        <div className="flex flex-col mx-auto mb-14 w-full space-y-6 ">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col p-5 md:p-20 z-10 mb-14 space-y-6 justify-between mx-auto w-full rounded-lg bg-[#F8F8FF]"
          >
            <Text as="h1" classname="text-xl text-center">
              Verify the 4 digits OTP Sent to your mail
            </Text>
            <Input
              placeholder="OTP"
              label="OTP:"
              type="number"
              name="otp"
              onchange={(e) => formik.handleChange(e)}
              onblur={formik.handleBlur}
              value={formik.values.otp}
              errorMessage={formik.errors.otp}
              iconLeft={<AiOutlineBarcode />}
            />

            <Button buttonType="submit" isLoading={isLoading}>
              Verify
            </Button>
          </form>
        </div>
      )}
      {isData && isData !== 0 && (
        <div className="flex mb-14 flex-col p-5 md:p-20 z-10 mb-14justify-between mx-auto w-full rounded-lg bg-[#F8F8FF] space-y-6 ">
          <div className="flex flex-col md:flex-row items-center md:items-start  md:space-x-7 mb-[20px]">
            <img
              src="/assets/icons/success-icon.png"
              alt=""
              className="w-20 h-20  lg:w-32 lg:h-32"
            />

            <div className="flex flex-col space-y-3 justify-start">
              <Text
                as="h1"
                weight="bold"
                classname="text-2xl md:text-[42px] md:text-left text-center"
              >
                Congratulations
              </Text>
              <Text
                as="p"
                classname="text-sm md:text-[16px] text-center md:text-justify text-[#9799A5]"
              >
                Here is your meter’s permanent bank account number. Transfer to
                get instant value. You will get your receipt via sms and email.
              </Text>
            </div>
          </div>

          <Text
            as="p"
            weight="bold"
            classname="text-sm md:text-[16px] text-center text-[#9799A5]"
          >
            Account Name: {userInfo?.virtual_account?.account_name}
          </Text>

          <Text
            as="p"
            weight="bold"
            classname="text-sm md:text-[16px] text-center text-black"
          >
            Bank Name: {userInfo?.virtual_account?.bank_name}
          </Text>

          <div className="flex flex-row gap-4 items-center justify-center p-4 mt-[20px] rounded-lg mx-auto border border-secondary-dark mb-[20px]">
            <Text
              as="p"
              weight="bold"
              classname="text-black text-center text-sm md:text-[16px]"
            >
              Account Number: {userInfo?.virtual_account?.account_number}
            </Text>
            <CopyToClipboard
              text={userInfo?.virtual_account?.account_number}
              onCopy={() =>
                toast.success(<CopyText>Token copied successfully!</CopyText>)
              }
            >
              <BiCopy className="block text-black cursor-pointer" />
            </CopyToClipboard>
          </div>

          <Button
            classname="w-full"
            buttonType="button"
            onclick={() => {
              window.location.reload();
              navigate("/login");
            }}
          >
            Login
          </Button>
        </div>
      )}
    </>
  );
}
