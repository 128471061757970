import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useQuery } from "react-query";

export default function useAffiliateList(query="get-affiliate",name="") {
  const authUser = useAuthUser();
  const fetchAllAffiliateList = () => {
    return axios.get(
      `https://pm-server.herokuapp.com/api/v2.0/affiliate?query=${query}&name=${name}`,
      {
        headers: {
          Authorization: authUser().authToken,
        },
      }
    );
  };
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [`all-affiliate-list`],
    queryFn: () => fetchAllAffiliateList(),
  });

  return { data: data?.data, isLoading, isFetching, refetch };
}
