import axios from "axios";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import useUserInfo from "./useUserInfo";
import useUserStore from "../../../store/user/store";
import { useQuery } from "react-query";

export default function useVendInfo({ prepaid }) {
  const user = useUserStore((state) => state.user);
  const authUser = useAuthUser();

  //   const navigate = useNavigate();
  const { isLoading, data, isError } = useQuery("VendInfo", () =>
    axios.post(
      "https://api-pm-e1ea777feac4.herokuapp.com/metering/user-prepaid-txn",
      {prepaid },
      {
        headers: {
          Authorization: authUser().authToken,
        },
      }
    )
  );
  return { data: data?.data, isLoading, isError };
}
