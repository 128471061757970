import React from "react";
import { clx } from "../../../utils/clx";
import Text from "../../../components/text";
import * as IMG from "./../../../images";
import CustomSlider from "./CustomSlider";

const Services = () => {
  
  const classes = clx(
    "py-6 lg:py-[80px] px-6 lg:px-20 sm:mb-3 overflow-hidden",
    "bg-gray-200" // Added background color style
  );

  return (
    <div className={classes}>
      <div className="text-center mb-6"> {/* Centering and adding margin bottom to "Our Solutions" text */}
        <Text
          as="h3"
          weight="semibold"
          classname="text-3xl md:text-4xl lg:text-5xl xl:text-6xl" // Increased text size
        >
          Our Solutions
        </Text>
      </div>
      <CustomSlider>
        {data.map((item, index) => (
          <div key={index} className="flex items-center justify-center">
            <div className="mx-4"> {/* Adding space between images */}
              <div className="w-full h-full flex items-center justify-center"> {/* Image container */}
                <img
                  src={item.imgSrc}
                  alt={item.title}
                  className="object-contain max-w-full max-h-full" // Adjusted image styles
                />
              </div>
            </div>
          </div>
        ))}
      </CustomSlider>
    </div>
  );
};

export default Services;

const data = [
  {
    title: "Energy Metering",
    imgSrc: IMG.energy,
  },
  {
    title: "Energy Audit",
    imgSrc: IMG.wind,
  },
  {
    title: "Water Metering",
    imgSrc: IMG.water,
  },
  {
    title: "Gas Metering",
    imgSrc: IMG.wind,
  },
  {
    title: "Generator Monitoring",
    imgSrc: IMG.generator,
  },
  {
    title: "Power Management",
    imgSrc: IMG.management,
  },
];
