import axios from "axios";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthUser, useSignOut } from "react-auth-kit";
import useUserStore from "../../../store/user/store";
import { useQuery } from "react-query";
import useAdminRank from "../../../store/admin/adminRank";
import useAdminUser from "../../../store/admin/admin-user";

export default function useAdminUserInfo() {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const authUser = useAuthUser();
  // const { setAdminRank } = useAdminRank();
  const {
    isLoading,
    data: user,
    isError,
    refetch,
    isFetching,
  } = useQuery("AdminUserInfo", () =>
    axios
      .get("https://pm-server.herokuapp.com/api/v2.0/user/info", {
        headers: {
          Authorization: authUser().authToken,
        },
      })
      .catch((err) => {
        if (
          err.response.data.message ===
          "Session timeout, Please login again.!!!"
        ) {
          signOut();
        }
      })
  );

  // React.useEffect(() => {
  //   setAdminRank(user?.data?.data.__rank__);
  // }, []);
  return { UserInfo: user?.data, isLoading, isError, isFetching, refetch };
}
