import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
const useAccountResolution = () => {
  const [resolvedAccountName, setResolvedAccountName] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setError] = useState(null);

  const resolveAccountName = async (accountNumber, bankCode) => {
    setIsFetching(true);
    try {
      const response = await axios.get(
        `https://pm-server.herokuapp.com/api/v2.0/resolve-bank-account?account_number=${accountNumber}&bank_code=${bankCode}`
      );
      const { account_name } = response?.data?.data;
      setResolvedAccountName(account_name);
      setIsFetching(false);
    } catch (error) {
      //   console.error("Failed to resolve account name:", error);
      setError(error.response.data.message);
      setResolvedAccountName("");
      setIsFetching(false);
    }
  };

  return { resolvedAccountName, resolveAccountName, isError, isFetching, setResolvedAccountName };
};

export default useAccountResolution;
