import Text from "../../../../components/text";
import { FiTruck } from "react-icons/fi";
import { TbMotorbike } from "react-icons/tb";

export default function Shipping() {
  return (
    <div className="flex flex-col gap-10">
      <div className="flex gap-3 items-center">
        <div className="rounded-lg flex items-center justify-center bg-[#9799A5] p-[10px]">
          <FiTruck className="w-6 h-6" />
        </div>
        <div>
          <Text as="h3" weight="bold" classname=" text-xl text-gray-600 ">
            Delivery
          </Text>
          <Text as="p" classname="text-sm text-gray-600">
            Estimated delivery time: 1 - 7 days
          </Text>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="rounded-lg flex items-center justify-center bg-[#9799A5] p-[10px]">
          <TbMotorbike className="w-6 h-6" />
        </div>
        <div>
          <Text as="h3" weight="bold" classname=" text-xl text-gray-600 ">
            Express Delivery Available
          </Text>
          <Text as="p" classname="text-sm text-gray-600">
            Same day delivery: Order before 11AM and get it today <br />
            Next day delivery: Order after 11AM and get it tomorrow
          </Text>
        </div>
      </div>
    </div>
  );
}
