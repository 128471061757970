import React from "react";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";


import Text from "../../../text";
import Button from "../../../button";
import useAdminAffiliates from "../../../../store/admin/affiliates";

export default function ExceptionalModal({ data, modalType, query }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const naviate = useNavigate();
  const authUser = useAuthUser();
  const { selectedAffiliate } = useAdminAffiliates();
  const onSubmit = () => {
    const userid = data?.userid;
    setIsLoading(true);
    axios.post(`https://pm-server.herokuapp.com/api/v2.0/manager/set-user-charges?query=${query}`, {
      userid,
      tag: selectedAffiliate
    }, {
      headers: {
        Authorization: authUser().authToken,
      },
    }).then(res => {
      naviate('/manager/configure/user-management')
      toast.success(res.data.message)
    }).catch(err => {
      toast.error(err.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  };

  return (
    <div>
      <Text
        as="h3"
        weight="bold"
        classname="text-[24px] lg:text-[30px] text-center capitalize"
      >
        {modalType}
      </Text>
      <div className="my-6 flex flex-col space-y-6">
        <Text as="p" weight="medium" classname=" text-secondary-dark">
          Are you sure you want to {modalType} this charges {data?.userid}?
        </Text>
      </div>
      <Button onclick={onSubmit} isLoading={isLoading} classname="w-full capitalize">
        {modalType}
      </Button>
    </div>

  );
}
