import React, { useCallback } from "react";
import ModalWrapper from "../../../../components/modals/modal-wrapper";
import EditApartment from "../../../../components/modals/admin/edit-apartment";
import DeleteUser from "../../../../components/modals/admin/delete-user";
import EditCategory from "../../../../components/modals/admin/edit-category";
import EditNumber from "../../../../components/modals/admin/edit-number";
import EditName from "../../../../components/modals/admin/edit-name";

export default function UsersActions({ value, row }) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");

  const handleButtonClick = useCallback(
    (type) => {
      if (type === "" || type === undefined) return setModalType("");
      setModalType(type);
      setModalOpen(true);
    },
    [modalType]
  );

  return (
    <React.Fragment>
      <select
        value={modalType}
        onChange={(e) => {
          handleButtonClick(e.target.value);
        }}
        className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
      >
        <option value="">Select Action</option>
        <option value="edit">Edit Apartment</option>
        <option value="category">Edit Category</option>
        <option value="name">Edit Name</option>
        <option value="number">Edit Phone Number</option>
      </select>

      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => {
          setModalOpen(false);
          setModalType("");
        }}
      >
        {modalType === "edit" && <EditApartment data={row.original} />}
        {modalType === "delete" && <DeleteUser data={row.original} />}
        {modalType === "category" && <EditCategory  data={row.original} />}
        {modalType === "name" && <EditName  data={row.original} />}
        {modalType === "number" && <EditNumber  data={row.original} />}
      </ModalWrapper>
    </React.Fragment>
  );
}
