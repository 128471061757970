import React from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import Input from "../../components/input";
import Text from "../../components/text";
import { clx } from "../../utils/clx";
import TransactionHistory from "../../components/transaction-history";

export default function WaterTransactionWrapper() {
  React.useEffect(() => window.scrollTo(0, 0));

  const classes = clx(
    "w-[93%] max-w-[93%] lg:w-[90%] lg:max-w-[90%] overflow-hidden mx-auto"
  );
  return (
    <div className={classes}>
      <div className='flex items-center space-x-6'>
        <MdArrowBackIosNew className='text-[#9799A5] text-[20px] cursor-pointer' />
        <Text as='span' classname='text-[#9799A5]'>
          Back to Prepaid Water Dashboard
        </Text>
      </div>
      <Text as='h5' weight='semibold' classname='text-[28px] py-[25px]'>
        Transaction History
      </Text>
      <Input
        iconLeft={<BiSearch className='text-[#7D8195] text-[20px]' />}
        placeholder='Please type your question here.'
        inputClass='bg-[#F5F5FE] w-[70%] mx-auto my-[20px]'
      />
      <div className='py-[30px] overflow-auto'>
        <div className='w-[700px] max-w-[700px] lg:w-[100%] lg:max-w-[100%] overflow-hidden'>
          <div className='overflow-auto w-[100%] flex'>
            <Text as='span' classname='w-[40%] text-center text-[#2C3048]'>
              Reference Id
            </Text>
            <Text as='span' classname='w-[20%] text-[#2C3048]'>
              Amount
            </Text>
            <Text as='span' classname='w-[20%] text-[#2C3048]'>
              Amount
            </Text>
            <Text as='span' classname='w-[20%] text-[#2C3048]'>
              Token
            </Text>
          </div>
        </div>
        <div>
          <TransactionHistory />
          <TransactionHistory />
        </div>
      </div>
    </div>
  );
}
