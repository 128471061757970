import React from "react";
import Text from "../text";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import CopyText from "../toast/copy-text";
import { BiCopy } from "react-icons/bi";

export default function VirtualAccountInfo({ user }) {
  return (
    <div className="flex flex-col items-center space-y-3">
      <Text
        as="h3"
        weight="bold"
        classname="text-[24px] lg:text-[30px] text-center"
      >
        Virtual Account
      </Text>
      <div>
        <Text as="p" classname="text-center">
          Bank Name:
        </Text>
        <Text as="p" weight="semibold" classname="text-center text-[18px]">
          {user?.virtual_account?.bank}
        </Text>
      </div>
      <div>
        <Text as="p" classname="text-center">
          Account Name:
        </Text>
        <Text as="p" weight="semibold" classname="text-center text-[18px]">
          {user?.virtual_account?.account_name}
        </Text>
      </div>
      <div>
        <Text as="p" classname="text-center">
          Account Number:
        </Text>
        <div className="flex items-center space-x-2">
          <Text as="p" weight="semibold" classname="text-primary-dark">
            {user?.virtual_account?.account_number}
          </Text>
          <CopyToClipboard
            text={user?.virtual_account?.account_number}
            onCopy={() =>
              toast.success(
                <CopyText>Account Number copied successfully!</CopyText>
              )
            }
          >
            <BiCopy className="block text-black cursor-pointer" />
          </CopyToClipboard>
        </div>
      </div>
      <Text
        as="p"
        weight="semibold"
        classname="text-center lg:max-w-[300px] mx-auto text-secondary-dark"
      >
        Send Money to your Wema Bank Virtual Account and your wallet will be
        funded automatically. Please do not transfer below ₦500. Thank you.
      </Text>
    </div>
  );
}
