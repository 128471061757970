import React from "react";
import { BiSearch } from "react-icons/bi";
import Input from "../../components/input";
import Text from "../../components/text";
import { clx } from "../../utils/clx";
import { getSortedLastToStart } from "../../utils/getSortedLastToStart";
import useVendInfo from "../../hooks/apis/user/useVendInfo";
import { EnergyTransactionHistoryColumns } from "../../utils/table-utils/user/columns/energy-transaction-history";
import Table from "../../components/table";

export default function EnergyTransactionWrapper() {
  const transaction_columns = React.useMemo(
    () => EnergyTransactionHistoryColumns,
    []
  );
  // const { data:  } = useWalletInfo({ action: "transactions" });
  const { data: transactionsInfo } = useVendInfo({ prepaid: "energy" });
  const transactions = getSortedLastToStart(transactionsInfo?.data);

  const [search, setSearch] = React.useState("");

  React.useEffect(() => window.scrollTo(0, 0));

  const classes = clx(
    "w-[93%] max-w-[93%] lg:w-[90%] lg:max-w-[90%] overflow-hidden mx-auto"
  );
  return (
    <div className={classes}>
      {/* <div className='flex items-center space-x-6'>
        <MdArrowBackIosNew className='text-[#9799A5] text-[20px] cursor-pointer' />
        <Text as='span' classname='text-[#9799A5]'>
          Back to Prepaid Energy Dashboard
        </Text>
      </div> */}
      <Text
        as='h5'
        weight='semibold'
        classname='text-[18px] lg:text-[28px] py-[25px]'
      >
        Transaction History
      </Text>
      <Input
        value={search}
        onchange={({ target }) => setSearch(target.value)}
        iconLeft={<BiSearch className='text-[#7D8195] text-[20px]' />}
        placeholder='Please type your question here.'
        inputClass='bg-[#F5F5FE] w-[70%] mx-auto my-[20px]'
      />
      <div className='py-[30px]'>
        <Table
          search={search}
          columns={transaction_columns}
          data={transactions}
        />
        {/* <div className='w-[700px] max-w-[700px] lg:w-[100%] lg:max-w-[100%]'>
          <EnergyTransactionHistoryTable tableInstance={tableInstance} />
        </div> */}
      </div>
    </div>
  );
}
