import React from "react";
import AdminDashboardLayout from "./admin-dashboard";
import { RequireAuth, useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import useAdminAffiliates from "../../../store/admin/affiliates";
import Text from "../../../components/text";
import useAdminUserInfo from "../../../hooks/apis/admin/useAdminUserInfo.js";

export default function ProtectedAdminDashboard(props) {
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const [isManager, setIsManager] = React.useState(false);
  

  React.useEffect(() => {
    if (authUser() && authUser().user !== "manager") {
      return navigate("/manager/login");
    } else {
      // refetch();
      setIsManager(true);
    }
  });
  if (isManager) {
    return (
      <RequireAuth loginPath="/manager/login">
        <AdminDashboardLayout {...props} />
      </RequireAuth>
    );
  }
}
