import React from "react";
import Text from "../text";
import CurrencyFormat from "react-currency-format";
import Radio from "../radio";
import Button from "../button";
import { toast } from "react-toastify";

export default function ConfirmPeriodicCharges({
  data,
  onButtonClick,
  isLoading,
}) {
  const [agree, setAgree] = React.useState(false);

  const onSubmit = () => {
    if (!agree) return toast.error("Kindly agree to the terms and conditions");
    onButtonClick();
  };
  return (
    <div className="flex flex-col justify-between items-center">
      <Text
        as="h3"
        weight="bold"
        className="text-center text-2xl md:text-[20px] mb-4"
      >
        {data?.name}
      </Text>
      <div className="flex w-full flex-col max-w-[600px] rounded-lg gap-2 px-4 py-4 bg-[#F0F0FF] mt-6">
        <table>
          {data?.insights?.map((item) => (
            <tr key={item?.slug} className="flex flex-row justify-between">
              <td className="text-left font-semibold text-[14px]  px-3 capitalize">
                {item?.name}
              </td>
              <td className="text-right text-[14px] px-3 font-semibold ">
                <CurrencyFormat
                  value={item?.amount}
                  displayType="text"
                  decimalScale={2}
                  renderText={(value) => <Text as="span">₦{value}</Text>}
                  thousandSeparator
                  fixedDecimalScale
                />
              </td>
            </tr>
          ))}
        </table>
      </div>

      <Text as="h6" classname="mt-[10px] text-[14px]">
        <CurrencyFormat
          value={data?.total}
          displayType="text"
          decimalScale={2}
          renderText={(value) => <Text as="span">₦{value}</Text>}
          thousandSeparator
          fixedDecimalScale
        />
        {" * "} {data?.replica}
      </Text>

      <Text
        as="h2"
        weight="semi-bold"
        classname="text-center mt-[10px] text-[24px]"
      >
        <CurrencyFormat
          value={data?.amount_to_pay}
          displayType="text"
          decimalScale={2}
          renderText={(value) => <Text as="span">₦{value}</Text>}
          thousandSeparator
          fixedDecimalScale
        />
      </Text>

      <Radio
        label="I agree to be debited the amount above instantly from my wallet"
        name="checkbox"
        value={agree}
        onchange={(e) => setAgree(e.target.checked)}
        checked={agree}
        onclick={(e) => setAgree(e.target.checked)}
        classname="text-[12px]  text-gray-400  mt-6"
      />

      <Button
        classname="text-[12px]  mt-6"
        onclick={onSubmit}
        isLoading={isLoading}
      >
        Pay now
      </Button>
    </div>
  );
}
