import React from "react";
import { BsCheck2 } from "react-icons/bs";


const Checkbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
        <>
            <div className="relative w-6 h-6 rounded-md border-[1px] flex items-center justify-center border-gray-400">
                <input type="checkbox" ref={resolvedRef} {...rest} className="z-[999] absolute opacity-0" />
                <span className="">
                    {rest.checked ? <BsCheck2 size={20} /> : ""}
                </span>
            </div>
        </>
    )
})

export default Checkbox