import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";
import { configureItems } from "../../../constants/admin-configureItems";
import useAdminRank from "../../../store/admin/adminRank";
import useAdminData from "../../../store/admin/adminData";

export default function ConfigureWrapper() {
  const { adminRank } = useAdminRank()
  const { adminData } = useAdminData()
  const features = useMemo(() => {
    return adminData?.features
  }, [adminData])




  const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
  return (
    <div className={classes}>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 gap-[40px] mt-[20px] items-center">
        {configureItems.map((item) => {
          if (item.rank <= adminRank && !features?.hide?.find(({ path }) => path === item.path) || features?.unhide?.find(({ path }) => path === item.path)) {

            return (
              <Link
                to={`/manager/configure/${item.path}`}
                key={item.id}
                className="hover:shadow-md"
              >
                <div
                  key={item.id}
                  className="flex flex-col items-center  justify-center w-full bg-white  rounded-md p-[20px] h-[200px] gap-3"
                >
                  <img src={item.image} className="w-[78px]" alt="icon" />
                  <Text as="p" classname={`font-bold`}>
                    {item.title}
                  </Text>
                </div>
              </Link>
            )
          }
        })}
      </div>
    </div>
  );
}
