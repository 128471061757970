import React from "react";
import { clx } from "../../../../utils/clx";
import PaymentsInvoicesTab from "./tabs";
import Invoices from "./invoices";
import Payout from "./payout";
import useAdminAffiliates from "../../../../store/admin/affiliates";
import { useNavigate } from "react-router-dom";

export default function PaymentsInvoiceWrapper() {
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = React.useState(1);

    const {  setSelectedAffiliate } = useAdminAffiliates();
    const redirect = React.useCallback(() => {
        if (!setActiveTab) {
            navigate('/manager/configure')
        }
    }, [setSelectedAffiliate])

    React.useEffect(() => {
        redirect()
    }, [redirect])

    const classes = clx(
        "w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex flex-col space-y-[40px]"
    );
    return (
        <div className={classes}>

            <PaymentsInvoicesTab activeTab={activeTab} setTab={setActiveTab} />
            <div>
                {activeTab === 1 && <Invoices />}
                {activeTab === 2 && <Payout />}
            </div>
        </div>
    );
}