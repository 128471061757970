import React from "react";
import { clx } from "../../utils/clx";
import Text from "../../components/text";
import BalanceCard from "../../components/balance-card";
import DiscoMeterIdContainer from "../../components/disco-meterid-container";
import DiscoMeterTransactionCard from "../../components/disco-meter-transaction-card";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import CopyText from "../../components/toast/copy-text";
import { toast } from "react-toastify";
import { BiCopy } from "react-icons/bi";
import useGetDiscoMeters from "../../hooks/apis/user/useGetDiscoMeters";
import Button from "../../components/button";
import ModalWrapper from "../../components/modals/modal-wrapper";
import { TbCurrencyNaira } from "react-icons/tb";
import Input from "../../components/input";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";
import { getFormattedPrice } from "../../utils/getFormattedPrice";
import useUserStore from "../../store/user/store";

export const transactionInfo = Array.from({ length: 10 }, () => ({
  Total_paid: "2323423",
  Gen_Date: new Date().toDateString(),
  Total_unit: "1.1",
  Token: "12341234",
  refr: "234124352356",
}));

export default function DiscoMeterWrapper({ classname }) {
  const [isVendOpen, setIsVendOpen] = React.useState(false);
  const params = useParams();

  const navigate = useNavigate();
  // console.log(params.slug);
  const classes = clx("w-[93%] lg:w-[90%] mx-auto py-[40px]", classname);

  const { data: meterDetails } = useGetDiscoMeters({
    key: "get-meter",
    query: `get-disco-profile&meterid=${params.slug}`,
  });
  // console.log(meterDetails);

  return (
    <React.Fragment>
      <div className={classes}>
        <div className="flex flex-col md:flex-row space-y-3 md:items-center md:space-x-6">
          <Text as="span" classname="text-[12px] lg:text-[16px]">
            Meter Number:
          </Text>
          <DiscoMeterIdContainer
            number={meterDetails?.data?.meterid}
            name={meterDetails?.data?.name}
           
          />
        </div>
        <div className="flex flex-col-reverse md:space-x-6 md:flex-row space-y-6  md:my-6 ">
          <div className="md:max-w-[50%] w-full flex flex-col mt-6 md:mt-0 gap-6">
            <BalanceCard
              platform={meterDetails?.data?.type}
              title="Total Recharge"
              // unit={meterDetails?.data?.total_credit}
              price={meterDetails?.data?.total_credit}
              classname="md:px-6 md:py-6 p-3 flex-col"
            />
            <div>
              <Button
                type="secondary"
                size="small"
                onclick={() => setIsVendOpen(true)}
                classname="w-full lg:w-fit"
              >
                Recharge from wallet
              </Button>
            </div>
          </div>
          <AccountInfo
            account_name={meterDetails?.data?.virtual_account?.account_name}
            account_number={meterDetails?.data?.virtual_account?.account_number}
            bank_name={meterDetails?.data?.virtual_account?.bank_name}
          />
        </div>
        <div className="">
          <div className="flex items-center justify-between py-[20px]">
            <Text
              as="span"
              weight="medium"
              classname="lg:text-[23px] text-[#6C6F7F]"
            >
              Recent Transactions
            </Text>
            <Text
              as="span"
              weight="medium"
              classname="lg:text-[23px] text-secondary-dark"
              onclick={() =>
                navigate(
                  `/user/disco-meters/${params.slug}/disco-meter-transaction`
                )
              }
            >
              see all
            </Text>
          </div>
          <div className="lg:py-[30px] flex flex-col space-y-6">
            {meterDetails &&
              meterDetails?.data?.transactions
                .slice(0, 5)
                .map((transaction, index) => (
                  <DiscoMeterTransactionCard
                    key={index}
                    data={transaction}
                    // onclick={() => handleModalControl("transaction")}
                    classname="w-[100%]"
                  />
                ))}
          </div>
        </div>
      </div>

      <ModalWrapper
        isOpen={isVendOpen}
        handleModalClose={() => setIsVendOpen(false)}
      >
        <VendMeterForm meterid={params.slug} />
      </ModalWrapper>
    </React.Fragment>
  );
}

const AccountInfo = ({ account_name, account_number, bank_name }) => {
  return (
    <div className="flex flex-col  rounded-xl border-primary-light border space-y-3">
      <Text
        as="h3"
        weight="bold"
        classname="text-[18px] lg:text-[30px] px-[20px] lg:px-[40px] py-[10px] lg:py-[20px] rounded-t-xl text-center bg-primary-light text-white "
      >
        Virtual Account
      </Text>

      <div className="flex flex-col py-[10px] lg:py-[20px] px-[20px] lg:px-[40px] gap-1 ">
        <Text as="p" classname="">
          Account Name: {account_name}
        </Text>
        <Text as="p" weight="semibold" classname=" text-[18px]"></Text>

        <div className="flex space-x-6">
          <Text as="p" classname="">
            Account Number:{account_number}
          </Text>
          <div className="flex items-center space-x-2">
            <Text as="p" weight="semibold" classname="text-primary-dark"></Text>
            <CopyToClipboard
              text={account_number}
              onCopy={() =>
                toast.success(
                  <CopyText>Account Number copied successfully!</CopyText>
                )
              }
            >
              <BiCopy className="block text-black cursor-pointer" />
            </CopyToClipboard>
          </div>
        </div>
        <Text as="p" classname="">
          Bank Name:{bank_name}
        </Text>
      </div>
    </div>
  );
};

const VendMeterForm = ({ meterid }) => {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const authUser = useAuthUser();
  const user = useUserStore((state) => state.user);
  const formValues = {
    amount: "",
  };

  const validationSchema = Yup.object({
    amount: Yup.number()
      .min(
        user?.minimum?.disco,
        `Minimum recharge can't be less than ₦${getFormattedPrice(
          user?.minimum?.disco
        )}`
      )
      .integer("Amount must be a whole number")
      .required("Amount is required"),
  });

  const onSubmit = (values) => {
    // console.log(values);
    setIsLoading(true);
    setValidationAttempt(false);
    axios
      .post(
        "https://api-pm-e1ea777feac4.herokuapp.com/metering/vend-disco-meter",
        {
          ...values,
          meterid: meterid,
        },
        {
          headers: {
            Authorization: authUser().authToken,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <Text
          as="h3"
          weight="bold"
          classname="text-[24px] lg:text-[30px] text-center"
        >
          Recharge Meter
        </Text>
        <div className="my-6 flex flex-col space-y-6">
          <Input
            iconLeft={<TbCurrencyNaira />}
            label="Amount:"
            placeholder="Enter amount"
            type="number"
            name="amount"
            value={formik.values.amount}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.amount}
          />
        </div>
        <Button
          isLoading={isLoading}
          onclick={() => setValidationAttempt(true)}
          buttonType="submit"
          classname="w-full"
        >
          Recharge
        </Button>
      </div>
    </form>
  );
};
