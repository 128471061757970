import axios from "axios";
import React from "react";
import { BiSearch } from "react-icons/bi";
import FAQ from "../../components/faq";
import Input from "../../components/input";
import Text from "../../components/text";
import { clx } from "../../utils/clx";

export default function FAQPageWrapper({ classname, children }) {
  const [activeFaq, setActiveFaq] = React.useState(1)
  const [activeTab, setActiveTab] = React.useState(1);
  const [faqs, setFaqs] = React.useState({})
  const filteredFaq = React.useMemo(() => {
    switch (activeTab) {
      case 1:
        return [
          ...(faqs.general ?? []),
          ...(faqs.prepaid ?? []),
          ...(faqs.iot ?? []),
        ];
      case 2:
        return [...(faqs.prepaid ?? [])];
      case 3:
        return [...(faqs.iot ?? [])];
      default:
        return null;
    }
  }, [activeTab, faqs])

  const handleOpen = (index) => {
    if (index + 1 === activeFaq) {
      setActiveFaq(null)
    } else {
    setActiveFaq(index + 1);
      
    }
  }

  React.useEffect(() => {
    axios.get("https://pm-server.herokuapp.com/api/v2.0/faq/?platform=user").then(res => {
      setFaqs(res.data.data)
    });
  }, [])
  
  return (
    <div className='w-[93%] lg:w-[90%] mx-auto py-[50px]'>
      <Input
        iconLeft={<BiSearch className='text-[#7D8195] text-[20px]' />}
        placeholder='Please type your question here.'
        inputClass='bg-[#F5F5FE] lg:w-[70%] mx-auto'
      />
      <FAQPageWrapper.Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className='flex flex-col space-y-[20px]'>
        {filteredFaq.map((faq, index) => (
          <FAQ
            body={faq.body}
            title={faq.title}
            isOpen={index + 1 === activeFaq}
            onclick={()=>handleOpen(index)}
            classname='w-[100%]'
            // active
          />
        ))}
      </div>
    </div>
  );
}

export function Tabs({ activeTab, setActiveTab, classname, children }) {

  const classes = clx(
    "flex lg:justify-center my-[40px] space-x-[20px] w-fit lg:w-[500px] mx-auto",
    classname
  );
  return (
    <div className="max-w-[100%] overflow-auto scrollbar-none">
      <div className={classes}>
        {tabs.map((tab, index) => (
          <Text
            key={index}
            as='span'
            onclick={() => setActiveTab(index + 1)}
            classname={`whitespace-nowrap text-[14px] lg:text-base py-2 px-3 cursor-pointer ${
              activeTab === index + 1
                ? "text-primary-dark  border-b-2 border-primary-dark font-semibold"
                : "text-[#9799A5]"
            }`}
          >
            {tab.label}
          </Text>
        ))}
      </div>
    </div>
  );
}

FAQPageWrapper.Tabs = Tabs;

const tabs = [
  {
    id: 1,
    label: "All",
    slug:'all'
  },
  {
    id: 2,
    label: "Prepaid",
    slug: 'prepaid'
  },
  {
    id: 4,
    label: "Fuel Monitory",
    slug:'iot'
  },
];
