import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useMutation } from "react-query";
import { clx } from "../../utils/clx";
import { supabase } from "./supabse";
import { useNavigate } from "react-router-dom";

const classes = clx("w-[93%] lg:w-[90%] flex flex-col mx-auto pt-8");

const CreateBlog = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState({
    title: "",
    content: "",
    images: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContent((prev) => ({ ...prev, [name]: value }));
  };

  const handleQuillChange = (content, delta, source, editor) => {
    setContent((prev) => ({ ...prev, content }));
  };

  
  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const imagesArray = Array.from(files);
  
      Promise.all(
        imagesArray.map(async (file) => {
          const reader = new FileReader();
  
          return new Promise((resolve) => {
            reader.onloadend = () => {
              const fileSizeInMB = file.size / (1024 * 1024); // Convert size to megabytes
  
              // Adjust this value based on your requirements
              const maxFileSize = 5; // Maximum file size in megabytes
  
              if (fileSizeInMB <= maxFileSize) {
                resolve(reader.result);
              } else {
                alert(`Image size exceeds the maximum allowed size of ${maxFileSize} MB.`);
                resolve(null); // Skip this image
              }
            };
            reader.readAsDataURL(file);
          });
        })
      ).then((imageResults) => {
        // Filter out null values (images that didn't meet the criteria)
        const validImages = imageResults.filter((image) => image !== null);
  
        setContent((prev) => ({ ...prev, images: [...prev.images, ...validImages] }));
      });
    }
  };
  
  const createBlogMutation = useMutation(
    async () => {
      const { data, error } = await supabase
        .from("blog_posts")
        .insert([
          {
            title: content.title,
            content: content.content,
            image: content.images,
          },
        ])
        .select();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
    {
      onSuccess: () => {
        console.log("Form submitted successfully");
      },
    }
  );
  const handleCancel = (index) => {
    setContent((prev) => {
      const updatedImages = [...prev.images];
      updatedImages.splice(index, 1);
      return { ...prev, images: updatedImages };
    });
  };

  const handleSubmit = () => {
    if (content.title && content.content ) {
      createBlogMutation.mutate();

      navigate("/blog");
    } else {
      alert("Please fill in all fields and upload at least one image.");
    }
  };

  return (
    <section className={classes}>
      <h1>CreateBlog</h1>
      <label>Title</label>
      <input
        type="text"
        className="p-2 border-2"
        placeholder="Title"
        value={content.title}
        name="title"
        onChange={handleChange}
      />

      <div className="editorContainer h-[300px] overflow-scroll border-2 my-5 ">
        <ReactQuill
          className="border-0 h-full"
          theme="snow"
          value={content.content}
          onChange={handleQuillChange}
          placeholder="content here"
        />
      </div>
      <input
        className="hidden p-2 border-2"
        type="file"
        id="images"
        name="images"
        onChange={handleFileChange}
        multiple 
      />

      <label className="file underline cursor-pointer py-3" htmlFor="images">
      Upload Images (optional)
    </label>
    

    {content.images.map((image, index) => (
      <div key={index} className="relative mb-3">
        <img
          src={image}
          alt={`Uploaded ${index + 1}`}
          className="w-full h-[350px]"
        />
        <button
          onClick={() => handleCancel(index)}
          className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded"
        >
          Cancel
        </button>
      </div>
    ))}

      <button
        onClick={handleSubmit}
        className="p-2 text-[#E1AA2B] border-2 w-40 rounded-lg mb-3 cursor-pointer focus:outline-none"
      >
        Submit
      </button>
    </section>
  );
};

export default CreateBlog;


  // const handleFileChange = (e) => {
  //   const files = e.target.files;
  //   if (files && files.length > 0) {
  //     const imagesArray = Array.from(files);

  //     Promise.all(
  //       imagesArray.map(async (file) => {
  //         const reader = new FileReader();
  //         return new Promise((resolve) => {
  //           reader.onloadend = () => {
  //             resolve(reader.result);
  //           };
  //           reader.readAsDataURL(file);
  //         });
  //       })
  //     ).then((imageResults) => {
  //       setContent((prev) => ({ ...prev, images: imageResults }));
  //     });
  //   }
  // };
  // const handleFileChange = (e) => {
  //   const files = e.target.files;
  //   if (files && files.length > 0) {
  //     const imagesArray = Array.from(files);
  
  //     Promise.all(
  //       imagesArray.map(async (file) => {
  //         const reader = new FileReader();
  
  //         return new Promise((resolve) => {
  //           reader.onloadend = () => {
  //             const img = new Image();
  //             img.src = reader.result;
  
  //             img.onload = () => {
  //               const width = img.width;
  //               const height = img.height;
  //               const fileSizeInMB = file.size / (1024 * 1024); // Convert size to megabytes
  
  //               // Adjust these values based on your requirements
  //               const minWidth = 200; // Minimum width
  //               const minHeight = 200; // Minimum height
  //               const maxFileSize = 5; // Maximum file size in megabytes
  
  //               if (width >= minWidth && height >= minHeight && fileSizeInMB <= maxFileSize) {
  //                 resolve(reader.result);
  //               } else {
  //                 let errorMessage = "Invalid image:";
  //                 if (width < minWidth) errorMessage += ` Minimum width is ${minWidth}px.`;
  //                 if (height < minHeight) errorMessage += ` Minimum height is ${minHeight}px.`;
  //                 if (fileSizeInMB > maxFileSize) errorMessage += ` Maximum file size is ${maxFileSize} MB.`;
  
  //                 alert(errorMessage);
  //                 resolve(null); // Skip this image
  //               }
  //             };
  //           };
  //           reader.readAsDataURL(file);
  //         });
  //       })
  //     ).then((imageResults) => {
  //       // Filter out null values (images that didn't meet the criteria)
  //       const validImages = imageResults.filter((image) => image !== null);
  
  //       setContent((prev) => ({ ...prev, images: [...prev.images, ...validImages] }));
  //     });
  //   }
  // };
