import Button from "../../button";
import Text from "../../text";
import { HiOutlineCloudDownload } from "react-icons/hi";
import { CSVLink } from "react-csv";
export default function DownloadCSV({ screen = 'tamper', data, title = "Your tamper code", }) {

  const screens = {
    tamper: {
      headers: [
        { label: "Meter-ID", key: "meterid" },
        { label: "Tamper Code", key: "tamper" },
        { label: "Date & Time", key: "timestamp" },
      ],
      skin: {
        label: 'Tamper Code',
        placeholder: 'Meter ID',
        csvName: 'tamper.csv'
      },
      singleOutput: {
        title: 'meterid',
        message: 'tamper',
      }
    },
    switch: {

      headers: [
        { label: "Meter-ID", key: "meterid" },
        { label: "Message", key: "message" },
        { label: "Date & Time", key: "timestamp" },
      ],
      skin: {
        label: 'Switch Message',
        placeholder: 'Meter ID',
        csvName: 'switch.csv'
      },
      singleOutput: {
        title: 'meterid',
        message: 'message',
      }

    },
    credit: {
      headers: [
        { label: "Meter-ID", key: "Meter_id" },
        { label: "Token", key: "Token" },
        { label: "Total Paid", key: "Total_paid" },
        { label: "Token Unit", key: "Total_unit" },
        { label: "Date & Time", key: "timestamp" },
      ],
      skin: {
        label: 'Token',
        placeholder: 'Meter ID',
        csvName: 'credit-meter.csv'
      },
      singleOutput: {
        title: 'Meter_id',
        message: 'Token',
        body: [
          { label: "Total Paid (N)", key: "Total_paid" },
          { label: "Token Unit", key: "Total_unit" },
        ]
      }



    },
    reset: {

      headers: [
        { label: "Meter-ID", key: "user" },
        { label: "Message", key: "message" },
        { label: "Auth Token", key: "auth" },

        { label: "Date & Time", key: "timestamp" },
      ],
      skin: {
        label: 'Reset-User Message',
        placeholder: 'Meter ID',
        csvName: 'reset.csv'
      },
      singleOutput: {
        title: 'user',
        message: 'message',
        body: [
          { label: "Auth Token", key: "auth" },
        ]
      },

    },

    newMeter: {
      headers: [
        { label: "Meter-ID", key: "meterid" },
        { label: "Message", key: "message" },
        { label: "User-ID", key: "userid" },
        { label: "Auth Token", key: "auth" },

      ],
      skin: {
        label: 'New Meter Message',
        placeholder: 'Meter ID',
        csvName: 'new-meter.csv'
      },
      singleOutput: {
        title: 'meterid',
        message: 'message',
        body: [
          { label: "User-ID", key: "userid" },
          { label: "Auth Token", key: "auth" }
        ]
      }
    }

  }[screen]

  const csvData = data?.map((item) => ({
    ...item,
    timestamp: new Date().toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    }),
    // Add more columns as needed
  }));

  return (
    <div className=" flex flex-col space-y-6">
      <Text
        as="h3"
        weight="bold"
        classname="text-[24px] lg:text-[30px] text-center"
      >
        {csvData && csvData.length === 1 ? title : "Download CSV"}
      </Text>
      {csvData && csvData.length > 1 && (
        <div className=" flex justify-center items-center">
          <HiOutlineCloudDownload
            size={150}
            className="p-6 rounded-[150px] bg-[#F0F0FF]"
          />
        </div>
      )}
      {csvData && csvData.length > 1 ? (
        <CSVLink
          data={csvData}
          headers={screens.headers}
          filename={screens.skin.csvName}
          className="w-full"
        >
          <Button classname="w-full">Download</Button>
        </CSVLink>
      ) : (
        <div className="flex flex-col space-y-6">
          <div className="flex flex-row items-center gap-2">
            <Text as="p" weight="bold" classname="text-center">
              {screens.skin.placeholder}
            </Text>
            <Text
              as="p"
              weight="bold"
              classname="text-center text-secondary-dark"
            >
              {csvData && csvData.length === 1 && csvData[0][screens.singleOutput.title]}
            </Text>
          </div>
          <div className="flex flex-row items-center gap-2">
            <Text as="p" weight="bold" classname="text-center">
              {screens.skin.label}
            </Text>
            <Text
              as="p"
              weight="bold"
              classname="text-center text-secondary-dark"
            >
              {csvData && csvData.length === 1 && csvData[0][screens.singleOutput.message]}
            </Text>
          </div>
          {screens.singleOutput.body && (
            <div className="flex flex-col space-y-2">
              {screens.singleOutput.body.map((item, index) => (
                <div key={index} className="flex flex-row items-center gap-2">
                  <Text as="p" weight="bold" classname="text-center">
                    {item.label}
                  </Text>
                  <Text
                    as="p"
                    weight="bold"
                    classname="text-center text-secondary-dark"
                  >
                    {csvData && csvData.length === 1 && csvData[0][item.key]}
                  </Text>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
