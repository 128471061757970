import React, { useEffect, useState } from "react";
import { FaRegBuilding } from "react-icons/fa";
import { IoPeople } from "react-icons/io5";
import { MdLocationCity } from "react-icons/md";
import Text from "../../../components/text";
import CountUp from "react-countup";

const RatingItem = ({ data }) => (
  <div className="flex flex-col items-center" data-aos="fade-up">
    <div className="rounded-full bg-[#E6E9FB] p-4 md:p-6 lg:p-4 xl:p-8 mb-4">
      {data.icon}
    </div>
    <div className="text-center">
      <Text as="h2" weight="semibold" className="text-6xl md:text-7xl lg:text-8xl xl:text-9xl text-[#E6E9FB]">
        <CountUp
          start={data.startCounting ? 0 : null}
          end={data.view}
          duration={10} // in seconds
          separator="," // optional
          decimals={0} // optional, number of decimals
        >
          {({ countUpRef }) => (
            <span ref={countUpRef} className="elementor-counter-number">
              {data.view}
            </span>
          )}
        </CountUp>
      </Text>
      <Text as="h2" className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold mt-2 text-[#E6E9FB]">
        {data.view2}
      </Text>
    </div>
  </div>
);

const Ratings = () => {
  const [startCounting, setStartCounting] = useState(false);

  useEffect(() => {
    setStartCounting(true);
  }, []);

  const dataItems = [
    { view: 7, view2: 'Cities', icon: <FaRegBuilding className="text-[#DB9800] font-bold w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-full" />, startCounting },
    { view: 82, view2: 'Estates', icon: <MdLocationCity className="text-[#DB9800] font-bold w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-full" />, startCounting },
    { view: 5481, view2: 'Residents', icon: <IoPeople className="text-[#DB9800] font-bold w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-full" />, startCounting },
  ];

  return (
    <section className="px-4 sm:px-6 lg:px-[140px] flex flex-col sm:flex-row justify-between items-center py-8 lg:py-[80px] bg-[#DB9800] overflow-hidden">
      {dataItems.map((data, index) => (
        <RatingItem key={index} data={data} />
      ))}
    </section>
  );
};

export default Ratings;
