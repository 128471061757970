import React from "react";
import { clx } from "../../utils/clx";
import Text from "../../components/text";
import { Link } from "react-router-dom";

export default function PaymentsWrapper({ classname, children }) {
  const serviceList = [
    {
      id: 1,
      title: "Periodic Charges",
      icon: "/assets/icons/periodic-user.svg",
      path: "periodic-charges",
      color: "#6C6F7F",
      btnLable: "View Insights",
    },
  ];

  const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:block", classname);
  return (
    <div className={classes}>
      <div className="md:grid md:grid-cols-3 md:gap-[40px] md:mt-[20px] items-center flex flex-col mt-[10px] gap-[20px]  ">
        {serviceList.map((item) => {
          return (
            <div
              key={item.id}
              className={`flex flex-col justify-between w-full max-w-[260px] rounded-md p-[20px] h-[260px] gap-3 
                            ${`bg-[${item.color}]`}
                            
                            `}
            >
              <div className="flex flex-col gap-[20px]">
                <div className="flex items-center justify-center bg-white mr-auto p-2 rounded-md">
                  <img src={item.icon} className="w-[32px] " alt="icon" />
                </div>
                <Text as="p" classname={`font-bold text-white text-[24px]`}>
                  {item.title}
                </Text>
              </div>
              <Link
                to={`/user/${item.path}`}
                className="text-white p-4 rounded-md text-center bg-primary-dark text-[12px] hover:bg-primary-light transition-all duration-200 ease-in-out"
              >
                {item.btnLable}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
