import { format, parseISO } from "date-fns";
import Text from "../../../../components/text";
import MeterActions from "./meter-action";

export const meter_request = [
  {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Date Created
        </Text>
      ),
      Cell: ({ value, row }) => <Text as="p">
      {format(parseISO(value), 'dd-MM-yyyy H:mm:ss')}
      </Text>,
      accessor: "createdAt",
    },
    {
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Full Name
        </Text>
      ),
      Cell: ({ row }) => (
        <Text as="p" classname='text-left text-[16px]'>
          {`${row.original.firstName} ${row.original.lastName}`}
        </Text>
      ),
      accessor: "fullName",
    },
    
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Telephone
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "phoneNumber",
    },
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Email
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "email",
    },
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Meter Type
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "provider",
    },
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Status
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "status",
    },
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Type
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "type",
    },
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
        Quantity
        </Text>
      ),
      // Cell: ({ value, row }) => <Text as="p">
      //     {value}
      // </Text>,
      accessor: "quantity",
    },
    
    {
      //     id: "_",
      Header: (
        <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
          Action
        </Text>
      ),
      Cell: MeterActions,
  
      accessor: "_id",
    },
  
  ];