import axios from 'axios'
import React from 'react'
import { useAuthUser } from 'react-auth-kit';
import { useQuery } from 'react-query'
import useUserStore from '../../../store/user/store';
import useAdminAffiliateInfo from './useAdminAffiliateInfo';
import useSelectedEnergyUser from '../../../store/admin/selected-energy-user';
import useAdminAffiliates from '../../../store/admin/affiliates';

export const useAdminCharges = ({slug}) => {
    
    const authUser = useAuthUser();
    const user = useUserStore((state) => state.user);
    const affiliate = useAdminAffiliateInfo()
    const yourAdminKey= affiliate?.apikey
    const { affiliates, selectedAffiliate } = useAdminAffiliates();

    
    // console.log(authUser().authToken)

    const {data,isLoading,isError,refetch,isFetching} = useQuery('charges',()=>{
        return axios.get(`https://api-pm-e1ea777feac4.herokuapp.com/charges/overview?type=periodic&charges_slug=${slug}`,{
            headers: {
                // Authorization: authUser().authToken,
                // "x-api-key":"GINJAR1qMl13gxU8NxCftG6H/sv4iFUU6Pkz3q"
                "x-api-key": yourAdminKey,
              },
        })
    })
    return {data,isLoading,isError,refetch,isFetching}
}



