import React from "react";
import useAffiliatesSettlementsDetails from "../../../../../hooks/apis/admin/useAffiliatesSettlementsDetails";
import { clx } from "../../../../../utils/clx";
import Text from "../../../../../components/text";
import { MdEmail } from "react-icons/md"
import Input from "../../../../../components/input"
import { FaUser } from "react-icons/fa"
import { BsBank } from "react-icons/bs";
import Radio from "../../../../../components/radio";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Button from "../../../../../components/button";
import { useAuthUser } from "react-auth-kit";
import useAdminAffiliates from "../../../../../store/admin/affiliates";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function SetDetails() {
    const {
        data: details,
        isLoading,
    } = useAffiliatesSettlementsDetails()
    const [radioState, setRadioState] = React.useState(details?.data?.settlement?.active);
    const [banks, setBanks] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [validationAttempt, setValidationAttempt] = React.useState(false);
    const [accountHolderName, setAccountHolderName] = React.useState("");
    const { selectedAffiliate } = useAdminAffiliates();
    const authUser = useAuthUser();
    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchbanks = async () => {
            try {
                const response = await axios.get(
                    "https://pm-server.herokuapp.com/api/v2.0/bank-codes"
                );
                setBanks(response.data.data);
            } catch (error) {
                
            }
        }
        fetchbanks()
    }, [])
    const fetchAccountHolderName = async (accountNumber) => {
        try {
            const response = await axios.get(
                `https://pm-server.herokuapp.com/api/v2.0/settlement?query=set-details/account-info?account_number=${accountNumber}`
            );
            setAccountHolderName(response.data.name);
        } catch (error) {
            console.error("Error fetching account holder name:", error);
            setAccountHolderName(""); // Clear name if error occurs
        }
    };
    const handleAccountNumberChange = async(e) => {
        const accountNumber = e.target.value;
        formik.handleChange(e); // Update formik values
      console.log(accountNumber)
        // Fetch account holder name
        if (accountNumber.length === 10) {
            console.log(accountNumber.length)
          const acct=  await fetchAccountHolderName(accountNumber);
          console.log(acct,"")

        }
    };

    const formValues = {
        name: details?.data?.settlement?.data?.name || "",
        email: details?.data?.settlement?.data?.email || "",
        account_number: details?.data?.settlement?.data?.account_number || "",
        bank_code: details?.data?.settlement?.data?.bank_code || "",
    };

    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required"),
        email: Yup.string().required("Email is required"),
        account_number: Yup.string().required(" Account Number is required"),
        bank_code: Yup.string().required(" Bank Code is required"),
    });


    const onSubmit = (values) => {
        setValidationAttempt(true);
        setLoading(true)
        if(!radioState){
            return toast.error("Please select an active status")
        }
        axios.post("https://pm-server.herokuapp.com/api/v2.0/settlement?query=set-details", {
            ...values,
            active: radioState,
            tag: selectedAffiliate,
        }, {
            headers: {
                Authorization: authUser().authToken,
            },
        }).then(res => {
            toast.success(res.data.message)
            navigate('/manager/configure/affiliates')
        }).catch(err => {
            toast.error(err.response.data.message)
        }).finally(() => {
            setLoading(false)
        }
        )

    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formValues,
        onSubmit,
        validationSchema,
        validateOnBlur: false,
        validateOnChange: validationAttempt,
    });

    const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex justify-center");
    return (
        <div className={classes}>
            <div className="bg-white max-w-[600px] p-8 w-full h-full flex flex-col items-center rounded-lg">

                {
                    isLoading ?
                        <div>
                            <Text as="h3" weight="semibold" classname="text-center">Loading...</Text>
                        </div>
                        :
                        <>


                            <form onSubmit={formik.handleSubmit} className="flex flex-col space-y-6 w-full">
                                <Text as="h3" weight="semibold" classname="text-center text-xl">Set Details</Text>

                                <Input
                                    type="text"
                                    value={accountHolderName || formik.values.name}
                                    name="name"
                                    iconLeft={<FaUser />}
                                    label="Name:"
                                    placeholder="Name"
                                    onchange={(e) => formik.handleChange(e)}
                                    onblur={formik.handleBlur}
                                    errorMessage={formik.errors.name}
                                />
                                <Input
                                    type="email"
                                    value={formik.values.email}
                                    defaultValue={details?.data?.settlement?.data?.email}
                                    name="email"
                                    iconLeft={<MdEmail />}
                                    label="Email:"
                                    placeholder="Email"
                                    onchange={(e) => formik.handleChange(e)}
                                    onblur={formik.handleBlur}
                                    errorMessage={formik.errors.email}
                                />
                                <Input
                                    classname="disabled:text-muted"
                                    type="text"
                                    name="account_number"
                                    placeholder="Account Number"
                                    value={formik.values.account_number}
                                    defaultValue={details?.data?.settlement?.data?.account_number}
                                    onchange={ handleAccountNumberChange}
                                    onblur={formik.handleBlur}
                                    errorMessage={formik.errors.account_number}
                                    iconLeft={<BsBank />}
                                    label="Account Number:"
                                    disabled={details?.data?.settlement?.slug === "instant-settlement"}

                                />
                                <select
                                    value={formik.values.bank_code}
                                    name="bank_code"
                                    disabled={details?.data?.settlement?.slug == "instant-settlement"}
                                    required
                                    id="bank_code"
                                    onChange={(e) => formik.handleChange(e)}
                                    onBlur={formik.handleBlur}
                                    className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
                                >
                                    <>
                                        <option value="">Select Sender Bank</option>
                                        {banks.map((bank) => (
                                            <option key={bank.id} value={bank.code} name={bank.name}>
                                                {bank.name}
                                            </option>
                                        ))}
                                    </>
                                </select>
                                <div className="flex justify-between items-center">
                                    <Text as="p" weight="medium" classname="">
                                        Active:
                                    </Text>
                                    <div className="flex flex-row space-x-6">
                                        <Radio
                                            name="confirm"
                                            label='YES'
                                            classname='text-[16px]'
                                            value="true"
                                            checked={radioState}
                                            onchange={() => setRadioState(true)}
                                            onclick={() => setRadioState(true)}
                                        />
                                        <Radio
                                            name='confirm'
                                            label='NO'
                                            classanme='text-[16px]'
                                            value="false"
                                            checked={radioState === false}
                                            onchange={() => setRadioState(false)}
                                            onclick={() => setRadioState(false)}

                                        />
                                    </div>
                                </div>
                                {/* <hr /> */}

                                <Button
                                    isLoading={loading}
                                    buttonType="submit"
                                    // onclick={() => setValidationAttempt(true)}
                                    classname="w-full"

                                >
                                    Set Details
                                </Button>
                            </form>


                        </>
                }
            </div>

        </div>
    )
}

