import React, { useCallback } from "react";
import { IoAddOutline } from "react-icons/io5";
import Button from "../../../components/button";
import Table from "../../../components/table";
import { clx } from "../../../utils/clx";
import useAdminManagerList from "../../../hooks/apis/admin/useAdminManagerList";
import { BsSearch } from "react-icons/bs";
import Input from "../../../components/input";
import ModalWrapper from "../../../components/modals/modal-wrapper";
import { restrict_managers_column } from "../../../utils/table-utils/admin/restrict-managers-table";
import Text from "../../../components/text";
import { useSelectedRows } from "../../../store/admin/configure";
import { toast } from "react-toastify";
import RestrictManagerModal from "../../../components/modals/admin/restrict-manager";
import UnRestrictManagerModal from "../../../components/modals/admin/unrestrict-manager";


export default function RestrictManager() {
    const [search, setSearch] = React.useState("");
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [modalType, setModalType] = React.useState("");
    const [filteredList, setFilteredList] = React.useState([]);
    const { selectedRows } = useSelectedRows();

    const {
        data: managerList,
        isLoading: isManagerLoading,
        isFetching: isManagerFetching,
    } = useAdminManagerList()
    const loadMeters = React.useCallback(() => {
        setFilteredList([])
        managerList?.data?.length && managerList?.data?.map((item) => {
            const { fullname, email, restriction, adminid } = item
            const modifiedRestriction = restriction === "1" ? "login" : "";
            setFilteredList((prev) => {
                return [...prev, { fullname, email, restriction: modifiedRestriction, adminid }]
            })
        })
    }, [managerList]);

    React.useEffect(() => {
        loadMeters();
    }, [loadMeters]);

    const handleModalClick = useCallback((type) => {
        if (selectedRows.length === 0)
            return toast.error("Please select a row")
        else if (selectedRows.length > 1)
            return toast.error("Please select only one row at a time")
        setModalType(type);
        setModalOpen(true);
    }, [selectedRows])

    const classes = clx(
        "w-[93%] lg:w-[90%] mx-auto lg:py-[10px] flex flex-col space-y-[10px]"
    );
    return (
        <React.Fragment>

            <div className={classes}>
                <div className='w-full h-full rounded-[20px] bg-white p-6 flex flex-col space-y-6'>
                    <div className="flex flex-row justify-between items-center">
                        <div
                            className="flex flex-row items-center space-x-2"
                        >
                            <Text
                                as="p"
                                weight="semibold"
                                classname="lg:text-[16px] text-secondary-dark cursor-pointer hover:underline"
                                onclick={() => handleModalClick("restrict")}
                            >
                                Restrict Manager
                            </Text>
                            <span>
                                |
                            </span>
                            <Text
                                as="p"
                                weight="semibold"
                                classname="lg:text-[16px] text-secondary-dark cursor-pointer hover:underline"
                                onclick={() => handleModalClick("unrestrict")}
                            >
                                Unrestrict Manager
                            </Text>


                        </div>
                        <div>

                            <Input
                                iconLeft={<BsSearch />}
                                placeholder="Search..."
                                inputClass="py-[6px] px-[15px] rounded-r-0"
                                onchange={({ target }) => setSearch(target.value)}
                            />
                        </div>
                    </div>
                    <Table
                        data={filteredList ?? []}
                        columns={restrict_managers_column}
                        isLoading={isManagerLoading || isManagerFetching}
                        search={search}

                    />
                </div>
            </div>
            <ModalWrapper
                isOpen={isModalOpen}
                handleModalClose={() => setModalOpen(false)}
            >
                {modalType === "restrict" && <RestrictManagerModal selectedRows={selectedRows} />}
                {modalType === "unrestrict" && <UnRestrictManagerModal selectedRows={selectedRows} />}
            </ModalWrapper>
        </React.Fragment>
    );
}