import React from "react";
import { useNavigate } from "react-router-dom";
import { clx } from "../utils/clx";
import { getFormattedPrice } from "../utils/getFormattedPrice";
import Text from "./text";
import CurrencyFormat from "react-currency-format";

export default function AdminCountCard({
  variant = "secondary",
  bg,
  platform,
  count,
  description,
  unit,
  price,
  onclick,
  title,
  iconBg,
  textColor,
  noPrice,
  isLoading,
  classname,
  children,
  isDecimal =true
}) {
  const classes = clx(
    "group rounded-[8px] lg:h-[110px] p-4 lg:p-6",
    bg,
    onclick && "cursor-pointer animate-pulse ring-2",
    classname
  );

  if (isLoading) {
    return (
      <div className={`${classes} flex items-center justify-center`}>
        <div className="w-[80px] h-[80px]">
          <img
            src="/assets/icons/loader2.svg"
            alt="loading..."
            className="w-full h-full object-fill"
          />
        </div>
      </div>
    );
  }
  return (
    <div className={classes} onClick={onclick}>
      <div className="flex space-x-4 items-start">
        <div
          className={`w-[32px] h-[32px] md:w-[45px] md:h-[45px] rounded-[4px] flex items-center justify-center ${iconBg}`}
        >
          <img src="/assets/icons/total-credit.png" alt="" />
        </div>
        <div className="py-0">
          <Text as="p" classname={`text-[11px] md:text-[12px] ${textColor}`}>
            {description}
          </Text>

          {count >= 0 ? (
            <div className="hidden md:flex items-center justify-between space-x-3">
              <Text
                as="h5"
                weight="bold"
                classname={`lg:text-[40px] xl:text-[40px] flex items-center ${textColor}`}
              >
                {count}
              </Text>
              {onclick && (
                <Text as="h5" weight="semibold" classname="text-secondary-dark">
                  Click to View
                </Text>
              )}
            </div>
          ) : (
            <div className="hidden pt-4 md:flex items-center space-x-3">
              {/* <Text
                as="h5"
                weight="bold"
                classname={`lg:text-[16px] xl:text-[18px] flex items-center ${textColor}`}
              >
               {
                isDecimal ?    <CurrencyFormat
                value={unit ?? 0}
                displayType="text"
                decimalScale={2}
                thousandSeparator
                fixedDecimalScale
              />
              :
                unit ?? 0
               }
                <span
                  className={`font-semibold lg:text-[16px] text-[18px] px-1 ${textColor}`}
                >
                  {platform === "Prepaid Energy" ? "kwh" : platform === 'Charges' ? 'txn' :"L"}
                </span>
              </Text> */}
              {!noPrice && price >= 0 && (
                <div
                  className={`text-black px-3  border-[#6C6F7F] ${textColor}`}
                >
                  <CurrencyFormat
                    value={price}
                    displayType="text"
                    decimalScale={2}
                    renderText={(value) => <Text as="span">₦{value}</Text>}
                    thousandSeparator
                    fixedDecimalScale
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="lg:hidden pt-4 flex flex-col items-center space-x-3">
        {/* <Text
          as="h5"
          weight="bold"
          classname={`lg:text-[16px] xl:text-[18px] flex items-center ${
            variant === "primary" || variant === "tertiary"
              ? "text-black group-hover:text-white"
              : "text-black"
          }`}
        >
          <span className="font-normal lg:text-[16px] text-[18px]">kwh</span>
        </Text> */}
        {!noPrice && (
          <div className="text-primary-dark bg-white py-[4px] px-[8px] rounded-[4px]">
            <Text as="span">₦{getFormattedPrice(price)}</Text>
          </div>
        )}
      </div>
    </div>
  );
}
