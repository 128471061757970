import React from "react";
import HomepageWrapper from "../../views/api-docs/hompage";
import ApiDocsLayout from "../../layouts/api-docs-layout";
import ComingSoon from "../../views/static/components/coming-soon";

export default function Homepage() {
  return (
    <React.Fragment>
      {/* <ApiDocsLayout>
        <HomepageWrapper />
      </ApiDocsLayout> */}
      <ComingSoon />
    </React.Fragment>
  );
}
