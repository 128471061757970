import React from 'react'
import { clx } from '../utils/clx'
import Text from './text';

export default function TransactionHistory({ referenceId, amount, unit, token, classname, children }) {
      const classes = clx(
        "w-[700px] max-w-[700px] lg:w-[100%] lg:max-w-[100%] overflow-hidden my-[5px]",
        classname
      );
      return (
        <div className={classes}>
          <div className='overflow-auto w-[100%] py-[20px] flex flex-col space-y-6'>
            <TransactionHistory.Tabs referenceId={referenceId} amount={amount} unit={unit} token={token} />
          </div>
        </div>
      );
}


export function Tabs({ referenceId, amount, unit, token, classname, children }) {
      const classes = clx(
        "flex items-center border-b-2 border-[#6C6F7F]/10 py-4",
        classname
      );
      return (
        <div className={classes}>
          <div className='w-[15%]'>
            <div className='w-[48px] h-[48px] rounded-full bg-[#FBF5E6] flex items-center justify-center'>
              <img src='/assets/icons/energy-trans-icon.png' alt='icon' />
            </div>
          </div>
          <div className='w-[28%]'>
            <Text as='span' classname='text-[14px] lg:text-[18px] text-[#6C6F7F] pr-4'>{referenceId??'626668777'}</Text>
          </div>
          <div className='w-[17%]'>
            <Text as='span' classname='text-[14px] lg:text-[18px] text-[#6C6F7F]'>₦ {amount??'5,000'}</Text>
          </div>
          <div className='w-[12%]'>
            <Text as='span' classname='text-[14px] lg:text-[18px] text-[#6C6F7F]'>{unit??"1.1"}</Text>
          </div>
          <div className='w-[28%]'>
            <Text as='span' classname='text-[14px] lg:text-[18px] text-[#6C6F7F]'>{token??'6353 6276 5333 3322'}</Text>
          </div>
        </div>
      );
}

TransactionHistory.Tabs = Tabs