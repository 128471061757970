import React from "react";
import StaticLayout from "../../layouts/static-layout";
import SingleBlog from "../../views/static/SingleBlog";


export default function SingleBlogpage() {
      return (
            <React.Fragment>
                  <StaticLayout>
                        <SingleBlog/>
                  </StaticLayout>
            </React.Fragment>
      )
}