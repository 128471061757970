import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { clx } from "../../utils/clx";
import Text from "../../components/text";
import { IoChevronBack } from "react-icons/io5";
import { useSingleAdminBlog } from "../../hooks/apis/admin/useAdminBlog";
import * as IMG from "./../../images";
import { supabase } from "./supabse";
import { FacebookShareButton, TwitterShareButton } from "react-share";

const classes = clx("w-[93%] lg:w-[90%] flex flex-col mx-auto pt-8");

const SingleBlog = () => {
  const { id } = useParams();
  const { data: single, isLoading } = useSingleAdminBlog(id);
  const navigate = useNavigate();
  const [likes, setLikes] = useState(0);

  useEffect(() => {
    const fetchLikes = async () => {
      const { data, error } = await supabase
        .from('blog_posts')
        .select('likes')
        .eq('id', id);

      if (error) {
        console.error('Error fetching likes:', error);
      } else {
        setLikes(data?.likes || 0);
      }
    };

    fetchLikes();
  }, [id]);

  const handleLike = async () => {
    // Update likes count in Supabase
    const { data, error } = await supabase
      .from('blog_posts')
      .upsert([{ id: id, likes: likes + 1 }], { onConflict: ['id'] });

    if (error) {
      console.error('Error updating likes:', error);
    } else {
      setLikes(data?.likes);
    }
  };

  if (isLoading) {
    return (
      <section className={"flex justify-center items-center h-screen"}>
        <img
          src={IMG.blogLoader}
          alt="Loading..."
          className="object-cover w-auto h-auto"
        />
      </section>
    );
  }
  if (single?.length === 0 || single === undefined) {
    return (
      <div
        className={`w-full h-screen bg-gradient-to-br from-white to-gray-100 flex items-center justify-center`}
      >
        <div className="text-center">
          <div className="w-[200px] lg:w-[250px] h-[200px] lg:h-[250px]">
            <img
              src="/assets/icons/not-found.png"
              alt="No Data found"
              className="w-full h-full object-contain"
            />
          </div>
          <Text
            weight="semibold"
            as="h2"
            classname="text-primary-dark leading-loose"
          >
            No Data Found
          </Text>

        </div>
      </div>
    );
  }
  const shareUrl = window.location.href;
  const title = single?.title || "Blog Post Title";

  return (
    <section className={classes}>
      <button
        onClick={() => navigate(-1, { replace: true })}
        className="text-[#E1AA2B] cursor-pointer focus:outline-none py-3"
      >
        <IoChevronBack className="text-[24px] font-extrabold" />
      </button>
  
      <div>
        <div className="ml-4 my-5">
          <Text as="h2" weight="bold" classname="my-3 text-lg mb-2">
            {single?.title}
          </Text>
          {
            single?.image && single?.image.length > 0  ? (
            <img
              src={Array.isArray(single.image) ? single.image[0] : single.image}
              alt={single?.title}
              className="w-full h-[400px] object-cover rounded-lg my-5"
            />
          ) : (
            <img
              src={IMG.banner}
              alt={single?.title}
              className="w-full h-[400px] object-cover rounded-lg my-5"
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: single?.content }} className="my-8" />
          
          {Array.isArray(single?.image) && single?.image?.length > 1 && (
            <img
              src={single?.image[1]}
              alt={single?.title}
              className="w-full h-[400px] object-cover rounded-lg my-5"
            />
          )}
        </div>
        
      </div>
    </section>
  )}

export default SingleBlog
// <div>
//             <p>Likes: {likes}</p>
//             <button onClick={handleLike}>Like</button>
//           </div>
//           <div>
//             <p>Share on:</p>
//             <FacebookShareButton url={shareUrl} quote={title}>
//               <button>Facebook</button>
//             </FacebookShareButton>
//             <TwitterShareButton url={shareUrl} title={title}>
//               <button>Twitter</button>
//             </TwitterShareButton>
//       </div>
