import React from "react";
import { TbLetterX } from "react-icons/tb";
import { MdCancel, MdSend } from "react-icons/md";
import Input from "../../components/input";
import Text from "../../components/text";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import useUserStore from "../../store/user/store";
import ChatContainer from "../../components/chat-container";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { toast } from "react-toastify";
import ChatSuccess from "../../components/toast/chat-success";
import { BsFillChatDotsFill } from "react-icons/bs";
import TextArea from "../../components/textarea";
const socket = new io("https://pm-server.herokuapp.com/api/v2.0/socket", {
  // autoConnect: false,
  // withCredentials: true,
});

export default function ChatPageWrapper() {
  const [isConnected, setConnected] = React.useState([]);
  const [chats, setChats] = React.useState();
  const user = useUserStore((state) => state.user);
  const authUser = useAuthUser();
  const [message, setMessage] = React.useState("");
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const scrollRef = React.useRef();

  const sendMessage = (message) => {
    if (message) {
      setMessage("");
      axios
        .post(
          "https://pm-server.herokuapp.com/api/v2.0/chat/s/sender",
          {
            userid: user?.userid,
            msg: message,
          },
          {
            headers: {
              Authorization: authUser().authToken,
            },
          }
        )
        .then((res) => {
          setIsSuccessful(true);
          toast.success(
            <ChatSuccess
              icon={<BsFillChatDotsFill className='text-green-500' />}
            >
              Message sent successfully!
            </ChatSuccess>,
            {
              icon: false,
              // autoClose: false
            }
          );
        });
    }
  };


  React.useEffect(() => {
    scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
  }, [chats]);

  React.useEffect(() => {
    setIsSuccessful(false);
    socket.connect();
    socket.on("connection");
    socket.emit("room", {
      userid: user?.userid,
    });
    socket.on("connect_error", () => {
      // console.log("Socket", "Connection not successful");
    });
    socket.on("chat", ({ chat }) => {
      setChats(chat);
    });
    return () => {
      socket.off("connect-error");
      socket.off("connection");
      socket.off("chat");
    };
  }, [isSuccessful]);
  return (
    <div className='fixed h-[100%] max-h-[100%] inset-0 z-50 bg-white lg:static w-[100%] lg:w-[90%] mx-auto'>
      <div className='flex flex-col h-[100%] lg:h-[calc(100vh-120px)] lg:mt-[20px] lg:w-[95%] mx-auto lg:border-2 lg:border-b-0 lg:border-primary-dark lg:rounded-t-[40px] overflow-hidden'>
        <div className='h-[100px] lg:h-[150px] w-full bg-primary-dark flex items-center justify-center'>
          <div className='w-[93%] lg:w-[85%] mx-auto flex items-center justify-between'>
            <div className='flex items-center space-x-3 lg:space-x-6'>
              <div className='w-[50px] h-[50px] lg:w-[70px] lg:h-[70px] p-4 rounded-full bg-white'>
                <img
                  src='/assets/images/chat-logo2.png'
                  width='100%'
                  height='100%'
                  alt=''
                />
              </div>
              <div className=''>
                <Text as='p' classname='lg:text-[28px] text-white'>
                  Ginjar Admin
                </Text>
                <Text
                  as='span'
                  classname='text-[12px] lg:text-[16px] text-white'
                >
                  Online
                </Text>
              </div>
            </div>
            <div className='lg:hidden text-white'>
              <Link
                to='/user'
                className='w-fit'
                // onClick={({ target, currentTarget }) => handleClose()}
              >
                <MdCancel className='text-[33px] text-primary-dark bg-white rounded-full' />
              </Link>
            </div>
          </div>
        </div>
        <div className='flex h-[calc(100%-100px)] lg:h-[calc(100%-150px)] flex-col w-[93%] lg:w-[85%] mx-auto pb-[20px]'>
          <div
            ref={scrollRef}
            className='flex-1 overflow-auto my-[12px] scrollbar-thin scrollbar-thumb-primary-dark scrollbar-thumb-rounded-md'
          >
            <div className='h-fit pt-[10px] flex flex-col space-y-4 lg:space-y-6 pr-3'>
              {chats?.map((chat, index) => (
                <ChatContainer key={index} mode={chat.mode} time={chat.time}>
                  {chat.body}
                </ChatContainer>
              ))}
            </div>
          </div>
          <div className='flex items-end space-x-4 lg:space-x-[30px]'>
            <TextArea
              placeholder='Enter your message here'
              inputClass='rounded-[16px] bg-[#F5F5FE]'
              onchange={(e) => setMessage(e.target.value)}
              value={message}
            />
            <div
              onClick={() => sendMessage(message)}
              className='min-w-[48px] min-h-[48px] rounded-full bg-[#F5F5FE] hover:bg-[#6C6F7F]/40 cursor-pointer flex items-center justify-center'
            >
              <MdSend className='text-[25px] text-primary-dark' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
