import React from "react";
import Select from "../../../components/select";
import useAdminAffiliates from "../../../store/admin/affiliates";
import Text from "../../../components/text";

const LocationSelect = () => {
  const { affiliates, selectedAffiliate, setSelectedAffiliate } =
    useAdminAffiliates((state) => state);

  return (
    <Select
      placeholder="Select Branch"
      classname="py-2 min-w-[150px]"
      value={selectedAffiliate}
      render={({ setDropdownOpen, setSelectedOption }) => (
        <div className="">
          {typeof affiliates === "object" &&
            ["All", "Landwey HQ", 'Flex Estate, Lekki Phase'].map((opt, i) => (
              <Text
                key={opt}
                as="p"
                classname="p-2 hover:bg-primary-dark hover:text-white cursor-pointer"
                onclick={async () => {
                  await setSelectedAffiliate(opt);
                  setDropdownOpen(false);
                }}
              >
                {opt}
              </Text>
            ))}
        </div>
      )}
    />
  );
};

export default LocationSelect;
