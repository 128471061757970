import React, { useEffect } from "react";
import { useFormik } from "formik";
import { FaUser } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { BsArrowRight } from "react-icons/bs";
import * as Yup from "yup";
import Input from "../../../components/input";
import Button from "../../../components/button";
import TextArea from "../../../components/textarea";

export default function ContactForm() {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const formValues = {
    fullname: "",
    email: "",
    message: "",
  };
  const validationSchema = Yup.object({
    fullname: Yup.string().required("Full Name is Required"),
    email: Yup.string().required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  const onSubmit = (values) => {
    // console.log(values);
    window.open(
      `https://mail.google.com/mail/?view=cm&fs=1&to=prepaidnigeria@gmail.com&su=Prepeaid%20Meter%20Enquiries&body=Hi,%20my%20name%20is%20${encodeURIComponent(
        values.fullname
      )}.%20${encodeURIComponent(values.message)}%20(${encodeURIComponent(
        values.email
      )})`,
      "_blank"
    );
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form
      onSubmit={formik.handleSubmit}
      className="p-6 lg:p-[54px] bg-primary-dark rounded-lg  lg:w-[649px]"
    >
      <div className="flex flex-col space-y-6 mb-6 w-full ">
        <Input
          iconLeft={<FaUser />}
          placeholder="Enter Full Name"
          type="text"
          name="fullname"
          value={formik.values.fullname}
          onchange={(e) => formik.handleChange(e)}
          onblur={formik.handleBlur}
          errorMessage={formik.errors.fullname}
        />
        <Input
          iconLeft={<IoMail />}
          placeholder="Enter Email"
          type="email"
          name="email"
          value={formik.values.email}
          onchange={(e) => formik.handleChange(e)}
          onblur={formik.handleBlur}
          errorMessage={formik.errors.email}
        />
        <TextArea
          placeholder="Enter Message"
          type="text"
          name="message"
          value={formik.values.message}
          onchange={(e) => formik.handleChange(e)}
          onblur={formik.handleBlur}
          errorMessage={formik.errors.message}
        />
      </div>
      <Button
        isLoading={isLoading}
        onclick={() => setValidationAttempt(true)}
        buttonType="submit"
        classname="w-full bg-secondary-dark flex flex-row text-white"
      >
        Send
      </Button>
    </form>
  );
}
