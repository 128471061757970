import React from "react";
import MayAlsoLike from "./components/may-also-like";
import Text from "../../components/text";
import {
  MdFavorite,
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import Button from "../../components/button";
import { Counter } from "./components/counter";
import Overview from "./components/item-detail-components/overview";
import Description from "./components/item-detail-components/description";
import Shipping from "./components/item-detail-components/shipping";
import Warranty from "./components/item-detail-components/warranty";
import ReturnPolicy from "./components/item-detail-components/return-policy";
import Reviews from "./components/item-detail-components/reviews";

export default function ItemDetailaPageWrapper() {
  const params = useParams();
  const [activeTab, setActiveTab] = React.useState(1);
  const navigate = useNavigate();
  const { category, id } = params;
  const [mainImageIndex, setMainImageIndex] = React.useState(0);
  const [quantity, setQuantity] = React.useState(1);
  const ratingChanged = (newRating) => {
    console.log(newRating);
    // setRateChange(newRating);
  };

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const images = [
    "/assets/images/product1.png",
    "/assets/images/product2.png",
    "/assets/images/product3.png",
    "/assets/images/product4.png",
  ];
  return (
    <React.Fragment>
      {/* header */}
      <div className="px-5 lg:py-[30px] py-5 lg:px-20 flex flex-col gap-10  bg-[#F8F8FF]">
        <div className="hidden lg:flex space-x-2">
          <Text href="/" as="p" classname="text-sm text-gray-400">
            Home
          </Text>
          <MdOutlineArrowForwardIos className="text-gray-400 w-4 h-4" />
          <Text as="p" classname="text-sm capitalize text-gray-400">
            {category}
          </Text>
          <MdOutlineArrowForwardIos className="text-gray-400 w-4 h-4" />
          <Text as="p" classname="text-sm capitalize text-gray-400">
            {id}
          </Text>
        </div>
        {/* mobile */}
        <div className="lg:hidden flex flex-col gap-2">
          <MdOutlineArrowBackIosNew
            className="h-6 w-6  text-gray-400"
            onClick={() => navigate(-1)}
          />
          <Text as="h1" weight="bold" classname="text-2xl  capitalize">
            Code Readers & Scan Tools Solar
          </Text>
        </div>

        <div className="hidden lg:flex items-center justify-between">
          <Text as="h1" weight="bold" classname="text-4xl capitalize">
            Code Readers & Scan Tools Solar
          </Text>
          {/* search input */}
        </div>
      </div>
      <div className=" w-[93%] lg:w-[90%] mt-[40px] mx-auto py-[20px] lg:py-[30px] px-5 lg:px-20  gap-10 flex flex-col items-center lg:flex-row lg:justify-between bg-[#F8F8FF]">
        <div className="flex flex-col items-center">
          {/* big image */}
          <div className="bg-white">
            <img
              src={images[mainImageIndex]}
              alt="Main Item"
              className="w-[514px] object-contain"
            />
          </div>

          {/* small image */}
          <div className="md:flex space-x-4 mt-4 hidden">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Item ${index}`}
                className={`w-20 h-20 cursor-pointer border ${
                  index === mainImageIndex
                    ? "border-primary-light"
                    : "border-gray-300"
                }`}
                onMouseEnter={() => setMainImageIndex(index)}
              />
            ))}
          </div>
        </div>

        <div className="lg:max-w-[50%] flex flex-col space-y-4 w-full">
          <Text as="h3" classname="text-[#6C6F7F]">
            APC UPS 1500VA 900W UPS Battery Backup & Surge Protector, BX1500M
            AVR, Dataline Protection - Black
          </Text>
          <Text as="p" classname="text-[#6C6F7F]">
            Product code: 4589230
          </Text>
          <Text as="p" classname="text-[#6C6F7F]">
            Brand: IOBOTECH
          </Text>
          <hr className="h-3" />
          <Text as="h3" weight="bold" classname="text-2xl lg:text-4xl">
            N 27,000
          </Text>
          <hr className="h-3" />
          <div className="flex items-center space-x-6">
            <Text as="h3" weight="bold" classname="text-lg text-[#6C6F7F]">
              Quantity:
            </Text>
            <Counter
              quantity={quantity}
              onIncrement={handleIncrement}
              onDecrement={handleDecrement}
            />
          </div>
          <hr className="h-3" />
          <div className="flex space-x-2 items-center">
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={16}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
            />
            <Text as="p" classname="text-sm text-[#6C6F7F]">
              10 Reviews
            </Text>
          </div>
          <div className="flex space-x-6">
            <Button
              onclick={() => {
                navigate("/cart");
              }}
              classname="w-full lg:w-[50%]"
            >
              Buy Now
            </Button>
            <div className="hidden lg:flex items-center justify-center rounded-full bg-[#6C6F7F] p-3">
              <MdFavorite className="w-7 h-7 text-[#E6E6E9] " />
            </div>
          </div>
          <Text as="p" classname="hidden lg:block text-[#6C6F7F]">
            Share With Friends
          </Text>
        </div>
      </div>

      <div className="w-[93%] lg:w-[90%] mt-[40px] mx-auto  py-[20px] lg:py-[30px] px-5 lg:px-20 gap-5 lg:gap-10 flex flex-col bg-[#F8F8FF] p-4">
        <Tabs activeTab={activeTab} setTab={setActiveTab} />
        <div>
          {activeTab === 1 && <Overview />}
          {activeTab === 2 && <Description />}
          {activeTab === 3 && <Shipping />}
          {activeTab === 4 && <Warranty />}
          {activeTab === 5 && <ReturnPolicy />}
          {activeTab === 6 && <Reviews />}
        </div>
      </div>

      <div className="py-[50px] lg:py-[140px] px-5 lg:px-20 flex flex-col gap-16 lg:gap-56 items-center">
        <MayAlsoLike title="Products you may also like" />
      </div>
    </React.Fragment>
  );
}

export const Tabs = ({ activeTab, setTab }) => {
  return (
    <div className="flex gap-6 flex-wrap lg:gap-[50px]">
      {[
        "Overview",
        "Description",
        "Shipping",
        "Warranty",
        "Return Policy",
        "Reviews",
      ].map((tab, index) => (
        <Text
          key={index}
          as="p"
          weight="semibold"
          classname={`lg:text-[20px] py-1 lg:py-2 tracking-wider ${
            activeTab === index + 1
              ? "text-primary-light font-bold"
              : "text-[#6C6F7F]"
          }`}
          onclick={() => setTab(index + 1)}
        >
          {tab}
        </Text>
      ))}
    </div>
  );
};
