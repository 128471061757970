import Text from "../../../../components/text";

export default function Description() {
  return (
    <div>
      <div className="flex border-2 space-x-4 mb-2">
        <Text as="p" classname="text-gray-600 p-2 w-[50%] lg:w-[10%]   border-r-2">
          Brand
        </Text>
        <Text as="p" classname="text-gray-600 p-2">
          ELM 327
        </Text>
      </div>
      <Text as="p" classname="leading-7 text-gray-600 tracking-wide">
        Obd II Obd2 Car Diagnostic Scanner is an amazing device Specifically and
        Specially designed for Car Owners to Scan & Find the exact fault of
        their Cars with Complete Fault Fix Explanation displayed on any Android
        Phone Or Tablet.Don't let your mechanic rip you off your hard earned
        money or even mess up your Car. This Obd2 EML327 Car Auto Scanner tool
        is an easy to use professional device, Specially designed for
        Non-Technical Persons (Car Owners) it requires "zero" technical
        training.It is an easy tool to use "Do It Yourself" 100% offline device
        with complete Fault Code Explanations and Fault Fix Instructions in
        simple English unlike the vanaucular you hear from the mechanic You can
        also confirm if your car was properly fixed or not by your mechanic just
        by using this little deviceAll you need do is to download an app called
        "Torque" on google playstore to your android or iphone and connect it
        with the device, to find out what's wrong with your carObd 2 Car Scanner
        is a portable (small) Easy to Use Android Car Scanner that will
        communicate with your Android Phone or Tablet to inform you on the
        Status, Health and Fault issues of your Car. It is the first and only
        100% offline Bluetooth diagnostic device in the Nigerian Market with
        complete Fault Code Explanation and Fault Fix Instructions without going
        onlineThe Obd2 ELM 327 Auto Car Scanner is so Easy to Use, it comes with
        a DVD for easy USE With Super Mini size.The newly developed wireless
        scan tool. It supports all OBD-II protocols.It adopts the latest version
        2.1Support Bluetooth connection, no Batteries, Cables, or Switches.The
        Wireless (Bluetooth) 5~10 meterRetrieve Fault Codes (DTCs) and clear
        Check Engine lights.The device lets you perform the following
        operations:Read diagnostic trouble codes, both generic and
        manufacturer-specific , and display their meaning (over 3000 generic
        code definitions in the database).Clear trouble codes and turn off the
        MIL ("Check Engine" light)Set the maximum number of responses to obtain,
        Remove spaces from ECU responses (to speed data transmission)Store a
        unique identifier in EEPROMSet the CAN mask and filter with one
        command.Easily switch between variable and fixed CAN message lengthsSend
        CAN remote frames (RTRs)Display current sensor data.
      </Text>
      <div className="mt-4">
        <Text as="h3" classname="text-gray-600">
          FEATURES
        </Text>

        <ul className="list-disc text-gray-600 tracking-wide">
          <li>
            <Text as="p">
              View your car engine data on your ANDROID PHONE live all by
              yourself
            </Text>
          </li>
          <li>
            <Text as="p">
              RETRIEVE Fault Codes (DTCs) and CLEAR Check Engine lights
            </Text>
          </li>
          <li>
            <Text as="p">
              View Detailed Fault Code Explanation with Technical Advice from
              your car Manufacture on how best to work/fix on the Faults on your
              Phone (internet required)• See what your car is doing in
              real-time, get OBD fault codes, car performance, sensor data and
              more
            </Text>
          </li>
          <li>
            <Text as="p">Find problems in your car before they happen</Text>
          </li>
        </ul>

        <Text as="p" classname="text-gray-600">
          All vehicles from 1996 has an OBD 2 port. it is usually somewhere
          below the car steering.Works on Cars, SUV's, Light Trucks and Buses,
          vehicles made by Toyota, Nissan, Honda, Mazda, Ford, Volkswagen,
          GM/Vauxhall/Opel, Chrysler, Mercedes, Volkswagen, Audi, Citroen,
          Peugeot, Skoda, Kia, Lexus, Subaru, Renault, Mitsubishi, Hyundai, BMW,
          Seat, Dodge, Jeep, Hummer, Infinity, Land Rover, Range Rover, Kia,
          Mini, Pontiac, Volvo, Saturn, Scion, Acura, Buick, Mini, Chevrolet,
          Suzuki, Lincoln, Isuzu, Saab, Oldsmobile, and many more vehicle makes,
          European, US, Far East, etc.
        </Text>
      </div>
    </div>
  );
}
