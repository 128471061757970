import React from "react";
import Text from "../../../../components/text";
import { clx } from "../../../../utils/clx";

export default function GridBalanceCard({
  description,
  users,
  bg,
  iconBg,
  classname,
}) {
  const classes = clx(
    "rounded-[8px] h-[100px] flex items-center space-x-4 p-4",
    bg,
    classname
  );
  return (
    <div className={classes}>
      <div className='w-[60px] h-[60px] rounded-[8px] bg-[#865D00]'></div>
      <div>
        <Text as='p' weight='semibold' classname='text-[14px]'>
          Total Units
        </Text>
        <Text as='h3' weight='semibold' classname='text-[24px]'>
          04
        </Text>
      </div>
    </div>
  );
}
