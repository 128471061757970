import React, { useCallback, useEffect } from "react";

import { clx } from "../../../../utils/clx";
import Text from "../../../../components/text";

import Table from "../../../../components/table";
import useDemoRequst from "../../../../hooks/apis/admin/useDemo";
import { Demo_request } from "./demo-table";

const DemoRequest = () => {
  const {
    isLoading,
    data: requestedMeter,
    isError,
    isFetching,
    refetch,
    refetchOnMount,
  } = useDemoRequst("book-demo");
  console.log(requestedMeter?.data?.data);
  useCallback(async () => {
    await refetchOnMount();
  }, [refetchOnMount]);

  const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");

  return (
    <section className={classes}>
      <div className="max-w-full h-full rounded-[8px] bg-white p-6 flex flex-col space-y-6 relative">
        <Text
          as="p"
          weight="semibold"
          className="lg:text-[16px] text-secondary-dark cursor-pointer hover:underline"
        >
          List of Demo Request
        </Text>
        <Table
          search={[]}
          data={requestedMeter?.data?.data ?? []}
          columns={Demo_request}
          isLoading={isLoading || isFetching}
        />
      </div>
    </section>
  );
};

export default DemoRequest;
