import React, { useCallback, useMemo } from "react";
import ModalWrapper from "../../../../components/modals/modal-wrapper";
import GrantBranch from "../../../../components/modals/admin/grant-branch";
import RemoveBranch from "../../../../components/modals/admin/remove-branch";
import HideFeatures from "../../../../components/modals/admin/hide-features";
import UnHideFeatures from "../../../../components/modals/admin/un-hide-features";

export default function AdminManagerOtherActions({ value, row }) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");

  const handleButtonClick = useCallback(
    (type) => {
      if (type === "" || type === undefined) return setModalType("");
      setModalType(type);
      setModalOpen(true);

    }, [
    modalType
  ])

  return (
    <React.Fragment>
      {row.original.role === "tenant" ? (

        <select
          value={modalType}
          onChange={
            (e) => {
              handleButtonClick(e.target.value)
            }
          }
          className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
        >
          <option value="">
            Select Action
          </option>
          <option value="grant-branch">Grant branch access</option>
          <option value="remove-branch">Remove branch access</option>
          <option value="hide">Update features </option>
          <option value="remove-feature">Extra Features
          </option>
        </select>) : (
        null
      )}


      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => {
          setModalOpen(false)
          setModalType("")
        }}
      >
        {modalType === "grant-branch" && <GrantBranch data={row.original} />}
        {modalType === "remove-branch" && <RemoveBranch data={row.original} />}
        {modalType === "hide" && <HideFeatures data={row.original} />}
        {modalType === "remove-feature" && <UnHideFeatures data={row.original} />}
      </ModalWrapper>
    </React.Fragment>
  );
}
