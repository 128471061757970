
import Text from "../../../components/text";
import AccessRecordsAction from "./components/access-records";

export const access_records_data = Array.from({ length: 5 }).map((data, i) => ({
    _id: i + 1,
    name: "Oloko Opeyemi ",
    email: "samuellasisi@gmail.com",
    phone: `09030332503`,
    date_time: `18-04-2023 / 10:30pm`,
    type: "Sign In",
    access_code: `567483903`,
}));

export const access_records_column = [
    // {
    //     id: "1",
    //     Header: (
    //         <Text as='p' classname='text-left text-[16px] text-primary-light'>
    //             S/N
    //         </Text>
    //     ),
    //     Cell: ({ value, row }) => <Text as="p">
    //         {value}
    //     </Text>,
    //     accessor: "_id",
    // },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-primary-light'>
                Name
            </Text>
        ),
        Cell: ({ value, row }) => <Text as="p" classname='text-left text-[16px]'>
            {value}
        </Text>,
        accessor: "fullname",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-primary-light'>
                Email
            </Text>
        ),
        // Cell: ({ value, row }) => <Text as="p">
        //     {value}
        // </Text>,
        accessor: "email",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-primary-light'>
                P.Number
            </Text>
        ),
        //     Cell: ({ value, row }) => <Text as="p">
        //     {value}
        // </Text>,
        accessor: "phonenumber",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-primary-light'>
                Entry Date/Time
            </Text>
        ),
        accessor: "checkin.createdAt",
        Cell: ({ value, row }) => <Text as='p' classname="text-left text-[16px] text-primary-light">
            {value ? new Date(value).toLocaleString("en-US") : "Not Signed In"}
        </Text>
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-primary-light'>
                Exit Date/Time
            </Text>
        ),
        accessor: "checkout.createdAt",
        Cell: ({ value, row }) => <Text as='p' classname="text-left text-[16px] text-primary-light">
            {value ? new Date(value).toLocaleString("en-US") : "Not Signed Out"}
        </Text>
    },

    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-primary-light'>
                A.Type
            </Text>
        ),
        accessor: "sign_in",
        Cell: ({ value, row }) => <Text as='p' classname={`
        ${value === "Signed In"
                ? "text-left text-[16px] text-green-500"
                : value === "Signed Out"
                    ? "text-left text-[16px] text-red-500"
                    : "text-left text-[16px] text-orange-500"
            }`}
        >
            {value}
        </Text>
    },

    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-primary-light '>
                Access Code
            </Text>
        ),
        accessor: "access_code",
        Cell: ({ value, row }) => <Text as='p' classname="text-left text-[16px] text-secondary-dark">
            {value}
        </Text>
    },
    {

        id: "1",
        Header: (
            <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
                Actions
            </Text>
        ),
        accessor: "_id",
        Cell: AccessRecordsAction,

    },


];
