import CopyToClipboard from "react-copy-to-clipboard";
import Button from "../../../../components/button";
import Text from "../../../../components/text";
import CopyText from "../../../../components/toast/copy-text";
import { toast } from "react-toastify";
import { BiCopy } from "react-icons/bi";

export default function AccessCode({ onButtonClick,code,formik }) {

    
    return (
        <div className="flex flex-col justify-between mx-auto w-full space-y-6 max-w-[500px]">
            <div className="bg-[#6C6F7F] rounded-lg h-full py-[50px]">
                <Text as="h3" classname="text-white text-center">
                    Hello {formik.values.fullname}, your access code is
                </Text>

                <div className="flex flex-row gap-4 items-center justify-center max-w-[300px] p-2 mt-[20px] rounded-lg bg-[#F0F0FF] mx-auto">
                    <Text as="h3" classname="text-black text-center text-[24px]">
                        {code}
                    </Text>
                    <CopyToClipboard
                        text={code}
                        onCopy={() =>
                            toast.success(
                                <CopyText>Token copied successfully!</CopyText>
                            )
                        }
                    >
                        <BiCopy className="block text-black cursor-pointer" />
                    </CopyToClipboard>

                </div>
                {
                    formik.values.email
                    ?? (<Text as="p" classname="text-white text-center py-[20px]">
                    Your access code has been sent to your email {formik.values.email}
                </Text>)
                }
            </div>
            <Button>
                Gotten, Thank!
            </Button>
        </div>
    )
}

