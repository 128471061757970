import React from "react";
import {
  FaTwitter,
  FaInstagramSquare,
  FaFacebookF,
  FaLinkedin,
} from "react-icons/fa";
import { clx } from "../utils/clx";
import Text from "./text";
import { Social, footerData } from "../data";
import { StoreDownlaod } from "../views/static/hompage";

export default function StaticFooter({ classname, children }) {
  const classes = clx(
    "w-[93%] lg:w-[90%] mx-auto flex flex-col-reverse gap-6  lg:flex-row py-[80px]",
    classname
  );

  
  return (
    <div className="w-full bg-primary-dark">
      <div className={classes}>
        <div className="flex-1 flex flex-col">
          <div>
            <img
              src="/assets/images/logo2.png"
              alt=""
              className="w-[150px] lg:w-[250px]"
            />
          </div>
          <Text as="p" classname="text-white lg:text-[20px] py-[25px]">
          Central Vending for your communities, simplifying bills Collection, Improving Community Security With Our Smart Gate and Visitor Management
          </Text>
          <div className="flex items-center space-x-[30px]">
          {
            Social.map((socail,index)=>(
              <a href={socail.path} target="_blank" rel="noreferrer" key={index}>{socail.icon}</a>
            ))
           }
          </div>
        </div>
        <div className="lg:flex-1 flex lg:justify-center">
          <div>
            <Text as="h6" weight="semibold" classname="text-white text-[18px]">
              Company
            </Text>
            <ul className="pt-[25px] flex flex-col space-y-[20px]">
              {footerData.map((link, index) => (
                <Text key={index} href={link.path} as="p" weight="normal" classname="text-white">
                  {link.page}
                </Text>
              ))}
            </ul>
          </div>
        </div>
        <div>
        <StoreDownlaod bgColor={true}/>
        </div>
        {/* <div className="flex-1 flex lg:justify-center">
          <div>
            <Text as="h6" weight="semibold" classname="text-white text-[18px]">
              Company
            </Text>
            <div className="pt-[25px] flex flex-col space-y-[20px] w-[200px]">
              <Text as="p" weight="normal" classname="text-white">
                29, Chief Israel Udo street Therra Annex Sangotedo, Ajah
              </Text>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}



