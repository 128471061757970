import React from "react";
import { IoArrowDown } from "react-icons/io5";
import { clx } from "../utils/clx";
import Text from "./text";
import { RiArrowDownSLine } from "react-icons/ri";

export default function Select({
  classname,
  label,
  placeholder,
  iconLeft,
  render = ({ setDropdownOpen, setSelectedOption }) => <p>Hellp</p>,
  value,
}) {
  const [isDropdownopen, setDropdownOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const classes = clx(
    `flex items-center justify-between select-none space-x-3 border-2 rounded-[8px] py-[15px] px-[20px] bg-[#E6E6E9] dark:bg-[#1B1D26] cursor-pointer relative`,
    classname
  );
  return (
    <div>
      {label && (
        <label htmlFor="">
          <Text as="span" weight="medium" classname="text-[15px] lg:text-base">
            {label}
          </Text>
        </label>
      )}
      <div
        className={classes}
        onClick={() => setDropdownOpen((status) => !status)}
      >
        <div
          className={`${
            isDropdownopen ? "block" : "hidden"
          } absolute border-2 inset-0 top-[105%] w-full left-0 h-fit rounded-[8px] bg-white py-2 flex flex-col space-y-1 max-h-[250px] overflow-y-auto scrollbar-thin scrollbar-thumb-primary-dark`}
        >
          {render({ setDropdownOpen, setSelectedOption })}
        </div>

        <div>
          <div>{iconLeft}</div>
          <Text as="span" weight="medium" classname="text-[15px] lg:text-base">
            {value ? value : selectedOption ?? placeholder}
          </Text>
        </div>
        <div>
          <RiArrowDownSLine className="text-[24px]" />
        </div>
      </div>
    </div>
  );
}
