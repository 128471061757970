import axios from "axios";

import { useQuery } from "react-query";

export default function useGetBillers(category) {
  const fetchBillers = () => {
    return axios.get(
      `https://api-pm-e1ea777feac4.herokuapp.com/billers?category=${category}`
    );
  };
  const { data, isLoading, isFetching } = useQuery({
    queryKey: "get-biller",
    queryFn: () => fetchBillers(),
  });

  return { data: data?.data, isLoading, isFetching };
}
