import { useNavigate } from "react-router-dom";
import Button from "../../../components/button";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";

export const OrderSummary = ({ items, classname }) => {
  const classes = clx(
    "bg-[#F8F8F8] flex flex-col gap-4 rounded-xl py-7 px-5 lg:max-w-[300px] w-full",
    classname
  );
  const navigate = useNavigate();
  return (
    <div className={classes}>
      <Text as="h3" weight="bold" classname="text-xl">
        Order Summary
      </Text>
      <hr />
      <div className="flex justify-between">
        <Text as="p" classname="text-[#6C6F7F]">
          Total item
        </Text>
        <Text as="p" classname="text-[#6C6F7F]">
          2
        </Text>
      </div>
      <hr />
      <div className="flex justify-between">
        <Text as="p" classname="text-[#6C6F7F]">
          Subtotal
        </Text>
        <Text as="p" classname="text-[#6C6F7F]">
          N14,392
        </Text>
      </div>
      <hr />
      <Button
        onclick={() => {
          navigate("/checkout");
        }}
        classname="mt-20 hidden lg:block"
      >
        Checkout
      </Button>
    </div>
  );
};
