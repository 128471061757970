// src/ServiceChargesTable.js
import React, { useState } from 'react';

const ServiceChargesTable = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showRestricted, setShowRestricted] = useState(true);
  const [data, setData] = useState([
    { id: '001', name: 'John Doe', apartment: 'A1', location: 'New York', restricted: true, restrictedArea: '10m²', unrestrictedArea: '90m²' },
    { id: '002', name: 'Jane Smith', apartment: 'B2', location: 'Los Angeles', restricted: false, restrictedArea: '20m²', unrestrictedArea: '80m²' },
    { id: '003', name: 'John Doe', apartment: 'A1', location: 'New York', restricted: true, restrictedArea: '10m²', unrestrictedArea: '90m²' },
    { id: '004', name: 'Jane Smith', apartment: 'B2', location: 'Los Angeles', restricted: false, restrictedArea: '20m²', unrestrictedArea: '80m²' },
      { id: '005', name: 'John Doe', apartment: 'A1', location: 'New York', restricted: true, restrictedArea: '10m²', unrestrictedArea: '90m²' },
    { id: '006', name: 'Jane Smith', apartment: 'B2', location: 'Los Angeles', restricted: false, restrictedArea: '20m²', unrestrictedArea: '80m²' },
    // Add more data as needed
  ]);

  const filteredData = data.filter(item =>
    item.id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const displayedData = showRestricted
    ? filteredData.filter(item => item.restricted)
    : filteredData.filter(item => !item.restricted);

  const toggleRestriction = (id) => {
    setData(prevData =>
      prevData.map(item =>
        item.id === id ? { ...item, restricted: !item.restricted } : item
      )
    );
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Restrict Users</h2>
        <input
          type="text"
          placeholder="Search by Meter ID"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500"
        />
      </div>
      <div className="flex justify-center mb-4">
        <button
          onClick={() => setShowRestricted(true)}
          className={`px-4 py-2 rounded-l-md ${showRestricted ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-600'} transition-colors duration-200`}
        >
          Restricted Users
        </button>
        <button
          onClick={() => setShowRestricted(false)}
          className={`px-4 py-2 rounded-r-md ${!showRestricted ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-600'} transition-colors duration-200`}
        >
          Unrestricted Users
        </button>
      </div>
      <div className="overflow-x-auto bg-white shadow-md rounded-lg mx-4">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Apartment</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Restricted Area</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unrestricted Area</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {displayedData.map((item) => (
              <tr key={item.id} className="hover:bg-gray-100 transition-colors duration-200">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.id}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.apartment}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.location}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.restrictedArea}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.unrestrictedArea}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <button
                    onClick={() => toggleRestriction(item.id)}
                    className={`px-4 py-2 rounded-md ${item.restricted ? 'bg-red-600 text-white' : 'bg-green-600 text-white'} transition-colors duration-200`}
                  >
                    {item.restricted ? 'Unrestrict' : 'Restrict'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServiceChargesTable;
