import React from "react";
import Text from "../../../components/text";
import { clx } from "../../clx";
import { getFormattedPrice } from "../../getFormattedPrice";

export function TableIcon({ classname, children }) {
  const classes = clx(
    "w-[40px] lg:w-[60px] h-[40px] lg:h-[60px] rounded-full bg-[#F5F5FE] flex items-center justify-center",
    children
  );
  return (
    <div className=''>
      <div className='w-[48px] h-[48px] rounded-full bg-[#FBF5E6] flex items-center justify-center'>
        <img src='/assets/icons/energy-trans-icon.png' alt='icon' />
      </div>
    </div>
  );
}

export function ReferenceID({ value }) {
  return (
    <Text
      as='p'
      weight='semibold'
      classname='text-left text-[13px] lg:text-base text-[#2C3048]'
    >
      {value}
    </Text>
  );
}

export function Amount({ value }) {
  return (
    <Text
      as='p'
      weight='normal'
      classname='text-left text-[13px] lg:text-base text-[#2C3048]'
    >
      ₦ {getFormattedPrice(value)}
    </Text>
  );
}

export function Unit({ value }) {
  return (
    <Text
      as='p'
      weight='normal'
      classname={`text-left text-[13px] lg:text-base`}
    >
      {value}
    </Text>
  );
}

export function Token({ value }) {
  return (
    <Text
      as='p'
      weight='normal'
      classname='text-left text-[13px] lg:text-base text-[#2C3048]'
    >
      {value}
    </Text>
  );
}
