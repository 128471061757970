import React from "react";
import Text from "../../text";
import Button from "../../button";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
export default function RemoveAdminManager({ onButtonClick, data }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const naviate = useNavigate();
  const authUser = useAuthUser();
  const onSubmit = () => {
    setIsLoading(true);
    const adminid = data?.adminid;
    axios.put("https://pm-server.herokuapp.com/api/v2.0/manager/actions?query=remove", {
      adminid: adminid
    }, {
      headers: {
        Authorization: authUser().authToken,
      },
    }).then(res => {
      setIsLoading(false)
      naviate('/manager/configure/admin-management')
      toast.success(res.data.message)
    }).catch(err => {
      // console.log(err)
      setIsLoading(false)
      toast.error(err.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  };

  return (
    <div>
      <Text
        as="h3"
        weight="bold"
        classname="text-[24px] lg:text-[30px] text-center"
      >
        Remove Manager
      </Text>
      <div className="my-6 flex flex-col space-y-6">
        <Text as="p" weight="medium" classname=" text-secondary-dark">
          Are you sure you want to remove {data?.fullname}?
        </Text>
      </div>
      <Button onclick={onSubmit} isLoading={isLoading} classname="w-full">
        Remove
      </Button>
    </div>

  );
}
