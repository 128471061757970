import React, { useCallback } from "react";
import Button from "../../../../components/button";
import { RiDeleteBinLine } from "react-icons/ri";
import ModalWrapper from "../../../../components/modals/modal-wrapper";
import AffiliateInsightsActionModal from "../../../../components/modals/admin/affiilate-insight-action-modal";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import useAdminAffiliates from "../../../../store/admin/affiliates";
import { toast } from "react-toastify";
import { useNavigate} from "react-router-dom";
import { useAffiliateProducts } from "../../../../store/admin/configure";


export default function AffiliiateServicesInsightsActions({ value, row }) {
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [validationAttempt, setValidationAttempt] = React.useState(false);

    const [modalType, setModalType] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [radioState, setRadioState] = React.useState("yes");
    const [productData, setProductData] = React.useState("");
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const { selectedAffiliate } = useAdminAffiliates();
    const { affiliateProducts } = useAffiliateProducts()

    const handleButtonClick = useCallback((type) => {
        setModalType(type);
        setModalOpen(true);
    }, []);
    const handleAction = (query) => {
        setIsLoading(true);
        axios.post(`https://pm-server.herokuapp.com/api/v2.0/affiliate/service?query=${query}`,
            query === "remove-product-data" ? {
                tag: selectedAffiliate,
                data_slug: row.original?.slug.toLowerCase(),
                service_slug: affiliateProducts.service_slug,
                product_slug: affiliateProducts.product_slug
            } : {
                tag: selectedAffiliate,
                data: {
                    active: radioState === "yes" ? true : false,
                    name: productData.name,
                    slug: productData.slug.toLowerCase(),
                    value: productData.data
                },
                service_slug: affiliateProducts.service_slug,
                product_slug: affiliateProducts.product_slug
            }, {
            headers: {
                Authorization: authUser().authToken,
            }
        }
        ).then((res) => {
            setIsLoading(false);
            setModalOpen(false);
            toast.success(res.data.message)
            navigate("/manager/configure/affiliates/service")
        }).catch((err) => {
            setIsLoading(false);
            toast.error(err.response.data.message)
        }).finally(() => {
            setIsLoading(false);
        })

    }

    // console.log(row.original);
    return (
        <React.Fragment>
            <div className="flex items-center space-x-6">
                <Button
                    variant="text"
                    classname=" w-fit text-secondary-dark"
                    onclick={() => handleButtonClick("edit")}
                >
                    Edit
                </Button>
                <Button
                    variant="text"
                    iconLeft={<RiDeleteBinLine />}
                    classname=" w-fit  text-secondary-dark"
                    onclick={() => handleButtonClick("remove")}
                />
                  <Button
                    variant="text"
                    classname=" w-fit  text-secondary-dark"
                    onclick={() => {}}
                >
                    Modify For User
                </Button>
            </div>

            <ModalWrapper
                isOpen={isModalOpen}
                handleModalClose={() => setModalOpen(false)}
            >
                {modalType === "edit" &&
                    <AffiliateInsightsActionModal
                        type="edit"
                        data={row.original}
                        label="Enter Value"
                        productData={productData}
                        setProductData={setProductData}
                        btnText="Edit"
                        isLoading={isLoading}
                        title="Edit Value"
                        onButtonClick={() => handleAction("edit-product-data")}
                        active={radioState}
                        setActive={setRadioState}
                        validationAttempt={validationAttempt}
                        setValidationAttempt={setValidationAttempt}
                        

                    />}
                {modalType === "remove" &&
                    <AffiliateInsightsActionModal
                        type="remove"
                        data={row.original}
                        label="Are you sure you want to remove this?"
                        onButtonClick={() => handleAction("remove-product-data")}
                        isLoading={isLoading}
                        title="Remove Value"
                        btnText="Remove"

                    />
                }
            </ModalWrapper>
        </React.Fragment>
    );
}
