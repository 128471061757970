import React from "react";
import Button from "../../../../components/button";
import Text from "../../../../components/text";
import useBuyElectricityData from "../../../../store/static/buy-electricity";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuthUser } from "react-auth-kit";

export default function MeterDetails() {
  const [isLoading, setIsLoading] = React.useState(false);
  const authUser = useAuthUser();
  const { discoInfo, currentStep, setCurrentStep, setUserInfo } =
    useBuyElectricityData();

  const handleClick = () => {
    setIsLoading(true);
    const biller = discoInfo?.data?.biller;
    axios
      .post(
        `https://api-pm-e1ea777feac4.herokuapp.com/metering/inject-disco-meter`,
        {
          meterid: discoInfo?.meterid,
          biller: { ...biller },
        },
        {
          headers: {
            Authorization: authUser().authToken,
          },
        }
      )
      .then((res) => {
        setUserInfo(res.data.data);
        toast.success(res.data.message);
        setCurrentStep(currentStep + 1);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        // console.log(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-col h-full mx-auto w-full space-y-6 ">
      <Text as="p" classname="text-[16px] text-center">
        Confirm Meter Details
      </Text>
      <div className="flex flex-col p-4 space-y-2 rounded-md bg-[#e9e9f8]">
        <Text as="p" classname="text-[16px] ">
          Meter Number: {discoInfo?.meterid}
        </Text>
        <Text as="p" classname="text-[16px] ">
          Meter Name: {discoInfo?.data?.name}
        </Text>
        <Text as="p" classname="text-[16px]">
          Provider: {discoInfo?.data?.biller?.biller_name}
        </Text>
      </div>

      <Button
        classname="mt-14"
        type="secondary"
        buttonType="button"
        variant="contained"
        onclick={handleClick}
        isLoading={isLoading}
      >
        Confirmed
      </Button>
    </div>
  );
}
