import React from "react";

export default function getDate(date, { format, seperator }) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  switch (format) {
    case "dd/mm/yyyy":
      return `${day}/${month}/${year}`;
    case "dd/mm/yy":
      return `${day}/${month}/${year.toString().slice(2)}`;
    case "mm/yyyy":
      return `${month}/${year}`;
    case "mm/yy":
      return `${month}/${year.toString().slice(2)}`;
    case "yyyy":
      return `${year}`;
    case "dd-mm-yyyy":
      return `${day}-${month}-${year}`;
    case "mm-yyyy":
      return `${month}-${year}`;
    case "yyyy/mm/dd":
      return `${year}/${month}/${day}`;
    case "mm/dd":
      return `${month}/${day}`;
    case "yyyy-mm-dd":
      return `${year}-${month}-${day}`;
    case "yyyy-mm":
      return `${year}-${month}`;
    case "mm-dd":
      return `${month}-${day}`;
    default:
      return "no date match";
  }
}
