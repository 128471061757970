import React from 'react';
import Button from '../../../../../components/button';
import ModalWrapper from '../../../../../components/modals/modal-wrapper';
import { IoAddOutline } from 'react-icons/io5';
import AddOverdraft from '../../../../../components/modals/admin/user-charges/add-overdraft';
import { GrUpdate } from 'react-icons/gr';
import UpdateOverdraft from '../../../../../components/modals/admin/user-charges/update-overdraft';


export default function OverDraftActions({ value, row }) {
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [modalType, setModalType] = React.useState("");

    const handleButtonClick = (type) => {
        setModalType(type);
        setModalOpen(true);
    };

    // console.log(row.original);
    return (
        <React.Fragment>
            <div className="flex items-center space-x-6">
                {/* <FiLock color="gray" /> */}
                <Button
                    variant="text"
                    iconLeft={<IoAddOutline />}
                    classname=" w-fit  text-secondary-dark"
                    onclick={() => handleButtonClick("add")}
                />
                <Button
                    variant="text"
                    iconLeft={<GrUpdate />}
                    classname=" w-fit  text-secondary-dark"
                    onclick={() => handleButtonClick("remove")}
                />
            </div>

            <ModalWrapper
                isOpen={isModalOpen}
                handleModalClose={() => setModalOpen(false)}
            >
                {modalType === "add" && <AddOverdraft data={row.original} />}
                {modalType === "remove" && <UpdateOverdraft data={row.original} />}
            </ModalWrapper>
        </React.Fragment>
    );
}