import React from 'react'
import RegisteredResident from './registered-resident'
import TotalResident from './total-resident'
import TotalStaff from './total-staff'

function index() {
  return (
    <div className="space-y-4 p-4">
     <RegisteredResident/>
     <TotalResident/>
     <TotalStaff/>
    </div>
  )
}

export default index
