import React from "react";

export default function useChart({
  isLoading,
  isFetching,
  dependency,
  chartId,
  type,
  width,
  height,
  yLabel,
  colors,
  categories,
  strokeColors,
  data,
}) {
  const options = React.useMemo(()=>({
    chart: {
      id: chartId,
      width: {width}
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: yLabel??"kwh",
        style: {
          color: "#9799A5",
          fontSize: 12,
          fontWeight: 600,
        },
      },
    },
    fill: {
      colors: colors,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: type === "area" ? 1 : 0,
      colors: strokeColors,
    },
  }), [categories, width] );
  const series = React.useMemo(()=>([
    {
      name: "consumption",
      data: data??[],
    },
  ]), [data]);
  const [otherConfig] = React.useState({
    type,
    // width,
      // width: '50%',
    height,
  });

  return { options, series, otherConfig };
}
