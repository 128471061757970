import axios from "axios";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthUser, useSignOut } from "react-auth-kit";
import useUserStore from "../../../store/user/store";
import { useQuery } from "react-query";

export default function useUserInfo() {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const authUser = useAuthUser();
  const setUser = useUserStore((state) => state.setUser);
  const setRefetchUser = useUserStore((state) => state.setRefetchUser);
  const {
    isLoading,
    data: user,
    isError,
    refetch,
    isFetching,
  } = useQuery("UserInfo", () =>
    axios
      .get("https://api-pm-e1ea777feac4.herokuapp.com/user/info", {
        headers: {
          Authorization: authUser().authToken,
        },
      })
      .catch((err) => {
        if (
          err.response.data.message ===
          "Session timeout, Please login again.!!!"
        ) {
          signOut();
        }
      })
  );

  setUser(user?.data.data);
  setRefetchUser(refetch);

  return { UserInfo: user?.data, isLoading, isError, isFetching, refetch };
}
