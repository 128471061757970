import Text from "../../../../components/text";
import {
  Amount,
  DateTime,
  ReferenceID,
  Status,
  TableIcon,
} from "../wallet-transaction-history-utils";

export const walletTransactionHistoryColumns = [
  {
    id: "_",
    Header: () => null,
    Cell: TableIcon,
    accessor: "",
  },
  {
    id: "reference",
    Header: (
      <Text
        as='p'
        classname='text-left text-[16px] lg:text-[18px] text-[#2C3048]'
      >
        Reference Id
      </Text>
    ),
    Cell: ReferenceID,
    accessor: "refr",
  },
  {
    id: "amount",
    Header: (
      <Text
        as='p'
        classname='text-left text-[16px] lg:text-[18px] text-[#2C3048]'
      >
        Amount
      </Text>
    ),
    Cell: Amount,
    accessor: "amount",
  },
  {
    id: "status",
    Header: (
      <Text
        as='p'
        classname='text-left text-[16px] lg:text-[18px] text-[#2C3048]'
      >
        Status
      </Text>
    ),
    Cell: Status,
    accessor: "status",
  },
  {
    id: "date",
    Header: (
      <Text
        as='p'
        classname='text-[16px] lg:text-[18px] text-left text-[#2C3048]'
      >
        Date & Time
      </Text>
    ),
    Cell: DateTime,
    accessor: "createdAt",
  },
];
