import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import Button from "../../components/button";
import Input from "../../components/input";
import OTP from "../../components/otp";
import Text from "../../components/text";
import AuthLayout from "../../layouts/auth-layout";
import { clx } from "../../utils/clx";

export default function NewPassPageWrapper() {
  return (
    <AuthLayout pageTitle='New Password' usePageNavigation>
      <div className='w-full'>
        <div className='w-full h-full lg:flex lg:space-x-[30px]'>
          <div className='flex-1 h-full hidden lg:flex flex-col justify-center'>
            <Text as='p' classname='font-[600] text-[64px] text-primary-dark'>
              Set new password
            </Text>
            <Text
              as='p'
              weight='medium'
              classname='text-[24px] text-primary-dark'
            >
              Your new password must be different from the previously used
              password
            </Text>
          </div>
          <div className='flex-1 flex justify-end items-center'>
            <div className='lg:bg-[#F8F8FF] lg:border-[1px] rounded-[16px] w-full lg:w-[550px] py-[20px] lg:py-[50px] px-[0px] lg:px-[70px]'>
              <NewPassPageWrapper.Form />
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export function Form({}) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);

  const formValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "password must be at least 8 characters")
      .required("Please enter your new password"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password Doesn't Match")
      .required("Please enter your new password"),
  });

  const onSubmit = (values) => {
    setValidationAttempt(false);
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex flex-col space-y-6'>
        <div className='flex flex-col space-y-6 mb-[30px]'>
          <Input
            type='password'
            label='Create Password'
            iconLeft={<FaLock className='text-[#9799A5]' />}
            placeholder='Create New Password'
            name='password'
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.password}
            value={formik.values.password}
          />
          <Input
            type='password'
            label='Confirm Password'
            iconLeft={<FaLock className='text-[#9799A5]' />}
            placeholder='Confirm Password'
            name='confirmPassword'
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.confirmPassword}
            value={formik.values.confirmPassword}
          />
        </div>
        <Button
          buttonType='submit'
          classname='w-full'
          onclick={() => setValidationAttempt(true)}
        >
          Sign in
        </Button>
      </div>
    </form>
  );
}

NewPassPageWrapper.Form = Form;
