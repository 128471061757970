import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import MeterDetails from "./steps/meter-details";
import MeterId from "./steps/meter-id";
import UserInfo from "./steps/user-info";
import Tabs from "./tabs";
import useBuyElectricityData from "../../../store/static/buy-electricity";

export default function BuyElectricityWrapper({ classname }) {
  const steps = ["Meter Id", "Meter details", "User info"];
  const { meterId } = useParams();
  const navigate = useNavigate();
  const { electricityInfo, currentStep, setCurrentStep } =
    useBuyElectricityData();

  React.useEffect(() => {
    if (!meterId || !Object.entries(electricityInfo).length) {
      navigate("/");
    }
  });

  const displaySteps = (step) => {
    switch (step) {
      case 1:
        return <MeterId />;
      case 2:
        return <MeterDetails />;
      case 3:
        return <UserInfo />;
      default:
        return null;
    }
  };
  return (
    <div className="relative z-0">
      <div className="flex flex-col gap-6 rounded-lg mx-auto  lg:p-[50px] md:px-[100px] w-full lg:max-w-[900px]">
        <div>
          <Tabs steps={steps} currentStep={currentStep} />
        </div>
        {displaySteps(currentStep, setCurrentStep)}
      </div>
    </div>
  );
}
