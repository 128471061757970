import CopyToClipboard from "react-copy-to-clipboard";
import CopyText from "../../../../../../components/toast/copy-text";
import { BiCopy } from "react-icons/bi";
import Text from "../../../../../../components/text";
import Button from "../../../../../../components/button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function AccessCode(
    { accessCode, visitorDetails }
) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/manager/configure");
    };
    return (

        <div className="flex flex-col justify-between mx-auto w-full space-y-6 max-w-[500px]">
            <div className="bg-[#6C6F7F] rounded-lg h-full py-[50px]">
                <Text as="h3" classname="text-white text-center">
                    Hello {visitorDetails.fullname}, your access code is
                </Text>

                <div className="flex flex-row gap-4 items-center justify-center max-w-[300px] p-2 mt-[20px] rounded-lg bg-[#F0F0FF] mx-auto">
                    <Text as="h3" classname="text-black text-center text-[24px]">
                        {accessCode}
                    </Text>
                    <CopyToClipboard
                        text={accessCode}
                        onCopy={() =>
                            toast.success(
                                <CopyText>Token copied successfully!</CopyText>
                            )
                        }
                    >
                        <BiCopy className="block text-black cursor-pointer" />
                    </CopyToClipboard>

                </div>
                <Text as="p" classname="text-white text-center py-[20px]">
                    Your access code has been sent to your email {visitorDetails.email}
                </Text>
            </div>
            <Button
                onclick={handleClick}
            >
                Gotten, Thank!
            </Button>
        </div>
    )
}




{/* <CopyToClipboard
text={token}
onCopy={() =>
  toast.success(
    <CopyText>Token copied successfully!</CopyText>
  )
}
>
<BiCopy className="block text-black cursor-pointer" />
</CopyToClipboard> */}