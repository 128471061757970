import React from "react";
import { clx } from "../../../utils/clx";
import Text from "../../../components/text";
import { affiliateClinet } from "../../../data";
import { DownloadApp } from "./DownloadApp";

const classes = clx(
  "px-3 lg:px-[140px] grid grid-cols-1 md:grid-cols-2 py-4 lg:py-[0px] overflow-hidden"
);

const Community = () => {
  return (
    <section className={classes} data-aos="fade-up">
      <div>
            <DownloadApp />
            <img
              src="/assets/images/hero-img.png"
              alt="hero"
              className="w-[329px] md:w-full hidden md:block mt-10"
            />
      </div>
      <div className="grid-span-2 mt-10">
        <Text
          as="h2"
          weight="semibold"
          classname="text-4xl lg:5xl 2xl:text-6xl mb-3 p-2"
        >
          Our Clients
        </Text>
        <div className="grid grid-cols-3 gap-x-8 gap-y-12">
          {affiliateClinet.map((client) => (
            <ListClients key={client.id} client={client} />
          ))}
        </div>
      </div>
    </section>
  );
};

const ListClients = ({ client }) => {
  return (
    <div className="flex items-center gap-1 p-2 ">
     
      <img 
      src={client.img} 
      alt="Client Logo"
      className="h-8 w-auto max-h-16 max-w-full"
      style={{ objectFit: "contain" }}
      />
    </div>
  );
};

export default Community;
