import React from "react";
import StaticLayout from "../../layouts/static-layout";
import ScheduleDemo from "../../views/static/Schedule-demo";

export default function DemoPage() {
  return <React.Fragment>
    <StaticLayout>
      <ScheduleDemo />
    </StaticLayout>
  </React.Fragment>
}
