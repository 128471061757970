import React from "react";
import { clx } from "../utils/clx";
import Text from "./text";

export default function Switch({ classname, children }) {
  const [isActive, setActive] = React.useState();
  const [isButtonClicked, setButtonClicked] = React.useState(false);

  const handleToggle = () => {
    setButtonClicked(true);
    if (isActive) {
      setActive(false);
    } else {
      setActive(true);
    }
  };

  React.useEffect(() => {
    const currentTheme = window.localStorage.getItem("theme");
    if (!isButtonClicked && currentTheme === "dark") {
      setActive(true);
    }
    // setActive(window.localStorage.getItem('theme')==='dark')

    if (isActive) {
      window.localStorage.setItem("theme", "dark");
    } else {
      window.localStorage.setItem("theme", "light");
    }
  }, [isActive, isButtonClicked]);
  const classes = clx(
    "w-[48px] h-[25px] rounded-full bg-[#E5E5E5] relative cursor-pointer",
    classname
  );
  return (
    <div className={classes} onClick={handleToggle}>
      <div
        className={`absolute top-[50%] translate-y-[-50%] w-[20px] h-[20px] rounded-full ${
          isActive ? "right-[3px] bg-primary-dark" : "left-[3px] bg-white"
        }`}
      />
    </div>
  );
}

export function MeterSwitch({
  isActive,
  toolTip,
  label,
  labelClasses,
  isMeterOn,
  toggleActive,
  toggleMeterOn,
  classname,
  children,
}) {
  // const [isActive, setActive] = React.useState();
  React.useEffect(() => {}, [isMeterOn, isActive]);
  const classes = clx(
    "w-[35px] h-[16px] rounded-full bg-[#A7B5DA] relative cursor-pointer",
    classname
  );
  return (
    <div className="flex items-center space-x-2" title={toolTip}>
      <div className={classes} onClick={toggleMeterOn || toggleActive}>
        <div
          className={`absolute top-[50%] translate-y-[-50%] w-[12px] h-[12px] rounded-full ${
            isMeterOn || isActive
              ? "right-[3px] bg-white"
              : "left-[3px] bg-black"
          }`}
        />
      </div>
      <Text as="span" classname={`text-[#E6E6E9] text-[12px] ${labelClasses}`}>
        {label ? label : isMeterOn ? "ON" : "OFF"}
      </Text>
    </div>
  );
}
