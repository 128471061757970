import React from "react";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useQuery } from "react-query";

export default function useUserCharges({ key, type }) {
  const authUser = useAuthUser();

  const { isLoading, data, isError, refetch } = useQuery(
    key,
    () =>
      axios.get(
        `https://api-pm-e1ea777feac4.herokuapp.com/charges?type=${type}`,
        {
          headers: {
            Authorization: authUser().authToken,
          },
        }
      )
  );
  return { data: data?.data, isLoading, isError, refetch };
}
