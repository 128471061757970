import React from "react";
import Button from "../../../../components/button";
import Text from "../../../../components/text";
import Input from "../../../../components/input";
import useGetBillers from "../../../../hooks/apis/useGetBillers";
import axios from "axios";
import { toast } from "react-toastify";
import useBuyElectricityData from "../../../../store/static/buy-electricity";

export default function MeterId() {
  const [provider, setProvider] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [meterid, setMeterid] = React.useState("");
  const [type, setType] = React.useState("");
  const { discoInfo, setDiscoInfo, currentStep, setCurrentStep } =
    useBuyElectricityData((state) => state);

  const { data: billers, isFetching: isBillersFetching } =
    useGetBillers("electricity");

  const onSubmit = (e) => {
    e.preventDefault();
    if (!meterid) return setErrorMessage("Meter number is required");
    const biller_code = billers?.data.find(({ name }) => name === provider);
    setIsLoading(true);
    axios
      .post("https://api-pm-e1ea777feac4.herokuapp.com/verify-disco-meter", {
        meterid: meterid,
        biller_code: biller_code.id,
        type,
      })
      .then((response) => {
        const data = response.data.data;
        setDiscoInfo({ ...discoInfo, data, meterid });
        toast.success(response.data.message);
        setCurrentStep(currentStep + 1);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        // console.log(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
        setMeterid("");
        setProvider("");
        setType("");
      });
  };

  return (
    <form onSubmit={onSubmit} className="flex flex-col justify-between w-full">
      <div className="flex flex-col space-y-2 mb-2">
        <Text as="p" classname="text-sm md:text-[16px]">
          Service Provider
        </Text>
        <select
          value={provider}
          disabled={isLoading}
          onChange={(e) => setProvider(e.target.value)}
          className="border-none
         bg-gray-200 outline-none text-sm md:text-[16px]
         rounded-[6px] py-[15px] px-[15px]"
          required
        >
          {isBillersFetching ? (
            <option value="">Loading billers...</option>
          ) : (
            <>
              <option value="">Choose a Provider</option>
              {billers?.data?.map((biller) => {
                return (
                  <option key={biller.id} value={biller.name}>
                    {biller.name}
                  </option>
                );
              })}
            </>
          )}
        </select>
      </div>

      <div className="flex flex-col space-y-2">
        <Text as="p" classname="text-sm md:text-[16px]">
          Package
        </Text>
        <select
          disabled={isLoading}
          value={type}
          onChange={(e) => setType(e.target.value)}
          className="border-none
         bg-gray-200 outline-none  text-sm md:text-[16px]
         rounded-[6px] py-[15px] px-[15px]"
          required
        >
          <option value="">Choose a Package</option>
          <option value="prepaid">PREPAID</option>
          <option value="postpaid">POSTPAID</option>
        </select>
        <Input
          placeholder="Meter number"
          label="Meter Number"
          value={meterid}
          onchange={(e) => setMeterid(e.target.value)}
          errorMessage={errorMessage}
          classname="text-sm md:text-[16px]"
        />
      </div>

      <Button
        classname="lg:mt-14 mt-6"
        type="secondary"
        variant="contained"
        buttonType="submit"
        isLoading={isLoading}
      >
        Next
      </Button>
    </form>
  );
}
