import React from "react";
import Button from "../../../../components/button";
import Text from "../../../../components/text";
import axios from "axios";
import useGetBillers from "../../../../hooks/apis/useGetBillers";
import useBuyElectricityData from "../../../../store/static/buy-electricity";
import { toast } from "react-toastify";

export default function MeterId({ onButtonClick }) {
  const [provider, setProvider] = React.useState("");
  const [type, setType] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { electricityInfo, setDiscoInfo, currentStep, setCurrentStep } =
    useBuyElectricityData((state) => state);

  const {
    data: billers,
    isFetching: isBillersFetching,
    isLoading: isBillersLoading,
  } = useGetBillers("electricity");

  // console.log(billers)

  const onSubmit = (e) => {
    e.preventDefault();
    if (!provider && !type) {
      return toast.error("please select all feilds");
    }
    const biller_code = billers?.data.find(({ name }) => name === provider);
    setIsLoading(true);
    axios
      .post("https://api-pm-e1ea777feac4.herokuapp.com/verify-disco-meter", {
        meterid: electricityInfo.meterid,
        biller_code: biller_code.id,
        type,
      })
      .then((response) => {
        setDiscoInfo(response.data.data);
        toast.success(response.data.message);
        setCurrentStep(currentStep + 1);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-col p-5 md:p-20 z-10 mb-14 justify-between mx-auto w-full rounded-lg bg-[#F8F8FF] space-y-8 md:space-y-16">
      <form onSubmit={onSubmit} className="flex flex-col space-y-6">
        <Text as="p" classname="text-[16px]">
          Service Provider
        </Text>
        <select
          value={provider}
          disabled={isLoading}
          onChange={(e) => setProvider(e.target.value)}
          className="border-none
         bg-gray-200 outline-none 
         rounded-[6px] py-[15px] px-[15px]"
          required
        >
          {isBillersFetching ? (
            <option value="">Loading billers...</option>
          ) : (
            <>
              <option value="">Choose a Provider</option>
              {billers?.data?.map((biller) => {
                return (
                  <option key={biller.id} value={biller.name}>
                    {biller.name}
                  </option>
                );
              })}
            </>
          )}
        </select>
        <div className="flex flex-col space-y-2">
          <Text as="p" classname="text-[16px]">
            Package
          </Text>
          <select
            disabled={isLoading}
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="border-none
         bg-gray-200 outline-none 
         rounded-[6px] py-[15px] px-[15px]"
            required
          >
            <option value="">Choose a Package</option>
            <option value="prepaid">PREPAID</option>
            <option value="postpaid">POSTPAID</option>
          </select>
        </div>
        <Button classname="mt-14 " buttonType="submit" isLoading={isLoading}>
          Verify
        </Button>
      </form>
    </div>
  );
}
