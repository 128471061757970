import React from "react";
import Text from "../../text";
import Button from "../../button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Radio from "../../radio";
import Input from "../../input";
import { RxValue } from 'react-icons/rx';
import { AiOutlineNumber } from "react-icons/ai";
import { BiRename } from "react-icons/bi";
import { useAuthUser } from "react-auth-kit";
import { useAffiliateProducts } from "../../../store/admin/configure";
import axios from "axios";
import useAdminAffiliates from "../../../store/admin/affiliates";

export default function AffiliateInsightsActionModal({ type, data, onButtonClick, label, title, btnText, isLoading, setProductData, productData, active, setActive, validationAttempt, setValidationAttempt
}) {
    // console.log(data);
    const [inputType, setInputType] = React.useState("text");
    const [loading, setLoading] = React.useState(false);
    const [description, setDescription] = React.useState("")
    const { selectedAffiliate } = useAdminAffiliates();
    const { affiliateProducts } = useAffiliateProducts()
    const authUser = useAuthUser();
    const [services, setServices] = React.useState([])
    const [slug, setSlug] = React.useState("");

    const fetchSlugs = React.useCallback(() => {
        setLoading(true)
        if (type === "add") {
            axios.post(`https://pm-server.herokuapp.com/api/v2.0/affiliate/service?query=get-data-slugs`, {
                tag: selectedAffiliate,
                service_slug: affiliateProducts?.service_slug,
                product_slug: affiliateProducts?.product_slug
            }, {
                headers: {
                    Authorization: authUser().authToken,

                }
            }).then((res) => {
                setLoading(false)
                setServices(res.data.data)
            }).catch((err) => {
                console.log(err.response.data)
                setLoading(false)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [type])

    React.useEffect(() => {
        fetchSlugs()
    }, [fetchSlugs])

    const formValues = {
        name: type === "edit" ? data?.name : "",
        data: type === "edit" ? data?.value : "",
        // slug: type === "edit" ? data?.slug : "",
    };
    const onSubmit = (values) => {
        setProductData({
            ...values,
            slug: type === "edit" ? data?.slug : slug,
        })
        if (productData) {
            onButtonClick()
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('Name is required'),
        data: inputType === "text" ? Yup.string().trim().required('Value is required') : Yup.number().required('Value is required'),
        // slug: Yup.string().trim().required('Slug is required').matches(/^[^\s]+$/, 'Slug should not contain spaces'),
    });

    const formik = useFormik({
        initialValues: formValues,
        onSubmit,
        validationSchema,
        validateOnBlur: true,
        validateOnChange: validationAttempt,
    });

    return (

        <div>
            <Text as="h3" weight="bold" classname="text-[24px] lg:text-[30px] text-left">
                {title}
            </Text>
            {type === "remove" ? (
                <div className="my-6 flex flex-col space-y-6">
                    <Text as="p" weight="medium" classname=" text-secondary-dark">
                        Are you sure you want to remove {data?.name}?
                    </Text>
                    <Button onclick={onButtonClick} isLoading={isLoading} classname="w-full">
                        {btnText}
                    </Button>
                </div>
            ) : (
                <>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="my-2 flex flex-col space-y-2">
                            <div>
                                <Text as="h3" weight="semi-bold" classname="text-[18px] text-left">
                                    Value Type:
                                </Text>
                                <div className="flex flex-row space-x-6">
                                    <Radio
                                        name="input-type"
                                        label="Text"
                                        classname="text-[16px]"
                                        value="text"
                                        checked={inputType === "text"}
                                        onchange={({ target }) => setInputType(target.value)}
                                        onclick={({ target }) => setInputType(target.value)}
                                    />
                                    <Radio
                                        name="input-type"
                                        label="Number"
                                        classname="text-[16px]"
                                        value="number"
                                        checked={inputType === "number"}
                                        onchange={({ target }) => setInputType(target.value)}
                                        onclick={({ target }) => setInputType(target.value)}
                                    />
                                </div>
                            </div>
                            <Input
                                iconLeft={<BiRename />}
                                label="Name:"
                                placeholder="Enter text value:"
                                type="text"
                                name="name"
                                value={formik.values.name}
                                onchange={formik.handleChange}
                                onblur={formik.handleBlur}
                                errorMessage={formik.errors.name}
                            />
                            {inputType === "text" ? (
                                <Input
                                    iconLeft={<RxValue />}
                                    label="Value:"
                                    placeholder="Enter text value:"
                                    type="text"
                                    name="data"
                                    value={formik.values.data}
                                    onchange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                    errorMessage={formik.errors.data}
                                />
                            ) : (
                                <Input
                                    iconLeft={<AiOutlineNumber />}
                                    label="Value:"
                                    placeholder="Enter Number value:"
                                    type="number"
                                    name="data"
                                    value={formik.values.data}
                                    onchange={formik.handleChange}
                                    onblur={formik.handleBlur}
                                    errorMessage={formik.errors.data}
                                />
                            )}

                            {type === "add" &&
                                <>
                                    <div>
                                        <select name="" id=""
                                            value={slug}
                                            onChange={(e) => {
                                                setSlug(e.target.value)
                                                const desc = services?.findIndex(({ slug }) => slug === e.target.value)
                                                setDescription((services[desc]?.["desc"]))
                                            }}
                                            required
                                            className="w-full h-[40px] bg-[#E6E6E9] border-none rounded-md capitalize outline-none"

                                        >
                                            {loading ? (
                                                <option value="">Loading slugs...</option>

                                            ) : (

                                                <>
                                                    <option value="">Select slugs...</option>
                                                    {services?.map((service, index) => (
                                                        <option key={index} value={service.slug}>{service.slug}</option>
                                                    ))}
                                                </>
                                            )
                                            }
                                        </select>
                                        {description && <Text as="p" classname="max-w-[400px]">
                                            {description}
                                        </Text>}

                                    </div>
                                </>
                            }
                            <div>
                                <Text as="h3" weight="semi-bold" classname="text-[18px] text-left">
                                    Active:
                                </Text>
                                <div className="flex flex-row space-x-6">
                                    <Radio
                                        name="filter"
                                        label="Yes"
                                        classname="text-[16px]"
                                        value="yes"
                                        checked={active === "yes"}
                                        onchange={({ target }) => setActive(target.value)}
                                        onclick={({ target }) => setActive(target.value)}
                                    />
                                    <Radio
                                        name="filter"
                                        label="No"
                                        classname="text-[16px]"
                                        value="no"
                                        checked={active === "no"}
                                        onchange={({ target }) => setActive(target.value)}
                                        onclick={({ target }) => setActive(target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <Button buttonType="submit" isLoading={isLoading}
                            onclick={() => setValidationAttempt(false)}
                            classname="w-full">
                            {btnText}
                        </Button>
                    </form>
                </>
            )}
        </div>
    );
}
