import React from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import AdminCountCard from "../../../components/admin-count-card";
import Button from "../../../components/button";
import Table from "../../../components/table";
import Text from "../../../components/text";
import { clx } from "../../../utils/clx";
import {
  energy_transactions_column,
  energy_transactions_data,
} from "../../../utils/table-utils/admin/energy-transactions-table";
import { GetAdminConsumptionData } from "../../../api-calls/admin/getAdminEnergyConsumptionData";
import useAdminVendInfo from "../../../hooks/apis/admin/useAdminVendInfo";
import Input from "../../../components/input";
import { BsSearch } from "react-icons/bs";
import { CSVDownload, CSVLink } from "react-csv";
import { EnergyCSVHeader } from "./download-config/energy-header";
import { FiDownload } from "react-icons/fi";

export default function PrepaidEnergyWrapper() {
  const [isBalanceHidden, setBalanceHidden] = React.useState(false);
  const {
    availableBalance,
    totalConsumption,
    totalCredit,
    totalUsers,
    currentMonthVending,
    todayVending,
    cost,
    isFetching,
    isLoading,
  } = GetAdminConsumptionData({ query: "realtime", prepaid: "energy" });
  const navigate = useNavigate();
  const {
    data: EnergyVendInfo,
    isLoading: isVendInfoLoading,
    isFetching: isVendInfoFetching,
  } = useAdminVendInfo({ prepaid: "energy" });
  const [search, setSearch] = React.useState("");

  const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
  return (
    <div className={classes}>
      {/* <div className='w-[70%]'>
        <Text as='p' classname='text-[20px]'>
          Wallet Balance
        </Text>
        <div className='pt-[15px] flex items-center justify-between'>
          <div className='flex items-center space-x-4'>
            <Text
              as='h5'
              classname='text-center  text-[28px] lg:text-[56px] font-bold'
            >
              {isBalanceHidden ? "*** *** ***" : "N500,000"}
            </Text>
            {isBalanceHidden ? (
              <AiFillEyeInvisible
                className='text-[#9799A5] text-[24px] cursor-pointer'
                onClick={() => setBalanceHidden((state) => !state)}
              />
            ) : (
              <AiFillEye
                className='text-[#9799A5] text-[24px] cursor-pointer'
                onClick={() => setBalanceHidden((state) => !state)}
              />
            )}
          </div>
          <Button classname='px-[80px]'>Fund Wallet</Button>
        </div>
      </div> */}
      <div className="grid grid-cols-3 gap-4 my-[40px]">
        <AdminCountCard
          description="Total Customers"
          count={totalUsers ?? 0}
          bg="bg-white"
          iconBg="bg-[#E6E6E9]/30"
          classname="w-full"
          onclick={() => navigate("customers")}
          isLoading={isLoading || isFetching}
        />
        <AdminCountCard
          description={`Total Recharge for ${new Date().toLocaleString("en-us", {
            month: "long",
          })}`}
          bg="bg-[#E6E6E9]"
          iconBg="bg-[#9799A54D]/30"
          classname="w-full"
          platform="Prepaid Energy"
          unit={Number(currentMonthVending?.kwh).toFixed(2)}
          price={currentMonthVending?.naira}
          isLoading={isLoading || isFetching}
        />
        <AdminCountCard
          description="Total Recharge Today"
          bg="bg-[#E6E6E9]"
          iconBg="bg-[#9799A54D]/30"
          classname="w-full"
          platform="Prepaid Energy"
          unit={Number(todayVending?.kwh ?? 0).toFixed(2)}
          price={todayVending?.naira}
          isLoading={isLoading || isFetching}
        />
        <AdminCountCard
          description="Total Credit"
          bg="bg-[#E6E6E9]"
          iconBg="bg-[#9799A54D]/30"
          classname="w-full"
          platform="Prepaid Energy"
          unit={Number(totalCredit?.unit).toFixed(2)}
          price={totalCredit?.price}
          isLoading={isLoading || isFetching}
        />
        <AdminCountCard
          description="Available Balance"
          bg="bg-[#E6E6E9]"
          iconBg="bg-[#9799A54D]/30"
          classname="w-full"
          platform="Prepaid Energy"
          unit={Number(availableBalance?.unit).toFixed(2)}
          price={availableBalance?.price}
          isLoading={isLoading || isFetching}
        />{" "}
        <AdminCountCard
          description="Total Consumption"
          bg="bg-[#E6E6E9]"
          iconBg="bg-[#9799A54D]/30"
          classname="w-full"
          platform="Prepaid Energy"
          unit={Number(totalConsumption?.unit).toFixed(2)}
          price={totalConsumption?.price}
          isLoading={isLoading || isFetching}
        />
      </div>

      <div className="my-[40px]">
        <Text
          as="span"
          classname="text-[16px] lg:text-[23px] py-[20px] font-semibold text-[#6C6F7F]"
        >
          Transactions History
        </Text>

        <div className="flex justify-end items-center space-x-6 py-2">
          <div className="flex items-center">
            <Input
              iconLeft={<BsSearch />}
              placeholder="Search By Meter ID"
              variant="white"
              inputClass="py-[6px] px-[15px] rounded-r-0"
              onchange={({ target }) => setSearch(target.value)}
            />
            {/* <div className="h-full w-[50px] border-2 py-[4px] rounded-[6px]">
              ee
            </div> */}
          </div>
          <CSVLink
            data={EnergyVendInfo?.data ?? []}
            headers={EnergyCSVHeader}
            filename="Energy-Consumption.CSV"
          >
            <Button iconLeft={<FiDownload />} variant="none">
              <Text as="p" weight="semibold">
                Download
              </Text>
            </Button>
          </CSVLink>
        </div>

        <div className="w-full my-[40px] mt-[10px] bg-white rounded-[16px] p-6">
          <Table
            search={search}
            data={EnergyVendInfo?.data ?? [{}]}
            columns={energy_transactions_column}
            isLoading={isVendInfoFetching || isVendInfoFetching}
          />
        </div>
      </div>
    </div>
  );
}
