import React from "react";
import Button from "../../../../components/button";
import Input from "../../../../components/input";
import * as Yup from "yup";
import Text from "../../../../components/text";

export default function VisitorsDetails({ onButtonClick,formik }) {
    
    const handleClick = async () => {
        // Perform form validation here if needed
        const VisitorsDetailSchema = Yup.object({
          fullname: Yup.string()
          .required('Please enter your fullname')
          .matches(/^[a-zA-Z]+ [a-zA-Z]+$/, 'Fullname must contain only alphabets and have at least one space')
          .min(5, 'Fullname length must be at least 5 characters long'), 
          gender: Yup.string().required("Gender is required"),
        });
      
        // Use formik.values instead of formik.value
        const match = await VisitorsDetailSchema.isValid(formik.values, { abortEarly: false });
      
        console.log(match);
      
        if (!match) {
          return;
        } else {
          console.log('submit');
          console.log(formik.values);
          // Call the onButtonClick callback to move to the next step
          onButtonClick();
        }
      };



    return (
        <div className="flex flex-col mx-auto w-full space-y-6 max-w-[500px]">
            <Input placeholder="Enter fullname"
            name="fullname"
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.touched.fullname && formik.errors.fullname}
            value={formik.values.fullname}
            
            />
            <Input placeholder="Enter email" 
            name="email"
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.touched.email && formik.errors.email}
            value={formik.values.email}
            
             
            />
            <Input placeholder="Enter phone" 
            name="phonenumber"
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.touched.phonenumber && formik.errors.phonenumber}
            
            value={formik.values.phonenumber}
            />

            <select
                name="gender"
                id=""
                className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px] my-6 w-full"
                // required
                // onChange={(e)=>console.log(e.target.value)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={formik.touched.gender && formik.errors.gender}
            
            // value={formik.values.gender}
            >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
            </select>
            {formik.errors.gender && (
                <Text 
                  as="span"
                  weight="medium"
                  classname="text-[15px] lg:text-base text-red-600"
                >
                  {formik.errors.gender}
                </Text>)
            }
            <Button 
            disabled={
              formik.errors.fullname ||
              formik.errors.email ||
              formik.errors.phonenumber ||
              formik.errors.gender
                ? true
                : false
            }
            onclick={handleClick}>Continue</Button>
            
            </div>
            );
        }
        // !formik.isValid || formik.isSubmitting
        // disabled={Object.keys(formik.errors).length !== 0} 
        
        // <Button disabled={!formik.isValid} onclick={handleClick}>Continue</Button>
// <Button onclick={handleClick}>Continue</Button>

//     fullname: string;
//   email?: string;
//   maximum: number|null;
//   expiresin: Date|null;
//   category: string;
//   gender: string;
//   phonenumber?: string;
    //!