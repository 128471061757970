import React, { useCallback, useEffect } from "react";
import Table from "../../../components/table";
import { clear_tamper_column, clear_tamper_data } from "../../../utils/table-utils/admin/clear-tamper-table";
import { clx } from "../../../utils/clx";
import Input from "../../../components/input";
import Text from "../../../components/text";
import ModalWrapper from "../../../components/modals/modal-wrapper";
import ClearTamperModal from "../../../components/modals/admin/clear-tamper";
import DownloadCSV from "../../../components/modals/admin/download-csv";
import { BsSearch } from "react-icons/bs";
import useAdminUserList from "../../../hooks/apis/admin/useAdminUserList";
import { useSelectedRows } from "../../../store/admin/configure";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const ClearTamper = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [download, setDownload] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [selectedType, setSelectedType] = React.useState("");
  const [filteredList, setFilteredList] = React.useState([]);
  const [radioState, setRadioState] = React.useState("yes");
  const [loading, setLoading] = React.useState(false)
  const [csvData, setCsvData] = React.useState()
  const navigate = useNavigate();


  const { selectedRows } = useSelectedRows();
  const authUser = useAuthUser();
  const {
    data: userList,
    isLoading: isUserListLoading,
    isFetching: isUserListFetching,
  } = useAdminUserList()
  const loadMeters = useCallback(() => {
    setFilteredList([]);
    userList?.data?.userList?.length && userList?.data?.userList?.map((item) => {
      const { fullname, apartment, energyid, waterid } = item;
      const isEnergy = selectedType.toLowerCase() === "energy";
      if ((isEnergy && energyid?.length) || (!isEnergy && waterid?.length)) {
        setFilteredList((prev) => [
          ...prev,
          {
            fullname,
            apartment,
            meterid: isEnergy ? energyid : waterid,
            type: selectedType,
          },
        ]);
      }
    });
  }, [selectedType, userList]);

  useEffect(() => {
    loadMeters()

  }, [loadMeters])

  const handleSelectType = useCallback((e) => {
    setSelectedType(e.target.value)
  }, [])

  useEffect(() => {
    setSelectedType("energy")
  }, []);
  const handleClearTamperClick = useCallback(() => {
    if (selectedRows?.length === 0) return toast.error("Please select a row")
    else {
      setModalOpen(true);
    }
  }, [selectedRows]);

  const handleSubmit = () => {
    setLoading(true)
    const selectedRowData = selectedRows.map((item) => item?.original?.meterid);
    axios.post("https://pm-server.herokuapp.com/api/v2.0/manager/clear-tamper", {
      meters: selectedRowData,
      remotely: radioState
    }, {
      headers: {
        Authorization: authUser().authToken,
      },
    }).then((res) => {
      // console.log(res.data.data)
      setCsvData(res?.data?.data)
      toast.success(res?.data?.message)
      setLoading(false)
      setModalOpen(false)
      setDownload(true)
    }).catch((err) => {
      // console.log(err)
      toast.error(err?.response?.data?.message)
      setLoading(false)
    })
  };

  const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
  return (
    <React.Fragment>

      <div className={classes}>
        <div className="max-w-full h-full rounded-[8px] bg-white p-6 flex flex-col space-y-6 relative">
          <div className="flex flex-row justify-between items-center">
            <Text
              as="p"
              weight="semibold"
              classname="lg:text-[16px] text-secondary-dark cursor-pointer hover:underline"
              onclick={handleClearTamperClick}
            >
              Clear Tamper
            </Text>
            <div className="flex justify-end items-center space-x-6">

              <div className="flex flex-row gap-3 items-center">
                <Text as="p" weight="semibold">
                  Meter Type:
                </Text>
                <select name="" id=""
                  value={selectedType}
                  onChange={handleSelectType}
                  className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
                >
                  <option value="energy">Energy</option>
                  <option value="water">Water</option>
                </select>
              </div>
              <div className="flex items-center">
                <Input
                  iconLeft={<BsSearch />}
                  placeholder="Search By Meter ID"
                  inputClass="py-[6px] px-[15px] rounded-r-0"
                  onchange={({ target }) => setSearch(target.value)}
                />
              </div>
            </div>
          </div>
          <Table
            search={search}
            data={filteredList ?? []}
            columns={clear_tamper_column}
            isLoading={isUserListLoading || isUserListFetching}
          />
        </div>
      </div>

      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => setModalOpen(false)}>
        <ClearTamperModal onButtonClick={handleSubmit} radioState={radioState} setRadioState={setRadioState} loading={loading} />
      </ModalWrapper>

      <ModalWrapper
        isOpen={
          download
        }

        handleModalClose={() => {
          setDownload(false)
          navigate("/manager/configure")
        }
        }
      >
        <DownloadCSV
          data={csvData}
        />
      </ModalWrapper>
    </React.Fragment>
  );
};

export default ClearTamper;
