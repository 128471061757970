import React from "react";
import { clx } from "../utils/clx";
import Text from "./text";

export default function ChatContainer({
  classname,
      mode = "sender",
  time,
  children,
}) {
  const classes = clx("", classname);
  return (
    <div className={classes}>
      <div className='flex flex-col space-y-3'>
        {mode !== "sender" && (
          <div className='flex items-center space-x-3'>
            <div className='w-[40px] lg:w-[45px] h-[40px] lg:h-[45px] rounded-full border-2 flex items-center justify-center relative'>
              <span className='absolute bottom-[2px] right-0 w-[7px] inline-block h-[7px] rounded-full bg-green-500 z-20' />
              <div className='w-[25px] h-[25px]'>
                <img
                  src='/assets/images/chat-logo2.png'
                  width='100%'
                  height='100%'
                  alt=''
                  className='w-full h-full object-contain'
                />
              </div>
            </div>
            <div>
              <Text as='p' weight='semibold' classname='lg:text-[18px]'>
                Ginjar Admin
              </Text>
              <Text
                as='p'
                weight='medium'
                classname='text-[12px] lg:text-[14px] text-[#9799A5]'
              >
                {time}
              </Text>
            </div>
          </div>
        )}
        <div>
          <div
            className={`w-fit max-w-[250px] lg:max-w-[350px] p-3 lg:p-4 rounded-lg  bg-[#F3F3FF] ${
              mode === "sender"
                ? "ml-auto bg-[#6A74B2] text-white rounded-tr-none"
                : "rounded-tl-none"
            }`}
          >
            <Text as='p' weight='medium' classname='text-[14px] lg:text-base'>
              {children}
            </Text>
          </div>
          {mode === "sender" && (
            <div className='w-fit ml-auto flex items-center space-x-3 lg:space-x-4 my-1'>
              <Text
                as='p'
                weight='medium'
                classname='text-[12px] lg:text-[14px] text-[#9799A5]'
              >
                {time}
              </Text>
              <div>
                <Text
                  as='p'
                  weight='medium'
                  classname='text-[12px] lg:text-[14px] text-[#9799A5]'
                >
                  Delivered
                </Text>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
