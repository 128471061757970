
import CurrencyFormat from "react-currency-format";
import Text from "../../../components/text";

export const charges_history_data = Array.from({ length: 5 }).map((data, i) => ({
    _id: i + 1,
    name: "Oloko Opeyemi ",
    apartment: "Landwey Estate Lekki 1",
    amount: `N10,000,000`,
    date_time: `18-04-2023 / 10:30pm`,
    purpose: `MONTHLY`
}));

export const charges_history_column = [
    // {
    //     // id: "1",
    //     // Header: (
    //     //     <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
    //     //         S/N
    //     //     </Text>
    //     // ),
    //     // Cell: ({ value, row }) => <Text as="p">
    //     //     {value}
    //     // </Text>,
    //     // accessor: "_id",
    // },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Arguments
            </Text>
        ),
        Cell: ({ value, row }) => <Text as="p" classname='text-left text-[16px]'>
            {value}
        </Text>,
        accessor: "arg",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Amount
            </Text>
        ),
        Cell: ({ value, row }) => <Text as="p">
            <CurrencyFormat
                value={value}
                displayType="text"
                decimalScale={2}
                renderText={(value) => <Text as="span">₦{value}</Text>}
                thousandSeparator
                fixedDecimalScale
            />
        </Text>,
        accessor: "amount",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname=' text-[16px] text-[#2C3048] text-left'>
                Total Transactions
            </Text>
        ),
        accessor: "total_number",
    },
];
