import React, { useCallback, useEffect } from 'react';
import { clx } from '../../../utils/clx';
import Text from '../../../components/text';
import Table from '../../../components/table';
import { meter_request } from './meter-request/meter-table-request';
import useMeterRequst from '../../../hooks/apis/admin/useMeterRequst';

const MeterRequest = () => {
  const { isLoading, data: requestedMeter, isError, isFetching,refetch ,refetchOnMount} = useMeterRequst();
  console.log(requestedMeter?.data?.data )
   useCallback(async () => {
    await refetchOnMount();
  }, [refetchOnMount]);
  
  // useEffect(() => {
  //   const timeoutId = setTimeout(debouncedRefetch, 1000); // Debounce for 1 second
  //   return () => clearTimeout(timeoutId);
  // }, [ refetch])

  const classes = clx('w-[93%] lg:w-[90%] mx-auto lg:py-[40px]');

  return (
    <section className={classes}>
      <div className='max-w-full h-full rounded-[8px] bg-white p-6 flex flex-col space-y-6 relative'>
        <Text as='p' weight='semibold' className='lg:text-[16px] text-secondary-dark cursor-pointer hover:underline'>
          List of Request
        </Text>
        <Table
          search={[]}
          data={requestedMeter?.data?.data ?? []} // Use directly
          columns={meter_request}
          isLoading={isLoading || isFetching}
        />
      </div>
    </section>
  );
};

export default MeterRequest;
