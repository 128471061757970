import React from "react";
import StaticLayout from "../../layouts/static-layout";
import ItemDetailaPageWrapper from "../../views/static/item-details-page";
export default function ItemDetailsPage() {
      return (
            <React.Fragment>
                  <StaticLayout>
                        <ItemDetailaPageWrapper />
                  </StaticLayout>
            </React.Fragment>
      )
}