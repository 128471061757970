import React, { useCallback } from 'react'
import Input from "../../../../../components/input"
import { payout_column } from '../../../../../utils/table-utils/admin/payout-table';
import ModalWrapper from '../../../../../components/modals/modal-wrapper';
import Table from '../../../../../components/table';
import Text from '../../../../../components/text';
import ConfirmPayout from '../../../../../components/modals/admin/confirm-payout';
import RechargeSuccess from '../../../../../components/modals/recharge-success';
import { BsSearch } from 'react-icons/bs';
import useAdminUserList from '../../../../../hooks/apis/admin/useAdminUserList';
import { useSelectedRows } from '../../../../../store/admin/configure';
import { toast } from 'react-toastify';
import PayoutFormDetails from '../../../../../components/modals/admin/payout-form-details';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { FiDownload } from 'react-icons/fi';
import { InvoicesCSVHeader } from '../../../prepaid-energy/download-config/settle-invoices-header';
import Button from '../../../../../components/button';
import DateRangePayout from '../../../../../components/modals/admin/date-range-payout';
const Payout = () => {
    const [search, setSearch] = React.useState("");
    const [query, setQuery] = React.useState("")
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [modalType, setModalType] = React.useState("");
    const [tableData, settabledata] = React.useState([]);
    const [data, setData] = React.useState([]);

    const { selectedRows } = useSelectedRows();
    const { data: payoutData, isLoading: isPayoutLoading, isFetching: isPayoutFetching, } = useAdminUserList("payout-unpaid");

    const loadTableData = useCallback(() => {
        if (payoutData?.data?.length) {
            settabledata(payoutData?.data)
        }
    }, [payoutData]);

    React.useEffect(() => {
        loadTableData();
    }, [loadTableData]);

    const handleDateRange = useCallback(() => {
        if(selectedRows.length){
            return toast.error("Please unselect row(s) to proceed")
        }
        setIsModalOpen(true);
        setModalType("daterange");
    }, [selectedRows])

    const handleDateRangePayout = useCallback((values) => {
        if (!values) return toast.error("Please select a date range");
        setQuery("date-range")
        setData(values);
        setModalType("formdetails");
    }, [])

    const handlePayoutModalClick = useCallback(() => {
        if (!selectedRows.length) return toast.error("Please select atleast one row");
        const selectedRowsData = selectedRows.map((item) => item.original.payoutid);
        setData(selectedRowsData);
        setIsModalOpen(true);
        setModalType("payout");
    }, [selectedRows])

    const handlePayment = () => {
        setQuery("default")
        setModalType("formdetails");
    }
    const handleSuccess = () => {
        setIsModalOpen(false)
        navigate("/manager/configure")
    }


    // const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[30px]");
    return (
        <React.Fragment>
            <div className='max-w-full h-full rounded-[8px] bg-white p-6 flex flex-col space-y-6 relative'>
                <div className="flex justify-between items-center space-x-6">
                    <div className='flex gap-x-6 items-center'>
                        <Text
                            as="p"
                            weight="semibold"
                            classname="lg:text-[16px] text-secondary-dark cursor-pointer hover:underline"
                            onclick={handlePayoutModalClick}
                        >
                            Payout By List
                        </Text>
                        <Text
                            as="p"
                            weight="semibold"
                            classname="lg:text-[16px] text-secondary-dark cursor-pointer hover:underline"
                            onclick={handleDateRange}
                            

                        >
                            Payout By Date
                        </Text>
                    </div>
                    <div className="flex gap-6 items-center">
                        <Input
                            iconLeft={<BsSearch />}
                            placeholder="Search By Meter ID"
                            inputClass="py-[6px] px-[15px] rounded-r-0"
                            onchange={({ target }) => setSearch(target.value)}
                        />

                        <CSVLink
                            data={tableData ?? []}
                            headers={InvoicesCSVHeader}
                            filename="Invoices.csv"
                        >
                            <Button iconLeft={<FiDownload />} variant="none">
                                <Text as="p" weight="semibold">
                                    Download
                                </Text>
                            </Button>
                        </CSVLink>
                    </div>

                </div>
                <Table
                    search={search}
                    data={tableData ?? []}
                    columns={payout_column}
                    isLoading={isPayoutLoading || isPayoutFetching}
                />
            </div>

            <ModalWrapper isOpen={
                isModalOpen
            }
                handleModalClose={
                    () => setIsModalOpen(false)
                }
            >
                {modalType === "payout" && <ConfirmPayout onButtonClick={handlePayment} selectedRows={selectedRows} />}
                {modalType === "formdetails" && <PayoutFormDetails modalType={modalType} setModalType={setModalType} data={data} query={query} />}
                {modalType === "success" && <RechargeSuccess text='You payout was successful' title='Payout Successful' onButtonClick={handleSuccess} />}
                {modalType === "daterange" && <DateRangePayout onButtonClick={handleDateRangePayout} />}
            </ModalWrapper>


        </React.Fragment>
    )
}

export default Payout