import React from "react";
import StaticLayout from "../../layouts/static-layout";
import Blog from "../../views/static/blog";


export default function Blogpage() {
      return (
            <React.Fragment>
                  <StaticLayout>
                        <Blog/>
                  </StaticLayout>
            </React.Fragment>
      )
}