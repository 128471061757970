import React from "react";
import StaticLayout from "../../layouts/static-layout";
import CheckoutPageWrapper from "../../views/static/checkout-page";

export default function CheckoutPage() {
  return <React.Fragment>
    <StaticLayout>
      <CheckoutPageWrapper />
    </StaticLayout>
  </React.Fragment>
}
