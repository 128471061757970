import React from "react";
import { useAuthUser, useIsAuthenticated, useSignOut } from "react-auth-kit";
import { BiCopy } from "react-icons/bi";
import { MdCancel, MdOutlineLogout } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import DashboardSidebar from "../../../components/dashboard-sidebar";
import LinkButton from "../../../components/link-button";
import Text from "../../../components/text";
import { userMenu } from "../../../constants/user-menu";
import { clx } from "../../../utils/clx";
import { IoChevronBack } from "react-icons/io5";
import ModalWrapper from "../../../components/modals/modal-wrapper";
import useUserInfo from "../../../hooks/apis/user/useUserInfo";
import useWalletInfo from "../../../hooks/apis/user/useWalletInfo";
import NotificationContainer from "../../../components/notification-container";
import { useQuery } from "react-query";
import useUserStore from "../../../store/user/store";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import CopyText from "../../../components/toast/copy-text";
import { getSortedLastToStart } from "../../../utils/getSortedLastToStart";
import { FaBell } from "react-icons/fa";

function isMainMenu() {
  return true;
}

// const {data} = useQuery()
export default function UserDashboardLayout({
  classname,
  pageTitle,
  children,
}) {
  const router = useLocation();
  const navigate = useNavigate();
  const [isNavOpen, setNavOpen] = React.useState(false);
  const [isMainMenu, setIsMainMenu] = React.useState(null);
  const [isProfileDropdownOpen, setProfileDropdown] = React.useState(false);
  const [isNotificationOpen, setNotificationOpen] = React.useState(false);
  const scrollRef = React.useRef();
  const [user, setUser] = React.useState();
  const authUser = useAuthUser();
  const { UserInfo, isLoading, isError } = useUserInfo();

  const userInfo = useUserStore((state) => state.user);

  React.useEffect(() => {
    setUser(UserInfo?.data);
  }, [UserInfo]);

  React.useEffect(() => {
    setIsMainMenu(false);
    scrollRef.current?.scrollTo(0, 0);
    for (let menu of userMenu) {
      if (
        menu.route === router.pathname ||
        menu.route === `${router.pathname}/`
      ) {
        setIsMainMenu(true);
      }
    }
  }, [router, isMainMenu]);

  const classes = clx("lg:max-h-screen h-screen flex", classname);

  if (isLoading || isError) {
    return (
      <div className="h-[100%] w-full flex items-center justify-center">
        <div className="fixed inset-0 h-[100%]">
          <img
            src="/assets/images/spiral-background.jpg"
            className="object-cover lg:object-fill w-full h-full opacity-[1.5%] lg:opacity-[1.5%]"
            alt=""
          />
        </div>
        <div className="fixed inset-0 left-[50%] translate-x-[-50%] flex justify-center items-center w-fit flex-col">
          <div>
            <img src="/assets/icons/loader.gif" alt="" className="w-[100px]" />
          </div>
          <Text as="p" weight="bold" classname="text-[26px] lg:text-[40px]">
            Welcome <span className="text-secondary-dark">Back!</span>
          </Text>
        </div>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div className={classes}>
        <div className="hidden lg:block">
          <DashboardSidebar user={user} />
        </div>
        <div className="flex-1 min-h-[100%] lg:min-h-fit w-full flex flex-col lg:max-h-screen lg:overflow-hidden relative">
          <div className="fixed lg:static inset-0 w-full max-h-[70px] lg:max-h-[100px] bg-white dark:bg-[#0C0C11] z-40">
            <div className=" lg:static w-[93%] lg:w-[90%]  mx-auto py-[20px] lg:py-[30px] flex items-center">
              {isMainMenu ? (
                <div
                  className="lg:hidden w-fit h-fit p-2 rounded-full bg-primary-dark flex justify-center items-center dark:bg-[#e6e6e9]"
                  onClick={() => setNavOpen(true)}
                >
                  <GiHamburgerMenu className="text-[20px] text-white dark:text-primary-dark" />
                </div>
              ) : (
                <div className="flex items-center" onClick={() => navigate(-1)}>
                  <IoChevronBack className="text-[24px]" />
                  {/* <Text as='span'>Back</Text> */}
                </div>
              )}
              <div className="flex-1 text-center dark:text-[#E6E6E9]">
                <Text as="span">{pageTitle}</Text>
              </div>
              <div className="flex items-center space-x-8">
                {isMainMenu && (
                  <div
                    className="cursor-pointer relative"
                    onClick={() => setNotificationOpen(true)}
                  >
                    {userInfo?.notification?.some(
                      (notification) => notification.read === false
                    ) && (
                      <span className="absolute top-0 right-0 w-[7px] inline-block h-[7px] rounded-full bg-red-500 z-20" />
                    )}
                    <FaBell className="text-[22px] lg:text-[24px]" />
                    {/* <img src='/assets/icons/bell.png' alt='' /> */}
                  </div>
                )}
                <div
                  className="hidden lg:flex items-center space-x-2 relative"
                  onClick={() => setProfileDropdown((status) => !status)}
                >
                  <div className="w-[48px] h-[48px] rounded-full overflow-hidden">
                    <img
                      src={user?.pics}
                      className="cursor-pointer object-contain w-full"
                      alt=""
                    />
                  </div>
                  <img
                    src="/assets/icons/dropdown.png"
                    className="cursor-pointer"
                    alt=""
                  />

                  {/* Dropdown pane */}
                  {isProfileDropdownOpen && (
                    <div className="absolute top-[110%] right-0 rounded-[20px] bg-white p-4 shadow-inner w-[200px]">
                      <Link to="/user/profile">
                        <Text as="h6" classname="my-2">
                          Profile
                        </Text>
                      </Link>
                      <Link to="/user/profile">
                        <Text as="h6" classname="my-2">
                          Change Password
                        </Text>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Mobile Nav */}
            <UserDashboardLayout.MobileNav
              user={user}
              isOpen={isNavOpen}
              handleClose={() => setNavOpen(false)}
            />
          </div>

          <div
            ref={scrollRef}
            className="lg:h-[100%] lg:flex-1 w-full lg:overflow-auto mt-[70px] lg:mt-0 lg:scrollbar lg:scrollbar-thumb-primary-dark lg:scrollbar-track-secondary-dar scrollbar-track-rounded-lg lg:scrollbar-thumb-rounded-lg dark:bg-[#121319]"
          >
            <div className="bg-white dark:bg-transparent h-fit">
              <Outlet />
            </div>
          </div>
        </div>
      </div>

      <ModalWrapper
        classname="bg-black/10"
        isOpen={isNotificationOpen}
        handleModalClose={({ target, currentTarget }) =>
          target === currentTarget ? setNotificationOpen(false) : null
        }
        flexibleModal
      >
        <div className="z-[999] lg:mt-[100px] lg:mr-[50px] w-full lg:w-[500px] h-screen lg:h-[450px] lg:rounded-[20px] lg:p-6 bg-white shadow-lg flex flex-col overflow-hidden">
          {/* Navigation */}
          <div className="lg:hidden flex p-4 lg:p-0">
            <IoChevronBack
              className="text-[24px] text-left ml-[-5px]"
              onClick={() => setNotificationOpen(false)}
            />
            <div className="flex-1">
              <Text
                as="p"
                weight="semibold"
                classname="text-center text-[18px]"
              >
                Notifications
              </Text>
            </div>
          </div>
          <Text
            as="h5"
            weight="semibold"
            classname="hidden lg:block text-[30px] pb-[20px] border-b-2"
          >
            Notifications
          </Text>
          <div className="flex-1 overflow-hidden py-[20px] lg:py-[10px]">
            <div className="h-[100%] w-full overflow-auto scrollbar-thin scrollbar-thumb-primary-dark scrollbar-thumb-rounded-full">
              <div className="h-fit w-full px-4 lg:p-0">
                {getSortedLastToStart(userInfo?.notification)?.map(
                  (notification, index) => (
                    <NotificationContainer
                      key={`${notification?.title}-${index}`}
                      title={notification?.title}
                      date={notification?.day}
                      time={notification?._id}
                      classname="py-3 border-b-2"
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </React.Fragment>
  );
}

export function MobileNav({ user, classname, isOpen, handleClose, children }) {
  const [isLinkClicked, setLinkClicked] = React.useState(false);
  const router = useLocation();
  const signOut = useSignOut();

  const handleLinkClick = () => {
    setLinkClicked(true);
    handleClose();
  };
  React.useEffect(() => {
    setLinkClicked(false);
    window.scrollTo(0, 0);
  }, [isLinkClicked]);
  const classes = clx(
    "lg:hidden fixed inset-0 z-50 h-[100%] max-h-[100%] w-full bg-black/5 overflow-hidden duration-300",
    isOpen ? "ml-0" : "ml-[-100%]",
    classname
  );

  return (
    <div
      className={classes}
      onClick={({ target, currentTarget }) =>
        target === currentTarget && handleClose()
      }
    >
      <div className="w-[300px] h-[100%] max-h-[100%] bg-white px-[20px] py-[40px] pb-[80px] overflow-auto">
        <div
          className="w-fit"
          onClick={({ target, currentTarget }) => handleClose()}
        >
          <MdCancel className="text-[33px] text-primary-dark" />
        </div>
        <div className="flex items-center space-x-6 pt-[30px]">
          <div className="w-[48px] h-[48px] rounded-full overflow-hidden">
            <img src={user?.pics} alt="profile pic" />
          </div>
          <div>
            <Text as="p">Hello</Text>
            <Text as="p" weight="semibold" classname="font-semibold">
              {user ? `${user?.firstname} ${user?.lastname}` : "Samuel Lasisi"}
            </Text>
          </div>
        </div>
        <div className="flex items-center space-x-1 pb-[30px] py-3">
          <Text as="p" weight="semibold" classname="text-[14px]">
            UserID:
          </Text>
          <div className="flex items-center space-x-3">
            <Text as="span" classname="text-[14px]">
              {user ? user.userid : "123456ABCD"}
            </Text>
            <CopyToClipboard
              text={user?.userid}
              onCopy={() =>
                toast.success(<CopyText>User ID copied successfully!</CopyText>)
              }
            >
              <BiCopy className="block text-black cursor-pointer" />
            </CopyToClipboard>
          </div>
        </div>
        <div className="flex flex-col space-y-6 py-[20px] pb-[100px] border-b-[3px]">
          {userMenu.map(
            (link) =>
              link.id !== 4 && (
                <LinkButton
                  key={link.id}
                  icon={link.icon}
                  href={link.route}
                  classname="w-full"
                  onclick={handleLinkClick}
                  isActive={
                    router.pathname === link.route ||
                    router.pathname === `${link.route}/`
                  }
                >
                  {link.label}
                </LinkButton>
              )
          )}
        </div>
        <div className="flex flex-col space-y-6 py-[20px]">
          {userMenu.map(
            (link) =>
              link.id === 4 && (
                <LinkButton
                  key={link.id}
                  icon={link.icon}
                  href={link.route}
                  classname="w-full"
                  onclick={handleLinkClick}
                  isActive={
                    router.pathname === link.route ||
                    router.pathname === `${link.route}/`
                  }
                >
                  {link.label}
                </LinkButton>
              )
          )}
          <LinkButton
            icon={<MdOutlineLogout className="text-[24px]" />}
            onclick={() => signOut()}
            classname="hover:bg-transparent hover:text-[#6C6F7F]"
          >
            Logout
          </LinkButton>
        </div>
      </div>
    </div>
  );
}

UserDashboardLayout.MobileNav = MobileNav;
