import React from "react";
import Text from "../../text";
import Button from "../../button";
import Radio from "../../radio";
import { useCallback } from "react";
import useAdminAffiliates from "../../../store/admin/affiliates";
import { useAffiliateProducts } from "../../../store/admin/configure";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";

export default function AffiliateServiceModal({ onButtonClick, data, title, body, btnText, isLoading, type, newService, setNewService, label, query }) {
    const [radioState, setRadioState] = React.useState("existing");
    const [loading, setLoading] = React.useState(false)
    const [description, setDescription] = React.useState("")
    const { selectedAffiliate } = useAdminAffiliates();
    const { affiliateProducts } = useAffiliateProducts()
    const authUser = useAuthUser();
    const [services, setServices] = React.useState([])

    const fetchSlugs = useCallback(() => {
        setLoading(true)
        if (radioState === "new") {
            axios.post(`https://pm-server.herokuapp.com/api/v2.0/affiliate/service?query=${query}`, {
                tag: selectedAffiliate,
                service_slug: affiliateProducts?.service_slug
            }, {
                headers: {
                    Authorization: authUser().authToken,

                }
            }).then((res) => {
                setLoading(false)
                setServices(res.data.data)
            }).catch((err) => {
                console.log(err.response.data)
                setLoading(false)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [radioState])

    React.useEffect(() => {
        fetchSlugs()
    }, [fetchSlugs])

    const handlefilter = useCallback((e) => {
        setNewService("")
        setRadioState(e.target.value)
    }, [newService])

    const handleSubmit = (e) => {
        e.preventDefault();
        onButtonClick()
    }
    const handleChange = (e) => {
        if (type === "activate" && radioState === "new") {
            setNewService(e.target.value)
            const desc = services?.findIndex(({ slug }) => slug === e.target.value)
            setDescription((services[desc]?.["desc"]))
        }
        else {
            setNewService(e.target.value)
        }
    }


    return (
        <div>
            <Text
                as="h3"
                weight="bold"
                classname="text-[24px] lg:text-[30px] text-left"
            >
                {title}
            </Text>
            <form onSubmit={handleSubmit} className="my-6 flex flex-col space-y-6">
                <Text as="p" weight="medium" classname=" text-secondary-dark">
                    {body}
                </Text>
                {type === "activate" &&
                    <div className="flex justify-start gap-4">
                        <Radio
                            name='filter-type'
                            label='Existing'
                            checked={radioState === "existing"}
                            onchange={handlefilter}
                            onclick={handlefilter}
                            classname='text-[16px]'
                            value="existing"
                        />
                        <Radio
                            name='filter-type'
                            label='New'
                            classname='text-[16px]'
                            value="new"
                            checked={radioState === "new"}
                            onchange={handlefilter}
                            onclick={handlefilter}
                        />
                    </div>}

                {radioState === "new" && type === "activate" ? (
                    <>
                        <div>
                            <select name="" id=""
                                value={newService}
                                onChange={handleChange}
                                required
                                className="w-full h-[40px] bg-[#E6E6E9] border-none rounded-md capitalize outline-none"

                            >
                                {loading ? (
                                    <option value="">Loading slugs...</option>

                                ) : (

                                    <>
                                        <option value="">Select slugs...</option>
                                        {services?.map((service, index) => (
                                            <option key={index} value={service.slug}>{service.slug}</option>
                                        ))}
                                    </>
                                )
                                }
                            </select>
                            {description && <Text as="p" classname="max-w-[400px]">
                                {description}
                            </Text>}

                        </div>
                    </>
                ) : (
                    <div>
                        <select name="" id=""
                            value={newService}
                            onChange={handleChange}
                            required
                            className="w-full h-[40px] bg-[#E6E6E9] border-none rounded-md capitalize outline-none"

                        >
                            <option value="">Select {label}</option>
                            {data?.map((service, index) => (
                                <option key={index} value={service.slug}>{service.slug}</option>
                            ))}
                        </select>
                    </div>
                )
                }

                <Button
                    buttonType="submit"
                    isLoading={isLoading}
                    classname="w-full">
                    {btnText}
                </Button>
            </form>
        </div>

    );
}
