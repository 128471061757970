import React, { useEffect } from 'react'
import { useAuthUser } from "react-auth-kit";
import { useQuery } from "react-query";
import useUserStore from '../../store/user/store';
import useSelectedEnergyUser from '../../store/admin/selected-energy-user';

const TestApi = () => {

    const authUser = useAuthUser();
    const user = useUserStore((state) => state.user);
    const { selectedEnergyUser: users } = useSelectedEnergyUser();
    useEffect(()=>{
        const getCredit = async()=>{
        let res = await fetch('https://api-pm-e1ea777feac4.herokuapp.com/charges/overview?type=periodic&charges_slug=monthly',{
        // let res = await fetch('https://pm-server.herokuapp.com/api/v2.0/consumption/?query=date-range',{
            method:"GET",
            // method:"POST",
            // body:JSON.stringify({
            //     userid:'UiD-513951783',
            //     prepaid:'energy',
            //     start:'2022-12',
            //     end:'2023-2'
            // },
            // ),
            headers:{
                'x-api-key':'GINJAR1qMl13gxU8NxCftG6H/sv4iFUU6Pkz3q',
                // 'x-api-key':'GINJAR469JhRtV4ZDioSfa/kWxOe8MnvqEhpTL',
                'Content-Type':'application/json'
            }

        })
        // 'https://pm-server.herokuapp.com/api/v2.0/consumption/?query=' + query,
        .then(res =>res.json())
        .then(data=>console.log(data))
        }
        getCredit()
    })

    useEffect(()=>{
        const getVendorTransact =  async()=>{
            await fetch('https://pm-server.herokuapp.com/api/v2.0/manager/vend-txn?query=default',{
                method:'POST',
                body:JSON.stringify({
                    "slug":"user",
                    "userid":"UiD-383883792",
                    "prepaid":"energy"
                    // slug: "user",
                    // slug: "tags",
                    // tags:'ALL',
                    // 'userid':'g',
                    // userid: users?.userid,
                    // prepaid:'energy',
                 
                }
                ),
                headers:{
                    'x-api-key':'GINJAR469JhRtV4ZDioSfa/kWxOe8MnvqEhpTL',
                    'Content-Type':'application/json',
                    Authorization: authUser().authToken,
                }
            })
            .then(res=>res.json())
            .then(data =>console.log(data))
        }
        getVendorTransact()
    })
    return (
        <div>
            testApi
        </div>
    )
}

export default TestApi
