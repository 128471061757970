import Text from "../../../components/text";
import { format, parseISO } from 'date-fns'

export const energy_transactions_data = Array.from({ length: 10 }).map(
  (data, i) => ({
    _id: i + 1,
    amount: 56000,
    token: "626668777",
    unit: "1.1",
    date_created: "Oct 05, 2022, 18:30",
    reference_id: `5232464252${i}`,
    fullname: "Oloko Opeyemi",
  })
);

export const energy_transactions_column = [
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Date Created
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className="flex items-center space-x-4">
        <Text as="p" classname="text-left text-[16px]">
          {value} 
        </Text>
      </div>
    ),
    accessor: "Gen_Date",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Name
      </Text>
    ),
    accessor: "fullname",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Apartment
      </Text>
    ),
    //     Cell: TableIcon,
    accessor: "apartment",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Token
      </Text>
    ),
    accessor: "Token",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Amount
      </Text>
    ),
    accessor: "Total_paid",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Unit
      </Text>
    ),
    accessor: "Total_unit",
  },
  {
    //     id: "_",
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Reference ID
      </Text>
    ),
    //     Cell: TableIcon,
    accessor: "refr",
  },

  // {
  //   id: "action",
  //   Header: (
  //     <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
  //       Action
  //     </Text>
  //   ),
  //   Cell: ({ value, row }) => (
  //     <div className="flex items-center space-x-4">
  //       <Text as="p" classname="text-left text-[16px] text-secondary-dark">
  //         View
  //       </Text>
  //     </div>
  //   ),
  //   // accessor: "fullname",
  // },
];


export const charges_transactions_column=[
  {
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Apartment 
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className="flex items-center space-x-4">
        <Text as="p" classname="text-left text-[16px]">
          {value}
        </Text>
      </div>
    ),
    accessor: "user.apartment",
  },
  {
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Target Period
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className="flex items-center space-x-4">
        <Text as="p" classname="text-left text-[16px]">
          {value}
        </Text>
      </div>
    ),
    accessor: "targetPeriod",
  },
  {
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Amount
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className="flex items-center space-x-4">
        <Text as="p" classname="text-left text-[16px]">
          {value}
        </Text>
      </div>
    ),
    accessor: "amount",
  },
  {
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Day
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className="flex items-center space-x-4">
        <Text as="p" classname="text-left text-[16px]">
          {value}
        </Text>
      </div>
    ),
    accessor: "day",
  },
]
export const charges_overv_column=[
  {
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Apartment 
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className="flex items-center space-x-4">
        <Text as="p" classname="text-left text-[16px]">
          {value}
        </Text>
      </div>
    ),
    accessor: "tag",
  },
  {
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Target Period
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className="flex items-center space-x-4">
        <Text as="p" classname="text-left text-[16px]">
          {value}
        </Text>
      </div>
    ),
    accessor: "targetPeriod",
  },
  {
    Header: (
      <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
        Date Created
      </Text>
    ),
    Cell: ({ value, row }) => (
      <div className="flex items-center space-x-4">
        <Text as="p" classname="text-left text-[16px]">
        {format(parseISO(value), 'dd-MM-yyyy H:mm:ss')}
        </Text>
      </div>
    ),
    accessor: "dateCreated",
  }
]
