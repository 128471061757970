import React, { useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';

function BillingCard({ billing, onDelete, onEdit }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedBilling, setEditedBilling] = useState({ ...billing });

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedBilling({ ...editedBilling, [name]: value });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    onEdit(editedBilling);
    setIsEditing(false);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-64 h-64 flex flex-col justify-between">
      {isEditing ? (
        <form onSubmit={handleEditSubmit}>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">Amount</label>
            <input
              type="number"
              name="amount"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2"
              value={editedBilling.amount}
              onChange={handleEditChange}
              required
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">Frequency</label>
            <select
              name="frequency"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2"
              value={editedBilling.frequency}
              onChange={handleEditChange}
              required
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">Billing Type</label>
            <select
              name="billingType"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2"
              value={editedBilling.billingType}
              onChange={handleEditChange}
              required
            >
              <option value="electricity">Electricity</option>
              <option value="water">Water</option>
              <option value="internet">Internet</option>
              <option value="rent">Rent</option>
            </select>
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">Payment Details</label>
            <input
              type="text"
              name="paymentDetails"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2"
              value={editedBilling.paymentDetails}
              onChange={handleEditChange}
              required
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">Created Date</label>
            <input
              type="date"
              name="createdDate"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2"
              value={editedBilling.createdDate}
              onChange={handleEditChange}
              required
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">Next Date</label>
            <input
              type="date"
              name="nextDate"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2"
              value={editedBilling.nextDate}
              onChange={handleEditChange}
              required
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button type="button" onClick={() => setIsEditing(false)} className="py-1 px-3 bg-gray-300 rounded-md">
              Cancel
            </button>
            <button type="submit" className="py-1 px-3 bg-blue-500 text-white rounded-md">
              Save
            </button>
          </div>
        </form>
      ) : (
        <>
          <div>
            <h3 className="text-lg font-bold mb-2">{billing.billingType}</h3>
            <p className="text-sm">Amount: ${billing.amount}</p>
            <p className="text-sm">Frequency: {billing.frequency}</p>
            <p className="text-sm">Payment Details: {billing.paymentDetails}</p>
            <p className="text-sm">Created Date: {billing.createdDate}</p>
            <p className="text-sm">Next Date: {billing.nextDate}</p>
          </div>
          <div className="flex justify-end space-x-2">
            <FaEdit className="text-blue-500 cursor-pointer" onClick={() => setIsEditing(true)} />
            <FaTrash className="text-red-500 cursor-pointer" onClick={onDelete} />
          </div>
        </>
      )}
    </div>
  );
}

export default BillingCard;
