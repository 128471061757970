import { sub } from "date-fns";
import React from "react";
import { useNavigate } from "react-router-dom";
import { clx } from "../../utils/clx";
import getDate from "../../utils/getDate";
import Button from "../button";
import Input from "../input";
import Radio from "../radio";
import Text from "../text";

export default function Filter({
  filteredChart,
  route,
  handleModalClose,
  classname,
  children,
}) {
  const [filterType, setFilterType] = React.useState("range");
  const [interval, setInterval] = React.useState({});
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const startRef = React.useRef();
  const endRef = React.useRef();

  const navigate = useNavigate();

  const handleRadioSelection = (duration) => {
    switch (duration) {
      case "2-weeks": {
        setStartDate(
          getDate(sub(new Date(), { days: 13 }), { format: "yyyy-mm-dd" })
        );
        setEndDate(getDate(new Date(), { format: "yyyy-mm-dd" }));
        break;
      }
      case "1-month": {
        setStartDate(
          getDate(sub(new Date(), { days: 29 }), { format: "yyyy-mm-dd" })
        );
        setEndDate(getDate(new Date(), { format: "yyyy-mm-dd" }));
        break;
      }
      case "3-months": {
        setStartDate(
          getDate(sub(new Date(), { days: 89 }), { format: "yyyy-mm-dd" })
        );
        setEndDate(getDate(new Date(), { format: "yyyy-mm-dd" }));
        break;
      }
      default:
        return;
    }
  };

  React.useEffect(() => {
    setStartDate(getDate(new Date(), { format: "yyyy-mm-dd" }));
    setEndDate(getDate(new Date(), { format: "yyyy-mm-dd" }));
  }, []);

  const classes = clx("", classname);
  return (
    <div className={classes}>
      <Text
        as="h3"
        weight="bold"
        classname="text-[24px] lg:text-[30px] text-center"
      >
        Filter
      </Text>
      <div className="flex-1 overflow-auto">
        <div className="h-[300px] lg:h-fit">
          <div className="my-4 pb-[10px]">
            {/* <Text as='h6' weight='semibold' classname='text-[20px]'>
              Range
            </Text> */}
            <Radio
              name="filter-type"
              label="Range"
              classname="font-semibold text-[18px]"
              checked={filterType === "range"}
              onclick={() => setFilterType("range")}
            />
            {filterType === "range" && (
              <div className="my-3 flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:items-center lg:space-x-6">
                <div>
                  <Input
                    // ref={startRef}
                    defaultValue="2023-01-07"
                    placeholder="dd/MM/yyyy"
                    label="Start"
                    // value={startDate}
                    variant="outlined"
                    type="date"
                    onchange={({ target }) => setStartDate(target.value)}
                  />
                </div>
                <div>
                  <Input
                    // ref={endRef}
                    defaultValue="2023-01-07"
                    placeholder="dd/MM/yyyy"
                    label="End"
                    // value={endDate}
                    variant="outlined"
                    type="date"
                    onchange={({ target }) => setEndDate(target.value)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="my-4 pb-[10px]">
            {/* <Text as='h6' weight='semibold' classname='text-[20px]'>
              Interval
            </Text> */}
            <Radio
              name="filter-type"
              label="Interval"
              classname="font-semibold text-[18px]"
              checked={filterType === "interval"}
              onclick={() => setFilterType("interval")}
            />
            {filterType === "interval" && (
              <div className="grid grid-cols-2 gap-y-2 lg:gap-y-0 lg:flex justify-between lg:space-x-6 px-4 my-3">
                <Radio
                  name="plan-duration"
                  label="Past 2 weeks"
                  onclick={() => handleRadioSelection("2-weeks")}
                />
                <Radio
                  name="plan-duration"
                  label="Past 1 month"
                  onclick={() => handleRadioSelection("1-month")}
                />
                <Radio
                  name="plan-duration"
                  label="Past 3 month"
                  onclick={() => handleRadioSelection("3-months")}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="my-4 lg:my-0 lg:w-fit mx-auto flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-[30px]">
        <Button
          size="medium"
          type="secondary"
          classname="px-[40px] w-full lg:w-fit"
          onclick={handleModalClose}
        >
          Cancel
        </Button>
        <Button
          size="medium"
          classname="w-full lg:w-fit"
          // href={`/user/energy-statistics?start-date=${startDate}&&end-date=${endDate}`}
          onclick={() => {
            handleModalClose();
            navigate(`${route}?start-date=${startDate}&&end-date=${endDate}`);
          }}
        >
          Apply Filter
        </Button>
      </div>
    </div>
  );
}
