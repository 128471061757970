import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { FaEnvelope, FaLock, FaPhoneAlt, FaUserAlt } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import Button from "../../components/button";
import Input from "../../components/input";
import Text from "../../components/text";
import AuthLayout from "../../layouts/auth-layout";
import { clx } from "../../utils/clx";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import useUserRegistration from "../../store/user/registration";

export default function SignupPageWrapper() {
  const [searchParams] = useSearchParams();
  const authData = JSON.parse(searchParams.get("auth-data"));

  const navigate = useNavigate();
  React.useEffect(() => {
    if (!authData) {
      navigate("/auth-token");
    }
  });
  return (
    <AuthLayout>
      <div className="w-full lg:py-[30px] pb-[50px]">
        <div className="w-full h-full lg:flex lg:space-x-[30px]">
          <div className="lg:hidden">
            <div className="my-[40px]">
              <img
                src="assets/images/logo2.png"
                alt="logo"
                className="w-[250px] mx-auto"
              />
            </div>
            {/* <div className='h-[70px] w-full rounded-[12px] bg-[#D9D9D9]'></div> */}
          </div>
          <div className="flex-1 h-full flex flex-col justify-center">
            <Text
              as="p"
              classname="font-[600] text-[28px] lg:text-[64px] text-primary-dark"
            >
              Let’s get started
            </Text>
            <Text
              as="p"
              weight="medium"
              classname="text-[14px] lg:text-[24px] text-primary-dark"
            >
              One click away to enjoy our amazing features
            </Text>
            <SignupPageWrapper.SampleInfoContainer />
          </div>
          <div className="flex-1 flex justify-end items-center">
            <div className="bg-[#F8F8FF] border-[1px] rounded-[16px] w-full py-[30px] lg:py-[50px] px-[20px] lg:px-[70px]">
              <SignupPageWrapper.Form />
            </div>
          </div>
        </div>
        {/* 
        <div className='w-[550px] py-10 rounded-[16px] bg-[#F8F8FF] flex flex-col justify center items-center space-y-10'>
          <LoginPageWrapper.Dropdown label='Wallet' />
          <LoginPageWrapper.Dropdown label='Meter Id' />
          <LoginPageWrapper.Dropdown label='Fuel Monitoring Subscription' />
        </div> */}
      </div>
    </AuthLayout>
  );
}

export function SampleInfoContainer({ classname, children }) {
  const [searchParams] = useSearchParams();
  const authData = JSON.parse(searchParams.get("auth-data"));

  const classes = clx(
    "bg-secondary-light p-6 px-8 rounded-[8px] flex flex-col lg:block space-y-3 lg:space-y-0 my-[40px] lg:w-fit ",
    classname
  );
  return (
    <div className={classes}>
      <div className="grid grid-cols-2 lg:flex items-start lg:space-x-[40px] lg:justify-between gap-y-3 lg:gap-y-0">
        <div className="flex flex-col space-y-1">
          <Text as="p" classname="text-[14px] lg:text-base text-primary-dark">
            Meter Number
          </Text>
          <Text
            as="span"
            classname="text-[14px] lg:text-base bg-primary-dark text-white rounded-[4px] w-fit p-1 px-4"
          >
            {authData?.meterid}
          </Text>
        </div>

        <div className="flex flex-col space-y-1 place-self-end">
          <Text as="p" classname="text-[14px] lg:text-base text-primary-dark">
            Company
          </Text>
          <Text
            as="span"
            classname="text-[14px] lg:text-base bg-secondary-dark text-primary-dark rounded-[4px] w-fit p-1 px-4"
          >
            {authData?.company}
          </Text>
        </div>

        <div className="hidden lg:flex flex-col space-y-1">
          <Text as="p" classname="text-[14px] lg:text-base text-primary-dark">
            Apartment
          </Text>
          <Text
            as="span"
            classname="text-[14px] lg:text-base bg-primary-dark text-white rounded-[4px] w-full p-1 px-4"
          >
            {authData?.apartment}
          </Text>
        </div>
      </div>
      <div className="flex lg:hidden flex-col space-y-1">
        <Text as="p" classname="text-[14px] lg:text-base text-primary-dark">
          Apartment
        </Text>
        <Text
          as="span"
          classname="text-[14px] lg:text-base bg-primary-dark text-white rounded-[4px] w-full p-1 px-4"
        >
          {authData?.apartment}
        </Text>
      </div>
    </div>
  );
}

export function Form({}) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [searchParams] = useSearchParams();
  const authData = JSON.parse(searchParams.get("auth-data"));
  const auth = searchParams.get("auth");
  // const { setAuthInfo } = useUserRegistration((state) => state);

  const navigate = useNavigate();

  const formValues = {
    fullname: "",
    username: "",
    email: "",
    phonenumber: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    fullname: Yup.string().required("Please enter your fullname"),
    username: Yup.string().required("Please enter your username"),
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Please enter your email"),
    phonenumber: Yup.number().required("Please enter your password"),
    password: Yup.string()
      .min(8, "password must be at least 8 characters")
      .required("Please enter your password"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password Doesn't Match")
      .required("Please enter your password"),
  });

  const onRegistration = (values, { resetForm }) => {
    setValidationAttempt(false);
    // console.log(values);
    // console.log(authData);
    const keysToFilter = ["apartment", "confirmPassword"];
    const filteredObject = { ...authData, ...values };
    for (const key of keysToFilter) {
      delete filteredObject[key];
    }

    axios
      .post("https://api-pm-e1ea777feac4.herokuapp.com/user/register", {
        ...filteredObject,
      })
      .then((res) => {
        const encodedData = encodeURIComponent(
          JSON.stringify({ ...authData, ...values })
        );
        navigate(
          `/verification?auth-email=${values.email}&&verify-data=${encodedData}`
        );
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data.message);
      });
    resetForm();
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit: onRegistration,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col space-y-6">
        <div className="flex flex-col space-y-6  mb-[30px]">
          <Input
            iconLeft={<FaUserAlt className="text-[#9799A5]" />}
            placeholder="Full Name"
            name="fullname"
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.fullname}
            value={formik.values.fullname}
          />
          <Input
            iconLeft={<FaUserAlt className="text-[#9799A5]" />}
            placeholder="Username"
            name="username"
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.username}
            value={formik.values.username}
          />
          <Input
            type="email"
            iconLeft={<FaEnvelope className="text-[#9799A5]" />}
            placeholder="Email Address"
            name="email"
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.email}
            value={formik.values.email}
          />
          <Input
            type="tel"
            iconLeft={<FaPhoneAlt className="text-[#9799A5]" />}
            placeholder="Phone Number"
            name="phonenumber"
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.phonenumber}
            value={formik.values.phonenumber}
          />
          <Input
            type="password"
            iconLeft={<FaLock className="text-[#9799A5]" />}
            placeholder="Create Password"
            name="password"
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.password}
            value={formik.values.password}
          />
          <Input
            type="password"
            iconLeft={<FaLock className="text-[#9799A5]" />}
            placeholder="Confirm Password"
            name="confirmPassword"
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.confirmPassword}
            value={formik.values.confirmPassword}
          />
        </div>
        <Button
          buttonType="submit"
          classname="w-full"
          onclick={() => setValidationAttempt(true)}
        >
          Sign up
        </Button>
        <Text
          as="p"
          classname="text-[14px] lg:text-base text-center"
          href="/login"
        >
          Already have an account?{" "}
          <span className="text-secondary-dark">Login</span>
        </Text>
      </div>
    </form>
  );
}

SignupPageWrapper.Form = Form;

SignupPageWrapper.SampleInfoContainer = SampleInfoContainer;