import React from "react";
import StaticLayout from "../../layouts/static-layout";
import BuyMeterWrapper from "../../views/static/buy-meter/buy-meter";

export default function BuyMeterPage() {
  return <React.Fragment>
    <StaticLayout>
      <BuyMeterWrapper />
    </StaticLayout>
  </React.Fragment>
}
