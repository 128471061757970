import React from "react";
import ReactDOM from "react-dom";
import { MdCancel } from "react-icons/md";
import { clx } from "../../utils/clx";
import Text from "../text";

export default function ModalWrapper({
  isOpen,
  flexibleModal,
  handleModalClose,
  classname,
  children,
}) {
  const classes = clx(
    isOpen ? "fixed" : "hidden",
    "top-0 left-0 right-0 z-[200] h-screen max-h-[screen] bg-black/30 flex overflow-y-auto",
    flexibleModal ? "justify-end items-start" : "justify-center items-center",
    classname
  );
  if (flexibleModal) {
    return ReactDOM.createPortal(
      <div className={classes} onClick={handleModalClose}>
      
        {children}
      </div>,
      document.getElementById("modal")
    );
  }
  return ReactDOM.createPortal(
    <div className={classes} 
    onClick={({ target, currentTarget }) =>
    target === currentTarget && handleModalClose(false)
  }
    >
      <div className="w-[90%] lg:min-w-[400px] lg:max-w-fit rounded-[20px] bg-white py-10 px-6 lg:px-12 relative flex flex-col overflow-y-auto" >
        <MdCancel
          className="text-[24px] absolute right-[30px] text-[#9799A5] top-[20px] cursor-pointer"
          onClick={handleModalClose}
        />
        {children}
      </div>
    </div>,
    document.getElementById("modal")
  );
}
