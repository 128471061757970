import React from "react";
import { BiCopy } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { userMenu } from "../constants/user-menu";
import { adminMenu } from "../constants/admin-menu";
import { ioboMenu } from "../constants/iobotech-menu";
import { clx } from "../utils/clx";
import LinkButton from "./link-button";
import Switch from "./switch";
import Text from "./text";
import { useSignOut } from "react-auth-kit";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdOutlineLogout } from "react-icons/md";
import { toast } from "react-toastify";
import CopyText from "./toast/copy-text";
import { QueryClient } from "react-query";
import useAdminAffiliates from "../store/admin/affiliates";
import useAdminAffiliateInfo from "../hooks/apis/admin/useAdminAffiliateInfo";
import useAdminRank from "../store/admin/adminRank";


export default function DashboardSidebar({
  user,
  useFor = "user",
  classname,
  background,
  children,
}) {
  const router = useLocation();
  const signOut = useSignOut();
  const queryClient = new QueryClient();
  const { setSelectedAffiliate } = useAdminAffiliates();
  const [show,setShow]=React.useState(false)
  const { adminRank } = useAdminRank()
  
  const signUserOut = () => {
    if (useFor === "admin") {
      setSelectedAffiliate("");
      signOut();
      queryClient.removeQueries();
    } else {
      signOut();
    }
  };

  const classes = clx(
    "w-[280px] h-screen max-h-screen px-[20px] py-[40px] overflow-auto scrollbar scrollbar-thin scrollbar-thumb-[#E5E5E5]",
    useFor === "user" ? "bg-[#F8F8FF] dark:bg-[#0C0C11]" : "bg-primary-dark",
    background,
    classname
  );
  return (
    <div className={classes}>
      <div className="mx-auto w-fit">
        <img
          src={`/assets/images/${useFor === "user" ? "logo2" : "logo3"}.png`}
          alt=""
        />
      </div>
      {useFor === "user" && (
        <div className="w-fit px-[10px] py-[40px] dark:text-[#9799A5]">
          <Text as="p" weight="normal" classname="w-fit text-[25px]">
            Hello
          </Text>
          <Text as="p" weight="semibold" classname="w-fit text-[20px]">
            {user ? `${user.firstname} ${user.lastname}` : "Samuel Lasisi"}
          </Text>
          <div className="flex items-center space-x-1">
            <Text as="p" weight="semibold" classname="text-[14px]">
              UserID:
            </Text>
            <div className="flex items-center space-x-3">
              <Text as="span" classname="text-[14px]">
                {user ? user.userid : "123456ABCD"}
              </Text>
              <CopyToClipboard
                text={user?.userid}
                onCopy={() =>
                  toast.success(
                    <CopyText>User ID copied successfully!</CopyText>
                  )
                }
              >
                <BiCopy className="block text-black cursor-pointer" />
              </CopyToClipboard>
            </div>
          </div>
        </div>
      )}
      <div
        className={`flex flex-col space-y-6 pb-[30px] ${
          useFor === "user" ? "border-b-[3px]" : "lg:pt-[50px] "
        }`}
      >
        {useFor === "user"
          ? userMenu.map(
              (link) =>
                link.id !== 4 &&
                link.id !== 5 && (
                  <LinkButton
                    key={link.id}
                    icon={link.icon}
                    href={link.route}
                    classname="w-full"
                    isActive={
                      router.pathname === link.route ||
                      router.pathname === `${link.route}/`
                    }
                  >
                    {link.label}
                  </LinkButton>
                )
            )
          : useFor === "admin"
          ? adminMenu.map(
              (link) =>
                (link.id !== 8 || (link.id === 8 && adminRank === 5)) && (
                  <LinkButton
                    useFor={useFor}
                    key={link.id}
                    icon={link.icon}
                    href={link.route}
                    subroutes={link.subroutes}
                    classname="w-full"
                    isActive={
                      router.pathname === link.route ||
                      router.pathname === `${link.route}/` ||
                      (link.subroutes &&
                        link.subroutes.some(
                          (link) => link.route === router.pathname
                        )) ||
                      (link.subroutes &&
                        link.subroutes.some(
                          (link) => `${link.route}/` === router.pathname
                        ))
                    }
                  >
                    {link.label}
                  </LinkButton>
                )
            )
          : ioboMenu.map((link) => (
              <LinkButton
                key={link.id}
                icon={link.icon}
                href={link.route}
                classname="w-full"
                isActive={
                  router.pathname === link.route ||
                  router.pathname === `${link.route}/`
                }
              >
                {link.label}
              </LinkButton>
            ))}
      </div>
      <div className="py-[30px] flex flex-col space-y-6">
        {useFor === "user" &&
          userMenu.map(
            (link) =>
              link.id === 4 && (
                <LinkButton
                  key={link.id}
                  icon={link.icon}
                  href={link.route}
                  classname="w-full"
                  isActive={
                    router.pathname === link.route ||
                    router.pathname === `${link.route}/`
                  }
                >
                  {link.label}
                </LinkButton>
              )
          )}
        {useFor === "user" && (
          <div className="pl-[48px] flex items-center justify-between">
            <Text as="span" classname="text-[#9799A5] py-[16px]">
              Dark Mode
            </Text>
            <Switch />
          </div>
        )}
        {useFor === "admin" ? (
          <LinkButton
            useFor="admin"
            onclick={signUserOut}
            icon={<MdOutlineLogout className="text-[24px]" />}
            classname="w-full"
          >
            Logout
          </LinkButton>
        ) : (
          <LinkButton
            onclick={signUserOut}
            icon={<MdOutlineLogout className="text-[24px]" />}
            classname="w-full"
          >
            Logout
          </LinkButton>
        )}
      </div>
    </div>
  );
}
