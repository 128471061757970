import React from "react";
import Text from "../../../text";
import Button from "../../../button";
import useAdminAffiliates from "../../../../store/admin/affiliates";

import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
export default function SettlementActionsModal({ btnType, query, data, type }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const { selectedAffiliate } = useAdminAffiliates();
  const navigate = useNavigate();
  const authUser = useAuthUser();

  const onSubmit = (values) => {
    setIsLoading(true)
    // console.log(values)
    axios.post(`https://pm-server.herokuapp.com/api/v2.0/settlement?query=${query}`, {
        tag: selectedAffiliate,
          }, {
        headers: {
            Authorization: authUser().authToken,
        }
    }).then(res => {
        toast.success(res.data.message)
        navigate('/manager/configure/affiliates')
    }).catch(err => {
        toast.error(err.response.data.message)
    }).finally(() => {
        setIsLoading(false)
    })
};

  return (
    <div>
      <Text
        as="h3"
        weight="bold"
        classname="text-[24px] lg:text-[30px] text-center"
      >
        {type}
      </Text>
      <div className="my-6 flex flex-col space-y-6">
        <Text as="p" weight="medium" classname=" text-secondary-dark">
          Are you sure you want to {type} this affilate?
        </Text>
      </div>
      <Button onclick={onSubmit} isLoading={isLoading} classname="w-full">
        {btnType}
      </Button>
    </div>

  );
}
