import React from "react";
import { clx } from "../../../../utils/clx";
import UseFetchEndpoint from "../../../../hooks/apis/useFetch";
import { useEffect } from "react";
import useAdminAffiliates from "../../../../store/admin/affiliates";
import { Link } from "react-router-dom";
import Text from "../../../../components/text";
import { GrServices, GrStatusDisabled } from "react-icons/gr";
import Button from "../../../../components/button";
import { IoAddOutline } from "react-icons/io5";
import { CgTrash } from "react-icons/cg";
import ModalWrapper from "../../../../components/modals/modal-wrapper";
import AffiliateServiceModal from "../../../../components/modals/admin/affiliate-service-modal";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuthUser } from "react-auth-kit";
import { useAffiliateProducts } from "../../../../store/admin/configure";
import useAdminRank from "../../../../store/admin/adminRank";
export default function AffiliateServices() {
    const { selectedAffiliate } = useAdminAffiliates();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [modalType, setModalType] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [newService, setNewService] = React.useState("");
    const { adminRank } = useAdminRank()

    const authUser = useAuthUser();
    const {
        data,
        isFetching,
        refetch
    } = UseFetchEndpoint({ method: "get", endpoint: `/${selectedAffiliate}/property?prop=charges`, key: 'charges-props' })
    useEffect(() => {
        refetch()
    }, [selectedAffiliate])

    const { setAffiliateProducts } = useAffiliateProducts()

    const handleButtonClick = (path) => {
        setIsModalOpen(true);
        setModalType(path);
    }

    const handleAction = (query) => {
        setIsLoading(true);
        axios.post(`https://pm-server.herokuapp.com/api/v2.0/affiliate/service?query=${query}`, {
            tag: selectedAffiliate,
            service_slug: newService.toLowerCase()
        }, {

            headers: {
                Authorization: authUser().authToken,
            }
        }
        ).then((res) => {
            setIsLoading(false);
            setIsModalOpen(false);
            console.log(res.data)
            setNewService("")
            refetch()
        }).catch((err) => {
            setIsLoading(false);
            toast.error(err.response.data.message)
        }).finally(() => {
            setIsLoading(false);
        })
    }
    const classes = clx(
        "w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex flex-col space-y-[40px]"
    );
    return (
        <React.Fragment>

            <div className={classes}>

                <div>

                    <div className="flex justify-end items-center">
                        {adminRank >= 2 && (
                            <div className="flex  gap-4 p-3">
                                <Button
                                    variant='text'
                                    iconLeft={<IoAddOutline />}
                                    onclick={() => {
                                        handleButtonClick("activate");
                                    }}

                                    classname=' w-fit text-secondary-dark'
                                >
                                    Activate a service
                                </Button>
                                <Button
                                    variant='text'
                                    iconLeft={<GrStatusDisabled />}
                                    onclick={() => {
                                        handleButtonClick("deactivate");
                                    }}
                                    classname=' w-fit text-secondary-dark'
                                >
                                    Deactivate a service
                                </Button>
                                <Button
                                    variant='text'
                                    iconLeft={<CgTrash />}
                                    onclick={() => {
                                        handleButtonClick("remove");
                                    }}
                                    classname=' w-fit text-secondary-dark'
                                >
                                    Remove a service
                                </Button>
                            </div>
                        )}
                    </div>
                    {
                        isFetching ? (
                            <>
                                <Text as="p" classname="text-center my-auto mt-60" >
                                    Loading servicess...
                                </Text>
                            </>
                        ) :
                            <>
                                {data?.services?.services.length ? (
                                    <div className="grid lg:grid-cols-4 md:grid-cols-3 gap-[40px] items-center">

                                        {data?.services?.services.map((tab, index) => (
                                            <div key={index} className="flex flex-col items-center justify-center w-[200px] bg-[#E6E6E9] rounded-md h-[200px] gap-3">
                                                <GrServices size={50} />
                                                <Text as="h3" weight="bold" classname="font-bold capitalize">
                                                    {tab.slug}
                                                </Text>
                                                <Text as="p" weight="bold" classname={`${tab.status ? "text-green-600" : "text-red-400"} `}>
                                                    {tab.status ? "Active" : "Inactive"}
                                                </Text>
                                                <Link
                                                    key={index}
                                                    className="text-white font-bold px-4 py-2 rounded-md bg-primary-dark hover:bg-primary-light transition-all duration-200 ease-in-out"
                                                    to={`/manager/configure/affiliates/service/${tab.slug}`}
                                                    state={{
                                                        data: tab?.product,
                                                        path: tab.slug
                                                    }}
                                                    onClick={() => {
                                                        setAffiliateProducts({
                                                            data: tab?.product,
                                                            tag: selectedAffiliate,
                                                            service_slug: tab.slug

                                                        })
                                                    }}
                                                >
                                                    View
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div>


                                        <Text as="p" classname="text-center mt-60 m-auto">
                                            No services available
                                        </Text>
                                    </div>
                                )}
                            </>
                    }
                </div>


            </div>
            <>
                <ModalWrapper
                    isOpen={isModalOpen}
                    handleModalClose={() => setIsModalOpen(false)}
                >
                    {modalType === "activate" && <AffiliateServiceModal
                        title={"Activate a service"}
                        body="Activate a service for this affiliate"
                        onButtonClick={() => {
                            handleAction('service-opt-in')

                        }}
                        data={data?.services?.services.filter((item) => item.status === false)}
                        btnText="Activate"
                        isLoading={isLoading}
                        type="activate"
                        newService={newService}
                        setNewService={setNewService}
                        label="Service"
                        query="get-service-slugs"
                    />}
                    {modalType === "deactivate" &&
                        <AffiliateServiceModal
                            title={"Deactivate a service"}
                            body="Deactivate a service for this affiliate"
                            onButtonClick={() => handleAction('service-opt-out')}
                            data={data?.services?.services.filter((item) => item.status === true)}
                            btnText="Deactivate"
                            isLoading={isLoading}
                            type="deactivate"
                            newService={newService}
                            setNewService={setNewService}
                            label="Service"
                        />
                    }
                    {modalType === "remove" && <AffiliateServiceModal
                        title={"Remove a service"}
                        body="Remove a service for this affiliate"
                        onButtonClick={() => handleAction('remove-service')}
                        data={data?.services?.services}
                        btnText="Remove"
                        isLoading={isLoading}
                        type="remove"
                        newService={newService}
                        setNewService={setNewService}
                        label="Service"
                    />}
                </ModalWrapper>
            </>
        </React.Fragment>
    );
}