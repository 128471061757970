import React from "react";
import { AiFillHome } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { clx } from "../utils/clx";
import Text from "./text";
import { RiArrowDownSLine } from "react-icons/ri";
import useAdminAffiliateInfo from "../hooks/apis/admin/useAdminAffiliateInfo";
import useAdminAffiliates from "../store/admin/affiliates";

export default function LinkButton({
  useFor = "user",
  icon = <AiFillHome />,
  href,
  subroutes,
  onclick,
  isActive,
  classname,
  children,
}) {
  const [isOpen, setOpen] = React.useState();
  const router = useLocation();
  const affiliate = useAdminAffiliateInfo();
  const { selectedAffiliate } = useAdminAffiliates();

  const energyCount = affiliate?.energymeters?.length;
  const waterCount = affiliate?.watermeters?.length;
  const iotCount = affiliate?.iotservices?.length;

  const classes = clx(
    "flex items-center space-x-4 w-full py-[16px] rounded-[8px] cursor-pointer duration-400",
    useFor === "user" && isActive
      ? "bg-primary-dark text-white"
      : "text-[#6C6F7F]",
    useFor === "admin" && isActive
      ? "bg-[#E6E6E9] text-primary-dark"
      : "text-[#6C6F7F]",
    useFor === "user"
      ? "px-[48px] hover:bg-primary-dark hover:text-white"
      : "px-[40px] hover:bg-[#E6E6E9] hover:text-primary-dark",
    classname
  );

  React.useEffect(() => {}, [selectedAffiliate]);

  if (subroutes) {
    return (
      <div>
        <div className={classes} onClick={() => setOpen((state) => !state)}>
          {icon}
          <Text as="span">{children}</Text>
          <div className="pl-[20px]">
            <RiArrowDownSLine className="text-[24px]" />
          </div>
        </div>
        {isOpen ? (
          <div className="pt-3 pl-[10px]">
            {subroutes.map((route, index) => (
              <Link
                key={route.label}
                to={route.route}
                className={`${
                  (route.label === "Prepaid Energy" && energyCount === 0) ||
                  (route.label === "Prepaid Water" && waterCount === 0) ||
                  (route.label === "Fuel Monitory" && iotCount === 0)
                    ? "hidden"
                    : null
                }`}
              >
                <div
                  className={`flex items-center space-x-4 w-full px-[40px] py-[16px] rounded-[8px] cursor-pointer duration-400 hover:bg-transparent hover:text-white ${
                    router.pathname === route.route ||
                    router.pathname === `${route.route}/`
                      ? "text-secondary-dark"
                      : "text-[#6C6F7F]"
                  }`}
                >
                  {route.icon}
                  <Text as="span" classname="text-[14px]">
                    {route.label}
                  </Text>
                </div>
              </Link>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
  return (
    <div>
      <Link to={href} onClick={onclick}>
        <div className={classes}>
          {/* Icon */}
          {icon}
          <Text as="span">{children}</Text>
        </div>
      </Link>
    </div>
  );
}
