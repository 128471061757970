import React from "react";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import useAdminAffiliateInfo from "./useAdminAffiliateInfo";
import useAdminAffiliates from "../../../store/admin/affiliates";
import { useQuery } from "react-query";

export default function useAdminAffiliateWalletInfo({ key, action }) {
  const affiliate = useAdminAffiliateInfo();
  const { selectedAffiliate } = useAdminAffiliates();
  const authUser = useAuthUser();
  // const [data, setData] = React.useState();

  const fetchAffiliateWalletInfo = () => {
    return axios.post(
      "https://pm-server.herokuapp.com/api/v2.0/wallet",
      { walletid: affiliate?.walletid, action },
      {
        headers: {
          Authorization: authUser().authToken,
        },
      }
    );
  };
  const { isLoading, data, isError, refetch } = useQuery({
    queryKey: [key ?? "WalletInfo", selectedAffiliate],
    queryFn: fetchAffiliateWalletInfo,
  });
  // React.useEffect(() => {
  //   refetch();
  // }, [affiliate, authUser()]);
  return { data: data?.data, isLoading, isError };
}
