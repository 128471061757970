export const ProductItems = [
  {
    id: 1,
    imgSrc: "/assets/images/product1.png",
    rating: 10,
    name: "Lasis Samuel Iren",
    meter_number: "626668778",
    provider: "IBED/Postpaid",
    price: 2000,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.",
  },
  {
    id: 2,
    imgSrc: "/assets/images/product2.png",
    rating: 10,
    name: "Lasis Samuel Iren",
    meter_number: "626668779",
    provider: "IBED/Postpaid",
    price: 2000,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.",
  },
  {
    id: 3,
    imgSrc: "/assets/images/product3.png",
    rating: 10,
    name: "Lasis Samuel Iren",
    meter_number: "626668776",
    provider: "IBED/Postpaid",
    price: 2000,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.",
  },
  {
    id: 4,
    imgSrc: "/assets/images/product4.png",
    rating: 10,
    name: "Lasis Samuel Iren",
    meter_number: "626668775",
    provider: "IBED/Postpaid",
    price: 2000,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.",
  },
];

export const CategoryItems = [
  {
    id: 1,
    imgSrc: "/assets/images/product1.png",
    rating: 10,
    price: 2000,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.",
  },
  {
    id: 2,
    imgSrc: "/assets/images/product2.png",
    rating: 10,
    price: 2000,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.",
  },
  {
    id: 3,
    imgSrc: "/assets/images/product3.png",
    rating: 10,
    price: 2000,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.",
  },
  {
    id: 4,
    imgSrc: "/assets/images/product4.png",
    rating: 10,
    price: 2000,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.",
  },
  {
    id: 5,
    imgSrc: "/assets/images/product3.png",
    rating: 10,
    price: 2000,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.",
  },
  {
    id: 6,
    imgSrc: "/assets/images/product4.png",
    rating: 10,
    price: 2000,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.",
  },
];
