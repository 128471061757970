import React from "react";
import { clx } from "../../../../../utils/clx";
import Text from "../../../../../components/text";
import Button from "../../../../../components/button";
import ModalWrapper from "../../../../../components/modals/modal-wrapper";
import GatePassData from "../../../../../components/modals/admin/verify-gate-pass";
import Input from "../../../../../components/input";
import { GrAccessibility } from "react-icons/gr";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { toast } from "react-toastify";

export default function VerifyGatePass() {
    const [accessCode, setAccessCode] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [data, setData] = React.useState({});
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const slug = "code"
    const authUser = useAuthUser();


    const onSubmit = async (e) => {
        e.preventDefault();
        if (!accessCode) {
            setErrorMessage("Access Code is required");
            return;
        }
        setIsLoading(true);
        await axios.get(`https://pm-server.herokuapp.com/api/v2.0/get-gatepass?slug=${slug}&access_code=${accessCode}`, {
            headers: {
                Authorization: authUser().authToken
            }
        }).then((res) => {
            toast.success(res.data.message)
            setData(res.data);
            setIsLoading(false);
            setIsModalOpen(true);
        }).catch((err) => {
            setIsLoading(false);
            toast.error(err.response.data.message)
            setErrorMessage(err.response.data.message);
        }).finally(() => {
            setIsLoading(false);
            setAccessCode("");
        });
    }




    const classes = clx("w-[93%] lg:w-[90%] mx-auto lg:py-[40px]");
    return (
        <React.Fragment>

            <div className={classes}>
                <div>
                    <Text as='h2' weight='bold' classname='text-[24px] text-center text-primary-light'>
                        Enter Access Code
                    </Text>
                </div>
                <form onSubmit={onSubmit}>
                    <div className="flex justify-center py-6 flex-col gap-6 items-center rounded-lg mx-auto mt-6 max-w-[787px] bg-[#fff]">
                        <Input
                            type="text"
                            classname="w-[472px]"
                            iconLeft={<GrAccessibility />}
                            name="access_code"
                            value={accessCode}
                            onchange={(e) => {
                                setAccessCode(e.target.value)
                            }}
                            onblur={
                                (e) => {
                                    setAccessCode(e.target.value)
                                }
                            }
                            errorMessage={
                                errorMessage
                            }
                            placeholder="Enter Access Code..."

                        />
                        <Button classname="w-[472px]"
                            buttonType="submit"
                            isLoading={isLoading}
                        >
                            Verify
                        </Button>
                    </div>
                </form>
            </div>

            <ModalWrapper
                isOpen={isModalOpen}
                handleModalClose={() => setIsModalOpen(false)}
            >
                {data?.data && <GatePassData data={data?.data} type="Sign In" />}
            </ModalWrapper>
        </React.Fragment>
    )
}