import Text from "../../../components/text";
import UnregisteredUserAuth from "./components/unregistered-user-auth";
import UsersActions from "./components/users-actions";

export const users_data = Array.from({ length: 20 }).map((data, i) => ({
    _id: i + 1,
    name: "Oloko Opeyemi ",
    apartment: "Landwey Estate Lekki 1",
    meterid: `5232464252${i}`,
    meter_type: `ENERGY`
}));

export const users_column = [
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Name
            </Text>
        ),
        Cell: ({ value, row }) => <Text as="p" classname='text-left text-[16px]'>
            {value}
        </Text>,
        accessor: "fullname",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Apartment
            </Text>
        ),
        Cell: ({ value, row }) => <Text as="p" classname='text-left text-[16px]'>
            {value}
        </Text>,
        accessor: "apartment",
    },

    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                P.Number
            </Text>
        ),
        //     Cell: ({ value, row }) => <Text as="p">
        //     {value}
        // </Text>,
        accessor: "phonenumber",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Email
            </Text>
        ),
        //     Cell: ({ value, row }) => <Text as="p">
        //     {value}
        // </Text>,
        accessor: "email",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Category
            </Text>
        ),
        //     Cell: ({ value, row }) => <Text as="p">
        //     {value}
        // </Text>,
        accessor: "address",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Meter ID
            </Text>
        ),
        //     Cell: ({ value, row }) => <Text as="p">
        //     {value}
        // </Text>,
        accessor: "meterid",
    },

    {
        id: "2",
        Header: (
            <Text as="p" classname="text-left text-[16px] text-[#2C3048]">
                Actions
            </Text>
        ),
        accessor: "_id",
        Cell: UsersActions
    },
];
