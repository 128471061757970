import React from "react";
import Text from "../../text";
import Input from "../../input";
import Button from "../../button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TbCurrencyNaira } from "react-icons/tb";
import { toast } from "react-toastify";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import useAdminAffiliates from "../../../store/admin/affiliates";
import axios from "axios";

export default function EditRegularNairaAddUp({ onButtonClick, data, title }) {
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const { selectedAffiliate } = useAdminAffiliates();

  const formValues = {
    naira_addup: data?.naira_addup,
  };

  const validationSchema = Yup.object({
    naira_addup: Yup.number().required("Charges Amount is required"),
  });
  const onSubmit = (values) => {
    setIsLoading(true);
    // console.log(values);
    setValidationAttempt(false);
    axios.put("https://pm-server.herokuapp.com/api/v2.0/charges-proxy?query=naira_addup", {
      ...values,
      tag: selectedAffiliate,
      charges_slug: data.charges_slug,
      insight_slug: data.insight_slug,
      type: data.type,

    }, {
      headers: {
        Authorization: authUser().authToken,
      },
    }).then(res => {
      // console.log(res)
      setIsLoading(false)
      navigate('/manager/configure/charges')
      toast.success(res.data.message)

    }).catch(err => {
      // console.log(err)
      setIsLoading(false)
      toast.error(err.response.data.message)

    }).finally(() => {
      setIsLoading(false)
    })
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <Text
          as="h3"
          weight="bold"
          classname="text-[24px] lg:text-[30px] text-center"
        >
          {title}
        </Text>
        <div className="my-6 flex flex-col space-y-6">
          <Input
            iconLeft={<TbCurrencyNaira />}
            label="Naira amount:"
            placeholder={data.naira_addup}
            type="number"
            name="naira_addup"
            value={formik.values.naira_addup}
            onchange={(e) => formik.handleChange(e)}
            onblur={formik.handleBlur}
            errorMessage={formik.errors.naira_addup}
          />
        </div>
        <Button buttonType="submit" classname="w-full" isLoading={isLoading}>
          Add
        </Button>
      </div>
    </form>
  );
}
