
import CurrencyFormat from "react-currency-format";
import Text from "../../../components/text";

export const invoices_data = Array.from({ length: 5 }).map((data, i) => ({
    _id: i + 1,
    name: "Oloko Opeyemi ",
    apartment: "Landwey Estate Lekki 1",
    amount: `N10,000`,
    purpose: `MONTHLY`,
    paid: "Yes",
    date: `18-04-2023`,
}));

export const invoices_column = [
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Date Created
            </Text>
        ),
        accessor: "date",
        Cell: ({ value, row }) => <Text as='p' classname="text-left text-[16px] text-primary-light">
            {value && new Date(value).toLocaleString("en-US")}
        </Text>
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Name
            </Text>
        ),
        Cell: ({ value, row }) => <Text as="p" classname='text-left text-[16px] capitalize'>
            {value}
        </Text>,
        accessor: "fullname",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048] ca'>
                Apartment
            </Text>
        ),
        // Cell: ({ value, row }) => <Text as="p">
        //     {value}
        // </Text>,
        accessor: "apartment",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Amount
            </Text>
        ),
        Cell: ({ value, row }) => <Text as="p">
            <CurrencyFormat
                value={value}
                displayType="text"
                decimalScale={2}
                renderText={(value) => <Text as="span">₦{value}</Text>}
                thousandSeparator
                fixedDecimalScale
            />
        </Text>,
        accessor: "amount",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Purpose
            </Text>
        ),
        accessor: "purpose",
        Cell: ({ value, row }) => <Text as='p' classname="text-left text-[16px] uppercase text-secondary-dark">
            {value}
        </Text>
    },

    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Paid
            </Text>
        ),
        accessor: "paid",
        Cell: ({ value, row }) => {
            const isPaid = value.toLowerCase() === "paid"
            return <Text as='p' classname={`text-left text-[16px] ${isPaid ? "text-green-400" : "text-red-500"}`}>
                {value}
            </Text>

        }
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Payout Date
            </Text>
        ),
        accessor: "payoutDate",
        Cell: ({ value, row }) => <Text as='p' classname="text-left text-[16px] text-primary-light">
            {value ? new Date(value).toLocaleString("en-US") : "N/A"}
        </Text>
    },

   

];
