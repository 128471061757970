import React from "react";
import Text from "../../../text";
import Button from "../../../button";
import useAdminAffiliates from "../../../../store/admin/affiliates";

import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";


export const settlementTypes = [
  {
    id: "instant-settlement",
    name: "Instant Settlement",
  },
  {
    id: "daily-settlement",
    name: "Daily Settlement",
  },
  {
    id: "two-weeks-settlemen",
    name: "Two Weeks Settlement",
  },
  {
    id: "monthly-settlement",
    name: "Monthly Settlement",
  }

]


export default function StartSettlement({ }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const { selectedAffiliate } = useAdminAffiliates();
  const [type, setType] = React.useState("");
  const navigate = useNavigate();
  const authUser = useAuthUser();

  const onSubmit = (values) => {
    if (!type) {
      return toast.error("Please select a settlement type")
    }
    setIsLoading(true)
    // console.log(values)
    axios.post(`https://pm-server.herokuapp.com/api/v2.0/settlement?query=activate-settlement`, {
      tag: selectedAffiliate,
      type
    }, {
      headers: {
        Authorization: authUser().authToken,
      }
    }).then(res => {
      toast.success(res.data.message)
      navigate('/manager/configure/affiliates')
    }).catch(err => {
      toast.error(err.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  };

  return (
    <div>
      <Text
        as="h3"
        weight="bold"
        classname="text-[24px] lg:text-[30px] text-center"
      >
        Start Settlement
      </Text>
      <div className="my-6 flex flex-col space-y-6">
        <Text as="p" weight="medium" classname=" text-secondary-dark">
          Select a settlement type to start settlement for this affiliate
        </Text>
        <select
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
          className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
          required
          disabled={isLoading}
        >
          <option value="">Select a settlement type</option>
          {settlementTypes.map((settlement) => (
            <option key={settlement.id} value={settlement.id}>
              {settlement.name}
            </option>
          ))}
        </select>

      </div>
      <Button onclick={onSubmit} isLoading={isLoading} classname="w-full">
        Proceed
      </Button>
    </div>

  );
}
