import axios from "axios";

import { useQuery } from "react-query";

export default function useGetFaq(platform) {
  const fetchFaq = () => {
    return axios.get(
      `https://pm-server.herokuapp.com/api/v2.0/faq/?platform=${platform}`
    );
  };
  const { data, isLoading, isFetching } = useQuery({
    queryKey: "get-faq",
    queryFn: () => fetchFaq(),
  });

  return { data: data?.data, isLoading, isFetching };
}
