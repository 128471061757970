import React from "react";
import StaticLayout from "../../layouts/static-layout";
import CategoryWrapper from "../../views/static/category-page";
export default function ProductCategoryPage() {
      return (
            <React.Fragment>
                  <StaticLayout>
                        <CategoryWrapper />
                  </StaticLayout>
            </React.Fragment>
      )
}