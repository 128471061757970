import { eachDayOfInterval, sub } from "date-fns";
import React from "react";
import Chart from "react-apexcharts";
import { FcCdLogo } from "react-icons/fc";
import { FiFilter } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import useAdminAffiliateInfo from "../../../../hooks/apis/admin/useAdminAffiliateInfo";
import useSelectedEnergyUser from "../../../../store/admin/selected-energy-user";
import getDate from "../../../../utils/getDate";
import useConsumptionInfo from "../../../../hooks/apis/user/useConsumptionInfo";
import useChart from "../../../../hooks/useChart";
import Text from "../../../../components/text";
import { clx } from "../../../../utils/clx";
import ModalWrapper from "../../../../components/modals/modal-wrapper";
import Filter from "../../../../components/modals/filter";

export default function AffiliateEnergyStatisticChart({ classname }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedEnergyUser: affiliate } = useSelectedEnergyUser();
  const affiliateUser = useAdminAffiliateInfo();

  const dayInterval = eachDayOfInterval({
    start: new Date(searchParams.get("start-date")),
    end: new Date(searchParams.get("end-date")),
  }).map((date) => getDate(date, { format: "yyyy-mm-dd" }));

  const {
    data: ConsumptionInfo,
    isLoading,
    isFetching,
    refetch,
  } = useConsumptionInfo({
    key: "barChartConsumptionStatistics",
    query: "date-range",
    prepaid: "energy",
    start: searchParams.get("start-date"),
    end: searchParams.get("end-date"),
    userId: affiliate?.userid,
    apiKey: affiliateUser?.apikey,
  });

  const Consumptions =
    ConsumptionInfo?.data.map((data) => Number(data.consumption).toFixed(2)) ??
    [];

  const getWidth = (Consumptions) => {
    switch (true) {
      case Consumptions?.length < 10:
        return "w-[100%]";
      case Consumptions?.length >= 10 && Consumptions?.length < 15:
        return "w-[700px] lg:w-full";
      case Consumptions?.length >= 15 && Consumptions?.length < 30:
        return "w-[900px] lg:w-[700px]";
      case Consumptions?.length >= 30 && Consumptions?.length < 60:
        return "w-[1200px] lg:w-[1000px]";
      case Consumptions?.length >= 60:
        return "w-[2500px] lg:w-[2000px]";
      default:
        return;
    }
  };

  React.useEffect(() => {
    refetch();
  }, [searchParams]);

  const {
    options: chartOptions,
    series: chartSeries,
    otherConfig: chartConfig,
  } = useChart({
    chartId: "energy-bar-chart",
    type: "bar",
    colors: ["#8692D6"],
    categories: dayInterval,
    data: Consumptions,
    width: "100%",
    height: "400px",
  });
  const classes = clx("pt-[5px] lg:pt-[40px]", classname);
  return (
    <div className={classes}>
      <div className="h-full w-full flex flex-col p-4 lg:p-6">
        <AffiliateEnergyStatisticChart.ChartTopBar />
        <div className="flex-1 overflow-x-scroll scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-primary-dark">
          {isFetching ? (
            <div className="h-[200px] w-full flex flex-col items-center justify-center">
              <div className="w-[30px] h-[30px]">
                <img
                  src="/assets/icons/loader.gif"
                  alt=""
                  className="w-full h-full"
                />
              </div>
              <Text
                as="p"
                weight="semibold"
                classname="text-[14px] lg:text-[18px]"
              >
                Getting your data!. One sec ...
              </Text>
            </div>
          ) : (
            <div className={`${getWidth(Consumptions)} h-[100%]`}>
              <Chart
                options={chartOptions}
                series={chartSeries}
                {...chartConfig}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function ChartTopBar({ classname, noTabs, children }) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const classes = clx("", classname);
  return (
    <React.Fragment>
      <div className={classes}>
        <div className="pb-2 mb-4 flex items-center justify-between border-b-2 border-[#6C6F7F]/10">
          <div className="">
            <Text as="h5" classname="text-[28px] font-semibold">
              524.52 <span className="text-[16px]">kwh</span>
            </Text>
            <Text as="p" classname="text-[12px] text-[#DB9800]">
              Estimated cost is around ₦1000
            </Text>
          </div>
          <div
            className="cursor-pointer flex items-center space-x-1"
            onClick={() => setModalOpen(true)}
          >
            <FiFilter />
            <Text as="span" classname="text-[14px] lg:text-base">
              Filter Data
            </Text>
          </div>
        </div>
      </div>

      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => setModalOpen(false)}
      >
        <Filter
          handleModalClose={() => setModalOpen(false)}
          route={"/manager/energy/customers/single/energy-statistics"}
        />
      </ModalWrapper>
    </React.Fragment>
  );
}

AffiliateEnergyStatisticChart.ChartTopBar = ChartTopBar;
