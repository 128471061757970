import React from "react";
import Button from "../../../../components/button";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import ModalWrapper from "../../../../components/modals/modal-wrapper";
import EditRegularCharges from "../../../../components/modals/admin/edit-regular-charges";
import RemoveRegularCharges from "../../../../components/modals/admin/remove-regular-charges";

export default function RegularChargesActions({ value, row }) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");

  const handleButtonClick = (type) => {
    setModalType(type);
    setModalOpen(true);
  };

  // console.log(row.original);
  return (
    <React.Fragment>
      <div className="flex items-center space-x-6">
        {/* <FiLock color="gray" /> */}
        <Button
          variant="text"
          iconLeft={<AiOutlineEdit />}
          classname=" w-fit  text-secondary-dark"
          onclick={() => handleButtonClick("edit")}
        >
          Edit
        </Button>
        <Button
          variant="text"
          iconLeft={<RiDeleteBinLine />}
          classname=" w-fit  text-secondary-dark"
          onclick={() => handleButtonClick("remove")}
        >
          Remove
        </Button>
      </div>

      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => setModalOpen(false)}
      >
        {modalType === "edit" && <EditRegularCharges data={row.original} title="Edit Charge" />}
        {modalType === "remove" && <RemoveRegularCharges data={row.original} title="Remove Charge" />}
      </ModalWrapper>
    </React.Fragment>
  );
}
