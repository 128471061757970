import React, { useCallback } from "react";
import ModalWrapper from "../../../../components/modals/modal-wrapper";
import EditApartment from "../../../../components/modals/admin/edit-apartment";
import DeleteUser from "../../../../components/modals/admin/delete-user";
import EditCategory from "../../../../components/modals/admin/edit-category";
import EditNumber from "../../../../components/modals/admin/edit-number";
import EditName from "../../../../components/modals/admin/edit-name";
import MeterStatusEdit from "./meter-status-edit";

export default function MeterActions({ value, row }) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");

  const handleButtonClick = useCallback(
    (type) => {
      if (type === "" || type === undefined) return setModalType("");
      setModalType(type);
      setModalOpen(true);
    },
    [modalType]
  );

  return (
    <>
      <select
        value={modalType}
        onChange={(e) => {
          handleButtonClick(e.target.value);
        }}
        className="border-none bg-gray-200 outline-none rounded-[6px] py-[6px] px-[15px]"
      >
        <option value="" disabled>Select Action</option>
        <option value="status">Edit Status</option>

      </select>

      <ModalWrapper
        isOpen={isModalOpen}
        handleModalClose={() => {
          setModalOpen(false);
          setModalType("");
        }}
      >
        {modalType === "status" && <MeterStatusEdit data={row.original} />}
      </ModalWrapper>
    </>
  );
}
