
import Text from "../../../components/text";
import PeriodicChargesActions from "./components/periodic-charges-actions";

export const periodic_charge_data = Array.from({ length: 5 }).map((data, i) => ({
    _id: i + 1,
    name: `Gateway Bill ${i + 1}`,
    amount: `N10,000`,
    type: `weekely`
}));

export const periodic_charge_column = [
    {
        id: "1",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                S/N
            </Text>
        ),
        Cell: ({ value, row }) => <Text as="p">
            {value}
        </Text>,
        accessor: "id",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Name
            </Text>
        ),
        Cell: ({ value, row }) => <Text as="p" classname='text-left capitalize text-[16px]'>
            {value}
        </Text>,
        accessor: "name",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Amount
            </Text>
        ),
        Cell: ({ value, row }) => <Text as='p' classname="text-left capitalze text-[16px]">
            ₦{value}
        </Text>,
        accessor: "amount",
    },
    {
        //     id: "_",
        Header: (
            <Text as='p' classname='text-left text-[16px] text-[#2C3048]'>
                Type
            </Text>
        ),
        accessor: "tableType",
        Cell: ({ value, row }) => <Text as='p' classname="text-left capitalize text-[16px]">
            {value}
        </Text>
    },
    {
        id: "2",
        Header: () => null,
        accessor: "id",
        Cell: PeriodicChargesActions,
    },
];
