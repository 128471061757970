import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const MeterForm = () => {
  const validationSchema = Yup.object({
    meterNumber: Yup.string()
      .required('Meter number is required')
      .matches(/^[0-9]+$/, 'Meter number must be numeric'),
    meterType: Yup.string()
      .oneOf(['Type 1', 'Type 2', 'Type 3'], 'Invalid meter type')
      .required('Meter type is required'),
    kct: Yup.string().required('KCT is required'),
  });

  const initialValues = {
    meterNumber: '',
    meterType: 'Type 1',
    kct: '',
  };

  const handleSubmit = (values) => {
    // Handle form submission
    console.log(values);
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Meter Form</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Meter Number</label>
              <Field
                name="meterNumber"
                type="text"
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter meter number"
              />
              <ErrorMessage
                name="meterNumber"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Meter Type</label>
              <Field
                name="meterType"
                as="select"
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="Type 1">Type 1</option>
                <option value="Type 2">Type 2</option>
                <option value="Type 3">Type 3</option>
              </Field>
              <ErrorMessage
                name="meterType"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">KCT</label>
              <Field
                name="kct"
                as="textarea"
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter KCT details"
              />
              <ErrorMessage
                name="kct"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MeterForm;
