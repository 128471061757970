import React from "react";
import { clx } from "../utils/clx";
import Text from "./text";

export default function TextArea({
  type = "text",
  disabled,
  defaultValue,
  errorMessage,
  value,
  onchange,
  onfocus,
  onblur,
  label,
  placeholder,
  inputClass,
  name,
  variant = "primary",
  background,
  borderColor,
  iconLeft,
  iconRight,
  classname,
  children,
}) {
  const classes = clx("flex flex-col space-y-2 w-full", classname);
  const textAreaClasses = clx(
    `flex items-center select-none space-x-3 border-${borderColor} rounded-[8px] py-[15px] px-[10px]`,
    variant === "primary" && "bg-[#E6E6E9] dark:bg-[#1B1D26]",
    variant === "secondary" && "bg-secondary-dark",
    variant === "outlined" && "bg-transparent border-[2px]  border-gray-400",
    inputClass
  );
  return (
    <div className={classes}>
      {label && (
        <label htmlFor=''>
          <Text as='span' weight='medium' classname='text-[15px] lg:text-base'>
            {label}
          </Text>
        </label>
      )}
      <div className={textAreaClasses}>
        {/* Left Icon */}
        <div>{iconLeft}</div>
        <textarea
          name={name}
          id={name}
          defaultValue={defaultValue}
          //   rows={1}
          wrap='hard'
          value={value}
          onChange={onchange}
          onBlur={onblur}
          onFocus={onfocus}
          placeholder={placeholder}
          className={` flex-1 font-gantari font-[500] outline-none placeholder:text-[14px] lg:placeholder:text-base bg-transparent ${
            variant === "secondary" && "placeholder-white"
          } dark:text-[#e6e6e9]`}
          disabled={disabled}
        />
        {/* Right Icon */}
        <div>{iconRight}</div>
      </div>
      {/* Error Message */}
      {errorMessage && (
        <Text
          as='span'
          weight='medium'
          classname='text-[15px] lg:text-base text-red-600'
        >
          {errorMessage}
        </Text>
      )}
    </div>
  );
}
