import React from "react";

import UserChargesTab from "./tabs";
import { clx } from "../../../../utils/clx";
import OverDraft from "./overdraft";
import Periodic from "./periodic";


export default function UserCharges() {
    const [activeTab, setActiveTab] = React.useState(1);

    const classes = clx(
        "w-[93%] lg:w-[90%] mx-auto lg:py-[40px] flex flex-col space-y-[40px]"
    );
    return (
        <div className={classes}>

            <UserChargesTab activeTab={activeTab} setTab={setActiveTab} />
            <div>
                {activeTab === 1 &&
                   <Periodic />
                }
                {activeTab === 2 && <>
                    <OverDraft />

                </>}
            </div>
        </div>
    );
}